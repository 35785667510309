import { AxiosResponse } from 'axios';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';
import {
  IVacation,
  IVacationData,
  IVacationDataWithId,
  VacationAdd,
  VacationDelete,
  VacationEdit,
  VacationsGet,
  VacationsPut,
  TVacationsGetAction,
} from '../types/Vacations';

export const getVacations = (offset: number, sort?: string, limit?: number) => {
  return apiCall<TVacationsGetAction, any, IVacation>(
    VacationsGet,
    'GET',
    API_END_POINTS.GET_VACATIONS,
    true,
    null,
    {
      limit: limit || PAGE_LIMIT,
      offset,
      Sort: sort || 'start desc, end desc',
    },
  );
};

export const changeOffset = (newOffset: number) => {
  return {
    type: VacationsPut.VACATIONS_OFFSET_UPDATE,
    payload: newOffset,
  };
};

export const deleteVacation = (vacationId: string) => {
  return async (dispatch: TDispatch<VacationDelete>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: VacationDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'DELETE',
        url: API_END_POINTS.DELETE_VACATION(vacationId),
        data: {},
      });
      dispatch({
        type: VacationDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: VacationDelete.DELETE_COMPANY_STORE,
        payload: vacationId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: VacationDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addNewVacationToStore = (vacation: IVacationDataWithId) => {
  return {
    type: VacationAdd.ADD_VACATION_STORE,
    payload: vacation,
  };
};

export const addVacation = (vacation: IVacationData) => {
  return async (dispatch: TDispatch<VacationAdd>): Promise<AxiosResponse<string>> => {
    dispatch({
      type: VacationAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_VACATION,
        data: {
          ...vacation,
          locale: 'en', //TODO: figure out what locale to send
        },
      });

      dispatch({
        type: VacationAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        dispatch(addNewVacationToStore({ ...vacation, id: response.data }));
      }
      return response;
    } catch (error) {
      dispatch({
        type: VacationAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedVacationToStore = (vacation: IVacationDataWithId) => {
  return {
    type: VacationEdit.EDIT_VACATION_STORE,
    payload: vacation,
  };
};

export const editVacation = (vacation: IVacationData, vacationId: string) => {
  return async (dispatch: TDispatch<VacationEdit>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: VacationEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_VACATION(vacationId),
        data: {
          ...vacation,
          locale: 'en', //TODO: figure out what locale to send
        },
      });
      dispatch({
        type: VacationEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedVacationToStore({ ...vacation, id: vacationId }));
      return response;
    } catch (error) {
      dispatch({
        type: VacationEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
