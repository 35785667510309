import { IExperienceWidgetMultipeState } from 'types/ExperienceWidgetMultiple';

const SELECTED_SERVICES = 'selectedServices';

export const removeSelectedServices = () => sessionStorage.removeItem(SELECTED_SERVICES);

export const getSelectedServices = () => {
  const selectedServices = sessionStorage.getItem(SELECTED_SERVICES);
  if (!selectedServices) return null;
  return JSON.parse(selectedServices);
};

export const setSelectedServices = (selectedServices: IExperienceWidgetMultipeState) => {
  sessionStorage.setItem(SELECTED_SERVICES, JSON.stringify(selectedServices));
};
