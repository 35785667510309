import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import FormInput from 'components/Form/FormInput';
import { IFormError } from 'constants/Interfaces';
import FormCheckbox from 'components/Form/FormCheckbox';
import PriceTable from './PriceTable';
import './MainInfoTab.scss';
import { useStoreState } from 'hooks/useStoreState';

interface IMainInfoTab {
  push?: any;
  submitFailed?: boolean;
  errors?: Record<string, any>;
  fieldError?: IFormError;
}

const MainInfoTab: React.FC<IMainInfoTab> = ({
  push,
  submitFailed,
  errors,
  fieldError,
}) => {
  const { t } = useTranslation();

  const { reservationGroupsEnabled } = useStoreState().companyState.userCompany || {};
  const validateDuration = (value?: string) => {
    if (!value) return;
    // check if value is number
    if (isNaN(parseInt(value, 10))) {
      return t('validation_number');
    }
  };
  return (
    <Row>
      <Col sm={12} md={4}>
        <FormInput
          size='sm'
          label={`${t('service_form_label_name')} *`}
          name='name'
          type='text'
          wrapperClass='mb-3'
          fieldError={fieldError}
        />
        <FormInput
          label={`${t('service_form_label_time_after_service')}`}
          size='sm'
          name='timeAfterService'
          type='text'
          inputClass='mb-0'
          validation={validateDuration}
        />
        {reservationGroupsEnabled && (
          <FormCheckbox
            name='fixedPersonsCount'
            label={t('fixed_person_count')}
            id='fixedPersonsCount'
            swapValues
          />
        )}

        <FormCheckbox
          name='hiddenInPlugin'
          label={t('visible_in_plugin')}
          id='hiddenInPlugin'
          swapValues
        />
      </Col>
      <Col sm={12} md={8} className='table-scroll'>
        <PriceTable push={push} errors={errors} submitFailed={submitFailed} />
      </Col>
    </Row>
  );
};

export default MainInfoTab;
