import React, { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button, { ButtonSize } from '../Button';
import ReservationInfoItem, {
  ItemModifier,
} from 'experience/utils/reservationInfo/ReservationInfoItem';
import { IAppState } from 'store/Store';
import { formatCurrency, getInitialPrice } from 'helpers/Currency';
import './ReservationInfo.scss';
import { IExperienceCompanyState } from 'experience/types/ExperienceCompany';
import { EXPERIENCE_LANG_ITEM } from 'constants/Storage';
import { ExperienceLocales } from 'constants/Experience';
import { SKIP_IDS } from 'config/shipConfig';
import { useStoreState } from 'hooks/useStoreState';

const ReservationInfo: React.FC<{ submitting: boolean }> = ({ submitting }) => {
  const { t } = useTranslation();
  const language = localStorage.getItem(EXPERIENCE_LANG_ITEM);

  const widgetStateMultiple = useStoreState().experienceWidgetMultipeState;

  const companyExperienceState: IExperienceCompanyState = useSelector<
    IAppState,
    IExperienceCompanyState
  >((state: IAppState) => state.experienceCompanyState);

  const isReservationGroupEnabled =
    widgetStateMultiple?.company?.reservationGroupsEnabled;
  const formatWeekday = (date: any) => {
    const numb = moment(date).day();
    return language === ExperienceLocales.LT
      ? t(`employee_calendar_weekday_${numb}`).toLowerCase()
      : t(`employee_calendar_weekday_${numb}`);
  };

  const formatServiceTime = (
    serviceId: string,
    duration?: number,
    serviceTimeStart?: string,
    serviceMonth?: string,
    date?: Date,
  ) => {
    const serviceTimeEnd = moment(serviceTimeStart, 'hh:mm')
      .add(duration, 'minutes')
      .format('HH:mm');

    const endTime = SKIP_IDS.includes(serviceId) ? '' : ` - ${serviceTimeEnd}`;
    return `${serviceTimeStart}${endTime} ${t('twig_time_short_hours')} ${
      language === ExperienceLocales.LT
        ? t(`employee_calendar_month_genitive_${serviceMonth}`).toLowerCase()
        : t(`employee_calendar_month_genitive_${serviceMonth}`)
    } ${moment(date).format('D')} ${
      language !== ExperienceLocales.EN ? t('employee_calendar_day_short') : ''
    } ${formatWeekday(date)}`;
  };

  const getRealTotalPrice = () => {
    const priceArray = widgetStateMultiple.selectedServices?.map(item => {
      const priceToUse = getInitialPrice(
        item?.service?.pricing.salePrice,
        item?.service?.pricing.price,
      );
      if (item && item.service && priceToUse != null) {
        return (item?.dateTimeUser?.personCount || 1) * priceToUse;
      } else {
        return item?.service?.pricing.price;
      }
    });

    const finalPrice = priceArray?.reduce((total, num) => {
      if (total === undefined || num === undefined) return 0;
      return total + num;
    }, 0);

    return finalPrice;
  };

  const renderService = useMemo(() => {
    const title = t('reservation_show_label_name');
    const content = widgetStateMultiple.selectedServices?.map(item => {
      const isFixedPersonsCount = !item?.service?.service.fixedPersonsCount;
      const isMultiReservation = (item?.service?.service?.maxPersonsCount || 1) > 1;
      const showPeopleSelection =
        isReservationGroupEnabled && isMultiReservation && isFixedPersonsCount;
      return (
        <div key={item.serviceId}>
          <span className='font-weight-normal'>{item?.service?.service?.name}</span>
          {showPeopleSelection && (
            <div className='service-container__person-count'>
              {item.dateTimeUser?.personCount} {t('person_short')}
            </div>
          )}
        </div>
      );
    });
    return <ReservationInfoItem title={title} content={content} />;
  }, [widgetStateMultiple.selectedServices]);

  const renderTime = useMemo(() => {
    const title = t('reservation_visiting_time');
    const content = widgetStateMultiple.selectedServices?.map(item => {
      return (
        <div key={item.serviceId}>
          {formatServiceTime(
            item.serviceId,
            item.service?.pricing.duration,
            item.dateTimeUser?.time,
            moment(item.dateTimeUser?.date).format('MM'),
            item.dateTimeUser?.date,
          )}
        </div>
      );
    });
    return <ReservationInfoItem title={title} content={content} />;
  }, [widgetStateMultiple.selectedServices]);

  return (
    <div className='reservation-info'>
      <div className='reservation-info__top'>
        {renderService}
        {renderTime}
        <ReservationInfoItem
          title={t('reservation_show_label_price')}
          content={`${getRealTotalPrice()} ${formatCurrency(
            companyExperienceState?.data?.currency,
          )}`}
          modifier={ItemModifier.Price}
        />
      </div>
      <div className='reservation-info__bottom'>
        <Button type='submit' size={ButtonSize.LG} disabled={submitting}>
          {t('reservation_form_submit')}
        </Button>
        <p className='reservation-info__payment'>
          {t('reservation_form_payment_notice')}
        </p>
      </div>
    </div>
  );
};

export default ReservationInfo;
