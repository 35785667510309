/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

export interface IClient {
  roles: string[];
  expiresIn: number;
  token: string;
  email: string;
  name: string;
  clientId: string;
  companyId?: string;
  userId: string;
}

export interface ILoginData {
  username: string;
  password: string;
}

export interface IUserInfo {
  id: string;
  userName: string;
  phone: string;
  firstName: string;
  lastName: string;
  groups: string;
  token?: string;
}

export interface IApiLoginEntity {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

export interface TUserState {
  readonly data?: IClient;
  readonly avatar?: File | null;
  readonly username?: string;
  readonly firstName?: string;
  readonly lastName?: string;
}

export const initialUserState: TUserState = {
  data: undefined,
  avatar: undefined,
  username: undefined,
  firstName: undefined,
  lastName: undefined,
};

export enum UserLogin {
  REQUEST = 'USER_LOGIN_REQUEST',
  SUCCESS = 'USER_LOGIN_SUCCESS',
  FAILED = 'USER_LOGIN_FAILED',
}

export enum UserLogOut {
  USER_LOG_OUT = 'USER_LOG_OUT',
}

export enum UserTokenLogin {
  LOGIN_WITH_TOKEN = 'USER_LOGIN_WITH_TOKEN',
}

export enum LoggedUser {
  EDIT_NAME_SURNAME = 'active_user/EDIT_NAME_SURNAME',
  EDIT_USERNAME = 'active_user/EDIT_USERNAME',
}

export enum UserGetAvatar {
  REQUEST = 'USER_AVATAR_REQUEST',
  SUCCESS = 'USER_AVATAR_SUCCESS',
  FAILED = 'USER_AVATAR_FAILED',
}

export type TUserLoginAction =
  | {
      type: UserLogin.REQUEST;
    }
  | {
      type: UserLogin.SUCCESS;
      payload: AxiosResponse<IApiLoginEntity>;
    }
  | {
      type: UserLogin.FAILED;
      payload: AxiosError;
    };

export type TUserTokenLoginAction = {
  type: UserTokenLogin.LOGIN_WITH_TOKEN;
  payload: IClient;
};

export type TUserLogOutAcion = {
  type: UserLogOut.USER_LOG_OUT;
};

export type TEditUsernameAction = {
  type: LoggedUser.EDIT_USERNAME;
  payload: string;
};

export type TEditNameSurnameAction = {
  type: LoggedUser.EDIT_NAME_SURNAME;
  payload: {
    firstName: string;
    lastName: string;
  };
};

export type TUserGetAvatarAction =
  | {
      type: UserGetAvatar.REQUEST;
    }
  | {
      type: UserGetAvatar.SUCCESS;
      payload: AxiosResponse<File>;
    }
  | {
      type: UserGetAvatar.FAILED;
      payload: AxiosError;
    };

export type TUserActions =
  | TUserLoginAction
  | TUserTokenLoginAction
  | TUserLogOutAcion
  | TEditUsernameAction
  | TEditNameSurnameAction
  | TUserGetAvatarAction
  | TResetAction;
