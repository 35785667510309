import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormCheckbox from '../../Form/FormCheckbox';
import Form from 'react-bootstrap/Form';
import FormErrorText from '../../Form/FormErrorText';
import FormSelect from '../../Form/FormSelect';
import FormInput from '../../Form/FormInput';
import { IReservationSettings } from '../../../types/ReservationSettings';
import { TUserState } from '../../../types/User';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store/Store';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import { IFormError } from '../../../constants/Interfaces';
import { getCompanyById } from '../../../actions/CompaniesActions';
import { TCompanyState } from '../../../types/Companies';
import './SettingsOnlineForm.scss';

interface ISettingsOnlineForm {
  setValue?: (name: string, value: any) => void;
  submitFailed?: boolean;
  errors?: Record<string, any>;
  values?: IReservationSettings;
}

const futureDaysOptions = [5, 10, 15, 20, 25, 30, 60, 90];

const SettingsOnlineForm: React.FC<ISettingsOnlineForm> = ({
  values,
  setValue,
  errors,
  submitFailed,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [daysInFuture, setDaysInFuture] = useState(futureDaysOptions);
  const hasErrors = errors ? Object.keys(errors).includes('calendarTabErrors') : false;
  const hostName = window.location.origin;
  const intervalOptions = [
    { id: 5, name: t('settings_interval_5_min') },
    { id: 15, name: t('settings_interval_15_min') },
    { id: 30, name: t('settings_interval_30_min') },
    { id: 60, name: t('settings_interval_1_hour') },
    { id: 90, name: t('settings_interval_1_hour_30min') },
    { id: 120, name: t('settings_interval_2_hour') },
  ];

  useError(error, setError);

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );
  const companyState: TCompanyState = useSelector<IAppState, TCompanyState>(
    (state: IAppState) => state.companyState,
  );

  useEffect(() => {
    if (
      companyState.userCompany?.maxReservationTimeInFuture === 365 &&
      !daysInFuture.includes(365)
    ) {
      setDaysInFuture(prev => [...prev, 365]);
    }
  }, [companyState.userCompany]);

  useEffect(() => {
    (async () => {
      if (userState.data) {
        if (userState.data.companyId) {
          try {
            await dispatch(getCompanyById(userState.data.companyId));
          } catch (err) {
            handleError(
              err?.response?.status,
              setError,
              err.response?.data?.title,
              err.response?.data?.CausedByField,
            );
          }
        }
      }
    })();
  }, [dispatch, userState.data]);

  useEffect(() => {
    if (!values?.webReservationCalendarInterval) {
      setValue?.('webReservationCalendarInterval', '15');
    }

    if (!values?.maxReservationTimeInFuture) {
      setValue?.('maxReservationTimeInFuture', '5');
    }
  }, [values]);

  const minHoursBeforeOptions = [
    { id: 0, name: t('twig_time_short_hours_0') },
    { id: 1 * 60, name: t('twig_time_short_hours_1') },
    { id: 2 * 60, name: t('twig_time_short_hours_2') },
    { id: 4 * 60, name: t('twig_time_short_hours_4') },
    { id: 5 * 60, name: t('twig_time_short_hours_5') },
    { id: 12 * 60, name: t('twig_time_short_hours_12') },
    { id: 24 * 60, name: t('twig_time_short_hours_24') },
    { id: 48 * 60, name: t('twig_time_short_hours_48') },
    { id: 72 * 60, name: t('twig_time_short_hours_72') },
  ];
  return (
    <Row>
      <Col xs={12}>
        <div className='mb-3'>
          <Form.Label className='d-block'>
            {t('settings_form_label_salon_url')}
          </Form.Label>
          {companyState.userCompany && (
            <a
              href={`${hostName}/experience/${companyState.userCompany.accessKey}/services`}
              className='link--blue'
            >
              {`${hostName}/experience/${companyState.userCompany.accessKey}/services`}
            </a>
          )}
        </div>
      </Col>

      <Col xs={12}>
        <FormSelect
          hasEmptyOption={false}
          label={t('web_reservation_minimal_time_before_reservation') + ' *'}
          emptyOptionLabel=''
          name='webReservationMinimalTimeBeforeReservation'
          options={minHoursBeforeOptions}
          optionValue='id'
          optionDisplay='name'
          size='sm'
        />
        {hasErrors && submitFailed && <FormErrorText errorText={t('required')} />}
      </Col>
      <Col xs={12}>
        <FormCheckbox
          name='webReservationNotification'
          label={t('web_reservation_notification')}
          id='webReservationNotification'
        />
      </Col>
      <Col xs={12}>
        <FormCheckbox
          name='webReservationNotificationForEmployees'
          label={t('web_reservation_notification_for_employees')}
          id='webReservationNotificationForEmployees'
        />
      </Col>

      <Col xs={12}>
        <FormInput
          size='sm'
          label={t('web_reservation_notification_email') + ' *'}
          name='webReservationNotificationEmail'
          type='email'
        />
      </Col>

      <Col xs={12}>
        <FormSelect
          hasEmptyOption={false}
          label={t('settings_form_label_reservation_time_to_service_start')}
          emptyOptionLabel=''
          name='maxReservationTimeInFuture'
          options={daysInFuture.map(d => ({ id: d, val: d }))}
          optionValue='id'
          optionDisplay='val'
          inputClass='mb-1'
          size='sm'
        />
        <div className='mb-3'>
          {t('settings_form_label_reservation_time_in_future_after')}
        </div>
      </Col>

      <Col xs={12}>
        <FormSelect
          hasEmptyOption={false}
          label={t('settings_interval_label_name')}
          emptyOptionLabel=''
          name='webReservationCalendarInterval'
          options={intervalOptions}
          optionValue='id'
          optionDisplay='name'
          inputClass='mb-0'
          size='sm'
        />
      </Col>
    </Row>
  );
};

export default SettingsOnlineForm;
