import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

interface ICustomMenu {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  currentValue: string;
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectedOption: (company: string) => void;
}

// eslint-disable-next-line react/display-name
const DropdownMenu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      currentValue,
      setCurrentValue,
      setSelectedOption,
    }: ICustomMenu,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        id='table-dropdown-input'
        aria-labelledby='aria-labelledby'
      >
        <Form.Control
          autoFocus
          className='mx-3 my-2 w-auto'
          placeholder={t('filter_dropdown_input')}
          onChange={e => {
            setCurrentValue(e.target.value);
            setSelectedOption(e.target.value);
          }}
          value={currentValue}
        />
        <ul className='list-unstyled'>
          {React.Children.toArray(children).filter(
            (child: any) =>
              !currentValue ||
              child.props.children.toLowerCase().includes(currentValue.toLowerCase()),
          )}
        </ul>
      </div>
    );
  },
);

export default DropdownMenu;
