import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { EXPERIENCE_LANGUAGES } from 'constants/Experience';
import { EXPERIENCE_LANG_ITEM } from 'constants/Storage';
import { ROUTES } from 'constants/Routes';
import Row from './Row';
import TitleAddress from './TitleAddress';
import './PageTitle.scss';
import { useSelector } from 'react-redux';
import { IExperienceWidgetState } from 'types/ExperienceWidget';
import { IAppState } from 'store/Store';

interface IPageTitle {
  title: string;
  disableLangSelect?: boolean;
}

const PageTitle = ({ title, disableLangSelect }: IPageTitle) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [showAddress, setShowAddress] = useState(false);

  const widgetState: IExperienceWidgetState = useSelector<
    IAppState,
    IExperienceWidgetState
  >((state: IAppState) => state.experienceWidgetState);

  const handleLanguageChange = (language: string) => {
    const current = url.split('/').slice(1);
    localStorage.setItem(EXPERIENCE_LANG_ITEM, language);
    history.push(`${ROUTES.EXPERIENCE.MAIN}/${language}/${current.slice(2).join('/')}`);
  };

  useEffect(() => {
    url.includes('services') ? setShowAddress(false) : setShowAddress(true);
  }, [url]);

  return (
    <div className='experience__header'>
      <Row>
        <h2 className='experience__page-title'>{title}</h2>
        {!disableLangSelect && (
          <select
            className='experience__locale-select'
            name='lang'
            id='lang'
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleLanguageChange(e.target.value)
            }
            value={localStorage.getItem(EXPERIENCE_LANG_ITEM) || 'lt'}
          >
            {EXPERIENCE_LANGUAGES.map(lang => (
              <option key={lang} value={lang}>
                {lang.toUpperCase()}
              </option>
            ))}
          </select>
        )}
      </Row>
      {showAddress && widgetState.location && (
        <TitleAddress
          addressText={`${widgetState.location?.address}, ${widgetState.location?.city}`}
        />
      )}
    </div>
  );
};

export default PageTitle;
