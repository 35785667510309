import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  TVacationsActions,
  IVacationState,
  initialVacationState,
  VacationsGet,
  VacationDelete,
  VacationAdd,
  VacationEdit,
  VacationsPut,
  IVacation,
} from '../types/Vacations';

export const vacationsReducer: Reducer<IVacationState, TVacationsActions> = (
  state = initialVacationState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialVacationState;
    }
    case VacationEdit.REQUEST:
    case VacationAdd.REQUEST:
    case VacationDelete.REQUEST:
    case VacationsGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case VacationEdit.FAILED:
    case VacationAdd.FAILED:
    case VacationDelete.FAILED:
    case VacationsGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case VacationsPut.VACATIONS_OFFSET_UPDATE: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    case VacationDelete.DELETE_COMPANY_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.filter((rec: IVacation) => rec.id !== action.payload)
          : [],
        total: state.total ? state.total - 1 : 0,
      };
    }
    case VacationAdd.ADD_VACATION_STORE: {
      return {
        ...state,
        data: state.data ? [action.payload, ...state?.data] : [action.payload],
      };
    }
    case VacationEdit.EDIT_VACATION_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.map(rec => (rec.id === action.payload.id ? action.payload : rec))
          : [],
      };
    }
    case VacationEdit.SUCCESS:
    case VacationAdd.SUCCESS:
    case VacationDelete.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case VacationsGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isLoading: false,
        total: action.payload.data.total,
      };
    }
    default:
      return state;
  }
};
