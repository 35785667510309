import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import {
  IGetReservations,
  IReservation,
  IReservationWithInfo,
  ReservationStatus,
} from '../../../types/Reservations';
import { handleError } from '../../../middlewares/ErrorHandler';
import { getAllReservationsByStatus } from '../../../actions/ReservationsActions';
import TableSortable from '../../../components/Utils/Tables/TableSortable';
import useError from '../../../hooks/useError';
import { IClient } from '../../../types/Clients';
import { IEmployee } from '../../../types/Employees';
import { IServices } from '../../../types/Services';
import { getAllServices } from '../../../actions/ServicesActions';
import { getAllEmployees } from '../../../actions/EmployeesActions';
import { IResource } from '../../../types/Resources';
import { getAllResources } from '../../../actions/ResourcesActions';
import { IFormError } from '../../../constants/Interfaces';
import styles from './ReservationsTable.module.scss';
import useGetDataForRedux from 'hooks/useGetDataForRedux';
import { useStoreState } from 'hooks/useStoreState';
import PaginationComp from 'components/Utils/Tables/PaginationComp';
const PAGE_SIZE = 10;

const formateTableData = (
  reservationData: Array<IReservation>,
  employeeData: Array<IEmployee>,
  serviceData: Array<IServices>,
  resourceData: Array<IResource>,
) => {
  const newTableData = [];
  for (let i = 0; i < reservationData.length; i++) {
    const reservation = reservationData[i];
    const service = serviceData.find(ser => ser.id === reservation.serviceId);
    const employee = employeeData.find(emp => emp.id === reservation.userId);
    const creator = employeeData.find(emp => emp.id === reservation.creatorId);
    const employeeName = `${employee?.name || ''}  ${employee?.lastName || ''}`;
    const creatorName = `${creator?.name || ''}  ${creator?.lastName || ''}`;

    newTableData.push({
      ...reservation,
      serviceName: service?.name || '',
      serviceResources: service?.resourceIds?.map(
        resId => resourceData.find(item => item.id === resId)?.name || '',
      ),
      servicePricing:
        service?.pricings.find(pr => pr.id === reservation.servicePricingId) || {},
      timeAfterService: service?.timeAfterService || 0,
      preparingTime: service?.preparingTime || 0,
      employee: employeeName,
      creator: creatorName,
      reservationDate: moment(reservation.start).format('dddd, DD MMMM YYYY'),
    });
  }
  return newTableData;
};
interface IReservationsTable {
  clientToEdit?: IClient;
  setReservationToEdit: (reservation: IReservationWithInfo) => void;
  setReservationInfoModalOpen: (open: boolean) => void;
}

const ReservationsTable: React.FC<IReservationsTable> = ({
  clientToEdit,
  setReservationToEdit,
  setReservationInfoModalOpen,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const reservationsState = useStoreState().reservationsState;
  const employeeState = useStoreState().employeeState;
  const servicesState = useStoreState().servicesState;
  const resourceState = useStoreState().resourcesState;

  useGetDataForRedux(setError, servicesState.allData, getAllServices());
  useGetDataForRedux(setError, resourceState.allData, getAllResources());
  useGetDataForRedux(setError, employeeState.allData, getAllEmployees());

  const [paginations, setPaginations] = useState<Record<string, number>>({
    [ReservationStatus.CANCELLED]: 0,
    [ReservationStatus.CONFIRMED]: 0,
    [ReservationStatus.FULFILLED]: 0,
  });

  const [tableCount, setTablesCount] = useState<Record<string, number>>({
    [ReservationStatus.CANCELLED]: 0,
    [ReservationStatus.CONFIRMED]: 0,
    [ReservationStatus.FULFILLED]: 0,
  });

  const [tableData, setTableData] = useState<Record<string, IReservation[]>>({
    [ReservationStatus.CANCELLED]: [],
    [ReservationStatus.CONFIRMED]: [],
    [ReservationStatus.FULFILLED]: [],
  });

  const isReadyToFetchReservations = useMemo(() => {
    return (
      !!clientToEdit &&
      servicesState.allData &&
      employeeState.allData &&
      resourceState.allData &&
      dispatch
    );
  }, [
    servicesState.allData,
    employeeState.allData,
    resourceState.allData,
    clientToEdit,
    dispatch,
  ]);

  const getReservationByStatus = useCallback(
    (page: number, status: ReservationStatus, sort?: string) => {
      if (!isReadyToFetchReservations) return;

      (async () => {
        try {
          const response: any = await dispatch(
            getAllReservationsByStatus({
              offset: page * PAGE_SIZE,
              limit: PAGE_SIZE,
              status,
              clientId: clientToEdit?.id,
              sort: sort,
            }),
          );
          const data: IGetReservations = response.data;

          setTableData(prev => ({
            ...prev,
            [status]: formateTableData(
              data.data,
              employeeState.allData || [],
              servicesState.allData || [],
              resourceState.allData || [],
            ),
          }));

          setTablesCount(prev => ({
            ...prev,
            [status]: data.total || 0,
          }));
        } catch (err) {
          handleError(err?.response?.status, setError, err.response?.data?.title);
        }
      })();
    },
    [isReadyToFetchReservations],
  );

  useEffect(() => {
    getReservationByStatus(
      paginations[ReservationStatus.CANCELLED],
      ReservationStatus.CANCELLED,
      'start desc',
    );
  }, [isReadyToFetchReservations, paginations[ReservationStatus.CANCELLED]]);

  useEffect(() => {
    getReservationByStatus(
      paginations[ReservationStatus.CONFIRMED],
      ReservationStatus.CONFIRMED,
      'start asc',
    );
  }, [isReadyToFetchReservations, paginations[ReservationStatus.CONFIRMED]]);

  useEffect(() => {
    getReservationByStatus(
      paginations[ReservationStatus.FULFILLED],
      ReservationStatus.FULFILLED,
      'start desc',
    );
  }, [isReadyToFetchReservations, paginations[ReservationStatus.FULFILLED]]);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('client_table_col_service_name')}`,
        accessor: 'serviceName',
        id: 'serviceName',
        disableSortBy: true,
      },
      {
        Header: `${t('client_table_col_reservation_date')}`,
        accessor: 'reservationDate',
        id: 'reservationDate',
        disableSortBy: true,
        Cell: (cell: any) => {
          const weekday = cell.value.split(',')[0];
          const date = cell.value.split(', ')[1];
          const day = date.split(' ')[0];
          const month = date.split(' ')[1];
          const year = date.split(' ')[2];
          const monthTranslated = t(
            `employee_calendar_month_genitive_${moment().month(month).format('MM')}`,
          );
          return `${t(weekday)}, ${day} ${monthTranslated} ${year}`;
        },
      },
      {
        Header: `${t('client_table_col_reservation_time')}`,
        accessor: 'duration',
        id: 'duration',
        disableSortBy: true,
      },
      {
        Header: `${t('client_table_col_user')}`,
        accessor: 'employee',
        id: 'employee',
        disableSortBy: true,
      },
      {
        Header: `${t('client_table_col_price')}`,
        accessor: 'price',
        id: 'price',
        disableSortBy: true,
        Cell: (cell: any) => cell.value.toFixed(2),
      },
    ],
    [tableData],
  );

  const getMaxPages = (key: ReservationStatus) => {
    return Array.from(Array(Math.ceil((tableCount[key] || 0) / PAGE_SIZE)).keys());
  };

  const handleSort = () => {
    return null;
  };

  const isLoading =
    reservationsState.isLoading || employeeState.isLoading || servicesState.isLoading;

  const allFormattedData = useMemo(
    () => [
      {
        title: t('client_confirmed_reservations'),
        key: ReservationStatus.CONFIRMED,
        emptyMessage: t('client_confirmed_reservations'),
      },
      {
        title: t('client_fulfilled_reservations'),
        key: ReservationStatus.FULFILLED,
        emptyMessage: t('client_fulfilled_reservations'),
      },
      {
        title: t('client_cancelled_reservations'),
        key: ReservationStatus.CANCELLED,
        emptyMessage: t('client_cancelled_reservations'),
      },
    ],
    [tableData],
  );

  return (
    <Container fluid className={`p-0 ${styles.reservations}`}>
      {allFormattedData.map(({ title, key, emptyMessage }) => {
        const data =
          ReservationStatus.CONFIRMED === key
            ? tableData[key].sort((a, b) => {
                return new Date(a.start).getTime() - new Date(b.start).getTime();
              })
            : tableData[key];
        return (
          <Row key={key}>
            <Col xs={12} className='p-0'>
              <Card.Body className='pt-0'>
                <p className={styles.reservationCategory}>{title}</p>
                {data.length > 0 && (
                  <TableSortable
                    striped
                    data={data}
                    columns={columns}
                    onSort={handleSort}
                    offset={reservationsState.offset}
                    selectRow={setReservationToEdit}
                    openModal={setReservationInfoModalOpen}
                    isLoading={isLoading}
                  />
                )}

                {data.length > 0 && getMaxPages(key).length > 1 && (
                  <PaginationComp
                    activePage={paginations[key]}
                    pages={getMaxPages(key)}
                    changePage={(page: number) =>
                      setPaginations(prev => ({ ...prev, [key]: page }))
                    }
                  />
                )}

                {!data.length && (
                  <div className={styles.reservationsEmpty}>{emptyMessage}</div>
                )}
              </Card.Body>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default ReservationsTable;
