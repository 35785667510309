import { ROUTES } from 'constants/Routes';
import React, { memo } from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className='footer__wrapper'>
      <div className='landing__footer'>
        <div className='landing__footer__terms-privacy-wrapper d-flex flex-column align-items-center'>
          <a href={ROUTES.TERMS} rel='noreferrer'>
            {t('terms_conditions')}
          </a>
          <a href={ROUTES.POLICY} rel='noreferrer'>
            {t('privacy_policy')}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
