import { Reducer } from 'redux';
import {
  initialUserState,
  TUserActions,
  UserLogin,
  UserTokenLogin,
  UserLogOut,
  UserGetAvatar,
  LoggedUser,
} from '../types/User';
import { ResetStore } from '../types/App';
import { setAccessToken, setRefreshToken, parseUserData } from '../helpers/Misc';

// TODO: any --> TUserState
export const usersReducer: Reducer<any, TUserActions> = (
  state = initialUserState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialUserState;
    }

    case UserLogin.REQUEST:
    case UserGetAvatar.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UserLogin.SUCCESS: {
      const { data } = action.payload;
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
      return {
        ...state,
        data: parseUserData(data.access_token),
        isLoading: false,
      };
    }
    case UserTokenLogin.LOGIN_WITH_TOKEN: {
      const data = action.payload;
      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case UserLogOut.USER_LOG_OUT: {
      return {};
    }
    case UserLogin.FAILED:
    case UserGetAvatar.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case UserGetAvatar.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        avatar: action.payload.data || null,
      };
    }

    case LoggedUser.EDIT_USERNAME: {
      return {
        ...state,
        data: {
          ...state.data,
          username: action.payload,
        },
      };
    }

    case LoggedUser.EDIT_NAME_SURNAME: {
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      };
    }

    default:
      return state;
  }
};
