import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceTable from './ServiceTable';
import LabelCell from '../../Utils/Tables/LabelCell';
import { IServices } from '../../../types/Services';
import { formatCurrency, getInitialPrice } from 'helpers/Currency';
import LabelToCopy from 'components/Utils/Tables/LabelToCopy';
import { useStoreState } from 'hooks/useStoreState';
import { IFormError } from 'constants/Interfaces';
import useError from 'hooks/useError';

interface IServiceTableWrapper {
  data: IServices[];
  isLoading: boolean;
  handleServiceModalOpen: (groupId?: string, service?: IServices) => void;
}

const createExpendedRowsState = (data: IServices[]) => {
  const obj: Record<string, boolean> = {};
  for (let i = 0; i < data.length; i++) {
    obj[i] = true;
  }
  return obj;
};

const ServiceTableWrapper: React.FC<IServiceTableWrapper> = ({
  data,
  isLoading,
  handleServiceModalOpen,
}) => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>(
    createExpendedRowsState(data),
  );
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const { t } = useTranslation();
  const { reservationSettingsState } = useStoreState();

  useEffect(() => {
    setExpandedRows(createExpendedRowsState(data));
  }, [data]);

  const formateHours = useCallback(
    (time: number) => {
      const hours = Math.floor(time / 60);
      if (hours) {
        const remainingMinutes = time - hours * 60;
        return remainingMinutes
          ? `${hours} ${t('twig_time_short_hours')} ${remainingMinutes} ${t(
              'report_services_duration_minutes_short',
            )}`
          : `${hours} ${t('twig_time_short_hours')}`;
      } else {
        return `${time} ${t('report_services_duration_minutes_short')}`;
      }
    },
    [t],
  );

  const columns = React.useMemo(
    () => [
      {
        accessor: 'code',
        id: 'code',
      },
      {
        accessor: 'name',
        id: 'name',
      },
      {
        accessor: 'singlePriceDuration',
        id: 'singlePriceDuration',
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) =>
          cell.row.original.pricings.length > 1 ? (
            ''
          ) : (
            <span>{formateHours(cell.value)}</span>
          ),
      },
      {
        accessor: 'singlePricePrice',
        id: 'singlePricePrice',
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) =>
          cell.row.original.pricings.length > 1 ? (
            ''
          ) : (
            <span>{`${cell.value} ${formatCurrency(
              reservationSettingsState?.data?.currency,
            )}`}</span>
          ),
      },
      {
        accessor: 'locationNames',
        id: 'locationNames',
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => <LabelCell cellData={cell.value} />,
      },
      {
        accessor: 'singlePriceId',
        id: 'singlePriceId',
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) =>
          cell.row.original.pricings.length > 1 ? (
            ''
          ) : (
            <LabelToCopy cellData={cell.value}></LabelToCopy>
          ),
      },
      {
        accessor: 'hiddenInPlugin',
        id: 'hiddenInPlugin',
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) =>
          cell.row.original.hiddenInPlugin ? (
            <span className='overflow-ellipsis'>{t('hidden_in_plugin')}</span>
          ) : (
            ''
          ),
      },
    ],
    [],
  );

  const renderRowSubComponent = React.useCallback(
    row => (
      <>
        {row.original.pricings.length <= 1
          ? null
          : row.original.pricings.map((p: any) => (
              <tr key={p.id} className={row.original.hiddenInPlugin ? 'opacity-50' : ''}>
                <td />
                <td>{p.name}</td>
                <td>{formateHours(p.duration)}</td>
                <td>
                  {getInitialPrice(p?.salePrice, p.price)}
                  {formatCurrency(reservationSettingsState?.data?.currency)}
                </td>
                <td colSpan={2} />
                <td>
                  <LabelToCopy cellData={p.id}></LabelToCopy>
                </td>
                <td />
              </tr>
            ))}
      </>
    ),
    [],
  );

  return (
    <ServiceTable
      data={data}
      expanded={expandedRows}
      columns={columns}
      id='group-table'
      renderRowSubComponent={renderRowSubComponent}
      handleServiceModalOpen={handleServiceModalOpen}
      isLoading={isLoading}
    />
  );
};

export default ServiceTableWrapper;
