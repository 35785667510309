import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import clsx from 'clsx';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { useTable, useSortBy, Column, TableInstance } from 'react-table';
import { ISort } from '../../../constants/Interfaces';
import { ICustomWorkTime } from '../../../types/CustomWorkTime';
import { useTranslation } from 'react-i18next';
import '../../Utils/Tables/TableSortable.scss';
import { IFilterData } from 'pages/admin/AdminWorkTime';
import moment from 'moment';

type Data = object;

interface ITableSortable {
  columns: Column<Data>[];
  data: ICustomWorkTime[];
  onSort?: (sortObj: ISort[], offset: number) => void;
  customWidth?: string;
  applyCustomWidthTo?: string[];
  striped?: boolean;
  selectCell?: (value: any) => void;
  openModal?: (open: boolean) => void;
  isLoading?: boolean;
  offset?: number;
  filters: IFilterData;
}

export const CustomWorkTimeTable: React.FC<ITableSortable> = ({
  data,
  columns,
  onSort,
  customWidth,
  applyCustomWidthTo,
  striped,
  selectCell,
  openModal,
  isLoading,
  offset,
  filters,
}) => {
  const { year, month } = filters;
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable<Data>(
    {
      columns,
      data,
      manualSortBy: true,
    },
    useSortBy,
  ) as TableInstance<object>;

  useEffect(() => {
    if (sortBy.length > 0 && onSort) {
      onSort(sortBy, offset || 0);
    }
  }, [onSort, sortBy]);

  if (!data.length) {
    return <div>{t('employee_calendar_no_data_found')}</div>;
  }

  return (
    <Table
      {...getTableProps()}
      striped={striped}
      hover
      responsive='md'
      size='sm'
      className='minTable'
    >
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} key={uuidv4()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={column.id}
                className={clsx(
                  column.id === 'name' || column.id === 'id' ? 'wideColumn' : '',
                  !column.disableSortBy && 'cursor-pointer',
                )}
                style={{
                  width:
                    customWidth &&
                    applyCustomWidthTo &&
                    applyCustomWidthTo.includes(column.id)
                      ? customWidth
                      : '',
                }}
              >
                {column?.Header}
                {!column.isSorted ? null : column.isSorted && column.isSortedDesc ? (
                  <MdArrowDownward />
                ) : (
                  <MdArrowUpward />
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {isLoading ? (
          <tr>
            <td />
            <td>
              <div>
                <Spinner animation='border' variant='primary' />
              </div>
            </td>
          </tr>
        ) : (
          rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell: any, index: number) => (
                  <td
                    {...cell.getCellProps()}
                    key={cell.getCellProps().key}
                    className='table-cells'
                    onClick={() => {
                      const momentDate = moment(
                        `${year}-${month}-${('' + index).padStart(2, '0')}`,
                      );
                      const key = momentDate.format('MM-DD');

                      selectCell?.(cell.value[key]);
                      openModal?.(!!cell.value[key]);
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
};
