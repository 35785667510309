import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import Toast from '../../Utils/Toast';
import { TUserState } from '../../../types/User';
import { IAppState } from '../../../store/Store';
import ModalForm from './CustomWorkTimeModalForm';
import {
  ICustomWorkTime,
  ICustomWorkTimeBreak,
  ICustomWorkTimeData,
  ICustomWorkTimeTableData,
} from '../../../types/CustomWorkTime';
import {
  addCustomWorkTime,
  editCustomWorkTime,
} from '../../../actions/CustomWorkTimeActions';
import { IFormError } from '../../../constants/Interfaces';
import FormModalExtended from '../../Utils/Modals/FormModalExtended';
import { v4 as uuidv4 } from 'uuid';
const moment = extendMoment(Moment as any);

interface ICustomWorkTimeAddModal {
  show: boolean;
  handleClose: () => void;
  customWorkTimeToEdit?: any;
  setCustomWorkTimeToEdit: React.Dispatch<
    React.SetStateAction<ICustomWorkTime | undefined>
  >;
}

const CustomWorkTimeAddModal: React.FC<ICustomWorkTimeAddModal> = ({
  show,
  handleClose,
  customWorkTimeToEdit,
  setCustomWorkTimeToEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [formattedCustomWorkTime, setFormattedCustomWorkTime] = useState<any>();
  const [isLoading, setLoading] = useState(false);

  useError(error, setError);
  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  const WEEKDAYS_LONG = [
    t('form_work_time_weekday_0'),
    t('form_work_time_weekday_1'),
    t('form_work_time_weekday_2'),
    t('form_work_time_weekday_3'),
    t('form_work_time_weekday_4'),
    t('form_work_time_weekday_5'),
    t('form_work_time_weekday_6'),
  ];

  const MONTHS = [
    t('employee_calendar_month_genitive_01'),
    t('employee_calendar_month_genitive_02'),
    t('employee_calendar_month_genitive_03'),
    t('employee_calendar_month_genitive_04'),
    t('employee_calendar_month_genitive_05'),
    t('employee_calendar_month_genitive_06'),
    t('employee_calendar_month_genitive_07'),
    t('employee_calendar_month_genitive_08'),
    t('employee_calendar_month_genitive_09'),
    t('employee_calendar_month_genitive_10'),
    t('employee_calendar_month_genitive_11'),
    t('employee_calendar_month_genitive_12'),
  ];

  const getFormattedDate = (_date: string) => {
    const date = moment(_date);
    return (
      WEEKDAYS_LONG[date.get('day')] + // get day name
      ', ' +
      MONTHS[date.get('M')] + // month name
      ' ' +
      date.get('D') + // day number
      ' d.'
    );
  };

  useEffect(() => {
    if (customWorkTimeToEdit) {
      const {
        enabled,
        workTime: { start, end, id },
        day,
        employee,
        location,
        workTimeBreaks,
      } = customWorkTimeToEdit;
      setFormattedCustomWorkTime({
        id,
        day,
        formattedDate: getFormattedDate(day),
        enabled,
        startHour: start ? start.split(':')[0] : '08',
        startMin: start ? start.split(':')[1] : '00',
        endHour: end ? end.split(':')[0] : '17',
        endMin: end ? end.split(':')[1] : '00',
        breaks:
          workTimeBreaks.map(({ id, end, start }: ICustomWorkTimeBreak) => ({
            id,
            startHour: start ? start.split(':')[0] : '00',
            startMin: start ? start.split(':')[1] : '00',
            endHour: end ? end.split(':')[0] : '00',
            endMin: end ? end.split(':')[1] : '00',
          })) || [],
        employee,
        location,
      });
    }
  }, [customWorkTimeToEdit]);

  const checkBreaksOverlapping = (timeSegments: Array<string[]>) => {
    let result = false;
    let i = 0;
    while (!result && i < timeSegments.length - 1) {
      const seg1 = timeSegments[i];
      const seg2 = timeSegments[i + 1];
      const range1 = moment.range(moment(seg1[0], 'HH:mm'), moment(seg1[1], 'HH:mm'));
      const range2 = moment.range(moment(seg2[0], 'HH:mm'), moment(seg2[1], 'HH:mm'));
      if (range1.overlaps(range2)) {
        result = true;
      }
      i++;
      return result;
    }
  };

  const formValidation = (values: ICustomWorkTimeTableData) => {
    const workTimeErrors: Record<string, any> = {};

    const { breaks = [], startHour, startMin, endHour, endMin } = values;
    if (`${endHour}${endMin}` <= `${startHour}${startMin}`) {
      workTimeErrors['timeRange'] = t('validators_date_time_range');
    }

    const breaksTimeRanges = breaks.map(breakItem => [
      `${breakItem.startHour}:${breakItem.startMin}:`,
      `${breakItem.endHour}:${breakItem.endMin}:`,
    ]);

    if (checkBreaksOverlapping(breaksTimeRanges)) {
      workTimeErrors['breakOverlap'] = t('validators_break_overlaps');
    }

    breaks.forEach(
      (
        {
          startHour: breakStartHour,
          startMin: breakStartMin,
          endHour: breakEndHour,
          endMin: breakEndMin,
        },
        index,
      ) => {
        const timeRangeError =
          `${breakEndHour}${breakEndMin}` <= `${breakStartHour}${breakStartMin}`;
        const inBoundsError =
          `${breakStartHour}${breakStartMin}` < `${startHour}${startMin}` ||
          `${breakEndHour}${breakEndMin}` > `${endHour}${endMin}`;
        if (timeRangeError || inBoundsError) {
          if (!workTimeErrors['customWorkBreakErrors']) {
            workTimeErrors['customWorkBreakErrors'] = {};
          }
          workTimeErrors['customWorkBreakErrors']['break-' + index] = timeRangeError
            ? t('validators_date_time_range')
            : t('validators_break_is_in_bounds');
        }
      },
    );

    return { workTimeErrors };
  };

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const handleSubmit = async (customWorkTimeData: ICustomWorkTimeTableData) => {
    const {
      enabled,
      breaks,
      startHour,
      startMin,
      endHour,
      endMin,
      employee,
      day,
      location,
    } = customWorkTimeData;
    if (!isLoading) {
      const newCustomWorkTimeObjs: ICustomWorkTimeData = {
        userId: employee.id,
        locationId: location.id,
        day: day,
        companyId: userState.data?.companyId || '',
        enabled: enabled,
        start: `${startHour}:${startMin}`,
        end: `${endHour}:${endMin}`,
        // If you delete all breaks, form value will return undefined instead of empty array
        customWorkTimeBreaks: (breaks || []).map(
          ({ id, endHour, endMin, startHour, startMin }) => ({
            id: id || uuidv4(),
            start: `${startHour}:${startMin}`,
            end: `${endHour}:${endMin}`,
          }),
        ),
      };
      try {
        setLoading(true);
        if (customWorkTimeToEdit.workTime.id) {
          await dispatch(
            editCustomWorkTime(newCustomWorkTimeObjs, customWorkTimeToEdit.workTime.id),
          );
          toast.success(<Toast text={t('editedWorkTime')} />);
        } else {
          const response: any = await dispatch(addCustomWorkTime(newCustomWorkTimeObjs));
          setCustomWorkTimeToEdit({
            ...customWorkTimeToEdit,
            workTime: { ...customWorkTimeToEdit.workTime, id: response.data },
            workTimeBreaks: newCustomWorkTimeObjs.customWorkTimeBreaks,
          });
          toast.success(<Toast text={t('addedWorkTime')} />);
        }
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
      setLoading(false);
    }
  };

  return (
    <FormModalExtended
      show={show}
      handleClose={handleClose}
      validation={formValidation}
      handleSubmit={handleSubmit}
      editValue={formattedCustomWorkTime}
      modalTitle={t('calendar_form_user_custom_work_time_title')}
      modalClass='custom-work-time-modal'
      size='lg'
      component={<ModalForm />}
    />
  );
};

export default CustomWorkTimeAddModal;
