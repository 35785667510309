import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';

interface IFormErrorText {
  errorText?: string;
}

const FormErrorText: React.FC<IFormErrorText> = ({ errorText }) => {
  return (
    <Form.Text className='text-danger' as='span'>
      <FaExclamationTriangle className='mr-1' />
      {errorText}
    </Form.Text>
  );
};

export default FormErrorText;
