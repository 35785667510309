import React from 'react';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../Form/FormSelect';
import { createTimeOptions } from '../../../helpers/FormHelpers';
import { IFormError } from '../../../constants/Interfaces';

interface ITimeSelectInput {
  name: string;
  label?: string;
  inputClass?: string;
  validation?: (value: any) => any;
  fieldError?: IFormError;
  disabled?: boolean;
}

const TimeSelectInput: React.FC<ITimeSelectInput> = ({
  name,
  label,
  inputClass,
  validation,
  fieldError,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <FormSelect
      label={label}
      size='sm'
      hasEmptyOption
      emptyOptionLabel={t('service_form_duration_placeholder')}
      name={name}
      inputClass={inputClass}
      options={createTimeOptions(
        t('report_services_duration_minutes_short'),
        t('twig_time_short_hours'),
        720,
      )}
      optionValue='value'
      optionDisplay='timeDisplay'
      validation={validation}
      fieldError={fieldError}
      disabled={disabled}
    />
  );
};

export default TimeSelectInput;
