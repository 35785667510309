import {
  TClientState,
  initialClientState,
  TClientsActions,
  ClientsGet,
  ClientDelete,
  ClientAdd,
  ClientEdit,
  ClientsPut,
  ClientsGetAll,
  ClientsImport,
} from '../types/Clients';
import { Reducer } from 'redux';
import { ResetStore } from 'types/App';

export const clientsReducer: Reducer<TClientState, TClientsActions> = (
  state = initialClientState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialClientState;
    }
    case ClientsImport.REQUEST: {
      return {
        ...state,
        isLoadingFileImport: true,
      };
    }
    case ClientsGetAll.REQUEST:
    case ClientEdit.REQUEST:
    case ClientAdd.REQUEST:
    case ClientDelete.REQUEST:
    case ClientsGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ClientsGetAll.FAILED:
    case ClientEdit.FAILED:
    case ClientAdd.FAILED:
    case ClientDelete.FAILED:
    case ClientsGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ClientsImport.FAILED: {
      return {
        ...state,
        isLoadingFileImport: false,
      };
    }
    case ClientsImport.SUCCESS: {
      return {
        ...state,
        isLoadingFileImport: false,
      };
    }
    case ClientsGetAll.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allData: action.payload.data.data,
      };
    }
    case ClientsPut.CLIENTS_OFFSET_UPDATE: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    case ClientDelete.DELETE_CLIENT_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.filter((rec: any) => rec.id !== action.payload)
          : [],
        allData: state.allData
          ? state.allData.filter((rec: any) => rec.id !== action.payload)
          : [],
        total: state.total ? state.total - 1 : 0,
      };
    }
    case ClientAdd.ADD_CLIENT_STORE: {
      return {
        ...state,
        data: state.data ? [action.payload, ...state?.data] : [action.payload],
        allData: state.allData ? [action.payload, ...state?.allData] : [action.payload],
      };
    }
    case ClientEdit.EDIT_CLIENT_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.map(rec => (rec.id === action.payload.id ? action.payload : rec))
          : [],
        allData: state.allData
          ? state.allData.map(rec =>
              rec.id === action.payload.id ? action.payload : rec,
            )
          : [],
      };
    }
    case ClientEdit.SUCCESS:
    case ClientAdd.SUCCESS:
    case ClientDelete.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ClientsGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isLoading: false,
        total: action.payload.data.total,
      };
    }
    default:
      return state;
  }
};
