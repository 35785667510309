import { ICompanyExperience } from 'experience/types/ExperienceCompany';
import { IReservationFormValues } from 'experience/types/ExperienceFormTypes';
import { ILocationExperience } from 'experience/types/ExperienceLocation';
import {
  IExperiencePricings,
  IServicesExperience,
} from 'experience/types/ExperienceServices';
import { ExperienceWidget } from 'types/ExperienceWidget';

export const setExperienceWidgetService = (
  service: IServicesExperience,
  pricing: IExperiencePricings,
) => {
  return {
    type: ExperienceWidget.CHANGE_SERVICE,
    payload: {
      service,
      pricing,
    },
  };
};

export const setExperienceWidgetLocation = (location: ILocationExperience) => {
  return {
    type: ExperienceWidget.CHANGE_LOCATION,
    payload: location,
  };
};

export const setExperienceWidgetClientInfo = (client: IReservationFormValues) => {
  return {
    type: ExperienceWidget.CHANGE_CLIENT_INFO,
    payload: client,
  };
};

export const setExperienceWidgetDateTimeUser = (
  date: Date,
  time: string,
  personCount: number,
  user: string | undefined,
) => {
  return {
    type: ExperienceWidget.CHANGE_DATE_TIME_USER,
    payload: {
      date,
      time,
      personCount,
      user,
    },
  };
};

export const setExperienceWidgetCompany = (company: ICompanyExperience) => {
  return {
    type: ExperienceWidget.CHANGE_COMPANY,
    payload: company,
  };
};
