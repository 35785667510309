import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  initialUserGetLocationState,
  IUserLocationState,
  TUserGetLocationAction,
  UserGetLocation,
} from 'experience/types/ExperienceUserLocation';

export const experienceUserLocationReducer: Reducer<
  IUserLocationState,
  TUserGetLocationAction
> = (state = initialUserGetLocationState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialUserGetLocationState;
    }

    case UserGetLocation.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UserGetLocation.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };

    case UserGetLocation.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
