import React from 'react';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoExitOutline } from 'react-icons/io5';
import { TiArrowBack } from 'react-icons/ti';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constants/Routes';
import ReactTooltip from 'react-tooltip';
import { COMPANY_ID_ITEM } from 'constants/Storage';
import i18next from 'config/i18next';

interface IPageHeader {
  sidebar: boolean;
  setSidebar: (value: boolean) => void;
  onLogout: () => void;
}

const PageHeader: React.FC<IPageHeader> = ({ sidebar, setSidebar, onLogout }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const returnToSuperAdmin = async () => {
    localStorage.removeItem(COMPANY_ID_ITEM);
    // super admin view is only in lithuanina
    await i18next.changeLanguage('lt');
    history.push(ROUTES.SUPER_ADMIN.COMPANIES);
  };

  return (
    <Row className='p-3 align-items-center'>
      <Col className='d-flex'>
        <Button onClick={() => setSidebar(!sidebar)} className='d-flex sidebarButton'>
          <GiHamburgerMenu />
        </Button>
        {localStorage.getItem(COMPANY_ID_ITEM) && (
          <>
            <Button
              onClick={returnToSuperAdmin}
              className='d-flex sidebarButton ml-2'
              data-tip
              data-for={`back-to-superadmin-tooltip`}
            >
              <TiArrowBack />
            </Button>
            <ReactTooltip id={`back-to-superadmin-tooltip`} place='top' effect='solid'>
              {t('back_to_administrator')}
            </ReactTooltip>
          </>
        )}
      </Col>
      <Col>
        <Button variant='none' className='float-right logoutButton' onClick={onLogout}>
          <IoExitOutline className='mr-2' />
          {t('header_user_logout_link')}
        </Button>
      </Col>
    </Row>
  );
};

export default PageHeader;
