import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

export interface IPricingModal {
  id: string;
  name?: string;
  duration: number;
  price: number;
  people?: number;
  personsCount?: number;
  salePrice?: number;
  hours?: string;
  minutes?: string;
  hoursAfterService?: string;
  minutesAfterService?: string;
}

export interface IServices {
  companyId: string;
  groupId: string;
  name: string;
  preparingTime?: number;
  timeAfterService?: number;
  userIds: Array<string>;
  locationIds: Array<string>;
  resourceIds?: Array<string>;
  resources?: Array<ServiceResource>;
  pricings: Array<IPricingModal>;
  id: string;
  code: number;
  creatorId: string;
  createdAt: string;
  updatedAt: string;
  hiddenInPlugin: boolean;
}

export interface IGetServices {
  data: Array<IServices>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

export interface IServiceResponseData {
  id: string;
  pricings: IPricingModal[];
}

export interface ServiceResource {
  id: string;
  count: number;
}

export interface IServiceData {
  name: string;
  locations: string[];
  locationIds: string[];
  userIds?: string[];
  pricings: IPricingModal[];
  companyId: string;
  groupId: string;
  preparingTime: number;
  timeAfterService: number;
  fixedPersonsCount: boolean;
  resourceIds: string[];
  hiddenInPlugin: boolean;
  resources: Array<ServiceResource>;
}

export interface TServicesState {
  readonly data?: Array<IServices>;
  readonly allData?: Array<IServices>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
}

export const initialServicesState: TServicesState = {
  data: undefined,
  allData: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
};

export enum ServicesGet {
  REQUEST = 'SERVICES_GET_REQUEST',
  SUCCESS = 'SERVICES_GET_SUCCESS',
  FAILED = 'SERVICES_GET_FAILED',
}

export enum ServicesGetAll {
  REQUEST = 'SERVICES_GET_ALL_REQUEST',
  SUCCESS = 'SERVICES_GET_ALL_SUCCESS',
  FAILED = 'SERVICES_GET_ALL_FAILED',
}

export enum ServicesAdd {
  REQUEST = 'SERVICES_ADD_REQUEST',
  SUCCESS = 'SERVICES_ADD_SUCCESS',
  FAILED = 'SERVICES_ADD_FAILED',
  ADD_SERVICES_STORE = 'SERVICES_ADD_TO_STORE',
}

export enum ServiceEdit {
  REQUEST = 'SERVICE_EDIT_REQUEST',
  SUCCESS = 'SERVICE_EDIT_SUCCESS',
  FAILED = 'SERVICE_EDIT_FAILED',
  EDIT_SERVICE_STORE = 'SERVICE_EDIT_ADD_TO_STORE',
}

export enum ServiceDelete {
  REQUEST = 'SERVICE_DELETE_REQUEST',
  SUCCESS = 'SERVICE_DELETE_SUCCESS',
  FAILED = 'SERVICE_DELETE_SUCCESS',
  DELETE_SERVICE_STORE = ' SERVICE_DELETE_FROM_STORE',
}

export type TServiceGroupDeleteAction =
  | {
      type: ServiceDelete.REQUEST;
    }
  | {
      type: ServiceDelete.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: ServiceDelete.DELETE_SERVICE_STORE;
      payload: string;
    }
  | {
      type: ServiceDelete.FAILED;
      payload: AxiosError;
    };

export type TServiceEditAction =
  | {
      type: ServiceEdit.REQUEST;
    }
  | {
      type: ServiceEdit.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: ServiceEdit.EDIT_SERVICE_STORE;
      payload: IServices;
    }
  | {
      type: ServiceEdit.FAILED;
      payload: AxiosError;
    };

export type TServicesAddAction =
  | {
      type: ServicesAdd.REQUEST;
    }
  | {
      type: ServicesAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: ServicesAdd.ADD_SERVICES_STORE;
      payload: IServices;
    }
  | {
      type: ServicesAdd.FAILED;
      payload: AxiosError;
    };

export type TServicesGetAllAction =
  | {
      type: ServicesGetAll.REQUEST;
    }
  | {
      type: ServicesGetAll.SUCCESS;
      payload: AxiosResponse<IGetServices>;
    }
  | {
      type: ServicesGetAll.FAILED;
      payload: AxiosError;
    };

export type TServicesGetAction =
  | {
      type: ServicesGet.REQUEST;
    }
  | {
      type: ServicesGet.SUCCESS;
      payload: AxiosResponse<IGetServices>;
    }
  | {
      type: ServicesGet.FAILED;
      payload: AxiosError;
    };

export type TServicesActions =
  | TServicesGetAllAction
  | TServicesGetAction
  | TServiceEditAction
  | TServiceGroupDeleteAction
  | TServicesAddAction
  | TResetAction;
