import React, { useState, useEffect } from 'react';
import * as Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FaRegEdit } from 'react-icons/all';
import Container from 'react-bootstrap/Container';
import { useHistory, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { ILocation } from 'types/Locations';
import { IFormError } from 'constants/Interfaces';
import { handleError } from 'middlewares/ErrorHandler';
import { getAllLocations } from 'actions/LocationActions';
import { getWorkTimeAll } from 'actions/WorkTimeActions';
import {
  getCustomWorkTimeAll,
  IGetCustomWorkTimeAll,
} from 'actions/CustomWorkTimeActions';
import { getAllEmployees } from 'actions/EmployeesActions';
import useError from 'hooks/useError';
import { IWorkTime, WeekDays } from 'types/WorkTime';
import { IEmployee } from 'types/Employees';
import { ROUTES } from 'constants/Routes';
import { useStoreState } from 'hooks/useStoreState';
import { CustomWorkTimeTable } from 'components/Admin/CustomWorkTime/CustomWorkTimeTable';
import {
  ICustomWorkTime,
  ICustomWorkTimeBreak,
  ICustomWorkTimeData,
} from 'types/CustomWorkTime';
import CustomWorkTimeFilters from 'components/Admin/CustomWorkTime/CustomWorkTimeFilters';
import WorkTimeAddModal from 'components/Admin/WorkTime/WorkTimeAddModal';
import CustomWorkTimeAddModal from 'components/Admin/CustomWorkTime/CustomWorkTimeAddModal';
import './AdminWorkTime.scss';
import { getVacations } from 'actions/VacationsActions';
import { IVacation } from 'types/Vacations';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const getDaysInMonth = function (month: string, year: string) {
  return new Date(+year, +month, 0).getDate();
};

const WEEKDAYS_NAMES = [
  WeekDays.MONDAY,
  WeekDays.TUESDAY,
  WeekDays.WEDNESDAY,
  WeekDays.THURSDAY,
  WeekDays.FRIDAY,
  WeekDays.SATURDAY,
  WeekDays.SUNDAY,
];

const checkIfHasVacation = (vacation: IVacation, filters: IFilterData) => {
  const { year, month } = filters;
  const locationHasVaction = vacation.locationIds.includes(filters.locationId);

  const workMonthStart = moment(`${year}-${month}-01`, 'YYYY-MM-DD');
  const workMonthEnd = moment(
    `${year}-${month}-${getDaysInMonth(month, year)}`,
    'YYYY-MM-DD',
  );

  const vacationStart = moment(vacation.start);
  const vacationEnd = moment(vacation.end);

  // Check if vacation is within the work month
  const isVacationWithinWorkMonth =
    vacationStart.isSameOrBefore(workMonthEnd) &&
    vacationEnd.isSameOrAfter(workMonthStart);

  return locationHasVaction && isVacationWithinWorkMonth;
};

const getHolidaysToArray = (vacation: IVacation) => {
  let vacationStartDay = vacation.start;
  const holidays: string[] = [];

  let start;
  const end = moment(vacation.end);

  while ((start = moment(vacationStartDay)) <= end) {
    holidays.push(start.format('MM-DD'));
    vacationStartDay = start.add(1, 'days').format('YYYY-MM-DD');
  }
  return holidays;
};

const formateTableData = (
  employeeData: Array<IEmployee>,
  locationData: Array<ILocation>,
  workTimeData: Array<IWorkTime>,
  customWorkTimeData: Array<ICustomWorkTimeData>,
  filters: IFilterData,
  vacations: Array<IVacation>,
) => {
  const newTableData = [];
  const { year, month, locationId } = filters;

  for (let i = 0; i < employeeData.length; i++) {
    const employee = employeeData[i];
    const workTimes = workTimeData.filter(wt => wt.userId === employee.id) || [];
    const customWorkTimes =
      customWorkTimeData.filter(cwt => cwt.userId === employee.id) || [];
    const weekdaySchedule: any = {};
    const customDaySchedule: any = {};
    const workDays: Record<string, any> = {};

    workTimes.forEach(wt => {
      if (!weekdaySchedule[wt.weekday]) {
        weekdaySchedule[wt.weekday] = {
          workTime: { start: wt.start, end: wt.end },
          workTimeBreaks: wt.weekdayWorkTimeBreaks,
        };
      }
    });

    vacations.forEach(vacation => {
      if (checkIfHasVacation(vacation, filters)) {
        const holidays = getHolidaysToArray(vacation);
        for (let j = 0; j < holidays.length; j++) {
          workDays[holidays[j]] = {
            enabled: false,
            workTime: {},
            workTimeBreaks: [],
            employee,
            location: locationData.find(loc => loc.id === locationId),
            day: `${year}-${month}-${holidays[j]}`,
            isCustomWorkTime: false,
            isHoliday: true,
          };
        }
      }
    });

    customWorkTimes.forEach(({ day, enabled, start, end, customWorkTimeBreaks, id }) => {
      const date = moment(day).format('MM-DD');
      if (!customDaySchedule[date]) {
        customDaySchedule[date] = {
          enabled,
          customWorkTime: { start: start, end: end, id },
          customWorkTimeBreaks: customWorkTimeBreaks,
        };
      }
    });

    for (let date = 1; date <= getDaysInMonth(month, year); date++) {
      const momentDate = moment(`${year}-${month}-${('' + date).padStart(2, '0')}`);
      const momentWeekday = momentDate.get('E');
      const objectDayKey = momentDate.format('MM-DD');

      const day: string = WEEKDAYS_NAMES[Math.max(0, momentWeekday - 1)];

      if (!day) continue;

      const customWorkTimeForDate = customDaySchedule[objectDayKey];

      if (customWorkTimeForDate) {
        workDays[objectDayKey] = {
          enabled: customWorkTimeForDate.enabled,
          workTime: customWorkTimeForDate.customWorkTime,
          workTimeBreaks: customWorkTimeForDate.customWorkTimeBreaks,
          employee,
          location: locationData.find(loc => loc.id === locationId),
          day: `${year}-${month}-${('' + date).padStart(2, '0')}`,
          isCustomWorkTime: true,
          isHoliday: workDays[objectDayKey]?.isHoliday || false,
        };
      } else if (weekdaySchedule[day]?.workTime && !workDays[objectDayKey]?.isHoliday) {
        workDays[objectDayKey] = {
          enabled: true,
          workTime: weekdaySchedule[day].workTime,
          workTimeBreaks: weekdaySchedule[day].workTimeBreaks,
          employee,
          location: locationData.find(loc => loc.id === locationId),
          day: `${year}-${month}-${('' + date).padStart(2, '0')}`,
          isCustomWorkTime: false,
          isHoliday: workDays[objectDayKey]?.isHoliday || false,
        };
      } else {
        workDays[objectDayKey] = {
          enabled: false,
          workTime: {},
          workTimeBreaks: [],
          employee,
          location: locationData.find(loc => loc.id === locationId),
          day: `${year}-${month}-${('' + date).padStart(2, '0')}`,
          isCustomWorkTime: false,
          isHoliday: workDays[objectDayKey]?.isHoliday || false,
        };
      }
    }

    newTableData.push({
      user: { ...employee, fullName: `${employee.name} ${employee.lastName || ''}` },
      workTimes: weekdaySchedule,
      workDays,
    });
  }
  return newTableData;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export interface IFilterData {
  locationId: string;
  month: string;
  userId: string;
  year: string;
}

const AdminWorkTime: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [workTimeModalOpen, setWorkTimeModalOpen] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState<IEmployee>();
  const [customWorkTimeToEdit, setCustomWorkTimeToEdit] = useState<ICustomWorkTime>();
  const [customWorkTimeModalOpen, setCustomWorkTimeModalOpen] = useState<boolean>(false);
  const [availableEmployees, setAvailableEmployees] = useState<IEmployee[]>();
  const history = useHistory();
  const query = useQuery();
  const [filters, setFilters] = useState<IFilterData>({
    locationId: '',
    userId: '',
    month: '',
    year: '',
  });

  const MONTHS = [
    { id: '01', name: t('filter_month_01') },
    { id: '02', name: t('filter_month_02') },
    { id: '03', name: t('filter_month_03') },
    { id: '04', name: t('filter_month_04') },
    { id: '05', name: t('filter_month_05') },
    { id: '06', name: t('filter_month_06') },
    { id: '07', name: t('filter_month_07') },
    { id: '08', name: t('filter_month_08') },
    { id: '09', name: t('filter_month_09') },
    { id: '10', name: t('filter_month_10') },
    { id: '11', name: t('filter_month_11') },
    { id: '12', name: t('filter_month_12') },
  ];

  const WEEKDAYS = [
    t('employee_calendar_weekday_short_1'),
    t('employee_calendar_weekday_short_2'),
    t('employee_calendar_weekday_short_3'),
    t('employee_calendar_weekday_short_4'),
    t('employee_calendar_weekday_short_5'),
    t('employee_calendar_weekday_short_6'),
    t('employee_calendar_weekday_short_0'),
  ];

  useError(error, setError);

  const {
    locationState,
    employeeState,
    workTimeState,
    customWorkTimeState,
    vacationsState,
  } = useStoreState();

  useEffect(() => {
    (async () => {
      if (!workTimeState.allData && filters.locationId) {
        try {
          await dispatch(getWorkTimeAll({ LocationId: filters.locationId }));
        } catch (err) {
          handleError(err?.response?.status, setError, err.response?.data?.title);
        }
      }
    })();
  }, [dispatch, workTimeState.allData, filters.locationId]);

  useEffect(() => {
    if (!filters.locationId) return;
    (async () => {
      try {
        const params = getQueryParamsForWorkTimes();
        await dispatch(getCustomWorkTimeAll(params));
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    })();
  }, [dispatch, filters.locationId, filters.month, filters.year]);

  useEffect(() => {
    (async () => {
      try {
        //first load offset is always 0
        await dispatch(getVacations(0, undefined, 1000));
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    })();
  }, [dispatch]);

  const getQueryParamsForWorkTimes = () => {
    const queryParams: IGetCustomWorkTimeAll = {
      LocationId: filters.locationId,
      DateFrom: `${filters.year}-${filters.month}-01`,
      DateTo: `${filters.year}-${filters.month}-${getDaysInMonth(
        filters.month,
        filters.year,
      )}`,
    };

    if (filters.userId) {
      queryParams.UserId = filters.userId;
    }
    return queryParams;
  };

  useEffect(() => {
    (async () => {
      if (!employeeState.allData) {
        try {
          await dispatch(getAllEmployees());
        } catch (err) {
          handleError(err?.response?.status, setError, err.response?.data?.title);
        }
      }
    })();
  }, [dispatch, employeeState.allData]);

  useEffect(() => {
    (async () => {
      if (!locationState.allData) {
        try {
          await dispatch(getAllLocations());
        } catch (err) {
          handleError(err?.response?.status, setError, err.response?.data?.title);
        }
      }
    })();
  }, [dispatch, locationState.allData]);

  const handleLocationChange = (locationId: string) => {
    setAvailableEmployees(
      employeeState.allData?.filter(emp => emp.locationIds?.includes(locationId)),
    );
  };

  useEffect(() => {
    if (filters.locationId) {
      handleLocationChange(filters.locationId);
    }
  }, [filters.locationId]);

  useEffect(() => {
    if (locationState.allData && employeeState.allData) {
      const availableLocationId =
        query.get('locationId') || locationState.allData?.[0]?.id;
      if (availableLocationId) {
        setFilters({
          locationId: availableLocationId,
          userId: query.get('userId') || '',
          month: query.get('month') || `${new Date().getMonth() + 1}`.padStart(2, '0'),
          year: query.get('year') || `${new Date().getFullYear()}`,
        });
        handleLocationChange(availableLocationId);
      }
    }
  }, [locationState.allData, employeeState.allData]);

  useEffect(() => {
    if (
      employeeState.allData &&
      workTimeState.allData &&
      customWorkTimeState.allData &&
      locationState.allData &&
      filters.locationId &&
      vacationsState.data
    ) {
      const selectedEmployee = employeeState.allData.find(
        emp => emp.id === filters.userId,
      );
      const formatedData = formateTableData(
        selectedEmployee ? [selectedEmployee] : availableEmployees || [],
        locationState.allData,
        workTimeState.allData,
        customWorkTimeState.allData,
        filters,
        vacationsState.data,
      );
      setTableData(formatedData || []);
    }
  }, [
    workTimeState.allData,
    customWorkTimeState.allData,
    employeeState.allData,
    locationState.allData,
    filters,
  ]);

  const columns = React.useMemo(() => {
    const { year, month } = filters;
    const getHeader = (date?: number) => {
      if (date) {
        const momentDate = moment(`${year}-${month}-${('' + date).padStart(2, '0')}`);
        const momentWeekday = momentDate.get('E');

        return (
          <>
            <div>{date} d.</div>
            <div className='text-uppercase'>
              {WEEKDAYS[Math.max(0, momentWeekday - 1)]}
            </div>
          </>
        );
      } else {
        return (
          <div className='text-uppercase'>
            {MONTHS.find(m => m.id === month)?.name || ''} {year}
          </div>
        );
      }
    };

    const generatedColumns: any = [
      {
        Header: getHeader(),
        accessor: 'user',
        id: 'user',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => {
          return (
            <>
              <div className='mb-1'>{cell.value.fullName}</div>
              <Button
                variant='warning'
                className='grey-borders white btn-sm'
                onClick={() => {
                  setEmployeeToEdit(cell.value);
                  setWorkTimeModalOpen(true);
                }}
              >
                <span> {t('employee_calendar_set_working_hours')}</span>
              </Button>
            </>
          );
        },
      },
    ];

    for (let i = 1; i <= getDaysInMonth(month, year); i++) {
      const now = new Date();
      const momentDate = moment(
        `${year || now.getFullYear()}-${month || now.getMonth() + 1}-${('' + i).padStart(
          2,
          '0',
        )}`,
      );

      const dayObjectKey = momentDate.format('MM-DD');

      generatedColumns.push({
        Header: getHeader(i),
        accessor: 'workDays',
        id: dayObjectKey,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => {
          const cellValue = cell.value[dayObjectKey];

          return (
            <div
              className={
                cellValue?.enabled
                  ? 'employee-table-col'
                  : 'employee-table-col cell-disabled'
              }
            >
              {cellValue?.enabled && (
                <>
                  {cellValue?.workTime.start && (
                    <div>
                      {`${cellValue?.workTime.start.slice(
                        0,
                        5,
                      )} - ${cellValue?.workTime.end.slice(0, 5)}`}
                    </div>
                  )}

                  {cellValue?.workTimeBreaks.length > 0 && (
                    <>
                      <div>{t('employee_calendar_breaks')}</div>
                      {cellValue?.workTimeBreaks.map(
                        (wtBreak: ICustomWorkTimeBreak, index: number) => (
                          <div key={index}>
                            {wtBreak.start.slice(0, 5)} - {wtBreak.end.slice(0, 5)}
                          </div>
                        ),
                      )}
                    </>
                  )}
                  {cellValue.isCustomWorkTime && (
                    <div className='d-flex justify-content-center align-items-center'>
                      <div>
                        <FaRegEdit className='mr-1' size={14} />
                      </div>
                      <div className='pt-1'>{t('employee_calendar_edited')}</div>
                    </div>
                  )}
                </>
              )}
              {!cellValue?.enabled && <div>{t('employee_calendar_not_working')}</div>}
            </div>
          );
        },
      });
    }
    return generatedColumns;
  }, [tableData]);

  const formValidation = (values: any) => {
    const error: Record<string, any> = {};
    if (!values.locationId) {
      error.locationId = t('required');
    }
    if (!values.month) {
      error.month = t('required');
    }
    if (!values.year) {
      error.year = t('required');
    }
    return error;
  };

  const filtersSubmit = async ({ locationId, userId, month, year }: IFilterData) => {
    setError(undefined);
    const filterDataObj = {
      locationId,
      month,
      year,
      userId: userId || '',
    };

    setFilters(filterDataObj);
    try {
      await dispatch(getWorkTimeAll({ LocationId: locationId, UserId: userId }));
      await dispatch(
        getCustomWorkTimeAll({
          LocationId: locationId,
          DateFrom: `${year}-${month}-01`,
          DateTo: `${year}-${month}-${getDaysInMonth(month, year)}`,
          UserId: userId,
        }),
      );
      history.replace({
        pathname: ROUTES.ADMIN_SETTINGS.EMPLOYEE_CALENDAR,
        search: '?' + new URLSearchParams(filterDataObj).toString(),
      });
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const getEmployeesOptions = () => {
    const employeesOptions = [{ id: '', fullName: t('filter_all_employees') }];
    if (availableEmployees) {
      availableEmployees.forEach(emp =>
        employeesOptions.push({
          ...emp,
          fullName: emp.name + ` ${emp.lastName || ''}`,
        }),
      );
    }
    return employeesOptions;
  };

  return (
    <Container fluid className='p-0 admin-worktime'>
      <Card className='mainCard'>
        <Card.Header className='title'>
          {t('employee_calendar_list_page_title')}
        </Card.Header>
        <Card.Body>
          <Row className='align-items-start'>
            <CustomWorkTimeFilters
              validation={formValidation}
              handleSubmit={filtersSubmit}
              editValue={filters}
              handleLocationChange={handleLocationChange}
              locations={locationState.allData || []}
              months={MONTHS}
              employees={getEmployeesOptions()}
            />
          </Row>
          <Row className='mt-4 mt-md-0'>
            <Col sm={12}>
              <CustomWorkTimeTable
                data={tableData}
                columns={columns}
                selectCell={setCustomWorkTimeToEdit}
                openModal={setCustomWorkTimeModalOpen}
                isLoading={employeeState.isLoading && locationState.isLoading}
                filters={filters}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <WorkTimeAddModal
        show={workTimeModalOpen}
        selectedEmployee={employeeToEdit}
        locations={locationState.allData}
        clearWorkTimesAfterSubmit
        currentLocationId={filters.locationId}
        handleClose={() => {
          setWorkTimeModalOpen(false);
          setEmployeeToEdit(undefined);
        }}
      />
      {customWorkTimeModalOpen && (
        <CustomWorkTimeAddModal
          show={customWorkTimeModalOpen}
          customWorkTimeToEdit={customWorkTimeToEdit}
          setCustomWorkTimeToEdit={setCustomWorkTimeToEdit}
          handleClose={() => {
            setCustomWorkTimeModalOpen(false);
            setCustomWorkTimeToEdit(undefined);
          }}
        />
      )}
    </Container>
  );
};
export default AdminWorkTime;
