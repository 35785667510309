import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'store/Store';
import FormInput from 'components/Form/FormInput';
import { Typeahead, Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { ILocation } from 'types/Locations';
import { IServices } from 'types/Services';
import { IServiceGroup } from 'types/ServiceGroups';
import {
  IReservationModalInitialData,
  IReservationWithInfo,
  ReservationCustomIds,
  ReservationCustomPricingIds,
  ReservationTypes,
} from 'types/Reservations';
import ReservationFormEmployees from './ReservationFormEmployees';
import ReservationFormServices from './ReservationFormServices';
import ReservationsErrors from './ReservationsErrors';
import ReservationFormCalendar from './ReservationFormCalendar';
import { IFormError } from 'constants/Interfaces';
import { IReservationSettingsState } from 'types/ReservationSettings';
import { useStoreState } from 'hooks/useStoreState';
import { getClients } from 'actions/ClientsActions';
import { ClientIdStatuses, IClient } from 'types/Clients';
import './AddEditReservationForm.scss';
import { getCompanyById } from 'actions/CompaniesActions';
import { getInitialPrice } from 'helpers/Currency';
import { convertToHoursAndMinutes } from 'reducers/ServicesReducer';
import { getDuration, getTimeDifferenceInMinutes } from 'helpers/DateTime';

interface IAddEditReservationForm {
  locations: ILocation[];
  services: IServices[];
  serviceGroups: IServiceGroup[];
  reservationToEdit: IReservationWithInfo;
  calendarWorkingHoursError: boolean;
  setCalendarWorkingHoursError: (error: boolean) => void;
  setCalendarAutoUserError: (error: boolean) => void;
  selectedUserId: string | undefined;
  setSelectedUserId: (val: string) => void;
  handleClientAdd: () => void;
  setValue?: (name: string, value: any) => void;
  errors?: Record<string, any>;
  values?: IReservationWithInfo;
  initialData?: IReservationModalInitialData;
  error?: IFormError;
  setSelectedClient?: (data: any) => void;
  failedToSubmit: boolean;
  setSelectedLocationId: (id: string) => void;
  selectedLocationId: string;
  setSelectedServiceId: (id: string) => void;
  selectedServiceId: string;
  isMultiReservation: boolean;
  calendarUserIsBusy: boolean;
}

const AddEditReservationForm: FC<IAddEditReservationForm> = ({
  reservationToEdit,
  locations,
  serviceGroups,
  services,
  handleClientAdd,
  setValue,
  errors,
  setCalendarWorkingHoursError,
  calendarWorkingHoursError,
  initialData,
  values,
  setCalendarAutoUserError,
  error,
  selectedUserId,
  setSelectedUserId,
  setSelectedClient,
  failedToSubmit,
  selectedLocationId,
  setSelectedLocationId,
  selectedServiceId,
  setSelectedServiceId,
  isMultiReservation,
  calendarUserIsBusy,
}) => {
  const { t } = useTranslation();
  const typeaheadRef = useRef(null);
  const reservationSettingsState: IReservationSettingsState = useSelector<
    IAppState,
    IReservationSettingsState
  >((state: IAppState) => state.reservationSettingsState);
  const dispatch = useDispatch();
  const clientState = useStoreState().clientsState;
  const employees = useStoreState().employeeState?.allData || [];
  const { companyId } = useStoreState().userState.data || {};
  const servicesState = useStoreState().servicesState;

  const [clientFullName, setClientFullName] = useState<string>();
  const [selectedClientId, setSelectedClientId] = useState<string>();
  const [selectedServiceGroupId, setSelectedServiceGroupId] = useState<string>(
    reservationToEdit?.serviceGroupId || '',
  );
  const [selectedPricingId, setSelectedPricingId] = useState<string>(
    reservationToEdit?.servicePricingId || '',
  );
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(
    reservationToEdit?.start || '',
  );

  useEffect(() => {
    if (!companyId) return;
    dispatch(getCompanyById(companyId));
  }, []);

  useEffect(() => {
    if (reservationToEdit.id === ReservationCustomIds.CreateNewToGroup) {
      setSelectedClientId(undefined);
      return;
    }

    if (reservationToEdit.id) {
      setSelectedClientId(reservationToEdit?.clientId || 'anonymous');
    } else {
      setSelectedClientId(reservationToEdit?.clientId || '');
    }
  }, [reservationToEdit?.clientId]);

  useEffect(() => {
    if (!initialData) return;
    setSelectedStartDate(initialData?.time);

    if (initialData?.employee) {
      setSelectedUserId(initialData.employee);
      setValue?.('userId', initialData.employee);
      return;
    }
    setValue?.('userId', '');
    setSelectedUserId('');
  }, [selectedServiceId, selectedPricingId, selectedLocationId, initialData]);

  useEffect(() => {
    if (error?.title === 'NO_AVAILABLE_USERS') {
      setSelectedStartDate(null);
    }
  }, [error]);

  useEffect(() => {
    if (reservationToEdit.id !== ReservationCustomIds.EditMultiple) {
      setValue?.('servicePricing.personsCount', 1);
    }
  }, [selectedUserId, reservationToEdit]);

  useEffect(() => {
    if (selectedStartDate) {
      const startDate = selectedStartDate.slice(0, 16);
      if (`${startDate}` !== values?.start) setValue?.('start', `${startDate}`);
      let endDate = moment(startDate);
      const duration = values?.serviceId
        ? getDuration(values?.servicePricing.hours, values?.servicePricing.minutes)
        : values?.servicePricing.duration || 0;
      const timeAfterService = getDuration(
        values?.servicePricing.hoursAfterService,
        values?.servicePricing.minutesAfterService,
      );
      if (values?.serviceId) {
        endDate = endDate.add(Math.abs(timeAfterService) + Math.abs(duration), 'minutes');
      } else {
        endDate = endDate.add(Math.abs(duration), 'minutes');
      }
      if (endDate.format('YYYY-MM-DD[T]HH:mm:ss') !== values?.end) {
        setValue?.('end', endDate.format('YYYY-MM-DD[T]HH:mm:ss'));
        setValue?.('servicePricing.duration', duration);
        setValue?.('duration', duration);
        setValue?.('timeAfterService', timeAfterService);
      }
    } else {
      setValue?.('start', null);
      setValue?.('end', null);
    }
  }, [selectedStartDate, values?.servicePricing, values?.serviceId]);

  const initialReservationPrice = useMemo(() => {
    // get initial reservation price for 1 person
    // if creating new reservation, return default price for this service.
    if (!reservationToEdit.id)
      return getInitialPrice(
        values?.servicePricing?.salePrice,
        values?.servicePricing?.price,
      );

    const pricing = servicesState.allData
      ?.find(s => s.id === reservationToEdit.serviceId)
      ?.pricings.find(p => p.id === reservationToEdit.servicePricingId);
    const price = getInitialPrice(pricing?.salePrice, pricing?.price);
    return price || 0;
    // careful: we need only values?.servicePricing.id, because we only track
    // service change itself, not service price change.
  }, [servicesState.allData, values?.servicePricing?.id, reservationToEdit]);

  useEffect(() => {
    if (initialReservationPrice == null || !values?.servicePricing?.personsCount) return;

    // auto update price field, when user adds/removes people
    setValue?.(
      'servicePricing.price',
      initialReservationPrice * values.servicePricing.personsCount,
    );
  }, [values?.servicePricing?.personsCount, initialReservationPrice]);

  useEffect(() => {
    const { userId, price, duration, end, start, type } = reservationToEdit;
    const totalTime = getTimeDifferenceInMinutes(new Date(start), new Date(end));
    const timeAfterService = totalTime - duration;

    setSelectedClient?.({
      name: reservationToEdit.client?.name,
      lastname: reservationToEdit.client?.lastname,
      phone: reservationToEdit.client?.phone,
      comments: reservationToEdit.client?.comments,
    });

    // If we're editing reservation and client is null, client = anonymous
    if (
      !reservationToEdit.clientId &&
      Boolean(reservationToEdit.id) &&
      reservationToEdit.id !== ReservationCustomIds.CreateNewToGroup // Exception - counts as edit, but actually is creating new reservation within group
    ) {
      setValue?.('clientId', ClientIdStatuses.Anonymous);
    }
    if (userId) {
      setValue?.('userId', userId);
      setSelectedUserId(userId);
    }
    if (price) {
      setValue?.('servicePricing.price', price);
    } else {
      setValue?.('servicePricing.price', initialReservationPrice);
    }
    if (duration) {
      const { hours, minutes } = convertToHoursAndMinutes(duration);
      setValue?.('servicePricing.duration', duration);
      setValue?.('servicePricing.hours', hours || '0');
      setValue?.('servicePricing.minutes', minutes || '0');
    }
    if (timeAfterService > 0) {
      const { hours, minutes } = convertToHoursAndMinutes(timeAfterService);
      setValue?.('servicePricing.hoursAfterService', hours || '0');
      setValue?.('servicePricing.minutesAfterService', minutes || '0');
      setValue?.('timeAfterService', timeAfterService);
    } else {
      setValue?.('servicePricing.hoursAfterService', '0');
      setValue?.('servicePricing.minutesAfterService', '0');
    }
    if (type === ReservationTypes.BLOCK) {
      setValue?.('isBlockTime', true);
      setValue?.(
        'servicePricing.duration',
        moment.duration(moment(end).diff(moment(start))).asMinutes(),
      );
    } else {
      setValue?.('isBlockTime', false);
    }

    if (reservationToEdit?.type === ReservationTypes.BLOCK) {
      setSelectedServiceGroupId(ReservationCustomPricingIds.BlockTime);
    }
  }, []);

  const handleClientSearch = useCallback(
    debounce((query?: string) => {
      (async () => {
        await dispatch(getClients(0, query, undefined, 'name asc', 150));
      })();
    }, 350),
    [],
  );

  useEffect(() => {
    if (!selectedClientId?.length) {
      handleClientSearch();
    }
  }, [selectedClientId]);

  useEffect(() => {
    const client = clientState.data?.find(cl => cl.id === selectedClientId);
    if (selectedClientId === 'anonymous') {
      setClientFullName(t('reservation_client_incognito'));
    } else {
      const fullName =
        client?.name || reservationToEdit.client?.name
          ? `${client?.name || reservationToEdit.client?.name || ''} ${
              client?.lastname || reservationToEdit.client?.lastname || ''
            }`
          : t('reservation_client_incognito');
      setClientFullName(fullName);
    }
  }, [selectedClientId, reservationToEdit]);

  const handleClientChange = (options: IClient[]) => {
    const [client] = options;
    if (options) {
      setValue?.('clientId', client.id);
      setSelectedClientId(client.id);
      setValue?.('client', {
        name: client.name,
        lastname: client.lastname,
        phone: client.phone,
        comments: client.comments,
        email: client.email,
        id: client.id,
      });
      setSelectedClient?.({
        name: client.name,
        lastname: client.lastname,
        phone: client.phone,
        comments: client.comments,
        email: client.email,
        id: client.id,
      });
    }
  };

  const getAvailableEmployees = () => {
    if (selectedServiceId) {
      return employees
        .filter(
          emp =>
            emp.serviceIds?.includes(selectedServiceId) &&
            emp.locationIds?.includes(selectedLocationId),
        )
        ?.sort((a, b) => a.name.localeCompare(b.name));
    } else if (selectedServiceGroupId === ReservationCustomPricingIds.BlockTime) {
      return employees
        .filter(emp => emp.locationIds?.includes(selectedLocationId))
        ?.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return [];
    }
  };

  const showOnlyServiceSelection = initialData?.location && initialData?.employee;

  const getClientItem = (client: IClient) => {
    let string = `${client?.name || ''} ${client?.lastname || ''}`;
    if (client.phone) {
      string += ` (${client.phone})`;
    }
    return string;
  };

  const clearUserAndTime = () => {
    setSelectedUserId('');
    setSelectedStartDate(null);
  };

  const maxPeopleForThisUser = useMemo(() => {
    if (!selectedUserId) return 1;
    const empl = employees?.find(e => e.id === selectedUserId);
    let emptySlots = empl?.maxPersonsCount || 1;

    if (isMultiReservation && emptySlots && initialData?.personsCount) {
      // Adding new multipersonal reservation, set max value to left empty slots
      emptySlots -= initialData.personsCount;
    }

    return emptySlots;
  }, [employees, selectedUserId, isMultiReservation]);

  const isEditingMultipleReservations =
    isMultiReservation && reservationToEdit.id === ReservationCustomIds.EditMultiple;

  const isCreatingNewReservationToGroup =
    reservationToEdit.id === ReservationCustomIds.CreateNewToGroup;

  return (
    <Row className='px-2 py-2 add-edit-reservation-form'>
      {initialData?.time && (
        <Col xs={12} className='mb-2'>
          <h4 className='font-weight-light'>
            {moment(initialData.time)
              .tz(`Europe/${reservationSettingsState.data?.calendarTimeZone}`)
              .format('YYYY-MM-DD HH:mm')}
          </h4>
        </Col>
      )}
      <Col xs={12}>
        {failedToSubmit &&
          (errors || calendarWorkingHoursError || calendarUserIsBusy) && (
            <ReservationsErrors
              errors={errors}
              calendarWorkingHoursError={calendarWorkingHoursError}
              calendarUserIsBusy={calendarUserIsBusy}
              selectedClientId={selectedClientId}
            />
          )}
      </Col>
      <Col xs={12}>
        <Row>
          {!isEditingMultipleReservations && selectedClientId && (
            <Col xs={12}>
              <div className='m-2 d-flex align-items-center'>
                <h6 className='mb-0 mr-2'>{clientFullName}</h6>
                <Button
                  variant='primary'
                  onClick={() => {
                    setValue?.('clientId', '');
                    setSelectedClientId('');
                    setSelectedClient?.(undefined);
                  }}
                  size='sm'
                >
                  {t('reservation_change_client')}
                </Button>
              </div>
            </Col>
          )}
          {!isEditingMultipleReservations && !selectedClientId && (
            <>
              <Col sm={6}>
                <Typeahead
                  ref={typeaheadRef}
                  id='find_client'
                  filterBy={() => true}
                  paginate={false}
                  minLength={0}
                  options={(clientState.data || []).map(cl => ({
                    ...cl,
                    fullName: getClientItem(cl),
                  }))}
                  onBlur={() => {
                    (typeaheadRef.current as any).clear?.();
                    handleClientSearch();
                  }}
                  isLoading={!!clientState.isLoading}
                  onChange={handleClientChange}
                  onInputChange={handleClientSearch}
                  emptyLabel={null}
                  placeholder={t('reservation_form_placeholder_client')}
                  labelKey='fullName'
                  renderMenuItemChildren={(option, props) => (
                    <Highlighter search={props.text || ''}>{option.fullName}</Highlighter>
                  )}
                  renderMenu={(results, menuProps, state) => {
                    if (!results.length) return null;
                    return (
                      <Menu {...menuProps}>
                        {results.map((result, index) => (
                          <MenuItem key={result.id} option={result} position={index}>
                            <Highlighter search={state.text}>
                              {result.fullName}
                            </Highlighter>
                          </MenuItem>
                        ))}
                      </Menu>
                    );
                  }}
                />
              </Col>
              <Col sm={6}>
                <div className='d-flex align-items-center'>
                  <Button
                    variant='primary'
                    onClick={() => {
                      setValue?.('clientId', 'anonymous');
                      setSelectedClientId('anonymous');
                      setSelectedClient?.(null);
                    }}
                    size='sm'
                  >
                    {t('reservation_client_incognito')}
                  </Button>

                  <Button
                    variant='primary'
                    className='centerIcon ml-1'
                    onClick={handleClientAdd}
                    size='sm'
                  >
                    <FaPlus className='pr-1' />
                    {t('reservation_create_client_btn')}
                  </Button>
                </div>
              </Col>
            </>
          )}
          {isCreatingNewReservationToGroup && (
            <Col xs={2}>
              <FormInput
                size='sm'
                name='servicePricing.personsCount'
                type='number'
                label={t('people_count')}
                inputClass='mb-0'
                wrapperClass='mb-0'
              />
            </Col>
          )}
          <Col xs={12}>
            <FormInput
              label={t('client_form_label_comments')}
              name='userComments'
              formType='textarea'
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={12} lg={showOnlyServiceSelection ? 12 : 6} className='border-right'>
            <div className='column'>
              <div className='column-title'>{t('services') + ' *'}</div>
              <ReservationFormServices
                reservationToEdit={reservationToEdit}
                locations={locations}
                serviceGroups={serviceGroups}
                services={services}
                errors={errors}
                values={values}
                setValue={setValue}
                clearUserAndTime={clearUserAndTime}
                selectedServiceId={selectedServiceId}
                setSelectedServiceId={setSelectedServiceId}
                selectedServiceGroupId={selectedServiceGroupId}
                setSelectedServiceGroupId={setSelectedServiceGroupId}
                selectedLocationId={selectedLocationId}
                setSelectedLocationId={setSelectedLocationId}
                selectedPricingId={selectedPricingId}
                setSelectedPricingId={setSelectedPricingId}
                hasPreselectedLocation={!!initialData}
                maxPeople={maxPeopleForThisUser}
                isMultiReservation={isMultiReservation}
              />
            </div>
          </Col>
          {!showOnlyServiceSelection && employees && (
            <Col xs={12} lg={2} className={clsx('border-right')}>
              <div className='column-title'>{t('user_role_employee') + ' *'}</div>
              <ReservationFormEmployees
                selectedUserId={selectedUserId}
                employees={getAvailableEmployees()}
                setSelectedUserId={(userId: string) => {
                  setSelectedUserId(userId);
                  setSelectedStartDate(null);
                  setValue?.('userId', userId);
                }}
                serviceIsSelected={!!selectedServiceId}
              />
            </Col>
          )}
          {!showOnlyServiceSelection && (
            <Col xs={12} lg={4} className={clsx('border-right')}>
              <div className='column-title'>{t('reservation_visiting_time') + ' *'}</div>
              <ReservationFormCalendar
                selectedUserId={selectedUserId}
                selectedLocationId={selectedLocationId}
                selectedServiceId={selectedServiceId}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                serviceDuration={
                  values?.serviceId
                    ? getDuration(
                        values?.servicePricing.hoursAfterService,
                        values?.servicePricing.minutesAfterService,
                      ) + (values?.servicePricing?.duration || 0)
                    : +(values?.servicePricing?.duration || 0)
                }
                reservationValues={values}
                selectedPricingId={selectedPricingId}
                setCalendarAutoUserError={setCalendarAutoUserError}
                setCalendarWorkingHoursError={setCalendarWorkingHoursError}
                reservationToEdit={reservationToEdit}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

AddEditReservationForm.displayName = 'AddEditReservationForm';

export default AddEditReservationForm;
