import i18n from '../config/i18next';
import { IFormError } from '../constants/Interfaces';

export const handleError = (
  status: number,
  setError: (error: IFormError) => void,
  message: string,
  causedByField?: string,
  defaultGenericError?: string,
) => {
  if (causedByField) {
    setError({ title: message, message: i18n.t(message), causedByField });
    return;
  }
  switch (status) {
    case 400:
      setError({ title: message, message: i18n.t(message), causedByField: undefined });
      break;
    case 401:
      setError({
        title: message,
        message: i18n.t('unauthorized'),
        causedByField: undefined,
      });
      break;

    case 403:
      setError({
        title: message,
        message: i18n.t('forbidden'),
        causedByField: undefined,
      });
      break;

    case 404:
      setError({ title: message, message: i18n.t('notFound'), causedByField: undefined });
      break;

    case 500:
      setError({ title: message, message: i18n.t('internal'), causedByField: undefined });
      break;
    case 503:
      setError({
        title: message,
        message: i18n.t('serviceUpgrading'),
        causedByField: undefined,
      });
      break;
    default:
      return setError({
        title: message,
        message: defaultGenericError || i18n.t('genericError'),
        causedByField: undefined,
      });
  }
};
