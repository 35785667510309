import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  TCustomWorkTimeActions,
  ICustomWorkTimeState,
  initialCustomWorkTimeState,
  CustomWorkTimeGet,
  CustomWorkTimeDelete,
  CustomWorkTimeAdd,
  CustomWorkTimeEdit,
  CustomWorkTimePut,
  ICustomWorkTime,
  CustomWorkTimeGetAll,
} from '../types/CustomWorkTime';

export const customWorkTimeReducer: Reducer<
  ICustomWorkTimeState,
  TCustomWorkTimeActions
> = (state = initialCustomWorkTimeState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialCustomWorkTimeState;
    }
    case CustomWorkTimeEdit.REQUEST:
    case CustomWorkTimeAdd.REQUEST:
    case CustomWorkTimeDelete.REQUEST:
    case CustomWorkTimeGetAll.REQUEST:
    case CustomWorkTimeGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CustomWorkTimeEdit.FAILED:
    case CustomWorkTimeAdd.FAILED:
    case CustomWorkTimeDelete.FAILED:
    case CustomWorkTimeGetAll.FAILED:
    case CustomWorkTimeGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CustomWorkTimePut.CUSTOM_WORK_TIME_OFFSET_UPDATE: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    case CustomWorkTimeDelete.DELETE_CUSTOM_WORK_TIME_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.filter((rec: ICustomWorkTime) => rec.id !== action.payload)
          : [],
        allData: state.allData
          ? state.allData.filter((rec: ICustomWorkTime) => rec.id !== action.payload)
          : [],
        total: state.total ? state.total - 1 : 0,
      };
    }
    case CustomWorkTimeAdd.ADD_CUSTOM_WORK_TIME_STORE: {
      return {
        ...state,
        data: state.data
          ? [{ ...action.payload }, ...state?.data]
          : [{ ...action.payload }],
        allData: state.allData
          ? [{ ...action.payload }, ...state?.allData]
          : [{ ...action.payload }],
      };
    }
    case CustomWorkTimeEdit.EDIT_CUSTOM_WORK_TIME_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.map(rec => (rec.id === action.payload.id ? action.payload : rec))
          : [],
        allData: state.allData
          ? state.allData.map(rec =>
              rec.id === action.payload.id ? action.payload : rec,
            )
          : [],
      };
    }
    case CustomWorkTimeEdit.SUCCESS:
    case CustomWorkTimeAdd.SUCCESS:
    case CustomWorkTimeDelete.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CustomWorkTimeGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isLoading: false,
        total: action.payload.data.total,
      };
    }
    case CustomWorkTimeGetAll.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allData: action.payload.data.data,
      };
    }
    default:
      return state;
  }
};
