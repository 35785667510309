import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import { IFormError } from '../../../constants/Interfaces';
import { getEmployeeProfilePicture } from 'actions/EmployeesActions';
import UserFrom from '../../Utils/Forms/UserForm';

interface IMainInfoTab {
  errors?: Record<string, any>;
  submitFailed?: boolean;
  fieldError?: IFormError;
  employeeId?: string;
  setUploadedAvatar?: (file?: File) => void;
  onPasswordGroupFocus?: () => void;
}

const MainInfoTab: React.FC<IMainInfoTab> = ({
  errors,
  submitFailed,
  fieldError,
  employeeId,
  setUploadedAvatar,
  onPasswordGroupFocus,
  ...rest
}) => {
  const dispatch = useDispatch();
  const hasRolesError = errors ? Object.keys(errors).includes('roles') : false;
  const [employeeAvatar, setEmployeeAvatar] = useState<File | null | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!employeeId) return;

    (async () => {
      const response: any = await dispatch(getEmployeeProfilePicture(employeeId));
      setEmployeeAvatar(response.data || null);
    })();
  }, [employeeId]);

  return (
    <Row>
      <UserFrom
        chechBoxError={hasRolesError && submitFailed}
        fieldError={fieldError}
        avatar={employeeAvatar}
        setAvatar={setUploadedAvatar}
        onPasswordGroupFocus={onPasswordGroupFocus}
        {...rest}
      />
    </Row>
  );
};

export default MainInfoTab;
