import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaChevronLeft } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { ISidebarLink } from 'constants/Interfaces';
import { Roles } from 'constants/Roles';
import { ROUTES } from 'constants/Routes';
import Collapse from 'react-bootstrap/Collapse';
import { isMobile } from 'constants/UtilVariables';
import CircleAvatar from 'components/Avatars/CircleAvatar';
import { editNameSurname, getUserProfilePicture } from 'actions/UserActions';
import { matchUserRole } from 'helpers/matchRole';
import { getAccessToken, parseUserData, isSuperViewerMode } from 'helpers/Misc';
import { API_END_POINTS } from 'constants/API';
import { useStoreState } from 'hooks/useStoreState';
import './Sidebar.scss';

interface ISidebar {
  superAdminLinks: ISidebarLink[];
  adminSidebarLinks: ISidebarLink[];
  employeeSidebarLinks: ISidebarLink[];
  AnalystSideBarLinks: ISidebarLink[];
  open: boolean;
  setOpen: (value: boolean) => void;
  onLogout: () => void;
}

const Sidebar: React.FC<ISidebar> = ({
  superAdminLinks,
  adminSidebarLinks,
  employeeSidebarLinks,
  AnalystSideBarLinks,
  open,
  setOpen,
  onLogout,
}) => {
  const sidebarRef = useRef<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [settingsListOpen, setSettingsListOpen] = useState<boolean>(false);
  const [userProfilePic, setUserProfilePic] = useState<any>();

  const token = getAccessToken();
  const client = token ? parseUserData(token) : null;
  const userId = client?.userId;

  const { userState } = useStoreState();

  const getUserName = async () => {
    const response = await axios.get(API_END_POINTS.GET_EMPLOYEE_BY_ID(userId || ''), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response;
  };

  useEffect(() => {
    if (!userId || userState.firstName) return;
    (async () => {
      const user: any = await getUserName();
      await dispatch(editNameSurname(user.data?.name, user.data?.lastName));
    })();
  }, [userId]);

  useEffect(() => {
    setSettingsListOpen(false);
  }, [open]);

  const SETTINGS = t('sidebar_menu_settings');
  const INSTRUCTIONS = t('sidebar_menu_instruction');

  const settingLinks = [
    {
      name: t('sidebar_menu_service'),
      route: [ROUTES.ADMIN_SETTINGS.SERVICES],
    },
    {
      name: t('sidebar_menu_user_activity_type_pasiutuslape'),
      route: [ROUTES.ADMIN_SETTINGS.USERS],
    },
    {
      name: t('sidebar_menu_resources'),
      route: [ROUTES.ADMIN_SETTINGS.RESOURCES],
    },
    {
      name: t('sidebar_menu_employee_calendar'),
      route: [ROUTES.ADMIN_SETTINGS.EMPLOYEE_CALENDAR],
    },
    {
      name: t('sidebar_menu_vacation_days'),
      route: [ROUTES.ADMIN_SETTINGS.VACATION_DAYS],
    },
    {
      name: t('sidebar_menu_location'),
      route: [ROUTES.ADMIN_SETTINGS.LOCATIONS],
    },
    {
      name: t('sidebar_menu_reservation_settings'),
      route: [ROUTES.ADMIN_SETTINGS.RESERVATION_SETTINGS],
    },
    {
      name: t('sidebar_menu_settings_notifications'),
      route: [ROUTES.ADMIN_SETTINGS.NOTIFICATIONS],
    },
    {
      name: t('sidebar_menu_client_import'),
      route: [ROUTES.ADMIN_SETTINGS.CLIENT_IMPORT],
    },
  ];

  const handleClickOutside = (e: any) => {
    if (sidebarRef.current && !sidebarRef.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!isMobile.matches) return;
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMobile]);

  useEffect(() => {
    const hasFetched = typeof userState.avatar !== 'undefined';
    const profilePicExists = !!userState.avatar?.size;
    if (hasFetched && profilePicExists) {
      setUserProfilePic(URL.createObjectURL(userState.avatar));
      return;
    }
    if (hasFetched && !profilePicExists) return;

    (async () => {
      await dispatch(getUserProfilePicture());
    })();
  }, [userState.avatar]);

  const isSettingsRoute = () => {
    return settingLinks.find(setting => setting.route[0] === history.location.pathname)
      ? true
      : false;
  };

  const onAdminLinkClick = (name: string, route: string) => {
    if (!open) {
      return;
    }
    if (name === SETTINGS) {
      setSettingsListOpen(!settingsListOpen);
      return;
    }
    if (name === INSTRUCTIONS) {
      window.open(route, '_blank');
    }
    if (isMobile.matches) setOpen(false);
    history.push(route);
  };

  return (
    <Col
      className={clsx('p-0 m-0 sidebar h-100', {
        ['active']: open,
        ['overflow-visible']: !open,
      })}
      ref={sidebarRef}
    >
      <Card className='userArea'>
        <Row>
          <Col xs={12}>
            <Image
              src='/logo-white.png'
              className={clsx('logo py-4', open ? 'ml-4' : 'px-3')}
            />
            {open && !isMobile.matches && (
              <FaChevronLeft onClick={() => setOpen(false)} className='collapseSidebar' />
            )}
          </Col>
        </Row>
        <div className='userProfile'>
          <Dropdown className='ml-4 p-0'>
            {userProfilePic && <CircleAvatar src={userProfilePic} />}
            <Dropdown.Toggle variant='none' className='userDropdown p-0 mt-2 mb-4'>
              {`${userState.firstName} ${userState.lastName || ''}`}
            </Dropdown.Toggle>
            <Dropdown.Menu className='p-0'>
              <Dropdown.Item
                onClick={() => history.push(ROUTES.ADMIN.PROFILE)}
                className='user-profile-item'
              >
                {t('sidebar_user_profile_profile_link')}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onLogout?.()} className='user-profile-item'>
                {t('sidebar_user_profile_logout_link')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card>
      <Nav className='flex-column'>
        <ListGroup as='ul' className='nav'>
          {(userState.data?.roles.includes(Roles.Admin) || isSuperViewerMode()) &&
            adminSidebarLinks.map((link, index) => (
              <ListGroup.Item
                as='li'
                className='nav-link borderless cursor-pointer'
                id={link.name === SETTINGS ? 'settings-list-settings-item' : ''}
                key={index}
                aria-controls={link.name === SETTINGS ? 'settings-list' : ''}
                style={{ zIndex: !open || isMobile.matches ? 3 : 1 }}
                active={
                  link.route.includes(history.location.pathname) ||
                  (link.name === SETTINGS && isSettingsRoute())
                }
                onClick={() => onAdminLinkClick(link.name, link.route[0])}
                onMouseEnter={() => {
                  if (open || link.name !== SETTINGS) {
                    return;
                  }
                  setSettingsListOpen(true);
                }}
                onMouseLeave={() => {
                  if (open || link.name !== SETTINGS) {
                    return;
                  }
                  setSettingsListOpen(false);
                }}
              >
                <span
                  className={clsx({
                    ['ml-2 d-flex']: open && !isMobile.matches,
                    ['drawer-small']: !open,
                    ['settings-list-item-text']: link.name === SETTINGS,
                  })}
                >
                  <span
                    className={!open || isMobile.matches ? 'drawer-small' : ''}
                    // called when clicked on minimized sidebar
                    onClick={() =>
                      link.name !== SETTINGS &&
                      (link.name === INSTRUCTIONS
                        ? window.open(link.route[0], '_blank')
                        : history.push(link.route[0]))
                    }
                  >
                    <i className='pr-2'>{link.icon}</i>
                  </span>
                  <span className='link-name'>
                    {link.name}
                    {link.name === SETTINGS &&
                      (!settingsListOpen ? (
                        <FiChevronDown className='settings-arrow' />
                      ) : (
                        <FiChevronUp className='settings-arrow' />
                      ))}
                  </span>
                </span>
                {link.name === SETTINGS && (
                  <Collapse
                    in={open && !isMobile.matches ? settingsListOpen : false}
                    dimension='height'
                  >
                    <ListGroup
                      className={clsx('cursor-pointer settings-list', {
                        ['show-list']:
                          (!open && settingsListOpen) ||
                          (isMobile.matches && open && settingsListOpen),
                        ['hide-list']: !open && !settingsListOpen,
                        ['settings-list-active']: isSettingsRoute(),
                      })}
                      id='settings-list'
                    >
                      {settingLinks.map((link, index) => (
                        <ListGroup.Item
                          key={index}
                          onClick={e => {
                            e.stopPropagation();
                            if (isMobile.matches) setOpen(false);
                            history.push(link.route[0]);
                          }}
                          active={link.route[0] === history.location.pathname}
                          className={clsx(
                            'nav-link-nested borderless-full cursor-pointer',
                            {
                              ['nav-link-nested-active']: isSettingsRoute(),
                            },
                          )}
                        >
                          <div>{link.name}</div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Collapse>
                )}
              </ListGroup.Item>
            ))}
          {(userState.data?.roles.includes(Roles.SuperAdmin) ||
            userState.data?.roles.includes(Roles.SuperViewer)) &&
            !isSuperViewerMode() &&
            superAdminLinks.map((link, index) => (
              <ListGroup.Item
                as='li'
                className='nav-link borderless cursor-pointer '
                key={index}
                active={link.route.includes(history.location.pathname)}
                onClick={() => history.push(link.route[0])}
              >
                <span
                  className={clsx({
                    ['ml-2 d-flex']: open && !isMobile.matches,
                    ['drawer-small']: !open,
                  })}
                >
                  <span
                    className={!open || isMobile.matches ? 'drawer-small' : ''}
                    onClick={() => history.push(link.route[0])}
                  >
                    <i className='pr-2'>{link.icon}</i>
                  </span>
                  <span className='link-name'>{link.name}</span>
                </span>
              </ListGroup.Item>
            ))}
          {userState.data?.roles &&
            matchUserRole(userState.data?.roles, [Roles.Employee]) &&
            employeeSidebarLinks.map((link, index) => (
              <ListGroup.Item
                as='li'
                className='nav-link borderless cursor-pointer '
                key={index}
                active={link.route.includes(history.location.pathname)}
                onClick={() => history.push(link.route[0])}
              >
                <span
                  className={clsx({
                    ['ml-2 d-flex']: open && !isMobile.matches,
                    ['drawer-small']: !open,
                  })}
                >
                  <span
                    className={!open || isMobile.matches ? 'drawer-small' : ''}
                    onClick={() => history.push(link.route[0])}
                  >
                    <i className='pr-2'>{link.icon}</i>
                  </span>
                  <span className='link-name'>{link.name}</span>
                </span>
              </ListGroup.Item>
            ))}

          {userState.data?.roles &&
            matchUserRole(userState.data?.roles, [Roles.Analyst]) &&
            AnalystSideBarLinks.map((link, index) => (
              <ListGroup.Item
                as='li'
                className='nav-link borderless cursor-pointer '
                key={index}
                active={link.route.includes(history.location.pathname)}
                onClick={() => history.push(link.route[0])}
              >
                <span
                  className={clsx({
                    ['ml-2 d-flex']: open && !isMobile.matches,
                    ['drawer-small']: !open,
                  })}
                >
                  <span
                    className={!open || isMobile.matches ? 'drawer-small' : ''}
                    onClick={() => history.push(link.route[0])}
                  >
                    <i className='pr-2'>{link.icon}</i>
                  </span>
                  <span className='link-name'>{link.name}</span>
                </span>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Nav>
    </Col>
  );
};

export default Sidebar;
