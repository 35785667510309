import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormInput from '../../Form/FormInput';
import { IFormError } from '../../../constants/Interfaces';

interface IGroupModalForm {
  fieldError?: IFormError;
}

const GroupModalForm: React.FC<IGroupModalForm> = ({ fieldError }) => {
  const { t } = useTranslation();
  return (
    <Row className='px-2 py-2'>
      <Col sm={12}>
        <FormInput
          label={`${t('service_category_form_label_name')} *`}
          name='name'
          type='text'
          fieldError={fieldError}
        />
      </Col>
    </Row>
  );
};

export default GroupModalForm;
