/* eslint-disable no-param-reassign */
import axios, { AxiosResponse, Method, ResponseType } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { Dispatch } from 'redux';
import { TDispatch } from '../types/Thunk';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../helpers/Misc';
import { SetAppError } from '../types/Error';
import { CLIENT_ID, GRANT_TYPE_PASSWORD, GRANT_TYPE_REFRESH } from '../constants/Login';
import { API_END_POINTS } from '../constants/API';
import Env from '../constants/Env';
import { COMPANY_ID_ITEM } from 'constants/Storage';

export const apiClient = axios.create({
  baseURL: Env.REACT_APP_BASE_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authorisedClient = axios.create({
  baseURL: Env.REACT_APP_BASE_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

authorisedClient.interceptors.request.use(config => {
  if (getAccessToken()) config.headers['Authorization'] = 'Bearer ' + getAccessToken();
  config.headers['CompanyId'] = localStorage.getItem(COMPANY_ID_ITEM);
  return config;
});

export const loginClient = axios.create({
  baseURL: Env.REACT_APP_BASE_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const refreshAuthToken = (failedRequest?: any) => {
  const refreshTokenData = new URLSearchParams();
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    throw Error('No refresh token');
  }

  refreshTokenData.append('grant_type', GRANT_TYPE_REFRESH);
  refreshTokenData.append('client_id', CLIENT_ID);
  refreshTokenData.append('refresh_token', refreshToken);

  return authorisedClient
    .request({
      data: refreshTokenData,
      method: 'POST',
      url: API_END_POINTS.REFRESH_TOKEN,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(tokenRefreshResponse => {
      setAccessToken(tokenRefreshResponse.data.access_token);
      setRefreshToken(tokenRefreshResponse.data.refresh_token);
      if (failedRequest)
        failedRequest.response.config.headers['Authorization'] =
          'Bearer ' + tokenRefreshResponse.data.access_token;
      return Promise.resolve();
    })
    .catch(() => {
      setAccessToken('');
      setRefreshToken('');
      window.location.assign('/login');
    });
};

createAuthRefreshInterceptor(authorisedClient, refreshAuthToken);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const loginCall = <TAction, TRequestData, TResponse extends {} = {}>(
  action: any,
  method: Method,
  url: string,
  data: any,
  params: any = {},
) => {
  return async (dispatch: TDispatch<TAction>): Promise<AxiosResponse<TResponse>> => {
    dispatch({
      type: action.REQUEST,
    });
    const formData = new URLSearchParams();
    formData.append('username', data.username);
    formData.append('password', data.password);
    formData.append('grant_type', GRANT_TYPE_PASSWORD);
    formData.append('client_id', CLIENT_ID);

    try {
      return await loginClient
        .request<TResponse>({
          data: formData,
          method,
          params,
          url,
        })
        .then((res: AxiosResponse<TResponse>) => {
          dispatch({
            type: action.SUCCESS,
            payload: res,
          });

          return res;
        });
    } catch (err) {
      dispatch({
        type: action.FAILED,
        payload: err,
      });
      throw err;
    }
  };
};

export const apiCall = <TAction, TRequestData, TResponse extends {} = {}>(
  action: any,
  method: Method,
  url: string,
  authorized = false,
  data: TRequestData | null = {} as TRequestData,
  params: any = {},
  accessKey?: string,
  responseType: ResponseType = 'json',
) => {
  return async (dispatch: TDispatch<TAction>): Promise<AxiosResponse<TResponse>> => {
    dispatch({
      type: action.REQUEST,
    });
    try {
      if (authorized) {
        apiClient.interceptors.request.use(request => {
          request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
          request.headers['CompanyId'] = localStorage.getItem(COMPANY_ID_ITEM);
          return request;
        });
      }
      if (accessKey) {
        apiClient.interceptors.request.use(request => {
          request.headers['accessKey'] = accessKey;
          return request;
        });
      }

      apiClient.interceptors.response.use(
        response => {
          return response;
        },
        async error => {
          const originalRequest = error.config;
          // if fail to get refresh token redirect to login
          if (
            error.response?.status === 401 &&
            originalRequest.url === API_END_POINTS.REFRESH_TOKEN
          ) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem(COMPANY_ID_ITEM);
            return Promise.reject(error);
          }
          if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            try {
              const data = new URLSearchParams();
              if (!refreshToken) return;
              data.append('grant_type', GRANT_TYPE_REFRESH);
              data.append('refresh_token', refreshToken);
              data.append('client_id', CLIENT_ID);
              const refreshTokenData = await axios.post(
                API_END_POINTS.REFRESH_TOKEN,
                data,
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                },
              );
              const newUser = refreshTokenData.data.access_token;
              localStorage.setItem('token', newUser);
              localStorage.setItem('refreshToken', refreshTokenData.data.refresh_token);
              originalRequest.headers.Authorization = `Bearer ${refreshTokenData.data.access_token}`;
              return axios(originalRequest);
            } catch (err) {
              setAccessToken('');
              setRefreshToken('');
              window.location.assign('/login');
              return Promise.reject(err);
            }
          }
          return Promise.reject(error);
        },
      );

      return await apiClient
        .request<TResponse>({
          data,
          method,
          params,
          url,
          responseType,
        })
        .then((res: AxiosResponse<TResponse>) => {
          dispatch({
            type: action.SUCCESS,
            payload: res,
          });

          return res;
        });
    } catch (err) {
      dispatch({
        type: action.FAILED,
        payload: err,
      });
      throw err;
    }
  };
};

interface IErrorPayload {
  message: string;
  code: number;
}

export const errorAction = (payload: IErrorPayload) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SetAppError.SUCCESS,
      payload,
    });
  };
};
