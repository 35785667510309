import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ButtonAdd from '../../components/Utils/ButtonAdd';
import { IAppState } from '../../store/Store';
import { TLocationState, ILocation } from '../../types/Locations';
import { IFormError, ISort } from '../../constants/Interfaces';
import { handleError } from '../../middlewares/ErrorHandler';
import { getAllLocations } from '../../actions/LocationActions';
import TableSortable from '../../components/Utils/Tables/TableSortable';
import PaginationComp from '../../components/Utils/Tables/PaginationComp';
import useError from '../../hooks/useError';
import { TABLE_PAGE_SIZE } from '../../constants/UtilVariables';
import './AdminResource.scss';
import { IResource, TResourceState } from '../../types/Resources';
import { changeOffset, getResources } from '../../actions/ResourcesActions';
import AddEditResourceModal from '../../components/Admin/Resources/AddEditResourceModal';

const formateTableData = (resourceData: IResource[], locationData: ILocation[]) => {
  const newTableData = [];
  for (let i = 0; i < resourceData.length; i++) {
    const resource = resourceData[i];
    const locations: ILocation[] = [];
    if (resource.locationId) {
      const location = locationData.find(loc => {
        return loc.id === resource.locationId;
      });
      if (location) {
        locations.push(location);
      }
    }
    newTableData.push({
      ...resource,
      locations,
    });
  }
  return newTableData;
};

const AdminResource: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [tableData, setTableData] = useState<IResource[]>([]);
  const [open, setOpen] = useState(false);
  const [pages, setPages] = useState<Array<number>>();
  const [activePage, setActivePage] = useState<number>(0);
  const [resourceToEdit, setResourceToEdit] = useState<IResource>();
  const [sortQuery, setSortQuery] = useState('');

  useError(error, setError);

  const locationState: TLocationState = useSelector<IAppState, TLocationState>(
    (state: IAppState) => state.locationState,
  );

  const resourceState: TResourceState = useSelector<IAppState, TResourceState>(
    (state: IAppState) => state.resourcesState,
  );

  useEffect(() => {
    (async () => {
      try {
        //first load offset is always 0
        await dispatch(getResources(0, undefined, TABLE_PAGE_SIZE));
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getAllLocations());
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (resourceState.data && locationState.allData) {
      const formatedData = formateTableData(resourceState.data, locationState.allData);

      setTableData(formatedData || []);
      if (resourceState.total) {
        const pages = Array.from(
          Array(Math.ceil(resourceState.total / TABLE_PAGE_SIZE)).keys(),
        );
        if (pages.length > 1) setPages(pages);
      }
    }
  }, [locationState.allData, resourceState.data]);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('resources_table_col_name')}`,
        accessor: 'name',
        id: 'name',
      },
      {
        Header: `${t('resources_table_col_location')}`,
        accessor: 'locations',
        id: 'locations',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => {
          return (
            <div className='d-flex wrap'>
              {cell.value.map((location: any) => (
                <div key={location.id}>{location.name}</div>
              ))}
            </div>
          );
        },
      },
      {
        Header: `${t('resources_table_col_count')}`,
        accessor: 'count',
        id: 'count',
      },
    ],
    [tableData],
  );

  const handleSort = useCallback(async (sortBy: Array<ISort>, offset: number) => {
    try {
      if (sortBy[0].id) {
        const sortDirection = sortBy[0].desc ? 'desc' : 'asc';
        const sortQuery = `${sortBy[0].id} ${sortDirection}`;
        setSortQuery(sortQuery);
        await dispatch(getResources(offset || 0, sortQuery, TABLE_PAGE_SIZE));
      }
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  }, []);

  const changePage = async (newPage: number) => {
    setActivePage(newPage);
    dispatch(changeOffset(newPage * TABLE_PAGE_SIZE));
    await dispatch(getResources(newPage * TABLE_PAGE_SIZE, sortQuery, TABLE_PAGE_SIZE));
  };

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='mainCard'>
            <Card.Header className='title'>
              {t('resources_table_col_page_page_title')}
            </Card.Header>
            <Card.Body>
              <Row className='align-items-start'>
                <Col sm={12} className='d-flex justify-content-end'>
                  <ButtonAdd
                    text={t('resource_create_header')}
                    handleClick={() => setOpen(true)}
                  />
                </Col>
              </Row>
              <Row className='mt-4 mt-md-0'>
                <Col sm={12}>
                  <TableSortable
                    striped
                    data={tableData}
                    columns={columns}
                    onSort={handleSort}
                    selectRow={setResourceToEdit}
                    customWidth='33%'
                    applyCustomWidthTo={['name', 'count', 'locations']}
                    openModal={setOpen}
                    isLoading={resourceState.isLoading && locationState.isLoading}
                    offset={resourceState.offset}
                  />
                  <PaginationComp
                    activePage={activePage}
                    pages={pages}
                    changePage={changePage}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {open && (
        <AddEditResourceModal
          show={open}
          resourceToEdit={resourceToEdit}
          locations={locationState.allData}
          handleClose={() => {
            setOpen(false);
            setResourceToEdit(undefined);
          }}
        />
      )}
    </Container>
  );
};

export default AdminResource;
