import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ButtonAdd from '../../components/Utils/ButtonAdd';
import { IAppState } from '../../store/Store';
import { TLocationState, ILocation } from '../../types/Locations';
import { IFormError, ISort } from '../../constants/Interfaces';
import { handleError } from '../../middlewares/ErrorHandler';
import { getAllLocations } from '../../actions/LocationActions';
import TableSortable from '../../components/Utils/Tables/TableSortable';
import PaginationComp from '../../components/Utils/Tables/PaginationComp';
import useError from '../../hooks/useError';
import { TABLE_PAGE_SIZE } from '../../constants/UtilVariables';
import { IVacation, IVacationTableData, IVacationState } from '../../types/Vacations';
import { changeOffset, getVacations } from '../../actions/VacationsActions';
import AddEditVacationModal from '../../components/Admin/Vacations/AddEditVacationModal';
import './AdminVacations.scss';

const formateTableData = (vacationData: IVacation[], locationData: ILocation[]) => {
  const newTableData = [];
  for (let i = 0; i < vacationData.length; i++) {
    const vacation = vacationData[i];
    const locationsData: Array<ILocation> = [];
    if (vacation.locationIds) {
      for (let j = 0; j < vacation.locationIds.length; j++) {
        const vacationLocation = vacation.locationIds[j];
        const location = locationData.find(loc => loc.id === vacationLocation);
        if (location) {
          locationsData.push(location);
        }
      }
    }

    const startDateFormatted = moment(vacation.start).format('YYYY-MM-DD');
    const endDateFormatted = moment(vacation.end).format('YYYY-MM-DD');
    const daysNumber = moment(vacation.end).diff(moment(vacation.start), 'days') + 1;

    newTableData.push({
      ...vacation,
      interval: `${startDateFormatted} - ${endDateFormatted}`,
      daysNumber,
      locationsData,
    });
  }
  return newTableData;
};

const AdminVacations: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [tableData, setTableData] = useState<IVacationTableData[]>([]);
  const [open, setOpen] = useState(false);
  const [pages, setPages] = useState<Array<number>>();
  const [activePage, setActivePage] = useState<number>(0);
  const [vacationToEdit, setVacationToEdit] = useState<IVacation>();
  const [sortQuery, setSortQuery] = useState<string>('');

  useError(error, setError);

  const locationState: TLocationState = useSelector<IAppState, TLocationState>(
    (state: IAppState) => state.locationState,
  );

  const vacationState: IVacationState = useSelector<IAppState, IVacationState>(
    (state: IAppState) => state.vacationsState,
  );

  useEffect(() => {
    return function cleanup() {
      dispatch(changeOffset(0));
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        //first load offset is always 0
        await dispatch(getVacations(0, undefined, TABLE_PAGE_SIZE));
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getAllLocations());
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (vacationState.data && locationState.allData) {
      const formatedData = formateTableData(vacationState.data, locationState.allData);

      setTableData(formatedData || []);
      if (vacationState.total) {
        const pages = Array.from(
          Array(Math.ceil(vacationState.total / TABLE_PAGE_SIZE)).keys(),
        );
        if (pages.length > 1) setPages(pages);
      }
    }
  }, [locationState.allData, vacationState.data]);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('vacation_day_table_col_time_interval')}`,
        accessor: 'interval',
        id: 'interval',
        disableSortBy: true,
      },
      {
        Header: `${t('vacation_day_table_col_day_count')}`,
        accessor: 'daysNumber',
        id: 'daysNumber',
        disableSortBy: true,
      },
      {
        Header: `${t('vacation_day_table_col_day_location')}`,
        accessor: 'locationsData',
        id: 'locationsData',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => (
          <div className='d-flex wrap'>
            {cell.value.map((location: any) => (
              <div className='mr-1 mt-1 label' key={location.id}>
                {location.name}
              </div>
            ))}
          </div>
        ),
      },
    ],
    [tableData],
  );

  const handleSort = useCallback(async (sortBy: Array<ISort>, offset: number) => {
    try {
      if (sortBy[0].id) {
        const sortDirection = sortBy[0].desc ? 'desc' : 'asc';
        const sortQuery = `${sortBy[0].id} ${sortDirection}`;
        setSortQuery(sortQuery);
        await dispatch(getVacations(offset || 0, sortQuery, TABLE_PAGE_SIZE));
      }
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  }, []);

  const changePage = async (newPage: number) => {
    setActivePage(newPage);
    dispatch(changeOffset(newPage * TABLE_PAGE_SIZE));
    await dispatch(getVacations(newPage * TABLE_PAGE_SIZE, sortQuery, TABLE_PAGE_SIZE));
  };

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='mainCard'>
            <Card.Header className='title'>
              {t('vacation_day_table_col_page_header')}
            </Card.Header>
            <Card.Body>
              <Row className='align-items-start'>
                <Col sm={12} className='d-flex justify-content-end'>
                  <ButtonAdd
                    text={t('vacation_day_btn_create_resource')}
                    handleClick={() => setOpen(true)}
                  />
                </Col>
              </Row>
              <Row className='mt-4 mt-md-0'>
                <Col sm={12}>
                  <TableSortable
                    striped
                    data={tableData}
                    columns={columns}
                    onSort={handleSort}
                    selectRow={setVacationToEdit}
                    customWidth='27%'
                    applyCustomWidthTo={['interval']}
                    openModal={setOpen}
                    isLoading={vacationState.isLoading && locationState.isLoading}
                    offset={vacationState.offset}
                  />
                  <PaginationComp
                    activePage={activePage}
                    pages={pages}
                    changePage={changePage}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddEditVacationModal
        show={open}
        vacationToEdit={vacationToEdit}
        locations={locationState.allData}
        handleClose={() => {
          setOpen(false);
          setVacationToEdit(undefined);
        }}
      />
    </Container>
  );
};

export default AdminVacations;
