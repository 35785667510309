import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

export interface ILocation {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  creatorId?: string;
  companyId: string;
  name: string;
  phone: string;
  city: string;
  address: string;
}

export interface IGetLocations {
  data: Array<ILocation>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

export interface ILocationData {
  name: string;
  city: string;
  phone: string;
  companyId: string;
  address: string;
}

export interface TLocationState {
  readonly data?: Array<ILocation>;
  readonly allData?: Array<ILocation>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
}

export const initialLocationState: TLocationState = {
  data: undefined,
  allData: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
};

export enum LocationGet {
  REQUEST = 'LOCATION_GET_REQUEST',
  SUCCESS = 'LOCATION_GET_SUCCESS',
  FAILED = 'LOCATION_GET_FAILED',
}

export enum LocationsGetAll {
  REQUEST = 'LOCATIONS_GET_ALL_REQUEST',
  SUCCESS = 'LOCATIONS_GET_ALL_SUCCESS',
  FAILED = 'LOCATIONS_GET_ALL_FAILED',
}

export enum LocationAdd {
  REQUEST = 'LOCATION_ADD_REQUEST',
  SUCCESS = 'LOCATION_ADD_SUCCESS',
  FAILED = 'LOCATION_ADD_FAILED',
  ADD_LOCATION_STORE = 'LOCATION_ADD_TO_STORE',
}

export enum LocationEdit {
  REQUEST = 'LOCATION_EDIT_REQUEST',
  SUCCESS = 'LOCATION_EDIT_SUCCESS',
  FAILED = 'LOCATION_EDIT_FAILED',
  EDIT_LOCATION_STORE = 'LOCATION_EDIT_ADD_TO_STORE',
}

export enum LocationDelete {
  REQUEST = 'LOCATION_DELETE_REQUEST',
  SUCCESS = 'LOCATION_DELETE_SUCCESS',
  FAILED = 'LOCATION_DELETE_SUCCESS',
  DELETE_LOCATION_STORE = ' LOCATION_DELETE_FROM_STORE',
}

export enum LocationsPut {
  LOCATION_OFFSET_UPDATE = 'LOCATION_OFFSET_UPDATE',
}

export type TLocationPutAction = {
  type: LocationsPut.LOCATION_OFFSET_UPDATE;
  payload: number;
};

export type TLocationEditAction =
  | {
      type: LocationEdit.REQUEST;
    }
  | {
      type: LocationEdit.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: LocationEdit.EDIT_LOCATION_STORE;
      payload: ILocation;
    }
  | {
      type: LocationEdit.FAILED;
      payload: AxiosError;
    };

export type TLocationDeleteAction =
  | {
      type: LocationDelete.REQUEST;
    }
  | {
      type: LocationDelete.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: LocationDelete.DELETE_LOCATION_STORE;
      payload: string;
    }
  | {
      type: LocationDelete.FAILED;
      payload: AxiosError;
    };

export type TLocationAddAction =
  | {
      type: LocationAdd.REQUEST;
    }
  | {
      type: LocationAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: LocationAdd.ADD_LOCATION_STORE;
      payload: ILocation;
    }
  | {
      type: LocationAdd.FAILED;
      payload: AxiosError;
    };

export type TLocationsGetAllAction =
  | {
      type: LocationsGetAll.REQUEST;
    }
  | {
      type: LocationsGetAll.SUCCESS;
      payload: AxiosResponse<IGetLocations>;
    }
  | {
      type: LocationsGetAll.FAILED;
      payload: AxiosError;
    };

export type TLocationGetAction =
  | {
      type: LocationGet.REQUEST;
    }
  | {
      type: LocationGet.SUCCESS;
      payload: AxiosResponse<IGetLocations>;
    }
  | {
      type: LocationGet.FAILED;
      payload: AxiosError;
    };

export type TLocationsActions =
  | TLocationGetAction
  | TLocationAddAction
  | TLocationEditAction
  | TLocationDeleteAction
  | TLocationPutAction
  | TLocationsGetAllAction
  | TResetAction;
