import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormCheckbox from '../../Form/FormCheckbox';
import { Roles } from '../../../constants/Interfaces';
import FormErrorText from '../../Form/FormErrorText';

interface IRoleCheckBoxGroup {
  error?: boolean;
}

const RoleCheckBoxGroup: React.FC<IRoleCheckBoxGroup> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className='mb-2'>
        <Col xs={12}>
          <Form.Label className={error ? 'text-danger' : 'form-label'}>
            {`${t('user_form_label_roles')} *`}
          </Form.Label>
        </Col>
        <Col xs={12}>
          <FormCheckbox
            name='roles'
            label={t('user_form_label_role_admin')}
            id='adminRole'
            value={Roles.Admin}
            groupClass='checkBoxMargins'
          />
        </Col>
        <Col xs={12}>
          <FormCheckbox
            name='roles'
            label={t('user_form_label_role_employee_activity_type_pasiutuslape')}
            value={Roles.Employee}
            id='employeeRole'
            groupClass='checkBoxMargins'
          />
        </Col>
        <Col xs={12}>
          <FormCheckbox
            name='roles'
            value={Roles.Analyst}
            label={t('user_form_label_role_analytic')}
            id='AnalystRole'
            groupClass='checkBoxMargins'
          />
        </Col>
      </Row>
      {/* FIX: DIDNT FIND IN TRANSLATIONS LEAVING OLD ONE FOR NOW */}
      {error && <FormErrorText errorText={t('required')} />}
    </>
  );
};

export default RoleCheckBoxGroup;
