import { AxiosResponse } from 'axios';
import {
  LocationGet,
  LocationAdd,
  LocationEdit,
  LocationDelete,
  LocationsGetAll,
  LocationsPut,
  TLocationGetAction,
  TLocationsGetAllAction,
  IGetLocations,
  ILocationData,
} from '../types/Locations';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';

export const getAllLocations = () => {
  return apiCall<TLocationsGetAllAction, null, IGetLocations>(
    LocationsGetAll,
    'GET',
    API_END_POINTS.GET_LOCATIONS,
    true,
    null,
    { limit: 1000, Sort: 'name asc' },
  );
};

export const getLocations = (
  offset: number,
  selectedCompany?: string,
  sort?: string,
  limit?: number,
) => {
  return apiCall<TLocationGetAction, null, IGetLocations>(
    LocationGet,
    'GET',
    API_END_POINTS.GET_LOCATIONS,
    true,
    null,
    {
      limit: limit || PAGE_LIMIT,
      offset,
      CompanyId: selectedCompany,
      Sort: sort || 'name asc',
    },
  );
};

export const changeLocationOffset = (newOffset: number) => {
  return {
    type: LocationsPut.LOCATION_OFFSET_UPDATE,
    payload: newOffset,
  };
};

export const addNewLocationToStore = (locationId: string, location: ILocationData) => {
  return {
    type: LocationAdd.ADD_LOCATION_STORE,
    payload: { ...location, id: locationId },
  };
};

export const addLocation = (location: ILocationData) => {
  return async (dispatch: TDispatch<LocationAdd>): Promise<AxiosResponse<string>> => {
    dispatch({
      type: LocationAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_LOCATION,
        data: {
          name: location.name,
          phone: location.phone,
          city: location.city,
          address: location.address,
          companyId: location.companyId,
        },
      });
      dispatch({
        type: LocationAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        dispatch(addNewLocationToStore(response.data, location));
      }
      return response;
    } catch (error) {
      dispatch({
        type: LocationAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedLocationToStore = (location: ILocationData, locationId: string) => {
  return {
    type: LocationEdit.EDIT_LOCATION_STORE,
    payload: {
      ...location,
      id: locationId,
    },
  };
};

export const editLocation = (location: ILocationData, locationId: string) => {
  return async (dispatch: TDispatch<LocationEdit>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: LocationEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_LOCATION(locationId),
        data: {
          name: location.name,
          phone: location.phone,
          city: location.city,
          address: location.address,
          companyId: location.companyId,
        },
      });
      dispatch({
        type: LocationEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedLocationToStore(location, locationId));
      return response;
    } catch (error) {
      dispatch({
        type: LocationEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const deleteLocation = (locationId: string) => {
  return async (dispatch: TDispatch<LocationDelete>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: LocationDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'DELETE',
        url: API_END_POINTS.DELETE_LOCATION(locationId),
        data: {},
      });
      dispatch({
        type: LocationDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: LocationDelete.DELETE_LOCATION_STORE,
        payload: locationId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: LocationDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
