import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormSelect from '../../Form/FormSelect';
import { ILocation } from '../../../types/Locations';
import { IEmployee } from '../../../types/Employees';
import arrayMutators from 'final-form-arrays';
import './CustomWorkTimeFilters.scss';

interface IAllEmployees {
  id: string;
  fullName: string;
}

interface IMonth {
  id: string;
  name: string;
}

interface ICustomWorkTimeFilters {
  validation: (values: any) => Record<string, any>;
  handleSubmit: (values: any) => void;
  locations: ILocation[];
  employees: Array<IAllEmployees | IEmployee>;
  months: IMonth[];
  editValue?: Record<string, any>;
  handleLocationChange: (id: string) => void;
}

const CustomWorkTimeFilters: React.FC<ICustomWorkTimeFilters> = ({
  validation,
  handleSubmit,
  editValue,
  locations,
  employees,
  handleLocationChange,
  months,
}) => {
  const getYears = () => {
    let nextYear = new Date().getFullYear() + 1;
    const lastYear = nextYear - 5;
    const yearOptions = [];
    while (nextYear >= lastYear) {
      yearOptions.push({ id: nextYear, name: nextYear });
      nextYear--;
    }
    return yearOptions.reverse();
  };

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={editValue}
      validate={(values: any) => {
        const errors = validation(values);
        return errors;
      }}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit, form }) => {
        return (
          <form className='w-100' onChange={handleSubmit}>
            <Row className='px-2 py-2'>
              <Col sm={6} lg={4} xl={2}>
                <FormSelect
                  size='sm'
                  hasEmptyOption={false}
                  emptyOptionLabel=''
                  name='locationId'
                  options={locations}
                  optionValue='id'
                  optionDisplay='name'
                  onChange={e => {
                    handleLocationChange(e.target.value);
                    form.mutators.setValue('userId', '');
                  }}
                />
              </Col>
              <Col sm={6} lg={4} xl={2}>
                <FormSelect
                  size='sm'
                  hasEmptyOption={false}
                  emptyOptionLabel={''}
                  name='userId'
                  options={employees}
                  optionValue='id'
                  optionDisplay='fullName'
                />
              </Col>
              <Col sm={6} lg={4} xl={2}>
                <FormSelect
                  size='sm'
                  hasEmptyOption={false}
                  emptyOptionLabel=''
                  name='month'
                  options={months}
                  optionValue='id'
                  optionDisplay='name'
                />
              </Col>
              <Col sm={6} lg={4} xl={2}>
                <FormSelect
                  size='sm'
                  hasEmptyOption={false}
                  emptyOptionLabel=''
                  name='year'
                  options={getYears()}
                  optionValue='id'
                  optionDisplay='name'
                />
              </Col>
            </Row>
          </form>
        );
      }}
    />
  );
};

export default CustomWorkTimeFilters;
