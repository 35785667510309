import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Toast from '../components/Utils/Toast';
import { IFormError } from '../constants/Interfaces';

const useError = (
  error: IFormError | undefined,
  setError: (value: undefined) => void,
  clearState = true,
) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (error && (!error.causedByField || error.title)) {
      toast.error(<Toast text={t(error.message)} isError />);
    }
    return () => {
      if (error && clearState) {
        setError(undefined);
      } else {
        return;
      }
    };
  }, [error]);
  return null;
};

export default useError;
