import React from 'react';
import clsx from 'clsx';
import './Row.scss';

interface IRow {
  className?: string;
  paddingY?: number;
}

const Row: React.FC<IRow> = ({ className, children, paddingY }) => {
  return (
    <div
      className={clsx('experience__row', className)}
      style={{
        ...(paddingY && {
          paddingTop: `${paddingY}rem`,
          paddingBottom: `${paddingY}rem`,
        }),
      }}
    >
      {children}
    </div>
  );
};

export default Row;
