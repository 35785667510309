import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import Toast from '../../Utils/Toast';
import { TUserState } from '../../../types/User';
import { IAppState } from '../../../store/Store';
import ModalForm from './ModalForm';
import FormModal from '../../Utils/Modals/FormModal';
import AgreeToDelete from '../../Utils/Modals/AgreeToDelete';
import { IResource, IResourceData } from '../../../types/Resources';
import {
  addResource,
  deleteResource,
  editResource,
} from '../../../actions/ResourcesActions';
import { ILocation } from '../../../types/Locations';
import { IFormError } from '../../../constants/Interfaces';

interface IAddEditResourceModal {
  show: boolean;
  handleClose: () => void;
  resourceToEdit?: IResource;
  locations?: ILocation[];
}

const AddEditResourceModal: React.FC<IAddEditResourceModal> = ({
  show,
  handleClose,
  resourceToEdit,
  locations = [],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [resourceToDelete, setResourceToDelete] = useState<string>();
  useError(error, setError);

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  const formValidation = (values: IResourceData) => {
    const error: Record<string, any> = {};
    if (!values.name) {
      error.name = t('required');
    }
    if (!values.locationId) {
      error.locationId = t('required');
    }
    if (!values.count) {
      error.count = t('required');
    }
    if (values.count < 1) {
      error.count = t('resourceCountMinValueError');
    }
    return error;
  };

  const handleSubmit = async (values: IResourceData) => {
    setError(undefined);
    try {
      if (resourceToEdit) {
        await dispatch(
          editResource(
            { ...values, companyId: userState.data?.companyId || '' },
            resourceToEdit.id,
          ),
        );
        toast.success(<Toast text={t('editedResource')} />);
      } else {
        await dispatch(
          addResource({ ...values, companyId: userState.data?.companyId || '' }),
        );
        toast.success(<Toast text={t('addedResource')} />);
        handleClose();
      }
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };
  const handleDelete = async () => {
    if (resourceToDelete) {
      try {
        await dispatch(deleteResource(resourceToDelete));
        toast.success(<Toast text={t('successfullyDeleted', { item: t('resource') })} />);
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    }
    handleClose();
  };

  const openDeleteDialog = () => {
    if (resourceToEdit) {
      setResourceToDelete(resourceToEdit.id);
    }
  };

  return (
    <>
      <FormModal
        show={show}
        handleClose={handleClose}
        modalTitle={
          resourceToEdit ? t('resource_update_header') : t('resource_create_header')
        }
        validation={formValidation}
        handleSubmit={handleSubmit}
        form={<ModalForm locations={locations} fieldError={error} />}
        editValue={resourceToEdit}
        handleDelete={openDeleteDialog}
      />
      <AgreeToDelete
        show={!!resourceToDelete}
        handleClose={() => setResourceToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </>
  );
};

export default AddEditResourceModal;
