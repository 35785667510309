import { AxiosError } from 'axios';
import { TResetAction } from 'types/App';

export interface IExperiencePricings {
  id: string;
  name: string;
  duration: number;
  price: number;
  salePrice?: number;
}

export interface IServicesExperience {
  id: string;
  name: string;
  groupId: string;
  preparingTime: number;
  timeAfterService: number;
  pricings: Array<IExperiencePricings>;
  maxPersonsCount: number;
  fixedPersonsCount: boolean;
}

export interface IExperienceServicesState {
  readonly data?: Array<IServicesExperience>;
  readonly isLoading?: boolean;
}

export const initialExperienceServicesState: IExperienceServicesState = {
  data: undefined,
  isLoading: undefined,
};

export interface IServicesGetExperience {
  data: Array<IServicesExperience>;
  status: number;
  statusText: string;
}

export enum ServicesGetExperience {
  REQUEST = 'SERVICES_GET_EXPERIENCE_REQUEST',
  SUCCESS = 'SERVICES_GET_EXPERIENCE_SUCCESS',
  FAILED = 'SERVICES_GET_EXPERIENCE_FAILED',
}

export type TServicesGetExperienceAction =
  | {
      type: ServicesGetExperience.REQUEST;
    }
  | {
      type: ServicesGetExperience.SUCCESS;
      payload: IServicesGetExperience;
    }
  | {
      type: ServicesGetExperience.FAILED;
      payload: AxiosError;
    }
  | TResetAction;
