import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import EmployeeForm from './EmployeeForm';
import { IAppState } from '../../../store/Store';
import { TEmployeeState } from '../../../types/Employees';
import { getAllEmployees } from '../../../actions/EmployeesActions';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import { IFormError } from '../../../constants/Interfaces';

interface IEmployeeTab {
  errors?: Record<string, any>;
  setValue?: (location: string, value: string[]) => void;
  submitFailed?: boolean;
}

const EmployeeTab: React.FC<IEmployeeTab> = ({ errors, setValue, submitFailed }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const employeeState: TEmployeeState = useSelector<IAppState, TEmployeeState>(
    (state: IAppState) => state.employeeState,
  );

  useEffect(() => {
    (async () => {
      if (!employeeState.allData) {
        try {
          await dispatch(getAllEmployees());
        } catch (err) {
          handleError(
            err?.response?.status,
            setError,
            err.response?.data?.title,
            err.response?.data?.CausedByField,
          );
        }
      }
    })();
  }, []);

  return (
    <>
      {employeeState.isLoading ? (
        <div className='tab-loader-container'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : (
        <EmployeeForm
          setValue={setValue}
          errors={errors}
          submitFailed={submitFailed}
          employees={employeeState.allData || []}
        />
      )}
    </>
  );
};

export default EmployeeTab;
