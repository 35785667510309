import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import Modal from 'react-bootstrap/Modal';
import ReservationInfo from './ReservationInfo';
import ExperienceFormInput from 'experience/utils/form/ExperienceFormInput';
import ExperienceFormCheckBox from 'experience/utils/form/ExperienceFormCheckbox';
import ExperiencePhoneInput from 'experience/utils/form/ExperiencePhoneInput';
import { validateExperienceForm } from 'experience/helpers/validations';
import { IReservationFormValues } from 'experience/types/ExperienceFormTypes';
import { IAppState } from 'store/Store';
import { ROUTES } from 'constants/Routes';
import { handleError } from '../../../middlewares/ErrorHandler';
import { IFormError } from '../../../constants/Interfaces';
import useError from '../../../hooks/useError';
import './ReservationForm.scss';
import { addExperienceClient } from 'experience/redux/actions/ExperienceClientActions';
import { addExperienceReservationMultiple } from 'experience/redux/actions/ExperienceReservationActions';
import { IExperienceReservationData } from 'experience/types/ExperienceReservations';
import { getUserLocation } from 'experience/redux/actions/ExperienceUserLocationActions';
import { IExperienceWidgetMultipeState } from 'types/ExperienceWidgetMultiple';
import { IExperienceWidgetState } from 'types/ExperienceWidget';
import { getSelectedServices, removeSelectedServices } from 'helpers/SessionStorage';
import { setExperienceWidgetMultipleInfo } from 'experience/redux/actions/ExperienceWidgetActionMultiple';

const formatReservationData = (
  widgetState: IExperienceWidgetState,
  clientId: string,
  values: IReservationFormValues,
) => {
  const duration =
    widgetState?.service?.pricing?.duration &&
    widgetState?.service?.pricing.duration +
      (widgetState?.service?.service?.timeAfterService || 0);

  const serviceDate = moment(widgetState?.dateTimeUser?.date)
    .toISOString(true)
    .slice(0, 11);
  const serviceTimeStart = widgetState?.dateTimeUser?.time;
  const serviceTimeEnd = moment(serviceTimeStart, 'hh:mm')
    .add(duration, 'minutes')
    .format('HH:mm');
  const personsCount = widgetState?.dateTimeUser?.personCount || 1;
  const reservation: IExperienceReservationData = {
    clientId: clientId,
    locationId: widgetState?.location?.id,
    servicePricingId: widgetState?.service?.pricing?.id,
    userId: widgetState.dateTimeUser?.user,
    start: serviceDate + serviceTimeStart,
    end: serviceDate + serviceTimeEnd,
    userComments: values.comment,
    personsCount,
    voucherNumber: values.voucherNumber,
  };

  return reservation;
};

const ReservationForm: React.FC = () => {
  const [privacyModal, setPrivacyModal] = useState(false);
  const [error, setError] = useState<IFormError>();
  const [country, setCountry] = useState('lt');
  const [isNumberValid, setIsNumberValid] = useState(false);

  useError(error, setError);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch<{ lang: string; company: string }>();
  const checkboxRef = useRef<HTMLLabelElement>(null);

  const widgetStateMultiple = useSelector<IAppState, IExperienceWidgetMultipeState>(
    (state: IAppState) => state.experienceWidgetMultipeState,
  );

  useEffect(() => {
    (async () => {
      const response: any = await dispatch(getUserLocation());
      if (response.data.country) {
        setCountry(response.data.country);
      }
    })();
  }, []);

  useEffect(() => {
    try {
      const services = getSelectedServices();
      dispatch(setExperienceWidgetMultipleInfo(services || []));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const onSubmit = async (values: IReservationFormValues) => {
    if (!values.agreements) {
      checkboxRef?.current?.classList.add('experience-form-error');
      return;
    }
    const phoneRegex = /[^+0-9]+/g; // remove all whitespace and hyphens
    // add client and get clientId
    const response: any = await dispatch(
      addExperienceClient({
        name: values.name,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone.replaceAll(phoneRegex, ''),
      }),
    );
    const clientId = response.data;

    // add reservation
    const reservationsInfo: Array<IExperienceReservationData> = [];

    widgetStateMultiple.selectedServices?.forEach(widgetState => {
      reservationsInfo.push(formatReservationData(widgetState, clientId, values));
    });

    try {
      await dispatch(addExperienceReservationMultiple(reservationsInfo));
      removeSelectedServices();
    } catch (error) {
      handleError(error?.response?.status, setError, error.response?.data?.title);
      return;
    }

    history.push(
      ROUTES.EXPERIENCE.CHECKOUT.replace(':company', params.company).replace(
        ':lang',
        params.lang,
      ),
    );
  };

  const handlePrivacyClose = () => {
    setPrivacyModal(false);
  };

  const handlePrivacyOpen = () => {
    setPrivacyModal(true);
  };

  const validateForm = (values: IReservationFormValues, isNumberValid: boolean) => {
    const errors = validateExperienceForm(values, isNumberValid);
    Object.keys(errors).map(key => (errors[key] = t(`${errors[key]}`)));
    return errors;
  };

  return (
    <>
      <FinalForm
        onSubmit={onSubmit}
        validate={values => validateForm(values, isNumberValid)}
        render={({ handleSubmit, submitting, form }) => (
          <form onSubmit={handleSubmit} className='reservation-form'>
            <div className='reservation-form__inputs'>
              <div className='reservation-form__inputs--two'>
                <label className='experience-input-block__label'>
                  {t('reservation_form_name_label')}
                </label>
                <div className='reservation-form__inputs--inline'>
                  <ExperienceFormInput
                    name='name'
                    type='text'
                    className='reservation-form__inputs--small'
                  />
                  <ExperienceFormInput
                    name='lastName'
                    type='text'
                    className='reservation-form__inputs--small'
                  />
                </div>
              </div>
              <ExperienceFormInput
                name='email'
                type='text'
                label={t('reservation_form_email_label') + ' *'}
              />
              <ExperiencePhoneInput
                name='phone'
                defaultCountry={country.toLowerCase()}
                type='text'
                label={t('reservation_form_phone_label') + ' *'}
                onBlur={(_e: React.FocusEvent<HTMLInputElement>, isValid: boolean) => {
                  if (isNumberValid !== isValid) setIsNumberValid(isValid);
                }}
              />
              <ExperienceFormInput
                name='voucherNumber'
                type='text'
                label={t('reservation_form_voucher_label')}
              />
              <ExperienceFormInput
                name='comment'
                type='textarea'
                label={t('reservation_form_comment_label')}
              />
              <ExperienceFormCheckBox
                name='agreements'
                checkboxRef={checkboxRef}
                label={
                  <Trans
                    i18nKey='reservation_form_agreements_checkbox'
                    defaults='I have read and accept the <a>terms and conditions</a>'
                    components={{
                      a: (
                        <span
                          className='reservation-form__privacy-link'
                          onClick={handlePrivacyOpen}
                        />
                      ),
                    }}
                  />
                }
              />
            </div>

            <ReservationInfo submitting={submitting} />
          </form>
        )}
      />
      {privacyModal && (
        <Modal show={privacyModal} onHide={handlePrivacyClose} size='xl'>
          <Modal.Header closeButton />
          <Modal.Body>
            <div
              className='reservation-form__privacy-modal'
              dangerouslySetInnerHTML={{
                __html: renderToString(
                  <Trans
                    i18nKey='privacyPolicy:experience_privacy_policy'
                    components={{
                      h1: <h2 className='font-weight-normal' />,
                      h2: <h5 className='font-weight-normal' />,
                    }}
                  />,
                )
                  .split('\n')
                  .join('<br />')
                  .split('\t')
                  .join('&nbsp; &nbsp; &nbsp; &nbsp;'),
              }}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ReservationForm;
