import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import LocationForm from '../../Admin/Employees/LocationForm';
import { IAppState } from '../../../store/Store';
import { TLocationState } from '../../../types/Locations';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import { getAllLocations } from '../../../actions/LocationActions';

import './LocationTab.scss';
import { IFormError } from '../../../constants/Interfaces';

interface ILocationTab {
  errors?: Record<string, any>;
  setValue?: (location: string, value: string[]) => void;
  submitFailed?: boolean;
}

const LocationTab: React.FC<ILocationTab> = ({ errors, setValue, submitFailed }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const locationState: TLocationState = useSelector<IAppState, TLocationState>(
    (state: IAppState) => state.locationState,
  );

  useEffect(() => {
    (async () => {
      if (!locationState.allData) {
        try {
          await dispatch(getAllLocations());
        } catch (err) {
          handleError(
            err?.response?.status,
            setError,
            err.response?.data?.title,
            err.response?.data?.CausedByField,
          );
        }
      }
    })();
  }, []);

  return (
    <>
      {locationState.isLoading ? (
        <div className='tab-loader-container'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : (
        <LocationForm
          setValue={setValue}
          errors={errors}
          submitFailed={submitFailed}
          locations={locationState.allData || []}
        />
      )}
    </>
  );
};

export default LocationTab;
