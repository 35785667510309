import React from 'react';
import Button from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';

interface IButtonAdd {
  text: string;
  btnSize?: 'sm' | 'lg';
  btnClass?: string;
  icon?: any;
  handleClick: () => void;
}

const ButtonAdd: React.FC<IButtonAdd> = ({
  text,
  handleClick,
  btnSize,
  btnClass,
  icon,
}) => {
  return (
    <Button
      className={`btn-info centerIcon ${btnClass && btnClass}`}
      size={btnSize}
      onClick={handleClick}
    >
      {icon ? icon : <FaPlus className='pr-1 mr-1' />}
      {text}
    </Button>
  );
};

export default ButtonAdd;
