import clsx from 'clsx';
import React from 'react';
import './Button.scss';

export enum ButtonSize {
  SM = 'sm',
  LG = 'lg',
  FULL_WIDTH = '100',
  HOLLOW_LG = 'hollow-lg',
}

interface IButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size: ButtonSize;
}

const Button: React.FC<IButton> = ({ size = ButtonSize.SM, children, ...props }) => {
  return (
    <button
      className={clsx('experience__button', `experience__button--size-${size}`)}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
