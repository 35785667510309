import { apiCall } from 'actions/BaseAction';
import { API_END_POINTS } from 'constants/API';
import {
  CompanyGetExperience,
  ICompanyGetExperience,
  TCompanyGetExperienceAction,
} from 'experience/types/ExperienceCompany';

export const getCompanyExperience = (accessKey: string) => {
  return apiCall<TCompanyGetExperienceAction, any, ICompanyGetExperience>(
    CompanyGetExperience,
    'GET',
    API_END_POINTS.GET_COMPANY_EXPERIENCE,
    false,
    {},
    {},
    accessKey,
  );
};
