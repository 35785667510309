export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  TERMS: '/terms',
  POLICY: '/privacy-policy',
  SUPER_ADMIN: {
    MAIN: '/superadmin',
    COMPANIES: '/superadmin/companies',
    WORKERS: '/superadmin/user',
  },
  ADMIN: {
    MAIN: '/admin',
    RESERVATIONS: '/admin/reservation',
    CLIENTS: '/admin/clients',
    REPORTS: '/admin/report',
    PROFILE: '/admin/users/profile',
    INSTRUCTION: '/admin/instruction',
    INSTRUCTION_EXTERNAL:
      'https://geradovana.atlassian.net/wiki/external/MWViZWJkOTJmZDc4NGUwNDhhYjE1MDBmYzM1ODZhZjE',
  },
  ADMIN_SETTINGS: {
    SERVICES: '/admin/services',
    USERS: '/admin/users',
    RESOURCES: '/admin/resources',
    EMPLOYEE_CALENDAR: '/admin/employee-calendar',
    VACATION_DAYS: '/admin/vacation-days',
    LOCATIONS: '/admin/locations',
    RESERVATION_SETTINGS: '/admin/settings/save',
    NOTIFICATIONS: '/admin/settings/notifications',
    CLIENT_IMPORT: '/admin/clients/import',
  },
  EXPERIENCE: {
    SERVICES: '/experience/:lang/:company/services',
    TIME: '/experience/:lang/:company/time',
    FORM: '/experience/:lang/:company/form',
    CHECKOUT: '/experience/:lang/:company/reservation',
    NO_LANGUAGE: '/experience/:lang/',
    MAIN: '/experience',
  },
  GOOGLE: {
    APPROVED: '/google/approved',
    DENIED: '/google/denied',
    ERROR: '/google/error',
  },
};
