import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from '../../../types/Locations';
import { IPricingModal, IServices } from '../../../types/Services';
import { IServiceGroup } from '../../../types/ServiceGroups';
import {
  IReservationWithInfo,
  ReservationCustomIds,
  ReservationCustomPricingIds,
} from '../../../types/Reservations';
import { Accordion, ListGroup } from 'react-bootstrap';
import ReservationPricings from './ReservationPricings';
import TimeSelectInput from '../../Utils/Inputs/TimeSelectInput';
import { timeConvert } from 'helpers/DateTime';
import { formatCurrency, getInitialPrice } from 'helpers/Currency';
import { useCallback } from 'react';
import { useStoreState } from 'hooks/useStoreState';
import { convertToHoursAndMinutes } from 'reducers/ServicesReducer';

interface IReservationFormServices {
  locations: ILocation[];
  services: IServices[];
  serviceGroups: IServiceGroup[];
  reservationToEdit?: IReservationWithInfo;
  values?: any;
  setValue?: (name: string, value: any) => void;
  selectedServiceId: string;
  setSelectedServiceId: (id: string) => void;
  selectedServiceGroupId?: string;
  setSelectedServiceGroupId: (id: string) => void;
  selectedLocationId: string;
  setSelectedLocationId: (id: string) => void;
  selectedPricingId: string;
  setSelectedPricingId: (id: string) => void;
  hasPreselectedLocation: boolean;
  clearUserAndTime: () => void;
  maxPeople: number;
  isMultiReservation: boolean;
  errors?: Record<string, any>;
}

interface IFormattedData {
  [locationId: string]: {
    [serGroup: string]: IServices[];
  };
}

const ReservationFormServices: FC<IReservationFormServices> = ({
  reservationToEdit,
  locations,
  serviceGroups,
  services,
  values,
  setValue,
  selectedServiceId,
  setSelectedServiceId,
  selectedServiceGroupId,
  setSelectedServiceGroupId,
  selectedLocationId,
  setSelectedLocationId,
  selectedPricingId,
  setSelectedPricingId,
  hasPreselectedLocation,
  clearUserAndTime,
  maxPeople,
  isMultiReservation,
  errors,
}) => {
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState<IFormattedData>({});
  const [openedAccordionItems, setOpenedAccordionItems] = useState<string[]>([]);

  const { reservationSettingsState } = useStoreState();

  useEffect(() => {
    const data: IFormattedData = {};
    services.forEach(ser => {
      ser.locationIds?.forEach((loc: string) => {
        if (!data[loc]) {
          data[loc] = {};
        }
        if (!data[loc][ser.groupId]) {
          data[loc][ser.groupId] = [];
        }
        data[loc][ser.groupId].push(ser);
      });
      if (reservationToEdit?.serviceId === ser.id) {
        setSelectedServiceGroupId(ser.groupId);
        setOpenedAccordionItems([
          reservationToEdit.locationId,
          reservationToEdit.locationId + ser.groupId,
        ]);
      }
    });
    setFormattedData(data);
  }, []);

  useEffect(() => {
    if (selectedPricingId && !values.servicePricing?.id) {
      const slectedpricing = services
        .find(rec => rec.id === selectedServiceId)
        ?.pricings.find(rec => rec.id === selectedPricingId);
      setValue?.('servicePricing', slectedpricing);
      setValue?.(
        'timeAfterService',
        services.find(rec => rec.id === selectedServiceId)?.timeAfterService,
      );
    }
  }, [values.servicePricing]);

  useEffect(() => {
    setValue?.('serviceId', selectedServiceId);
    setValue?.('userId', '');
  }, [selectedServiceId]);

  useEffect(() => {
    setValue?.('locationId', selectedLocationId);
  }, [selectedLocationId, values.locationIds]);

  const handleAccordionToggleChange = (selectedId: string) => {
    if (isMultiReservation) return;

    if (openedAccordionItems.includes(selectedId)) {
      const newAccordionItems = openedAccordionItems.filter(id => id !== selectedId);
      setOpenedAccordionItems(newAccordionItems);
    } else {
      setOpenedAccordionItems([...openedAccordionItems, selectedId]);
    }
  };

  const isEditingReservation = !!reservationToEdit?.id?.length;
  const isEditingMultiReservation =
    reservationToEdit?.id === ReservationCustomIds.EditMultiple;
  const isCreatingResToGroup =
    reservationToEdit?.id === ReservationCustomIds.CreateNewToGroup;

  const handleServiceClick = (
    service: IServices,
    pricing: IPricingModal,
    locId: string,
    groupId: string,
  ) => {
    /* This is called when user click on expanded service */
    const { hours: hoursAfterService, minutes: minutesAfterService } =
      convertToHoursAndMinutes(service.timeAfterService || 0);
    setSelectedServiceId(service.id);
    setValue?.('locationId', selectedLocationId);
    setSelectedLocationId(locId);
    setSelectedServiceGroupId(groupId);
    setSelectedPricingId(pricing.id);
    setValue?.('servicePricing', {
      ...pricing,
      hours: pricing.hours || '0',
      minutes: pricing.minutes || '0',
      hoursAfterService: hoursAfterService || '0',
      minutesAfterService: minutesAfterService || '0',
      personsCount: 1,
    });

    setValue?.('timeAfterService', service.timeAfterService);
    setValue?.('isBlockTime', false);
    clearUserAndTime();
  };

  const handleBlockTimeSelect = useCallback(
    (locId: string) => {
      if (isMultiReservation || isEditingReservation) return;

      handleAccordionToggleChange(locId + ReservationCustomPricingIds.BlockTime);
      setSelectedServiceId('');
      setValue?.('locationId', selectedLocationId);
      setSelectedLocationId(locId);
      setSelectedServiceGroupId(ReservationCustomPricingIds.BlockTime);
      setSelectedPricingId('');

      setValue?.('servicePricing', {
        personsCount: 0,
        duration: 0,
        price: 0,
        id: '',
      });
      setValue?.('timeAfterService', 0);
      setValue?.('isBlockTime', true);
    },
    [selectedLocationId],
  );

  const disableReservationPriceChange = isEditingMultiReservation;
  const disableServiceChange = isEditingMultiReservation;

  return (
    <>
      {Object.keys(formattedData).map(locId => {
        return (
          <Accordion key={locId} defaultActiveKey={selectedLocationId}>
            {!hasPreselectedLocation && locations.find(loc => loc.id === locId)?.name && (
              <Accordion.Toggle
                as={ListGroup.Item}
                className={
                  openedAccordionItems.includes(locId)
                    ? 'opened acc-toggle'
                    : 'acc-toggle'
                }
                active={selectedLocationId === locId}
                eventKey={locId}
                onClick={() => handleAccordionToggleChange(locId)}
              >
                {locations.find(loc => loc.id === locId)?.name}
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey={locId}>
              <>
                {Object.keys(formattedData[locId]).map(groupId => (
                  <Accordion
                    key={groupId}
                    defaultActiveKey={selectedLocationId + selectedServiceGroupId}
                  >
                    <Accordion.Toggle
                      as={ListGroup.Item}
                      active={
                        selectedLocationId === locId && selectedServiceGroupId === groupId
                      }
                      className={
                        openedAccordionItems.includes(locId + groupId)
                          ? 'opened acc-toggle'
                          : 'acc-toggle'
                      }
                      onClick={() => handleAccordionToggleChange(locId + groupId)}
                      eventKey={locId + groupId}
                    >
                      {serviceGroups.find(group => group.id === groupId)?.name}
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey={locId + groupId}>
                      <Accordion
                        defaultActiveKey={
                          locId + groupId + selectedServiceId + selectedPricingId
                        }
                      >
                        {formattedData[locId][groupId].map(service => (
                          <div key={service.id}>
                            {service.pricings.map(pricing => {
                              const thisServiceIsSelected =
                                selectedLocationId === locId &&
                                selectedServiceGroupId === groupId &&
                                selectedServiceId === service.id &&
                                selectedPricingId === pricing.id;
                              return (
                                <div key={pricing.id} className='service-wrap'>
                                  <Accordion.Toggle
                                    eventKey={locId + groupId + service.id + pricing.id}
                                    as={ListGroup.Item}
                                    className='acc-toggle no-icon service d-block'
                                    active={thisServiceIsSelected}
                                    disabled={
                                      thisServiceIsSelected || disableServiceChange
                                    }
                                    onClick={() =>
                                      handleServiceClick(service, pricing, locId, groupId)
                                    }
                                  >
                                    <div>{service.name}</div>
                                    <div className='pricing'>
                                      <span>
                                        {`${pricing.name || '-'} ${getInitialPrice(
                                          pricing.salePrice,
                                          pricing.price,
                                        )}${formatCurrency(
                                          reservationSettingsState?.data?.currency,
                                        )} ${timeConvert(pricing.duration)}`}
                                      </span>
                                    </div>
                                  </Accordion.Toggle>

                                  <Accordion.Toggle
                                    eventKey={locId + groupId + service.id + pricing.id}
                                    as='div'
                                  >
                                    <div className='pricing-table'>
                                      {pricing.id === selectedPricingId &&
                                        !isEditingMultiReservation &&
                                        !isCreatingResToGroup &&
                                        thisServiceIsSelected && (
                                          <ReservationPricings
                                            disabled={disableReservationPriceChange}
                                            maxPeople={maxPeople}
                                            errors={errors}
                                          />
                                        )}
                                    </div>
                                  </Accordion.Toggle>
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </Accordion>
                    </Accordion.Collapse>
                  </Accordion>
                ))}

                <ListGroup.Item
                  className='acc-toggle text-danger'
                  onClick={() => handleBlockTimeSelect(locId)}
                >
                  {t('reservation_block_time')}
                </ListGroup.Item>
                {selectedLocationId === locId &&
                  selectedServiceGroupId === ReservationCustomPricingIds.BlockTime && (
                    <TimeSelectInput
                      name='servicePricing.duration'
                      inputClass='mb-0 block-time-select'
                    />
                  )}
              </>
            </Accordion.Collapse>
          </Accordion>
        );
      })}
    </>
  );
};

export default ReservationFormServices;
