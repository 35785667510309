import lodash from 'lodash';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, FieldInputProps, useFormState } from 'react-final-form';

interface IFormCheckbox {
  name: string;
  id: string;
  label?: string;
  groupClass?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  swapValues?: boolean;
  disableArrayFormat?: boolean;
  checked?: boolean;
  formStateName?: string;
}

interface IChecked {
  disableArrayFormat?: boolean;
  swapValues?: boolean;
  input: FieldInputProps<string, HTMLElement>;
  checked?: boolean;
}

const isChecked = ({ disableArrayFormat, swapValues, input, checked }: IChecked) => {
  const defaultCheck = swapValues ? !input.checked : input.checked;
  if (!disableArrayFormat) return defaultCheck;
  return checked;
};

const normalise = (value: any) => {
  if (Array.isArray(value)) return value[0];
  return value;
};

const FormCheckbox: React.FC<IFormCheckbox> = ({
  name,
  label,
  id,
  groupClass,
  value,
  onChange,
  swapValues,
  disableArrayFormat,
  checked,
  formStateName,
}) => {
  const { values: formState } = useFormState();

  return (
    <Field
      name={name}
      parse={disableArrayFormat ? normalise : undefined}
      component='input'
      type='checkbox'
      value={value}
    >
      {({ input, meta }) => (
        <Form.Group className={groupClass}>
          <Form.Check type='checkbox' id={id}>
            <Form.Check.Input
              {...input}
              checked={isChecked({
                disableArrayFormat,
                swapValues,
                input,
                checked,
              })}
              type='checkbox'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const modified = { ...e };
                modified.target.checked = swapValues
                  ? !e.target.checked
                  : e.target.checked;

                if (disableArrayFormat && formStateName) {
                  if (checked) {
                    // we need this to remove the value from the array !!
                    const index = lodash.findIndex(
                      formState[formStateName],
                      (item: Record<string, any>) => item?.id === value,
                    );
                    lodash.set(formState, `${formStateName}[${index}]`, {});
                    const customModifedObject = {
                      ...modified,
                      target: { ...modified.target, checked: false, value: '' },
                    };
                    input.onChange(customModifedObject);
                    onChange?.(customModifedObject);
                  } else {
                    input.onChange(modified);
                    onChange?.(modified);
                  }
                } else {
                  input.onChange(modified);
                  onChange?.(modified);
                }
              }}
            />
            <Form.Check.Label
              className={
                meta.error && meta.touched && meta.submitFailed
                  ? 'text-danger'
                  : 'form-check-label'
              }
            >
              {label}
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>
      )}
    </Field>
  );
};

export default FormCheckbox;
