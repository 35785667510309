import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DateRange, extendMoment } from 'moment-range';
import FullCalendar, { EventContentArg, EventInput } from '@fullcalendar/react';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import * as Moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import interactionPlugin from '@fullcalendar/interaction';
import 'react-datepicker/dist/react-datepicker.css';
import { IEmployer } from '../../../types/Calendar';
import { IFormError } from '../../../constants/Interfaces';
import { getAllEmployees } from '../../../actions/EmployeesActions';
import { getLocations } from '../../../actions/LocationActions';
import useError from '../../../hooks/useError';
import './ReservationSmallCalendar.scss';
import {
  IReservation,
  IReservationAvailability,
  IReservationData,
  IReservationWithInfo,
  ReservationStatus,
  ReservationTypes,
} from '../../../types/Reservations';
import {
  clearAllReservationsInStore,
  getAllReservations,
} from '../../../actions/ReservationsActions';
import EventsContent from '../../../components/Admin/Calendar/EventsContent';
import { getAllServices } from '../../../actions/ServicesActions';
import { Spinner } from 'react-bootstrap';
import { getAllClients } from '../../../actions/ClientsActions';
import { SettingsTimezones } from '../../../types/ReservationSettings';
import { getReservationSettings } from '../../../actions/ReservationSettingsActions';
import useGetDataForRedux from '../../../hooks/useGetDataForRedux';
import { useTranslation } from 'react-i18next';
import { formateCalendarDataSmall } from './FormattedCalendarData';
import { handleError } from '../../../middlewares/ErrorHandler';
import Env from '../../../constants/Env';
import { formateDate } from 'helpers/DateTime';
import { useStoreState } from 'hooks/useStoreState';
import {
  checkReservationsOverlapping,
  isBadAutoUserSelection,
  isOverlapping,
} from 'helpers/CalendarUtils';

const moment = extendMoment(Moment as any);

interface IReservationSmallCalendar {
  selectedUserId: string;
  selectedLocationId: string | undefined;
  availableTimeSlots: IReservationAvailability[];
  selectedDate: string;
  selectedServiceId: string;
  setSelectedStartDate: (date: string | null) => void;
  setCalendarAutoUserError: (error: boolean) => void;
  setCalendarWorkingHoursError: (error: boolean) => void;
  reservationValues?: IReservationWithInfo;
  reservationToEdit: IReservationWithInfo;
}

const ReservationSmallCalendar: React.FC<IReservationSmallCalendar> = ({
  selectedUserId,
  selectedLocationId,
  availableTimeSlots,
  selectedDate,
  setSelectedStartDate,
  reservationValues,
  setCalendarAutoUserError,
  setCalendarWorkingHoursError,
  reservationToEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [error, setError] = useState<IFormError>();
  const [calendarData, setCalendarData] = useState<EventInput[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedUserReservations, setSelectedUserReservations] =
    useState<IReservation[]>();
  const [autoUserReservations, setAutoUserReservations] = useState<IReservation[]>();
  const [reservationPreview, setReservationPreview] = useState<IReservationData>();
  const [emplToShow, setEmplToShow] = useState<IEmployer[]>();

  const calendarRef: React.RefObject<FullCalendar> = useRef<FullCalendar>(null);
  //redux state
  const {
    employeeState,
    reservationsState,
    servicesState,
    clientsState,
    reservationSettingsState,
    locationState,
  } = useStoreState();

  useEffect(() => {
    (async () => {
      try {
        await dispatch(clearAllReservationsInStore());
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    })();
  }, [dispatch]);

  //hooks
  useError(error, setError);
  // get data to redux state
  useGetDataForRedux(setError, employeeState.allData, getAllEmployees());
  useGetDataForRedux(setError, reservationSettingsState.data, getReservationSettings());
  useGetDataForRedux(setError, servicesState.allData, getAllServices());
  useGetDataForRedux(setError, clientsState.allData, getAllClients());
  useGetDataForRedux(setError, locationState.data, getLocations(0));

  useEffect(() => {
    (async () => {
      try {
        if (!selectedDate) return;
        const dayFromWhichToAdd = moment(selectedDate).get('date');
        const endDay = formateDate(
          moment(selectedDate)
            .date(dayFromWhichToAdd + 1)
            .hours(23)
            .minutes(59),
        );
        await dispatch(
          getAllReservations({
            dateFrom: formateDate(moment(selectedDate).hours(0)),
            dateTo: endDay,
          }),
        );
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    })();
  }, [dispatch, selectedDate]);

  useEffect(() => {
    let blockedTime = reservationsState.data
      ? reservationsState.data.filter(
          res =>
            res.type === ReservationTypes.BLOCK &&
            res.locationId === selectedLocationId &&
            res.id !== reservationValues?.id &&
            (res.start.includes(selectedDate) || res.end.includes(selectedDate)),
        )
      : [];
    let reservations = reservationsState.data
      ? reservationsState.data.filter(
          res =>
            res.type === ReservationTypes.REGULAR &&
            res.locationId === selectedLocationId &&
            res.status !== ReservationStatus.CANCELLED &&
            res.id !== reservationValues?.id &&
            (res.start.includes(selectedDate) || res.end.includes(selectedDate)),
        )
      : [];

    if (selectedUserId && selectedUserId !== 'auto') {
      reservations = reservations.filter(res => res.userId === selectedUserId);
      blockedTime = blockedTime.filter(res => res.userId === selectedUserId);
      setSelectedUserReservations([...reservations, ...blockedTime]);
      setAutoUserReservations([]);
    } else {
      setSelectedUserReservations([]);
      setAutoUserReservations([...reservations, ...blockedTime]);
    }
  }, [reservationsState.data, selectedDate, selectedUserId]);

  useEffect(() => {
    if (
      servicesState.allData &&
      selectedUserReservations &&
      clientsState.allData &&
      employeeState.allData &&
      availableTimeSlots &&
      selectedUserId
    ) {
      const calendarData = formateCalendarDataSmall(
        servicesState.allData,
        reservationPreview
          ? [reservationPreview, ...selectedUserReservations]
          : selectedUserReservations,
        clientsState.allData,
        employeeState.allData,
        availableTimeSlots,
        selectedUserId,
        selectedDate,
        reservationToEdit,
        reservationPreview,
      );

      setCalendarData(calendarData);
      setLoading(false);
    }
  }, [
    servicesState.allData,
    selectedUserReservations,
    clientsState.allData,
    employeeState.allData,
    availableTimeSlots,
    selectedUserId,
    reservationPreview,
    selectedDate,
  ]);

  // filter to get which employees to show in the calendar
  useEffect(() => {
    if (employeeState.allData && selectedUserId && selectedLocationId) {
      const employeeToShow = employeeState.allData.find(emp => emp.id === selectedUserId);
      setEmplToShow([
        {
          id: selectedUserId,
          locationIds: [selectedLocationId],
          title: employeeToShow
            ? employeeToShow.name
            : t('reservation_auto_employee_title'),
        },
      ]);
    }
  }, [employeeState.allData, selectedLocationId, selectedUserId]);

  useEffect(() => {
    if (selectedDate && calendarRef.current) {
      calendarRef?.current?.getApi().gotoDate(selectedDate);
    }
  }, [selectedDate, calendarRef.current]);

  const handleEventAdd = (eventInfo: any) => {
    // console.log(`handleEventAdd`, eventInfo);
    updateReservationPreview(eventInfo.dateStr, eventInfo.dateStr);
  };

  const isSelectedTimeOutOfWorkingHours = useCallback(
    (selectedTime: DateRange) => {
      let isOutOfWorkingHours = true;
      if (availableTimeSlots) {
        const newTimes = [...availableTimeSlots];
        const sortedAvailableTimes = newTimes.filter(rec => rec.start && rec.end);
        sortedAvailableTimes.sort((a, b) => moment(a.start).diff(moment(b.start)));

        const unavailableRanges: DateRange[] = [];

        sortedAvailableTimes.forEach((avTime, avIndex) => {
          if (avIndex === 0) {
            unavailableRanges.push(
              moment.range(
                moment(selectedTime.start.format('YYYY-MM-DD 00:00:01')),
                moment(avTime.start),
              ),
            );
          } else {
            unavailableRanges.push(
              moment.range(
                moment(sortedAvailableTimes[avIndex - 1].end),
                moment(avTime.start),
              ),
            );
          }
        });

        unavailableRanges.push(
          moment.range(
            moment(sortedAvailableTimes[sortedAvailableTimes.length - 1]?.end),
            moment(selectedTime.start.format('YYYY-MM-DD 23:59:59')),
          ),
        );

        if (unavailableRanges.some(unRange => unRange.intersect(selectedTime))) {
          isOutOfWorkingHours = true;
        } else if (
          newTimes.some(timeSlot =>
            //check if available timeslot contains selected time
            isOverlapping(
              timeSlot.start,
              timeSlot.end,
              selectedTime.start.toString(),
              selectedTime.end.toString(),
            ),
          )
        ) {
          isOutOfWorkingHours = false;
        }
      }
      return isOutOfWorkingHours;
    },
    [availableTimeSlots],
  );

  useEffect(() => {
    setCalendarAutoUserError(
      isBadAutoUserSelection(
        reservationValues,
        availableTimeSlots,
        autoUserReservations,
        selectedUserId,
        selectedUserReservations,
      ),
    );

    if (
      !selectedUserId ||
      selectedUserId === 'auto' ||
      !reservationValues?.end ||
      !reservationValues?.start
    ) {
      setCalendarWorkingHoursError(false);
      setCalendarAutoUserError(false);
      return;
    }

    const selectedDateRange = moment.range(
      moment(reservationValues?.start),
      moment(reservationValues?.end),
    );

    setCalendarWorkingHoursError(isSelectedTimeOutOfWorkingHours(selectedDateRange));
  }, [
    selectedUserId,
    reservationValues?.start,
    reservationValues?.end,
    availableTimeSlots,
    selectedUserReservations,
  ]);

  useEffect(() => {
    if (reservationValues) {
      const {
        status,
        companyId,
        clientId,
        servicePricing: { duration, price, id },
        locationId,
        serviceId,
        userComments,
        userId,
        timeAfterService,
        start,
        client,
      } = reservationValues;
      const calculatedDuration = serviceId
        ? Number(duration) + Number(timeAfterService || 0)
        : Number(duration);
      const endDate = moment(start)
        .add(calculatedDuration, 'minutes')
        .format('YYYY-MM-DD[T]HH:mm:ss');

      if (endDate === start || !start) {
        setReservationPreview(undefined);
      } else {
        setReservationPreview({
          status,
          client,
          companyId,
          clientId,
          duration,
          end: endDate,
          locationId,
          price,
          serviceId,
          servicePricingId: id,
          start,
          userComments,
          userId,
          isPreview: true,
          initialData: reservationToEdit,
        });
      }
    }
  }, [reservationValues]);

  const updateReservationPreview = (startStr: string, endStr: string) => {
    const startDate = startStr.slice(0, 19);
    let endDate = endStr.slice(0, 19);

    if (reservationValues) {
      const {
        servicePricing: { duration },
        serviceId,
        timeAfterService,
      } = reservationValues;
      const calculatedDuration = serviceId
        ? Number(duration) + Number(timeAfterService || 0)
        : Number(duration);
      endDate = moment(startDate)
        .add(calculatedDuration, 'minutes')
        .format('YYYY-MM-DD[T]HH:mm:ss');
    }
    const selectedDateRange = moment.range(moment(startDate), moment(endDate));

    // console.log(
    //   `updateReservationPreview`,
    //   startStr,
    //   endStr,
    //   startDate,
    //   endDate,
    //   selectedDateRange,
    //   reservationValues,
    // );

    //check if selected time is already reserved for current user
    if (
      checkReservationsOverlapping(
        selectedDateRange,
        selectedUserReservations || [],
        selectedUserReservations,
        reservationPreview,
      )
    ) {
      setSelectedStartDate(startDate);
    }
  };

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xs={12} className='p-0'>
          {selectedUserId && selectedLocationId && selectedDate && (
            <Card className='mainCard'>
              <Card.Body>
                <Row>
                  <Col>
                    {!loading && reservationSettingsState.data ? (
                      <FullCalendar
                        schedulerLicenseKey={Env.REACT_APP_CALENDAR_LICENSE_KEY}
                        plugins={[
                          resourceTimeGridPlugin,
                          scrollGridPlugin,
                          interactionPlugin,
                          momentTimezonePlugin,
                        ]}
                        nowIndicator
                        dayMinWidth={150}
                        initialView='resourceTimeGridDay'
                        headerToolbar={{ left: '', right: '' }}
                        timeZone={`Europe/${reservationSettingsState.data?.calendarTimeZone}`}
                        locale={
                          reservationSettingsState.data?.calendarTimeZone ===
                          SettingsTimezones.VILNIUS
                            ? 'lt'
                            : 'pl'
                        }
                        selectable
                        height='340px'
                        allDaySlot={false}
                        slotDuration='00:15:00'
                        slotLabelFormat={{
                          hour: 'numeric',
                          minute: '2-digit',
                          hour12: false,
                          meridiem: 'short',
                        }}
                        dateClick={(selectionInfo: any) => {
                          handleEventAdd(selectionInfo);
                        }}
                        slotMinTime='00:00'
                        slotMaxTime='24:00'
                        resources={emplToShow}
                        ref={calendarRef}
                        dayHeaderContent={(args: { date: string }) =>
                          moment(args.date).format('dd. MMMM D')
                        }
                        resourceLabelClassNames={['resource-label']}
                        events={calendarData}
                        eventContent={(eventContent: EventContentArg) => (
                          <EventsContent eventContent={eventContent} smallCalendar />
                        )}
                        businessHours={false}
                      />
                    ) : (
                      <Spinner animation='border' variant='primary' />
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ReservationSmallCalendar;
