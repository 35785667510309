import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import ReportsFilters from 'components/Admin/Reports/ReportsFilters';
import { IFormError } from 'constants/Interfaces';
import useGetDataForRedux from 'hooks/useGetDataForRedux';
import { getAllEmployees } from 'actions/EmployeesActions';
import { getLocations } from 'actions/LocationActions';
import { TLocationState } from 'types/Locations';
import { IAppState } from 'store/Store';
import { IEmployee, TEmployeeState } from 'types/Employees';
import { getReportsByServices, getReportsByUsers } from 'actions/ReportsActions';
import { handleError } from 'middlewares/ErrorHandler';
import ReportsTableComponent from 'components/Admin/Reports/ReportsTableComponent';
import './AdminReports.scss';
import { IReservationSettingsState } from 'types/ReservationSettings';
import { getReservationSettings } from 'actions/ReservationSettingsActions';
import { formatCurrency } from 'helpers/Currency';
import useError from '../../hooks/useError';

export interface IFilterData {
  locationId: string;
  userId: string;
  startDate: string;
  endDate: string;
}

enum RerportType {
  Service = 'service',
  Employee = 'employee',
}

const formateTableSubRow = (subRowData: Array<any>) => {
  const newSubRowData = [];
  for (let i = 0; i < subRowData.length; i++) {
    const item = subRowData[i];
    newSubRowData.push({
      ...item,
      serviceGroupName: `${item.duration || 0} min. ${item.serviceName || ''}`,
    });
  }
  return newSubRowData;
};

const formateServicesTableData = (byServiceData: Array<any>) => {
  const newTableData = [];
  for (let i = 0; i < byServiceData.length; i++) {
    const item = byServiceData[i];
    newTableData.push({
      ...item,
      subRows: item.services ? formateTableSubRow(item.services) : '',
      expanded: true,
      style: { fontWeight: 'bold' },
    });
  }
  return newTableData;
};

const formateEmployeeTableData = (byEmployee: Array<any>) => {
  const newTableData = [];
  for (let i = 0; i < byEmployee.length; i++) {
    const item = byEmployee[i];

    item.userId &&
      newTableData.push({
        ...item,
        employeeName: `${item.name}  ${item.email ? `(${item.email})` : ''}`,
      });
  }
  return newTableData;
};

export const formatDate = (date?: Date) => {
  const dateToFormat = date || new Date();
  return dateToFormat.toLocaleDateString('lt', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

const AdminReports: React.FC = () => {
  const [error, setError] = useState<IFormError>();
  const [availableEmployees, setAvailableEmployees] = useState<IEmployee[]>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [filters, setFilters] = useState<IFilterData>({
    locationId: '',
    userId: '',
    startDate: '',
    endDate: '',
  });
  const [tableDataServices, setTableDataServices] = useState<any[]>([]);
  const [tableDataUsers, setTableDataUsers] = useState<any[]>([]);

  useError(error, setError);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const locationState: TLocationState = useSelector<IAppState, TLocationState>(
    (state: IAppState) => state.locationState,
  );

  const employeeState: TEmployeeState = useSelector<IAppState, TEmployeeState>(
    (state: IAppState) => state.employeeState,
  );

  const reservationSettingsState: IReservationSettingsState = useSelector<
    IAppState,
    IReservationSettingsState
  >((state: IAppState) => state.reservationSettingsState);

  useGetDataForRedux(setError, employeeState.allData, getAllEmployees());
  useGetDataForRedux(setError, locationState.data, getLocations(0));
  useGetDataForRedux(setError, reservationSettingsState.data, getReservationSettings());

  const filtersSubmit = async ({ locationId, userId }: IFilterData) => {
    setError(undefined);
    const modifiedStartDate = formatDate(startDate).toString();
    const modifiedEndDate = formatDate(endDate).toString();
    try {
      await dispatch(
        getReportsByUsers(
          {
            LocationId: locationId,
            UserId: userId,
            From: modifiedStartDate,
            To: modifiedEndDate,
          },
          userReports => {
            const formatedData = formateEmployeeTableData(userReports || []);
            setTableDataUsers(formatedData || []);
          },
        ),
      );
      await dispatch(
        getReportsByServices(
          {
            LocationId: locationId,
            UserId: userId,
            From: modifiedStartDate,
            To: modifiedEndDate,
          },
          serviceReports => {
            const formatedData = formateServicesTableData(serviceReports || []);
            setTableDataServices(formatedData || []);
          },
        ),
      );
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const getEmployeesOptions = () => {
    const employeesOptions = [{ id: '', fullName: t('filter_all_employees') }];
    if (availableEmployees) {
      availableEmployees.forEach(emp =>
        employeesOptions.push({
          ...emp,
          fullName: emp.name + ` ${emp.lastName || ''}`,
        }),
      );
    }
    return employeesOptions;
  };

  const handleLocationChange = (locationId: string) => {
    if (locationId) {
      setAvailableEmployees(
        employeeState.allData?.filter(emp => emp.locationIds?.includes(locationId)),
      );
    } else {
      setAvailableEmployees(employeeState.allData);
    }
  };

  useEffect(() => {
    if (filters.locationId) {
      handleLocationChange(filters.locationId);
    }
  }, [filters.locationId]);

  useEffect(() => {
    if (locationState.data && employeeState.allData) {
      const availableLocationId = locationState.data?.[0]?.id;
      if (availableLocationId) {
        setFilters({
          locationId: '',
          userId: '',
          startDate: moment(startDate).format('yyyy-MM-DD'),
          endDate: moment(endDate).format('yyyy-MM-DD'),
        });
        handleLocationChange(availableLocationId);
      }
    }
  }, [locationState.data, employeeState.allData, startDate, endDate]);

  const getColumns = (reportType: RerportType) => {
    const columns = [
      {
        Header: t('report_table_col_services_count'),
        accessor: 'count',
        width: '20%',
      },
      {
        Header: t('report_table_col_served_count'),
        accessor: 'personsCount',
        width: '20%',
      },
      {
        Header: t('report_table_col_services_price'),
        accessor: 'price',
        width: '20%',
        Cell: (cell: any) =>
          `${cell.value} ${formatCurrency(reservationSettingsState?.data?.currency)}`,
      },
    ];

    reportType === RerportType.Service &&
      columns.unshift({
        Header: t('report_table_col_service'),
        accessor: 'serviceGroupName',
        width: '40%',
        Cell: (cell: any) => cell.value || t('reservation_blocked_times'),
      });

    reportType === RerportType.Employee &&
      columns.unshift({
        Header: t('report_table_col_employee'),
        accessor: 'employeeName',
        width: '40%',
      });

    return columns;
  };

  return (
    <Container fluid className='p-0'>
      <Row>
        <Card className='mainCard'>
          <Card.Header className='title-big'>{t('report_index_page_title')}</Card.Header>
          <Card.Body className='px-2 py-3 reports-content'>
            <ReportsFilters
              handleSubmit={filtersSubmit}
              handleLocationChange={handleLocationChange}
              locations={[
                { id: '', name: t('filter_all_locations') },
                ...(locationState.data || []),
              ]}
              employees={getEmployeesOptions()}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              locationId={filters.locationId}
              userId={filters.userId}
              setFilters={setFilters}
            />
            <ReportsTableComponent
              columns={getColumns(RerportType.Service)}
              data={tableDataServices}
              title={t('report_selling_by_services')}
              striped
            />
            <ReportsTableComponent
              columns={getColumns(RerportType.Employee)}
              data={tableDataUsers}
              title={t('report_selling_by_employees_activity_type_pasiutuslape')}
              striped
            />
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default AdminReports;
