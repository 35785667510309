import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import Toast from '../../Utils/Toast';
import FormModal from '../../Utils/Modals/FormModal';
import AgreeToDelete from '../../Utils/Modals/AgreeToDelete';
import { deleteClient } from '../../../actions/ClientsActions';

import { IClient } from '../../../types/Clients';
import ReservationsTable from '../Reservations/ReservationsTable';
import { IReservationWithInfo } from '../../../types/Reservations';
import { IFormError } from '../../../constants/Interfaces';

interface IClientReservationsListModal {
  show: boolean;
  handleClose: () => void;
  clientToEdit?: IClient;
  handleClientEdit: () => void;
  setReservationToEdit: (reservation: IReservationWithInfo) => void;
  setReservationInfoModalOpen: (open: boolean) => void;
}

const ClientReservationsListModal: React.FC<IClientReservationsListModal> = ({
  show,
  handleClose,
  clientToEdit,
  handleClientEdit,
  setReservationToEdit,
  setReservationInfoModalOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [clientToDelete, setClientToDelete] = useState<string>();
  useError(error, setError);

  const formValidation = () => {
    const error: Record<string, any> = {};
    return error;
  };

  const handleSubmit = async () => {
    return null;
  };

  const handleDelete = async () => {
    if (clientToDelete) {
      try {
        await dispatch(deleteClient(clientToDelete));
        toast.success(<Toast text={t('successfullyDeleted', { item: t('client') })} />);
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    }
    handleClose();
  };

  const openDeleteDialog = () => {
    if (clientToEdit) {
      setClientToDelete(clientToEdit.id);
    }
  };

  const getDateParams = () => {
    const dateArray = clientToEdit?.birthDate?.slice(0, 10).split('-') || [];
    if (dateArray) {
      return {
        year: dateArray[0],
        month: dateArray[1],
        date: dateArray[2],
      };
    }
  };

  const dateParams = getDateParams();

  return (
    <>
      <FormModal
        show={show}
        handleClose={handleClose}
        modalTitle={`${clientToEdit?.name || ''} ${clientToEdit?.lastname || ''}`}
        validation={formValidation}
        handleSubmit={handleSubmit}
        form={
          <ReservationsTable
            clientToEdit={clientToEdit}
            setReservationToEdit={setReservationToEdit}
            setReservationInfoModalOpen={setReservationInfoModalOpen}
          />
        }
        editValue={clientToEdit ? { ...clientToEdit, ...dateParams } : undefined}
        handleDelete={openDeleteDialog}
        hideSaveBtn
        showEditBtn
        handleEdit={() => handleClientEdit()}
      />
      <AgreeToDelete
        show={!!clientToDelete}
        handleClose={() => setClientToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </>
  );
};

export default ClientReservationsListModal;
