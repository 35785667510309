import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize } from 'experience/components/Button';
import './ServiceItem.scss';
import { IExperienceCompanyState } from 'experience/types/ExperienceCompany';
import { useSelector } from 'react-redux';
import { IAppState } from 'store/Store';
import { formatCurrency } from 'helpers/Currency';
import clsx from 'clsx';

interface IServiceItem {
  title: string;
  quantity: string | null;
  price: number;
  onSelect?: () => any;
  maxPersonsCount: number;
  salePrice?: number;
  fixedPersonsCount: boolean;
  isReservationGroupEnabled?: boolean;
}

const ServiceItem = ({
  title,
  quantity,
  price,
  onSelect,
  maxPersonsCount,
  salePrice,
  fixedPersonsCount,
  isReservationGroupEnabled,
}: IServiceItem) => {
  const { t } = useTranslation();
  const companyExperienceState: IExperienceCompanyState = useSelector<
    IAppState,
    IExperienceCompanyState
  >((state: IAppState) => state.experienceCompanyState);
  const { currency } = { ...companyExperienceState.data };
  const showSalePrice = salePrice != null;
  const showMaxPersonCount =
    maxPersonsCount > 1 && !fixedPersonsCount && isReservationGroupEnabled
      ? `1-${maxPersonsCount} ${t('person_short')}`
      : null;
  return (
    <div className='experience__service-item'>
      <p className='experience__service-item--name'>{title}</p>
      <p className='experience__service-item--count'>{showMaxPersonCount}</p>
      <p className='experience__service-item--quantity'>{quantity || ''}</p>
      <div className='experience__service-item--group'>
        <div className='experience__service-item__price--wrapper'>
          <p
            className={clsx('experience__service-item--price', {
              ['experience__service-item--price-underline']: showSalePrice,
            })}
          >
            {price}
            {formatCurrency(currency)}
          </p>
          {showSalePrice && (
            <p className='experience__service-item--price-sale'>
              {salePrice}
              {formatCurrency(currency)}
            </p>
          )}
        </div>
        <Button size={ButtonSize.SM} onClick={() => onSelect?.()}>
          {t('reservation_select_service')}
        </Button>
      </div>
    </div>
  );
};

export default ServiceItem;
