import { IEmployeesExperience } from 'experience/types/ExperienceEmployees';
import {
  IReservationDateTimeUserValues,
  IReservationFormValues,
} from 'experience/types/ExperienceFormTypes';
import { ICurrentService, IReservationService } from 'experience/types/ExperienceService';
import { TResetAction } from './App';
import { ICompany } from './Companies';
import { ILocation } from './Locations';

export interface ISelectedService {
  readonly serviceId: string;
  readonly service?: IReservationService;
  readonly location?: ILocation;
  readonly client?: IReservationFormValues;
  readonly dateTimeUser?: IReservationDateTimeUserValues;
  readonly company?: ICompany;
  readonly reservationInfo?: {
    readonly selectedDate?: Date;
    readonly selectedTime?: string;
    readonly selectedEmployee?: IEmployeesExperience;
  };
}

export type IExperienceWidgetMultipeState = {
  isEditingService?: boolean;
  currentService?: string;
  company?: ICompany;
  selectedServices?: Array<ISelectedService>;
};

export const initialExperienceWidgetMultipleState: IExperienceWidgetMultipeState = {
  isEditingService: false,
  currentService: '',
  selectedServices: [],
};
export enum ExperienceWidgetMultiple {
  CHANGE_CURRENT_SERVICE = 'EXPERIENCE_WIDGET_SERVICE_CURRENT_CHANGE_MULTIPLE',
  CHANGE_SERVICE_M = 'EXPERIENCE_WIDGET_SERVICE_CHANGE_MULTIPLE',
  CHANGE_LOCATION_M = 'EXPERIENCE_WIDGET_LOCATION_CHANGE_MULTIPLE',
  CHANGE_DATE_TIME_USER_M = 'EXPERIENCE_WIDGET_DATETIMEUSER_CHANGE_MULTIPLE',
  CHANGE_CLIENT_INFO_M = 'EXPERIENCE_WIDGET_CLIENT_INFO_CHANGE_MULTIPLE',
  CHANGE_SERVICE_COMPANY_M = 'EXPERIENCE_WIDGET_COMPANY_CHANGE_MULTIPLE',
  CHANGE_SERVICE_EDITING_M = 'EXPERIENCE_WIDGET_SERVICE_EDITING_MULTIPLE',
  CHANGE_INFO_M = 'EXPERIENCE_WIDGET_INFO_CHANGE_MULTIPLE',
  CHANGE_INFO_EMPLOYEE_M = 'EXPERIENCE_WIDGET_EMPLOYEE_INFO_CHANGE_MULTIPLE',
  REMOVE_SERVICE_M = 'EXPERIENCE_WIDGET_SERVICE_REMOVE_MULTIPLE',
  CHANGE_NEW_SERVICE_M = 'EXPERIENCE_WIDGET_SERVICE_NEW_ADD_MULTIPLE',
  CHANGE_ADDRESS_M = 'EXPERIENCE_ADDRESS_CHANGE_MULTIPLE',
  INIT_MULTIPLE = 'EXPERIENCE_WIDGET_MULTIPLE_INIT',
}

export type TExperienceWidgetMultipleActions =
  | {
      type: ExperienceWidgetMultiple.CHANGE_NEW_SERVICE_M;
      payload: IReservationService;
    }
  | {
      type: ExperienceWidgetMultiple.CHANGE_CURRENT_SERVICE;
      payload: ICurrentService;
    }
  | {
      type: ExperienceWidgetMultiple.CHANGE_SERVICE_M;
      payload: IReservationService;
    }
  | {
      type: ExperienceWidgetMultiple.CHANGE_LOCATION_M;
      payload: {
        location: ILocation;
        serviceId: string;
      };
    }
  | {
      type: ExperienceWidgetMultiple.CHANGE_CLIENT_INFO_M;
      payload: IReservationFormValues;
    }
  | {
      type: ExperienceWidgetMultiple.CHANGE_DATE_TIME_USER_M;
      payload: {
        dateTimeUser: IReservationDateTimeUserValues;
        serviceId: string;
      };
    }
  | {
      type: ExperienceWidgetMultiple.REMOVE_SERVICE_M;
      payload: {
        serviceId?: string;
      };
    }
  | {
      type: ExperienceWidgetMultiple.CHANGE_INFO_M;
      payload: {
        serviceId: string;
        date?: Date;
        time?: string;
        employee?: IEmployeesExperience;
      };
    }
  | {
      type: ExperienceWidgetMultiple.CHANGE_INFO_EMPLOYEE_M;
      payload: {
        serviceId: string;
        employee?: IEmployeesExperience;
      };
    }
  | {
      type: ExperienceWidgetMultiple.CHANGE_SERVICE_COMPANY_M;
      payload: ICompany;
    }
  | {
      type: ExperienceWidgetMultiple.CHANGE_SERVICE_EDITING_M;
      payload: boolean;
    }
  | {
      type: ExperienceWidgetMultiple.INIT_MULTIPLE;
      payload: IExperienceWidgetMultipeState;
    }
  | TResetAction;
