import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'react-bootstrap';
import FormCheckbox from '../../Form/FormCheckbox';
import FormInput from '../../Form/FormInput';
import './NotificationsForm.scss';
import NotificationsTagsList from './NotificationsTagsList';
import FormSelect from '../../Form/FormSelect';

interface IValuesType {
  [key: string]: string | number;
}

interface ICommonFormSettingsInputs {
  notificationsOnFieldName: string;
  titleFieldName: string;
  bodyFieldName: string;
  smsFieldName: string;
  timeBeforeReservationFieldName?: string;
  doNotSendTimeFieldName?: string;
}

interface ICommonFormSettings {
  defaultValues: IValuesType;
  fieldsToReset: string[];
  inputFields: ICommonFormSettingsInputs;
}

interface IConfirmationsForm {
  setValue?: (name: string, value: any) => void;
  values?: IValuesType;
  settings: ICommonFormSettings;
}

const ConfirmationsForm: React.FC<IConfirmationsForm> = ({
  values,
  setValue,
  settings,
}) => {
  const { t } = useTranslation();

  const {
    defaultValues,
    fieldsToReset,
    inputFields: {
      notificationsOnFieldName,
      bodyFieldName,
      smsFieldName,
      titleFieldName,
      timeBeforeReservationFieldName,
      doNotSendTimeFieldName,
    },
  } = settings;

  useEffect(() => {
    if (values) {
      const emptyFields = fieldsToReset.filter(
        field => values[field] === null || values[field] === 0,
      );

      setDefaultValues(emptyFields);
    }
  }, [values]);

  const setDefaultValues = (fields: string[]) => {
    fields.forEach(field => setValue?.(field, defaultValues[field]));
  };

  const remindBeforeOptions = [
    {
      id: 2,
      name: t('settings_form_value_notification_reminder_time_before_reservation_2'),
    },
    {
      id: 3,
      name: t('settings_form_value_notification_reminder_time_before_reservation_3'),
    },
    {
      id: 6,
      name: t('settings_form_value_notification_reminder_time_before_reservation_6'),
    },
    {
      id: 12,
      name: t('settings_form_value_notification_reminder_time_before_reservation_12'),
    },
    {
      id: 24,
      name: t('settings_form_value_notification_reminder_time_before_reservation_24'),
    },
    {
      id: 36,
      name: t('settings_form_value_notification_reminder_time_before_reservation_36'),
    },
    {
      id: 48,
      name: t('settings_form_value_notification_reminder_time_before_reservation_48'),
    },
    {
      id: 72,
      name: t('settings_form_value_notification_reminder_time_before_reservation_72'),
    },
  ];

  const dontRemindOptions = [
    {
      id: 1,
      name: t('settings_form_value_notification_reminder_do_not_send_time_1'),
    },
    {
      id: 3,
      name: t('settings_form_value_notification_reminder_do_not_send_time_3'),
    },
    {
      id: 5,
      name: t('settings_form_value_notification_reminder_do_not_send_time_5'),
    },
    {
      id: 12,
      name: t('settings_form_value_notification_reminder_do_not_send_time_12'),
    },
    {
      id: 24,
      name: t('settings_form_value_notification_reminder_do_not_send_time_24'),
    },
  ];

  return (
    <Row>
      <Col xs={12}>
        <FormCheckbox
          name={notificationsOnFieldName}
          label={t('settings_form_label_notification_on')}
          id={notificationsOnFieldName}
        />
      </Col>
      {timeBeforeReservationFieldName && (
        <Col xs={12}>
          <FormSelect
            hasEmptyOption={false}
            label={`${t(
              'settings_form_label_notification_reminder_time_before_reservation',
            )} *`}
            emptyOptionLabel=''
            name={timeBeforeReservationFieldName}
            options={remindBeforeOptions}
            optionValue='id'
            optionDisplay='name'
            size='sm'
          />
        </Col>
      )}
      {doNotSendTimeFieldName && (
        <Col xs={12}>
          <FormSelect
            hasEmptyOption={false}
            label={`${t('settings_form_label_notification_reminder_do_not_send_time')} *`}
            emptyOptionLabel=''
            name={doNotSendTimeFieldName}
            options={dontRemindOptions}
            optionValue='id'
            optionDisplay='name'
            size='sm'
          />
        </Col>
      )}
      <Col xs={12}>
        <FormInput
          size='sm'
          name={titleFieldName}
          label={`${t('settings_form_label_notification_email_title')} *`}
          type='text'
        />
      </Col>
      <Col xs={12}>
        <FormInput
          size='sm'
          name={bodyFieldName}
          label={`${t('settings_form_label_notification_email_body')} *`}
          inputClass='notifications-email-body'
          formType='textarea'
        />
      </Col>
      <Col xs={12}>
        <FormInput
          size='sm'
          name={smsFieldName}
          label={`${t('settings_form_label_notification_sms')} *`}
          inputClass='notifications-sms'
          formType='textarea'
          disabled
        />
      </Col>
      <Col xs={12}>
        <Button
          variant='primary'
          size='sm'
          className='notifications-reset'
          onClick={() => setDefaultValues(fieldsToReset)}
        >
          {t('settings_restore_default_values')}
        </Button>
      </Col>
      <Col xs={12} className='mt-3'>
        <NotificationsTagsList />
      </Col>
    </Row>
  );
};

export default ConfirmationsForm;
