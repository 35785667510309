import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import TableExpandable from 'components/Utils/Tables/TableExpandable';

interface IReportsTableComponent {
  columns: any;
  data: any;
  title: string;
  striped: boolean;
}

const ReportsTableComponent: React.FC<IReportsTableComponent> = ({
  columns,
  data,
  title,
  striped,
}) => {
  const { t } = useTranslation();

  return (
    <Col className='mb-5'>
      <h4 style={{ fontSize: ' 14px' }}>{title}</h4>
      <TableExpandable
        columns={columns}
        data={data}
        striped={striped}
        noDataText={t('report_table_no_information')}
      />
    </Col>
  );
};

export default ReportsTableComponent;
