import { RefObject, useCallback, useEffect } from 'react';

interface useDetectClickOutsideProps {
  ref: RefObject<HTMLElement>;
  excludedRefs?: Array<RefObject<HTMLElement>>;
  onClickOutside: () => void;
}

const useDetectClickOutside = ({
  ref,
  excludedRefs = [],
  onClickOutside,
}: useDetectClickOutsideProps) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!event.target || !ref.current) return;

      const isExcludedRef = excludedRefs.find(excludedRef =>
        excludedRef.current?.contains(event.target as Node),
      );

      if (!ref.current.contains(event.target as Node) && !isExcludedRef) onClickOutside();
    },
    [excludedRefs, onClickOutside, ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return null;
};

export default useDetectClickOutside;
