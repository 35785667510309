import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import { useSelector } from 'react-redux';

import { IExperienceCompanyState } from 'experience/types/ExperienceCompany';
import { IAppState } from 'store/Store';

import './ExperienceDateSelect.scss';
import { IExperienceTimetableState } from 'experience/types/ExperienceTimetable';

interface IExperienceDateSelect {
  date?: Date;
  setDate: (value: Date) => any;
  disabledDays: Date[];
}

const pad = (value: number) => {
  if (value < 10) return '0' + value;
  return value.toString();
};

const ExperienceDateSelect = ({ date, setDate, disabledDays }: IExperienceDateSelect) => {
  const { t, i18n } = useTranslation();

  const companyExperienceState: IExperienceCompanyState = useSelector<
    IAppState,
    IExperienceCompanyState
  >((state: IAppState) => state.experienceCompanyState);
  const timetableExperienceState: IExperienceTimetableState = useSelector<
    IAppState,
    IExperienceTimetableState
  >((state: IAppState) => state.experienceTimetableState);

  const MONTHS = useMemo(
    () => [
      ...Array.from({ length: 12 }, (_, index) => index + 1).map(mo =>
        t(`filter_month_${pad(mo)}`),
      ),
    ],
    [t],
  );

  const WEEKDAYS_LONG = useMemo(
    () => [
      ...Array.from({ length: 7 }, (_, index) => index).map(mo =>
        t(`employee_calendar_weekday_${mo}`),
      ),
    ],
    [t],
  );

  return (
    <DayPicker
      locale={i18n.language}
      months={MONTHS}
      weekdaysLong={WEEKDAYS_LONG}
      weekdaysShort={WEEKDAYS_LONG.map(day => day.slice(0, 2))}
      firstDayOfWeek={1}
      selectedDays={date}
      month={date}
      fromMonth={new Date()}
      toMonth={moment(new Date())
        .add(companyExperienceState?.data?.maxReservationTimeInFuture, 'days')
        .toDate()}
      onDayClick={(day: Date, modifiers) => {
        if (timetableExperienceState.isLoading) return;
        if (modifiers.disabled && !modifiers.today) {
          return;
        } else if (
          modifiers.today &&
          disabledDays
            .map(date => date.toDateString())
            .includes(new Date().toDateString())
        ) {
          return;
        }
        const formattedDate = moment(new Date(day)).subtract(12, 'hours').toDate();
        setDate(formattedDate);
      }}
      disabledDays={[
        ...disabledDays,
        {
          before: new Date(),
          after: moment(new Date())
            .add(companyExperienceState?.data?.maxReservationTimeInFuture, 'days')
            .toDate(),
        },
      ]}
    />
  );
};

export default ExperienceDateSelect;
