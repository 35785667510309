import React from 'react';
import './Button.scss';

type IButton = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button: React.FC<IButton> = ({ children, ...props }) => {
  return (
    <button className='landing__button' {...props}>
      {children}
    </button>
  );
};

export default Button;
