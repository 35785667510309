import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import './PaginationComp.scss';

interface IPaginationComp {
  activePage: number;
  pages?: number[];
  changePage: (number: number) => void;
}

const PaginationComp: React.FC<IPaginationComp> = ({ activePage, pages, changePage }) => {
  const [preparedPages, setPreparedPages] = useState(pages || []);

  useEffect(() => {
    if (!pages || pages.length === 1) return;

    if (pages.length > 10) {
      if (activePage >= 3 && activePage <= pages.length - 3) {
        setPreparedPages(
          pages
            .slice(0, 1)
            .concat(pages.slice(activePage - 1, activePage + 2))
            .concat(pages.slice(-1)),
        );
        return;
      }

      if (activePage < 3) {
        setPreparedPages(pages.slice(0, 4).concat(pages.slice(-2)));
        return;
      }

      if (activePage > pages.length - 3) {
        setPreparedPages(pages.slice(0, 2).concat(pages.slice(-4)));
        return;
      }
    }

    setPreparedPages(pages);
  }, [activePage, pages]);

  if (!pages || pages.length < 2) return null;

  return (
    <div className='util--pagination'>
      {pages && (
        <Pagination className='justify-content-center'>
          <Pagination.Prev
            onClick={() => changePage(activePage !== undefined ? activePage - 1 : 0)}
            disabled={activePage === 0}
          />
          {preparedPages.map((number, i) => (
            <>
              {i !== 0 && Math.abs(preparedPages[i - 1] - number) > 1 && (
                <Pagination.Ellipsis />
              )}
              <Pagination.Item
                key={number}
                active={number === activePage}
                onClick={() => changePage(number)}
              >
                {number + 1}
              </Pagination.Item>
            </>
          ))}
          <Pagination.Next
            onClick={() => changePage(activePage !== undefined ? activePage + 1 : 0)}
            disabled={activePage === pages[pages.length - 1]}
          />
        </Pagination>
      )}
    </div>
  );
};

export default PaginationComp;
