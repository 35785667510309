import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { IAppState } from '../../../store/Store';
import { TServiceGroupState, IServiceGroup } from '../../../types/ServiceGroups';
import { TServicesState, IServices } from '../../../types/Services';
import { IFormError, IServiceCheckBoxGroup } from '../../../constants/Interfaces';
import { getAllServiceGroups } from '../../../actions/ServiceGroupsActions';
import { getAllServices } from '../../../actions/ServicesActions';
import { handleError } from '../../../middlewares/ErrorHandler';
import ServicesForm from './ServicesForm';
import useError from '../../../hooks/useError';

interface IServicesTab {
  setValue?: (location: string, value: string[]) => void;
  errors?: Record<string, any>;
  submitFailed?: boolean;
}

const formatServices = (services: IServices[], serviceGroups: IServiceGroup[]) => {
  // group all services in to correct groups
  const groupedServices: Array<IServiceCheckBoxGroup> = [];
  for (let i = 0; i < serviceGroups.length; i++) {
    const groupServices = [];
    for (let j = 0; j < services.length; j++) {
      if (services[j].groupId === serviceGroups[i].id) {
        groupServices.push({
          id: services[j].id,
          name: services[j].name,
        });
      }
    }
    const group = {
      groupName: serviceGroups[i].name,
      groupServices,
    };
    groupedServices.push(group);
  }
  const filtered = groupedServices.filter(group => group.groupServices.length > 0);
  return filtered;
};

const ServicesTab: React.FC<IServicesTab> = ({ errors, setValue, submitFailed }) => {
  const dispatch = useDispatch();
  const [checkBoxGroups, setCheckBoxGroups] = useState<Array<IServiceCheckBoxGroup>>();
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const serviceGroupsState: TServiceGroupState = useSelector<
    IAppState,
    TServiceGroupState
  >((state: IAppState) => state.servicesGroupState);

  const serviceState: TServicesState = useSelector<IAppState, TServicesState>(
    (state: IAppState) => state.servicesState,
  );

  useEffect(() => {
    (async () => {
      if (!serviceGroupsState.allData) {
        try {
          await dispatch(getAllServiceGroups());
        } catch (err) {
          handleError(
            err?.response?.status,
            setError,
            err.response?.data?.title,
            err.response?.data?.CausedByField,
          );
        }
      }
    })();
  }, [dispatch, serviceGroupsState.allData]);

  useEffect(() => {
    (async () => {
      if (!serviceState.allData) {
        try {
          await dispatch(getAllServices());
        } catch (err) {
          handleError(
            err?.response?.status,
            setError,
            err.response?.data?.title,
            err.response?.data?.CausedByField,
          );
        }
      }
    })();
  }, [dispatch, serviceState.allData]);

  useEffect(() => {
    if (serviceState.allData && serviceGroupsState.allData) {
      const groups = formatServices(serviceState.allData, serviceGroupsState.allData);
      setCheckBoxGroups(groups);
      return;
    }
  }, [serviceState.allData, serviceGroupsState.allData]);

  return (
    <>
      {serviceState.isLoading || serviceGroupsState.isLoading ? (
        <div className='tab-loader-container'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : (
        <ServicesForm
          groups={checkBoxGroups || []}
          errors={errors}
          submitFailed={submitFailed}
          setValue={setValue}
        />
      )}
    </>
  );
};

export default ServicesTab;
