import { AxiosResponse } from 'axios';
import {
  NotificationsGet,
  NotificationsAdd,
  TNotificationsGetAction,
  INotifications,
  TUrgentMessageSendAction,
  UrgetMessageSend,
  IUrgentMessageBody,
} from '../types/Notifications';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS } from '../constants/API';

export const getNotifications = () => {
  return apiCall<TNotificationsGetAction, null, INotifications>(
    NotificationsGet,
    'GET',
    API_END_POINTS.GET_NOTIFICATIONS_SETTINGS,
    true,
  );
};

export const addNewNotificationsToStore = (notifications: INotifications) => {
  return {
    type: NotificationsAdd.ADD_NOTIFICATIONS_STORE,
    payload: notifications,
  };
};

export const addNotifications = (notifications: INotifications) => {
  return async (
    dispatch: TDispatch<NotificationsAdd>,
  ): Promise<AxiosResponse<string>> => {
    dispatch({
      type: NotificationsAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.ADD_NOTIFICATIONS_SETTINGS,
        data: notifications,
      });
      dispatch({
        type: NotificationsAdd.SUCCESS,
      });
      dispatch(addNewNotificationsToStore(notifications));
      return response;
    } catch (error) {
      dispatch({
        type: NotificationsAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const sendUrgentMessage = (reservationId: string, values: IUrgentMessageBody) => {
  return apiCall<TUrgentMessageSendAction, IUrgentMessageBody, {}>(
    UrgetMessageSend,
    'POST',
    API_END_POINTS.SEND_URGENT_MESSAGE(reservationId),
    true,
    values,
  );
};
