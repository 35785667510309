import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import './TableFilter.scss';
import { ICompany } from '../../../types/Companies';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownToggle from '../Dropdown/DropdownToggle';
import DropdownMenu from '../Dropdown/DropdownMenu';

interface ITableSearch {
  setSearchQuery: (query: string) => void;
  searchQuery: string;
  options: Array<ICompany>;
  setSelectedOption: (company: string) => void;
  handleSearch: () => void;
  selectPlaceHolder: string;
}

const TableSearch: React.FC<ITableSearch> = ({
  setSearchQuery,
  searchQuery,
  options,
  setSelectedOption,
  handleSearch,
  selectPlaceHolder,
}) => {
  const { t } = useTranslation();

  const [displayValue, setDisplayValue] = useState(selectPlaceHolder);
  const [currentValue, setCurrentValue] = useState('');

  return (
    <div className='d-flex align-items-start employee-filter-inputs'>
      <InputGroup className='mb-3 input mr-1 dropdown-input-wrapper'>
        <FormControl
          placeholder={t('filter_search_placeholder')}
          aria-label={t('filter_search_placeholder')}
          value={searchQuery}
          onChange={(e: React.ChangeEvent<{ value: string }>) =>
            setSearchQuery(e.target.value)
          }
        />
      </InputGroup>
      <Dropdown className='dropdown-input-wrapper'>
        <Dropdown.Toggle
          as={DropdownToggle}
          id='dropdown-custom-components'
          displayValue={displayValue}
        />

        <Dropdown.Menu
          as={DropdownMenu}
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
          setSelectedOption={setSelectedOption}
        >
          {options.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => {
                setDisplayValue(item.name);
                setCurrentValue(item.name);
                setSelectedOption(item.id);
              }}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <InputGroup.Append>
        <Button variant='primary' className='button space-left' onClick={handleSearch}>
          {t('filter_search_btn')}
        </Button>
      </InputGroup.Append>
    </div>
  );
};

export default TableSearch;
