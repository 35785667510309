import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import './PageFooter.scss';
import Row from './Row';

interface IPageFooter {
  isCheckoutPage?: boolean;
}

const PageFooter: React.FC<IPageFooter> = ({ isCheckoutPage }) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'experience__footer',
        !isCheckoutPage && 'experience__footer--checkout',
      )}
    >
      <Row>
        <p>{t('reservation_system_name')}</p>
        <img src='/logo.png' alt={t('reservation_system_name')} />
      </Row>
    </div>
  );
};

export default PageFooter;
