import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ButtonAdd from '../../components/Utils/ButtonAdd';
import { IAppState } from '../../store/Store';
import { TLocationState, ILocation } from '../../types/Locations';
import { TEmployeeState, IEmployee } from '../../types/Employees';
import { IFormError, ISort } from '../../constants/Interfaces';
import { handleError } from '../../middlewares/ErrorHandler';
import { getEmployees, changeOffset } from '../../actions/EmployeesActions';
import { getAllLocations } from '../../actions/LocationActions';
import TableSearch from '../../components/Utils/Tables/TableSearch';
import TableSortable from '../../components/Utils/Tables/TableSortable';
import PaginationComp from '../../components/Utils/Tables/PaginationComp';
import AddEditEmployeeModal from '../../components/Admin/Employees/AddEditEmployeeModal';
import useError from '../../hooks/useError';
import { TABLE_PAGE_SIZE } from '../../constants/UtilVariables';

import './AdminEmployee.scss';
import WorkTimeAddModal from '../../components/Admin/WorkTime/WorkTimeAddModal';

const formateTableData = (
  employeeData: Array<IEmployee>,
  locationData: Array<ILocation>,
) => {
  const newTableData = [];
  for (let i = 0; i < employeeData.length; i++) {
    const employee = employeeData[i];
    const locations: Array<ILocation> = [];
    if (employee.locationIds) {
      for (let j = 0; j < employee.locationIds.length; j++) {
        const employeeLocation = employee.locationIds[j];
        const location = locationData.find(rec => rec.id === employeeLocation);
        if (location) {
          locations.push(location);
        }
      }
    }
    newTableData.push({
      ...employee,
      locations,
    });
  }
  return newTableData;
};

const AdminEmployee: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [tableData, setTableData] = useState<IEmployee[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [pages, setPages] = useState<Array<number>>();
  const [activePage, setActivePage] = useState<number>(0);
  const [employeeToEdit, setEmployeeToEdit] = useState<IEmployee>();
  const [sortQuery, setSortQuery] = useState('');
  const [workTimeModalOpen, setWorkTimeModalOpen] = useState(false);

  useError(error, setError);

  const employeeState: TEmployeeState = useSelector<IAppState, TEmployeeState>(
    (state: IAppState) => state.employeeState,
  );
  const locationState: TLocationState = useSelector<IAppState, TLocationState>(
    (state: IAppState) => state.locationState,
  );

  useEffect(() => {
    return function cleanup() {
      dispatch(changeOffset(0));
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        //first load offset is always 0
        await dispatch(getEmployees(0, undefined, undefined, undefined, TABLE_PAGE_SIZE));
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getAllLocations());
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (employeeState.data && locationState.allData) {
      const formatedData = formateTableData(employeeState.data, locationState.allData);
      setTableData(formatedData || []);
      if (employeeState.total) {
        const pages = Array.from(
          Array(Math.ceil(employeeState.total / TABLE_PAGE_SIZE)).keys(),
        );
        setPages(pages);
      } else {
        setPages(undefined);
      }
    }
  }, [locationState.allData, employeeState.data]);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('user_table_col_name')}`,
        accessor: 'name',
        id: 'name',
      },
      {
        Header: `${t('user_table_col_email')}`,
        accessor: 'email',
        id: 'email',
      },
      {
        Header: `${t('user_table_col_phone')}`,
        accessor: 'phoneNumber',
        id: 'phoneNumber',
      },
      {
        Header: `${t('user_table_col_roles')}`,
        disableSortBy: true,
        accessor: 'roles',
        id: 'roles',
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => (
          <div className='d-flex'>
            {cell.value.map((role: string, index: number) => (
              <span className='mr-1 label' key={index}>
                {t(`user_role_${role.toLocaleLowerCase()}`)}
              </span>
            ))}
          </div>
        ),
      },
      {
        Header: `${t('user_table_col_locations')}`,
        accessor: 'locations',
        id: 'locations',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => (
          <div className='d-flex wrap'>
            {cell.value.map((location: any) => (
              <div className='mr-1 mt-1 label' key={location.id}>
                {location.name}
              </div>
            ))}
          </div>
        ),
      },
    ],
    [tableData],
  );

  const handleSort = useCallback(async (sortBy: Array<ISort>, offset: number) => {
    try {
      if (sortBy[0].id) {
        const sortDirection = sortBy[0].desc ? 'desc' : 'asc';
        const sortQuery = `${sortBy[0].id} ${sortDirection}`;
        setSortQuery(sortQuery);
        await dispatch(
          getEmployees(offset || 0, searchQuery, '', sortQuery, TABLE_PAGE_SIZE),
        );
      }
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  }, []);

  const handleSearch = async () => {
    try {
      setSearchQuery(searchQuery);
      await dispatch(
        getEmployees(
          employeeState.offset || 0,
          searchQuery,
          undefined,
          undefined,
          TABLE_PAGE_SIZE,
        ),
      );
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const changePage = async (newPage: number) => {
    setActivePage(newPage);
    dispatch(changeOffset(newPage * TABLE_PAGE_SIZE));
    await dispatch(
      getEmployees(
        newPage * TABLE_PAGE_SIZE,
        searchQuery,
        undefined,
        sortQuery,
        TABLE_PAGE_SIZE,
      ),
    );
  };

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='mainCard'>
            <Card.Header className='title'>{t('user_table_page_title')}</Card.Header>
            <Card.Body>
              <Row className='align-items-start'>
                <Col sm={12} md={7} lg={9} xl={9}>
                  <TableSearch
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    handleSearch={handleSearch}
                  />
                </Col>

                <Col sm={12} md={5} lg={3} xl={3} className='d-flex justify-content-end'>
                  <ButtonAdd
                    text={t('user_btn_create_client')}
                    handleClick={() => setOpen(true)}
                  />
                </Col>
              </Row>
              <Row className='mt-4 mt-md-0'>
                <Col sm={12}>
                  <TableSortable
                    striped
                    data={tableData}
                    columns={columns}
                    onSort={handleSort}
                    selectRow={setEmployeeToEdit}
                    openModal={setOpen}
                    isLoading={employeeState.isLoading && locationState.isLoading}
                    offset={employeeState.offset}
                  />
                  <PaginationComp
                    activePage={activePage}
                    pages={pages}
                    changePage={changePage}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {open && (
        <AddEditEmployeeModal
          show={open}
          employeeToEdit={employeeToEdit}
          handleWorkTimeEdit={() => {
            setOpen(false);
            setWorkTimeModalOpen(true);
          }}
          handleClose={() => {
            setOpen(false);
            setEmployeeToEdit(undefined);
          }}
        />
      )}
      <WorkTimeAddModal
        show={workTimeModalOpen}
        selectedEmployee={employeeToEdit}
        locations={locationState.allData}
        handleClose={() => {
          setWorkTimeModalOpen(false);
          setEmployeeToEdit(undefined);
        }}
      />
    </Container>
  );
};

export default AdminEmployee;
