import {
  TLocationState,
  initialLocationState,
  TLocationsActions,
  LocationGet,
  LocationsGetAll,
  LocationsPut,
  LocationAdd,
  LocationEdit,
  LocationDelete,
  ILocation,
} from '../types/Locations';
import { Reducer } from 'redux';
import { ResetStore } from 'types/App';

export const locationsReducer: Reducer<TLocationState, TLocationsActions> = (
  state = initialLocationState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialLocationState;
    }
    case LocationDelete.REQUEST:
    case LocationEdit.REQUEST:
    case LocationAdd.REQUEST:
    case LocationsGetAll.REQUEST:
    case LocationGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LocationDelete.FAILED:
    case LocationEdit.FAILED:
    case LocationAdd.FAILED:
    case LocationsGetAll.FAILED:
    case LocationGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case LocationsGetAll.SUCCESS:
      return {
        ...state,
        isLoading: false,
        allData: action.payload.data.data,
      };
    case LocationGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        total: action.payload.data.total,
        isLoading: false,
      };
    }
    case LocationDelete.SUCCESS:
    case LocationEdit.SUCCESS:
    case LocationAdd.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case LocationAdd.ADD_LOCATION_STORE: {
      return {
        ...state,
        data: state.data ? [action.payload, ...state?.data] : [action.payload],
        allData: state.allData
          ? [action.payload, ...state.allData]
          : [...(state.data ? state.data : []), action.payload],
      };
    }
    case LocationDelete.DELETE_LOCATION_STORE: {
      let newAllData: ILocation[] = [];
      if (state.allData) {
        newAllData = state.allData.filter((rec: any) => rec.id !== action.payload);
      } else if (state.data) {
        newAllData = state.data.filter((rec: any) => rec.id !== action.payload);
      }
      return {
        ...state,
        data: state.data
          ? state.data.filter((rec: any) => rec.id !== action.payload)
          : [],
        total: state.total ? state.total - 1 : 0,
        allData: newAllData,
      };
    }
    case LocationEdit.EDIT_LOCATION_STORE: {
      let newAllData: ILocation[] = [];
      if (state.allData) {
        newAllData = state.allData.map(rec =>
          rec.id === action.payload.id ? action.payload : rec,
        );
      } else if (state.data) {
        newAllData = state.data.map(rec =>
          rec.id === action.payload.id ? action.payload : rec,
        );
      }
      return {
        ...state,
        data: state.data
          ? state.data.map(rec => (rec.id === action.payload.id ? action.payload : rec))
          : [],
        allData: newAllData,
      };
    }
    case LocationsPut.LOCATION_OFFSET_UPDATE: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    default:
      return state;
  }
};
