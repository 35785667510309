import React from 'react';
import ButtonAdd from '../../Utils/ButtonAdd';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Roles } from '../../../constants/Roles';
import { matchUserRole } from 'helpers/matchRole';
import { FaRegFilePdf } from 'react-icons/fa';
import axios from 'axios';
import { getAccessToken } from 'helpers/Misc';
import { API_END_POINTS } from 'constants/API';
import { formatDate } from 'pages/admin/AdminReports';
import { View } from 'constants/Interfaces';
import { toast } from 'react-toastify';
import Toast from 'components/Utils/Toast';

interface ICalendarHeader {
  selectedLocation: string | undefined;
  selectedEmployee: string | undefined;
  date: Date;
  activeView: View;
  handleClick: () => void;
  roles?: Array<string>;
}

const escapeFilename = (name: string) => {
  if (name[0] === '"' && name[name.length - 1] === '"') {
    return name.substring(1, name.length - 1);
  }

  return name;
};

const CalendarHeader: React.FC<ICalendarHeader> = ({
  selectedEmployee,
  selectedLocation,
  date,
  activeView,
  handleClick,
  roles,
}) => {
  const downloadFile = async () => {
    const userId = selectedEmployee === 'all' ? undefined : selectedEmployee;
    const formattedDate = formatDate(date);
    try {
      const response = await axios.get(API_END_POINTS.GET_CALENDAR_PDF, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          locationId: selectedLocation,
          userId,
          date: formattedDate,
        },
      });

      const filename = response.headers['content-disposition']
        .split(';')[1]
        .split('=')[1];
      const escapedFilename = escapeFilename(filename);

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', escapedFilename);
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      toast.error(<Toast text={t('pdf_generation_failed')} isError />);
    }
  };

  const { t } = useTranslation();
  return (
    <Card.Header className='title-big'>
      <Row className='d-flex align-items-center'>
        <Col className='d-flex align-items-center'>
          <div>{t('reservation_calendar_title')}</div>
          {activeView === View.day && (
            <ButtonAdd
              handleClick={downloadFile}
              text='PDF'
              btnSize='sm'
              btnClass='ml-3 pdf-calendar-button'
              icon={<FaRegFilePdf className='mr-1' />}
            />
          )}
        </Col>
        <Col className='d-flex justify-content-end header-btn'>
          {roles && !matchUserRole(roles, [Roles.Employee]) && (
            <ButtonAdd text={t('reservation_btn_create')} handleClick={handleClick} />
          )}
        </Col>
      </Row>
    </Card.Header>
  );
};

export default CalendarHeader;
