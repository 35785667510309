import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useError from '../../../hooks/useError';
import { TUserState } from '../../../types/User';
import { IAppState } from '../../../store/Store';
import { IFormError } from '../../../constants/Interfaces';
import Modal from 'react-bootstrap/Modal';
import './ViewMailModal.scss';
import moment from 'moment';
import { NotificationsAvailableParams } from '../../../types/Notifications';

interface IViewMailModal {
  show: boolean;
  handleClose: () => void;
  modalBody?: string | null;
}

interface IObject {
  [key: string]: string;
}

const ViewMailModal: React.FC<IViewMailModal> = ({ show, handleClose, modalBody }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  const renderModalBody = () => {
    const mapObj: IObject = {
      [NotificationsAvailableParams.LOCATION_PHONE]: '',
      [NotificationsAvailableParams.LOCATION_ADDRESS]: 'LOCATION_ADDRESS',
      [NotificationsAvailableParams.PERSONS_COUNT]: 'PERSONS_COUNT',
      [NotificationsAvailableParams.LOCATION_NAME]: '',
      [NotificationsAvailableParams.SERVICE_NAME]: '',
      [NotificationsAvailableParams.CLIENT_NAME]: userState.data?.name || 'CLIENT_NAME',
      [NotificationsAvailableParams.RESERVATION_DATE]: moment(new Date()).format(
        'YYYY-MM-DD hh:mm',
      ),
      [NotificationsAvailableParams.EMPLOYEE_NAME]: `${t('user_form_label_name')} ${t(
        'user_form_label_lastname',
      )}`,
    };
    const regex = new RegExp(Object.keys(mapObj).join('|'), 'gi');

    return modalBody?.replace(regex, function (matched) {
      return mapObj[matched];
    });
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName='modal-md view-modal-dialog'>
      <Modal.Header closeButton>
        <Modal.Title className='title view-modal-title mt-1' color='secondary'>
          {t('notifications_mail_preview_header')}
        </Modal.Title>
      </Modal.Header>
      {modalBody && (
        <Modal.Body className='view-modal-body'>
          <pre className='view-modal-text'>{renderModalBody()}</pre>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default ViewMailModal;
