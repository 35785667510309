import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { IoArrowBack } from 'react-icons/io5';
import { ROUTES } from 'constants/Routes';
import { IFormError } from 'constants/Interfaces';
import useError from 'hooks/useError';
import Row from 'experience/components/Row';
import Block from 'experience/components/Block';
import ExperienceDateSelect from 'experience/components/date-time/ExperienceDateSelect';
import ExperienceTimeSelect from 'experience/components/date-time/ExperienceTimeSelect';
import Button, { ButtonSize } from 'experience/components/Button';
import {
  IExperienceSelectedServices,
  getReservationDatesExperience,
  getTimetableExperience,
} from 'experience/redux/actions/ExperienceTimetableActions';
import { getDaysBetweenDates } from 'helpers/DateTime';
import { useStoreState } from 'hooks/useStoreState';
import './ExperienceDateTimePage.scss';
import { getEmployeesExperience } from 'experience/redux/actions/ExperienceEmployeesActions';
import { IEmployeesExperience } from 'experience/types/ExperienceEmployees';
import Select from 'experience/components/Select';
import {
  removeExperienceWidgetMultiple,
  setExperienceWidgeMultipleEditing,
  setExperienceWidgeMultipletDateTimeUser,
  setExperienceWidgetReservationEmployeeInfo,
  setExperienceWidgetReservationInfo,
} from 'experience/redux/actions/ExperienceWidgetActionMultiple';
import EditReservation from 'experience/components/EditReservation';
import { setSelectedServices } from 'helpers/SessionStorage';
import { Loader } from 'react-bootstrap-typeahead';

const isDateArray = (array: any): array is Date[] => {
  return array?.length && moment(array[0]).isValid();
};

const ExperienceTimePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { params }: { params: { lang: string; company: string } } = useRouteMatch();
  const [date, setDate] = useState<Date>();
  const [time, setTime] = useState<string>();
  const [personCount, setPersonCount] = useState<number>(1);
  const [employee, setEmployee] = useState<IEmployeesExperience | undefined>(undefined);

  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const employeeExperienceState = useStoreState().experienceEmployeesState;
  const timetableExperienceState = useStoreState().experienceTimetableState;
  const reservationDatesExperienceState = useStoreState().experienceReservationDatesState;
  const companyExperienceState = useStoreState().experienceCompanyState;
  const widgetStateMultiple = useStoreState().experienceWidgetMultipeState;
  const currentlyEditingServiceId = widgetStateMultiple.currentService;
  const widgetState = widgetStateMultiple.selectedServices?.filter(
    item => item.serviceId === currentlyEditingServiceId,
  )[0];
  const isEditingService = widgetStateMultiple.isEditingService;
  const showPeopleSelect =
    widgetState && widgetState.service && widgetState.service.service.maxPersonsCount > 1;

  const maxPersons = widgetState?.service?.service?.maxPersonsCount || 1;

  const isFixedPersonsCount = !widgetState?.service?.service.fixedPersonsCount;
  const isReservationGroupEnabled =
    widgetStateMultiple?.company?.reservationGroupsEnabled;
  const showPeopleSelection =
    showPeopleSelect && isFixedPersonsCount && isReservationGroupEnabled;

  useEffect(() => {
    dispatch(
      setExperienceWidgetReservationInfo(
        currentlyEditingServiceId || '',
        date,
        undefined,
        undefined,
      ),
    );
  }, [date]);

  useEffect(() => {
    (async () => {
      if (!widgetState || !widgetState.location || !widgetState.service) return;
      await dispatch(
        getEmployeesExperience(
          params.company,
          widgetState.location.id,
          widgetState.service.pricing.id,
        ),
      );
    })();
  }, [widgetState?.location, widgetState?.service]);
  const formatStartDate = (date?: Date, time?: string) => {
    if (!date || !time) return '';
    const timeArray = time.split(':');
    const hours = parseInt(timeArray[0], 10);
    const minutes = parseInt(timeArray[1], 10);
    return moment(date)
      .set('hour', hours)
      .set('minute', minutes)
      .toISOString(true)
      .slice(0, 19);
  };
  useEffect(() => {
    (async () => {
      if (!widgetState || !widgetState.location || !date) return;
      // need to get date and just set midnight
      const startDate = moment(date)
        .set('hour', 0)
        .set('minute', 0)
        .format('YYYY-MM-DDTHH:mm:ss')
        .toString();
      const endDate = moment(date)
        .set('hour', 23)
        .set('minute', 59)
        .format('YYYY-MM-DDTHH:mm:ss')
        .toString();
      const currentService = {
        MinPersonsCount: personCount,
        ServiceId: widgetState.service?.service.id,
        LocationId: widgetState.location?.id,
        ServicePricingId: widgetState.service?.pricing.id,
        UserId: employee?.id || '',
        Start: startDate,
        End: endDate,
      };
      const selectedServices: Array<IExperienceSelectedServices> = (
        widgetStateMultiple.selectedServices || []
      )
        ?.filter(item => !!item.dateTimeUser)
        .map(item => {
          const modifiedServiceItem = {
            personsCount: item.dateTimeUser?.personCount || 1,
            serviceId: item.service?.service.id || '',
            servicePricingId: item.service?.pricing.id || '',
            locationId: item.location?.id || '',
            start: formatStartDate(item.dateTimeUser?.date, item.dateTimeUser?.time),
          };
          // if user exists, add it to the object
          if (item.dateTimeUser?.user) {
            return { ...modifiedServiceItem, userId: item.dateTimeUser?.user };
          }
          return modifiedServiceItem;
        });
      await dispatch(getTimetableExperience({ currentService, selectedServices }));
    })();
  }, [date, personCount, employee]);

  useEffect(() => {
    (async () => {
      if (!widgetState || !widgetState.location) return;

      const currentService = {
        accessKey: params.company,
        MinPersonsCount: personCount,
        ServiceId: widgetState.service?.service.id,
        LocationId: widgetState.location?.id,
        ServicePricingId: widgetState.service?.pricing.id,
        UserId: employee?.id || '',
        Start: moment(new Date())
          .hours(0)
          .minutes(0)
          .seconds(0)
          .toISOString(true)
          .slice(0, 19),
        End: moment(new Date())
          .hours(23)
          .minutes(59)
          .add(companyExperienceState?.data?.maxReservationTimeInFuture, 'days')
          .endOf('month')
          .toISOString(true)
          .slice(0, 19),
      };
      const selectedServices: Array<IExperienceSelectedServices> = (
        widgetStateMultiple.selectedServices || []
      )
        ?.filter(item => !!item.dateTimeUser)
        .map(item => {
          const modifiedServiceItem = {
            personsCount: item.dateTimeUser?.personCount || 1,
            serviceId: item.service?.service.id || '',
            servicePricingId: item.service?.pricing.id || '',
            locationId: item.location?.id || '',
            start: formatStartDate(item.dateTimeUser?.date, item.dateTimeUser?.time),
          };
          // if user exists, add it to the object
          if (item.dateTimeUser?.user) {
            return { ...modifiedServiceItem, userId: item.dateTimeUser?.user };
          }
          return modifiedServiceItem;
        });

      const response: any = await dispatch(
        getReservationDatesExperience({
          currentService,
          selectedServices,
        }),
      );

      if (isEditingService) {
        if (
          isDateArray(response.data) &&
          widgetState.reservationInfo?.selectedDate &&
          response.data.find((r: Date) =>
            moment(r).isSame(moment(widgetState.reservationInfo?.selectedDate), 'day'),
          )
        ) {
          setDate(undefined);
        } else {
          setDate(new Date());
        }
      } else {
        if (isDateArray(response.data) && date) {
          const foundDate = response.data.find((r: Date) =>
            moment(r).isSame(moment(date), 'day'),
          );
          if (foundDate) {
            setDate(new Date(foundDate));
          }
        }
      }
    })();
  }, [employee, personCount, widgetState?.location, widgetState?.service]);

  const disabledDays = useMemo(() => {
    const allDates = getDaysBetweenDates(
      moment(new Date()),
      moment(new Date())
        .add(
          companyExperienceState?.data?.maxReservationTimeInFuture &&
            companyExperienceState?.data?.maxReservationTimeInFuture + 1,
          'days',
        )
        .toDate(),
    ).map(date => date.split('T')[0]);

    const workingDays = reservationDatesExperienceState?.data?.map(
      date => date.split('T')[0],
    );

    const difference = allDates
      .filter(x => !workingDays?.includes(x))
      .concat(workingDays?.filter(x => !allDates.includes(x)))
      .map(diff => new Date(diff));
    return difference;
  }, [companyExperienceState, reservationDatesExperienceState]);

  const availableTimes = useMemo(() => {
    if (!timetableExperienceState.data) return [];
    return timetableExperienceState.data.map(d => moment(d).format('HH:mm'));
  }, [timetableExperienceState.data]);

  const handleSubmit = () => {
    if (
      !date ||
      !time ||
      !widgetStateMultiple.currentService ||
      widgetStateMultiple.selectedServices?.length === 0
    )
      return;

    // multiple
    dispatch(
      setExperienceWidgeMultipletDateTimeUser(
        date,
        time,
        personCount,
        employee?.id,
        widgetStateMultiple.currentService,
      ),
    );

    saveToSessionStorage();

    history.push(
      ROUTES.EXPERIENCE.FORM.replace(':lang', params.lang).replace(
        ':company',
        params.company,
      ),
    );
  };

  const saveToSessionStorage = () => {
    if (!date || !time || !widgetStateMultiple.currentService) return;
    const dateTimeUser = {
      date,
      time,
      personCount,
      user: employee?.id,
      serviceId: widgetStateMultiple.currentService,
    };
    const newService =
      widgetStateMultiple.selectedServices?.find(
        item => item.serviceId === widgetStateMultiple.currentService,
      ) || ({} as IExperienceSelectedServices);
    const modifiedService = { ...(newService || {}), dateTimeUser };
    const selectedServices = widgetStateMultiple.selectedServices?.filter(
      item => item.serviceId !== widgetStateMultiple.currentService,
    );
    selectedServices?.push(modifiedService);
    const objectToSave = {
      isEditingService: false,
      currentService: widgetStateMultiple.currentService,
      selectedServices,
    };
    setSelectedServices(objectToSave);
  };

  const handleGoBackForAnotherReservation = () => {
    if (!date || !time || !widgetStateMultiple.currentService) return;
    dispatch(
      setExperienceWidgeMultipletDateTimeUser(
        date,
        time,
        personCount,
        employee?.id,
        widgetStateMultiple.currentService,
      ),
    );
    saveToSessionStorage();
    history.push(
      ROUTES.EXPERIENCE.SERVICES.replace(':lang', params.lang).replace(
        ':company',
        params.company,
      ),
    );
  };

  const handleDecrease = () => {
    if (personCount <= 1) return;
    setPersonCount(prev => prev - 1);
  };

  const handleIncrease = () => {
    if (personCount >= Number(maxPersons)) return;
    setPersonCount(prev => prev + 1);
  };

  const handleReturnServiceSelection = () => {
    if (!isEditingService) dispatch(removeExperienceWidgetMultiple());
    dispatch(setExperienceWidgeMultipleEditing(false));
    history.push(
      ROUTES.EXPERIENCE.SERVICES.replace(':company', params.company).replace(
        ':lang',
        params.lang,
      ),
    );
  };

  const handleTimeSelection = (time: string) => {
    setTime(time);
    dispatch(
      setExperienceWidgetReservationInfo(
        currentlyEditingServiceId || '',
        undefined,
        time,
        undefined,
      ),
    );
  };

  useEffect(() => {
    if (widgetState?.reservationInfo?.selectedEmployee?.id) {
      setEmployee(widgetState?.reservationInfo?.selectedEmployee);
    }
  }, [widgetStateMultiple.currentService]);

  useEffect(() => {
    if (date && disabledDays.some(day => moment(day).isSame(date, 'day'))) {
      setDate(undefined);
    }
  }, [date, disabledDays, widgetStateMultiple.currentService, personCount]);

  useEffect(() => {
    if (time && availableTimes.length && !availableTimes.some(at => time === at)) {
      setTime(undefined);
    }
  }, [time, availableTimes, widgetStateMultiple.currentService, personCount]);

  useEffect(() => {
    setTime(undefined);
  }, [date]);

  return (
    <div className='experience__time-page'>
      <Row paddingY={2}>
        <div className='experience__reservertion-options'>
          {showPeopleSelection ? (
            <Block title={`${t('reservation_select_max_count')} (1-${maxPersons})`}>
              <div className='experience__person-count-box'>
                <button
                  disabled={maxPersons <= 1}
                  className='experience__person-count-btn'
                  onClick={handleDecrease}
                >
                  -
                </button>
                <div className='experience__person-count-input'>
                  {String(personCount)}
                </div>
                <button
                  disabled={maxPersons <= 1}
                  className='experience__person-count-btn'
                  onClick={handleIncrease}
                >
                  +
                </button>
              </div>
            </Block>
          ) : (
            <Block title={t('reservation_select_employee')}>
              <Select
                value={employee?.id || ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const employeeToSelect = employeeExperienceState.data?.find(
                    a => a.id === e.target.value,
                  );
                  setEmployee(employeeToSelect);
                  dispatch(
                    setExperienceWidgetReservationEmployeeInfo(
                      currentlyEditingServiceId || '',
                      employeeToSelect,
                    ),
                  );
                }}
              >
                <option value=''>{t('reservation_select_employee_all')}</option>
                {employeeExperienceState.data?.map(e => (
                  <option key={e.id} value={e.id}>
                    {`${e.firstName || ''} ${e.lastName || ''}`}
                  </option>
                ))}
              </Select>
            </Block>
          )}

          <EditReservation />
        </div>
      </Row>
      <Row paddingY={2} className='experience__date-time-block'>
        <Block title={t('reservation_select_date')}>
          <ExperienceDateSelect
            date={date}
            setDate={setDate}
            disabledDays={disabledDays}
          />
        </Block>
        {timetableExperienceState.isLoading ? (
          <Block className='loader-wrapper' title=''>
            <div className='experience__loader'>
              <Loader />
            </div>
          </Block>
        ) : (
          <Block title={t('reservation_select_time')}>
            <ExperienceTimeSelect
              active={time}
              handleTimeSelection={handleTimeSelection}
              availableTimes={date ? availableTimes : []}
            />
          </Block>
        )}
      </Row>
      <Row className='experience__block-bottom'>
        <div className='experience__container'>
          <p onClick={handleReturnServiceSelection}>
            <IoArrowBack /> {t('reservation_salon_list')}
          </p>
        </div>
        <div className='experience__container'>
          <div className='experience__container_button_wrapper'>
            <Button onClick={handleSubmit} size={ButtonSize.FULL_WIDTH}>
              {t('reservation_reserve_time')}
            </Button>

            <Button
              onClick={handleGoBackForAnotherReservation}
              size={ButtonSize.HOLLOW_LG}
            >
              {t('reservation_reserve_time_and_choose_other')}
            </Button>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default ExperienceTimePage;
