import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationsAvailableParams } from '../../../types/Notifications';

const NotificationsTagsList: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <label className='form-label'>{t('settings_notifications_available_params')}</label>
      <ul className='mb-0'>
        {Object.values(NotificationsAvailableParams).map(param => (
          <li key={param}>{param}</li>
        ))}
      </ul>
    </>
  );
};

export default NotificationsTagsList;
