import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  TNotificationsActions,
  INotificationsState,
  initialNotificationsState,
  NotificationsGet,
  NotificationsAdd,
} from '../types/Notifications';

export const notificationsReducer: Reducer<INotificationsState, TNotificationsActions> = (
  state = initialNotificationsState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialNotificationsState;
    }
    case NotificationsAdd.REQUEST:
    case NotificationsGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case NotificationsAdd.FAILED:
    case NotificationsGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case NotificationsAdd.ADD_NOTIFICATIONS_STORE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case NotificationsAdd.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case NotificationsGet.SUCCESS: {
      return {
        data: action.payload.data,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
