import React, { useCallback } from 'react';
import clsx from 'clsx';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import { Field, FieldInputProps } from 'react-final-form';

import ExperienceFormError from './ExperienceFormError';
import './ExperienceFormInput.scss';
import FormErrorText from 'components/Form/FormErrorText';

type ErrorDisplayType = 'experience' | 'basic';

interface IExperienceFormInput
  extends Partial<Pick<FieldInputProps<any, HTMLInputElement>, 'onChange'>> {
  name: string;
  type?: string;
  label?: string;
  validation?: (value: any) => any;
  className?: string;
  defaultCountry?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, isValid: boolean) => void;
  errorDisplayType?: ErrorDisplayType;
}

const textRegex = /([A-Za-zĄą-Žž])+/;

const ExperiencePhoneInput: React.FC<IExperienceFormInput> = ({
  name,
  label,
  type,
  validation,
  className,
  defaultCountry,
  onBlur,
  errorDisplayType = 'experience',
  ...rest
}) => {
  const renderErrorComponent = useCallback(
    (errorText?: string) => {
      if (errorDisplayType === 'basic') {
        return <FormErrorText errorText={errorText} />;
      }
      if (errorDisplayType === 'experience') {
        return <ExperienceFormError errorText={errorText} />;
      }
    },
    [errorDisplayType],
  );

  return (
    <div className={clsx(`experience-input-block`, className)}>
      <Field name={name} validate={validation}>
        {({ input, meta }) => {
          const isError = meta.error && meta.touched;
          return (
            <>
              {label && (
                <label
                  htmlFor={name}
                  className={`experience-input-block__label ${
                    isError && errorDisplayType === 'basic' ? 'text-danger' : ''
                  }`}
                >
                  {label}
                </label>
              )}
              <div className='experience-input-block__input-container'>
                <IntlTelInput
                  nationalMode
                  type={type}
                  defaultCountry={defaultCountry}
                  inputClassName={clsx(
                    'experience-input-block__input',
                    isError && 'experience-input-block__input--error',
                  )}
                  {...rest}
                  {...input}
                  onPhoneNumberBlur={(
                    isValid,
                    _value,
                    _selectedCountryData,
                    _fullNumber,
                    _extension,
                    event,
                  ) => {
                    onBlur?.(event, isValid);
                    input?.onBlur?.(event);
                  }}
                  onPhoneNumberChange={(isValid, val, _selected, fullNumber) => {
                    if (textRegex.test(val)) {
                      return;
                    }
                    // if number isValid, we can assume it was formatted and it can be passed to the onChange
                    rest?.onChange?.(isValid ? fullNumber : val);
                    input?.onChange?.(isValid ? fullNumber : val);
                  }}
                />
                {isError && renderErrorComponent(meta.error)}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default ExperiencePhoneInput;
