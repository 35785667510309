import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  ServicesGetExperience,
  IExperienceServicesState,
  initialExperienceServicesState,
  TServicesGetExperienceAction,
} from 'experience/types/ExperienceServices';

export const experienceServicesReducer: Reducer<
  IExperienceServicesState,
  TServicesGetExperienceAction
> = (state = initialExperienceServicesState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialExperienceServicesState;
    }

    case ServicesGetExperience.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ServicesGetExperience.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };

    case ServicesGetExperience.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
