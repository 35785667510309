import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  EmployeesGetExperience,
  IExperienceEmployeesState,
  initialExperienceEmployeesState,
  TEmployeesGetExperienceAction,
} from 'experience/types/ExperienceEmployees';

export const experienceEmployeesReducer: Reducer<
  IExperienceEmployeesState,
  TEmployeesGetExperienceAction
> = (state = initialExperienceEmployeesState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialExperienceEmployeesState;
    }

    case EmployeesGetExperience.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case EmployeesGetExperience.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };

    case EmployeesGetExperience.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
