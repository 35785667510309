import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormSelect from '../../Form/FormSelect';
import { ICompany } from '../../../types/Companies';
import { IFormError } from '../../../constants/Interfaces';

interface ICompanySelectInput {
  inputColSizeSm: number;
  inputColSizeMd?: number;
  options: Array<ICompany>;
  fieldError?: IFormError;
}

const CompanySelectInput: React.FC<ICompanySelectInput> = ({
  inputColSizeSm,
  inputColSizeMd,
  options,
  fieldError,
}) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={inputColSizeSm} md={inputColSizeMd}>
        <FormSelect
          label={`${t('user_form_label_company')} *`}
          size='sm'
          hasEmptyOption
          emptyOptionLabel={t('user_form_placeholder_company')}
          name='company'
          options={options}
          optionValue='id'
          optionDisplay='name'
          fieldError={fieldError}
        />
      </Col>
    </Row>
  );
};

export default CompanySelectInput;
