import React from 'react';
import { Field } from 'react-final-form';
import './ExperienceFormCheckbox.scss';

interface IExperienceFormCheckBox {
  name: string;
  label?: JSX.Element;
  checkboxRef?: React.LegacyRef<HTMLLabelElement>;
}

const ExperienceFormCheckBox: React.FC<IExperienceFormCheckBox> = ({
  name,
  label,
  checkboxRef,
}) => {
  return (
    <div className='experience-checkbox-block'>
      <Field name={name} type='checkbox'>
        {({ input }) => (
          <input id={name} className='experience-checkbox-block__input' {...input} />
        )}
      </Field>
      <label
        htmlFor={name}
        className='experience-checkbox-block__label'
        ref={checkboxRef}
      >
        {label}
      </label>
    </div>
  );
};

export default ExperienceFormCheckBox;
