import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiCheckCircle } from 'react-icons/hi';

import './AcceptMessage.scss';

const AcceptMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='accept-message'>
      <HiCheckCircle className='accept-message__icon' />
      <span>{t('reservation_form_checkout_title')}</span>
    </div>
  );
};

export default AcceptMessage;
