import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  TReservationSettingsActions,
  IReservationSettingsState,
  initialReservationSettingsState,
  ReservationSettingsGet,
  ReservationSettingsAdd,
} from '../types/ReservationSettings';

export const reservationSettingsReducer: Reducer<
  IReservationSettingsState,
  TReservationSettingsActions
> = (state = initialReservationSettingsState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialReservationSettingsState;
    }
    case ReservationSettingsAdd.REQUEST:
    case ReservationSettingsGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ReservationSettingsAdd.FAILED:
    case ReservationSettingsGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ReservationSettingsAdd.ADD_RESERVATION_SETTINGS_STORE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case ReservationSettingsAdd.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ReservationSettingsGet.SUCCESS: {
      return {
        data: action.payload.data,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
