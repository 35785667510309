import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

interface IModalBasicButtons {
  handleClose: () => void;
  submitting: boolean;
  hideSaveBtn?: boolean;
  closeButtonText?: string;
  submitButtonText?: string;
}

const ModalBasicButtons: React.FC<IModalBasicButtons> = ({
  handleClose,
  submitting,
  hideSaveBtn,
  closeButtonText,
  submitButtonText,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant='light'
        onClick={handleClose}
        disabled={submitting}
        className='grey-borders mr-2'
      >
        {closeButtonText ?? t('form_btn_close')}
      </Button>
      {!hideSaveBtn && (
        <Button variant='primary' disabled={submitting} type='submit'>
          {submitButtonText ?? t('form_btn_save')}
        </Button>
      )}
    </>
  );
};

export default ModalBasicButtons;
