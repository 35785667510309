import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TEmployeeState } from 'types/Employees';
import { TResourceState } from 'types/Resources';
import {
  addService,
  deleteService,
  editService,
  getAllServices,
} from '../../../actions/ServicesActions';
import { IFormError } from '../../../constants/Interfaces';
import useError from '../../../hooks/useError';
import { handleError } from '../../../middlewares/ErrorHandler';
import { IAppState } from '../../../store/Store';
import { IServiceData, IServices } from '../../../types/Services';
import { TUserState } from '../../../types/User';
import AgreeToDelete from '../../Utils/Modals/AgreeToDelete';
import TabsModals from '../../Utils/Modals/TabsModal';
import LocationTab from '../../Utils/Tabs/LocationTab';
import Toast from '../../Utils/Toast';
import EmployeeTab from './EmployeeTab';
import MainInfoTab from './MainInfoTab';
import ResourceTab from './ResourceTab';

interface IServiceModalWrapper {
  show: boolean;
  handleClose: () => void;
  selectedService?: IServices;
  group?: string;
  setSelectedLocation: (value: string) => void;
}

const ServiceModalWrapper: React.FC<IServiceModalWrapper> = ({
  show,
  handleClose,
  group,
  selectedService,
  setSelectedLocation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [infoErrors, setInfoErrors] = useState<boolean>(false);
  const [employeeErrors, setEmployeeErrors] = useState<boolean>(false);
  const [locationErrors, setLocationErrors] = useState<boolean>(false);
  const [resourceErrors, setResourceErrors] = useState<boolean>(false);
  const [serviceToDelete, setServiceToDelete] = useState<string>();
  const [activeTab, setActiveTab] = useState<string | undefined>(
    t('service_create_tab_main_info'),
  );
  useError(error, setError);

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  const employeeState: TEmployeeState = useSelector<IAppState, TEmployeeState>(
    (state: IAppState) => state.employeeState,
  );
  const resourcesState: TResourceState = useSelector<IAppState, TResourceState>(
    (state: IAppState) => state.resourcesState,
  );

  const tabs = useMemo(
    () => [
      {
        key: t('service_create_tab_main_info'),
        component: <MainInfoTab fieldError={error} />,
        hasError: infoErrors,
      },
      {
        key: t('service_create_tab_employees_activity_type_pasiutuslape'),
        component: <EmployeeTab />,
        hasError: employeeErrors,
      },
      {
        key: t('service_create_tab_locations'),
        component: <LocationTab />,
        hasError: locationErrors,
      },
      {
        key: t('service_create_tab_resources'),
        component: <ResourceTab />,
        hasError: resourceErrors,
      },
    ],
    [infoErrors, employeeErrors, locationErrors, resourceErrors, error],
  );

  const formValidation = (values: IServiceData) => {
    setInfoErrors(false);
    setEmployeeErrors(false);
    setLocationErrors(false);
    setResourceErrors(false);
    const locationErrors: Record<string, any> = {};
    const employeeErrors: Record<string, any> = {};
    const mainInfoErrors: Record<string, any> = {};
    const resourceErrors: Record<string, any> = {};

    if (!values.name) {
      mainInfoErrors.name = t('required');
    }
    // check if there are no pricing fields
    if (values.pricings && values.pricings.length === 0) {
      mainInfoErrors.pricings = t('required');
    }
    if (!values.locations?.length) {
      locationErrors.locations = t('required');
    }
    if (!values.userIds?.length) {
      employeeErrors.userIds = t('required');
    }
    const resourceError = values?.resources?.some(value => value?.id && !value?.count);
    if (resourceError) {
      resourceErrors.resourceValues = t('required');
    }
    if (Object.keys(mainInfoErrors).length) {
      setInfoErrors(true);
    }
    if (Object.keys(locationErrors).length) {
      setLocationErrors(true);
    }
    if (Object.keys(employeeErrors).length) {
      setEmployeeErrors(true);
    }
    if (Object.keys(resourceErrors).length) {
      setResourceErrors(true);
    }
    return {
      mainInfoErrors,
      locationErrors,
      employeeErrors,
      resourceErrors,
    };
  };

  const handleSubmit = async (values: IServiceData) => {
    setError(undefined);
    setSelectedLocation('');
    const serviceObj = Object.assign({}, values);
    serviceObj.locationIds = values.locations;
    if (values.resources) {
      const filteredResoursesWithoutArrayGaps = values.resources
        .filter(value => !!value?.id && !!value?.count)
        .map(value => ({
          id: value.id,
          count: Number(value.count),
        }));
      const resourcesWithCorrectLocation = filteredResoursesWithoutArrayGaps.filter(
        item => {
          const element = resourcesState.allData?.find(res => res.id === item.id);
          if (!element) {
            return false;
          }
          return values.locations.includes(element?.locationId);
        },
      );
      serviceObj.resources = resourcesWithCorrectLocation;
    }
    const filteredEmployees = employeeState?.allData
      ?.filter(employee => values.userIds?.includes(employee.id))
      .map(empl => empl.id);
    serviceObj.userIds = filteredEmployees;
    if (userState.data?.companyId) {
      serviceObj.companyId = userState.data?.companyId;
    }
    if (group) {
      serviceObj.groupId = group;
    }
    // pick pricings, and if hours and minutes exist, convert them to minutes and add to duration field
    const pricings = values.pricings?.map(pricing => {
      const pricingObj = Object.assign({}, pricing);
      if (pricingObj.hours || pricingObj.minutes) {
        pricingObj.duration =
          parseInt(pricingObj?.hours || '0', 10) * 60 +
          parseInt(pricingObj.minutes || '0', 10);
      }
      return pricingObj;
    });
    serviceObj.pricings = pricings;
    if (serviceObj.pricings.length === 1) {
      serviceObj.pricings[0].name = undefined;
    }
    try {
      if (selectedService) {
        await dispatch(editService(serviceObj, selectedService.id));
        toast.success(<Toast text={t('editedService')} />);
      } else {
        await dispatch(addService(serviceObj));
        await dispatch(getAllServices());
        toast.success(<Toast text={t('addedService')} />);
        handleClose();
      }
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };

  const handleDelete = async () => {
    if (selectedService) {
      try {
        await dispatch(deleteService(selectedService.id));
        toast.success(<Toast text={t('successfullyDeleted', { item: t('service') })} />);
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    }
    handleClose();
  };

  const openDeleteDialog = () => {
    if (selectedService) {
      setServiceToDelete(selectedService.id);
    }
  };

  return (
    <>
      <TabsModals
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        show={show}
        handleClose={handleClose}
        customDialogClass='modal-xl'
        modalTitle={
          selectedService
            ? t('service_update_page_title')
            : t('service_create_page_title')
        }
        modalTabs={tabs}
        defaultActive={t('service_create_tab_main_info')}
        validation={formValidation}
        handleSubmit={handleSubmit}
        editValue={
          selectedService
            ? {
                ...selectedService,
                locations: selectedService.locationIds,
              }
            : undefined
        }
        handleDelete={openDeleteDialog}
      />
      <AgreeToDelete
        show={!!serviceToDelete}
        handleClose={() => setServiceToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </>
  );
};

export default ServiceModalWrapper;
