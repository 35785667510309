import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  TResourcesActions,
  TResourceState,
  initialResourceState,
  ResourcesGet,
  ResourceDelete,
  ResourceAdd,
  ResourceEdit,
  ResourcesPut,
  IResource,
  ResourcesGetAll,
} from '../types/Resources';

export const resourcesReducer: Reducer<TResourceState, TResourcesActions> = (
  state = initialResourceState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialResourceState;
    }
    case ResourceEdit.REQUEST:
    case ResourceAdd.REQUEST:
    case ResourceDelete.REQUEST:
    case ResourcesGetAll.REQUEST:
    case ResourcesGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ResourceEdit.FAILED:
    case ResourceAdd.FAILED:
    case ResourceDelete.FAILED:
    case ResourcesGetAll.FAILED:
    case ResourcesGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ResourcesPut.RESOURCES_OFFSET_UPDATE: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    case ResourceDelete.DELETE_RESOURCE_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.filter((rec: IResource) => rec.id !== action.payload)
          : [],
        total: state.total ? state.total - 1 : 0,
      };
    }
    case ResourceAdd.ADD_RESOURCE_STORE: {
      return {
        ...state,
        data: state.data ? [action.payload, ...state?.data] : [action.payload],
      };
    }
    case ResourceEdit.EDIT_RESOURCE_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.map(rec => (rec.id === action.payload.id ? action.payload : rec))
          : [],
      };
    }
    case ResourceEdit.SUCCESS:
    case ResourceAdd.SUCCESS:
    case ResourceDelete.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ResourcesGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isLoading: false,
        total: action.payload.data.total,
      };
    }
    case ResourcesGetAll.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allData: action.payload.data.data,
      };
    }
    default:
      return state;
  }
};
