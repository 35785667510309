import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'constants/Routes';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'store/Store';
import { EXPERIENCE_LANG_ITEM } from 'constants/Storage';
import { EXPERIENCE_LANGUAGES } from 'constants/Experience';
import ExperienceService from 'experience/pages/ExperienceServices';
import ExperienceFormPage from 'experience/pages/ExperienceFormPage';
import ExperienceCheckout from './pages/ExperienceCheckout';
import ExperienceDateTimePage from 'experience/pages/ExperienceDateTimePage';
import PageTitle from 'experience/components/PageTitle';
import PageFooter from 'experience/components/PageFooter';
import useGetDataForRedux from 'hooks/useGetDataForRedux';
import { IFormError } from 'constants/Interfaces';
import useError from 'hooks/useError';
import { ICompanyExperience, IExperienceCompanyState } from './types/ExperienceCompany';
import { getCompanyExperience } from './redux/actions/ExperienceCompanyActions';
import './index.scss';
import { IExperienceWidgetMultipeState } from 'types/ExperienceWidgetMultiple';
import { setExperienceWidgetCompanyMultiple } from './redux/actions/ExperienceWidgetActionMultiple';

const isValidLanguage = (lang: string) => EXPERIENCE_LANGUAGES.includes(lang);

const ExperienceRouter = () => {
  const { url, params }: { url: string; params: { lang?: string; company: string } } =
    useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const savedLanguage = localStorage.getItem(EXPERIENCE_LANG_ITEM);
  const isCheckoutPage = useRouteMatch(ROUTES.EXPERIENCE.CHECKOUT);
  const isServicesPage = useRouteMatch(ROUTES.EXPERIENCE.SERVICES);

  const [company, setCompany] = useState<ICompanyExperience>();
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const widgetState: IExperienceWidgetMultipeState = useSelector<
    IAppState,
    IExperienceWidgetMultipeState
  >((state: IAppState) => state.experienceWidgetMultipeState);

  const companyExperienceState: IExperienceCompanyState = useSelector<
    IAppState,
    IExperienceCompanyState
  >((state: IAppState) => state.experienceCompanyState);

  const companyId =
    params.company || (!isValidLanguage(params?.lang || '') && params.lang) || '';

  useGetDataForRedux(
    setError,
    companyExperienceState.data,
    getCompanyExperience(companyId),
  );

  useEffect(() => {
    if (!params.lang || !isValidLanguage(params.lang)) {
      // If no language or invalid language was entered in url
      if (widgetState.company?.locale) {
        // Set to default company language
        history.push(
          `${ROUTES.EXPERIENCE.MAIN}/${widgetState.company.locale.toLowerCase()}` +
            '/' +
            location.pathname.split('/').slice(2).join('/'),
        );
      }
      return;
    }

    localStorage.setItem(EXPERIENCE_LANG_ITEM, params.lang);

    if (
      !isServicesPage &&
      params.company &&
      (!widgetState || !widgetState.selectedServices?.length)
    ) {
      // user has no selection data in store and tried other link than '/services'. Route back.
      const language = params.lang || savedLanguage || 'lt';
      history.push(`${ROUTES.EXPERIENCE.MAIN}/${language}/${params.company}/services`);
      return;
    }

    if (i18n.language !== params.lang) {
      i18n.changeLanguage(params.lang);
    }
  }, [url, widgetState]);

  useEffect(() => {
    if (!companyExperienceState.data) {
      return;
    }

    const company = companyExperienceState.data;
    dispatch(setExperienceWidgetCompanyMultiple(company));
    setCompany(company);
  }, [companyExperienceState.data]);

  return (
    <div className='experience__root'>
      {!isCheckoutPage && <PageTitle title={company?.name || ''} />}
      <Switch>
        <Route exact path={ROUTES.EXPERIENCE.SERVICES} component={ExperienceService} />
        <Route exact path={ROUTES.EXPERIENCE.FORM} component={ExperienceFormPage} />
        <Route exact path={ROUTES.EXPERIENCE.CHECKOUT}>
          <ExperienceCheckout title={company?.name || ''} />
        </Route>
        <Route exact path={ROUTES.EXPERIENCE.TIME} component={ExperienceDateTimePage} />
      </Switch>
      <PageFooter isCheckoutPage={!isCheckoutPage} />
    </div>
  );
};

export default ExperienceRouter;
