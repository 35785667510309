import { apiCall } from 'actions/BaseAction';
import { API_END_POINTS } from 'constants/API';
import {
  LocationGetExperience,
  ILocationGetExperience,
  TLocationGetExperienceAction,
} from 'experience/types/ExperienceLocation';

export const getLocationExperience = (accessKey: string) => {
  return apiCall<TLocationGetExperienceAction, any, ILocationGetExperience>(
    LocationGetExperience,
    'GET',
    API_END_POINTS.GET_LOCATION_EXPERIENCE,
    false,
    {},
    {},
    accessKey,
  );
};
