import moment from 'moment';
import { EventInput } from '@fullcalendar/react';
import {
  IReservationAvailability,
  IReservationData,
  IReservationWithInfo,
  ReservationTypes,
} from '../../../types/Reservations';
import { IServices } from '../../../types/Services';
import { IClient } from '../../../types/Clients';
import { IEmployee } from '../../../types/Employees';
import { getSmallCalendarEventColor } from 'helpers/CalendarUtils';

interface ISmallCalendarEvent extends IReservationData {
  resourceId: string;
  title?: string;
  backgroundColor: string;
  borderColor?: string;
  display?: string;
}

export const formateBusinessHours = (
  events: EventInput[],
  availableTimes: IReservationAvailability[],
  selectedUserId: string,
  selectedDate: string,
  currentReservation?: IReservationData,
) => {
  const formatedEvents = events;
  if (selectedUserId === 'auto') {
    formatedEvents.push({
      resourceId: selectedUserId,
      groupId: 'businessHours',
      start: `${selectedDate}T00:00:00`,
      end: `${selectedDate}T24:00:00`,
      display: 'inverse-background',
    });
    return formatedEvents;
  }
  if (!availableTimes.length) {
    formatedEvents.push({
      resourceId: selectedUserId,
      groupId: 'businessHours',
      start: `${selectedDate}T00:00:00`,
      end: `${selectedDate}T24:00:00`,
      display: 'background',
    });
    return formatedEvents;
  }
  for (let i = 0; i < availableTimes.length; i++) {
    if (availableTimes[i].start !== availableTimes[i].end) {
      const workHours = {
        resourceId: selectedUserId,
        groupId: 'businessHours',
        start: availableTimes[i].start.slice(0, 16),
        end: availableTimes[i].end.slice(0, 16),
        display: 'inverse-background',
      };
      formatedEvents.push(workHours);
    }
  }
  if (currentReservation) {
    formatedEvents.push({
      resourceId: selectedUserId,
      groupId: 'businessHours',
      start: currentReservation.start.slice(0, 16),
      end: currentReservation.end.slice(0, 16),
      display: 'inverse-background',
    });
  }
  return formatedEvents;
};

export const formateCalendarDataSmall = (
  services: Array<IServices>,
  reservations: Array<IReservationData>,
  clients: Array<IClient>,
  employees: Array<IEmployee>,
  availableTimes: Array<IReservationAvailability>,
  selectedUserId: string,
  selectedDate: string,
  reservationToEdit: IReservationWithInfo,
  reservationPreview: IReservationData | undefined,
) => {
  const events: EventInput[] = [];
  const skipReservationsDate: string[] = [];

  reservations.forEach(reservation => {
    // we need to skip this date
    if (skipReservationsDate.includes(moment(reservation.start).toISOString())) {
      return;
    }

    const matchingReservations = reservations.filter(r => {
      // jeigu sita rezervacija yra preview (ji neturi id), sujungiam visas kurios sutampa su preview initialData duomenim
      // kad galetume stumdyt visa blokeli kaip viena grupine rezervacija
      if (
        !reservation.id &&
        moment(r.start).isSame(reservationPreview?.initialData?.start) &&
        reservationPreview?.initialData?.locationId === r.locationId &&
        reservationPreview?.initialData?.userId === r.userId
      ) {
        skipReservationsDate.push(
          moment(reservationPreview?.initialData?.start).toISOString(),
        );
        return true;
      }

      // jeigu prasideda tuo paciu metu, vadinas ta pati rezervacija -> sujungsim jas
      if (
        moment(r.start).isSame(reservation.start) &&
        r.userId === reservation.userId &&
        r.locationId === reservation.locationId &&
        r.serviceId === reservation.serviceId &&
        // TODO: check if this really works
        r.id !== reservation.id
      ) {
        skipReservationsDate.push(moment(reservation.start).toISOString());
        return true;
      }

      return false;
    });

    // get service name
    const { name: serviceName } =
      services.find(s => s.id === reservation.serviceId) || {};

    // get employee name
    const { name: employeeName, maxPersonsCount: employeeMaxPersons } =
      employees.find(employee => employee.id === reservation.userId) || {};

    const isMultiReservation =
      !!matchingReservations.length ||
      Number(reservation?.personsCount) > 1 ||
      Number(employeeMaxPersons) > 1;

    let totalPersonsCount = reservation.isPreview
      ? 0 // jeigu preview, imam jos asmenis kaip 0
      : Number(reservation.personsCount || 1);

    matchingReservations.forEach(r => {
      if (r.isPreview || !r.id || r.id === reservation.id) return; // Neskaiciuojam preview blokelio asmenu ir besikartojanciu id
      totalPersonsCount += Number(r.personsCount || 1);
    });

    const color = getSmallCalendarEventColor(reservation, isMultiReservation);
    const event: ISmallCalendarEvent = {
      ...reservation,
      resourceId: selectedUserId,
      backgroundColor: color,
      borderColor: color,
    };

    const clientName = reservation?.client?.name || '';
    const clientPhone = reservation?.client?.phone || '';

    event.title = JSON.stringify({
      serviceName: serviceName || '-',
      clientName:
        reservation.type === ReservationTypes.BLOCK
          ? ReservationTypes.BLOCK
          : clientName || '-',
      clientPhone: clientPhone || '-',
      price: reservation.price || 0,
      employeeName: employeeName || '-',
      employeeMaxPersons: employeeMaxPersons || 0,
      comments: reservation.userComments || '-',
      duration: reservation.duration || 0,
      start: `${reservation.start}`.slice(0, 16) || '-',
      end: `${reservation.end}`.slice(0, 16) || '-',
      clientId: reservation.clientId,
      totalPersonsCount: totalPersonsCount,
      isMultiReservation,
    });
    events.push(event);
  });

  return formateBusinessHours(
    events,
    availableTimes,
    selectedUserId,
    selectedDate,
    reservationToEdit,
  );
};
