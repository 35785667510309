import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  initialReportsByServicesState,
  initialReportsByUsersState,
  ReportsByServicesGet,
  ReportsByUsersGet,
  TReportsActions,
  TReportsByServicesState,
  TReportsByUsersState,
} from 'types/Reports';

export const reportsByUsersReducer: Reducer<TReportsByUsersState, TReportsActions> = (
  state = initialReportsByUsersState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialReportsByUsersState;
    }
    case ReportsByUsersGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ReportsByUsersGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ReportsByUsersGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export const reportsByServicesReducer: Reducer<TReportsByServicesState, TReportsActions> =
  (state = initialReportsByServicesState, action) => {
    switch (action.type) {
      case ResetStore.RESET: {
        return initialReportsByServicesState;
      }
      case ReportsByServicesGet.REQUEST: {
        return {
          ...state,
          isLoading: true,
        };
      }
      case ReportsByServicesGet.FAILED: {
        return {
          ...state,
          isLoading: false,
        };
      }

      case ReportsByServicesGet.SUCCESS: {
        return {
          ...state,
          data: action.payload.data,
          isLoading: false,
        };
      }

      default:
        return state;
    }
  };
