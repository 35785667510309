import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ButtonAdd from '../../components/Utils/ButtonAdd';
import './AdminEmployee.scss';
import { ILocation, TLocationState } from '../../types/Locations';
import { handleError } from '../../middlewares/ErrorHandler';
import { IAppState } from '../../store/Store';
import { IFormError, ISort } from '../../constants/Interfaces';
import { getLocations, changeLocationOffset } from '../../actions/LocationActions';
import TableSortable from '../../components/Utils/Tables/TableSortable';
import PaginationComp from '../../components/Utils/Tables/PaginationComp';
import AddEditLocationModal from '../../components/Admin/Locations/AddEditLocationModal';
import useError from '../../hooks/useError';
import { TABLE_PAGE_SIZE } from '../../constants/UtilVariables';

import './AdminLocations.scss';

const AdminLocations: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [tableData, setTableData] = useState<ILocation[]>([]);
  const data = React.useMemo(() => tableData, [tableData]);
  const [open, setOpen] = useState(false);
  const [pages, setPages] = useState<Array<number>>();
  const [activePage, setActivePage] = useState<number>(0);
  const [selectedLocation, setSelectedLocation] = useState<ILocation>();
  useError(error, setError);

  const locationState: TLocationState = useSelector<IAppState, TLocationState>(
    (state: IAppState) => state.locationState,
  );

  useEffect(() => {
    return function cleanup() {
      dispatch(changeLocationOffset(0));
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        //first load offset is always 0
        await dispatch(getLocations(0, undefined, undefined, TABLE_PAGE_SIZE));
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (locationState.data) {
        setTableData(locationState.data);
        if (locationState.total) {
          const pages = Array.from(
            Array(Math.ceil(locationState.total / TABLE_PAGE_SIZE)).keys(),
          );
          if (pages.length > 1) {
            setPages(pages);
          } else {
            setPages(undefined);
          }
        }
      }
    })();
  }, [dispatch, locationState.data]);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('location_table_col_name')}`,
        accessor: 'name',
        id: 'name',
      },
      {
        Header: `${t('location_table_col_city')}`,
        accessor: 'city',
        id: 'city',
      },
      {
        Header: `${t('location_table_col_phone')}`,
        accessor: 'phone',
        id: 'phone',
      },
      {
        Header: `${t('location_table_col_address')}`,
        accessor: 'address',
        id: 'address',
      },
    ],
    [],
  );

  const changePage = async (newPage: number) => {
    setActivePage(newPage);
    dispatch(changeLocationOffset(newPage * TABLE_PAGE_SIZE));
    await dispatch(
      getLocations(newPage * TABLE_PAGE_SIZE, undefined, undefined, TABLE_PAGE_SIZE),
    );
  };

  const handleSort = useCallback(async (sortBy: Array<ISort>) => {
    try {
      if (sortBy[0].id) {
        const sortDirection = sortBy[0].desc ? 'desc' : 'asc';
        const sortQuery = `${sortBy[0].id} ${sortDirection}`;
        await dispatch(
          getLocations(locationState.offset || 0, '', sortQuery, TABLE_PAGE_SIZE),
        );
      }
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  }, []);

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='mainCard'>
            <Card.Header className='title-big'>
              {t('location_table_col_page_header')}
            </Card.Header>
            <Card.Body>
              <Row className='align-items-end mb-3'>
                <Col sm={12} className='d-flex justify-content-end'>
                  <ButtonAdd
                    text={t('location_btn_create_locations')}
                    handleClick={() => setOpen(true)}
                  />
                </Col>
              </Row>
              <Row className='mt-4 mt-md-0'>
                <Col sm={12}>
                  <TableSortable
                    striped
                    data={data}
                    columns={columns}
                    isLoading={locationState.isLoading}
                    selectRow={setSelectedLocation}
                    customWidth='25%'
                    applyCustomWidthTo={['name', 'city', 'address', 'phone']}
                    openModal={setOpen}
                    onSort={handleSort}
                  />
                  <PaginationComp
                    activePage={activePage}
                    pages={pages}
                    changePage={changePage}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddEditLocationModal
        show={open}
        selectedLocation={selectedLocation}
        handleClose={() => {
          setOpen(false);
          setSelectedLocation(undefined);
        }}
      />
    </Container>
  );
};

export default AdminLocations;
