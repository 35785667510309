import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MdErrorOutline } from 'react-icons/md';
import './AgreeToDelete.scss';

interface IAgreeToDelete {
  show: boolean;
  handleClose: () => void;
  handleAccept?: () => void;
  customText?: string;
}

const AgreeToDelete: React.FC<IAgreeToDelete> = ({
  show,
  handleClose,
  handleAccept,
  customText,
}) => {
  const { t } = useTranslation();

  const handleClick = async () => {
    await handleAccept?.();
    handleClose();
  };

  return (
    <Modal id='deleteModal' show={show} onHide={handleClose} centered>
      <Modal.Body className='flexContainer container-body'>
        <div>
          <MdErrorOutline className='icon' />
        </div>
        <div className='attention mt-1'>{t('messages_delete_alert_message_title')}</div>
        <div className='to-delete mt-3'>
          {customText ? customText : t('messages_delete_alert_message')}
        </div>
        <div className='button-container mt-3'>
          <Button
            variant='secondary'
            className='button p-2 mr-3 close-button'
            size='lg'
            onClick={handleClose}
          >
            {t('messages_alert_cancel_btn')}
          </Button>
          <Button
            variant='danger'
            className='button agree-button'
            size='lg'
            onClick={handleClick}
          >
            {t('messages_alert_confirm_btn')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AgreeToDelete;
