import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FaEdit, FaPlus } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { IAppState } from '../../store/Store';
import { TCompanyState, ICompany } from '../../types/Companies';
import { TEmployeeState, IEmployee } from '../../types/Employees';
import { IFormError, ISort } from '../../constants/Interfaces';
import { handleError } from '../../middlewares/ErrorHandler';
import {
  getEmployees,
  deleteEmployee,
  changeOffset,
} from '../../actions/EmployeesActions';
import { getAllCompaniesUnpaginated } from '../../actions/CompaniesActions';
import TableFilter from '../../components/Utils/Tables/TableFilter';
import TableSortable from '../../components/Utils/Tables/TableSortable';
import PaginationComp from '../../components/Utils/Tables/PaginationComp';
import AgreeToDelete from '../../components/Utils/Modals/AgreeToDelete';
import EmployeeModal from '../../components/SuperAdmin/Employees/EmployeeModal';
import Toast from '../../components/Utils/Toast';
import useError from '../../hooks/useError';
import { TABLE_PAGE_SIZE } from '../../constants/UtilVariables';
import { COMPANIES_PAGE } from 'constants/Storage';

import './AdminWorker.scss';
import { PAGE_LIMIT } from 'constants/API';

const AdminWorkers: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [tableData, setTableData] = useState<IEmployee[]>([]);
  const [availableCompanies, setAvailableCompanies] = useState<Array<ICompany>>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [open, setOpen] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState<string>();
  const [employeeToEdit, setEmployeeToEdit] = useState<IEmployee>();
  const [pages, setPages] = useState<Array<number>>();
  const [activePage, setActivePage] = useState<number>(0);
  const data = React.useMemo(() => tableData, [tableData]);

  useError(error, setError);
  const employeeState: TEmployeeState = useSelector<IAppState, TEmployeeState>(
    (state: IAppState) => state.employeeState,
  );
  const companyState: TCompanyState = useSelector<IAppState, TCompanyState>(
    (state: IAppState) => state.companyState,
  );

  const formateTableData = (
    employeeData: Array<IEmployee>,
    companyData: Array<ICompany>,
  ) => {
    const newTableData = [];
    for (let i = 0; i < employeeData.length; i++) {
      newTableData.push({
        ...employeeData[i],
        companyName:
          companyData.find(rec => rec.id === employeeData[i]?.companyId)?.name || '',
      });
    }
    return newTableData;
  };

  useEffect(() => {
    localStorage.removeItem(COMPANIES_PAGE);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(
          getEmployees(
            employeeState.offset || 0,
            searchQuery,
            selectedOption,
            undefined,
            TABLE_PAGE_SIZE,
          ),
        );
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
    return () => {
      dispatch(changeOffset(0));
    };
  }, [dispatch]);

  useEffect(() => {
    const offset = companyState.allData?.length ?? 0;
    (async () => {
      try {
        if (!companyState.total || !companyState.allData?.length) {
          return await dispatch(
            getAllCompaniesUnpaginated({ limit: PAGE_LIMIT, offset: 0 }),
          );
        }
        if (companyState.total <= companyState.allData?.length) return;
        await dispatch(getAllCompaniesUnpaginated({ limit: PAGE_LIMIT, offset: offset }));
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch, companyState.allData]);

  useEffect(() => {
    if (employeeState.data && companyState.allData) {
      const formatedData = formateTableData(employeeState.data, companyState.allData);
      setAvailableCompanies(companyState.allData);

      setTableData(formatedData);
      if (employeeState.total) {
        const pages = Array.from(
          Array(Math.ceil(employeeState.total / TABLE_PAGE_SIZE)).keys(),
        );
        if (pages.length > 1) {
          setPages(pages);
        } else {
          setPages(undefined);
        }
      } else {
        setPages(undefined);
      }
    }
  }, [companyState.allData, employeeState.data]);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('user_table_col_name')}`,
        accessor: 'name',
        id: 'name',
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => (
          <div>
            {cell.row.original.name} {cell.row.original.lastName}
          </div>
        ),
      },
      {
        Header: `${t('user_table_col_email')}`,
        accessor: 'email',
        id: 'email',
      },
      {
        Header: `${t('user_table_col_phone')}`,
        accessor: 'phoneNumber',
        id: 'phoneNumber',
      },
      {
        Header: `${t('user_table_col_company')}`,
        accessor: 'companyName',
        id: 'companyName',
        disableSortBy: true,
      },
      {
        Header: '',
        id: 'buttons',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => (
          <div className='flex'>
            <Button
              size='sm'
              className='btn btn-sm btn-warning centerIcon mx-1 py-2 my-lg-0 white'
              onClick={() => {
                setEmployeeToEdit(cell.row.original);
                setOpen(true);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              size='sm'
              className='btn btn-sm btn-danger centerIcon mx-1 py-2 my-lg-0'
              onClick={() => setEmployeeToDelete(cell.row.original.id)}
            >
              <IoClose />
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  const handleSort = useCallback(async (sortBy: Array<ISort>) => {
    try {
      if (sortBy[0].id) {
        const sortDirection = sortBy[0].desc ? 'desc' : 'asc';
        const sortQuery = `${sortBy[0].id} ${sortDirection}`;
        await dispatch(
          getEmployees(
            employeeState.offset || 0,
            searchQuery,
            selectedOption,
            sortQuery,
            TABLE_PAGE_SIZE,
          ),
        );
      }
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  }, []);

  const handleSearch = async () => {
    try {
      // we set offSet to 0 to get first page data, becouse we dont know if it has more pages
      await dispatch(
        getEmployees(0, searchQuery, selectedOption, undefined, TABLE_PAGE_SIZE),
      );
      setActivePage(0);
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const handleDelete = async () => {
    if (employeeToDelete) {
      try {
        await dispatch(deleteEmployee(employeeToDelete));
        toast.success(
          <Toast text={t('successfullyDeleted', { item: t('user_role_employee') })} />,
        );
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    }
  };

  const changePage = async (newPage: number) => {
    setActivePage(newPage);
    dispatch(changeOffset(newPage * TABLE_PAGE_SIZE));
    await dispatch(
      getEmployees(
        newPage * TABLE_PAGE_SIZE,
        searchQuery,
        selectedOption,
        undefined,
        TABLE_PAGE_SIZE,
      ),
    );
  };

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='p-3 w-100'>
            <h3 className='font-weight-light'>{t('user_table_page_title')}</h3>
          </Card>
          <Card className='mainCard'>
            <Card.Header className='title'>{t('user_table_header')}</Card.Header>
            <Card.Body>
              <Row className='align-items-start'>
                <Col sm={12} md={7} lg={9} xl={9}>
                  <TableFilter
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    options={availableCompanies}
                    setSelectedOption={setSelectedOption}
                    handleSearch={handleSearch}
                    selectPlaceHolder={t('filter_select_company')}
                  />
                </Col>

                <Col sm={12} md={5} lg={3} xl={3} className='d-flex justify-content-end'>
                  <Button className='btn-info centerIcon' onClick={() => setOpen(true)}>
                    <FaPlus className='pr-1' />
                    {t('user_btn_create_client')}
                  </Button>
                </Col>
              </Row>
              <Row className='mt-4 mt-md-0'>
                <Col sm={12}>
                  <TableSortable
                    striped
                    data={data}
                    columns={columns}
                    onSort={handleSort}
                    customWidth='22.5%'
                    applyCustomWidthTo={['name', 'phoneNumber', 'companyName', 'email']}
                    isLoading={companyState.isLoading && employeeState.isLoading}
                  />
                  <PaginationComp
                    activePage={activePage}
                    pages={pages}
                    changePage={changePage}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {open && (
        <EmployeeModal
          show={open}
          handleClose={() => {
            setOpen(false);
            setEmployeeToEdit(undefined);
          }}
          employee={employeeToEdit}
        />
      )}

      <AgreeToDelete
        show={!!employeeToDelete}
        handleClose={() => setEmployeeToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </Container>
  );
};

export default AdminWorkers;
