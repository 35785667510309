import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormModalExtended from 'components/Utils/Modals/FormModalExtended';
import UrgentMessageForm from './UrgentMessageForm';
import { IUrgentMessageBody, IUrgentMessageFields } from 'types/Notifications';
import { useDispatch } from 'react-redux';
import { sendUrgentMessage } from 'actions/NotificationsActions';
import { toast } from 'react-toastify';
import Toast from 'components/Utils/Toast';
import { handleError } from 'middlewares/ErrorHandler';
import { IFormError } from 'constants/Interfaces';
import useError from 'hooks/useError';
import { IReservation } from 'types/Reservations';

interface IUrgentMessageModal {
  show: boolean;
  handleClose: () => void;
  reservations?: Array<IReservation>;
}

const errorTranslationKeys: Record<string, string> = {
  INVALID_MESSAGE: 'urgent_message_invalid_message',
};

const UrgentMessageModal: React.FC<IUrgentMessageModal> = ({
  show,
  handleClose,
  reservations,
}) => {
  const [failedToSubmit, setFailedToSubmit] = useState(false);
  const [error, setError] = useState<IFormError>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useError(error, setError, false);

  const handleSendMessage = async (values: IUrgentMessageFields) => {
    if (validateForm(values).hasErrors) {
      setFailedToSubmit(true);
      return;
    }

    if (!reservations) return;

    const { subject, emailMessage, smsMessage } = values;
    const data: IUrgentMessageBody = {
      emailSubject: subject,
      emailBodyTemplate: emailMessage,
      smsTemplate: smsMessage,
    };

    try {
      await Promise.all(
        reservations.map(reservation =>
          dispatch(sendUrgentMessage(reservation?.id || '', data)),
        ),
      );
      toast.success(<Toast text={t('urgent_message_sent')} />);
      handleClose();
    } catch (err) {
      const title = String(err.response?.data?.title);
      const message = errorTranslationKeys[title] ?? title;
      handleError(
        err?.response?.status,
        setError,
        message,
        err.response?.data?.CausedByField,
      );
    }
  };

  const validateForm = (values: IUrgentMessageFields) => {
    const { subject, emailMessage, smsMessage } = values;
    const formErrors: Record<string, string> = {};

    if (!subject && emailMessage) {
      formErrors.subject = t('send_urgent_message_subject_error');
    }

    if (!smsMessage && !subject && !emailMessage) {
      formErrors.emptyFields = t('required');
    }

    return {
      formErrors,
      hasErrors: Object.keys(formErrors).length > 0,
    };
  };

  return (
    <div className='add-edit-reservation'>
      <FormModalExtended
        show={show}
        handleClose={handleClose}
        modalTitle={t('form_btn_reservation_urgent_message_single')}
        validation={validateForm}
        handleSubmit={handleSendMessage}
        component={<UrgentMessageForm failedToSubmit={failedToSubmit} />}
        editValue={{ subject: '', emailMessage: '', smsMessage: '' }}
        modalClass='urgent-message-modal'
        submitButtonText={t('send_urgent_message')}
      />
    </div>
  );
};

export default UrgentMessageModal;
