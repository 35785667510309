const Env = {
  REACT_APP_API_URL:
    (<any>window).env?.REACT_APP_API_URL || `${process.env.REACT_APP_API_URL}`,

  REACT_APP_BASE_API_URL:
    (<any>window).env?.REACT_APP_BASE_API_URL || `${process.env.REACT_APP_BASE_API_URL}`,

  REACT_APP_CALENDAR_LICENSE_KEY:
    (<any>window).env?.REACT_APP_CALENDAR_LICENSE_KEY ||
    `${process.env.REACT_APP_CALENDAR_LICENSE_KEY}`,
  REACT_APP_IP_TOKEN:
    (<any>window).env?.REACT_APP_IP_TOKEN || `${process.env.REACT_APP_IP_TOKEN}`,
  REACT_APP_SHOW_INSTRUCTION_TAB:
    (<any>window).env?.REACT_APP_SHOW_INSTRUCTION_TAB ||
    `${process.env.REACT_APP_SHOW_INSTRUCTION_TAB}`,
  REACT_APP_SHOW_CHECKBOX:
    (<any>window).env?.REACT_APP_SHOW_CHECKBOX ||
    `${process.env.REACT_APP_SHOW_CHECKBOX}`,
};

export default Env;
