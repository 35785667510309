import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import FormInput from '../Form/FormInput';
import { MdBlock } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import './LoginForm.scss';
import { ILoginData } from '../../types/User';

interface ILoginForm {
  error?: boolean;
  setError?: (error: boolean | undefined) => void;
  onLogIn: (values: ILoginData) => void;
}

const LoginForm: React.FC<ILoginForm> = ({ error, setError, onLogIn }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: ILoginData) => {
    // Called whenever user submits form
    setIsLoading(true);
    await onLogIn?.(values);
    setIsLoading(false);
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={(values: ILoginData) => {
        const errors: any = {};
        if (!values.username) {
          errors.username = t('required');
        }
        if (!values.password) {
          errors.password = t('required');
        }
        return errors;
      }}
      render={({ handleSubmit, submitting }) => (
        <Form onSubmit={handleSubmit} className='loginForm p-3'>
          <Container>
            {error && (
              <Row>
                <Col xs={12}>
                  <Alert
                    variant='danger'
                    onClose={() => setError?.(undefined)}
                    dismissible
                    transition
                  >
                    <Alert.Heading className='alertHeading'>
                      <MdBlock className='mr-1' />
                      {t('login_form_error_header')}!
                    </Alert.Heading>
                    <p className='font-weight-light'>{t('incorrectLogin')}</p>
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <FormInput
                  size='sm'
                  placeholder={t('login_form_username')}
                  name='username'
                  type='text'
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormInput
                  size='sm'
                  placeholder={t('login_form_password')}
                  name='password'
                  type='password'
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={{ offset: 3, span: 6 }}>
                <Button
                  type='submit'
                  size='sm'
                  variant='primary'
                  className='button'
                  disabled={submitting}
                >
                  {isLoading ? <Spinner animation='border' /> : t('login_form_login_btn')}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    />
  );
};

export default LoginForm;
