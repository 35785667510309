import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  TWorkTimeActions,
  IWorkTimeState,
  initialWorkTimeState,
  WorkTimeGet,
  WorkTimeAdd,
  WorkTimePut,
  WorkTimeGetAll,
} from '../types/WorkTime';

export const workTimeReducer: Reducer<IWorkTimeState, TWorkTimeActions> = (
  state = initialWorkTimeState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialWorkTimeState;
    }
    case WorkTimeAdd.REQUEST:
    case WorkTimeGetAll.REQUEST:
    case WorkTimeGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case WorkTimeAdd.FAILED:
    case WorkTimeGetAll.FAILED:
    case WorkTimeGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case WorkTimePut.WORK_TIME_OFFSET_UPDATE: {
      return {
        ...state,
        offset: action.payload,
      };
    }

    case WorkTimePut.CLEAR_WORK_TIME_STORE: {
      return {
        ...state,
        data: undefined,
        allData: undefined,
      };
    }

    case WorkTimeAdd.ADD_WORK_TIME_STORE: {
      const uniqueOldAllData =
        state.allData?.filter(item => {
          const newObject = action.payload.workTime.find(({ id }) => id === item.id);
          if (!newObject) {
            return item;
          }
        }) || [];
      return {
        ...state,
        data: action.payload.workTime,
        allData: state.allData
          ? [...uniqueOldAllData, ...action.payload.workTime]
          : action.payload.workTime,
      };
    }

    case WorkTimeAdd.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case WorkTimeGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isLoading: false,
        total: action.payload.data.total,
      };
    }
    case WorkTimeGetAll.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allData: action.payload.data.data,
      };
    }
    default:
      return state;
  }
};
