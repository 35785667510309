import React from 'react';
import clsx from 'clsx';

import { Field, FieldInputProps } from 'react-final-form';

import ExperienceFormError from './ExperienceFormError';
import './ExperienceFormInput.scss';

interface IExperienceFormInput
  extends Partial<Pick<FieldInputProps<any, HTMLInputElement>, 'onBlur' | 'onChange'>> {
  name: string;
  type?: string;
  label?: string;
  validation?: (value: any) => any;
  className?: string;
}

const ExperienceFormInput: React.FC<IExperienceFormInput> = ({
  name,
  label,
  type,
  validation,
  className,
  ...rest
}) => {
  return (
    <div className={clsx(`experience-input-block`, className)}>
      {label && (
        <label htmlFor={name} className='experience-input-block__label'>
          {label}
        </label>
      )}
      {type === 'textarea' ? (
        <Field name={name}>
          {({ input }) => (
            <div className='experience-input-block__input-container'>
              <textarea
                rows={4}
                id={name}
                className='experience-input-block__input'
                {...rest}
                {...input}
              />
            </div>
          )}
        </Field>
      ) : (
        <Field name={name} validate={validation}>
          {({ input, meta }) => (
            <div className='experience-input-block__input-container'>
              <input
                type={type}
                id={name}
                className={clsx(
                  'experience-input-block__input',
                  meta.error && meta.touched && 'experience-input-block__input--error',
                )}
                {...rest}
                {...input}
                onChange={e => {
                  rest?.onChange?.(e);
                  input?.onChange?.(e);
                }}
                onBlur={e => {
                  rest?.onBlur?.(e);
                  input?.onBlur?.(e);
                }}
              />
              {meta.error && meta.touched && (
                <ExperienceFormError errorText={meta.error} />
              )}
            </div>
          )}
        </Field>
      )}
    </div>
  );
};

export default ExperienceFormInput;
