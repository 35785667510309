/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';
import { IEmployee } from './Employees';
import { ILocation } from './Locations';

export interface ICustomWorkTime {
  id: string;
  userId: string;
  locationId: string;
  day: string;
  companyId: string;
  start: string;
  end: string;
  customWorkTimeBreaks?: ICustomWorkTimeBreak[];
  enabled: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface ICustomWorkTimeTableDataTime {
  id?: string;
  startHour: string;
  startMin: string;
  endHour: string;
  endMin: string;
}

export interface ICustomWorkTimeTableData extends ICustomWorkTimeTableDataTime {
  day: string;
  formattedDate: string;
  enabled: boolean;
  breaks: ICustomWorkTimeTableDataTime[];
  employee: IEmployee;
  location: ILocation;
}

export interface ICustomWorkTimeBreak {
  id: string;
  start: string;
  end: string;
}

export interface ICustomWorkTimeData {
  userId: string;
  locationId: string;
  day: string;
  customWorkTimeBreaks?: ICustomWorkTimeBreak[];
  companyId: string;
  start?: string;
  end?: string;
  enabled: boolean;
  id?: string;
}

export interface ICustomWorkTimeDataWithId extends ICustomWorkTimeData {
  id: string;
}

export interface IGetCustomWorkTime {
  data: Array<ICustomWorkTime>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

export interface ICustomWorkTimeState {
  readonly data?: Array<ICustomWorkTime>;
  readonly allData?: Array<ICustomWorkTime>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
}

export const initialCustomWorkTimeState: ICustomWorkTimeState = {
  data: undefined,
  allData: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
};

export enum CustomWorkTimeAdd {
  REQUEST = 'CUSTOM_WORK_TIME_ADD_REQUEST',
  SUCCESS = 'CUSTOM_WORK_TIME_ADD_SUCCESS',
  FAILED = 'CUSTOM_WORK_TIME_ADD_FAILED',
  ADD_CUSTOM_WORK_TIME_STORE = 'CUSTOM_WORK_TIME_ADD_TO_STORE',
}

export enum CustomWorkTimeEdit {
  REQUEST = 'CUSTOM_WORK_TIME_EDIT_REQUEST',
  SUCCESS = 'CUSTOM_WORK_TIME_EDIT_SUCCESS',
  FAILED = 'CUSTOM_WORK_TIME_EDIT_FAILED',
  EDIT_CUSTOM_WORK_TIME_STORE = 'CUSTOM_WORK_TIME_EDIT_ADD_TO_STORE',
}

export enum CustomWorkTimeGet {
  REQUEST = 'CUSTOM_WORK_TIME_GET_REQUEST',
  SUCCESS = 'CUSTOM_WORK_TIME_GET_SUCCESS',
  FAILED = 'CUSTOM_WORK_TIME_GET_FAILED',
}

export enum CustomWorkTimeGetAll {
  REQUEST = 'CUSTOM_WORK_TIME_GET_ALL_REQUEST',
  SUCCESS = 'CUSTOM_WORK_TIME_GET_ALL_SUCCESS',
  FAILED = 'CUSTOM_WORK_TIME_GET_ALL_FAILED',
}

export enum CustomWorkTimePut {
  CUSTOM_WORK_TIME_OFFSET_UPDATE = 'CUSTOM_WORK_TIME_OFFSET_UPDATE',
}

export enum CustomWorkTimeDelete {
  REQUEST = 'CUSTOM_WORK_TIME_DELETE_REQUEST',
  SUCCESS = 'CUSTOM_WORK_TIME_DELETE_SUCCESS',
  FAILED = 'CUSTOM_WORK_TIME_DELETE_SUCCESS',
  DELETE_CUSTOM_WORK_TIME_STORE = 'CUSTOM_WORK_TIME_DELETE_FROM_STORE',
}

export type TCustomWorkTimePutAction = {
  type: CustomWorkTimePut.CUSTOM_WORK_TIME_OFFSET_UPDATE;
  payload: number;
};

export type TCustomWorkTimeEditAction =
  | {
      type: CustomWorkTimeEdit.REQUEST;
    }
  | {
      type: CustomWorkTimeEdit.SUCCESS;
    }
  | {
      type: CustomWorkTimeEdit.EDIT_CUSTOM_WORK_TIME_STORE;
      payload: ICustomWorkTime;
    }
  | {
      type: CustomWorkTimeEdit.FAILED;
      payload: AxiosError;
    };

export type TCustomWorkTimeAddAction =
  | {
      type: CustomWorkTimeAdd.REQUEST;
    }
  | {
      type: CustomWorkTimeAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: CustomWorkTimeAdd.ADD_CUSTOM_WORK_TIME_STORE;
      payload: ICustomWorkTime;
    }
  | {
      type: CustomWorkTimeAdd.FAILED;
      payload: AxiosError;
    };

export type TCustomWorkTimeDeleteAction =
  | {
      type: CustomWorkTimeDelete.REQUEST;
    }
  | {
      type: CustomWorkTimeDelete.SUCCESS;
    }
  | {
      type: CustomWorkTimeDelete.DELETE_CUSTOM_WORK_TIME_STORE;
      payload: string;
    }
  | {
      type: CustomWorkTimeDelete.FAILED;
      payload: AxiosError;
    };

export type TCustomWorkTimeGetAction =
  | {
      type: CustomWorkTimeGet.REQUEST;
    }
  | {
      type: CustomWorkTimeGet.SUCCESS;
      payload: AxiosResponse<IGetCustomWorkTime>;
    }
  | {
      type: CustomWorkTimeGet.FAILED;
      payload: AxiosError;
    };

export type TCustomWorkTimeGetAllAction =
  | {
      type: CustomWorkTimeGetAll.REQUEST;
    }
  | {
      type: CustomWorkTimeGetAll.SUCCESS;
      payload: AxiosResponse<IGetCustomWorkTime>;
    }
  | {
      type: CustomWorkTimeGetAll.FAILED;
      payload: AxiosError;
    };

export type TCustomWorkTimeActions =
  | TCustomWorkTimeGetAction
  | TCustomWorkTimeDeleteAction
  | TCustomWorkTimeEditAction
  | TCustomWorkTimeAddAction
  | TCustomWorkTimeGetAllAction
  | TCustomWorkTimePutAction
  | TResetAction;
