export const EXPERIENCE_LANGUAGES = ['lt', 'en', 'ru', 'lv', 'pl', 'cz'];

export enum ExperienceLocales {
  LT = 'lt',
  EN = 'en',
  LV = 'lv',
  RU = 'ru',
  PL = 'pl',
  CZ = 'cz',
}
