import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import FormInput from '../../Form/FormInput';
import { Row } from 'react-bootstrap';
import { IFormError } from '../../../constants/Interfaces';

interface IMaxPeopleCount {
  inputColSizeSm: number;
  inputColSizeMd?: number;
  fieldError?: IFormError;
  disabled: boolean;
}

const MaxPeopleCount: React.FC<IMaxPeopleCount> = ({
  inputColSizeSm,
  inputColSizeMd,
  fieldError,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={inputColSizeSm} md={inputColSizeMd}>
        <FormInput
          size='sm'
          label={t('max_served_people')}
          name='maxPersonsCount'
          type='text'
          fieldError={fieldError}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default MaxPeopleCount;
