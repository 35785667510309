import React from 'react';
import Logo from 'assets/images/logo.png';
import './Header.scss';
import Button from 'landing/components/Button';
import LanguageSelect from 'landing/components/LanguageSelect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'constants/Routes';

export const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleLogin = () => {
    history.push(ROUTES.LOGIN);
  };

  return (
    <header className='landing__header'>
      <div className='landing__container'>
        <div className='d-flex justify-content-between align-items-center'>
          <img className='landing__logo' src={Logo} alt='logo' />
          <div className='d-flex align-items-center justify-content-end'>
            <Button onClick={handleLogin}>{t('landing_login')}</Button>
            <LanguageSelect />
          </div>
        </div>
      </div>
    </header>
  );
};
