import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import lt from 'date-fns/locale/lt';
import lv from 'date-fns/locale/lv';
import pl from 'date-fns/locale/pl';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import '../../../assets/styles/DatepickerStylesOverride.scss';
import cn from 'classnames';
import FormSelect from '../../Form/FormSelect';
import { Button, ButtonGroup, Table } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import WorkTimeErrors from '../WorkTime/WorkTimeErrors';
import { FieldArray } from 'react-final-form-arrays';
import { IoClose } from 'react-icons/io5';
import { getHours, getMinutes } from '../../../helpers/FormHelpers';
import './CustomWorkTimeModaForm.scss';

registerLocale('lt', lt);
registerLocale('en', en);
registerLocale('lv', lv);
registerLocale('pl', pl);
registerLocale('ru', ru);

interface IModalForm {
  values?: any;
  setValue?: (name: string, value: any) => void;
  errors?: Record<string, any>;
  push?: any;
  submitFailed?: boolean;
}

const ModalForm: FC<IModalForm> = ({ values, setValue, push, errors, submitFailed }) => {
  const { t } = useTranslation();
  const rowHasErrors =
    errors &&
    (Object.keys(errors).includes('timeRange') ||
      Object.keys(errors).includes('breakOverlap')) &&
    submitFailed;
  return (
    <div>
      <div>{t('location') + ': ' + values?.location?.name}</div>
      <div>
        {t('user_role_employee') +
          ': ' +
          values?.employee?.name +
          ' ' +
          (values?.employee?.lastName || '')}
      </div>
      <div className='mb-2'>
        {t('employee_calendar_form_user_custom_work_time_label_date') +
          ': ' +
          values?.formattedDate}
      </div>
      <ButtonGroup aria-label='Basic example' className='mb-2'>
        <Button
          variant={values?.enabled ? 'primary' : 'light'}
          className='grey-borders'
          size='sm'
          onClick={() => setValue?.('enabled', true)}
        >
          {t('employee_calendar_button_working')}
        </Button>
        <Button
          variant={values?.enabled ? 'light' : 'primary'}
          className='grey-borders'
          size='sm'
          onClick={() => setValue?.('enabled', false)}
        >
          {t('employee_calendar_button_not_working')}
        </Button>
      </ButtonGroup>
      {values.enabled && (
        <Table responsive='md' size='sm' className='custom-work-time-table'>
          <tbody>
            <tr className='gray-bg'>
              <th />
              <th>{t('form_label_work_time_start')}</th>
              <th>{t('form_label_work_time_end')}</th>
              <th />
            </tr>
            <tr
              className={cn({
                'row-invalid': rowHasErrors,
              })}
            >
              <td />
              <td>
                <Row className='m-0'>
                  <Col xs={6} md={4} className='p-0'>
                    <FormSelect
                      hasEmptyOption={false}
                      emptyOptionLabel={''}
                      name='startHour'
                      options={getHours()}
                      optionValue='id'
                      optionDisplay='name'
                      inputClass='mb-0 time-start'
                    />
                  </Col>
                  <Col xs={6} md={4} className='p-0'>
                    <FormSelect
                      hasEmptyOption={false}
                      emptyOptionLabel={''}
                      name='startMin'
                      options={getMinutes()}
                      optionValue='id'
                      optionDisplay='name'
                      inputClass='mb-0 time-end'
                    />
                  </Col>
                </Row>
              </td>
              <td>
                <Row className='m-0'>
                  <Col xs={6} md={4} className='p-0'>
                    <FormSelect
                      hasEmptyOption={false}
                      emptyOptionLabel={''}
                      name='endHour'
                      options={getHours()}
                      optionValue='id'
                      optionDisplay='name'
                      inputClass='mb-0 time-start'
                    />
                  </Col>
                  <Col xs={6} md={4} className='p-0'>
                    <FormSelect
                      hasEmptyOption={false}
                      emptyOptionLabel={''}
                      name='endMin'
                      options={getMinutes()}
                      optionValue='id'
                      optionDisplay='name'
                      inputClass='mb-0 time-end'
                    />
                  </Col>
                </Row>
              </td>
              <td className='d-flex justify-content-end'>
                <Button
                  className='btn-info centerIcon'
                  onClick={() =>
                    push('breaks', {
                      startHour: '00',
                      startMin: '00',
                      endHour: '00',
                      endMin: '00',
                    })
                  }
                >
                  <FaPlus className='pr-1' />
                  {t('form_work_time_break')}
                </Button>
              </td>
            </tr>

            {errors && rowHasErrors && submitFailed && <WorkTimeErrors errors={errors} />}

            <FieldArray name='breaks'>
              {({ fields }) => (
                <>
                  {fields.map((workBreak, index) => {
                    const hasBreakError =
                      errors &&
                      submitFailed &&
                      Object.keys(errors).includes('customWorkBreakErrors') &&
                      Object.keys(errors['customWorkBreakErrors']).includes(
                        'break-' + index,
                      );
                    return (
                      <Fragment key={workBreak}>
                        <tr
                          key={workBreak}
                          className={cn({
                            'break-error': hasBreakError,
                          })}
                        >
                          <td>{t('form_work_time_break')}</td>
                          <td>
                            <Row className='m-0'>
                              <Col xs={6} md={4} className='p-0'>
                                <FormSelect
                                  hasEmptyOption={false}
                                  emptyOptionLabel={''}
                                  name={`${workBreak}.startHour`}
                                  options={getHours()}
                                  optionValue='id'
                                  optionDisplay='name'
                                  inputClass='mb-0 time-start'
                                />
                              </Col>
                              <Col xs={6} md={4} className='p-0'>
                                <FormSelect
                                  hasEmptyOption={false}
                                  emptyOptionLabel={''}
                                  name={`${workBreak}.startMin`}
                                  options={getMinutes()}
                                  optionValue='id'
                                  optionDisplay='name'
                                  inputClass='mb-0 time-end'
                                />
                              </Col>
                            </Row>
                          </td>
                          <td>
                            <Row className='m-0'>
                              <Col xs={6} md={4} className='p-0'>
                                <FormSelect
                                  hasEmptyOption={false}
                                  emptyOptionLabel={''}
                                  name={`${workBreak}.endHour`}
                                  options={getHours()}
                                  optionValue='id'
                                  optionDisplay='name'
                                  inputClass='mb-0 time-start'
                                />
                              </Col>
                              <Col xs={6} md={4} className='p-0'>
                                <FormSelect
                                  hasEmptyOption={false}
                                  emptyOptionLabel={''}
                                  name={`${workBreak}.endMin`}
                                  options={getMinutes()}
                                  optionValue='id'
                                  optionDisplay='name'
                                  inputClass='mb-0 time-end'
                                />
                              </Col>
                            </Row>
                          </td>
                          <td className='d-flex justify-content-end'>
                            <Button
                              size='sm'
                              className='btn btn-sm btn-danger centerIcon mx-1 py-2 my-lg-0'
                              onClick={() => fields.remove(index)}
                            >
                              <IoClose />
                            </Button>
                          </td>
                        </tr>
                        {errors && hasBreakError && (
                          <WorkTimeErrors
                            errors={{
                              customBreakErrors:
                                errors.customWorkBreakErrors['break-' + index],
                            }}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </>
              )}
            </FieldArray>
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ModalForm;
