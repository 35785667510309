import {
  IApiLoginEntity,
  TUserLoginAction,
  ILoginData,
  UserLogin,
  UserTokenLogin,
  UserLogOut,
  TUserGetAvatarAction,
  UserGetAvatar,
  LoggedUser,
} from '../types/User';
import { resetStore } from '../types/App';
import { apiCall, loginCall } from './BaseAction';
import { API_END_POINTS } from '../constants/API';
import { getAccessToken, parseUserData } from '../helpers/Misc';
import { TDispatch } from 'types/Thunk';

export const logIn = (username: string, password: string) => {
  return loginCall<TUserLoginAction, ILoginData, IApiLoginEntity>(
    UserLogin,
    'POST',
    API_END_POINTS.LOGIN,
    {
      username,
      password,
    },
  );
};

export const logOut = () => {
  return (dispatch: TDispatch<any>) => {
    dispatch(resetStore());
    localStorage.clear();

    return {
      type: UserLogOut.USER_LOG_OUT,
    };
  };
};

export const tokenLogin = (token: string) => {
  if (!token) return;
  return {
    type: UserTokenLogin.LOGIN_WITH_TOKEN,
    payload: parseUserData(token),
  };
};

export const editUsername = (username: string) => {
  if (!username) return;
  return {
    type: LoggedUser.EDIT_USERNAME,
    payload: username,
  };
};

export const editNameSurname = (firstName: string, lastName: string) => {
  if (!firstName && !lastName) return;
  return {
    type: LoggedUser.EDIT_NAME_SURNAME,
    payload: {
      firstName,
      lastName,
    },
  };
};

export const getUserProfilePicture = () => {
  const token = getAccessToken();
  const client = token ? parseUserData(token) : null;
  const id = client?.userId;
  if (!id) return null;

  return apiCall<TUserGetAvatarAction, null, File>(
    UserGetAvatar,
    'GET',
    API_END_POINTS.EMPLOYEE_AVATAR(id),
    true,
    null,
    null,
    undefined,
    'blob',
  );
};

export const updateUserProfilePicture = (image: File) => {
  return {
    type: UserGetAvatar.SUCCESS,
    payload: {
      data: image,
    },
  };
};
