import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Button, ButtonGroup } from 'react-bootstrap';
import { GrCopy } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import ClipboardJS from 'clipboard';

import './LabelToCopy.scss';

interface ILabelToCopy {
  cellData: string;
}

const LabelToCopy: React.FC<ILabelToCopy> = ({ cellData }) => {
  const [copied, setCopied] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    new ClipboardJS('.btn');
  }, []);

  return (
    <ButtonGroup>
      <Button
        variant='light'
        className='priceId-btn'
        data-tip
        data-for={`id-tooltip-${cellData}`}
      >
        {cellData}
      </Button>
      <Button
        variant='light'
        className='copy-btn btn'
        data-clipboard-text={cellData}
        data-tip
        data-for={`copy-tooltip-${cellData}`}
        onClick={() => {
          setCopied(cellData);
        }}
        onMouseLeave={() => setCopied(undefined)}
      >
        <GrCopy />
      </Button>
      <ReactTooltip
        id={`id-tooltip-${cellData}`}
        place='top'
        effect='solid'
        className='priceId-tooltip'
      >
        {cellData}
      </ReactTooltip>
      <ReactTooltip
        id={`copy-tooltip-${cellData}`}
        place='top'
        effect='solid'
        className='copy-tooltip'
      >
        {copied === cellData ? t('copied') : t('copy_to_clipboard')}
      </ReactTooltip>
    </ButtonGroup>
  );
};

export default LabelToCopy;
