import { AxiosResponse } from 'axios';
import {
  ReservationsGet,
  ReservationAdd,
  ReservationEdit,
  ReservationDelete,
  IReservationData,
  ReservationsPut,
  IReservationDataWithId,
  TReservationsGetAction,
  IReservation,
  TReservationsGetAllAction,
  ReservationsGetAll,
  ReservationStatus,
  TReservationsGetAvailableTimeAction,
  IReservationAvailability,
  ReservationsGetAvailableTime,
  IReservationAvailabilityData,
  CalendarReservationsGetAll,
  TCalendarReservationsGetAllAction,
  IGetUserServicesAvailabilityRequest,
  IGetUserServicesAvailability,
  ReservationsGetAvailableServiceTime,
  TFilteredReservationsGetAction,
  FilteredReservationsGet,
  FilteredReservationsSet,
  IReservationGroupUpdateParams,
  IReservationGroupUpdateData,
} from '../types/Reservations';
import { apiCall, apiClient, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';
import { store } from 'index';
import { matchingReservationFilterFunc } from 'helpers/CalendarUtils';

interface IGetReservations {
  data: Array<IReservation>;
  offset: number;
  clientId?: string;
  status?: string;
  sort?: string;
}

export interface IGetReservationsParams {
  offset?: number;
  clientId?: string;
  status?: string;
  sort?: string;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
  LocationId?: string;
}

interface IGetFilteredReservationsParams extends IGetReservationsParams {
  userId?: string;
}

export const getReservations = ({
  offset,
  clientId,
  status,
  sort,
}: IGetReservationsParams) => {
  return apiCall<TReservationsGetAction, any, IReservation>(
    ReservationsGet,
    'GET',
    API_END_POINTS.GET_RESOURCES,
    true,
    null,
    {
      limit: PAGE_LIMIT,
      offset,
      clientId,
      status,
      Sort: sort,
    },
  );
};

export const getReservationAvailableTime = ({
  LocationId,
  UserId,
  Start,
  End,
  ServicePricingId,
}: IReservationAvailabilityData) => {
  return apiCall<TReservationsGetAvailableTimeAction, null, IReservationAvailability[]>(
    ReservationsGetAvailableTime,
    'GET',
    API_END_POINTS.GET_USER_AVAILABLE_TIME,
    true,
    null,
    {
      LocationId,
      ServicePricingId,
      UserId,
      Start,
      End,
    },
  );
};

export const getUserAvailability = (filters: IGetUserServicesAvailabilityRequest) => {
  return apiCall<
    TReservationsGetAvailableTimeAction,
    null,
    IGetUserServicesAvailability[]
  >(
    ReservationsGetAvailableServiceTime,
    'GET',
    API_END_POINTS.GET_USER_AVAILABLE_SERVICE_TIME,
    true,
    null,
    filters,
  );
};

export const getAllReservations = ({
  offset,
  clientId,
  status,
  sort,
  dateFrom,
  dateTo,
}: IGetReservationsParams = {}) => {
  return apiCall<TReservationsGetAllAction, null, IGetReservations>(
    ReservationsGetAll,
    'GET',
    API_END_POINTS.GET_RESERVATIONS,
    true,
    null,
    {
      limit: 1000,
      offset,
      clientId,
      status,
      Sort: sort,
      DateFrom: dateFrom,
      DateTo: dateTo,
    },
  );
};

export const setFilteredReservations = (payload: IReservation[] | undefined) => ({
  type: FilteredReservationsSet.SET,
  payload: payload,
});

export const getFilteredReservations = ({
  offset,
  clientId,
  status,
  sort,
  dateFrom,
  dateTo,
  userId,
  LocationId,
}: IGetFilteredReservationsParams = {}) => {
  return apiCall<TFilteredReservationsGetAction, null, IGetReservations>(
    FilteredReservationsGet,
    'GET',
    API_END_POINTS.GET_RESERVATIONS,
    true,
    null,
    {
      limit: 1000,
      offset,
      clientId,
      status,
      Sort: sort,
      DateFrom: dateFrom,
      DateTo: dateTo,
      UserId: userId,
      LocationId,
    },
  );
};

export const getAllReservationsByStatus = ({
  offset,
  clientId,
  status,
  limit,
  sort,
}: IGetReservationsParams = {}) => {
  return async (
    dispatch: TDispatch<ReservationsGetAll>,
  ): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ReservationsGetAll.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'GET',
        url: API_END_POINTS.GET_RESERVATIONS,
        data: null,
        params: {
          Limit: limit,
          Offset: offset,
          ClientId: clientId,
          Status: status,
          Sort: sort,
        },
      });
      dispatch({
        type: ReservationsGetAll.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ReservationsGetAll.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getAllReservationsCalendar = ({
  offset,
  clientId,
  status,
  sort,
  dateFrom,
  dateTo,
  LocationId,
}: IGetReservationsParams = {}) => {
  return apiCall<TCalendarReservationsGetAllAction, null, IGetReservations>(
    CalendarReservationsGetAll,
    'GET',
    API_END_POINTS.GET_RESERVATIONS_CALENDAR,
    true,
    null,
    {
      limit: 1000,
      offset,
      clientId,
      status,
      Sort: sort,
      DateFrom: dateFrom,
      DateTo: dateTo,
      LocationId: LocationId,
    },
  );
};

export const changeOffset = (newOffset: number, reservationType: string) => {
  return {
    type: ReservationsPut.RESERVATIONS_OFFSET_UPDATE,
    payload: {
      newOffset,
      reservationType: reservationType.toLowerCase(),
    },
  };
};

export const clearAllReservationsInStore = () => {
  return {
    type: ReservationsPut.RESERVATIONS_CLEAR_DATA,
  };
};

export const deleteReservation = (reservationId: string) => {
  return async (dispatch: TDispatch<ReservationDelete>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ReservationDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.DELETE_RESERVATION(reservationId),
        data: {},
      });
      dispatch({
        type: ReservationDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: ReservationDelete.DELETE_RESERVATION_STORE,
        payload: reservationId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ReservationDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const removeReservationFromStore = (reservationId: string) => {
  return {
    type: ReservationDelete.DELETE_RESERVATION_STORE,
    payload: reservationId,
  };
};

export const addNewReservationToStore = (reservation: IReservationDataWithId) => {
  return {
    type: ReservationAdd.ADD_RESERVATION_STORE,
    payload: reservation,
  };
};

export const addReservation = (reservation: IReservationData) => {
  return async (dispatch: TDispatch<ReservationAdd>): Promise<AxiosResponse<string>> => {
    dispatch({
      type: ReservationAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_RESERVATION,
        data: {
          ...reservation,
          locale: 'en', //TODO: figure out what locale to send
        },
      });

      dispatch({
        type: ReservationAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        if (reservation.isAutoEmployee) {
          const autoReservation: AxiosResponse<IReservationData> =
            await authorisedClient.request({
              method: 'GET',
              url: API_END_POINTS.GET_RESERVATION_BY_ID(response.data),
            });
          dispatch(
            addNewReservationToStore({
              ...autoReservation.data,
              id: response.data,
              status: ReservationStatus.PRELIMINARY,
            }),
          );
          return response;
        }

        dispatch(
          addNewReservationToStore({
            ...reservation,
            id: response.data,
            status: ReservationStatus.PRELIMINARY,
          }),
        );
      }
      return response;
    } catch (error) {
      dispatch({
        type: ReservationAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedReservationToStore = (reservation: IReservationDataWithId) => {
  return {
    type: ReservationEdit.EDIT_RESERVATION_STORE,
    payload: reservation,
  };
};

export const editReservation = (reservation: IReservationData, reservationId: string) => {
  return async (dispatch: TDispatch<ReservationEdit>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ReservationEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_RESERVATION(reservationId),
        data: {
          ...reservation,
          locale: 'en', //TODO: figure out what locale to send
        },
      });
      dispatch({
        type: ReservationEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedReservationToStore({ ...reservation, id: reservationId }));
      return response;
    } catch (error) {
      dispatch({
        type: ReservationEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const updateGroupReservation =
  (
    group: IReservationGroupUpdateParams,
    data: IReservationGroupUpdateData,
    updateLocalState = false,
  ) =>
  async (dispatch: TDispatch<ReservationEdit>): Promise<AxiosResponse<null>> => {
    const response = await apiClient.request({
      method: 'PUT',
      url: API_END_POINTS.UPDATE_RESERVATION_GROUP(
        group.locationId,
        group.userId,
        group.start,
      ),
      data: data,
    });
    if (updateLocalState) {
      const matchingReservations = store
        .getState()
        .reservationsState.calendarData?.filter(r =>
          matchingReservationFilterFunc(r, {
            id: '',
            userId: String(group.userId),
            locationId: group.locationId,
            start: String(group.start),
          }),
        );

      matchingReservations?.forEach(res =>
        dispatch(
          addEditedReservationToStore({
            ...res,
            end: String(data.end),
            duration: data.duration,
            start: String(data.start),
            userId: String(data.userId),
            locationId: group.locationId,
          }),
        ),
      );
    }
    return response;
  };

export const cancelGroupReservation = async (group: IReservationGroupUpdateParams) => {
  const response = await apiClient.request({
    method: 'DELETE',
    url: API_END_POINTS.CANCEL_RESERVATION_GROUP(
      group.locationId,
      group.userId,
      group.start,
    ),
  });
  return response;
};
