import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormErrorText from '../../Form/FormErrorText';
import FormSelect from '../../Form/FormSelect';
import {
  SettingsCurrencies,
  SettingsLocales,
  SettingsTimezones,
} from '../../../types/ReservationSettings';

interface ISettingsCalendarForm {
  submitFailed?: boolean;
  errors?: Record<string, any>;
}

export const getHours = () => {
  const hours = [];
  for (let x = 0; x <= 23; x++) {
    hours.push({ id: x, name: `${x}`.padStart(2, '0') });
  }
  return hours;
};

const languageOptions = [
  { id: SettingsLocales.LT, name: 'lietuvių' },
  { id: SettingsLocales.EN, name: 'english' },
  { id: SettingsLocales.LV, name: 'latviešu' },
  { id: SettingsLocales.RU, name: 'русский' },
  { id: SettingsLocales.PL, name: 'polski' },
  { id: SettingsLocales.CZ, name: 'čeština' },
];

const timeZoneOptions = [
  { id: SettingsTimezones.VILNIUS, name: 'Vilnius, Riga (GMT+2)' },
  { id: SettingsTimezones.WARSAW, name: 'Warsaw, Prague (GMT+1)' },
];

const currencyOptions = [
  { id: SettingsCurrencies.EUR, name: 'EUR' },
  { id: SettingsCurrencies.ZL, name: 'ZL' },
  { id: SettingsCurrencies.CZK, name: 'CZK' },
];

const SettingsCalendarForm: React.FC<ISettingsCalendarForm> = ({
  errors,
  submitFailed,
}) => {
  const { t } = useTranslation();
  const hasIntervalError = errors
    ? Object.keys(errors).includes('invalidInterval')
    : false;

  return (
    <>
      <Row>
        <Col xs={12} className='mb-3'>
          <Form.Label className='d-block'>
            {t('settings_form_label_calendar_hours') + ' *'}
          </Form.Label>
          <div className='d-inline-flex align-items-center'>
            <div>{t('settings_form_label_calendar_hours_form')}</div>
            <FormSelect
              hasEmptyOption={false}
              emptyOptionLabel=''
              name='calendarHoursFrom'
              options={getHours()}
              optionValue='id'
              optionDisplay='name'
              inputClass='mx-1 mb-0'
              size='sm'
            />
          </div>
          <div className='d-inline-flex align-items-center'>
            <div>{t('settings_form_label_calendar_hours_to')}</div>
            <FormSelect
              hasEmptyOption={false}
              emptyOptionLabel=''
              name='calendarHoursTo'
              options={getHours()}
              optionValue='id'
              optionDisplay='name'
              inputClass='mx-1 mb-0'
              size='sm'
            />
          </div>
          {hasIntervalError && submitFailed && (
            <div className='interval-error'>
              <FormErrorText errorText={errors?.invalidInterval} />
            </div>
          )}
        </Col>

        <Col xs={12}>
          <div className='d-inline-flex'>
            <FormSelect
              hasEmptyOption={false}
              label={t('settings_system_locales')}
              emptyOptionLabel=''
              name='locale'
              options={languageOptions}
              optionValue='id'
              optionDisplay='name'
              size='sm'
            />
          </div>
        </Col>

        <Col xs={12}>
          <div className='d-inline-flex'>
            <FormSelect
              hasEmptyOption={false}
              label={t('settings_timezone')}
              emptyOptionLabel=''
              name='calendarTimeZone'
              options={timeZoneOptions}
              optionValue='id'
              optionDisplay='name'
              size='sm'
            />
          </div>
        </Col>
        <Col xs={12}>
          <div className='d-inline-flex'>
            <FormSelect
              hasEmptyOption={false}
              label={t('settings_currency')}
              emptyOptionLabel=''
              name='currency'
              options={currencyOptions}
              optionValue='id'
              optionDisplay='name'
              inputClass='mb-0'
              size='sm'
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SettingsCalendarForm;
