import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import { Button } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-bootstrap/Modal';
import ModalBasicButtons from './ModalBasicButtons';
import { FaRegEdit } from 'react-icons/all';

interface IFormModal {
  show: boolean;
  modalTitle: string;
  handleClose: () => void;
  validation: (values: any) => Record<string, any>;
  handleSubmit: (values: any) => void;
  handleDelete?: () => void;
  editValue?: Record<string, any>;
  form: JSX.Element;
  hideSaveBtn?: boolean;
  showEditBtn?: boolean;
  handleEdit?: () => void;
  modalClass?: string;
}

const FormModal: React.FC<IFormModal> = ({
  show,
  handleClose,
  modalTitle,
  validation,
  handleSubmit,
  editValue,
  form,
  handleDelete,
  hideSaveBtn,
  showEditBtn,
  handleEdit,
  modalClass,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName='modal-lg'
      className={modalClass}
    >
      <Modal.Header closeButton>
        <Modal.Title className='title'>{modalTitle}</Modal.Title>
      </Modal.Header>
      <FinalForm
        onSubmit={handleSubmit}
        initialValues={editValue}
        validate={(values: any) => {
          const errors = validation(values);
          return errors;
        }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {/* Creates modal form component from props */}
              <Modal.Body>{React.cloneElement(form)}</Modal.Body>
              <Modal.Footer
                className={editValue ? 'justify-content-between' : 'justify-content-end'}
              >
                {editValue && (
                  <div className='left-container d-flex'>
                    <Button
                      variant='danger'
                      onClick={handleDelete}
                      disabled={submitting}
                      className='grey-borders centerIcon btn-sm mr-2'
                    >
                      <IoClose />
                      {t('form_button_delete')}
                    </Button>
                    {showEditBtn && (
                      <Button
                        variant='warning'
                        onClick={handleEdit}
                        disabled={submitting}
                        className='grey-borders centerIcon btn-sm mr-2 white'
                      >
                        <FaRegEdit color='#fff' className='mr-1' />
                        {t('edit')}
                      </Button>
                    )}
                  </div>
                )}
                <div>
                  {/* close and save buttons */}
                  <ModalBasicButtons
                    handleClose={handleClose}
                    submitting={submitting}
                    hideSaveBtn={hideSaveBtn}
                  />
                </div>
              </Modal.Footer>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default FormModal;
