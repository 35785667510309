import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from 'pages/Login';
import { ROUTES } from 'constants/Routes';
//superAdmin
import AdminCompanies from 'pages/super_admin/AdminCompanies';
import AdminWorkers from 'pages/super_admin/AdminWorkers';
//admin
import AdminEmployee from 'pages/admin/AdminEmployee';
import AdminLocations from 'pages/admin/AdminLocations';
import AdminResource from 'pages/admin/AdminResource';
import AdminServices from 'pages/admin/AdminServices';
import ExperienceRouter from 'experience/ExperienceRouter';
import PrivateRoute from 'router/PrivateRoute';
import { Roles } from 'constants/Roles';
import AdminClients from 'pages/admin/AdminClients';
import AdminVacations from 'pages/admin/AdminVacations';
import AdminWorkTime from 'pages/admin/AdminWorkTime';
import AdminReservationSettings from 'pages/admin/AdminReservationSettings';
import AdminCalendar from 'pages/admin/AdminCalendar';
import AdminProfile from 'pages/admin/AdminProfile';
import AdminNotifications from 'pages/admin/AdminNotifications';
import CustomerImport from 'pages/admin/CustomerImport';
import AdminReports from 'pages/admin/AdminReports';
import { getAccessToken, parseUserData } from 'helpers/Misc';
import { matchAnyUserRoles } from 'helpers/matchRole';
import Instruction from 'pages/admin/Instruction';
import Approved from 'pages/google/Approved';
import Denied from 'pages/google/Denied';
import Error from 'pages/google/Error';
import Terms from 'pages/Terms';
import Policy from 'pages/Policy';
import Landing from 'pages/Landing';

const MainRouter = () => {
  const token = getAccessToken();
  const user = token ? parseUserData(token) : null;

  const getHomeComponent = () => {
    if (
      user?.roles &&
      matchAnyUserRoles(user?.roles, [Roles.SuperAdmin, Roles.SuperViewer])
    ) {
      return <Redirect to={ROUTES.SUPER_ADMIN.WORKERS} />;
    }
    if (user?.roles && matchAnyUserRoles(user?.roles, [Roles.Admin, Roles.Employee])) {
      return <Redirect to={ROUTES.ADMIN.RESERVATIONS} />;
    }
    if (user?.roles && matchAnyUserRoles(user?.roles, [Roles.Analyst])) {
      return <Redirect to={ROUTES.ADMIN.REPORTS} />;
    }
    return <Landing />;
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={[ROUTES.HOME, '/']} render={() => getHomeComponent()} />
        <PrivateRoute
          exact
          path={[ROUTES.SUPER_ADMIN.WORKERS, ROUTES.HOME]}
          component={AdminWorkers}
          roles={[Roles.SuperAdmin, Roles.SuperViewer]}
        />
        <PrivateRoute
          exact
          path={ROUTES.SUPER_ADMIN.COMPANIES}
          component={AdminCompanies}
          roles={[Roles.SuperAdmin, Roles.SuperViewer]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN.MAIN}
          component={() => <div>TESTAS</div>}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_SETTINGS.USERS}
          component={AdminEmployee}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_SETTINGS.LOCATIONS}
          component={AdminLocations}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_SETTINGS.SERVICES}
          component={AdminServices}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_SETTINGS.RESOURCES}
          component={AdminResource}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_SETTINGS.VACATION_DAYS}
          component={AdminVacations}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN.CLIENTS}
          component={AdminClients}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN.REPORTS}
          component={AdminReports}
          roles={[Roles.Admin, Roles.Analyst]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_SETTINGS.EMPLOYEE_CALENDAR}
          component={AdminWorkTime}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_SETTINGS.RESERVATION_SETTINGS}
          component={AdminReservationSettings}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_SETTINGS.NOTIFICATIONS}
          component={AdminNotifications}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN.RESERVATIONS}
          component={AdminCalendar}
          roles={[Roles.Admin, Roles.Employee]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN.PROFILE}
          component={AdminProfile}
          roles={[Roles.Admin, Roles.Employee]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN_SETTINGS.CLIENT_IMPORT}
          component={CustomerImport}
          roles={[Roles.Admin]}
        />
        <PrivateRoute
          exact
          path={ROUTES.ADMIN.INSTRUCTION}
          component={Instruction}
          roles={[Roles.Admin]}
        />
        <Route path={ROUTES.POLICY} component={Policy} />
        <Route path={ROUTES.TERMS} component={Terms} />
        <Route path={Object.values(ROUTES.EXPERIENCE)} component={ExperienceRouter} />
        <Route path={Object.values(ROUTES.EXPERIENCE)} component={ExperienceRouter} />
        <Route path={ROUTES.LOGIN} component={Login} />
        <Route exact path={[ROUTES.HOME]} component={Landing} />
        <Route exact path={[ROUTES.GOOGLE.APPROVED]} component={Approved} />
        <Route exact path={[ROUTES.GOOGLE.DENIED]} component={Denied} />
        <Route exact path={[ROUTES.GOOGLE.ERROR]} component={Error} />
        <Redirect from='*' to='/' />
      </Switch>
    </BrowserRouter>
  );
};

export default MainRouter;
