import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FullCalendar from '@fullcalendar/react';
import clsx from 'clsx';
import WeekPicker from './WeekPicker';
import { View } from '../../../constants/Interfaces';
import ButtonAdd from '../../../components/Utils/ButtonAdd';
import { IEmployer, IlocationOptions } from '../../../types/Calendar';
import { Roles } from '../../../constants/Roles';
import { matchUserRole } from 'helpers/matchRole';
import './CalendarControls.scss';
import { SettingsLocales } from 'types/ReservationSettings';
import { cs as csLocale } from 'date-fns/locale';

interface ICalendarControls {
  switchToDayView: (arg0: string) => void;
  switchToWeekView: (arg0: Date) => void;
  setSelectedEmpl: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedLocation: React.Dispatch<React.SetStateAction<string | undefined>>;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  activeView: string;
  calendarRef: React.RefObject<FullCalendar>;
  locationOptions: Array<IlocationOptions> | undefined;
  emplOptions: Array<IEmployer> | undefined;
  handleAdd: () => void;
  setDateChanged: React.Dispatch<React.SetStateAction<boolean>>;
  dateChanged: boolean;
  roles?: Array<string>;
  userId?: string;
  selectedEmpl: string | undefined;
  startDate: Date;
  isLoading: boolean;
}

const CalendarControls: React.FC<ICalendarControls> = ({
  switchToDayView,
  switchToWeekView,
  setSelectedEmpl,
  setSelectedLocation,
  setStartDate,
  activeView,
  calendarRef,
  locationOptions,
  emplOptions,
  handleAdd,
  setDateChanged,
  roles,
  selectedEmpl,
  startDate,
  isLoading,
}) => {
  const { t, i18n } = useTranslation();

  const [weekStartDayPicker, setWeekStartDayPicker] = useState<string>(
    moment().weekday(0).toISOString(),
  );
  const [weekEndDayPicker, setWeekEndDayPicker] = useState<string>(
    moment().weekday(6).toISOString(),
  );

  const handleDayChange = (date: Date | [Date, Date] | null) => {
    if (isLoading) return;
    setDateChanged(prev => !prev);
    if (date) {
      setStartDate(date as Date);
      calendarRef?.current?.getApi().gotoDate(date);
    }
  };

  const handleWeekChange = (date: [Date, Date]) => {
    if (isLoading) return;
    setDateChanged(prev => !prev);
    setStartDate(moment(date[0]).toDate());
    if (date && date.length) {
      setWeekStartDayPicker(moment(date[0]).weekday(0).toString());
      setWeekEndDayPicker(moment(date[0]).weekday(6).toString());
      calendarRef?.current?.getApi().gotoDate(date[0]);
    }
  };

  const handleNext = () => {
    if (isLoading) return;
    calendarRef?.current?.getApi().next();
    const nextDay = moment(startDate).add(1, 'days').toDate();
    const nextWeekStart = moment(weekStartDayPicker).add(7, 'days').toString();
    const nextWeekEnd = moment(weekEndDayPicker).add(7, 'days').toString();
    setStartDate(moment(weekStartDayPicker).add(7, 'days').toDate());
    setDateChanged(prev => !prev);
    if (activeView === View.day) {
      setStartDate(nextDay);
    } else {
      setWeekStartDayPicker(nextWeekStart);
      setWeekEndDayPicker(nextWeekEnd);
    }
  };

  const handlePrev = () => {
    if (isLoading) return;
    calendarRef?.current?.getApi().prev();
    const prevDay = moment(startDate).subtract(1, 'days').toDate();
    const prevWeekStart = moment(weekStartDayPicker).subtract(7, 'days').toString();
    const prevWeekEnd = moment(weekEndDayPicker).subtract(7, 'days').toString();
    setStartDate(moment(weekStartDayPicker).subtract(7, 'days').toDate());
    setDateChanged(prev => !prev);
    if (activeView === View.day) {
      setStartDate(prevDay);
    } else {
      setWeekStartDayPicker(prevWeekStart);
      setWeekEndDayPicker(prevWeekEnd);
    }
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLocation(e.target.value);
    if (activeView === View.day && roles && !matchUserRole(roles, [Roles.Employee])) {
      setSelectedEmpl(View.all);
    }
  };

  const handleWeekButtonClick = () => {
    if (activeView === View.week) return;
    switchToWeekView(startDate);
    setWeekStartDayPicker(moment(startDate).weekday(0).toISOString());
    setWeekEndDayPicker(moment(startDate).weekday(6).toISOString());
  };

  const handleDayButtonClick = () => {
    if (activeView === View.day) return;
    switchToDayView(startDate.toString());
  };

  return (
    <>
      <Col
        xs={{ span: 12, order: 1 }}
        md={{ span: 5, order: 2 }}
        lg={4}
        className='justify-content-end subheader-btn'
      >
        {roles && !matchUserRole(roles, [Roles.Employee]) && (
          <ButtonAdd
            text={t('reservation_btn_create')}
            handleClick={handleAdd}
            btnClass='btn-wide'
          />
        )}
      </Col>
      <Col
        className='d-flex'
        xs={{ span: 12, order: 2 }}
        md={{ span: 7, order: 1 }}
        lg={8}
        xl={4}
      >
        <Form.Control
          as='select'
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            handleLocationChange(e);
          }}
        >
          {locationOptions?.map((item: IlocationOptions) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>

        {roles && !matchUserRole(roles, [Roles.Employee]) && (
          <Form.Control
            as='select'
            className='ml-4'
            onChange={e => setSelectedEmpl(e.target.value)}
            value={selectedEmpl}
          >
            {(activeView === View.day || !emplOptions?.length) && (
              <option value='all'>{t('filter_all_employees')}</option>
            )}
            {emplOptions
              ?.sort((a, b) => a.title.localeCompare(b.title))
              ?.map((item: IEmployer) => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
          </Form.Control>
        )}
      </Col>

      <Col
        xs={{ span: 12, order: 4 }}
        md={{ span: 8, order: 3 }}
        lg={8}
        xl={5}
        className='d-flex justify-content-center days-control'
      >
        <ButtonGroup className='btn-wide'>
          <Button
            variant='outline-secondary'
            className='calendar-button'
            onClick={handlePrev}
            disabled={isLoading}
          >
            <FaChevronLeft className='btn-arrow' />
          </Button>
          {activeView === View.day && (
            <>
              <Button
                variant='outline-secondary'
                className='calendar-button btn-active'
                disabled={isLoading}
                onClick={() => {
                  calendarRef?.current?.getApi().today();
                  setStartDate(moment().toDate());
                  setDateChanged(prev => !prev);
                }}
              >
                {t('reservation_calendar_today')}
              </Button>
              <DatePicker
                selected={startDate}
                onChange={date => handleDayChange(date)}
                dateFormat='MMMM d, yyyy'
                className='calendar-picker btn-wide'
                locale={
                  i18n.language.toLowerCase() === SettingsLocales.CZ.toLowerCase()
                    ? csLocale
                    : i18n.language
                }
              />
            </>
          )}
          {activeView === View.week && (
            <WeekPicker
              weekStartDay={weekStartDayPicker}
              weekEndDay={weekEndDayPicker}
              handleWeek={handleWeekChange}
            />
          )}

          <Button
            variant='outline-secondary'
            className='calendar-button'
            disabled={isLoading}
            onClick={handleNext}
          >
            <FaChevronRight className='btn-arrow' />
          </Button>
        </ButtonGroup>
      </Col>
      <Col xs={{ span: 12, order: 3 }} md={{ span: 3, order: 4 }}>
        <div className='d-flex justify-content-end view-control'>
          <Button
            variant='outline-secondary'
            className={clsx(
              'calendar-button btn-wide',
              activeView === View.day && 'btn-active',
            )}
            onClick={handleDayButtonClick}
          >
            {t('reservation_calendar_day_view')}
          </Button>
          <Button
            variant='outline-secondary'
            className={clsx(
              'calendar-button btn-wide ml-1',
              activeView === View.week && 'btn-active',
            )}
            onClick={handleWeekButtonClick}
          >
            {t('reservation_calendar_week_view')}
          </Button>
        </div>
      </Col>
    </>
  );
};

export default CalendarControls;
