import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import './ExperienceFormError.scss';

interface IExperienceFormError {
  errorText?: string;
}

const ExperienceFormError: React.FC<IExperienceFormError> = ({ errorText }) => {
  return (
    <>
      <FaExclamationTriangle className='experience-form-error__triangle' />
      <div className='experience-form-error'>
        <span className='experience-form-error__text'>{errorText}</span>
      </div>
    </>
  );
};

export default ExperienceFormError;
