import { AxiosError } from 'axios';
import { TResetAction } from 'types/App';

export interface IServiceGroupsExperience {
  id: string;
  name: string;
}

export interface IExperienceServiceGroupsState {
  readonly data?: Array<IServiceGroupsExperience>;
  readonly isLoading?: boolean;
}

export const initialExperienceServiceGroupsState: IExperienceServiceGroupsState = {
  data: undefined,
  isLoading: undefined,
};

export interface IServiceGroupsGetExperience {
  data: Array<IServiceGroupsExperience>;
  status: number;
  statusText: string;
}

export enum ServiceGroupsGetExperience {
  REQUEST = 'SERVICE_GROUPS_GET_EXPERIENCE_REQUEST',
  SUCCESS = 'SERVICE_GROUPS_GET_EXPERIENCE_SUCCESS',
  FAILED = 'SERVICE_GROUPS_GET_EXPERIENCE_FAILED',
}

export type TServiceGroupsGetExperienceAction =
  | {
      type: ServiceGroupsGetExperience.REQUEST;
    }
  | {
      type: ServiceGroupsGetExperience.SUCCESS;
      payload: IServiceGroupsGetExperience;
    }
  | {
      type: ServiceGroupsGetExperience.FAILED;
      payload: AxiosError;
    }
  | TResetAction;
