import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form as FinalForm, Field } from 'react-final-form';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormInput from '../../Form/FormInput';
import FormCheckbox from '../../Form/FormCheckbox';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Toast from '../../../components/Utils/Toast';
import { handleError } from '../../../middlewares/ErrorHandler';
import { ICompany, ICompanyData } from '../../../types/Companies';
import { SELECT_VALUES } from '../../../constants/CompanySelects';
import { IFormError } from '../../../constants/Interfaces';
import { addCompany, editCompany, getCompanies } from '../../../actions/CompaniesActions';
import useError from '../../../hooks/useError';
import './CompanyModal.scss';
import { Errors } from 'constants/Errors';
import FormSelect from 'components/Form/FormSelect';
import { COUNTRIES, PERIODS_FOR_RESERVATION } from 'constants/UtilVariables';
import i18n from 'i18next';
import FormErrorText from 'components/Form/FormErrorText';

interface ICompanyModal {
  show: boolean;
  handleClose: () => void;
  company?: ICompany;
  offset?: number;
  searchQuery?: string;
  sortQuery?: string;
}

const CompanyModal: React.FC<ICompanyModal> = ({
  show,
  handleClose,
  company,
  offset,
  searchQuery,
  sortQuery,
}) => {
  const { t } = useTranslation();
  const locale = i18n.language;
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<IFormError>();
  let submit: (event: any) => Promise<any | undefined> | undefined;
  useError(error, setError);

  const onSubmit = async (values: ICompanyData) => {
    if (!values.name) {
      return;
    }
    setSubmitting(true);
    setError(undefined);
    const newCompany = values;
    newCompany.maxReservationTimeInFuture = Number(values.maxReservationTimeInFuture);
    if (!newCompany.smsSender) {
      newCompany.smsSender = null;
    }
    newCompany.locale = locale;
    try {
      if (company) {
        await dispatch(editCompany(newCompany, company.id));
        toast.success(<Toast text={t('editedCompany')} />);
      } else {
        await dispatch(addCompany(newCompany));
        toast.success(<Toast text={t('addedCompany')} />);
        await dispatch(getCompanies(offset || 0, searchQuery || '', sortQuery || ''));
      }
      handleClose();
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        t(
          err.response?.data?.title === Errors.COMPANY_HAS_USER_WITH_MULTI_PERSONS_COUNT
            ? Errors.COMPANY_HAS_USER_WITH_MULTI_PERSONS_COUNT
            : Errors.GENERIC,
        ),
      );
    }
    setSubmitting(false);
  };

  const validateForm = (values: ICompanyData) => {
    const errors: Record<string, string> = {};
    if (!values.name) {
      errors.name = t('required');
    }
    if (!values.country) {
      errors.country = t('required');
    }
    if (!values.activityType) {
      errors.activityType = t('required');
    }
    return errors;
  };

  const initialDaysInFutre = PERIODS_FOR_RESERVATION.find(
    period => period.days === company?.maxReservationTimeInFuture,
  );

  const initialCountry = COUNTRIES.find(
    country => country.country.toLowerCase() === company?.country?.toLowerCase(),
  );

  return (
    <Modal show={show} onHide={handleClose} dialogClassName='modal-lg'>
      <Modal.Header closeButton>
        <Modal.Title className='title'>
          {company ? t('company_update_header') : t('company_create_header')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FinalForm
          onSubmit={onSubmit}
          initialValues={{
            name: company?.name ? company?.name : '',
            activityType: company?.activityType ? company?.activityType : '',
            sendSms: company?.sendSms ? company?.sendSms : false,
            smsSender: company?.smsSender ? company?.smsSender : '',
            reservationGroupsEnabled: company?.reservationGroupsEnabled
              ? company?.reservationGroupsEnabled
              : false,
            sendSmsToEmployee: company?.sendSmsToEmployee || false,
          }}
          validate={validateForm}
          render={({ handleSubmit }) => {
            submit = handleSubmit;
            return (
              <Form onSubmit={handleSubmit}>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <FormInput
                        size='sm'
                        label={`${t('company_form_label_name')} *`}
                        name='name'
                        type='text'
                        fieldError={error}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormSelect
                        hasEmptyOption={initialCountry ? false : true}
                        label={`${t('company_form_label_country')} *`}
                        emptyOptionLabel=''
                        name='country'
                        options={COUNTRIES}
                        optionValue='country'
                        fieldError={error}
                        optionDisplay='country'
                        initialValue={initialCountry?.country}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className='mb-3'>
                      <Field name='activityType'>
                        {({ input, meta }) => (
                          <>
                            <Form.Label>{t('activity_type_form_label_name')}</Form.Label>
                            <Form.Control
                              {...input}
                              size='sm'
                              as='select'
                              isInvalid={meta.error && meta.touched && meta.submitFailed}
                            >
                              <option value=''>-</option>
                              {SELECT_VALUES.map(item => {
                                return (
                                  <option key={item.value} value={item.value}>
                                    {t(item.key)}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            {meta.error && meta.submitFailed && meta.touched && (
                              <FormErrorText errorText={meta.error} />
                            )}
                          </>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormCheckbox
                        name='sendSms'
                        label={t('send_sms_form_label_name')}
                        id='sendSms'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormCheckbox
                        name='sendSmsToEmployee'
                        label={t('send_sms_form_label_name_to_employees')}
                        id='sendSmsToEmployee'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormInput
                        size='sm'
                        label={t('sms_sender_form_label_name')}
                        name='smsSender'
                        type='text'
                        fieldError={error}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormCheckbox
                        name='reservationGroupsEnabled'
                        label={t('enable_groups_reservations')}
                        id='reservationGroupsEnabled'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormSelect
                        hasEmptyOption={false}
                        label={t('max_period_for_reservation')}
                        emptyOptionLabel=''
                        name='maxReservationTimeInFuture'
                        options={PERIODS_FOR_RESERVATION}
                        optionValue='days'
                        optionDisplay='days'
                        initialValue={initialDaysInFutre?.days}
                      />
                    </Col>
                  </Row>
                </Container>
              </Form>
            );
          }}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='light'
          onClick={handleClose}
          disabled={submitting}
          className='grey-borders'
        >
          {t('form_btn_close')}
        </Button>
        <Button
          variant='primary'
          disabled={submitting}
          onClick={event => {
            submit(event);
          }}
        >
          {t('form_btn_save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompanyModal;
