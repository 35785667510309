import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import { Alert, Button, Form, Spinner, Table } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import moment from 'moment';
import { handleError } from 'middlewares/ErrorHandler';
import useError from 'hooks/useError';
import Toast from 'components/Utils/Toast';
import AgreeToDelete from 'components/Utils/Modals/AgreeToDelete';
import { IClient } from 'types/Clients';
import { useStoreState } from 'hooks/useStoreState';
import {
  IReservation,
  IReservationWithInfo,
  ReservationStatus,
  ReservationTypes,
} from 'types/Reservations';
import {
  cancelGroupReservation,
  deleteReservation,
  removeReservationFromStore,
} from 'actions/ReservationsActions';
import { FaEdit, FaRegCopy, FaRegEdit, FaEnvelope } from 'react-icons/all';
import { IFormError } from 'constants/Interfaces';
import { Roles } from 'constants/Roles';
import { formatCurrency } from 'helpers/Currency';
import { getAccessToken, parseUserData } from 'helpers/Misc';
import { matchAllUserRoles } from 'helpers/matchRole';
import './ReservationInfoModal.scss';
import ButtonAdd from 'components/Utils/ButtonAdd';
import { useCallback } from 'react';
import {
  filterSameReservations,
  getPersonCountInReservations,
} from 'helpers/Reservations';
import { formatCalendarEventTitle } from 'helpers/CalendarUtils';

interface IReservationInfoModal {
  show: boolean;
  handleClose: () => void;
  clientToEdit?: IClient;
  reservationToEdit?: IReservationWithInfo;
  handleClientEdit: (clientId?: string) => void;
  handleReservationEdit: (
    reservationToEdit: IReservationWithInfo | undefined,
    editMultiple: boolean,
  ) => void;
  handleReservationCopy: () => void;
  clearReservationsInStore: () => void;
  setReservationToEdit?: (...rest: any[]) => any;
  isMultiReservation: boolean;
  handleMultiReservationCreate?: () => void;
  onClientNameClick?: (clientId: string) => void;
  allowClientEdit?: boolean;
  displayUrgentMessage?: boolean;
  handleUrgentMessage?: () => void;
}

const getResourcesMarkup = (resources: string[]) => {
  return (
    <div className='d-flex flex-wrap'>
      {resources.map(res => (
        <span key={res} className='label mr-1 mb-1'>
          {res}
        </span>
      ))}
    </div>
  );
};

const ReservationInfoModal: React.FC<IReservationInfoModal> = ({
  show,
  handleClose,
  clientToEdit,
  handleClientEdit,
  reservationToEdit,
  clearReservationsInStore,
  handleReservationCopy,
  handleReservationEdit,
  isMultiReservation,
  handleMultiReservationCreate,
  onClientNameClick,
  allowClientEdit = true,
  displayUrgentMessage = false,
  handleUrgentMessage,
}) => {
  const token = getAccessToken();
  const user = token ? parseUserData(token) : null;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [reservationToDelete, setReservationToDelete] = useState<string>();
  useError(error, setError);

  const {
    reservationSettingsState,
    reservationsState: { filteredReservations, isLoading: reservationsLoading },
    employeeState,
    servicesState,
  } = useStoreState();

  const isAdmin = user?.roles && matchAllUserRoles(user?.roles, [Roles.Admin]);
  const isAnonymousClient = !reservationToEdit?.client?.name;

  // should display this reservation as multi-person reservation
  const displayAsMultireservation =
    isMultiReservation && reservationToEdit?.type !== ReservationTypes.BLOCK;

  const sameReservations = useMemo(
    () => filterSameReservations(reservationToEdit, filteredReservations),
    [reservationToEdit, filteredReservations],
  );

  const { totalPersonsCount, areAllAnonymous } = useMemo(
    () => ({
      totalPersonsCount: getPersonCountInReservations(sameReservations),
      areAllAnonymous: sameReservations?.every(reservation => !reservation.client),
    }),
    [sameReservations],
  );

  const employee = useMemo(() => {
    return employeeState.allData?.find(e => e.id === reservationToEdit?.userId);
  }, [employeeState, reservationToEdit]);

  const handleGroupReservationCancel = async () => {
    if (
      !reservationToEdit?.locationId ||
      !reservationToEdit?.userId ||
      !reservationToEdit?.start
    ) {
      return false;
    }

    await cancelGroupReservation({
      locationId: reservationToEdit.locationId,
      userId: reservationToEdit.userId,
      start: reservationToEdit.start,
    });

    (filteredReservations || []).forEach(res => {
      dispatch(removeReservationFromStore(res.id));
    });
  };

  const handleSingleReservationCancel = async (reservationId: string) => {
    try {
      dispatch(deleteReservation(reservationId));
      dispatch(removeReservationFromStore(reservationId));
      toast.success(
        <Toast
          text={t('successfullyDeleted', {
            item: t('reservation').charAt(0).toUpperCase() + t('reservation').slice(1),
          })}
        />,
      );
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }

    if (!filteredReservations?.filter(r => r.id !== reservationId)?.length) {
      // 0 reservations left, close
      handleClose();
    }
  };

  const handleDelete = async () => {
    try {
      if (displayAsMultireservation) {
        if (!handleGroupReservationCancel()) return;

        toast.success(<Toast text={t('multi_user_cancel_success')} />);
      } else if (reservationToDelete) {
        await dispatch(deleteReservation(reservationToDelete));
        toast.success(
          <Toast
            text={t('successfullyDeleted', {
              item: t('reservation').charAt(0).toUpperCase() + t('reservation').slice(1),
            })}
          />,
        );
      }

      clearReservationsInStore();
    } catch (err) {
      handleError(err?.response?.status, setError, t(err.response?.data?.title));
    }
    handleClose();
  };

  const openDeleteDialog = () => {
    if (reservationToEdit) {
      setReservationToDelete(reservationToEdit.id);
    }
  };

  const getReservationPriceTotal = useCallback(() => {
    let calculatedPrice = 0;

    if (filteredReservations?.length) {
      calculatedPrice = 0;

      filteredReservations.forEach(
        // check res.id so we dont add same reservation twice
        res => {
          if (
            res.locationId === reservationToEdit?.locationId &&
            res.userId === reservationToEdit.userId
          ) {
            calculatedPrice += res.price;
          }
        },
      );
    } else {
      calculatedPrice = !reservationToEdit?.price ? 0 : reservationToEdit.price;
    }

    let price = (
      <>
        {`${calculatedPrice}${formatCurrency(reservationSettingsState?.data?.currency)}`}
      </>
    );

    if (!isMultiReservation) {
      if (reservationToEdit?.voucherNumber) {
        price = (
          <>
            {price}
            <Badge pill variant='warning' className='badge-reservation orange'>
              {t('reservation_coupon')}
            </Badge>
          </>
        );
      } else if (
        (reservationToEdit?.status === ReservationStatus.CONFIRMED ||
          reservationToEdit?.status === ReservationStatus.FULFILLED) &&
        reservationToEdit?.fromApi &&
        !reservationToEdit?.isAutoConfirm
      ) {
        price = (
          <>
            {price}
            <Badge pill variant='info' className='badge-reservation'>
              {t('reservation_paid')}
            </Badge>
          </>
        );
      } else if (reservationToEdit?.isAutoConfirm) {
        price = (
          <>
            {price}
            <Badge pill variant='danger' className='badge-reservation'>
              {t('reservation_not_paid')}
            </Badge>
          </>
        );
      }
    }

    return price;
  }, [reservationToEdit, totalPersonsCount, filteredReservations, isMultiReservation]);

  const reservationData = useMemo(() => {
    const { pricings = [] } =
      servicesState.allData?.find(s => s.id === reservationToEdit?.serviceId) || {};

    const { name: servicePricingName } =
      pricings.find(p => p.id === reservationToEdit?.servicePricingId) || {};

    return [
      {
        title: t('reservation_show_label_name'),
        desc: formatCalendarEventTitle(
          reservationToEdit?.serviceName,
          servicePricingName,
        ),
      },
      { title: t('serviceId'), desc: reservationToEdit?.serviceCode },
      {
        title: t('reservation_show_label_date'),
        desc: moment(reservationToEdit?.start).format('YYYY-MM-DD'),
      },
      {
        title: t('reservation_show_label_time'),
        desc: `${moment(reservationToEdit?.start).format('HH:mm')} - ${moment(
          reservationToEdit?.end,
        ).format('HH:mm')}`,
      },
      {
        title: t('reservation_show_label_price'),
        desc: getReservationPriceTotal(),
      },
      reservationToEdit?.voucherNumber
        ? {
            title: t('reservation_form_voucher_label'),
            desc: reservationToEdit?.voucherNumber,
          }
        : {},
      {
        title: t('reservation_show_label_resources'),
        desc: reservationToEdit?.serviceResources?.length
          ? getResourcesMarkup(reservationToEdit.serviceResources)
          : null,
      },
      { title: t('user_role_employee'), desc: reservationToEdit?.employee },
      {
        hideOnMultiReservation: true,
        title: t('reservation_show_label_create'),
        desc: moment(reservationToEdit?.createdAt).format('YYYY-MM-DD HH:mm'),
      },
      {
        hideOnMultiReservation: true,
        title: t('reservation_show_label_reservation_code'),
        desc: reservationToEdit?.code,
      },
      {
        hideOnMultiReservation: true,
        title: t('reservation_show_label_reservation_creator'),
        desc: reservationToEdit?.fromApi
          ? t('reservation_form_label_client')
          : reservationToEdit?.creator,
      },
    ];
  }, [t, reservationToEdit, servicesState, getReservationPriceTotal]);

  const renderReservationData = useMemo(() => {
    return reservationData
      .filter(
        r =>
          (!r.hideOnMultiReservation && displayAsMultireservation) ||
          !displayAsMultireservation,
      )
      .map(({ title, desc }) =>
        desc ? (
          <Row className='m-0 reservation-row' key={title}>
            <Col xs={6} className='px-1 px-sm-3'>
              <div className='title'>{title}</div>
            </Col>
            <Col xs={6} className='px-1 px-sm-3'>
              <div className='desc'>{desc}</div>
            </Col>
          </Row>
        ) : null,
      );
  }, [reservationData, isMultiReservation]);

  const renderReservationStatus = (reservation: IReservation) => {
    if (reservation?.voucherNumber?.length) {
      return (
        <div className='centerized-cell'>
          <Badge pill variant='warning' className='badge-reservation orange ml-0'>
            {t('reservation_coupon')}
          </Badge>
        </div>
      );
    }

    if (
      (reservation?.status === ReservationStatus.CONFIRMED ||
        reservation?.status === ReservationStatus.FULFILLED) &&
      reservation?.fromApi &&
      !reservation.isAutoConfirm
    ) {
      return (
        <div className='centerized-cell'>
          <Badge pill variant='info' className='badge-reservation'>
            {t('reservation_paid')}
          </Badge>
        </div>
      );
    }
    return (
      <div className='centerized-cell'>
        <Badge pill variant='danger' className='badge-reservation'>
          {t('reservation_not_paid')}
        </Badge>
      </div>
    );
  };

  const getMultiReservationPrice = (res: IReservation) => {
    return res.price;
  };

  const renderMultiReservationsHTML = useMemo(() => {
    if (!displayAsMultireservation) return;

    const anyoneHasVoucher = sameReservations?.find(r => r.voucherNumber?.length);

    return (
      <div className='multires-users-table'>
        <Table>
          <thead>
            <tr>
              <th>{t('reservation_form_label_client')}</th>
              <th>{t('reservation_form_label_slots_amount')}</th>
              <th>{t('reservation_show_label_reservation_code')}</th>
              <th>{t('reservation_form_label_sum')}</th>
              <th>
                <div className='centerized-cell'>
                  {t('reservation_form_label_status')}
                </div>
              </th>
              {anyoneHasVoucher && <th>{t('reservation_form_voucher_label_full')}</th>}
              <th />
            </tr>
          </thead>
          <tbody>
            {sameReservations?.map(res => (
              <tr key={res.id}>
                <td className='first-cell'>
                  <div className='d-flex align-items-center'>
                    {res.client?.name && res.clientId && (
                      <FaEdit
                        onClick={() => res.clientId && handleClientEdit(res.clientId)}
                        className='mr-1 cursor-pointer edit-icon'
                      />
                    )}
                    <span
                      onClick={() => res.clientId && handleClientNameClick(res.clientId)}
                      className='cursor-pointer break-word'
                    >
                      {res.client?.name
                        ? `${res.client?.name} ${res.client?.lastname || ''}`
                        : t('reservation_client_incognito')}
                    </span>
                  </div>
                </td>
                <td>
                  {res.personsCount} {t('person_short')}
                </td>
                <td>{res.code}</td>
                <td>
                  {getMultiReservationPrice(res)}
                  {formatCurrency(reservationSettingsState?.data?.currency)}
                </td>
                <td className={!anyoneHasVoucher ? 'prelast-cell' : ''}>
                  {renderReservationStatus(res)}
                </td>
                {res.voucherNumber?.length ? (
                  <td className='prelast-cell'>{res.voucherNumber || ''}</td>
                ) : anyoneHasVoucher ? (
                  <td className='prelast-cell'>&nbsp;</td>
                ) : null}
                <td className='last-cell'>
                  <Button
                    variant='light'
                    disabled={res.status === ReservationStatus.TEMPORARY}
                    className='grey-borders btn-sm d-flex align-items-center'
                    onClick={() => handleSingleReservationCancel(res.id)}
                  >
                    <FaEdit className='mr-1' />
                    {t('form_btn_cancel')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }, [filteredReservations, isMultiReservation]);

  const handleClientNameClick = (clientId: string) => {
    if (!allowClientEdit) return;
    onClientNameClick?.(clientId);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='modal-xl'
        className='reservation-info-modal'
      >
        <Modal.Header closeButton>
          {!displayAsMultireservation && (
            <Modal.Title className='title'>
              <div className='d-flex align-items-center wrap'>
                <div className='reservation-userinfo'>
                  <div
                    className='cursor-pointer'
                    onClick={() =>
                      reservationToEdit?.clientId &&
                      handleClientNameClick(reservationToEdit?.clientId)
                    }
                  >
                    {`${
                      !isAnonymousClient
                        ? `${reservationToEdit?.client?.name || ''} ${
                            reservationToEdit?.client?.lastname || ''
                          }`
                        : t('reservation_client_incognito')
                    }`}
                  </div>
                  <div className='client-info'>
                    {reservationToEdit?.client?.phone || ''}{' '}
                    {reservationToEdit?.client?.email || ''}
                  </div>
                </div>
                {isAdmin && !isAnonymousClient && (
                  <Button
                    variant='light'
                    size='sm'
                    onClick={() =>
                      allowClientEdit && handleClientEdit(clientToEdit?.id || undefined)
                    }
                    className='grey-borders centerIcon ml-sm-3'
                  >
                    <FaRegEdit color='inherit' size={14} className='mr-1' />
                    {t('form_btn_client_edit')}
                  </Button>
                )}
              </div>

              {reservationToEdit?.client?.comments && (
                <Alert variant='warning' className='client-comment'>
                  <Form.Text as='span' className='client-comment__text word-break'>
                    {`${t('reservation_client_comments')} ${
                      reservationToEdit?.client?.comments
                    }`}
                  </Form.Text>
                </Alert>
              )}
            </Modal.Title>
          )}
        </Modal.Header>

        <Modal.Body>
          <Container fluid className='px-1 px-sm-2'>
            {renderReservationData}
            <Row className='m-0'>
              <Col sm={12} className='px-1 px-sm-3'>
                <div className='about mt-4'>
                  {reservationToEdit?.userComments && (
                    <Alert variant='warning' className='word-break'>
                      {t('reservation_comments')} {reservationToEdit?.userComments}
                    </Alert>
                  )}
                </div>
              </Col>
            </Row>
            {displayAsMultireservation && (
              <Row className='m-0'>
                <Col sm={12}>
                  <div className='d-flex justify-content-between'>
                    <h5 className='busyness'>
                      {t('label_busyness')} {totalPersonsCount} /{' '}
                      {Math.max(Number(employee?.maxPersonsCount), totalPersonsCount)}
                    </h5>
                    {/* Only displayed if reservation is multipersonal */}
                    <ButtonAdd
                      text={t('reservation_btn_create')}
                      handleClick={() => handleMultiReservationCreate?.()}
                      icon={
                        reservationsLoading && (
                          <Spinner animation='border' className='mr-1' size='sm' />
                        )
                      }
                    />
                  </div>
                </Col>
                <Col sm={12}>{renderMultiReservationsHTML}</Col>
              </Row>
            )}
            {/* End */}
          </Container>
        </Modal.Body>
        <Modal.Footer
          className={clsx(
            'reservation-footer',
            isAdmin ? 'justify-content-between' : 'justify-content-end',
          )}
        >
          {isAdmin && (
            <div className='d-flex wrap flex-column flex-sm-row'>
              <Button
                size='sm'
                disabled={reservationToEdit?.status === ReservationStatus.TEMPORARY}
                className='grey-borders centerIcon ml-1 mb-2 btn-sm btn-danger order-3 order-sm-0'
                onClick={openDeleteDialog}
              >
                <IoClose />
                {t(
                  displayAsMultireservation
                    ? 'reservation_multiple_cancel_alert_confirm_btn'
                    : 'reservation_cancel_alert_confirm_btn',
                )}
              </Button>
              <Button
                size='sm'
                variant='light'
                disabled={
                  reservationToEdit?.status === ReservationStatus.CANCELLED ||
                  reservationToEdit?.status === ReservationStatus.TEMPORARY
                }
                onClick={() =>
                  handleReservationEdit(reservationToEdit, displayAsMultireservation)
                }
                className='grey-borders centerIcon ml-1 mb-2 btn-sm'
              >
                <FaRegEdit color='inherit' size={14} className='mr-1' />
                {t(
                  displayAsMultireservation
                    ? 'form_btn_reservation_multiple_edit'
                    : 'form_btn_reservation_edit',
                )}
              </Button>

              {!displayAsMultireservation && (
                <Button
                  variant='light'
                  size='sm'
                  onClick={handleReservationCopy}
                  className='grey-borders centerIcon ml-1 mb-2 btn-sm'
                >
                  <FaRegCopy color='inherit' size={14} className='mr-1' />
                  {t('form_btn_reservation_copy')}
                </Button>
              )}
              {displayUrgentMessage && (
                <Button
                  size='sm'
                  variant='light'
                  onClick={handleUrgentMessage}
                  disabled={areAllAnonymous}
                  className='grey-borders centerIcon ml-1 mb-2 btn-sm'
                >
                  <FaEnvelope color='inherit' size={14} className='mr-1' />
                  {t(
                    displayAsMultireservation
                      ? 'form_btn_reservation_urgent_message_multiple'
                      : 'form_btn_reservation_urgent_message_single',
                  )}
                </Button>
              )}
            </div>
          )}
          <div className='reservation-footer__right'>
            <Button
              variant='light'
              onClick={handleClose}
              className='grey-borders mb-2 btn-sm'
            >
              {t('form_btn_close')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AgreeToDelete
        show={!!reservationToDelete}
        handleClose={() => setReservationToDelete(undefined)}
        handleAccept={handleDelete}
        customText={
          displayAsMultireservation ? t('confirm_delete_multireservation') : undefined
        }
      />
    </>
  );
};

export default ReservationInfoModal;
