import { AxiosResponse } from 'axios';
import {
  EmployeesGet,
  EmployeeAdd,
  EmployeeEdit,
  EmployeesGetAll,
  EmployeeDelete,
  TEmployeesGetAction,
  TEmployeeGetAllAction,
  IEmployee,
  IEmployeeData,
  EmployeesPut,
  IGetEmployees,
  EmployeesGetAvatar,
  EmployeeEditAvatar,
  IGetEmployeeStatusData,
  EmployeeSendStatusEmail,
} from '../types/Employees';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';
import { IReservation } from 'types/Reservations';

export const getAllEmployees = () => {
  return apiCall<TEmployeeGetAllAction, null, IGetEmployees>(
    EmployeesGetAll,
    'GET',
    API_END_POINTS.GET_EMPLOYEES,
    true,
    null,
    {
      limit: 1000,
      Sort: 'name asc',
    },
  );
};

export const getEmployees = (
  offset: number,
  searchQuery?: string,
  selectedCompany?: string,
  sort?: string,
  limit?: number,
) => {
  return apiCall<TEmployeesGetAction, any, IEmployee>(
    EmployeesGet,
    'GET',
    API_END_POINTS.GET_EMPLOYEES,
    true,
    {},
    {
      limit: limit || PAGE_LIMIT,
      offset,
      query: searchQuery,
      Sort: sort || 'name asc',
      CompanyId: selectedCompany,
    },
  );
};

export const getEmployeeProfilePicture = (employeeId: string) => {
  return async (
    dispatch: TDispatch<EmployeesGetAvatar>,
  ): Promise<AxiosResponse<string>> => {
    dispatch({
      type: EmployeesGetAvatar.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'GET',
        url: API_END_POINTS.EMPLOYEE_AVATAR(employeeId),
        data: {},
        responseType: 'blob',
      });
      dispatch({
        type: EmployeesGetAvatar.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: EmployeesGetAvatar.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const setEmployeeProfilePicture = (employeeId: string, file: File) => {
  return async (
    dispatch: TDispatch<EmployeeEditAvatar>,
  ): Promise<AxiosResponse<null>> => {
    dispatch({
      type: EmployeeEditAvatar.REQUEST,
    });
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EMPLOYEE_AVATAR(employeeId),
        data: formData,
      });
      dispatch({
        type: EmployeeEditAvatar.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: EmployeeEditAvatar.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
export const changeOffset = (newOffset: number) => {
  return {
    type: EmployeesPut.EPLOYEES_OFFSET_UPDATE,
    payload: newOffset,
  };
};

export const deleteEmployee = (employeeId: string) => {
  return async (dispatch: TDispatch<EmployeeDelete>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: EmployeeDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'DELETE',
        url: API_END_POINTS.DELETE_EMPLOYEE(employeeId),
        data: {},
      });
      dispatch({
        type: EmployeeDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: EmployeeDelete.DELETE_COMPANY_STORE,
        payload: employeeId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: EmployeeDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addNewEmployeeToStore = (employeeId: string, employee: IEmployeeData) => {
  return {
    type: EmployeeAdd.ADD_EMPLOYEE_STORE,
    payload: {
      ...employee,
      password: null,
      passwordRepeat: null,
      id: employeeId,
      serviceIds: employee.serviceIds,
      locationIds: employee.locationIds,
      companyId: employee.company,
    },
  };
};

export const addEmployee = (employee: IEmployeeData) => {
  return async (dispatch: TDispatch<EmployeeAdd>): Promise<AxiosResponse<string>> => {
    dispatch({
      type: EmployeeAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_EMPLOYEE,
        data: {
          username: employee.username,
          email: employee.email,
          roles: employee.roles,
          companyId: employee.company,
          password: employee.password,
          name: employee.name,
          lastName: employee.lastName,
          phoneNumber: employee.phoneNumber,
          locale: 'en', //TODO: figure out what locale to send
          locationIds: employee.locationIds,
          serviceIds: employee.serviceIds,
          maxPersonsCount: employee.maxPersonsCount,
        },
      });
      dispatch({
        type: EmployeeAdd.SUCCESS,
        payload: response,
      });

      if (response.data) {
        dispatch(addNewEmployeeToStore(response.data, employee));
      }
      return response;
    } catch (error) {
      dispatch({
        type: EmployeeAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedEmployeeToStore = (employee: IEmployeeData, employeeId: string) => {
  return {
    type: EmployeeEdit.EDIT_EMPLOYEE_STORE,
    payload: {
      ...employee,
      password: null,
      passwordRepeat: null,
      id: employeeId,
      serviceIds: employee.serviceIds,
      locationIds: employee.locationIds,
    },
  };
};

export const editedEmployee = (employee: IEmployeeData, employeeId: string) => {
  return async (dispatch: TDispatch<EmployeeEdit>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: EmployeeEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_EMPLOYEE(employeeId),
        data: {
          username: employee.username,
          email: employee.email,
          roles: employee.roles,
          companyId: employee.company,
          password: employee.password,
          name: employee.name,
          lastName: employee.lastName,
          serviceIds: employee.serviceIds,
          locationIds: employee.locationIds,
          phoneNumber: employee.phoneNumber,
          locale: 'en', //TODO: figure out what locale to send
          maxPersonsCount: employee.maxPersonsCount,
        },
      });
      dispatch({
        type: EmployeeEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedEmployeeToStore(employee, employeeId));
      return response;
    } catch (error) {
      dispatch({
        type: EmployeeEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getEmployeeReservations = async (employeeId: string) => {
  const response = await authorisedClient.request<AxiosResponse<IReservation[]>>({
    method: 'GET',
    url: API_END_POINTS.GET_RESERVATIONS,
    params: {
      UserId: employeeId,
      limit: 1000,
    },
  });
  return response;
};

export const sendEmployeeEmail = (employeeId: string) => {
  return async (
    dispatch: TDispatch<EmployeeSendStatusEmail>,
  ): Promise<AxiosResponse<null>> => {
    dispatch({
      type: EmployeeSendStatusEmail.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.SEND_EMPLOYEE(employeeId),
      });
      dispatch({
        type: EmployeeSendStatusEmail.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: EmployeeSendStatusEmail.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const sendEmployeeEmailRevoke = (employeeId: string) => {
  return async (
    dispatch: TDispatch<EmployeeSendStatusEmail>,
  ): Promise<AxiosResponse<null>> => {
    dispatch({
      type: EmployeeSendStatusEmail.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.SEND_EMPLOYEE_REVOKE(employeeId),
      });
      dispatch({
        type: EmployeeSendStatusEmail.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: EmployeeSendStatusEmail.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getEmployeeStatus = (employeeId: string) => {
  return async (
    dispatch: TDispatch<EmployeeEdit>,
  ): Promise<AxiosResponse<IGetEmployeeStatusData>> => {
    try {
      const response = await authorisedClient.request<IGetEmployeeStatusData>({
        method: 'GET',
        url: API_END_POINTS.GET_STATUS_EMPLOYEE(employeeId),
      });
      return response;
    } catch (error) {
      dispatch({
        type: EmployeeEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
