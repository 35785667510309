import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import Toast from '../../Utils/Toast';
import { TUserState } from '../../../types/User';
import { IAppState } from '../../../store/Store';
import ModalForm from './ModalForm';
import FormModal from '../../Utils/Modals/FormModal';
import AgreeToDelete from '../../Utils/Modals/AgreeToDelete';
import {
  addLocation,
  deleteLocation,
  editLocation,
} from '../../../actions/LocationActions';
import { ILocation, ILocationData } from '../../../types/Locations';
import { IFormError } from '../../../constants/Interfaces';

interface IAddEditLocationModal {
  show: boolean;
  handleClose: () => void;
  selectedLocation?: ILocation;
}

const AddEditLocationModal: React.FC<IAddEditLocationModal> = ({
  show,
  handleClose,
  selectedLocation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [locationToDelete, setLocationToDelete] = useState<string>();
  useError(error, setError);

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  const formValidation = (values: ILocationData) => {
    const error: Record<string, any> = {};
    if (!values.name) {
      error.name = t('required');
    }
    if (!values.phone) {
      error.phone = t('required');
    }
    if (!values.address) {
      error.address = t('required');
    }
    if (!values.city) {
      error.city = t('required');
    }
    return error;
  };

  const handleSubmit = async (values: ILocationData) => {
    setError(undefined);
    const locationObj = { ...values };
    if (userState.data?.companyId) {
      locationObj.companyId = userState.data?.companyId;
    }
    try {
      if (selectedLocation) {
        await dispatch(editLocation(locationObj, selectedLocation.id));
        toast.success(<Toast text={t('editedLocation')} />);
      } else {
        await dispatch(addLocation(locationObj));
        toast.success(<Toast text={t('addedLocation')} />);
        handleClose();
      }
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };

  const handleDelete = async () => {
    if (locationToDelete) {
      try {
        await dispatch(deleteLocation(locationToDelete));
        toast.success(<Toast text={t('successfullyDeleted', { item: t('location') })} />);
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    }
    handleClose();
  };

  const openDeleteDialog = () => {
    if (selectedLocation) {
      setLocationToDelete(selectedLocation.id);
    }
  };

  return (
    <>
      <FormModal
        show={show}
        handleClose={handleClose}
        modalTitle={
          selectedLocation ? t('location_update_header') : t('location_create_header')
        }
        validation={formValidation}
        handleSubmit={handleSubmit}
        form={<ModalForm fieldError={error} />}
        editValue={selectedLocation}
        handleDelete={openDeleteDialog}
      />
      <AgreeToDelete
        show={!!locationToDelete}
        handleClose={() => setLocationToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </>
  );
};

export default AddEditLocationModal;
