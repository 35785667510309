import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import Toast from '../../Utils/Toast';
import { TUserState } from '../../../types/User';
import { IAppState } from '../../../store/Store';
import ModalForm from './ModalForm';
import FormModal from '../../Utils/Modals/FormModal';
import AgreeToDelete from '../../Utils/Modals/AgreeToDelete';
import {
  addClient,
  deleteClient,
  editClient,
  getClientById,
} from '../../../actions/ClientsActions';
import { IClient, IClientData } from '../../../types/Clients';
import { IFormError } from '../../../constants/Interfaces';
import { IReservationWithInfo } from '../../../types/Reservations';

interface IAddEditClientModal {
  show: boolean;
  handleClose: () => void;
  clientToEdit?: IClient;
  reservationToEdit?: IReservationWithInfo;
  setSelectedClient: (data: any) => void;
  setReservationToEdit?: React.Dispatch<
    React.SetStateAction<IReservationWithInfo | undefined>
  >;
  onClientEdit?: (clientId: string) => void;
  setClientToEdit?: React.Dispatch<React.SetStateAction<IClient | undefined>>;
}

export type ClientPhoneFieldStates = 'valid' | 'invalid' | 'empty';

const AddEditClientModal: React.FC<IAddEditClientModal> = ({
  show,
  handleClose,
  clientToEdit,
  reservationToEdit,
  setReservationToEdit,
  setSelectedClient,
  onClientEdit,
  setClientToEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [phoneFieldState, setPhoneFieldState] = useState<ClientPhoneFieldStates>('empty');
  const [clientToDelete, setClientToDelete] = useState<string>();
  useError(error, setError);
  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  const formValidation = (values: IClientData) => {
    const error: Record<string, any> = {};
    if (!values.name) {
      error.name = t('required');
    }

    if (!values.notification) {
      error.notification = t('required');
    }

    if (!values.date && (values.month || values.year)) {
      if (!values.month) {
        error.month = t('dateInvalid');
      }
      if (!values.year) {
        error.year = t('dateInvalid');
      }
      error.date = t('dateInvalid');
    }

    if (!values.month && (values.date || values.year)) {
      if (!values.date) {
        error.date = t('dateInvalid');
      }
      if (!values.year) {
        error.year = t('dateInvalid');
      }
      error.month = t('dateInvalid');
    }

    if (!values.year && (values.date || values.month)) {
      if (!values.date) {
        error.date = t('dateInvalid');
      }
      if (!values.month) {
        error.month = t('dateInvalid');
      }
      error.year = t('dateInvalid');
    }

    if (phoneFieldState === 'invalid') {
      error.phone = t('validators_wrong_phone_format');
    }

    return error;
  };

  const getFormattedPhone = (phone: string) => {
    const phoneRegex = /[^+0-9]+/g; // remove all whitespace and hyphens
    return phone.replaceAll(phoneRegex, '');
  };

  const handleSubmit = async (values: IClientData) => {
    const {
      name,
      lastname,
      email,
      phone,
      address,
      birthDate,
      gender,
      notification,
      comments,
      year,
      date,
      month,
    } = values;

    const client = {
      name,
      lastname,
      email,
      phone: phone ? getFormattedPhone(phone) : phone,
      address,
      birthDate: year || date || month ? [year, month, date].join('-') : birthDate,
      gender,
      notification,
      comments,
      companyId: userState.data?.companyId || '',
    };
    try {
      let newClientId = undefined;
      if (clientToEdit) {
        await dispatch(editClient(client, clientToEdit.id));
        toast.success(<Toast text={t('editedClient')} />);
        newClientId = clientToEdit.id;
        onClientEdit?.(clientToEdit.id);
        const updatedClient = (await getClientById(newClientId)).data;
        if (updatedClient) setClientToEdit?.(updatedClient);
      } else {
        toast.success(<Toast text={t('addedClient')} />);
        newClientId = ((await dispatch(addClient(client))) as any)?.data;
        handleClose();
      }

      if (reservationToEdit) {
        setReservationToEdit?.({
          ...reservationToEdit,
          client: {
            name: client.name,
            lastname: client.lastname || reservationToEdit?.client?.lastname || '',
            phone: client.phone || reservationToEdit?.client?.phone || '',
            comments: client.comments,
            email: client.email || reservationToEdit?.client?.email || '',
          },
          clientId: newClientId || '',
        });
        setSelectedClient({
          name: client.name,
          lastname: client.lastname,
          phone: client.phone,
          comments: client.comments,
        });
      }
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const handleDelete = async () => {
    if (clientToDelete) {
      try {
        await dispatch(deleteClient(clientToDelete));
        toast.success(<Toast text={t('successfullyDeleted', { item: t('client') })} />);
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    }
    handleClose();
  };

  const openDeleteDialog = () => {
    if (clientToEdit) {
      setClientToDelete(clientToEdit.id);
    }
  };

  const getDateParams = () => {
    const dateArray = clientToEdit?.birthDate?.slice(0, 10).split('-') || [];
    if (dateArray) {
      return {
        year: dateArray[0],
        month: dateArray[1],
        date: dateArray[2],
      };
    }
  };

  const dateParams = getDateParams();

  return (
    <>
      <FormModal
        show={show}
        handleClose={handleClose}
        modalTitle={
          clientToEdit
            ? `${clientToEdit?.name || ''} ${clientToEdit?.lastname || ''}`
            : t('client_create_page_title')
        }
        validation={formValidation}
        handleSubmit={handleSubmit}
        form={<ModalForm setPhoneFieldState={setPhoneFieldState} />}
        editValue={clientToEdit ? { ...clientToEdit, ...dateParams } : undefined}
        handleDelete={openDeleteDialog}
      />
      <AgreeToDelete
        show={!!clientToDelete}
        handleClose={() => setClientToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </>
  );
};

export default AddEditClientModal;
