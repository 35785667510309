import React from 'react';
import { EventContentArg } from '@fullcalendar/react';
import { useStoreState } from 'hooks/useStoreState';
import { useTranslation } from 'react-i18next';
import './EventCard.scss';

interface IProps {
  eventInfo: any;
  tooltipId: string;
  isBlockTime: boolean;
  eventContent: EventContentArg;
}

enum CalendarIntervals {
  Min15 = 15,
  Min30 = 30,
  Min60 = 60,
}

export const EventCard: React.FC<IProps> = ({
  isBlockTime,
  tooltipId,
  eventInfo,
  eventContent,
}) => {
  const { t } = useTranslation();

  const clientName = !eventInfo.clientName.trim()
    ? t('reservation_client_incognito')
    : eventInfo.clientName;

  const reservationSettingsState = useStoreState().reservationSettingsState;
  const { totalPersonsCount, employeeMaxPersons } = eventInfo;

  const getMinTimeToChangeNameStyles = () => {
    const calendarInterval =
      reservationSettingsState?.data?.webReservationCalendarInterval;
    let minutes;
    if (calendarInterval && calendarInterval === CalendarIntervals.Min60) {
      minutes = 70;
    } else if (calendarInterval && calendarInterval === CalendarIntervals.Min30) {
      minutes = 30;
    } else {
      minutes = 20;
    }
    return minutes;
  };

  const clientNameHTML = () => {
    if (employeeMaxPersons > 1 && !isBlockTime) return null;

    return (
      <div
        style={{
          fontWeight: isBlockTime ? 400 : 700,
          marginRight: 5,
        }}
      >
        {` ${isBlockTime ? t('reservation_blocked_time') : clientName}`}
      </div>
    );
  };

  const peopleCountHTML = () => {
    if (employeeMaxPersons <= 1 || isBlockTime) return null;

    return (
      <div>
        {t('label_busyness')}: {Number(totalPersonsCount)}/
        {Math.max(Number(employeeMaxPersons), Number(totalPersonsCount))}
      </div>
    );
  };

  const serviceNameHTML = () => {
    return <div>{eventInfo.serviceName}</div>;
  };

  const reservationTimeHTML = () => {
    return (
      <div style={eventInfo.duration < 15 ? { marginRight: 5 } : {}}>
        {`${eventContent.timeText} `}
      </div>
    );
  };

  return (
    <div
      data-for={tooltipId}
      data-tip={JSON.stringify(eventInfo)}
      className='event-content'
    >
      <div
        className={
          eventInfo.duration < 15 ? 'event-content-flow event-content-flow--column' : ''
        }
      >
        <div
          className={
            eventInfo.duration < getMinTimeToChangeNameStyles()
              ? 'event-content-flow event-content-flow--row'
              : 'event-content-flow event-content-flow--column'
          }
        >
          {reservationTimeHTML()}
          {clientNameHTML()}
          {serviceNameHTML()}
        </div>

        {peopleCountHTML()}
      </div>
    </div>
  );
};
