import React, { useEffect } from 'react';

import { Header } from 'components/Landing/Header';
import './Landing.scss';
import Hero from 'landing/sections/Hero/Hero';
import WhyPlus from 'landing/sections/WhyPlus/WhyPlus';
import Footer from 'components/Landing/Footer';
import { useTranslation } from 'react-i18next';
import { EXPERIENCE_LANG_ITEM } from 'constants/Storage';

const Landing = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    const lang = localStorage.getItem(EXPERIENCE_LANG_ITEM);
    if (!lang) {
      localStorage.setItem(EXPERIENCE_LANG_ITEM, 'lt');
      i18n.changeLanguage('lt');
      return;
    }
    i18n.changeLanguage(lang);
  }, []);
  return (
    <div className='d-flex flex-column h-100'>
      <Header />
      <main>
        <Hero />
        <WhyPlus />
      </main>
      <Footer />
    </div>
  );
};

export default Landing;
