import { apiCall } from 'actions/BaseAction';
import { API_END_POINTS } from 'constants/API';
import {
  IUserGetLocation,
  TUserGetLocationAction,
  UserGetLocation,
} from 'experience/types/ExperienceUserLocation';

export const getUserLocation = () => {
  return apiCall<TUserGetLocationAction, any, IUserGetLocation>(
    UserGetLocation,
    'GET',
    API_END_POINTS.GET_USER_LOCATION,
    false,
    {},
    {},
  );
};
