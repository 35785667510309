import React, { memo } from 'react';
import './WhyPlus.scss';
import { useTranslation } from 'react-i18next';
import Plus from 'assets/images/plus.png';
const WhyPlus = () => {
  const { t } = useTranslation();
  const reasons = t('reasons', { returnObjects: true }) as Array<{
    title: string;
    description: string;
  }>;
  return (
    <section className='landing__why-plus'>
      <div className='landing__container'>
        <h1 className='landing__why-plus__title'>{t('why_use_plus')}</h1>
        <div className='landing__why-plus__reasons-wrapper d-flex'>
          {reasons.map((reason, index) => (
            <div className='d-flex align-items-start' key={`${reason.title}_${index}`}>
              <img className='landing__why-plus__icon' src={Plus} alt={reason.title} />
              <div key={index} className='landing__why-plus__reason'>
                <h3>{reason.title}</h3>
                <p>{reason.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default memo(WhyPlus);
