import { apiCall } from 'actions/BaseAction';
import { API_END_POINTS } from 'constants/API';
import {
  EmployeesGetExperience,
  IEmployeesGetExperience,
  TEmployeesGetExperienceAction,
} from 'experience/types/ExperienceEmployees';

export const getEmployeesExperience = (
  accessKey: string,
  LocationId: string,
  ServicePricingId: string,
) => {
  return apiCall<TEmployeesGetExperienceAction, any, IEmployeesGetExperience>(
    EmployeesGetExperience,
    'GET',
    API_END_POINTS.GET_EMPLOYEES_EXPERIENCE,
    false,
    {},
    { LocationId, ServicePricingId },
    accessKey,
  );
};
