export interface IApp {
  loading: boolean;
  loaded: boolean;
  error: boolean | [];
}

export enum ResetStore {
  RESET = 'STORE_RESET',
}

export type TResetAction = {
  type: ResetStore.RESET;
};

export const resetStore = () => {
  return {
    type: ResetStore.RESET,
  };
};
