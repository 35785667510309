/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

//Client read modal in docs
export interface IClient {
  id: string;
  companyId?: string;
  name: string;
  lastname?: string;
  email?: string;
  phone?: string;
  address?: string;
  birthDate: string;
  gender?: 'Male' | 'Female';
  notification: 'Email' | 'NotSend';
  comments?: string;
  creatorId?: string;
  createdAt?: string;
  updatedAt?: string;
  locale?: string;
}

export interface IClientData {
  companyId?: string;
  name: string;
  lastname?: string;
  email?: string;
  phone?: string;
  address?: string;
  birthDate?: string;
  gender?: 'Male' | 'Female';
  notification: 'Email' | 'NotSend';
  comments?: string;
  locale?: string;
  date?: string;
  month?: string;
  year?: string;
}

export interface IClientDataWithId extends IClientData {
  id: string;
}

export enum ClientIdStatuses {
  Anonymous = 'anonymous',
}

export interface IGetClients {
  data: Array<IClient>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

export interface TClientState {
  readonly data?: Array<IClient>;
  readonly allData?: Array<IClient>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
  readonly isLoadingFileImport?: boolean;
}

export const initialClientState: TClientState = {
  data: undefined,
  allData: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
  isLoadingFileImport: false,
};

export enum ClientAdd {
  REQUEST = 'CLIENT_ADD_REQUEST',
  SUCCESS = 'CLIENT_ADD_SUCCESS',
  FAILED = 'CLIENT_ADD_FAILED',
  ADD_CLIENT_STORE = 'CLIENT_ADD_TO_STORE',
}

export enum ClientEdit {
  REQUEST = 'CLIENT_EDIT_REQUEST',
  SUCCESS = 'CLIENT_EDIT_SUCCESS',
  FAILED = 'CLIENT_EDIT_FAILED',
  EDIT_CLIENT_STORE = 'CLIENT_EDIT_ADD_TO_STORE',
}

export enum ClientsImport {
  REQUEST = 'CLIENTS_IMPORT_REQUEST',
  SUCCESS = 'CLIENTS_IMPORT_SUCCESS',
  FAILED = 'CLIENTS_IMPORT_FAILED',
}

export enum ClientsGet {
  REQUEST = 'CLIENTS_GET_REQUEST',
  SUCCESS = 'CLIENTS_GET_SUCCESS',
  FAILED = 'CLIENTS_GET_FAILED',
}

export enum ClientsGetAll {
  REQUEST = 'CLIENTS_GET_ALL_REQUEST',
  SUCCESS = 'CLIENTS_GET_ALL_SUCCESS',
  FAILED = 'CLIENTS_GET_ALL_FAILED',
}

export enum ClientsPut {
  CLIENTS_OFFSET_UPDATE = 'CLIENTS_OFFSET_UPDATE',
}

export enum ClientDelete {
  REQUEST = 'CLIENT_DELETE_REQUEST',
  SUCCESS = 'CLIENT_DELETE_SUCCESS',
  FAILED = 'CLIENT_DELETE_SUCCESS',
  DELETE_CLIENT_STORE = 'CLIENT_DELETE_FROM_STORE',
}

export type TClientPutAction = {
  type: ClientsPut.CLIENTS_OFFSET_UPDATE;
  payload: number;
};

export type TClientFileImportAction =
  | {
      type: ClientsImport.REQUEST;
    }
  | {
      type: ClientsImport.SUCCESS;
      payload: AxiosResponse<number>;
    }
  | {
      type: ClientsImport.FAILED;
      payload: AxiosError;
    };

export type TClientEditAction =
  | {
      type: ClientEdit.REQUEST;
    }
  | {
      type: ClientEdit.SUCCESS;
    }
  | {
      type: ClientEdit.EDIT_CLIENT_STORE;
      payload: IClient;
    }
  | {
      type: ClientEdit.FAILED;
      payload: AxiosError;
    };

export type TClientAddAction =
  | {
      type: ClientAdd.REQUEST;
    }
  | {
      type: ClientAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: ClientAdd.ADD_CLIENT_STORE;
      payload: IClient;
    }
  | {
      type: ClientAdd.FAILED;
      payload: AxiosError;
    };

export type TClientDeleteAction =
  | {
      type: ClientDelete.REQUEST;
    }
  | {
      type: ClientDelete.SUCCESS;
    }
  | {
      type: ClientDelete.DELETE_CLIENT_STORE;
      payload: string;
    }
  | {
      type: ClientDelete.FAILED;
      payload: AxiosError;
    };

export type TClientsGetAction =
  | {
      type: ClientsGet.REQUEST;
    }
  | {
      type: ClientsGet.SUCCESS;
      payload: AxiosResponse<IGetClients>;
    }
  | {
      type: ClientsGet.FAILED;
      payload: AxiosError;
    };

export type TClientsGetAllAction =
  | {
      type: ClientsGetAll.REQUEST;
    }
  | {
      type: ClientsGetAll.SUCCESS;
      payload: AxiosResponse<IGetClients>;
    }
  | {
      type: ClientsGetAll.FAILED;
      payload: AxiosError;
    };

export type TClientsActions =
  | TClientsGetAction
  | TClientsGetAllAction
  | TClientDeleteAction
  | TClientEditAction
  | TClientAddAction
  | TClientPutAction
  | TClientFileImportAction
  | TResetAction;
