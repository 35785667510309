import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormInput from 'components/Form/FormInput';
import Form from 'react-bootstrap/Form';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Alert } from 'react-bootstrap';
import './UrgentMessageForm.scss';

interface IUrgentMessageForm {
  failedToSubmit: boolean;
  errors?: Record<string, any>;
}

const UrgentMessageForm: FC<IUrgentMessageForm> = ({ errors = {}, failedToSubmit }) => {
  const { t } = useTranslation();
  const displayError = failedToSubmit && errors?.emptyFields;

  return (
    <Row>
      {displayError && (
        <Col xs={12}>
          <Alert variant='danger'>
            <Form.Text as='span' className='text-danger'>
              <FaExclamationTriangle className='mr-1' />
              {t('send_urgent_message_one_of_fields_missing')}
            </Form.Text>
          </Alert>
        </Col>
      )}
      <Col xs={12} className='mb-3'>
        <FormInput
          size='sm'
          name='subject'
          type='text'
          label={`${t('send_urgent_message_subject')} *`}
          fieldError={
            failedToSubmit
              ? {
                  message: errors?.subject,
                  title: errors?.subject,
                  causedByField: 'subject',
                }
              : undefined
          }
        />
        <FormInput
          size='sm'
          name='emailMessage'
          formType='textarea'
          label={t('send_urgent_message_email')}
          inputClass='email-input'
          fieldError={
            failedToSubmit
              ? {
                  message: errors?.emailMessage,
                  title: errors?.emailMessage,
                  causedByField: 'emailMessage',
                }
              : undefined
          }
        />
        <FormInput
          size='sm'
          name='smsMessage'
          formType='textarea'
          label={t('send_urgent_message_sms')}
          inputClass='sms-input'
        />
      </Col>
    </Row>
  );
};

export default UrgentMessageForm;
