import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormInput from '../../Form/FormInput';
import { IFormError } from '../../../constants/Interfaces';

interface IEmailAndPhoneInputGroup {
  inputColSizeSm: number;
  inputColSizeMd?: number;
  fieldError?: IFormError;
}

const EmailAndPhoneInputGroup: React.FC<IEmailAndPhoneInputGroup> = ({
  inputColSizeSm,
  inputColSizeMd,
  fieldError,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col sm={inputColSizeSm} md={inputColSizeMd}>
          <FormInput
            size='sm'
            label={t('user_form_label_email')}
            name='email'
            type='text'
            fieldError={fieldError}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={inputColSizeSm} md={inputColSizeMd}>
          <FormInput
            size='sm'
            label={t('user_form_label_phone')}
            name='phoneNumber'
            type='text'
            fieldError={fieldError}
          />
        </Col>
      </Row>
    </>
  );
};

export default EmailAndPhoneInputGroup;
