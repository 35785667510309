import React from 'react';
import { formatCurrency } from 'helpers/Currency';
import { useStoreState } from 'hooks/useStoreState';
import { useTranslation } from 'react-i18next';
import { ReservationTypes } from 'types/Reservations';

interface IProps {
  dataTip: string;
  isEmployee?: boolean;
}

export const EventTooltip: React.FC<IProps> = ({ isEmployee, dataTip }) => {
  const info = JSON.parse(dataTip);

  const { t } = useTranslation();
  const { reservationSettingsState } = useStoreState();

  // if no info is provided, return empty react fragment;
  if (!info) return null;
  const { totalPersonsCount, employeeMaxPersons } = info;

  const isBlockTime = info.clientName === ReservationTypes.BLOCK;
  const formatHours = (time: number) => {
    const hours = Math.floor(time / 60);
    if (hours) {
      const remainingMinutes = time - hours * 60;
      return remainingMinutes
        ? `${hours} ${t('twig_time_short_hours')} ${remainingMinutes} ${t(
            'report_services_duration_minutes_short',
          )}`
        : `${hours} ${t('twig_time_short_hours')}`;
    } else {
      return `${time} ${t('report_services_duration_minutes_short')}`;
    }
  };

  //time in seconds
  const timeDifference =
    (new Date(info.end).getTime() - new Date(info.start).getTime()) / 1000;

  const clientNameHTML = () => {
    if (employeeMaxPersons > 1 && !isBlockTime) return null;

    return (
      <b>
        {info.clientName.trim() === '' || isBlockTime
          ? t('reservation_client_incognito')
          : info.clientName}
      </b>
    );
  };

  const clientPhoneHTML = () => {
    if ((employeeMaxPersons > 1 && !isBlockTime) || isEmployee) return null;

    return (
      <i>{info.clientPhone !== '-' ? info.clientPhone : t('reservation_phone_empty')}</i>
    );
  };

  const serviceNameHTML = () =>
    isBlockTime ? t('reservation_blocked_time') : info.serviceName;

  const priceHTML = () => {
    if ((totalPersonsCount > 1 || employeeMaxPersons > 1) && !isBlockTime) return null;

    return (
      <>
        <hr className='tooltip-line' />
        {`${isBlockTime ? '0.00' : Number(info.price)}${formatCurrency(
          reservationSettingsState?.data?.currency,
        )}`}
      </>
    );
  };

  const employeeNameHTML = () => info.employeeName;

  const reservationTimeHTML = () => `${info.time} (${formatHours(timeDifference / 60)})`;

  const clientCommentsHTML = () => {
    if (info.reservationComments === '-' && info.clientComments === '-') return null;

    return (
      <div className='tooltip-comment'>
        {info.clientComments && info.clientComments !== '-' && (
          <>
            <hr className='tooltip-line' />
            <p className='overflow-ellipsis'>{info.clientComments}</p>
          </>
        )}
        {info.reservationComments !== '-' && (
          <>
            <hr className='tooltip-line' />
            <p className='overflow-ellipsis'>{info.reservationComments}</p>
          </>
        )}
      </div>
    );
  };

  const peopleCountHTML = () => {
    if (employeeMaxPersons <= 1 || isBlockTime) return null;

    return (
      <div>
        {t('label_busyness')}: {totalPersonsCount}/
        {Math.max(employeeMaxPersons, totalPersonsCount)}
      </div>
    );
  };

  return (
    <div className='tooltip-container' style={{ zIndex: 99999 }}>
      {clientNameHTML()}
      {clientPhoneHTML()}
      {serviceNameHTML()}
      {peopleCountHTML()}
      <hr className='tooltip-line' />
      {reservationTimeHTML()}
      <hr className='tooltip-line' />
      {employeeNameHTML()}
      {priceHTML()}
      {clientCommentsHTML()}
    </div>
  );
};
