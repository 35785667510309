import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const INSTRUCTION_LINK =
  'https://www.notion.so/PLUS-rezervacij-sistema-API-4f209d25795b4cb59b5dec8863607523';

const Instruction = () => {
  const { t } = useTranslation();
  return (
    <Container fluid className='p-0 customer-import'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='mainCard'>
            <Card.Header className='title-big'>
              {t('sidebar_menu_instruction')}
            </Card.Header>
            <Card.Body>
              <a href={INSTRUCTION_LINK}>{INSTRUCTION_LINK}</a>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Instruction;
