/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

export interface IWorkTime {
  id: string;
  userId: string;
  locationId: string;
  weekday: string;
  companyId: string;
  start: string;
  end: string;
  weekdayWorkTimeBreaks?: IWorkTimeBreak[];
  createdAt?: string;
  updatedAt?: string;
}

export interface IWorkTimeTableDataTime {
  id?: string;
  startHour: string;
  startMin: string;
  endHour: string;
  endMin: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum WeekDays {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export interface IWorkTimeTableData extends IWorkTimeTableDataTime {
  enabled: boolean;
  weekday: string;
  breaks: IWorkTimeTableDataTime[];
}

export interface IWorkTimeBreak {
  id: string;
  start: string;
  end: string;
}

export interface IWorkTimeData {
  userId: string;
  locationId: string;
  weekday:
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';
  weekdayWorkTimeBreaks?: IWorkTimeBreak[];
  companyId: string;
  start: string;
  end: string;
  id?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IWorkTimeDataWithId extends IWorkTimeData {
  id: string;
}

export interface IGetWorkTime {
  data: Array<IWorkTime>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

export interface IWorkTimeState {
  readonly data?: Array<IWorkTime>;
  readonly allData?: Array<IWorkTime>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
}

export const initialWorkTimeState: IWorkTimeState = {
  data: undefined,
  allData: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
};

export enum WorkTimeAdd {
  REQUEST = 'WORK_TIME_ADD_REQUEST',
  SUCCESS = 'WORK_TIME_ADD_SUCCESS',
  FAILED = 'WORK_TIME_ADD_FAILED',
  ADD_WORK_TIME_STORE = 'WORK_TIME_ADD_TO_STORE',
}

export enum WorkTimeGet {
  REQUEST = 'WORK_TIME_GET_REQUEST',
  SUCCESS = 'WORK_TIME_GET_SUCCESS',
  FAILED = 'WORK_TIME_GET_FAILED',
}

export enum WorkTimeGetAll {
  REQUEST = 'WORK_TIME_GET_ALL_REQUEST',
  SUCCESS = 'WORK_TIME_GET_ALL_SUCCESS',
  FAILED = 'WORK_TIME_GET_ALL_FAILED',
}

export enum WorkTimePut {
  WORK_TIME_OFFSET_UPDATE = 'WORK_TIME_OFFSET_UPDATE',
  CLEAR_WORK_TIME_STORE = 'CLEAR_WORK_TIME_STORE',
}

export type TWorkTimePutAction =
  | {
      type: WorkTimePut.CLEAR_WORK_TIME_STORE;
    }
  | {
      type: WorkTimePut.WORK_TIME_OFFSET_UPDATE;
      payload: number;
    };

export type TWorkTimeAddAction =
  | {
      type: WorkTimeAdd.REQUEST;
    }
  | {
      type: WorkTimeAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: WorkTimeAdd.ADD_WORK_TIME_STORE;
      payload: { workTime: IWorkTime[]; userId: string };
    }
  | {
      type: WorkTimeAdd.FAILED;
      payload: AxiosError;
    };

export type TWorkTimeGetAction =
  | {
      type: WorkTimeGet.REQUEST;
    }
  | {
      type: WorkTimeGet.SUCCESS;
      payload: AxiosResponse<IGetWorkTime>;
    }
  | {
      type: WorkTimeGet.FAILED;
      payload: AxiosError;
    };

export type TWorkTimeGetAllAction =
  | {
      type: WorkTimeGetAll.REQUEST;
    }
  | {
      type: WorkTimeGetAll.SUCCESS;
      payload: AxiosResponse<IGetWorkTime>;
    }
  | {
      type: WorkTimeGetAll.FAILED;
      payload: AxiosError;
    };

export type TWorkTimeActions =
  | TWorkTimeGetAction
  | TWorkTimeAddAction
  | TWorkTimeGetAllAction
  | TWorkTimePutAction
  | TResetAction;
