import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import LoginForm from '../components/Login/LoginForm';
import './Login.scss';
import { ILoginData } from '../types/User';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../constants/Routes';
import { logIn } from '../actions/UserActions';
import { TUserState } from '../types/User';
import { IAppState } from '../store/Store';
import { Roles } from '../constants/Roles';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState<boolean>();
  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  useEffect(() => {
    if (userState.data) {
      pushToRouteByRole(userState.data.roles);
    }
  }, [userState.data]);

  const pushToRouteByRole = (userRoles: string[]) => {
    if (userRoles.some(role => [Roles.Employee, Roles.Admin].includes(role as Roles))) {
      history.push(ROUTES.ADMIN.RESERVATIONS);
      return;
    }
    if (userRoles.includes(Roles.SuperAdmin) || userRoles.includes(Roles.SuperViewer)) {
      history.push(ROUTES.SUPER_ADMIN.WORKERS);
      return;
    }
    if (userRoles.includes(Roles.Analyst)) {
      history.push(ROUTES.ADMIN.REPORTS);
      return;
    }
  };

  const handleLogin = async (values: ILoginData) => {
    try {
      await dispatch(logIn(values.username, values.password));
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Container fluid className='loginRoot'>
      <Card className='card'>
        <Image
          onClick={() => history.push(ROUTES.HOME)}
          src='/logo.png'
          fluid
          className='logo py-2'
        />
        <LoginForm error={error} setError={setError} onLogIn={handleLogin} />
      </Card>
      <div className='terms-privacy-wrapper'>
        <a href={ROUTES.TERMS} rel='noreferrer'>
          Terms & Conditions
        </a>
        <a href={ROUTES.POLICY} rel='noreferrer'>
          Privacy policy
        </a>
      </div>
    </Container>
  );
};

export default Login;
