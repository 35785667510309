import React from 'react';

const Policy = () => {
  return (
    <div className='terms_and_policies'>
      <h1 className='bigH1'>Privacy policy</h1>
      <p className='bolded-intro'>Introduction</p>
      <p>
        ReservationPlus booking system is committed to protecting the privacy and personal
        information of our clients. In accordance with the General Data Protection
        Regulation (GDPR), we have created this privacy policy to explain how we collect,
        use, and protect the personal information our booking system user and partners.
      </p>

      <p>
        This Privacy Policy apply to any information collected on system and does not
        apply if data is collect through other channels.
      </p>

      <h2>What Personal Information do we collect?</h2>
      <p>
        Our booking system collects the following personal information from our users:
        <ul>
          <li>Name</li>
          <li>Last Name</li>
          <li>Telephone number</li>
          <li>Email</li>
        </ul>
      </p>
      <p>
        This information is collected through our online booking form and is necessary for
        us to provide our user with the service.
      </p>
      <p>
        Data of the authorized representative is also collected during business
        registration.
      </p>
      <h2>How Do We Use Your Personal Information?</h2>
      <p>The personal information collected is used for the following purposes:</p>

      <ul>
        <li>To confirm and process bookings.</li>
        <br />
        <li>To communicate with users regarding their bookings.</li>
        <br />
        <li>To evaluate and improve the quality of our services.</li>
        <br />
      </ul>

      <p>
        We may also use your personal information for internal purposes such as auditing,
        data analysis, and research to improve our services.
      </p>

      <h2>Who Do We Share Your Personal Information With?</h2>

      <p>
        Generally, we do not share our users’ personal information to third-party
        companies except event organizer on which behalf services are booked.
      </p>

      <p>
        We may also need to share personal information and our service providers to
        provide you with the best possible service. Our service providers are required to
        maintain the confidentiality of your personal information and to use it only for
        the purpose of providing services to our users.
      </p>

      <p>
        Upon logging into the booking system using their Google account, the system stores
        the access key given by Google. Google prompts the user during login to choose
        which account information the booking system can access. Once granted access, the
        booking system can manipulate Google Calendar entries, such as adding, editing, or
        deleting them. For further details on how Google shares data with third-party
        applications, please refer to this page. The booking system also offers the choice
        to disconnect the user&apos;s Google account, thereby removing the saved access
        key. This can also be accomplished through the user&apos;s Google settings.
      </p>

      <p>
        ReservationPlus use and transfer of information received from Google APIs to any
        other app will adhere to{' '}
        <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>
          Google API Services User Data Policy
        </a>
        , including the Limited Use requirements.
      </p>

      <h2>How Do We Protect Your Personal Information?</h2>

      <p>
        We take the protection of our users’ personal information seriously and have
        implemented measures to ensure that it is secure. We have implemented security
        protocols and procedures to prevent unauthorized access to our users’ personal
        information.
      </p>

      <p>
        In addition, we only store personal information for as long as necessary to
        fulfill the purposes for which it was collected. After that, the personal
        information is deleted or anonymized.
      </p>

      <h2>Data Subject Rights</h2>
      <p>As a data subject, you have the following rights under the GDPR: </p>

      <ul>
        <li>
          <span>The right to be informed: </span>
          Data subjects have the right to be informed about the collection and use of
          their personal information.
        </li>
        <li>
          <span>The right of access: </span>
          Data subjects have the right to access their personal information and to receive
          information about how it is being used.
        </li>
        <li>
          <span>The right to rectification: </span>
          Data subjects have the right to have any inaccurate or incomplete personal
          information corrected.
        </li>
        <li>
          <span>The right to erasure (also known as the “right to be forgotten”): </span>
          Data subjects have the right to receive their personal information in a
          structured, commonly used, and machine-readable format and to request that it be
          transferred to another organization.
        </li>
        <li>
          <span>The right to restrict processing: </span>
          Data subjects have the right to restrict the processing of their personal
          information, in certain circumstances.
        </li>
        <li>
          <span> The right to data portability: </span>
          Data subjects have the right to receive their personal information in a
          structured, commonly used, and machine-readable format and to request that it be
          transferred to another organization.
        </li>
        <li>
          <span>The right to object: </span>
          Data subjects have the right to object to the processing of their personal
          information for certain purposes, including for direct marketing purposes.
        </li>
        <li>
          <span> Rights in relation to automated decision-making and profiling: </span>
          Data subjects have the right not to be subject to automated decision-making,
          including profiling, that has a legal or similarly significant effect on them.
        </li>
      </ul>

      <p>
        If you would like to exercise any of your data subject rights, please contact us.
      </p>

      <h2>Cookies</h2>

      <p>
        Our booking system uses cookies to improve the user experience. For example,
        cookies can be used to remember user preferences, such as language settings, or to
        maintain a user’s session on our website.
      </p>

      <p>
        Most web browsers automatically accept cookies, but users can change their browser
        settings to block or delete cookies. However, blocking or deleting cookies may
        affect the user experience on our website.
      </p>

      <h2>Changes to This Privacy Policy</h2>

      <p>
        We may update this privacy policy from time to time to reflect changes in our
        practices or to comply with applicable laws. We will notify you of any changes to
        this privacy policy by posting the updated policy on our website.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or concerns about this privacy policy or the way we
        handle your personal information, please contact us by email at info@geradovana.lt
      </p>

      <h2>Conclusion</h2>

      <p>
        We are committed to protecting the privacy and personal information of our users
        and take the necessary measures to ensure that it is secure. We believe that
        transparency is key to building trust with our users, and we are committed to
        keeping you informed about how we collect, use, and protect your personal
        information.
      </p>
    </div>
  );
};

export default Policy;
