import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../constants/Routes';
import Container from 'react-bootstrap/Container';
import Sidebar from '../components/Sidebar';
import PageHeader from '../components/PageHeader';
import { ISidebarLink } from '../constants/Interfaces';
import { setAccessToken } from '../helpers/Misc';
import { logOut } from '../actions/UserActions';
import './Layout.scss';
import { isMobile } from '../constants/UtilVariables';

interface IDefaultLayout {
  superAdminSidebarLinks: ISidebarLink[];
  adminSidebarLinks: ISidebarLink[];
  employeeSidebarLinks: ISidebarLink[];
  AnalystSideBarLinks: ISidebarLink[];
}

const DefaultLayout: React.FC<IDefaultLayout> = ({
  superAdminSidebarLinks,
  children,
  adminSidebarLinks,
  employeeSidebarLinks,
  AnalystSideBarLinks,
}) => {
  const [sidebar, setSidebar] = useState(isMobile.matches ? false : true);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    setAccessToken('');
    await dispatch(logOut());
    history.push(ROUTES.LOGIN);
  };

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      isMobile?.addListener(mediaChange);
    } else {
      isMobile?.addEventListener('change', mediaChange);
    }

    return () => isMobile?.removeEventListener('change', mediaChange);
  }, []);

  const mediaChange = (e: MediaQueryListEvent) => {
    if (e.matches) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  };

  return (
    <Container fluid className='h-100'>
      <Row className='h-100'>
        {/* Drawer */}
        <Sidebar
          superAdminLinks={superAdminSidebarLinks}
          adminSidebarLinks={adminSidebarLinks}
          employeeSidebarLinks={employeeSidebarLinks}
          AnalystSideBarLinks={AnalystSideBarLinks}
          open={sidebar}
          setOpen={setSidebar}
          onLogout={handleLogout}
        />
        <Col className='main-container'>
          <PageHeader sidebar={sidebar} setSidebar={setSidebar} onLogout={handleLogout} />
          <Row>
            <Col xs={12}>{children}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DefaultLayout;
