import { store } from 'index';
import moment from 'moment';
import { SettingsTimezones } from 'types/ReservationSettings';

export const timeConvert = (num: number) => {
  const hours = Math.floor(num / 60);
  const minutes = (num % 60) + ' min.';
  return hours ? hours + 'h. ' + minutes : minutes;
};

export const getDaysBetweenDates = (startOfWeek: any, endOfWeek: any) => {
  const now = startOfWeek?.clone();
  const dates = [];
  while (now.isSameOrBefore(endOfWeek - 1)) {
    dates.push(now.format('YYYY-MM-DD'));
    now.add(1, 'days');
  }
  return dates.length ? dates : [startOfWeek.format('YYYY-MM-DD')];
};

export const formateDate = (date: any) =>
  date.format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');

export const getDateOnCompanyTimeZone = (
  date?: moment.MomentInput,
  timeZone?: SettingsTimezones,
) => {
  let timezone = timeZone;

  if (!timezone)
    timezone =
      store.getState().reservationSettingsState.data?.calendarTimeZone ||
      SettingsTimezones.VILNIUS;

  return moment(date).tz(`Europe/${timezone}`).format('YYYY-MM-DD[T]HH:mm:ssZ');
};

export const getJSDateWithCompanyTimezone = (
  dateWithTz: string,
  timeZone?: SettingsTimezones,
) => {
  let timezone = timeZone;

  if (!timezone)
    timezone =
      store.getState().reservationSettingsState.data?.calendarTimeZone ||
      SettingsTimezones.VILNIUS;

  const d = new Date(dateWithTz);

  const offsetCompany = moment.tz(moment(dateWithTz), `Europe/${timezone}`).utcOffset();
  const offsetLocal = moment(new Date()).utcOffset();

  d.setTime(d.getTime() - offsetLocal * 60 * 1000 + offsetCompany * 60 * 1000);
  return d;
};

export const getCompanyDateNow = (timeZone?: SettingsTimezones) => {
  let timezone = timeZone;

  if (!timezone)
    timezone =
      store.getState().reservationSettingsState.data?.calendarTimeZone ||
      SettingsTimezones.VILNIUS;

  return moment.tz(moment(), `Europe/${timezone}`).toISOString();
};

export const getCompanyTimeZoneFromJSDate = (
  date: Date,
  timeZone?: SettingsTimezones,
) => {
  let timezone = timeZone;

  if (!timezone)
    timezone =
      store.getState().reservationSettingsState.data?.calendarTimeZone ||
      SettingsTimezones.VILNIUS;

  const d = date;

  const offsetCompany = moment.tz(moment(), `Europe/${timezone}`).utcOffset();
  const offsetLocal = moment(date).utcOffset();

  d.setTime(d.getTime() + offsetLocal * 60 * 1000 - offsetCompany * 60 * 1000);
  return d;
};

export const getDuration = (hours?: string, minutes?: string) => {
  const parsedHours = hours ? parseInt(hours, 10) : 0;
  const parsedMinutes = minutes ? parseInt(minutes, 10) : 0;
  return parsedHours * 60 + parsedMinutes;
};

export const getTimeDifferenceInMinutes = (startDate: Date, endDate: Date) => {
  const diffInMilliseconds = endDate.getTime() - startDate.getTime();
  const diffInMinutes = diffInMilliseconds / (1000 * 60);
  return Math.floor(diffInMinutes);
};

export const addMinutesToDate = (dateString: string, minutes = 0) => {
  const date = moment(dateString, 'YYYY-MM-DDTHH:mm:ss').toDate();
  const newDate = moment(date).add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
  return newDate;
};
