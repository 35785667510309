import React from 'react';
import clsx from 'clsx';
import './ReservationInfoItem.scss';

export enum ItemModifier {
  Service = 'service',
  Price = 'price',
  Checkout = 'checkout',
}

interface IReservationInfoItem {
  title?: string;
  content?: React.ReactNode;
  subContent?: string;
  modifier?: ItemModifier;
}

const ReservationInfoItem: React.FC<IReservationInfoItem> = ({
  title,
  content,
  subContent,
  modifier,
}) => {
  return (
    <div
      className={clsx(
        'reservation-info-item',
        modifier === ItemModifier.Checkout && `reservation-info-item--checkout`,
      )}
    >
      {title && (
        <h1
          className={clsx(
            'reservation-info-item__title',
            modifier === ItemModifier.Checkout &&
              `reservation-info-item__title--checkout`,
          )}
        >
          {title}
        </h1>
      )}
      <p
        className={clsx(
          'reservation-info-item__content',
          modifier && `reservation-info-item__content--${modifier}`,
        )}
      >
        {content}
        {subContent && (
          <span
            className={clsx(
              'reservation-info-item__subcontent',
              modifier && `reservation-info-item__subcontent--${modifier}`,
            )}
          >
            {subContent}
          </span>
        )}
      </p>
    </div>
  );
};

export default ReservationInfoItem;
