import { AxiosResponse } from 'axios';
import { TDispatch } from 'types/Thunk';
import { API_END_POINTS } from 'constants/API';
import { apiClient } from 'actions/BaseAction';
import {
  ExperienceReservationAdd,
  IExperienceReservationData,
  IExperienceReservationDataWithId,
} from 'experience/types/ExperienceReservations';

export const addNewExperienceReservationtToStore = (
  reservation: IExperienceReservationDataWithId,
) => {
  return {
    type: ExperienceReservationAdd.ADD_RESERVATION_STORE,
    payload: reservation,
  };
};

export const addExperienceReservation = (reservation: IExperienceReservationData) => {
  return async (
    dispatch: TDispatch<ExperienceReservationAdd>,
  ): Promise<AxiosResponse<string>> => {
    dispatch({
      type: ExperienceReservationAdd.REQUEST,
    });

    try {
      const response = await apiClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_EXPERIENCE_RESERVATION,
        data: reservation,
      });
      dispatch({
        type: ExperienceReservationAdd.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ExperienceReservationAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addExperienceReservationMultiple = (
  reservations: Array<IExperienceReservationData>,
) => {
  return async (
    dispatch: TDispatch<ExperienceReservationAdd>,
  ): Promise<AxiosResponse<string>> => {
    dispatch({
      type: ExperienceReservationAdd.REQUEST,
    });
    try {
      const response = await apiClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_EXPERIENCE_RESERVATION_MULTIPLE,
        data: reservations,
      });
      dispatch({
        type: ExperienceReservationAdd.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ExperienceReservationAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
