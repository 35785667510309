import React, { useRef, useState, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleError } from '../../../middlewares/ErrorHandler';
import {
  addEmployee,
  deleteEmployee,
  editedEmployee,
  setEmployeeProfilePicture,
} from '../../../actions/EmployeesActions';
import useError from '../../../hooks/useError';
import MainInfoTab from './MainInfoTab';
import LocationTab from '../../Utils/Tabs/LocationTab';
import ServicesTab from './ServicesTab';
import TabsModals from '../../Utils/Modals/TabsModal';
import { validateUser } from '../../../helpers/validators';
import Toast from '../../Utils/Toast';
import { IEmployee, IEmployeeData } from '../../../types/Employees';
import AgreeToDelete from '../../Utils/Modals/AgreeToDelete';
import { Button } from 'react-bootstrap';
import { Roles } from '../../../constants/Roles';
import { IFormError } from '../../../constants/Interfaces';
import { FaRegClock } from 'react-icons/all';
import { useStoreState } from 'hooks/useStoreState';
import useGetDataForRedux from 'hooks/useGetDataForRedux';
import { getAllServices } from 'actions/ServicesActions';
import { getAllLocations } from 'actions/LocationActions';
import { getAllServiceGroups } from 'actions/ServiceGroupsActions';
import SynchronizatioTab from 'components/Utils/Tabs/SynchronizationTab';

interface IEditEmployeeSubmitData extends IEmployeeData {
  services: string[];
  locations: string[];
}

interface IAddEditEmployeeModal {
  show: boolean;
  handleClose: () => void;
  employeeToEdit?: IEmployee;
  handleWorkTimeEdit: () => void;
}

interface IEDitHoursButton {
  submitting?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
}

const EditHoursButton: React.FC<IEDitHoursButton> = ({
  submitting,
  onClick,
  isDisabled,
}) => {
  const { userState } = useStoreState();

  const { t } = useTranslation();

  return (
    <>
      {userState.data?.roles.includes(Roles.Employee) && (
        <Button
          variant='warning'
          disabled={submitting || isDisabled}
          className='grey-borders centerIcon white btn-sm'
          onClick={onClick}
        >
          <FaRegClock size={14} className='mr-1' />
          <span>{t('user_edit_worktimes')}</span>
        </Button>
      )}
    </>
  );
};

const AddEditEmployeeModal: React.FC<IAddEditEmployeeModal> = ({
  show,
  handleClose,
  employeeToEdit,
  handleWorkTimeEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hasFocusedPassword, setHasFocusedPassword] = useState(false);
  const [error, setError] = useState<IFormError>();
  const infoErrors = useRef<boolean>(false);
  const [employeeToDelete, setEmployeeToDelete] = useState<string>();
  const [locationErrors, setLocationErrors] = useState<boolean>(false);
  const [serviceErrors, setServiceErrors] = useState<boolean>(false);
  const [uploadedAvatar, setUploadedAvatar] = useState<File | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string | undefined>(
    t('user_create_tab_main_info'),
  );
  useError(error, setError);

  const { locationState, userState } = useStoreState();

  const handlePaswordGroupFocus = () => {
    if (!hasFocusedPassword) {
      setHasFocusedPassword(true);
    }
  };

  // not using useGetDataForRedux here - its check for current state does not update info when superviewer switches between companies in view mode
  useEffect(() => {
    (async () => {
      try {
        await dispatch(getAllServices());
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getAllServiceGroups());
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch]);

  useGetDataForRedux(setError, locationState.allData, getAllLocations());

  const tabs = useMemo(() => {
    const tabs = [
      {
        key: t('user_create_tab_main_info'),
        component: (
          <MainInfoTab
            fieldError={error}
            employeeId={employeeToEdit?.id}
            setUploadedAvatar={setUploadedAvatar}
            onPasswordGroupFocus={handlePaswordGroupFocus}
          />
        ),
        hasError: infoErrors.current,
      },
      {
        key: t('user_create_tab_locations'),
        component: <LocationTab />,
        hasError: locationErrors,
      },
      {
        key: t('user_create_tab_services'),
        component: <ServicesTab />,
        hasError: serviceErrors,
      },
    ];

    if (employeeToEdit?.email) {
      tabs.push({
        key: t('user_create_tab_synchro'),
        component: <SynchronizatioTab employeeId={employeeToEdit?.id} />,
        hasError: false,
      });
    }
    return tabs;
  }, [t, employeeToEdit, locationErrors, serviceErrors, infoErrors.current, error]);

  const formValidation = (values: any) => {
    const isEditingEmployee = !!employeeToEdit;
    infoErrors.current = false;
    setLocationErrors(false);
    setServiceErrors(false);
    const locationErrors: Record<string, any> = {};
    const serviceErrors: Record<string, any> = {};
    // mainInfoTab validation
    const mainInfoErrors: Record<string, any> = validateUser(
      values,
      false,
      isEditingEmployee,
    );
    // add translations to validated form
    Object.keys(mainInfoErrors).map(
      key => (mainInfoErrors[key] = t(`${mainInfoErrors[key]}`)),
    );

    // we cant use setState for this, or it will re-render whole
    if (Object.keys(mainInfoErrors).length) infoErrors.current = true;

    return {
      mainInfoErrors,
      locationErrors,
      serviceErrors,
    };
  };

  const handleSubmit = async (values: IEditEmployeeSubmitData) => {
    setError(undefined);
    const employeeObj = {
      ...values,
      serviceIds: values.services,
      locationIds: values.locations,
    };
    if (userState.data?.companyId) {
      employeeObj.company = userState.data?.companyId;
    }

    try {
      if (employeeObj?.id) {
        // const employeeReservations = (await getEmployeeReservations(employeeObj?.id))
        //   ?.data.data;

        // const hasBiggerReservationGroup = userHasReservationWithAtLeastPersons(
        //   employeeReservations,
        //   values.maxPersonsCount,
        // );

        // if (hasBiggerReservationGroup) {
        //   return toast.error(<Toast text={t('USER_ALREADY_HAS_RESERVATION_GROUP')} />);
        // }

        await dispatch(editedEmployee(employeeObj, employeeObj?.id));

        if (uploadedAvatar) {
          await dispatch(setEmployeeProfilePicture(employeeObj?.id, uploadedAvatar));
          setUploadedAvatar(undefined);

          // FIXME: app crashes if you try to open same users modal again after avatar upload.
          // This is temprary fix.
          location.reload();
        }

        toast.success(<Toast text={t('editedEmployee')} />);
      } else {
        await dispatch(addEmployee(employeeObj));
        toast.success(<Toast text={t('addedEmployee')} />);
        handleClose();
      }
      await dispatch(getAllServices());
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
        err.response?.data?.detail,
      );
    }
  };

  const handleDelete = async () => {
    if (employeeToEdit) {
      try {
        await dispatch(deleteEmployee(employeeToEdit.id));
        toast.success(
          <Toast text={t('successfullyDeleted', { item: t('user_role_employee') })} />,
        );
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    }
    handleClose();
  };

  const openDeleteDialog = () => {
    if (employeeToEdit) {
      setEmployeeToDelete(employeeToEdit.id);
    }
  };

  const formatInitialValue = () => {
    if (employeeToEdit) {
      return {
        ...employeeToEdit,
        locations: employeeToEdit?.locationIds,
        services: employeeToEdit?.serviceIds,
      };
    }
  };

  return (
    <>
      <TabsModals
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        show={show}
        handleClose={handleClose}
        modalTitle={
          employeeToEdit ? t('user_update_header') : t('user_create_page_title')
        }
        modalTabs={tabs}
        defaultActive={t('user_create_tab_main_info')}
        validation={formValidation}
        handleSubmit={handleSubmit}
        editValue={formatInitialValue()}
        handleDelete={openDeleteDialog}
        customButton={
          <EditHoursButton
            onClick={handleWorkTimeEdit}
            isDisabled={!employeeToEdit?.locationIds?.length || false}
          />
        }
      />
      <AgreeToDelete
        show={!!employeeToDelete}
        handleClose={() => setEmployeeToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </>
  );
};

export default AddEditEmployeeModal;
