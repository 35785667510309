import React from 'react';
import Row from './Row';
import { MdLocationOn } from 'react-icons/md';

import './TitleAddress.scss';

interface ITitleAddress {
  addressText: string;
}

const TitleAddress: React.FC<ITitleAddress> = ({ addressText }) => {
  return (
    <div className='title-address'>
      <Row>
        <div className='title-address__row'>
          <MdLocationOn className='title-address__icon' />
          <p className='title-address__text'>{addressText}</p>
        </div>
      </Row>
    </div>
  );
};

export default TitleAddress;
