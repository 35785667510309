export interface IExperienceReservationData {
  clientId: string;
  locationId?: string;
  servicePricingId?: string;
  userId?: string | null;
  start: string;
  end: string;
  userComments?: string;
  personsCount: number;
  voucherNumber?: string;
}

export interface IExperienceReservationDataWithId extends IExperienceReservationData {
  id: string;
}

export enum ExperienceReservationAdd {
  REQUEST = 'EXPERIENCE_RESERVATION_ADD_REQUEST',
  SUCCESS = 'EXPERIENCE_RESERVATION_ADD_SUCCESS',
  FAILED = 'EXPERIENCE_RESERVATION_ADD_FAILED',
  ADD_RESERVATION_STORE = 'EXPERIENCE_RESERVATION_ADD_TO_STORE',
}
