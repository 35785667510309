import { CompanyActivities } from './Interfaces';

export const SELECT_VALUES = [
  {
    value: CompanyActivities.bookItNow,
    key: 'activity_type_bookitnow',
  },
  {
    value: CompanyActivities.geraDovana,
    key: 'activity_type_geradovana',
  },
  {
    value: CompanyActivities.geraDovanaAndBookItNow,
    key: 'activity_type_geradovanaandbookitnow',
  },
  {
    value: CompanyActivities.lieliskaDavana,
    key: 'activity_type_lieliskadavana',
  },
  {
    value: CompanyActivities.superPrezenty,
    key: 'activity_type_superprezenty',
  },
  {
    value: CompanyActivities.bombadar,
    key: 'activity_type_bombadar',
  },
];
