import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormInput from '../../Form/FormInput';
import { IFormError } from '../../../constants/Interfaces';

interface IUserNameInput {
  inputColSizeSm: number;
  inputColSizeMd?: number;
  fieldError?: IFormError;
}

const UserNameInput: React.FC<IUserNameInput> = ({
  inputColSizeSm,
  inputColSizeMd,
  fieldError,
}) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={inputColSizeSm} md={inputColSizeMd}>
        <FormInput
          size='sm'
          label={`${t('user_form_label_username')} *`}
          name='username'
          type='text'
          fieldError={fieldError}
        />
      </Col>
    </Row>
  );
};

export default UserNameInput;
