import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NameInputGroup from '../../Utils/Inputs/NameInputGroup';
import { ICompany } from '../../../types/Companies';
import RoleCheckBoxGroup from '../../Utils/Inputs/RoleCheckBoxGroup';
import CompanySelectInput from '../../Utils/Inputs/CompanySelectInput';
import EmailAndPhoneInputGroup from '../Inputs/EmailAndPhoneInputGroup';
import PasswordInputGroup from '../Inputs/PasswordInputGroup';
import UserNameInput from '../Inputs/UserNameInput';
import ImportPictureInput from '../Inputs/ImportPictureInput';
import { IFormError } from '../../../constants/Interfaces';
import SquareAvatar from 'components/Avatars/SquareAvatar';
import MaxPeopleCount from '../Inputs/MaxPeopleCount';
import { useStoreState } from 'hooks/useStoreState';
import useError from 'hooks/useError';
import { getCompanyById } from 'actions/CompaniesActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleError } from 'middlewares/ErrorHandler';

interface IUserForm {
  hasCompanySelect?: boolean;
  companyOptions?: Array<ICompany>;
  chechBoxError?: boolean;
  fieldError?: IFormError;
  avatar?: File | null;
  setAvatar?: (file?: File) => void;
  [key: string]: any;
  onPasswordGroupFocus?: () => void;
}

const UserForm: React.FC<IUserForm> = ({
  hasCompanySelect,
  companyOptions,
  chechBoxError,
  fieldError,
  avatar,
  setAvatar,
  onPasswordGroupFocus,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const { userCompany } = useStoreState().companyState;
  const userState = useStoreState().userState?.data;

  const companyToCheckGroups =
    rest.values?.company ||
    (!hasCompanySelect && (rest.values.companyId || userState?.companyId));

  useEffect(() => {
    if (!companyToCheckGroups) return;

    try {
      dispatch(getCompanyById(companyToCheckGroups));
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  }, [companyToCheckGroups]);

  const shouldDisplayAvatar = avatar && avatar.size > 0;

  return (
    <Row>
      {/* FIRST COLUMN */}
      <Col sm={12} md={6}>
        {hasCompanySelect && (
          <CompanySelectInput
            inputColSizeSm={12}
            options={companyOptions || []}
            fieldError={fieldError}
          />
        )}
        <NameInputGroup inputColSizeSm={12} inputColSizeMd={6} fieldError={fieldError} />
        <EmailAndPhoneInputGroup inputColSizeSm={12} fieldError={fieldError} />
        <RoleCheckBoxGroup error={chechBoxError} />
        {!!companyToCheckGroups && userCompany?.reservationGroupsEnabled && (
          <MaxPeopleCount
            inputColSizeSm={12}
            fieldError={fieldError}
            disabled={!userCompany?.reservationGroupsEnabled}
          />
        )}
      </Col>
      {/* SECOND COLUMN */}
      <Col sm={12} md={6}>
        <UserNameInput inputColSizeSm={12} fieldError={fieldError} />
        <PasswordInputGroup
          inputColSizeSm={12}
          fieldError={fieldError}
          onPasswordGroupFocus={onPasswordGroupFocus}
        />
        <ImportPictureInput setValue={setAvatar} inputColSizeSm={12} />
        {shouldDisplayAvatar && (
          <Row>
            <Col sm={12} className='d-flex justify-content-center'>
              <SquareAvatar src={URL.createObjectURL(avatar)} />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default UserForm;
