import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import Toast from '../../Utils/Toast';
import { TUserState } from '../../../types/User';
import { IAppState } from '../../../store/Store';
import GroupModalForm from './GroupModalForm';
import AgreeToDelete from '../../Utils/Modals/AgreeToDelete';
import {
  addServiceGroup,
  editServiceGroup,
  deleteServiceGroup,
} from '../../../actions/ServiceGroupsActions';
import { IServiceGroup, IServiceGroupData } from '../../../types/ServiceGroups';
import FormModal from '../../Utils/Modals/FormModal';
import { IFormError } from '../../../constants/Interfaces';

interface IServiceGroupModalWrapper {
  show: boolean;
  handleClose: () => void;
  selectedGroup?: IServiceGroup;
}

const ServiceGroupModalWrapper: React.FC<IServiceGroupModalWrapper> = ({
  show,
  handleClose,
  selectedGroup,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [groupToDelete, setGroupToDelete] = useState<string>();
  useError(error, setError);

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  const formValidation = (values: IServiceGroupData) => {
    const error: Record<string, any> = {};
    if (!values.name) {
      error.name = t('required');
    }
    return error;
  };

  const handleSubmit = async (values: IServiceGroupData) => {
    setError(undefined);
    const groupObj = { ...values };
    if (userState.data?.companyId) {
      groupObj.companyId = userState.data?.companyId;
    }
    try {
      if (selectedGroup) {
        await dispatch(editServiceGroup(groupObj, selectedGroup.id));
        toast.success(<Toast text={t('editedServiceGroup')} />);
      } else {
        await dispatch(addServiceGroup(groupObj));
        toast.success(<Toast text={t('addedServiceGroup')} />);
        handleClose();
      }
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };

  const handleDelete = async () => {
    if (groupToDelete) {
      try {
        await dispatch(deleteServiceGroup(groupToDelete));
        toast.success(
          <Toast text={t('successfullyDeleted', { item: t('serviceGroup') })} />,
        );
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    }
    handleClose();
  };

  const openDeleteDialog = () => {
    if (selectedGroup) {
      setGroupToDelete(selectedGroup.id);
    }
  };

  return (
    <>
      <FormModal
        show={show}
        handleClose={handleClose}
        modalTitle={
          selectedGroup
            ? t('service_group_update_page_title')
            : t('service_group_create_page_title')
        }
        validation={formValidation}
        handleSubmit={handleSubmit}
        form={<GroupModalForm fieldError={error} />}
        handleDelete={openDeleteDialog}
        editValue={selectedGroup}
      />
      <AgreeToDelete
        show={!!groupToDelete}
        handleClose={() => setGroupToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </>
  );
};

export default ServiceGroupModalWrapper;
