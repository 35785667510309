import { IFormError } from 'constants/Interfaces';
import useError from 'hooks/useError';
import { useStoreState } from 'hooks/useStoreState';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ResourcesForm from './ResourcesForm';

interface IResourceTab {
  errors?: Record<string, any>;
  setValue?: (resource: string, value: string[]) => void;
  submitFailed?: boolean;
  values?: any;
}

interface IResourceInTab {
  name: string;
  id: string;
  count: number;
}

const ResourceTab: React.FC<IResourceTab> = ({
  errors,
  setValue,
  submitFailed,
  values,
}) => {
  const [error, setError] = useState<IFormError>();
  const [resources, setResources] = useState<Array<IResourceInTab>>();

  const resourcesState = useStoreState().resourcesState;
  useError(error, setError);
  useEffect(() => {
    const resources: any = {};
    if (resourcesState.allData) {
      resourcesState.allData.forEach(res => {
        if (!values?.locations?.includes(res.locationId)) return;

        if (!resources[res.locationId]) {
          resources[res.locationId] = [
            {
              name: res.name,
              id: res.id,
              count: res.count,
            },
          ];
        } else {
          resources[res.locationId].push(res);
        }
      });

      setResources(resources);
    }
  }, [values, resourcesState.allData]);

  return (
    <>
      {resourcesState.isLoading ? (
        <div className='tab-loader-container'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : (
        <ResourcesForm
          setValue={setValue}
          errors={errors}
          submitFailed={submitFailed}
          resourcesGroups={resources ? Object.entries(resources) : []}
        />
      )}
    </>
  );
};

export default ResourceTab;
