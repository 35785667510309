import React from 'react';
import './Block.scss';

interface IBlock {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const Block: React.FC<IBlock> = ({ title, children, className = '' }) => {
  return (
    <div className={`experience__block ${className}`}>
      <h6>{title}</h6>
      {children}
    </div>
  );
};

export default Block;
