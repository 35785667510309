interface IObject {
  [key: string]: string;
}

export const EXAMPLE_FILE_PATHS: IObject = {
  // must be in /public folder
  lt: '/klientų_importavimo_pavyzdys.xlsx',
  pl: '/przyklad_importu_klientów.xlsx',
  en: '/clients_import_example.xlsx',
  lv: '/klientu_importa_piemērs.xlsx',
  ru: '/пример_импорта_клиентов.xlsx',
};

export const FILE_RULE_COUNT = 8;
export const FILE_ALLOWED_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/octet-stream',
];
