import { INotifications, INotificationsFuncs } from 'types/Notifications';
import { PasswordValidation } from '../constants/Interfaces';

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePassword = (password: string) => {
  const symbolReg = new RegExp(
    '^(?=.*[!@#$%^&*"\\[\\]\\{\\}<>/\\(\\)=\\\\\\-_´+`~\\:;,\\.€\\|])',
  );
  if (password) {
    if (password.length < 6) {
      return PasswordValidation.Short;
    }
    if (password.search(/[A-Z]/) < 0) {
      return PasswordValidation.NoUpper;
    }
    if (password.search(/\d/) < 0) {
      return PasswordValidation.NoDigit;
    }
    if (password.search(symbolReg) < 0) {
      return PasswordValidation.NonAlphanumeric;
    }
    return null;
  }
};

export const validateUser = (values: any, isSuperAdmin: boolean, isEditing: boolean) => {
  const userErrors: any = {};
  if (isSuperAdmin) {
    if (!values.company) {
      userErrors.company = 'required';
    }
  }
  if (!values.name) {
    userErrors.name = 'required';
  }
  if (!values.roles?.length) {
    userErrors.roles = 'required';
  }
  if (!values.username) {
    userErrors.username = 'required';
  }
  if (values.password && !values.passwordRepeat) {
    userErrors.passwordRepeat = 'required';
  }
  if (values.password !== values.passwordRepeat) {
    userErrors.password = 'passwordMustMatch';
  }
  if (!isEditing && !values.password) {
    userErrors.password = 'required';
  }
  if (!isEditing && !values.passwordRepeat) {
    userErrors.passwordRepeat = 'required';
  }
  if (values.email && !validateEmail(values.email)) {
    userErrors.email = 'wrongEmailFormat';
  }
  if (!isEditing && values.password !== values.passwordRepeat) {
    userErrors.password = 'passwordMustMatch';
  }
  if (values.password) {
    const value = validatePassword(values.password);
    if (value) {
      userErrors.password = value;
    }
  }
  return userErrors;
};

export const validateNotifications = (
  values: INotifications,
  setFuncs: INotificationsFuncs,
) => {
  const errors: Record<string, any> = {};

  const validationArr = [
    {
      name: 'notificationConfirmedEmailTitle',
      value: values.notificationConfirmedEmailTitle,
      setFunc: setFuncs.confirmations,
    },
    {
      name: 'notificationConfirmedEmailBody',
      value: values.notificationConfirmedEmailBody,
      setFunc: setFuncs.confirmations,
    },
    {
      name: 'notificationConfirmedSms',
      value: values.notificationConfirmedSms,
      setFunc: setFuncs.confirmations,
    },
    {
      name: 'notificationReminderEmailTitle',
      value: values.notificationReminderEmailTitle,
      setFunc: setFuncs.reminders,
    },
    {
      name: 'notificationReminderEmailBody',
      value: values.notificationReminderEmailBody,
      setFunc: setFuncs.reminders,
    },
    {
      name: 'notificationReminderSms',
      value: values.notificationReminderSms,
      setFunc: setFuncs.reminders,
    },
    {
      name: 'notificationUpdatedEmailTitle',
      value: values.notificationUpdatedEmailTitle,
      setFunc: setFuncs.changes,
    },
    {
      name: 'notificationUpdatedEmailBody',
      value: values.notificationUpdatedEmailBody,
      setFunc: setFuncs.changes,
    },
    {
      name: 'notificationUpdatedSms',
      value: values.notificationUpdatedSms,
      setFunc: setFuncs.changes,
    },
    {
      name: 'notificationCancelledEmailTitle',
      value: values.notificationCancelledEmailTitle,
      setFunc: setFuncs.cancellations,
    },
    {
      name: 'notificationCancelledEmailBody',
      value: values.notificationCancelledEmailBody,
      setFunc: setFuncs.cancellations,
    },
    {
      name: 'notificationCancelledSms',
      value: values.notificationCancelledSms,
      setFunc: setFuncs.cancellations,
    },
    {
      name: 'notificationGratitudeEmailTitle',
      value: values.notificationGratitudeEmailTitle,
      setFunc: setFuncs.gratitude,
    },
    {
      name: 'notificationGratitudeEmailBody',
      value: values.notificationGratitudeEmailBody,
      setFunc: setFuncs.gratitude,
    },
    {
      name: 'notificationGratitudeSms',
      value: values.notificationGratitudeSms,
      setFunc: setFuncs.gratitude,
    },
  ];

  validationArr.forEach(({ name, value, setFunc }) => {
    if (!value) {
      errors[name] = 'required';
      setFunc(true);
    }
  });

  return errors;
};

export const validateUserProfile = (values: any) => {
  const userErrors: any = {};
  if (!values.name) {
    userErrors.name = 'required';
  }
  if (!values.username) {
    userErrors.username = 'required';
  }
  if (values.email && !validateEmail(values.email)) {
    userErrors.email = 'wrongEmailFormat';
  }
  if (values.password && !values.passwordRepeat) {
    userErrors.passwordRepeat = 'required';
  }
  if (
    values.password?.length &&
    values.passwordRepeat?.length &&
    values.password !== values.passwordRepeat
  ) {
    userErrors.password = 'passwordMustMatch';
  }
  if (values.password) {
    const value = validatePassword(values.password);
    if (value) {
      userErrors.password = value;
    }
  }
  if (values.profilePicture) {
    if (
      !values.profilePicture.match(
        /(.*\/)*.+\.(png|jpg|gif|bmp|jpeg|PNG|JPG|GIF|BMP|JPEG)/g,
      )
    ) {
      userErrors.profilePicture = 'notValidImage';
    }
  }
  return userErrors;
};
