import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import './TableSearch.scss';

interface ITableSearch {
  setSearchQuery: (query: string) => void;
  searchQuery: string;
  handleSearch: () => void;
  noInputClass?: boolean;
}

const TableSearch: React.FC<ITableSearch> = ({
  setSearchQuery,
  searchQuery,
  handleSearch,
}) => {
  const { t } = useTranslation();
  const isMobile = window.matchMedia('(max-width: 768px)');

  return (
    <InputGroup className='mb-3 input'>
      <FormControl
        placeholder={t('filter_search_placeholder')}
        aria-label={t('filter_search_placeholder')}
        value={searchQuery}
        onChange={(e: React.ChangeEvent<{ value: string }>) =>
          setSearchQuery(e.target.value)
        }
      />
      <InputGroup.Append>
        {!isMobile.matches ? (
          <Button variant='primary' className='button space-left' onClick={handleSearch}>
            {t('filter_search_btn')}
          </Button>
        ) : (
          <Button
            variant='primary'
            size='sm'
            className='search-button-mobile'
            onClick={handleSearch}
          >
            <FaSearch />
          </Button>
        )}
      </InputGroup.Append>
    </InputGroup>
  );
};

export default TableSearch;
