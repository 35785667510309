import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { useTable, Column, TableInstance, useExpanded } from 'react-table';
import { IServices } from '../../../types/Services';

type Data = object;

interface IServiceTable {
  columns: Column<Data>[];
  data: Array<IServices>;
  striped?: boolean;
  isLoading?: boolean;
  id?: string;
  renderRowSubComponent: (row: any) => JSX.Element;
  expanded: Record<string, boolean>;
  handleServiceModalOpen: (groupId?: string, service?: IServices) => void;
}

const ServiceTable: React.FC<IServiceTable> = ({
  data,
  columns,
  isLoading,
  striped,
  id,
  renderRowSubComponent,
  expanded,
  handleServiceModalOpen,
}) => {
  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable<Data>(
    {
      columns,
      data,
      initialState: {
        expanded,
      },
      autoResetExpanded: true,
    },
    useExpanded,
  ) as TableInstance<object>;
  return (
    <Table
      {...getTableProps()}
      striped={striped}
      hover
      responsive='md'
      size='md'
      className='minTable'
      id={id}
    >
      <tbody {...getTableBodyProps()}>
        {isLoading ? (
          <tr>
            <td />
            <td>
              <div>
                <Spinner animation='border' variant='primary' />
              </div>
            </td>
          </tr>
        ) : (
          rows.map((row: any) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                <tr
                  onClick={(e: any) => {
                    if (
                      e.target.tagName === 'path' ||
                      e.target.tagName === 'svg' ||
                      e.target.tagName === 'BUTTON' ||
                      e.target.tagName === 'INPUT'
                    )
                      return;
                    handleServiceModalOpen(row.original.groupId, row.original);
                  }}
                  className={`cursor-pointer ${
                    row.original.hiddenInPlugin ? 'opacity-50' : ''
                  }`}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                        className='service-row'
                        colSpan={cell.column.id === 'singlePriceId' ? 2 : 1}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? renderRowSubComponent(row) : null}
              </React.Fragment>
            );
          })
        )}
      </tbody>
    </Table>
  );
};

export default ServiceTable;
