import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import ModalForm from './ModalForm';
import Modal from 'react-bootstrap/Modal';
import Toast from '../../../components/Utils/Toast';
import { handleError } from '../../../middlewares/ErrorHandler';
import { IEmployee, IEmployeeData } from '../../../types/Employees';
import { addEmployee, editedEmployee } from '../../../actions/EmployeesActions';
import { TCompanyState } from '../../../types/Companies';
import { IAppState } from '../../../store/Store';
import useError from '../../../hooks/useError';
import { validateUser } from '../../../helpers/validators';
import ModalBasicButtons from '../../Utils/Modals/ModalBasicButtons';
import './EmployeeModal.scss';
import { IFormError } from '../../../constants/Interfaces';

interface IEmployeeModal {
  show: boolean;
  handleClose: () => void;
  employee?: IEmployee;
}

const EmployeeModal: React.FC<IEmployeeModal> = ({ show, handleClose, employee }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [checkboxError, setCheckBoxError] = useState<boolean>(false);

  const companyState: TCompanyState = useSelector<IAppState, TCompanyState>(
    (state: IAppState) => state.companyState,
  );

  useError(error, setError);

  const onSubmit = async (values: IEmployeeData) => {
    setError(undefined);
    setCheckBoxError(false);
    const employeeObject = { ...values };
    if (companyState.allData) {
      const selectedCompany = companyState?.allData.find(
        company => company.id === values.company,
      );
      if (selectedCompany) {
        employeeObject.companyName = selectedCompany.name;
      }
    }
    if (!values.roles) {
      setCheckBoxError(true);
      return;
    }
    try {
      if (employee) {
        await dispatch(editedEmployee(employeeObject, employee.id));
        toast.success(<Toast text={t('editedEmployee')} />);
      } else {
        await dispatch(addEmployee(employeeObject));
        toast.success(<Toast text={t('addedEmployee')} />);
      }
      handleClose();
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName='modal-lg'>
      <Modal.Header closeButton>
        <Modal.Title className='title'>
          {employee ? t('user_update_header') : t('user_create_header')}
        </Modal.Title>
      </Modal.Header>
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{
          ...employee,
          role: employee?.roles?.length ? employee.roles : '',
          company: employee?.companyId ? employee.companyId : '',
        }}
        validate={(values: IEmployeeData) => {
          const mainInfoErrors = validateUser(values, true, employee ? true : false);
          // add translations to validated form
          Object.keys(mainInfoErrors).map(
            key => (mainInfoErrors[key] = t(`${mainInfoErrors[key]}`)),
          );
          return mainInfoErrors;
        }}
        render={({ handleSubmit, submitting, ...rest }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <ModalForm
                  checkboxError={checkboxError}
                  handleSubmit={handleSubmit}
                  fieldError={error}
                  {...rest}
                />
              </Modal.Body>
              <Modal.Footer>
                {/* close and save buttons */}
                <ModalBasicButtons handleClose={handleClose} submitting={submitting} />
              </Modal.Footer>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default EmployeeModal;
