import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

interface ICustomToggle {
  onClick: (props: Record<string, any>) => void;
  displayValue: string;
}

// eslint-disable-next-line react/display-name
const DropdownToggle = React.forwardRef(
  ({ onClick, displayValue }: ICustomToggle, ref: React.Ref<HTMLDivElement>) => (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <InputGroup className='mb-3 mr-1 w-100'>
        <Form.Control as='input' className='dropdown-input' value={displayValue} />
      </InputGroup>
    </div>
  ),
);

export default DropdownToggle;
