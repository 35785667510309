import React from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import ButtonAdd from '../../../components/Utils/ButtonAdd';

import ServiceTableWrapper from './ServiceTableWrapper';
import './ServiceGroupTable.scss';
import { IServiceGroup } from '../../../types/ServiceGroups';
import { IServices } from '../../../types/Services';

interface IServiceGroupTable {
  data: IServices[];
  group: IServiceGroup;
  isLoading: boolean;
  handleGroupModalOpen: (value?: IServiceGroup) => void;
  handleServiceModalOpen: (groupId?: string, service?: IServices) => void;
  selectedLocation?: string;
}

const ServiceGroupTable: React.FC<IServiceGroupTable> = ({
  data,
  group,
  isLoading,
  handleGroupModalOpen,
  handleServiceModalOpen,
  selectedLocation,
}) => {
  const { t } = useTranslation();

  if (selectedLocation && !data.length) return null;
  return (
    <Col sm={12}>
      <Row
        className='group-header'
        onClick={(e: any) => {
          if (e.target.type === 'button') return;
          handleGroupModalOpen(group);
        }}
      >
        <span className='group-name'>{group.name}</span>
        <ButtonAdd
          text={t('service_btn_create_service')}
          handleClick={() => handleServiceModalOpen(group.id)}
          btnSize='sm'
        />
      </Row>
      {data.length > 0 ? (
        <ServiceTableWrapper
          data={data}
          isLoading={isLoading}
          handleServiceModalOpen={handleServiceModalOpen}
        />
      ) : null}
    </Col>
  );
};

export default ServiceGroupTable;
