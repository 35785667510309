import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Toast from '../../components/Utils/Toast';
import Container from 'react-bootstrap/Container';
import { IFormError } from '../../constants/Interfaces';
import useError from '../../hooks/useError';
import SettingsCalendarForm from '../../components/Admin/ReservationSettings/SettingsCalendarForm';
import TabsForm from '../../components/Utils/Tabs/TabsForm';
import {
  IReservationSettings,
  IReservationSettingsState,
} from '../../types/ReservationSettings';
import { IAppState } from '../../store/Store';
import {
  addReservationSettings,
  getReservationSettings,
} from '../../actions/ReservationSettingsActions';
import { handleError } from '../../middlewares/ErrorHandler';
import SettingsOnlineForm from '../../components/Admin/ReservationSettings/SettingsOnlineForm';
import i18next from 'config/i18next';
import { getAccessToken, parseUserData } from 'helpers/Misc';
import { Roles } from '../../constants/Roles';

const AdminReservationSettings: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [calendarTabErrors, setCalendarTabErrors] = useState<boolean>(false);
  const [onlineTabErrors, setOnlineTabErrors] = useState<boolean>(false);
  useError(error, setError);

  const token = getAccessToken();
  const user = token ? parseUserData(token) : null;
  const isSuperviewer = user?.roles.includes(Roles.SuperViewer);

  const reservationSettingsState: IReservationSettingsState = useSelector<
    IAppState,
    IReservationSettingsState
  >((state: IAppState) => state.reservationSettingsState);

  useEffect(() => {
    (async () => {
      if (!reservationSettingsState.data) {
        try {
          await dispatch(getReservationSettings());
        } catch (err) {
          handleError(
            err?.response?.status,
            setError,
            err.response?.data?.title,
            err.response?.data?.CausedByField,
          );
        }
      }
    })();
  }, [dispatch, reservationSettingsState.data]);

  const tabs = [
    {
      key: 'settings_reservation_tab_calendar',
      component: <SettingsCalendarForm />,
      hasError: calendarTabErrors,
    },
    {
      key: 'settings_reservation_tab_reservations',
      component: <SettingsOnlineForm />,
      hasError: onlineTabErrors,
    },
  ];

  const formValidation = ({
    calendarHoursTo,
    calendarHoursFrom,
    webReservationNotificationForEmployees,
    webReservationNotificationEmail,
  }: IReservationSettings) => {
    setCalendarTabErrors(false);
    setOnlineTabErrors(false);
    const calendarTabErrors: Record<string, any> = {};
    const onlineTabErrors: Record<string, any> = {};
    if (+calendarHoursFrom >= +calendarHoursTo) {
      calendarTabErrors.invalidInterval = t('settings_calendar_hours_to_less_from');
      setCalendarTabErrors(true);
    }
    if (webReservationNotificationForEmployees && !webReservationNotificationEmail) {
      onlineTabErrors.webReservationNotificationEmail = t('required');
      setOnlineTabErrors(true);
    }

    return {
      calendarTabErrors,
      onlineTabErrors,
    };
  };

  const handleSubmit = async (values: IReservationSettings) => {
    setError(undefined);
    try {
      if (values.locale && !isSuperviewer)
        await i18next.changeLanguage(values.locale.toLowerCase());
      await dispatch(addReservationSettings(values));
      toast.success(<Toast text={t('addedReservationSettings')} />);
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };

  return (
    <Container fluid className='p-0'>
      <TabsForm
        title={t('settings_reservation_group_title')}
        tabs={tabs}
        validation={formValidation}
        handleSubmit={handleSubmit}
        editValue={reservationSettingsState.data}
      />
    </Container>
  );
};

export default AdminReservationSettings;
