import {
  TServiceGroupState,
  initialServiceGroupState,
  TServiceGroupsActions,
  ServiceGroupsGet,
  ServiceGroupGetAll,
  ServiceGroupAdd,
  ServiceGroupDelete,
  ServiceGroupEdit,
} from '../types/ServiceGroups';
import { Reducer } from 'redux';
import { ResetStore } from 'types/App';

export const serviceGroupReducer: Reducer<TServiceGroupState, TServiceGroupsActions> = (
  state = initialServiceGroupState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialServiceGroupState;
    }
    case ServiceGroupDelete.REQUEST:
    case ServiceGroupEdit.REQUEST:
    case ServiceGroupAdd.REQUEST:
    case ServiceGroupGetAll.REQUEST:
    case ServiceGroupsGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ServiceGroupDelete.FAILED:
    case ServiceGroupEdit.FAILED:
    case ServiceGroupAdd.FAILED:
    case ServiceGroupGetAll.FAILED:
    case ServiceGroupsGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ServiceGroupGetAll.SUCCESS:
      return {
        ...state,
        isLoading: false,
        allData: action.payload.data.data.sort((a, b) =>
          a.name.localeCompare(b.name, 'lt'),
        ),
      };
    case ServiceGroupDelete.SUCCESS:
    case ServiceGroupEdit.SUCCESS:
    case ServiceGroupAdd.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ServiceGroupDelete.DELETE_SERVICE_GROUP_STORE: {
      return {
        ...state,
        allData: state.allData
          ? state.allData.filter((rec: any) => rec.id !== action.payload)
          : [],
        data: state.data
          ? state.data.filter((rec: any) => rec.id !== action.payload)
          : [],
        total: state.total ? state.total - 1 : 0,
      };
    }
    case ServiceGroupEdit.EDIT_SERVICE_GROUP_STORE: {
      return {
        ...state,
        allData: state.allData
          ? state.allData.map(rec =>
              rec.id === action.payload.id ? action.payload : rec,
            )
          : [],
        data: state.data
          ? state.data.map(rec => (rec.id === action.payload.id ? action.payload : rec))
          : [],
      };
    }
    case ServiceGroupAdd.ADD_SERVICE_GROUP_STORE: {
      return {
        ...state,
        data: (state.data || [])
          .concat(action.payload)
          .sort((a, b) => a.name.localeCompare(b.name, 'lt')),
        allData: (state.allData || [])
          .concat(action.payload)
          .sort((a, b) => a.name.localeCompare(b.name, 'lt')),
      };
    }
    case ServiceGroupsGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        total: action.payload.data.total,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
