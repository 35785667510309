import { AxiosResponse } from 'axios';
import {
  ReservationSettingsGet,
  ReservationSettingsAdd,
  TReservationSettingsGetAction,
  IReservationSettings,
} from '../types/ReservationSettings';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS } from '../constants/API';

export const getReservationSettings = () => {
  return apiCall<TReservationSettingsGetAction, any, IReservationSettings>(
    ReservationSettingsGet,
    'GET',
    API_END_POINTS.GET_RESERVATION_SETTINGS,
    true,
  );
};

export const addNewReservationSettingsToStore = (
  reservationSettings: IReservationSettings,
) => {
  return {
    type: ReservationSettingsAdd.ADD_RESERVATION_SETTINGS_STORE,
    payload: reservationSettings,
  };
};

export const addReservationSettings = (reservationSettings: IReservationSettings) => {
  return async (
    dispatch: TDispatch<ReservationSettingsAdd>,
  ): Promise<AxiosResponse<string>> => {
    dispatch({
      type: ReservationSettingsAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.ADD_RESERVATION_SETTINGS,
        data: reservationSettings,
      });
      dispatch({
        type: ReservationSettingsAdd.SUCCESS,
      });
      dispatch(addNewReservationSettingsToStore(reservationSettings));
      return response;
    } catch (error) {
      dispatch({
        type: ReservationSettingsAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
