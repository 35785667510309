import React, { memo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Modal from 'react-bootstrap/Modal';
import ModalBasicButtons from '../../Utils/Modals/ModalBasicButtons';

interface IFormModalExtended {
  show: boolean;
  modalTitle: string;
  handleClose: () => void;
  validation: (values: any) => Record<string, any>;
  handleSubmit: (values: any) => void;
  editValue?: Record<string, any>;
  customButton?: any;
  component: JSX.Element;
  modalClass: string;
  size?: 'sm' | 'lg' | 'xl';
  closeButtonText?: string;
  submitButtonText?: string;
}

const FormModalExtended: React.FC<IFormModalExtended> = memo(
  ({
    show,
    handleClose,
    modalTitle,
    validation,
    handleSubmit: submit,
    editValue,
    component,
    modalClass,
    size,
    closeButtonText,
    submitButtonText,
  }) => {
    const [loading, setLaoding] = useState(false);
    return (
      <Modal show={show} onHide={handleClose} size={size || 'xl'} className={modalClass}>
        <Modal.Header closeButton>
          <Modal.Title className='title'>{modalTitle}</Modal.Title>
        </Modal.Header>
        <FinalForm
          onSubmit={submit}
          initialValues={editValue}
          mutators={{
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          validate={(values: any) => {
            const errors = validation(values);
            const keys = Object.keys(errors);
            let fullError: Record<string, any> = {};
            keys.forEach(key => (fullError = { ...fullError, ...errors[key] }));
            return fullError;
          }}
          render={({
            form,
            errors,
            form: {
              mutators: { push, pop },
            },
            ...rest
          }) => {
            return (
              <form
                onSubmit={async (e: any) => {
                  e.preventDefault();
                  setLaoding(true);
                  await submit(rest.values);
                  setLaoding(false);
                }}
              >
                <Modal.Body>
                  {React.cloneElement(component, {
                    errors,
                    submitFailed: rest.submitFailed,
                    setValue: form.mutators.setValue,
                    push,
                    pop,
                    values: rest.values,
                  })}
                </Modal.Body>
                <Modal.Footer className='justify-content-end'>
                  <div>
                    <ModalBasicButtons
                      handleClose={handleClose}
                      submitting={loading}
                      closeButtonText={closeButtonText}
                      submitButtonText={submitButtonText}
                    />
                  </div>
                </Modal.Footer>
              </form>
            );
          }}
        />
      </Modal>
    );
  },
);

FormModalExtended.displayName = 'FormModalExtended';

export default FormModalExtended;
