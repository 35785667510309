import { IServiceCheckBoxGroup } from '../constants/Interfaces';
import { ChangeEvent } from 'react';
import { ILocation } from '../types/Locations';
import { IEmployee } from '../types/Employees';

export const checkAllBoxes = (
  e: ChangeEvent<HTMLInputElement>,
  data: Array<ILocation> | Array<IServiceCheckBoxGroup> | Array<IEmployee>,
  name: string,
  isGroup: boolean,
  setValue?: (name: string, value: string[]) => void,
) => {
  if (e.target.checked) {
    const allItems: string[] = [];
    data.forEach((item: any) => {
      if (isGroup) {
        item.groupServices.forEach((groupService: any) => allItems.push(groupService.id));
      } else {
        allItems.push(item.id);
      }
    });
    setValue?.(name, allItems);
  } else {
    setValue?.(name, []);
  }
};

export const createTimeOptions = (
  minuteTranslation: string,
  hourTranslation: string,
  maxTimeInMinutes: number,
) => {
  const timeArr = [];
  let hours = 0;
  for (let i = 0; i <= maxTimeInMinutes; i += i < 5 ? 1 : 5) {
    // add 1 minute for first 5 selections, then 5 minutes
    if (i !== 0 && i % 60 === 0) {
      hours++;
    }
    const minutes = i - hours * 60;
    if (i > 0) {
      timeArr.push({
        value: i,
        timeDisplay:
          hours > 0
            ? `${hours}${hourTranslation} ${minutes !== 0 ? minutes : ''}${
                minutes !== 0 ? minuteTranslation : ''
              }`
            : `${minutes}${minuteTranslation}`,
      });
    }
  }
  return timeArr;
};

export const getMinutes = () => {
  const minutes = [];
  for (let x = 0; x <= 59; x++) {
    minutes.push({ id: `${x}`.padStart(2, '0'), name: `${x}`.padStart(2, '0') });
  }
  return minutes;
};

export const getHours = () => {
  const minutes = [];
  for (let x = 0; x <= 23; x++) {
    minutes.push({ id: `${x}`.padStart(2, '0'), name: `${x}`.padStart(2, '0') });
  }
  return minutes;
};
