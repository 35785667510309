import { IReservationFormValues } from 'experience/types/ExperienceFormTypes';
import { validateEmail } from 'helpers/validators';

export const validateExperienceForm = (
  values: IReservationFormValues,
  isNumberValid: boolean,
) => {
  const errors: any = {};

  if (!values.name) {
    errors.name = 'required';
  }
  if (!values.email) {
    errors.email = 'required';
  }
  if (values.email && !validateEmail(values.email)) {
    errors.email = 'wrongEmailFormat';
  }
  if (!values.phone) {
    errors.phone = 'required';
  }
  if (!isNumberValid) {
    errors.phone = 'validators_wrong_phone_format';
  }
  return errors;
};
