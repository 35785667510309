import { apiCall } from 'actions/BaseAction';
import { API_END_POINTS } from 'constants/API';
import {
  ServiceGroupsGetExperience,
  IServiceGroupsGetExperience,
  TServiceGroupsGetExperienceAction,
} from 'experience/types/ExperienceServiceGroups';

export const getServiceGroupsExperience = (accessKey: string) => {
  return apiCall<TServiceGroupsGetExperienceAction, any, IServiceGroupsGetExperience>(
    ServiceGroupsGetExperience,
    'GET',
    API_END_POINTS.GET_SERVICE_GROUPS_EXPERIENCE,
    false,
    {},
    {},
    accessKey,
  );
};
