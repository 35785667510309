import { EventInput, AllowFunc } from '@fullcalendar/react';
import * as Moment from 'moment';
import { ICalendarEvent } from '../constants/Interfaces';
import { IVacation } from '../types/Vacations';
import { ICustomWorkTime } from '../types/CustomWorkTime';
import { IEmployee } from '../types/Employees';
import {
  IReservation,
  IReservationAvailability,
  IReservationData,
  ReservationStatus,
  ReservationTypes,
} from '../types/Reservations';
import { IServices } from '../types/Services';
import { IWorkTime } from '../types/WorkTime';
import { getDaysBetweenDates } from './DateTime';
import { extendMoment } from 'moment-range';
import { store } from 'index';
import { SettingsLocales } from 'types/ReservationSettings';

const moment = extendMoment(Moment as any);

const weekDay = (weekday: string) => {
  const locale = store.getState().reservationSettingsState?.data?.locale;
  const weekDays =
    locale === SettingsLocales.EN
      ? ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return weekDays.indexOf(weekday);
};

export const getTotalPersonsCount = (reservations?: IReservation[]) => {
  let total = 0;
  reservations?.forEach(r => (total += r.personsCount || 0));
  return total;
};

export const isOverlapping = (
  startDate1: string,
  endDate1: string,
  startDate2: string,
  endDate2: string,
) =>
  moment(startDate1).isSameOrBefore(endDate2) &&
  moment(startDate2).isSameOrBefore(endDate1);

const vacationDayHasCustomWork = (
  vacation: IVacation,
  customWorkTimes: ICustomWorkTime[],
  employee: IEmployee,
) => {
  for (let z = 0; z < customWorkTimes.length; z++) {
    const workDate = moment(customWorkTimes[z].day);

    if (
      employee.id === customWorkTimes[z].userId &&
      moment(workDate).isSameOrAfter(vacation.start) &&
      moment(workDate).isSameOrBefore(vacation.end)
    ) {
      return true;
    }
  }
  return false;
};

const getVocationBackgroundEvents = (
  vacations: IVacation[] | undefined,
  employess: IEmployee[],
  customWorkTime: ICustomWorkTime[],
  selectedLocation: string | undefined,
) => {
  const vocationBackgroundEvents: EventInput[] = [];
  if (vacations) {
    for (let i = 0; i < vacations.length; i++) {
      if (vacations[i].locationIds.includes(selectedLocation || '')) {
        const overlapinVocations = vocationBackgroundEvents.find(rec => {
          return isOverlapping(
            rec.start as string,
            rec.end as string,
            `${vacations[i].start.split('T')[0]}T00:00:00`,
            `${vacations[i].end.split('T')[0]}T23:59:59`,
          );
        });
        if (!overlapinVocations) {
          for (let j = 0; j < employess.length; j++) {
            if (vacationDayHasCustomWork(vacations[i], customWorkTime, employess[j])) {
              continue;
            }
            vocationBackgroundEvents.push({
              resourceId: employess[j].id,
              groupId: 'businessHours',
              start: `${vacations[i].start.split('T')[0]}T00:00:00`,
              end: `${vacations[i].end.split('T')[0]}T23:59:59`,
              display: 'background',
              allDay: false,
              locationId: selectedLocation,
            });
          }
        }
      }
    }
  }

  return vocationBackgroundEvents;
};

const findIfDayIsVacation = (
  dayToCheck: string,
  vacations?: IVacation[],
  selectedLocation?: string,
) => {
  return (vacations || []).some(
    vac =>
      moment(dayToCheck).isBetween(
        vac.start.slice(0, 10),
        vac.end.slice(0, 10),
        'days',
        '[]',
      ) && vac.locationIds.includes(selectedLocation || ''),
  );
};

const findIfNotWorkingOnThisDate = (
  nonWorkingHours: EventInput[],
  workTime: IWorkTime,
  date: string,
  selectedLocation?: string,
) => {
  return nonWorkingHours.find(
    rec =>
      rec.resourceId === workTime.userId &&
      workTime.locationId === (selectedLocation || '') &&
      moment(date).isBetween(rec.start, rec.end, 'hours', '[]'),
  );
};

const getNonWorkingHoursFromCustomWorkTimes = (
  customWorkTime: ICustomWorkTime[],
  dateList: string[],
) => {
  const nonWorkingHours: EventInput[] = [];

  customWorkTime.forEach(workTime => {
    const day = workTime.day.slice(0, 10);
    if (!dateList.includes(day)) return;
    if (workTime.start && workTime.end && workTime.enabled) {
      nonWorkingHours.push({
        resourceId: workTime.userId,
        groupId: 'businessHours',
        start: `${day}T00:00:00`,
        end: `${day}T${workTime.start}`,
        display: 'background',
        allDay: false,
        locationId: workTime.locationId,
      });
      nonWorkingHours.push({
        resourceId: workTime.userId,
        groupId: 'businessHours',
        start: `${day}T${workTime.end}`,
        end: `${day}T23:59:59`,
        display: 'background',
        allDay: false,
        locationId: workTime.locationId,
      });
    } else {
      // Whole day is disabled
      nonWorkingHours.push({
        resourceId: workTime.userId,
        groupId: 'businessHours',
        start: `${day}T00:00:00`,
        end: `${day}T23:59:59`,
        display: 'background',
        allDay: false,
        locationId: workTime.locationId,
      });
    }

    if (workTime.customWorkTimeBreaks) {
      workTime.customWorkTimeBreaks.forEach(_break => {
        nonWorkingHours.push({
          resourceId: workTime.userId,
          groupId: 'businessHours',
          start: `${day}T${_break.start}`,
          end: `${day}T${_break.end}`,
          display: 'background',
          allDay: false,
          locationId: workTime.locationId,
        });
      });
    }
  });
  return nonWorkingHours;
};

const getWorkingHoursFromCustomWorkTimes = (
  workTimes: IWorkTime[],
  dateList: string[],
  vocations: IVacation[] | undefined,
  nonWorkingHours: EventInput[],
  selectedLocation?: string,
) => {
  const workingHours: EventInput[] = [];
  workTimes.forEach(workTime => {
    const day = weekDay(workTime.weekday);
    dateList.forEach(date => {
      if (moment(date).weekday() !== day) return;
      // ignore vacation days, so we dont see overlaps
      if (findIfDayIsVacation(date, vocations, selectedLocation)) return;
      // ignore non working hours
      if (findIfNotWorkingOnThisDate(nonWorkingHours, workTime, date, selectedLocation)) {
        return;
      }

      workingHours.push({
        resourceId: workTime.userId,
        groupId: 'businessHours',
        start: `${date}T00:00:00`,
        end: `${date}T${workTime.start}`,
        display: 'background',
        allDay: false,
        locationId: workTime.locationId,
      });
      workingHours.push({
        resourceId: workTime.userId,
        groupId: 'businessHours',
        start: `${date}T${workTime.end}`,
        end: `${date}T23:59:59`,
        display: 'background',
        allDay: false,
        locationId: workTime.locationId,
      });

      if (workTime.weekdayWorkTimeBreaks) {
        workTime.weekdayWorkTimeBreaks.forEach(_break => {
          workingHours.push({
            resourceId: workTime.userId,
            groupId: 'businessHours',
            start: `${date}T${_break.start}`,
            end: `${date}T${_break.end}`,
            display: 'background',
            allDay: false,
            locationId: workTime.locationId,
          });
        });
      }
    });
  });
  return workingHours;
};

export const formateBusinessHours = (
  events: EventInput[],
  customWorkTime: ICustomWorkTime[],
  workTime: IWorkTime[],
  calendarStartDate: any,
  calendarEndDate: any,
  employees: IEmployee[],
  selectedLocation?: string,
  vacations?: IVacation[] | undefined,
) => {
  const formatedEvents = events;
  const notWorkingEvents: EventInput[] = [];
  const dateList: string[] = getDaysBetweenDates(calendarStartDate, calendarEndDate);
  const vocations = vacations?.filter(rec =>
    dateList.find(
      day =>
        moment(day).isBetween(moment(rec.start), moment(rec.end), 'days', '[]') &&
        rec.locationIds.includes(selectedLocation || ''),
    ),
  );

  const vocationBacgroundEvents = getVocationBackgroundEvents(
    vocations,
    employees,
    customWorkTime,
    selectedLocation,
  );
  const nonWorkingHoursFromCustomWorkTimes = getNonWorkingHoursFromCustomWorkTimes(
    customWorkTime,
    dateList,
  );
  const workingHoursFromWorkTimes = getWorkingHoursFromCustomWorkTimes(
    workTime,
    dateList,
    vocations,
    nonWorkingHoursFromCustomWorkTimes,
    selectedLocation,
  );
  formatedEvents.push(
    ...vocationBacgroundEvents,
    ...nonWorkingHoursFromCustomWorkTimes,
    ...workingHoursFromWorkTimes,
  );

  for (let i = 0; i < employees.length; i++) {
    for (let d = 0; d < dateList.length; d++) {
      if (
        !formatedEvents.some(
          ev =>
            ev.resourceId === employees[i].id &&
            ev.locationId === selectedLocation &&
            moment(dateList[d]).isBetween(ev.start, ev.end, undefined, '[]') &&
            ev.groupId,
        )
      ) {
        let notWorkStart;
        let notWorkEnd;
        const startTime = '00:00:01';
        const endTime = '23:59:59';

        if (dateList[d].includes('T')) {
          notWorkStart = dateList[d].slice(0, 11) + startTime;
          notWorkEnd = dateList[d].slice(0, 11) + endTime;
        } else {
          notWorkStart = dateList[d].slice(0, 11) + 'T' + startTime;
          notWorkEnd = dateList[d].slice(0, 11) + 'T' + endTime;
        }

        const notWorking = {
          resourceId: employees[i].id,
          groupId: 'businessHours',
          start: notWorkStart,
          end: notWorkEnd,
          display: 'background',
          locationId: selectedLocation,
          day: dateList[d],
        };

        notWorkingEvents.push(notWorking);
      }
    }
  }
  formatedEvents.push(...notWorkingEvents);
  return formatedEvents;
};

export enum EventColors {
  Preview = '#F8886F',
  Block = '#cccccc',
  Multireservation = '#B453C6',
  Default = '#1AB394',
  FromApi = '#5443F5',
  External = '#F8886F',
}
export const getSmallCalendarEventColor = (
  reservation: IReservation | IReservationData,
  isMultiReservation: boolean,
) => {
  if ((reservation as IReservationData).isPreview) {
    return EventColors.Preview;
  }
  if (reservation.type === ReservationTypes.BLOCK) {
    return EventColors.Block;
  }
  if (isMultiReservation) {
    return EventColors.Multireservation;
  }
  return EventColors.Default;
};

export const getCalendarEventColor = (
  reservation: IReservation | IReservationData,
  isMultiReservation: boolean,
) => {
  if ((reservation as IReservationData).isPreview) {
    return EventColors.Preview;
  }
  if (reservation.type === ReservationTypes.BLOCK) {
    return EventColors.Block;
  }
  if (isMultiReservation) {
    return EventColors.Multireservation;
  }
  if (reservation.fromApi) {
    if (reservation.isAutoConfirm) {
      return EventColors.FromApi;
    }
    return EventColors.External;
  }
  return EventColors.Default;
};

export const toHoursAndMinutes = (totalMinutes: number) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:00`;
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const formatCalendarEventTitle = (
  serviceName: string | undefined,
  servicePricingName: string | undefined,
) => {
  if (!servicePricingName) return serviceName || '-';
  return `${serviceName || '-'} - ${servicePricingName}`;
};

type MatchingReservation = Pick<IReservation, 'locationId' | 'userId' | 'start' | 'id'>;
export const matchingReservationFilterFunc = (
  a: MatchingReservation,
  b: MatchingReservation,
) => {
  return (
    a.locationId === b.locationId &&
    a.userId === b.userId &&
    moment(b.start).isSame(a.start) &&
    a.id !== b.id
  );
};

export const formateCalendarData = (
  services: Array<IServices>,
  reservations: Array<IReservation>,
  employees: Array<IEmployee>,
  customWorkTime: Array<ICustomWorkTime>,
  workTime: Array<IWorkTime>,
  calendarStartDate: any,
  calendarEndDate: any,
  selectedLocation?: string,
  vocations?: IVacation[],
) => {
  const events: EventInput[] = [];
  // filter reservations not to show canceled ones
  const filteredReservations = reservations.filter(
    (res: IReservation) => res.status !== ReservationStatus.CANCELLED,
  );
  let skipReservations: Array<IReservation> = [];

  filteredReservations.forEach(reservation => {
    if (skipReservations.some(r => r.id === reservation.id)) return;

    // find all reservations with same userId, locationId and start-end times:
    const matchingReservations = filteredReservations.filter(r =>
      matchingReservationFilterFunc(r, reservation),
    );

    // get service name
    const { name: serviceName, pricings } =
      services.find(s => s.id === reservation.serviceId) || {};

    const { name: servicePricingName } =
      (pricings || []).find(p => p.id === reservation.servicePricingId) || {};

    // get employee name
    const { name: employeeName, maxPersonsCount: employeeMaxPersons } =
      employees.find(employee => employee.id === reservation.userId) || {};

    const isMultiReservation =
      !!matchingReservations.length ||
      Number(reservation?.personsCount) > 1 ||
      Number(employeeMaxPersons) > 1;
    skipReservations = [...skipReservations, ...matchingReservations]; // now ignore matching reservations on other iterations.

    let totalPersonsCount = Number(reservation.personsCount || 1);
    matchingReservations.forEach(r => (totalPersonsCount += Number(r.personsCount || 0)));

    const color = getCalendarEventColor(reservation, isMultiReservation);
    const event: ICalendarEvent = {
      ...reservation,
      resourceId: reservation.userId, //fullCalendar finds out to which resource it belongs
      backgroundColor: color,
      borderColor: color,
      locationId: reservation.locationId,
      isMultiReservation: !!isMultiReservation,
    };

    const clientName = `${reservation.client?.name || ''} ${
      reservation.client?.lastname || ''
    }`;
    const clientPhone = reservation.client?.phone;

    event.title = JSON.stringify({
      serviceName: formatCalendarEventTitle(serviceName, servicePricingName),
      clientName:
        reservation.type === ReservationTypes.BLOCK
          ? ReservationTypes.BLOCK
          : clientName || '-',
      clientPhone: clientPhone || '-',
      price: reservation.price || 0,
      employeeName: employeeName || '-',
      employeeMaxPersons: employeeMaxPersons || 0,
      clientComments: reservation.client?.comments || '-',
      reservationComments: reservation.userComments || '-',
      duration: reservation.duration || 0,
      start: `${reservation.start}` || '-',
      end: `${reservation.end}` || '-',
      id: reservation.id,
      clientId: reservation.clientId,
      totalPersonsCount: totalPersonsCount,
      isMultiReservation,
      droppable: true,
    });

    events.push(event);
  });

  const formatedEvents = formateBusinessHours(
    events,
    customWorkTime,
    workTime,
    calendarStartDate,
    calendarEndDate,
    employees,
    selectedLocation,
    vocations,
  );

  return formatedEvents;
};

// **** Used for reservations modal

export const checkReservationsOverlapping = (
  availableTimeSlotRange: any,
  userReservations: IReservation[],
  selectedUserReservations: IReservation[] | undefined,
  // reservationPreview is only used in small calendar.
  reservationPreview?: IReservationData,
) => {
  if (selectedUserReservations) {
    for (let i = 0; i < userReservations.length; i++) {
      const reservationRange = moment.range(
        moment(userReservations[i].start),
        moment(userReservations[i].end),
      );
      // if we are editing a reservation
      // allow to select the initial time slot
      if (
        reservationPreview &&
        moment(userReservations[i].start).isSame(reservationPreview?.initialData?.start)
      ) {
        continue;
      }

      if (reservationRange.overlaps(availableTimeSlotRange)) {
        return false;
      }
    }
    return true;
  }
};

export const isBadAutoUserSelection = (
  reservationValues: any,
  availableTimeSlots: IReservationAvailability[],
  autoUserReservations: IReservation[] | undefined,
  selectedUserId: string,
  selectedUserReservations: IReservation[] | undefined,
) => {
  if (selectedUserId !== 'auto' || !reservationValues?.start || !reservationValues?.end) {
    return false;
  }

  const availableSlotsArray = [];
  if (
    reservationValues &&
    reservationValues?.start &&
    reservationValues?.end &&
    availableTimeSlots?.length
  ) {
    const selectedDate = [
      moment(reservationValues?.start),
      moment(reservationValues?.end),
    ];

    for (let i = 0; i < availableTimeSlots.length; i++) {
      const availableTimeSlotItem = [
        moment(availableTimeSlots[i].start),
        moment(availableTimeSlots[i].end),
      ];

      const selectedDateRange = moment.range(selectedDate[0], selectedDate[1]);
      const availableTimeSlotRange = moment.range(
        availableTimeSlotItem[0],
        availableTimeSlotItem[1],
      );
      //check if available timeslot contains selected time
      if (availableTimeSlotRange.contains(selectedDateRange)) {
        const userReservations = autoUserReservations?.filter(
          res => res.userId === availableTimeSlots[i].userId,
        );
        //check if selected time is already reserved for current user
        if (
          checkReservationsOverlapping(
            selectedDateRange,
            userReservations || [],
            selectedUserReservations,
          )
        ) {
          availableSlotsArray.push(availableTimeSlots[i]);
        }
      }
    }
  }
  return !(availableSlotsArray.length > 0);
};

export const isEventDragAllowed: AllowFunc = (span, movingEvent) => {
  const { start: oldDate } = movingEvent || {};
  const { start: newDate } = span || {};

  const isSameDay = moment(oldDate).isSame(newDate, 'day');
  const isSameEmployee =
    movingEvent?._def.extendedProps.userId === span.resource?._resource.id;

  return isSameDay && isSameEmployee;
};
