import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import clsx from 'clsx';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { useTable, useSortBy, Column, TableInstance } from 'react-table';
import { ICompany } from '../../../types/Companies';
import { IEmployee } from '../../../types/Employees';
import { ISort } from '../../../constants/Interfaces';
import { ILocation } from '../../../types/Locations';
import { IResource } from '../../../types/Resources';
import { IVacation } from '../../../types/Vacations';
import { IClient } from '../../../types/Clients';
import { IReservation } from '../../../types/Reservations';
import './TableSortable.scss';

type Data = object;

interface ITableSortable {
  columns: Column<Data>[];
  data:
    | ICompany[]
    | IEmployee[]
    | ILocation[]
    | IResource[]
    | IClient[]
    | IVacation[]
    | IReservation[];
  onSort: (sortObj: ISort[], offset: number) => void;
  customWidth?: string;
  applyCustomWidthTo?: string[];
  striped?: boolean;
  selectRow?: (value: any) => void;
  openModal?: (open: boolean) => void;
  isLoading?: boolean;
  offset?: number;
}

const TableSortable: React.FC<ITableSortable> = ({
  data,
  columns,
  onSort,
  customWidth,
  applyCustomWidthTo,
  striped,
  selectRow,
  openModal,
  isLoading,
  offset,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable<Data>(
    {
      columns,
      data,
      manualSortBy: true,
    },
    useSortBy,
  ) as TableInstance<object>;

  useEffect(() => {
    if (sortBy.length > 0) {
      onSort(sortBy, offset || 0);
    }
  }, [onSort, sortBy]);

  return (
    <div className='table-sortable-container'>
      <Table
        {...getTableProps()}
        striped={striped}
        hover
        responsive='md'
        size='sm'
        className='minTable'
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={uuidv4()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                  id={column.id}
                  className={clsx(
                    !column.disableSortBy && 'cursor-pointer',
                    column.id === 'buttons' || column.id === 'gender'
                      ? 'buttons-column'
                      : '',
                    column.id === 'daysNumber' ? 'small-column' : '',
                  )}
                  style={{
                    width:
                      customWidth &&
                      applyCustomWidthTo &&
                      applyCustomWidthTo.includes(column.id)
                        ? customWidth
                        : '',
                  }}
                >
                  {column.render('Header')}
                  {!column.isSorted ? null : column.isSorted && column.isSortedDesc ? (
                    <MdArrowDownward />
                  ) : (
                    <MdArrowUpward />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            <tr>
              <td />
              <td>
                <div>
                  <Spinner animation='border' variant='primary' />
                </div>
              </td>
            </tr>
          ) : (
            rows.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.id}
                  onClick={() => {
                    selectRow?.(row.original);
                    openModal?.(true);
                  }}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                        className='table-cells'
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableSortable;
