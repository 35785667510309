import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import Toast from '../../Utils/Toast';
import { IAppState } from '../../../store/Store';
import {
  IWorkTime,
  IWorkTimeData,
  IWorkTimeState,
  IWorkTimeTableData,
  WeekDays,
} from '../../../types/WorkTime';
import { ILocation } from '../../../types/Locations';
import { IFormError } from '../../../constants/Interfaces';
import { IEmployee } from '../../../types/Employees';
import TabsModals from '../../Utils/Modals/TabsModal';
import {
  addWorkTime,
  getWorkTime,
  getWorkTimeAll,
} from '../../../actions/WorkTimeActions';
import WorkTimeTable from './WorkTimeTable';
import { TUserState } from '../../../types/User';
import { v4 as uuidv4 } from 'uuid';

const moment = extendMoment(Moment as any);

interface IAddEditWorkTimeModal {
  show: boolean;
  handleClose: () => void;
  selectedEmployee?: IEmployee;
  locations?: ILocation[];
  clearWorkTimesAfterSubmit?: boolean;
  currentLocationId?: string;
}

interface ITab {
  key: string;
  component: JSX.Element;
  hasError: boolean;
}

interface IDataObj {
  [location: string]: {
    [weekday: string]: IWorkTimeTableData;
  };
}

const formateTableData = (employee: IEmployee, workTimeData: Array<IWorkTime>) => {
  const dataObj: IDataObj = {};

  employee.locationIds?.forEach(locId => {
    if (!dataObj[locId]) {
      dataObj[locId] = {};
    }
  });

  workTimeData.forEach(
    ({ id, start, end, weekday, weekdayWorkTimeBreaks, locationId }) => {
      if (dataObj[locationId]) {
        dataObj[locationId][weekday] = {
          id,
          weekday,
          enabled: true,
          startHour: start.split(':')[0],
          startMin: start.split(':')[1],
          endHour: end.split(':')[0],
          endMin: end.split(':')[1],
          breaks:
            weekdayWorkTimeBreaks?.map(({ id, end, start }) => ({
              id,
              startHour: start.split(':')[0],
              startMin: start.split(':')[1],
              endHour: end.split(':')[0],
              endMin: end.split(':')[1],
            })) || [],
        };
      }
    },
  );

  [
    WeekDays.MONDAY,
    WeekDays.TUESDAY,
    WeekDays.WEDNESDAY,
    WeekDays.THURSDAY,
    WeekDays.FRIDAY,
    WeekDays.SATURDAY,
    WeekDays.SUNDAY,
  ].forEach(weekday => {
    for (const location in dataObj) {
      if (!dataObj[location][weekday]) {
        dataObj[location][weekday] = {
          enabled: false,
          weekday,
          startHour: '08',
          startMin: '00',
          endHour: '17',
          endMin: '00',
          breaks: [],
        };
      }
    }
  });
  return dataObj;
};

const WorkTimeAddModal: React.FC<IAddEditWorkTimeModal> = ({
  show,
  handleClose,
  selectedEmployee,
  locations = [],
  currentLocationId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [tabs, setTabs] = useState<ITab[]>([]);
  const [tableData, setTableData] = useState<IDataObj>();
  const [selectedLocationId, setSelectedLocationId] = useState<string>();
  const [userId, setUserId] = useState<string>();
  const [activeTab, setActiveTab] = useState<string | undefined>(tabs[0]?.key || '');
  const workTimeState: IWorkTimeState = useSelector<IAppState, IWorkTimeState>(
    (state: IAppState) => state.workTimeState,
  );
  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  useError(error, setError);

  useEffect(() => {
    if (selectedEmployee) {
      setUserId(selectedEmployee.id);
      (async () => {
        try {
          await dispatch(
            getWorkTime({
              UserId: selectedEmployee?.id || '',
              offset: 0,
              limit: 1000,
            }),
          );
          setSelectedLocationId(undefined);
        } catch (err) {
          handleError(err?.response?.status, setError, err.response?.data?.title);
        }
      })();
    } else {
      setSelectedLocationId(undefined);
    }
  }, [dispatch, selectedEmployee]);

  useEffect(() => {
    if (workTimeState.data && locations && selectedEmployee) {
      const formattedData = formateTableData(selectedEmployee, workTimeState.data);
      setTableData(formattedData || []);
    }
  }, [locations, workTimeState.data]);

  useEffect(() => {
    if (selectedEmployee) {
      const formattedLocations =
        selectedEmployee?.locationIds?.map(locId =>
          locations.find(loc => loc.id === locId),
        ) || [];

      const newTabs = formattedLocations.map(loc => ({
        key: loc?.name || '',
        component: (
          <WorkTimeTable selectedLocationId={selectedLocationId || ''} locId={loc?.id} />
        ),
        hasError: false,
      }));

      setTabs(newTabs);

      if (newTabs.length > 0 && selectedLocationId === undefined) {
        setSelectedLocationId(locations.find(loc => loc.name === newTabs[0].key)?.id);
      }
    }
  }, [selectedEmployee, selectedLocationId]);

  const checkBreaksOverlapping = (timeSegments: Array<string[]>) => {
    let result = false;
    let i = 0;
    while (!result && i < timeSegments.length - 1) {
      const seg1 = timeSegments[i];
      const seg2 = timeSegments[i + 1];
      const range1 = moment.range(moment(seg1[0], 'HH:mm'), moment(seg1[1], 'HH:mm'));
      const range2 = moment.range(moment(seg2[0], 'HH:mm'), moment(seg2[1], 'HH:mm'));
      if (range1.overlaps(range2)) {
        result = true;
      }
      i++;
      return result;
    }
  };

  const formValidation = (values: IDataObj) => {
    const workTimeErrors: Record<string, any> = {};

    for (const locationId in values) {
      const workDays = values[locationId];
      const { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } = WeekDays;
      const initWeekdayErrors = (weekday: string) => {
        if (!workTimeErrors[weekday]) {
          workTimeErrors[weekday] = {};
        }
      };
      [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY].forEach(
        weekday => {
          const { breaks, startHour, startMin, endHour, endMin } = workDays[weekday];
          if (`${endHour}${endMin}` <= `${startHour}${startMin}`) {
            initWeekdayErrors(weekday);
            workTimeErrors[weekday]['timeRange'] = t('validators_date_time_range');
          }
          const breaksTimeRanges = breaks.map(breakItem => [
            `${breakItem?.startHour}:${breakItem?.startMin}:`,
            `${breakItem?.endHour}:${breakItem?.endMin}:`,
          ]);

          if (checkBreaksOverlapping(breaksTimeRanges)) {
            initWeekdayErrors(weekday);
            workTimeErrors[weekday]['breakOverlap'] = t('validators_break_overlaps');
          }

          breaks.forEach((breakItem, index) => {
            const breakStartHour = breakItem?.startHour;
            const breakStartMin = breakItem?.startMin;
            const breakEndHour = breakItem?.endHour;
            const breakEndMin = breakItem?.endMin;
            const timeRangeError =
              `${breakEndHour}${breakEndMin}` <= `${breakStartHour}${breakStartMin}`;
            const inBoundsError =
              `${breakStartHour}${breakStartMin}` < `${startHour}${startMin}` ||
              `${breakEndHour}${breakEndMin}` > `${endHour}${endMin}`;

            if (timeRangeError || inBoundsError) {
              initWeekdayErrors(weekday);
              if (!workTimeErrors[weekday]['breaks']) {
                workTimeErrors[weekday]['breaks'] = {};
              }
              workTimeErrors[weekday]['breaks'][index] = timeRangeError
                ? t('validators_date_time_range')
                : t('validators_break_is_in_bounds');
            }
          });
        },
      );
    }

    return { workTimeErrors };
  };

  const handleSubmit = async (workTime: IDataObj) => {
    const workDaysData: IWorkTimeData[] = [];
    const days = [
      WeekDays.MONDAY,
      WeekDays.TUESDAY,
      WeekDays.WEDNESDAY,
      WeekDays.THURSDAY,
      WeekDays.FRIDAY,
      WeekDays.SATURDAY,
      WeekDays.SUNDAY,
    ];

    for (const locationId in workTime) {
      const workDays = workTime[locationId];
      days.forEach(weekday => {
        const {
          enabled,
          breaks,
          startHour,
          startMin,
          endHour,
          endMin,
          id,
          createdAt,
          updatedAt,
        } = workDays[weekday];
        if (enabled) {
          const workDayData: IWorkTimeData = {
            userId: selectedEmployee?.id || userId || '',
            locationId,
            weekday,
            companyId: userState.data?.companyId?.toLowerCase() || '',
            weekdayWorkTimeBreaks: breaks.map(
              ({ id, endHour, endMin, startHour, startMin }) => ({
                id: id || uuidv4(),
                start: `${startHour}:${startMin}`,
                end: `${endHour}:${endMin}`,
              }),
            ),
            start: `${startHour}:${startMin}`,
            end: `${endHour}:${endMin}`,
            createdAt,
            updatedAt,
          };

          workDaysData.push(id ? { ...workDayData, id } : workDayData);
        }
      });
    }
    await handleEdit(workDaysData);
  };

  const handleEdit = async (workTimeData: IWorkTimeData[]) => {
    try {
      if (selectedEmployee) {
        await dispatch(addWorkTime(workTimeData, selectedEmployee.id));
        await dispatch(
          getWorkTime({
            UserId: selectedEmployee?.id || '',
            offset: 0,
            limit: 1000,
          }),
        );
        if (currentLocationId) {
          await dispatch(getWorkTimeAll({ LocationId: currentLocationId }));
        }
        toast.success(<Toast text={t('editedWorkTime')} />);
      }
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const onTabChange = (tab: string) => {
    setSelectedLocationId(locations.find(loc => loc.name === tab)?.id || '');
  };

  return (
    <TabsModals
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      show={show}
      handleClose={handleClose}
      modalTitle={t('editWorkingHours')}
      modalTabs={tabs}
      defaultActive={tabs[0]?.key || ''}
      validation={formValidation}
      handleSubmit={handleSubmit}
      onTabChange={onTabChange}
      editValue={tableData}
      customDialogClass='work-time-modal'
    />
  );
};

export default WorkTimeAddModal;
