import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import ProfileForm from '../../components/Admin/UserProfile/ProfileForm';

const AdminProfile: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className='p-0'>
      <Row>
        <Card className='mainCard'>
          <Card.Header className='title-big'>{t('user_profile_header')}</Card.Header>
          <Card.Body className='px-2 py-3'>
            <ProfileForm />
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default AdminProfile;
