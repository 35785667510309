import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  DeepPartial,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// reducers
import { requestReducer } from '../reducers/RequestReducer';
import { usersReducer } from '../reducers/UserReducer';
import { companiesReducer } from '../reducers/CompaniesReducer';
import { employeesReducer } from '../reducers/EmployeesReducer';
import { locationsReducer } from '../reducers/LocationsReducer';
import { servicesReducer } from '../reducers/ServicesReducer';
import { serviceGroupReducer } from '../reducers/ServiceGroupsReducer';
import { resourcesReducer } from '../reducers/ResourcesReducer';
import { vacationsReducer } from '../reducers/VacationsReducer';
import { IUtilsState, utilsReducer } from '../reducers/UtilsReducer';
import { experienceWidgetReducer } from '../experience/redux/reducers/ExperienceReducer';
import { experienceWidgetMultipleReducer } from './../experience/redux/reducers/ExperienceMultipleReducer';
import { notificationsReducer } from '../reducers/NotificationsReducer';
import { experienceCompanyReducer } from 'experience/redux/reducers/ExperienceCompanyReducer';
import { experienceLocationReducer } from 'experience/redux/reducers/ExperienceLocationReducer';
import { experienceServicesReducer } from 'experience/redux/reducers/ExperienceServicesReducer';
import { experienceServiceGroupsReducer } from 'experience/redux/reducers/EcperienceServiceGroupsReducer';
import { experienceEmployeesReducer } from 'experience/redux/reducers/ExperienceEmployeesReducer';
import { experienceUserLocationReducer } from 'experience/redux/reducers/ExpierenceUserLocationReducer';
import {
  experienceReservationDatesReducer,
  experienceTimetableReducer,
} from 'experience/redux/reducers/ExperienceTimetableReducer';
import { reportsByServicesReducer, reportsByUsersReducer } from 'reducers/ReportsReducer';
//state types
import { TUserState } from '../types/User';
import { TCompanyState } from '../types/Companies';
import { TEmployeeState } from '../types/Employees';
import { TLocationState } from '../types/Locations';
import { TServicesState } from '../types/Services';
import { TServiceGroupState } from '../types/ServiceGroups';
import { TResourceState } from '../types/Resources';
import { TClientState } from '../types/Clients';
import { clientsReducer } from '../reducers/ClientsReducer';
import { IVacationState } from '../types/Vacations';
import { TReservationState } from '../types/Reservations';
import { reservationsReducer } from '../reducers/ReservationsReducer';
import { IWorkTimeState } from '../types/WorkTime';
import { workTimeReducer } from '../reducers/WorkTiimeReducer';
import { ICustomWorkTimeState } from '../types/CustomWorkTime';
import { customWorkTimeReducer } from '../reducers/CustomWorkTiimeReducer';
import { reservationSettingsReducer } from '../reducers/ReservationSettingsReducer';
import { IReservationSettingsState } from '../types/ReservationSettings';
import { IExperienceWidgetState } from '../types/ExperienceWidget';
import { INotificationsState } from '../types/Notifications';
import { IExperienceCompanyState } from 'experience/types/ExperienceCompany';
import { IExperienceLocationState } from 'experience/types/ExperienceLocation';
import { IExperienceServicesState } from 'experience/types/ExperienceServices';
import { IExperienceServiceGroupsState } from 'experience/types/ExperienceServiceGroups';
import { IExperienceEmployeesState } from 'experience/types/ExperienceEmployees';
import {
  IExperienceReservationDatesState,
  IExperienceTimetableState,
} from 'experience/types/ExperienceTimetable';
import { TReportsByServicesState, TReportsByUsersState } from 'types/Reports';
import { IUserLocationState } from 'experience/types/ExperienceUserLocation';
import { IExperienceWidgetMultipeState } from 'types/ExperienceWidgetMultiple';

export interface IAppState {
  appState: any;
  userState: TUserState;
  companyState: TCompanyState;
  employeeState: TEmployeeState;
  locationState: TLocationState;
  servicesState: TServicesState;
  servicesGroupState: TServiceGroupState;
  resourcesState: TResourceState;
  vacationsState: IVacationState;
  clientsState: TClientState;
  reservationsState: TReservationState;
  workTimeState: IWorkTimeState;
  customWorkTimeState: ICustomWorkTimeState;
  reservationSettingsState: IReservationSettingsState;
  experienceWidgetState: IExperienceWidgetState;
  experienceWidgetMultipeState: IExperienceWidgetMultipeState;
  notificationsState: INotificationsState;
  reportsByUsersState: TReportsByUsersState;
  reportsByServicesState: TReportsByServicesState;
  experienceCompanyState: IExperienceCompanyState;
  experienceLocationState: IExperienceLocationState;
  experienceServicesState: IExperienceServicesState;
  experienceServiceGroupsState: IExperienceServiceGroupsState;
  experienceEmployeesState: IExperienceEmployeesState;
  experienceTimetableState: IExperienceTimetableState;
  experienceReservationDatesState: IExperienceReservationDatesState;
  experienceUserLocation: IUserLocationState;
  utilsState: IUtilsState;
}

const initialAppState: DeepPartial<IAppState> = {
  appState: undefined,
  userState: undefined,
  companyState: undefined,
  employeeState: undefined,
  locationState: undefined,
  servicesState: undefined,
  servicesGroupState: undefined,
  resourcesState: undefined,
  clientsState: undefined,
  vacationsState: undefined,
  reservationsState: undefined,
  workTimeState: undefined,
  customWorkTimeState: undefined,
  reservationSettingsState: undefined,
  experienceWidgetState: undefined,
  experienceWidgetMultipeState: undefined,
  notificationsState: undefined,
  reportsByUsersState: undefined,
  reportsByServicesState: undefined,
  experienceCompanyState: undefined,
  experienceLocationState: undefined,
  experienceServicesState: undefined,
  experienceServiceGroupsState: undefined,
  experienceEmployeesState: undefined,
  experienceTimetableState: undefined,
  experienceReservationDatesState: undefined,
  experienceUserLocation: undefined,
  utilsState: undefined,
};

const rootReducer = combineReducers<IAppState>({
  appState: requestReducer,
  userState: usersReducer,
  companyState: companiesReducer,
  employeeState: employeesReducer,
  locationState: locationsReducer,
  servicesState: servicesReducer,
  servicesGroupState: serviceGroupReducer,
  resourcesState: resourcesReducer,
  vacationsState: vacationsReducer,
  clientsState: clientsReducer,
  reservationsState: reservationsReducer,
  workTimeState: workTimeReducer,
  customWorkTimeState: customWorkTimeReducer,
  reservationSettingsState: reservationSettingsReducer,
  experienceWidgetState: experienceWidgetReducer,
  experienceWidgetMultipeState: experienceWidgetMultipleReducer,
  notificationsState: notificationsReducer,
  reportsByUsersState: reportsByUsersReducer,
  reportsByServicesState: reportsByServicesReducer,
  experienceCompanyState: experienceCompanyReducer,
  experienceLocationState: experienceLocationReducer,
  experienceServicesState: experienceServicesReducer,
  experienceServiceGroupsState: experienceServiceGroupsReducer,
  experienceEmployeesState: experienceEmployeesReducer,
  experienceTimetableState: experienceTimetableReducer,
  experienceReservationDatesState: experienceReservationDatesReducer,
  experienceUserLocation: experienceUserLocationReducer,
  utilsState: utilsReducer,
});

export default function configureStore(): Store<IAppState, AnyAction> {
  return createStore(
    rootReducer,
    initialAppState,
    composeWithDevTools(applyMiddleware(thunk)),
  );
}
