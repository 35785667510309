import React, { useRef, useState } from 'react';
import './LanguageSelect.scss';
import { EXPERIENCE_LANG_ITEM } from 'constants/Storage';
import { useTranslation } from 'react-i18next';
import ArrowDown from 'assets/images/arrow-down.svg';
import { EXPERIENCE_LANGUAGES } from 'constants/Experience';
import clsx from 'clsx';
import useDetectClickOutside from 'hooks/useDeteckClickOutside';

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const currentLanguage = localStorage.getItem(EXPERIENCE_LANG_ITEM) || 'lt';
  const optionsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useDetectClickOutside({
    ref: optionsRef,
    onClickOutside: () => setOpen(false),
    excludedRefs: [wrapperRef],
  });

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem(EXPERIENCE_LANG_ITEM, lang);
    setOpen(false);
  };

  return (
    <div className='position-relative'>
      <div
        onClick={() => setOpen(prev => !prev)}
        ref={wrapperRef}
        className='landing__language-selector d-flex align-items-center'
      >
        <p className='landing__current-language'>{currentLanguage.toUpperCase()}</p>
        <img className='arrow' src={ArrowDown} alt='arrow down' />
      </div>
      <div
        ref={optionsRef}
        className={clsx('landing__language-options', {
          'landing__language-options--open': open,
        })}
      >
        {EXPERIENCE_LANGUAGES.map(lang => (
          <p
            key={lang}
            className='landing__language-option'
            onClick={() => handleLanguageChange(lang)}
          >
            {lang.toUpperCase()}
          </p>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelect;
