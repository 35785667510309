import { AxiosResponse } from 'axios';
import {
  ServiceGroupsGet,
  ServiceGroupAdd,
  ServiceGroupEdit,
  ServiceGroupGetAll,
  ServiceGroupDelete,
  TServiceGroupsGetAction,
  TServiceGroupsGetAllAction,
  IGetServiceGroups,
  IServiceGroupData,
} from '../types/ServiceGroups';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';

export const getAllServiceGroups = () => {
  return apiCall<TServiceGroupsGetAllAction, null, IGetServiceGroups>(
    ServiceGroupGetAll,
    'GET',
    API_END_POINTS.GET_SERVICE_GROUPS,
    true,
    null,
    { limit: 1000 },
  );
};

export const getServiceGroups = (offset: number, searchQuery?: string, sort?: string) => {
  return apiCall<TServiceGroupsGetAction, null, IGetServiceGroups>(
    ServiceGroupsGet,
    'GET',
    API_END_POINTS.GET_SERVICE_GROUPS,
    true,
    null,
    {
      limit: PAGE_LIMIT,
      offset,
      query: searchQuery,
      Sort: sort,
    },
  );
};

export const addServiceGroupToStore = (groupId: string, group: IServiceGroupData) => {
  return {
    type: ServiceGroupAdd.ADD_SERVICE_GROUP_STORE,
    payload: { ...group, id: groupId },
  };
};

export const addServiceGroup = (serviceGroup: IServiceGroupData) => {
  return async (dispatch: TDispatch<ServiceGroupAdd>): Promise<AxiosResponse<string>> => {
    dispatch({
      type: ServiceGroupAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_SERVICE_GROUP,
        data: {
          name: serviceGroup.name,
          companyId: serviceGroup.companyId,
        },
      });
      dispatch({
        type: ServiceGroupAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        dispatch(addServiceGroupToStore(response.data, serviceGroup));
      }
      return response;
    } catch (error) {
      dispatch({
        type: ServiceGroupAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedServiceGroupToStore = (
  group: IServiceGroupData,
  groupId: string,
) => {
  return {
    type: ServiceGroupEdit.EDIT_SERVICE_GROUP_STORE,
    payload: { ...group, id: groupId },
  };
};

export const editServiceGroup = (group: IServiceGroupData, groupId: string) => {
  return async (dispatch: TDispatch<ServiceGroupEdit>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ServiceGroupEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_SERVICE_GROUP(groupId),
        data: {
          name: group.name,
          companyId: group.companyId,
        },
      });
      dispatch({
        type: ServiceGroupEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedServiceGroupToStore(group, groupId));
      return response;
    } catch (error) {
      dispatch({
        type: ServiceGroupEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const deleteServiceGroup = (groupId: string) => {
  return async (
    dispatch: TDispatch<ServiceGroupDelete>,
  ): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ServiceGroupDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'DELETE',
        url: API_END_POINTS.DELETE_SERVICE_GROUP(groupId),
        data: {},
      });
      dispatch({
        type: ServiceGroupDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: ServiceGroupDelete.DELETE_SERVICE_GROUP_STORE,
        payload: groupId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ServiceGroupDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
