import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FaExclamationTriangle } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import FormRadio from 'components/Form/FormRadio';
import { IEmployee } from 'types/Employees';
import { getEmployeeProfilePicture } from 'actions/EmployeesActions';
import './ReservationFormEmployees.scss';

interface IReservationFormEmployees {
  employees: IEmployee[];
  selectedUserId?: string;
  setSelectedUserId: (id: string) => void;
  serviceIsSelected: boolean;
}

const ReservationFormEmployees: FC<IReservationFormEmployees> = ({
  employees,
  selectedUserId,
  setSelectedUserId,
  serviceIsSelected,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const required = (value: string) => (value ? undefined : t('required'));

  const [profilePictures, setProfilePictures] = useState<any>({});

  useEffect(() => {
    if (!employees.length || !dispatch) return;
    const avatars: typeof profilePictures = {};

    (async () => {
      await Promise.all(
        employees.map(async employee => {
          try {
            // create temporary img url from blob for web client
            const response: any = await dispatch(getEmployeeProfilePicture(employee.id));
            avatars[employee.id] = URL.createObjectURL(response?.data);
          } catch (e) {
            return null;
          }
        }),
      );

      setProfilePictures(avatars);
    })();
  }, [dispatch]);

  return (
    <div className='rf-employees'>
      {employees.length <= 0 && serviceIsSelected && (
        <Form.Text as='span'>
          <FaExclamationTriangle className='mr-1' />
          {t('reservation_service_no_users_for_location')}
        </Form.Text>
      )}
      {employees.length > 0 && serviceIsSelected && (
        <div
          className={
            selectedUserId === 'auto'
              ? 'rf-employees__item random active'
              : 'rf-employees__item random'
          }
        >
          <FormRadio
            size='sm'
            id='auto'
            name='userId'
            label={t('reservation_auto_employee_title')}
            value='auto'
            validation={required}
            onChange={() => setSelectedUserId('auto')}
          />
        </div>
      )}

      {employees.map(emp => (
        <div
          className={
            selectedUserId === emp.id ? 'rf-employees__item active' : 'rf-employees__item'
          }
          key={emp.id}
          onClick={() => {
            setSelectedUserId(emp.id);
          }}
        >
          <FormRadio
            size='sm'
            id={emp.id}
            name='userId'
            label={emp.name}
            value={emp.id}
            validation={required}
            showAvatar
            avatarUrl={profilePictures[emp.id]}
          />
        </div>
      ))}
    </div>
  );
};

export default ReservationFormEmployees;
