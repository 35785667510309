import { AxiosError } from 'axios';
import { TResetAction } from 'types/App';
import { SettingsCurrencies } from 'types/ReservationSettings';

export interface ICompanyExperience {
  name: string;
  locale: string;
  currency: SettingsCurrencies;
  maxReservationTimeInFuture: number;
  reservationGroupsEnabled: boolean;
}

export interface IExperienceCompanyState {
  readonly data?: ICompanyExperience;
  readonly isLoading?: boolean;
}

export const initialExperienceCompanyState: IExperienceCompanyState = {
  data: undefined,
  isLoading: undefined,
};

export interface ICompanyGetExperience {
  data: ICompanyExperience;
  status: number;
  statusText: string;
}

export enum CompanyGetExperience {
  REQUEST = 'COMPANY_GET_EXPERIENCE_REQUEST',
  SUCCESS = 'COMPANY_GET_EXPERIENCE_SUCCESS',
  FAILED = 'COMPANY_GET_EXPERIENCE_FAILED',
}

export type TCompanyGetExperienceAction =
  | {
      type: CompanyGetExperience.REQUEST;
    }
  | {
      type: CompanyGetExperience.SUCCESS;
      payload: ICompanyGetExperience;
    }
  | {
      type: CompanyGetExperience.FAILED;
      payload: AxiosError;
    }
  | TResetAction;
