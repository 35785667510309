import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { IoClose } from 'react-icons/io5';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ModalBasicButtons from './ModalBasicButtons';
import useGetDataForRedux from 'hooks/useGetDataForRedux';
import useError from 'hooks/useError';
import { getAllResources } from 'actions/ResourcesActions';
import { IFormError } from 'constants/Interfaces';
import { useStoreState } from 'hooks/useStoreState';
import FormErrorText from 'components/Form/FormErrorText';
import './TabsModal.scss';

interface ITab {
  key: string;
  component: JSX.Element;
  hasError: boolean;
}

interface ITabsModal {
  show: boolean;
  modalTitle: string;
  handleClose: () => void;
  modalTabs: ITab[];
  defaultActive: string;
  validation: (values: any) => Record<string, any>;
  handleSubmit: (values: any) => void;
  editValue?: Record<string, any>;
  handleDelete?: () => void;
  customButton?: any;
  onTabChange?: (tab: any) => void;
  customDialogClass?: string;
  activeTab: string | undefined;
  setActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const TabsModals = React.memo(
  ({
    show,
    handleClose,
    modalTitle,
    modalTabs,
    defaultActive,
    validation,
    handleSubmit,
    editValue,
    handleDelete,
    customButton,
    onTabChange,
    customDialogClass,
    activeTab,
    setActiveTab,
  }: ITabsModal) => {
    const { t } = useTranslation();
    const [error, setError] = useState<IFormError>();
    useError(error, setError);

    const resourcesState = useStoreState().resourcesState;
    useGetDataForRedux(setError, resourcesState.allData, getAllResources());

    const modalClose = useCallback(() => {
      setActiveTab(undefined);
      handleClose?.();
    }, []);

    const modalSubmit = async (values: any) => {
      await handleSubmit?.(values);
    };

    const isResourcesTabDisabled = useCallback(
      (values: any) => {
        if (!resourcesState.allData) return false;

        return !resourcesState.allData.some(res =>
          values?.locations?.includes(res.locationId),
        );
      },
      [resourcesState.allData],
    );

    return (
      <Modal
        show={show}
        onHide={modalClose}
        dialogClassName={`modal-lg ${customDialogClass}`}
      >
        <Modal.Header closeButton>
          <Modal.Title className='title'>{modalTitle}</Modal.Title>
        </Modal.Header>
        <FinalForm
          onSubmit={modalSubmit}
          initialValues={editValue}
          mutators={{
            // expect (field, value) args from the mutator
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          validate={(values: any) => {
            const errors = validation(values);
            // map validated errors object and spread the object to return it for react-final-form
            const keys = Object.keys(errors);
            let fullError: Record<string, any> = {};
            keys.forEach(key => (fullError = { ...fullError, ...errors[key] }));
            return fullError;
          }}
          render={({
            form,
            handleSubmit,
            errors,
            form: {
              mutators: { push, pop },
            },
            submitting,
            ...rest
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Tabs
                    id='controlled-tab'
                    activeKey={!activeTab ? defaultActive : activeTab}
                    onSelect={k => {
                      k && setActiveTab(k);
                      onTabChange?.(k);
                    }}
                  >
                    {modalTabs.map(tab => (
                      <Tab
                        eventKey={tab.key}
                        title={tab.key}
                        className='modal-tab'
                        key={tab.key}
                        disabled={
                          tab.key === t('service_create_tab_resources')
                            ? isResourcesTabDisabled(rest.values)
                            : false
                        }
                        tabClassName={
                          tab.hasError && rest.submitFailed ? 'text-danger' : ''
                        }
                      >
                        {React.cloneElement(tab.component, {
                          errors,
                          submitFailed: rest.submitFailed,
                          setValue: form.mutators.setValue,
                          push,
                          pop,
                          values: rest.values,
                          selectedResources: rest.values?.resourceIds,
                        })}
                      </Tab>
                    ))}
                  </Tabs>
                </Modal.Body>
                <Modal.Footer
                  className={
                    editValue ? 'justify-content-between' : 'justify-content-end'
                  }
                >
                  {editValue && (
                    <div className='left-container d-flex'>
                      <Button
                        variant='danger'
                        onClick={handleDelete}
                        disabled={submitting}
                        className='grey-borders centerIcon btn-sm mr-2'
                      >
                        <IoClose />
                        {t('form_button_delete')}
                      </Button>
                      {/* Creates custom button from props */}
                      {customButton &&
                        React.cloneElement(customButton, {
                          submitting,
                        })}
                    </div>
                  )}
                  <div>
                    <p>
                      {rest.submitFailed && (
                        <FormErrorText errorText={t('notAllFields')} />
                      )}
                    </p>
                    {/* close and save buttons */}
                    <ModalBasicButtons handleClose={modalClose} submitting={submitting} />
                  </div>
                </Modal.Footer>
              </form>
            );
          }}
        />
      </Modal>
    );
  },
);

TabsModals.displayName = 'TabsModals';

export default TabsModals;
