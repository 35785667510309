import React, { memo } from 'react';
import './Hero.scss';
import Button from 'landing/components/Button';
import HeroImage from 'assets/images/landing-hero.png';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'constants/Routes';
import { useTranslation } from 'react-i18next';

const LandingHero = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleLogin = () => {
    history.push(ROUTES.LOGIN);
  };
  return (
    <section className='landing__hero-section'>
      <div className='landing__container'>
        <div className='landing__hero-wrapper landing__hero-section__content-wrapper d-flex justify-content-between'>
          <div className='landing__hero-section__left-side'>
            <h2>{t('hero_title')}</h2>
            <p>{t('hero_description')}</p>
            <Button onClick={handleLogin}>{t('landing_login')}</Button>
          </div>
          <div className='landing__hero-section__right-side'>
            <img src={HeroImage} alt='hero-section-image' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(LandingHero);
