import { AxiosResponse } from 'axios';
import {
  ServicesGet,
  ServicesGetAll,
  ServicesAdd,
  ServiceEdit,
  ServiceDelete,
  TServicesGetAction,
  TServicesGetAllAction,
  IGetServices,
  IServiceData,
  IPricingModal,
  IServiceResponseData,
} from '../types/Services';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';
import { convertToHoursAndMinutes } from 'reducers/ServicesReducer';

export const getAllServices = () => {
  return apiCall<TServicesGetAllAction, null, IGetServices>(
    ServicesGetAll,
    'GET',
    API_END_POINTS.GET_SERVICES,
    true,
    null,
    { limit: 1000, sort: 'name asc' },
  );
};

export const getServices = (
  offset: number,
  searchQuery?: string,
  groupId?: string,
  locationId?: string,
  sort?: string,
  companyId?: string,
) => {
  return apiCall<TServicesGetAction, null, IGetServices>(
    ServicesGet,
    'GET',
    API_END_POINTS.GET_SERVICES,
    true,
    null,
    {
      limit: PAGE_LIMIT,
      offset,
      query: searchQuery,
      groupId,
      locationId,
      companyId,
      sort: sort || 'name asc',
    },
  );
};

export const AddServiceToStore = (data: IServiceResponseData, service: IServiceData) => {
  return {
    type: ServicesAdd.ADD_SERVICES_STORE,
    payload: {
      ...service,
      id: data.id,
      locationIds: service.locations,
      pricings: data.pricings,
    },
  };
};

export const addService = (service: IServiceData) => {
  return async (
    dispatch: TDispatch<ServicesAdd>,
  ): Promise<AxiosResponse<IServiceResponseData>> => {
    dispatch({
      type: ServicesAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_SERVICES,
        data: {
          name: service.name,
          companyId: service.companyId,
          groupId: service.groupId,
          preparingTime: service.preparingTime,
          timeAfterService: service.timeAfterService,
          userIds: service.userIds,
          locationIds: service.locations,
          resources: service.resources,
          resourceIds: [],
          pricings: service.pricings,
        },
      });
      dispatch({
        type: ServicesAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        const pricings = response.data.pricings.map((pricing: IPricingModal) => {
          const { hours, minutes } = convertToHoursAndMinutes(pricing.duration);
          return {
            ...pricing,
            hours,
            minutes,
          };
        });
        const data = { ...response.data, pricings };
        dispatch(AddServiceToStore(data, service));
      }
      return response;
    } catch (error) {
      dispatch({
        type: ServicesAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedServiceToStore = (
  service: IServiceData,
  serviceId: string,
  pricings: IPricingModal[],
) => {
  const modifiedPricings = pricings.map(pricing => {
    const { hours, minutes } = convertToHoursAndMinutes(pricing.duration);
    return {
      ...pricing,
      hours,
      minutes,
    };
  });
  return {
    type: ServiceEdit.EDIT_SERVICE_STORE,
    payload: { ...service, id: serviceId, pricings: modifiedPricings },
  };
};

export const editService = (service: IServiceData, serviceId: string) => {
  return async (
    dispatch: TDispatch<ServiceEdit>,
  ): Promise<AxiosResponse<IServiceResponseData>> => {
    dispatch({
      type: ServiceEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_SERVICE(serviceId),
        data: {
          name: service.name,
          companyId: service.companyId,
          groupId: service.groupId,
          preparingTime: service.preparingTime,
          timeAfterService: service.timeAfterService,
          userIds: service.userIds,
          locationIds: service.locationIds,
          resourceIds: service.resourceIds,
          resources: service.resources,
          pricings: service.pricings,
          hiddenInPlugin: service.hiddenInPlugin,
          fixedPersonsCount: service.fixedPersonsCount,
        },
      });
      dispatch({
        type: ServiceEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedServiceToStore(service, serviceId, response.data.pricings));
      return response;
    } catch (error) {
      dispatch({
        type: ServiceEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const deleteService = (serviceId: string) => {
  return async (dispatch: TDispatch<ServiceDelete>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ServiceDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'DELETE',
        url: API_END_POINTS.DELETE_SERVICE(serviceId),
        data: {},
      });
      dispatch({
        type: ServiceDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: ServiceDelete.DELETE_SERVICE_STORE,
        payload: serviceId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ServiceDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
