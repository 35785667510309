import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  TReservationsActions,
  TReservationState,
  initialReservationState,
  ReservationsGet,
  ReservationDelete,
  ReservationAdd,
  ReservationEdit,
  ReservationsPut,
  IReservation,
  ReservationsGetAll,
  ReservationStatus,
  ReservationsGetAvailableTime,
  CalendarReservationsGetAll,
  ReservationsGetAvailableServiceTime,
  FilteredReservationsGet,
  FilteredReservationsSet,
} from '../types/Reservations';

export const getUpdatedClientObject = (oldClient: any, newClient: any) => {
  if (!oldClient && !newClient) {
    return {
      name: '',
      lastname: '',
      phone: '',
      comments: '',
      email: '',
    };
  }

  if (!newClient) {
    return oldClient;
  }

  return {
    name: newClient?.name || '',
    lastname: newClient?.lastname || '',
    phone: newClient?.phone || '',
    comments: newClient?.comments || '',
    email: newClient?.email || '',
  };
};

export const reservationsReducer: Reducer<TReservationState, TReservationsActions> = (
  state = initialReservationState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialReservationState;
    }
    case ReservationsGetAvailableTime.REQUEST:
    case ReservationEdit.REQUEST:
    case ReservationAdd.REQUEST:
    case ReservationDelete.REQUEST:
    case ReservationsGetAll.REQUEST:
    case CalendarReservationsGetAll.REQUEST:
    case ReservationsGetAvailableServiceTime.REQUEST:
    case FilteredReservationsGet.REQUEST:
    case ReservationsGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ReservationEdit.FAILED:
    case ReservationsGetAvailableTime.FAILED:
    case ReservationAdd.FAILED:
    case ReservationDelete.FAILED:
    case ReservationsGetAll.FAILED:
    case CalendarReservationsGetAll.FAILED:
    case ReservationsGetAvailableServiceTime.FAILED:
    case FilteredReservationsGet.FAILED:
    case ReservationsGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CalendarReservationsGetAll.SUCCESS: {
      return {
        ...state,
        calendarData: action.payload.data.data,
        isLoading: false,
        total: action.payload.data.total,
      };
    }
    case ReservationsPut.RESERVATIONS_OFFSET_UPDATE: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    case ReservationDelete.DELETE_RESERVATION_STORE: {
      return {
        ...state,
        calendarData: state.calendarData
          ? state.calendarData.map((rec: IReservation) => {
              return rec.id === action.payload
                ? { ...rec, status: ReservationStatus.CANCELLED }
                : rec;
            })
          : undefined,
        filteredReservations: state.filteredReservations
          ? state.filteredReservations.filter(rec => rec.id !== action.payload)
          : undefined,
      };
    }
    case ReservationAdd.ADD_RESERVATION_STORE: {
      return {
        ...state,
        calendarData: state.calendarData
          ? [action.payload, ...state?.calendarData]
          : [action.payload],
      };
    }
    case ReservationEdit.EDIT_RESERVATION_STORE: {
      return {
        ...state,
        calendarData: state.calendarData
          ? state.calendarData.map(rec =>
              rec.id === action.payload.id
                ? {
                    ...action.payload,
                    client:
                      action.payload.client &&
                      getUpdatedClientObject(rec.client, action.payload.client),
                  }
                : rec,
            )
          : [],
      };
    }
    case ReservationEdit.SUCCESS:
    case ReservationAdd.SUCCESS:
    case ReservationDelete.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ReservationsPut.RESERVATIONS_CLEAR_DATA: {
      return {
        ...state,
        data: undefined,
      };
    }

    case ReservationsGetAll.SUCCESS:
    case ReservationsGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isLoading: false,
        total: action.payload.data.total,
      };
    }

    case ReservationsGetAvailableTime.SUCCESS: {
      return {
        ...state,
        availableTime: action.payload.data,
        isLoading: false,
      };
    }
    case ReservationsGetAvailableServiceTime.SUCCESS: {
      return {
        ...state,
        userAvailableTime: action.payload.data,
        isLoading: false,
      };
    }
    case FilteredReservationsGet.SUCCESS: {
      return {
        ...state,
        filteredReservations: action.payload.data.data,
        isLoading: false,
      };
    }
    case FilteredReservationsSet.SET: {
      return {
        ...state,
        filteredReservations: action.payload,
      };
    }

    default:
      return state;
  }
};
