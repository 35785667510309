import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ButtonAdd from '../../components/Utils/ButtonAdd';
import { IClient } from '../../types/Clients';
import { IFormError, ISort } from '../../constants/Interfaces';
import { handleError } from '../../middlewares/ErrorHandler';
import { getClients, changeOffset } from '../../actions/ClientsActions';
import { getAllLocations } from '../../actions/LocationActions';
import TableSearch from '../../components/Utils/Tables/TableSearch';
import TableSortable from '../../components/Utils/Tables/TableSortable';
import PaginationComp from '../../components/Utils/Tables/PaginationComp';
import useError from '../../hooks/useError';
import { useStoreState } from 'hooks/useStoreState';
import { TABLE_PAGE_SIZE } from '../../constants/UtilVariables';
import AddEditClientModal from '../../components/Admin/Clients/AddEditClientModal';
import './AdminClients.scss';
import ClientReservationsListModal from '../../components/Admin/Clients/ClientReservationsListModal';
import { IReservationWithInfo } from '../../types/Reservations';
import ReservationInfoModal from '../../components/Admin/Reservations/ReservationInfoModal';
import {
  clearAllReservationsInStore,
  getFilteredReservations,
} from '../../actions/ReservationsActions';
import AddEditReservationModal from '../../components/Admin/Reservations/AddEditReservationModal';
import { emptyReservationObject } from '../../constants/UtilVariables';
import UrgentMessageModal from 'components/Admin/Reservations/UrgentMessageModal';
import { filterSameReservations } from 'helpers/Reservations';

const formateTableData = (clientData: Array<IClient>) => {
  const newTableData = [];
  for (let i = 0; i < clientData.length; i++) {
    const client = clientData[i];

    newTableData.push({
      ...client,
      fullName: `${client.name} ${client.lastname || ''}`,
    });
  }
  return newTableData;
};

const AdminClients: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [tableData, setTableData] = useState<IClient[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [addEditClientModalOpen, setAddEditClientModalOpen] = useState<boolean>(false);
  const [addEditReservationModalOpen, setAddEditReservationModalOpen] =
    useState<boolean>(false);
  const [reservationsListModalOpen, setReservationsListModalOpen] =
    useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<{
    name: string;
    lastname: string;
    phone: string;
  }>();
  const [pages, setPages] = useState<Array<number>>();
  const [activePage, setActivePage] = useState<number>(0);
  const [clientToEdit, setClientToEdit] = useState<IClient>();
  const [reservationInfoModalOpen, setReservationInfoModalOpen] =
    useState<boolean>(false);
  const [reservationToEdit, setReservationToEdit] = useState<IReservationWithInfo>();
  const [urgentMessageModalOpen, setUrgentMessageModalOpen] = useState(false);

  useError(error, setError);

  const { clientsState, locationState, reservationsState } = useStoreState();

  const sameReservations = useMemo(
    () =>
      filterSameReservations(reservationToEdit, reservationsState.filteredReservations),
    [reservationToEdit, reservationsState.filteredReservations],
  );

  useEffect(() => {
    return () => {
      dispatch(changeOffset(0));
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        //first load offset is always 0
        await dispatch(getClients(0, undefined, undefined, undefined, TABLE_PAGE_SIZE));
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getAllLocations());
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (clientsState.data && locationState.allData) {
      const formatedData = formateTableData(clientsState.data);
      setTableData(formatedData || []);
      if (clientsState.total) {
        const pages = Array.from(
          Array(Math.ceil(clientsState.total / TABLE_PAGE_SIZE)).keys(),
        );
        if (pages.length > 1) setPages(pages);
        else setPages(undefined);
      }
    }
  }, [locationState.allData, clientsState.data]);

  useEffect(() => {
    if (!reservationToEdit) return;

    dispatch(
      getFilteredReservations({
        userId: reservationToEdit?.userId,
        LocationId: reservationToEdit?.locationId,
        dateFrom: reservationToEdit?.start,
        dateTo: reservationToEdit?.end,
      }),
    );
  }, [reservationToEdit, dispatch]);
  const isMultiReservation = (reservationsState.filteredReservations?.length || 0) > 1;

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('client_table_col_name')}`,
        accessor: 'fullName',
        id: 'name',
      },
      {
        Header: `${t('client_table_col_email')}`,
        accessor: 'email',
        id: 'email',
      },
      {
        Header: `${t('client_table_col_phone')}`,
        accessor: 'phone',
        id: 'phone',
      },
      {
        Header: `${t('client_table_col_gender')}`,
        accessor: 'gender',
        id: 'gender',
        Cell: (cell: any) => {
          if (cell.value) {
            return t(`client_gender_${cell.value.toLowerCase()}`);
          }
          return null;
        },
      },
    ],
    [tableData],
  );

  const handleSort = useCallback(async (sortBy: Array<ISort>, offset) => {
    try {
      if (sortBy[0].id) {
        const sortDirection = sortBy[0].desc ? 'desc' : 'asc';
        const sortQuery = `${sortBy[0].id} ${sortDirection}`;
        await dispatch(
          getClients(offset || 0, searchQuery, '', sortQuery, TABLE_PAGE_SIZE),
        );
      }
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  }, []);

  const handleSearch = async () => {
    try {
      setSearchQuery(searchQuery);
      await dispatch(
        getClients(
          clientsState.offset || 0,
          searchQuery,
          undefined,
          undefined,
          TABLE_PAGE_SIZE,
        ),
      );
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const changePage = async (newPage: number) => {
    setActivePage(newPage);
    dispatch(changeOffset(newPage * TABLE_PAGE_SIZE));
    await dispatch(
      getClients(
        newPage * TABLE_PAGE_SIZE,
        searchQuery,
        undefined,
        undefined,
        TABLE_PAGE_SIZE,
      ),
    );
  };

  const handleClientEdit = () => {
    setAddEditClientModalOpen(true);
    setReservationsListModalOpen(false);
    setReservationInfoModalOpen(false);
  };

  const handleClientAdd = () => {
    setClientToEdit(undefined);
    setAddEditClientModalOpen(true);
  };

  const handleReservationEdit = () => {
    setAddEditReservationModalOpen(true);
    setReservationInfoModalOpen(false);
  };

  const handleReservationCopy = () => {
    setReservationToEdit(
      reservationToEdit
        ? {
            ...reservationToEdit,
            id: '',
          }
        : undefined,
    );
    setAddEditReservationModalOpen(true);
    setReservationInfoModalOpen(false);
  };

  const clearReservationsInStore = async () => {
    if (reservationsState.data) {
      try {
        await dispatch(clearAllReservationsInStore());
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    }
  };

  const handleUrgentMessage = () => {
    setUrgentMessageModalOpen(true);
    setReservationInfoModalOpen(false);
  };

  const closeUrgentMessageModal = () => {
    setUrgentMessageModalOpen(false);
    setReservationInfoModalOpen(true);
  };

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='mainCard'>
            <Card.Header className='title'>{t('client_table_page_title')}</Card.Header>
            <Card.Body>
              <Row className='align-items-start'>
                <Col sm={12} md={7} lg={6} xl={3}>
                  <TableSearch
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    handleSearch={handleSearch}
                  />
                </Col>

                <Col sm={12} md={5} lg={6} xl={9} className='d-flex justify-content-end'>
                  <ButtonAdd
                    text={t('client_btn_create_client')}
                    handleClick={() => setAddEditClientModalOpen(true)}
                  />
                </Col>
              </Row>
              <Row className='mt-4 mt-md-0'>
                <Col sm={12}>
                  <TableSortable
                    striped
                    data={tableData}
                    columns={columns}
                    onSort={handleSort}
                    selectRow={setClientToEdit}
                    customWidth='30%'
                    applyCustomWidthTo={['name', 'email', 'phone']}
                    openModal={setReservationsListModalOpen}
                    isLoading={clientsState.isLoading && locationState.isLoading}
                    offset={clientsState.offset}
                  />
                  <PaginationComp
                    activePage={activePage}
                    pages={pages}
                    changePage={changePage}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddEditClientModal
        setSelectedClient={setSelectedClient}
        show={addEditClientModalOpen}
        clientToEdit={clientToEdit}
        setClientToEdit={setClientToEdit}
        handleClose={() => {
          setAddEditClientModalOpen(false);
          clearReservationsInStore();
          setReservationsListModalOpen(true);
        }}
      />
      <ClientReservationsListModal
        show={reservationsListModalOpen}
        clientToEdit={clientToEdit}
        handleClientEdit={handleClientEdit}
        setReservationToEdit={setReservationToEdit}
        setReservationInfoModalOpen={() => {
          setReservationsListModalOpen(false);
          setReservationInfoModalOpen(true);
        }}
        handleClose={() => {
          setReservationsListModalOpen(false);
          setClientToEdit(undefined);
        }}
      />
      <ReservationInfoModal
        show={reservationInfoModalOpen}
        clientToEdit={clientToEdit}
        reservationToEdit={reservationToEdit}
        handleClientEdit={handleClientEdit}
        handleReservationEdit={handleReservationEdit}
        handleReservationCopy={handleReservationCopy}
        clearReservationsInStore={clearReservationsInStore}
        handleClose={() => {
          setReservationInfoModalOpen(false);
          setReservationToEdit(undefined);
          setReservationsListModalOpen(true);
        }}
        isMultiReservation={isMultiReservation}
        displayUrgentMessage
        handleUrgentMessage={handleUrgentMessage}
      />
      {addEditReservationModalOpen && (
        <AddEditReservationModal
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          show={addEditReservationModalOpen}
          setReservationToEdit={setReservationToEdit}
          reservationToEdit={reservationToEdit || emptyReservationObject}
          handleClientAdd={handleClientAdd}
          clearReservationsInStore={clearReservationsInStore}
          handleClose={() => {
            setAddEditReservationModalOpen(false);
            setClientToEdit(undefined);
          }}
          setClientToEdit={setClientToEdit}
          isMultiReservation={isMultiReservation}
          isTimeCheckSkippable={false}
        />
      )}
      {urgentMessageModalOpen && (
        <UrgentMessageModal
          show={urgentMessageModalOpen}
          handleClose={closeUrgentMessageModal}
          reservations={sameReservations}
        />
      )}
    </Container>
  );
};

export default AdminClients;
