import SynchronizationForm from 'components/Admin/Employees/SynchronizationForm';
import React from 'react';
import './LocationTab.scss';

interface ISynchroForm {
  employeeId?: string;
}

const SynchronizatioTab: React.FC<ISynchroForm> = ({ employeeId }) => {
  return <SynchronizationForm employeeId={employeeId} />;
};

export default SynchronizatioTab;
