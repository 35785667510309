import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { ILocation } from '../../types/Locations';
import { IServices } from '../../types/Services';
import { IServiceGroup } from '../../types/ServiceGroups';
import { getAllLocations } from '../../actions/LocationActions';
import { getAllServiceGroups } from '../../actions/ServiceGroupsActions';
import { getServices } from '../../actions/ServicesActions';
import ServiceTableUtils from '../../components/Admin/Services/ServiceTableUtils';
import ServiceGroupTable from '../../components/Admin/Services/ServiceGroupTable';
import ServiceGroupModalWrapper from '../../components/Admin/Services/ServiceGroupModalWrapper';
import ServiceModalWrapper from '../../components/Admin/Services/ServiceModalWrapper';
import useError from '../../hooks/useError';
import { IFormError, IServiceTableRow } from '../../constants/Interfaces';
import { useStoreState } from 'hooks/useStoreState';
import { handleError } from 'middlewares/ErrorHandler';
import './AdminServices.scss';
import { getInitialPrice } from 'helpers/Currency';
import { getAllResources } from 'actions/ResourcesActions';
import { getCompanyById } from 'actions/CompaniesActions';
import { TUserState } from 'types/User';
import { IAppState } from 'store/Store';

const formateServicePrice = (
  service: IServiceTableRow,
  currentService: IServiceTableRow,
) => {
  const serviceReference = currentService;
  if (service.pricings.length === 1) {
    const pricing = service.pricings[0];
    serviceReference.singlePriceId = pricing.id;
    serviceReference.singlePriceDuration = pricing.duration;
    serviceReference.name = serviceReference.name || pricing.name!;
    serviceReference.singlePricePrice = getInitialPrice(pricing.salePrice, pricing.price);
  }
  return serviceReference;
};

const formateTableData = (locations: ILocation[], services: IServices[]) => {
  const newData: IServiceTableRow[] = [];
  for (let i = 0; i < services.length; i++) {
    const service: IServiceTableRow = { ...services[i] };
    const foundLocations: string[] = [];
    const serviceLocationIds = services[i].locationIds;
    for (let j = 0; j < serviceLocationIds.length; j++) {
      const foundLocation = locations.find(
        location => location.id === serviceLocationIds[j],
      );
      if (foundLocation) {
        foundLocations.push(foundLocation.name);
      }
    }
    service.locationNames = foundLocations;
    const formedService = formateServicePrice(services[i], service);
    newData.push(formedService);
  }
  return newData;
};

const AdminServices = React.memo(() => {
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [tableData, setTableData] = useState<IServices[]>([]);
  const [groupModalOpen, setGroupModalOpen] = useState<boolean>(false);
  const [serviceModalOpen, setServiceModalOpen] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IServiceGroup>();
  const [groupId, setGroupId] = useState<string>();
  const [selectedService, setSelectedService] = useState<IServices>();
  const [isLoading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<string>('');

  const { locationState, servicesState, servicesGroupState } = useStoreState();
  const dispatch = useDispatch();
  useError(error, setError);

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  // not using useGetDataForRedux here - its check for current state does not update info when superviewer switches between companies
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch(getServices(0));
        await dispatch(getAllServiceGroups());
        await dispatch(getAllLocations());
        await dispatch(getAllResources());
        setLoading(false);
      } catch (err) {
        setLoading(false);
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (!userState.data || !userState.data.companyId) return;
      try {
        await dispatch(getCompanyById(userState.data.companyId));
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [dispatch, userState.data]);

  useEffect(() => {
    if (locationState.allData && servicesState.data) {
      const tableData = formateTableData(locationState.allData, servicesState.data);
      setTableData(tableData);
    }
  }, [locationState.allData, servicesState.data]);

  const handleGroupModalOpen = useCallback((group?: IServiceGroup) => {
    setGroupModalOpen(true);
    setSelectedGroup(group || undefined);
  }, []);

  const handleServiceModalOpen = useCallback(
    (serviceGroupId?: string, service?: IServices) => {
      setServiceModalOpen(true);
      setGroupId(serviceGroupId || undefined);
      setSelectedService(service || undefined);
    },
    [],
  );

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='mainCard'>
            <Card.Header className='title-big'>{t('service_table_header')}</Card.Header>
            <Card.Body>
              <ServiceTableUtils
                setLoading={setLoading}
                selectOptions={locationState.allData || []}
                setGroupModalOpen={handleGroupModalOpen}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
              <Row className='mt-4 mt-md-0'>
                {!isLoading ? (
                  servicesGroupState.allData &&
                  servicesGroupState.allData.map(group => (
                    <ServiceGroupTable
                      group={group}
                      isLoading={isLoading}
                      key={group.id}
                      data={tableData.filter(service => service.groupId === group.id)}
                      handleGroupModalOpen={handleGroupModalOpen}
                      handleServiceModalOpen={handleServiceModalOpen}
                      selectedLocation={selectedLocation}
                    />
                  ))
                ) : (
                  <div className='flex services-loader'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {groupModalOpen && (
        <ServiceGroupModalWrapper
          show
          handleClose={() => {
            setGroupModalOpen(false);
            setSelectedGroup(undefined);
          }}
          selectedGroup={selectedGroup}
        />
      )}
      {serviceModalOpen && (
        <ServiceModalWrapper
          show
          handleClose={() => {
            setServiceModalOpen(false);
            setSelectedService(undefined);
            setGroupId(undefined);
          }}
          group={groupId}
          selectedService={selectedService}
          setSelectedLocation={setSelectedLocation}
        />
      )}
    </Container>
  );
});

AdminServices.displayName = 'AdminServices';

export default AdminServices;
