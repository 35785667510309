import { AxiosError } from 'axios';
import { TResetAction } from 'types/App';

export interface IExperienceTimetableState {
  readonly data?: Array<string>;
  readonly isLoading?: boolean;
}

export const initialExperienceTimetableState: IExperienceTimetableState = {
  data: undefined,
  isLoading: undefined,
};

export interface ITimetableGetExperience {
  data: Array<string>;
  status: number;
  statusText: string;
}

export enum TimetableGetExperience {
  REQUEST = 'TIMETABLE_GET_EXPERIENCE_REQUEST',
  SUCCESS = 'TIMETABLE_GET_EXPERIENCE_SUCCESS',
  FAILED = 'TIMETABLE_GET_EXPERIENCE_FAILED',
}

export interface IExperienceReservationDatesState {
  readonly data?: Array<string>;
  readonly isLoading?: boolean;
}

export const initialExperienceReservationDatesState: IExperienceReservationDatesState = {
  data: undefined,
  isLoading: undefined,
};

export interface IReservationDatesGetExperience {
  data: Array<string>;
  status: number;
  statusText: string;
}

export enum ReservationDatesGetExperience {
  REQUEST = 'RESERVATION_DATES_GET_EXPERIENCE_REQUEST',
  SUCCESS = 'RESERVATION_DATES_GET_EXPERIENCE_SUCCESS',
  FAILED = 'RESERVATION_DATES_GET_EXPERIENCE_FAILED',
}

export type TTimetableGetExperienceAction =
  | {
      type: TimetableGetExperience.REQUEST;
    }
  | {
      type: TimetableGetExperience.SUCCESS;
      payload: ITimetableGetExperience;
    }
  | {
      type: TimetableGetExperience.FAILED;
      payload: AxiosError;
    }
  | TResetAction;

export type TReservationDatesGetExperienceAction =
  | {
      type: ReservationDatesGetExperience.REQUEST;
    }
  | {
      type: ReservationDatesGetExperience.SUCCESS;
      payload: IReservationDatesGetExperience;
    }
  | {
      type: ReservationDatesGetExperience.FAILED;
      payload: AxiosError;
    }
  | TResetAction;
