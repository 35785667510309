import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  LocationGetExperience,
  IExperienceLocationState,
  initialExperienceLocationState,
  TLocationGetExperienceAction,
} from 'experience/types/ExperienceLocation';

export const experienceLocationReducer: Reducer<
  IExperienceLocationState,
  TLocationGetExperienceAction
> = (state = initialExperienceLocationState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialExperienceLocationState;
    }

    case LocationGetExperience.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LocationGetExperience.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };

    case LocationGetExperience.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
