import React from 'react';
import ReactTooltip from 'react-tooltip';
import { EventContentArg } from '@fullcalendar/react';
import { ReservationTypes } from '../../../types/Reservations';
import './EventsContent.scss';
import { EventCard } from './EventContent/EventCard';
import { EventTooltip } from './EventContent/EventTooltip';

const EventContent: React.FC<{
  eventContent: EventContentArg;
  smallCalendar?: boolean;
  isEmployee?: boolean;
}> = ({ eventContent, smallCalendar, isEmployee }) => {
  const eventInfo = eventContent.event.title
    ? JSON.parse(eventContent.event.title)
    : {
        serviceName: '-',
        clientName: '-',
        clientPhone: '-',
        price: 0,
        employeeName: '-',
        clientComments: '-',
        reservationComments: '-',
        duration: 0,
        start: '-',
        end: '-',
        totalPersonsCount: 0,
      };
  eventInfo.time = eventContent.timeText;

  if (eventContent.event._def.groupId === 'businessHours') return <div />;

  const isBlockTime = eventInfo.clientName === ReservationTypes.BLOCK;

  return (
    <>
      <EventCard
        tooltipId='event-tooltip'
        eventInfo={eventInfo}
        eventContent={eventContent}
        isBlockTime={isBlockTime}
      />
      {!smallCalendar && (
        <ReactTooltip
          id='event-tooltip'
          place='right'
          type='light'
          multiline
          borderColor='#929194'
          backgroundColor='#FFF'
          border
          getContent={(dataTip: string) => (
            <EventTooltip isEmployee={isEmployee} dataTip={dataTip} />
          )}
        />
      )}
    </>
  );
};

export default EventContent;
