import React from 'react';
import i18next from 'i18next';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { BiCalendar } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import arrayMutators from 'final-form-arrays';
import ButtonAdd from 'components/Utils/ButtonAdd';
import { API_END_POINTS } from 'constants/API';
import { getAccessToken } from 'helpers/Misc';
import FormSelect from '../../Form/FormSelect';
import { ILocation } from '../../../types/Locations';
import { IEmployee } from '../../../types/Employees';
import { COMPANY_ID_ITEM } from 'constants/Storage';
import './ReportsFilters.scss';
import { IFilterData, formatDate } from 'pages/admin/AdminReports';

interface IAllEmployees {
  id: string;
  fullName: string;
}

interface IReportsFilters {
  handleSubmit: (values: any) => void;
  locations: Partial<ILocation>[];
  employees: Array<IAllEmployees | IEmployee>;
  handleLocationChange: (id: string) => void;
  startDate?: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  endDate?: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  locationId: string;
  userId: string;
  setFilters: React.Dispatch<React.SetStateAction<IFilterData>>;
}

const ReportsFilters: React.FC<IReportsFilters> = ({
  handleSubmit,
  locations,
  employees,
  handleLocationChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  locationId,
  userId,
  setFilters,
}) => {
  const { t } = useTranslation();
  const language = i18next.language;

  const downloadFile = async (url: string) => {
    const fromDate = formatDate(startDate);
    const toDate = formatDate(endDate);
    try {
      const response = await axios.get(url, {
        responseType: 'blob', // important
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          CompanyId: localStorage.getItem(COMPANY_ID_ITEM),
        },
        params: {
          LocationId: locationId || null,
          UserId: userId,
          From: fromDate,
          To: toDate,
        },
      });

      const filename = response.headers['content-disposition']
        .split(';')[1]
        .split('=')[1];

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const endDateChange = (date: Date | [Date, Date] | null) => {
    if (!startDate) {
      setStartDate(date as Date);
    }
    setEndDate(date as Date);
  };

  const startDateChange = (date: Date | [Date, Date] | null) => {
    if (!endDate) {
      setEndDate(date as Date);
    }
    setStartDate(date as Date);
  };

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={{
        locationId: '',
        userId: '',
        startDate: '',
        endDate: '',
      }}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit, submitting, form }) => {
        return (
          <form onSubmit={handleSubmit} className='w-100'>
            <Row className='px-2 py-2'>
              <Col sm={12} md={6} xl={2} className='mb-3'>
                <div className='reports-custom-calendar'>
                  <div className='reports-calendar-icon-wrapper'>
                    <BiCalendar className='reports-calendar-icon' />
                  </div>
                  <DatePicker
                    selected={startDate}
                    onChange={startDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='yyyy-MM-dd'
                    locale={language}
                    calendarClassName='reports-picker'
                    className='reports-calendar-input'
                    placeholderText={t('filter_from')}
                  />
                </div>
              </Col>
              <Col sm={12} md={6} xl={2} className='mb-3'>
                <div className='reports-custom-calendar'>
                  <div className='reports-calendar-icon-wrapper'>
                    <BiCalendar className='reports-calendar-icon' />
                  </div>
                  <DatePicker
                    selected={endDate}
                    onChange={endDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='yyyy-MM-dd'
                    locale={language}
                    calendarClassName='reports-picker'
                    className='reports-calendar-input'
                    placeholderText={t('filter_till')}
                  />
                </div>
              </Col>
              <Col sm={12} md={6} xl={2}>
                <FormSelect
                  size='sm'
                  hasEmptyOption={false}
                  emptyOptionLabel=''
                  name='locationId'
                  options={locations}
                  optionValue='id'
                  optionDisplay='name'
                  onChange={e => {
                    handleLocationChange(e.target.value);
                    form.mutators.setValue('userId', '');
                    setFilters(prev => ({
                      ...prev,
                      locationId: e.target.value,
                      userId: '',
                    }));
                  }}
                />
              </Col>
              <Col sm={12} md={6} xl={2}>
                <FormSelect
                  size='sm'
                  hasEmptyOption={false}
                  emptyOptionLabel=''
                  name='userId'
                  options={employees}
                  optionValue='id'
                  optionDisplay='fullName'
                  onChange={e =>
                    setFilters(prev => ({ ...prev, userId: e.target.value }))
                  }
                />
              </Col>

              <Col
                sm={12}
                md={6}
                xl={3}
                className='d-flex align-items-start report-buttons'
              >
                <Button
                  className='btn-primary btn-sm mr-1 report-buttons__btn'
                  onClick={handleSubmit}
                  disabled={submitting}
                  type='submit'
                >
                  {t('report_generate_btn')}
                </Button>
                <ButtonAdd
                  text='Excel'
                  handleClick={() => downloadFile(API_END_POINTS.GET_REPORTS_EXCEL)}
                  btnSize='sm'
                  btnClass='mr-1 report-buttons__btn'
                  icon={<FaRegFileExcel className='mr-1' />}
                />

                <ButtonAdd
                  text='PDF'
                  handleClick={() => downloadFile(API_END_POINTS.GET_REPORTS_PDF)}
                  btnSize='sm'
                  btnClass='report-buttons__btn'
                  icon={<FaRegFilePdf className='mr-1' />}
                />
              </Col>
            </Row>
          </form>
        );
      }}
    />
  );
};

export default ReportsFilters;
