import React from 'react';
import './Select.scss';

interface ISelect
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  value: string;
}

const Select: React.FC<ISelect> = ({ value, children, ...props }) => {
  return (
    <select {...props} className='experience__drop-down' value={value}>
      {children}
    </select>
  );
};

export default Select;
