import { AxiosError } from 'axios';
import { TResetAction } from 'types/App';

export interface ILocationExperience {
  id: string;
  name: string;
  address: string;
  city: string;
}

export interface IExperienceLocationState {
  readonly data?: Array<ILocationExperience>;
  readonly isLoading?: boolean;
}

export const initialExperienceLocationState: IExperienceLocationState = {
  data: undefined,
  isLoading: undefined,
};

export interface ILocationGetExperience {
  data: Array<ILocationExperience>;
  status: number;
  statusText: string;
}

export enum LocationGetExperience {
  REQUEST = 'LOCATION_GET_EXPERIENCE_REQUEST',
  SUCCESS = 'LOCATION_GET_EXPERIENCE_SUCCESS',
  FAILED = 'LOCATION_GET_EXPERIENCE_FAILED',
}

export type TLocationGetExperienceAction =
  | {
      type: LocationGetExperience.REQUEST;
    }
  | {
      type: LocationGetExperience.SUCCESS;
      payload: ILocationGetExperience;
    }
  | {
      type: LocationGetExperience.FAILED;
      payload: AxiosError;
    }
  | TResetAction;
