import {
  CompaniesAdd,
  CompaniesDelete,
  CompaniesEdit,
  CompaniesGet,
  CompaniesGetAll,
  CompaniesGetAllUnpaginated,
  CompanyGetById,
  initialCompaniesState,
  TCompaniesActions,
  TCompanyState,
} from '../types/Companies';
import { Reducer } from 'redux';
import { ResetStore } from 'types/App';

export const companiesReducer: Reducer<TCompanyState, TCompaniesActions> = (
  state = initialCompaniesState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialCompaniesState;
    }
    case CompaniesGetAll.REQUEST:
    case CompaniesDelete.REQUEST:
    case CompaniesEdit.REQUEST:
    case CompaniesAdd.REQUEST:
    case CompanyGetById.REQUEST:
    case CompaniesGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CompaniesGetAll.FAILED:
    case CompaniesDelete.FAILED:
    case CompaniesEdit.FAILED:
    case CompaniesAdd.FAILED:
    case CompanyGetById.FAILED:
    case CompaniesGet.FAILED:
    case CompaniesGetAllUnpaginated.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CompaniesGetAll.SUCCESS:
      return {
        ...state,
        isLoading: false,
        allData: action.payload.data.data,
      };
    case CompaniesGetAllUnpaginated.SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.data.total,
        allData: state.allData
          ? [...state.allData, ...action.payload.data.data]
          : action.payload.data.data,
      };
    case CompaniesEdit.EDIT_COMPANY_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.map(rec => (rec.id === action.payload.id ? action.payload : rec))
          : [],
      };
    }
    case CompaniesEdit.COMPANIES_CHNAGE_OFFSET: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    case CompaniesDelete.DELETE_COMPANY_STORE: {
      return {
        ...state,
        data: state.data
          ? state.data.filter((rec: any) => rec.id !== action.payload)
          : [],
        total: state.total ? state.total - 1 : 0,
      };
    }
    case CompaniesDelete.SUCCESS:
    case CompaniesEdit.SUCCESS:
    case CompaniesAdd.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CompaniesAdd.ADD_COMPANY_STORE: {
      return {
        ...state,
        data: state.data ? [action.payload, ...state?.data] : [action.payload],
      };
    }
    case CompaniesGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        total: action.payload.data.total,
        isLoading: false,
      };
    }
    case CompanyGetById.SUCCESS: {
      return {
        ...state,
        userCompany: action.payload.data,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
