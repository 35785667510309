import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormInput from '../../Form/FormInput';
import { IFormError } from '../../../constants/Interfaces';

interface IModalForm {
  fieldError?: IFormError;
}

const ModalForm: React.FC<IModalForm> = ({ fieldError }) => {
  const { t } = useTranslation();
  return (
    <Row className='px-2 py-2'>
      <Col sm={12}>
        <FormInput
          label={`${t('location_form_label_name')}*`}
          name='name'
          type='text'
          fieldError={fieldError}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          label={`${t('location_form_label_phone')}*`}
          name='phone'
          type='text'
          fieldError={fieldError}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          label={`${t('location_form_label_address')}*`}
          name='address'
          type='text'
          fieldError={fieldError}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          label={`${t('location_form_label_city')}*`}
          name='city'
          type='text'
          fieldError={fieldError}
        />
      </Col>
    </Row>
  );
};

export default ModalForm;
