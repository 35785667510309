import React from 'react';
import Form from 'react-bootstrap/Form';

interface ILocationSelect {
  options: Array<any>;
  handleSearch: (e: React.ChangeEvent<{ value: string }>) => void;
  selectPlaceHolder: string;
  selectedLocation: string;
}

const LocationSelect: React.FC<ILocationSelect> = ({
  options,
  handleSearch,
  selectPlaceHolder,
  selectedLocation,
}) => {
  return (
    <Form.Group className='mt-4'>
      <Form.Control
        value={selectedLocation}
        as='select'
        className='select'
        onChange={handleSearch}
      >
        <option value=''>{selectPlaceHolder}</option>
        {options.map(item => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default LocationSelect;
