import React, { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TitleAddress from 'experience/components/TitleAddress';
import ReservationInfoItem, {
  ItemModifier,
} from 'experience/utils/reservationInfo/ReservationInfoItem';
import AcceptMessage from 'experience/utils/reservationInfo/AcceptMessage';
import Spacer from 'experience/components/Spacer';
import './ExperienceCheckout.scss';
import { IExperienceWidgetState } from 'types/ExperienceWidget';
import { IExperienceCompanyState } from 'experience/types/ExperienceCompany';
import { IAppState } from 'store/Store';
import { formatCurrency, getInitialPrice } from 'helpers/Currency';
import { IExperienceEmployeesState } from 'experience/types/ExperienceEmployees';
import { EXPERIENCE_LANG_ITEM } from 'constants/Storage';
import { ExperienceLocales } from 'constants/Experience';
import { SKIP_IDS } from 'config/shipConfig';
import { useStoreState } from 'hooks/useStoreState';
import clsx from 'clsx';

interface IExperienceCheckout {
  title: string;
}

const ExperienceCheckout: React.FC<IExperienceCheckout> = ({ title }) => {
  const { t } = useTranslation();
  const language = localStorage.getItem(EXPERIENCE_LANG_ITEM);

  const widgetStateMultiple = useStoreState().experienceWidgetMultipeState;

  const companyExperienceState: IExperienceCompanyState = useSelector<
    IAppState,
    IExperienceCompanyState
  >((state: IAppState) => state.experienceCompanyState);

  const employeeExperienceState: IExperienceEmployeesState = useSelector<
    IAppState,
    IExperienceEmployeesState
  >((state: IAppState) => state.experienceEmployeesState);

  const formatWeekday = (date: any) => {
    const numb = moment(date).day();
    return language === ExperienceLocales.LT
      ? t(`employee_calendar_weekday_${numb}`).toLowerCase()
      : t(`employee_calendar_weekday_${numb}`);
  };

  const getEmployer = (item: IExperienceWidgetState) => {
    const employee = employeeExperienceState?.data?.find(
      empl => empl.id === item.dateTimeUser?.user,
    );
    return employee
      ? `${employee.firstName} ${employee.lastName || ''}`
      : t('reservation_select_employee_all');
  };

  const formatServiceTime = (
    serviceId: string,
    duration?: number,
    serviceTimeStart?: string,
    serviceMonth?: string,
    date?: Date,
  ) => {
    const serviceTimeEnd = moment(serviceTimeStart, 'hh:mm')
      .add(duration, 'minutes')
      .format('HH:mm');

    const endTime = SKIP_IDS.includes(serviceId) ? '' : ` - ${serviceTimeEnd}`;
    return (
      <div>
        <div>
          {serviceTimeStart}
          {endTime} {t('twig_time_short_hours')}
        </div>
        <div>
          {language === ExperienceLocales.LT
            ? t(`employee_calendar_month_genitive_${serviceMonth}`).toLowerCase()
            : t(`employee_calendar_month_genitive_${serviceMonth}`)}{' '}
          {moment(date).format('D')}
          {language !== ExperienceLocales.EN ? t('employee_calendar_day_short') : ''}
        </div>
        <div>{formatWeekday(date)}</div>
      </div>
    );
  };

  const getRealTotalPrice = () => {
    const priceArray = widgetStateMultiple.selectedServices?.map(item => {
      const priceToUse = getInitialPrice(
        item?.service?.pricing.salePrice,
        item?.service?.pricing.price,
      );
      if (item && item.service && priceToUse != null) {
        return (item?.dateTimeUser?.personCount || 1) * priceToUse;
      } else {
        return item?.service?.pricing.price;
      }
    });

    const finalPrice = priceArray?.reduce((total, num) => {
      if (total === undefined || num === undefined) return 0;
      return total + num;
    }, 0);

    return finalPrice;
  };

  const renderSerivce = useMemo(() => {
    const title = t('reservation_visiting_time');
    const content = widgetStateMultiple.selectedServices?.map(
      (item, index) => {
        const arrayLength = widgetStateMultiple.selectedServices?.length || 0;
        return (
          <div
            className={clsx({
              'reservation__visiting_time--last-item': arrayLength - 1 !== index,
            })}
            key={item.serviceId}
          >
            {formatServiceTime(
              item.serviceId,
              item.service?.pricing.duration,
              item.dateTimeUser?.time,
              moment(item.dateTimeUser?.date).format('MM'),
              item.dateTimeUser?.date,
            )}
          </div>
        );
      },
      [widgetStateMultiple.selectedServices],
    );
    return (
      <ReservationInfoItem
        title={title}
        content={content}
        modifier={ItemModifier.Checkout}
      />
    );
  }, [widgetStateMultiple.selectedServices]);

  const renderEmployee = useMemo(() => {
    const title = t('reservation_form_checkout_worker_activity_type_pasiutuslape');
    const content = widgetStateMultiple.selectedServices?.map(item => {
      const employee = getEmployer(item);
      return <div key={item.serviceId}>{employee}</div>;
    });
    return (
      <ReservationInfoItem
        title={title}
        content={content}
        modifier={ItemModifier.Checkout}
      />
    );
  }, [widgetStateMultiple.selectedServices]);

  return (
    <div className='experience-checkout-wrapper'>
      <div className='experience-checkout'>
        <AcceptMessage />
        <Spacer size={1} />
        <p className='experience-checkout__info-title'>
          {t('reservation_form_checkout_info')}
        </p>
        <Spacer size={2} />
        <h2 className='experience-checkout__title'>{title}</h2>
        {widgetStateMultiple.selectedServices?.map(item => {
          return (
            <TitleAddress
              key={item.serviceId}
              addressText={`${item.location?.address}, ${item.location?.city}`}
            />
          );
        })}
        <Spacer size={2} />
        {widgetStateMultiple.selectedServices?.map(item => {
          const isReservationGroupEnabled =
            widgetStateMultiple.company?.reservationGroupsEnabled;
          const isFixedPersonsCount = !item?.service?.service.fixedPersonsCount;
          const showPeopleSelection = isReservationGroupEnabled && isFixedPersonsCount;
          return (
            <h3 className='experience-checkout__service-title' key={item.serviceId}>
              {item?.service?.service?.name}
              {showPeopleSelection && (
                <span className='experience-checkout__service-title__service-subtitle'>{`${
                  item?.dateTimeUser?.personCount
                } ${t('person_short')}`}</span>
              )}
            </h3>
          );
        })}
        <Spacer size={1} />
        {renderSerivce}
        {renderEmployee}
        <ReservationInfoItem
          title={t('reservation_show_label_price')}
          content={`${getRealTotalPrice()} ${formatCurrency(
            companyExperienceState?.data?.currency,
          )}`}
          modifier={ItemModifier.Checkout}
        />
      </div>
    </div>
  );
};

export default ExperienceCheckout;
