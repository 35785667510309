import React from 'react';

interface ISpacer {
  size?: number;
}

const Spacer = ({ size }: ISpacer) => {
  return <div style={{ height: size ? `${size}rem` : `1rem` }} />;
};

export default Spacer;
