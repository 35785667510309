import { AxiosResponse } from 'axios';
import {
  TCompaniesGetAction,
  TCompaniesGetAll,
  CompaniesGet,
  CompaniesGetAll,
  CompaniesAdd,
  CompaniesEdit,
  CompaniesDelete,
  ICompanyData,
  IGetCompanies,
  TCompanyGetByIdAction,
  CompanyGetById,
  IGetCompanyById,
  TCompaniesGetAllUnpaginated,
  CompaniesGetAllUnpaginated,
} from '../types/Companies';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';

export const getAllCompanies = () => {
  return apiCall<TCompaniesGetAll, any, IGetCompanies>(
    CompaniesGetAll,
    'GET',
    API_END_POINTS.GET_COMPANIES,
    true,
    {},
    { limit: 1000, Sort: 'name asc' },
  );
};

export const getAllCompaniesUnpaginated = ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}) => {
  return apiCall<TCompaniesGetAllUnpaginated, any, IGetCompanies>(
    CompaniesGetAllUnpaginated,
    'GET',
    API_END_POINTS.GET_COMPANIES,
    true,
    {},
    { limit: limit, offset: offset, Sort: 'name asc' },
  );
};

export const getCompanies = (
  offset: number,
  searchQuery: string,
  sort?: string,
  limit?: number,
) => {
  return apiCall<TCompaniesGetAction, any, IGetCompanies>(
    CompaniesGet,
    'GET',
    API_END_POINTS.GET_COMPANIES,
    true,
    {},
    { limit: limit || PAGE_LIMIT, offset, query: searchQuery, Sort: sort || 'name asc' },
  );
};

export const getCompanyById = (companyId: string) => {
  return apiCall<TCompanyGetByIdAction, any, IGetCompanyById>(
    CompanyGetById,
    'GET',
    API_END_POINTS.GET_COMPANY_BY_ID(companyId),
    true,
  );
};

export const changeOffset = (newOffset: number) => {
  return {
    type: CompaniesEdit.COMPANIES_CHNAGE_OFFSET,
    payload: newOffset,
  };
};

export const addNewCompanyToStore = (companyId: string, company: ICompanyData) => {
  return {
    type: CompaniesAdd.ADD_COMPANY_STORE,
    payload: {
      ...company,
      id: companyId,
    },
  };
};

export const addCompany = (company: ICompanyData) => {
  return async (dispatch: TDispatch<CompaniesAdd>): Promise<AxiosResponse<string>> => {
    dispatch({
      type: CompaniesAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_COMPANY,
        data: {
          name: company.name,
          sendSms: company.sendSms,
          smsSender: company.smsSender,
          activityType: company.activityType,
          locale: company.locale,
          reservationGroupsEnabled: company.reservationGroupsEnabled,
          maxReservationTimeInFuture: Number(company.maxReservationTimeInFuture),
          sendSmsToEmployee: company.sendSmsToEmployee,
          country: company.country,
        },
      });
      dispatch({
        type: CompaniesAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        dispatch(addNewCompanyToStore(response.data, company));
      }
      return response;
    } catch (error) {
      dispatch({
        type: CompaniesAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedCompanyToStore = (company: ICompanyData, companyId: string) => {
  return {
    type: CompaniesEdit.EDIT_COMPANY_STORE,
    payload: { ...company, id: companyId },
  };
};

export const editCompany = (company: ICompanyData, companyId: string) => {
  return async (dispatch: TDispatch<CompaniesEdit>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: CompaniesEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_COMPANY(companyId),
        data: {
          name: company.name,
          sendSms: company.sendSms,
          smsSender: company.smsSender,
          activityType: company.activityType,
          locale: company.locale,
          reservationGroupsEnabled: company.reservationGroupsEnabled,
          maxReservationTimeInFuture: Number(company.maxReservationTimeInFuture),
          sendSmsToEmployee: company.sendSmsToEmployee,
          country: company.country,
        },
      });
      dispatch({
        type: CompaniesEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedCompanyToStore(company, companyId));
      return response;
    } catch (error) {
      dispatch({
        type: CompaniesEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const deleteCompany = (companyId: string) => {
  return async (dispatch: TDispatch<CompaniesDelete>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: CompaniesDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'DELETE',
        url: API_END_POINTS.DELETE_COMPANY(companyId),
        data: {},
      });
      dispatch({
        type: CompaniesDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: CompaniesDelete.DELETE_COMPANY_STORE,
        payload: companyId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: CompaniesDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
