import { Reducer } from 'redux';
import { ResetStore } from 'types/App';

export interface IUtilsState {
  superadminCompanySearch?: string;
}

const initialState: IUtilsState = {
  superadminCompanySearch: '',
};

export const UTIL_ACTIONS = {
  SET_SUPER_ADMIN_COMPANY_SEARCH: 'utils/SET_SUPER_ADMIN_COMPANY_SEARCH',
};

export const utilsReducer: Reducer<IUtilsState, any> = (state = initialState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialState;
    }
    case UTIL_ACTIONS.SET_SUPER_ADMIN_COMPANY_SEARCH: {
      return {
        ...state,
        superadminCompanySearch: action.payload,
      };
    }
    default:
      return state;
  }
};

export const setSuperAdminCompanySearch = (payload: string) => {
  return {
    type: UTIL_ACTIONS.SET_SUPER_ADMIN_COMPANY_SEARCH,
    payload,
  };
};
