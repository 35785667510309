import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormInput from '../../Form/FormInput';
import { IFormError } from '../../../constants/Interfaces';

interface IPasswordInputGroup {
  inputColSizeSm: number;
  inputColSizeMd?: number;
  fieldError?: IFormError;
  onPasswordGroupFocus?: () => void;
}

const PasswordInputGroup: React.FC<IPasswordInputGroup> = ({
  inputColSizeSm,
  inputColSizeMd,
  fieldError,
  onPasswordGroupFocus,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col sm={inputColSizeSm} md={inputColSizeMd}>
          <FormInput
            size='sm'
            label={`${t('user_form_label_password')} *`}
            name='password'
            type='password'
            autoComplete='new-password'
            fieldError={fieldError}
            onFocus={() => onPasswordGroupFocus?.()}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={inputColSizeSm} md={inputColSizeMd}>
          <FormInput
            size='sm'
            label={`${t('user_form_label_repeat_password')} *`}
            name='passwordRepeat'
            type='password'
            autoComplete='new-password'
            fieldError={fieldError}
            onFocus={() => onPasswordGroupFocus?.()}
          />
        </Col>
      </Row>
    </>
  );
};

export default PasswordInputGroup;
