import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  IExperienceWidgetState,
  TExperienceWidgetActions,
  ExperienceWidget,
  initialExperienceWidgetState,
} from 'types/ExperienceWidget';

export const experienceWidgetReducer: Reducer<
  IExperienceWidgetState,
  TExperienceWidgetActions
> = (state = initialExperienceWidgetState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialExperienceWidgetState;
    }

    case ExperienceWidget.CHANGE_SERVICE: {
      return {
        ...state,
        service: action.payload,
      };
    }

    case ExperienceWidget.CHANGE_LOCATION: {
      return {
        ...state,
        location: action.payload,
      };
    }

    case ExperienceWidget.CHANGE_CLIENT_INFO: {
      return {
        ...state,
        client: action.payload,
      };
    }

    case ExperienceWidget.CHANGE_DATE_TIME_USER: {
      return {
        ...state,
        dateTimeUser: action.payload,
      };
    }

    case ExperienceWidget.CHANGE_COMPANY: {
      return {
        ...state,
        company: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
