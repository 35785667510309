import React from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { TCompanyState } from '../../../types/Companies';
import { IAppState } from '../../../store/Store';
import './EmployeeModal.scss';
import UserForm from '../../Utils/Forms/UserForm';
import { IFormError } from '../../../constants/Interfaces';

interface IModalForm {
  checkboxError: boolean;
  handleSubmit: (
    event?:
      | Partial<
          Pick<React.SyntheticEvent<Element, Event>, 'preventDefault' | 'stopPropagation'>
        >
      | undefined,
  ) => Promise<any | undefined> | undefined;
  fieldError?: IFormError;
  [key: string]: any;
}

const ModalForm: React.FC<IModalForm> = ({
  checkboxError,
  handleSubmit,
  fieldError,
  ...rest
}) => {
  const companyState: TCompanyState = useSelector<IAppState, TCompanyState>(
    (state: IAppState) => state.companyState,
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Container>
        <UserForm
          hasCompanySelect
          companyOptions={companyState.allData || []}
          chechBoxError={checkboxError}
          fieldError={fieldError}
          {...rest}
        />
      </Container>
    </Form>
  );
};

export default ModalForm;
