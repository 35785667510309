import React from 'react';
import Row from 'experience/components/Row';
import ReservationForm from 'experience/components/form/ReservationForm';

const ExperienceForm: React.FC = () => {
  return (
    <div className='experience-form-page'>
      <Row>
        <ReservationForm />
      </Row>
    </div>
  );
};

export default ExperienceForm;
