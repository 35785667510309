import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormInput from '../../Form/FormInput';
import styles from './ModalForm.module.scss';
import FormSelect from '../../Form/FormSelect';
import Form from 'react-bootstrap/Form';
import ExperiencePhoneInput from 'experience/utils/form/ExperiencePhoneInput';
import { getUserLocation } from 'experience/redux/actions/ExperienceUserLocationActions';
import { useDispatch } from 'react-redux';
import './PhoneInput.scss';
import { ClientPhoneFieldStates } from './AddEditClientModal';

interface IModalFormProps {
  setPhoneFieldState: React.Dispatch<React.SetStateAction<ClientPhoneFieldStates>>;
}

const DEFAULT_COUNTRY = 'lt';

const ModalForm: FC<IModalFormProps> = ({ setPhoneFieldState }) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const response: any = await dispatch(getUserLocation());
      if (response.data.country) {
        setCountry(response.data.country);
      }
    })();
  }, []);

  const validatePhone = (e: React.FocusEvent<HTMLInputElement>, isValid: boolean) => {
    if (e.target.value === '') {
      setPhoneFieldState('empty');
      return;
    }
    setPhoneFieldState(isValid ? 'valid' : 'invalid');
  };

  const getYears = () => {
    let currentYear = new Date().getFullYear();
    const lastYear = currentYear - 100;
    const yearOptions = [];
    while (currentYear >= lastYear) {
      yearOptions.push({ id: currentYear, name: currentYear });
      currentYear--;
    }
    return yearOptions;
  };

  const getDays = () => {
    const days = [];
    for (let x = 1; x <= 31; x++) {
      days.push({ id: `${x}`.padStart(2, '0'), name: `${x}`.padStart(2, '0') });
    }
    return days;
  };

  const MONTHS = [
    { id: '01', name: t('filter_month_01') },
    { id: '02', name: t('filter_month_02') },
    { id: '03', name: t('filter_month_03') },
    { id: '04', name: t('filter_month_04') },
    { id: '05', name: t('filter_month_05') },
    { id: '06', name: t('filter_month_06') },
    { id: '07', name: t('filter_month_07') },
    { id: '08', name: t('filter_month_08') },
    { id: '09', name: t('filter_month_09') },
    { id: '10', name: t('filter_month_10') },
    { id: '11', name: t('filter_month_11') },
    { id: '12', name: t('filter_month_12') },
  ];

  const GENDER = [
    { id: 'Male', name: t('client_gender_male') },
    { id: 'Female', name: t('client_gender_female') },
  ];

  const NOTIFY_BY_TYPES = [
    { id: 'NotSend', name: t('form_notification_not_send') },
    { id: 'Email', name: t('form_notification_email') },
    { id: 'Sms', name: t('form_notification_sms') },
    { id: 'EmailAndSms', name: t('form_notification_email_and_sms') },
  ];

  return (
    <Row className={`px-2 py-2 ${styles.root}`}>
      <Col xs={12} lg={7}>
        <Row>
          <Col xs={12} lg={6}>
            <FormInput
              label={t('client_form_label_name') + ' *'}
              name='name'
              type='text'
            />
          </Col>
          <Col xs={12} lg={6}>
            <FormInput
              label={t('client_form_label_lastname')}
              name='lastname'
              type='text'
            />
          </Col>
          <Col xs={12} lg={6}>
            <FormInput label={t('client_form_label_email')} name='email' type='email' />
          </Col>
          <Col xs={12} lg={6}>
            <ExperiencePhoneInput
              className='client-modal'
              name='phone'
              defaultCountry={country.toLowerCase()}
              type='text'
              label={t('client_form_label_phone')}
              errorDisplayType='basic'
              onBlur={validatePhone}
            />
          </Col>
          <Col xs={12} lg={6}>
            <FormInput
              label={t('client_form_label_address')}
              name='address'
              type='text'
            />
          </Col>
          <Col xs={12} lg={6}>
            <Form.Label>{t('client_form_label_birth_date')}</Form.Label>
            <Row className='no-gutters'>
              <Col xs={12} sm={4} lg='auto'>
                <div className={styles.selectWrapper}>
                  <FormSelect
                    hasEmptyOption
                    emptyOptionLabel={t('client_form_field_birthday_year')}
                    name='year'
                    options={getYears()}
                    optionValue='id'
                    optionDisplay='name'
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} lg={5}>
                <div className={styles.selectWrapper}>
                  <FormSelect
                    hasEmptyOption
                    emptyOptionLabel={t('client_form_field_birthday_month')}
                    name='month'
                    options={MONTHS}
                    optionValue='id'
                    optionDisplay='name'
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} lg='auto'>
                <div className={styles.selectWrapper}>
                  <FormSelect
                    hasEmptyOption
                    emptyOptionLabel={t('client_form_field_birthday_day')}
                    name='date'
                    options={getDays()}
                    optionValue='id'
                    optionDisplay='name'
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <FormSelect
              label={t('client_form_label_gender')}
              hasEmptyOption={true}
              emptyOptionLabel={t('client_form_label_gender')}
              name='gender'
              options={GENDER}
              optionValue='id'
              optionDisplay='name'
            />
          </Col>
          <Col xs={12}>
            <FormSelect
              label={t('client_form_label_notification') + ' *'}
              hasEmptyOption={true}
              emptyOptionLabel={t('selectNotificationsOption')}
              name='notification'
              options={NOTIFY_BY_TYPES}
              optionValue='id'
              optionDisplay='name'
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12} lg={5}>
        <FormInput
          label={t('client_form_label_comments')}
          name='comments'
          formType='textarea'
          inputClass={styles.commentsTextarea}
        />
      </Col>
    </Row>
  );
};

export default ModalForm;
