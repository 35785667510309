import React from 'react';
import { Field } from 'react-final-form';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormErrorText from './FormErrorText';
import { IFormError } from '../../constants/Interfaces';

interface IFormInput {
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  formType?: any;
  options?: Array<string>;
  size?: 'sm' | 'lg';
  startButton?: string | JSX.Element;
  endButton?: string | JSX.Element;
  inputClass?: string;
  wrapperClass?: string;
  validation?: (value: any) => any;
  fieldError?: IFormError;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => any;
  accept?: string;
  disabled?: boolean;
  [key: string]: any;
}

const handleErrorText = (
  metaError: any,
  inputName: string,
  submitFailed?: boolean,
  metaTouched?: boolean,
  fieldError?: IFormError,
) => {
  if (
    fieldError &&
    fieldError.message &&
    fieldError.causedByField?.toLowerCase() === inputName.toLowerCase()
  ) {
    return <FormErrorText errorText={fieldError.message} />;
  }
  if (metaError && metaTouched && submitFailed) {
    return <FormErrorText errorText={metaError} />;
  }
};

const FormInput: React.FC<IFormInput> = ({
  name,
  type,
  placeholder,
  size,
  label,
  formType,
  options,
  startButton,
  inputClass,
  validation,
  wrapperClass,
  fieldError,
  onChange,
  onFocus,
  disabled,
  endButton,
  ...rest
}) => {
  return (
    <Field name={name} validate={validation}>
      {({ input, meta }) => (
        <Form.Group className={wrapperClass}>
          {label && (
            <Form.Label
              className={
                meta.error && meta.touched && meta.submitFailed ? 'text-danger' : ''
              }
            >
              {label}
            </Form.Label>
          )}
          <InputGroup className={inputClass} size={size}>
            {startButton && (
              <InputGroup.Prepend>
                <InputGroup.Text id='basic-addon1'>{startButton}</InputGroup.Text>
              </InputGroup.Prepend>
            )}
            <Form.Control
              isInvalid={meta.error && meta.touched && meta.submitFailed}
              as={formType}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              {...rest}
              {...input}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange?.(e);
                input.onChange(e);
              }}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                onFocus?.(e);
                input.onFocus(e);
              }}
            >
              {options && options.map(option => <option key={option}>{option}</option>)}
            </Form.Control>
            {endButton && (
              <InputGroup.Append>
                <InputGroup.Text id='basic-addon1'>{endButton}</InputGroup.Text>
              </InputGroup.Append>
            )}
          </InputGroup>
          {handleErrorText(meta.error, name, meta.submitFailed, meta.touched, fieldError)}
        </Form.Group>
      )}
    </Field>
  );
};

export default FormInput;
