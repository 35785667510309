import React, { FC, useEffect, useState } from 'react';
import './ReservationFormEmployees.scss';
import DatePicker, { registerLocale } from 'react-datepicker';
import './ReservationFormCalendar.scss';
import moment from 'moment';
import { handleError } from '../../../middlewares/ErrorHandler';
import { getReservationAvailableTime } from '../../../actions/ReservationsActions';
import { useDispatch, useSelector } from 'react-redux';
import { IFormError } from '../../../constants/Interfaces';
import {
  IReservationAvailability,
  IReservationWithInfo,
  TReservationState,
} from '../../../types/Reservations';
import { IAppState } from '../../../store/Store';
import ReservationSmallCalendar from './ReservationSmallCalendar';
import useError from '../../../hooks/useError';

import lt from 'date-fns/locale/lt';
import lv from 'date-fns/locale/lv';
import pl from 'date-fns/locale/pl';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import cz from 'date-fns/locale/cs';
import useGetDataForRedux from '../../../hooks/useGetDataForRedux';
import { getReservationSettings } from '../../../actions/ReservationSettingsActions';
import { IReservationSettingsState } from '../../../types/ReservationSettings';

registerLocale('LT', lt);
registerLocale('EN', en);
registerLocale('LV', lv);
registerLocale('PL', pl);
registerLocale('RU', ru);
registerLocale('CZ', cz);

const getDaysInMonth = function (year: string, month: string) {
  return new Date(+year, +month, 0).getDate();
};

interface ISelectedDate {
  day: string;
  month: string;
  year: string;
}

interface IReservationFormCalendar {
  selectedUserId?: string;
  selectedLocationId: string;
  selectedServiceId: string;
  selectedStartDate: string | null;
  setSelectedStartDate: (date: string | null) => void;
  setCalendarAutoUserError: (error: boolean) => void;
  setCalendarWorkingHoursError: (error: boolean) => void;
  serviceDuration: number;
  setValue?: (name: string, value: any) => void;
  reservationValues?: IReservationWithInfo;
  selectedPricingId: string;
  reservationToEdit: IReservationWithInfo;
}

const ReservationFormCalendar: FC<IReservationFormCalendar> = ({
  selectedUserId,
  selectedServiceId,
  selectedLocationId = null,
  selectedStartDate,
  setSelectedStartDate,
  serviceDuration,
  setCalendarAutoUserError,
  setCalendarWorkingHoursError,
  reservationValues,
  selectedPricingId,
  reservationToEdit,
}) => {
  const dispatch = useDispatch();
  const [availableTime, setAvailableTime] = useState<IReservationAvailability[]>();
  const [availableTimeSlots, setAvailableTimeSlots] =
    useState<IReservationAvailability[]>();
  const [error, setError] = useState<IFormError>();
  const [selectedDate, setSelectedDate] = useState<ISelectedDate>();
  const [activeMonth, setActiveMonth] = useState<string>();
  const [activeYear, setActiveYear] = useState<string>();
  const [selectedMonthAvailability, setSelectedMonthAvailability] =
    useState<IReservationAvailability[]>();
  useError(error, setError);

  const reservationsState: TReservationState = useSelector<IAppState, TReservationState>(
    (state: IAppState) => state.reservationsState,
  );

  const reservationSettingsState: IReservationSettingsState = useSelector<
    IAppState,
    IReservationSettingsState
  >((state: IAppState) => state.reservationSettingsState);
  useGetDataForRedux(setError, reservationSettingsState.data, getReservationSettings());

  useEffect(() => {
    if (selectedStartDate) {
      const dateArr = moment(selectedStartDate).format('YYYY-MM-DD').split('-');
      setSelectedDate({
        year: dateArr[0],
        month: dateArr[1],
        day: dateArr[2],
      });
      setActiveYear(dateArr[0]);
      setActiveMonth(dateArr[1]);
    } else {
      handleDateChange(new Date());
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedLocationId) {
        try {
          const year = moment(new Date()).format('YYYY');
          const month = moment(new Date()).format('MM');
          const endDefaultDate = `${year}-${month}-${getDaysInMonth(
            year,
            month,
          )}T23:59:00`;
          const startDefaultDate = `${year}-${month}-01T00:00:00`;
          await dispatch(
            getReservationAvailableTime({
              LocationId: selectedLocationId,
              UserId: null,
              ServicePricingId: selectedPricingId,
              Start:
                activeMonth && activeYear
                  ? `${activeYear}-${activeMonth}-01T00:00:00`
                  : startDefaultDate,
              End:
                activeMonth && activeYear
                  ? `${activeYear}-${activeMonth}-${getDaysInMonth(
                      activeYear,
                      activeMonth,
                    )}T23:59:00`
                  : endDefaultDate,
            }),
          );
        } catch (err) {
          handleError(err?.response?.status, setError, err.response?.data?.title);
        }
      }
    })();
  }, [
    dispatch,
    selectedLocationId,
    activeYear,
    activeMonth,
    selectedDate,
    selectedPricingId,
  ]);

  useEffect(() => {
    const availableTime =
      selectedUserId === 'auto'
        ? reservationsState.availableTime
        : reservationsState.availableTime?.filter(data => data.userId === selectedUserId);

    if (selectedUserId === reservationToEdit?.userId && availableTime) {
      availableTime.push({
        locationId: reservationToEdit?.locationId,
        userId: reservationToEdit?.userId,
        start: reservationToEdit?.start,
        end: reservationToEdit?.end,
      });
    }
    setAvailableTime(availableTime);

    if (activeMonth === selectedDate?.month) {
      setSelectedMonthAvailability(availableTime);
    }
  }, [reservationsState.availableTime, activeMonth, selectedUserId]);

  const removeOverlappingDates = (dates: IReservationAvailability[]) => {
    if (!dates) return;
    const datesCopy = [...dates];
    const sortedDates = datesCopy.sort(
      (a, b) => new Date(a.end).getTime() - new Date(b.end).getTime(),
    );
    for (let i = 0; i < sortedDates.length - 1; i++) {
      if (
        new Date(sortedDates[i].end).getTime() >=
        new Date(sortedDates[i + 1].start).getTime()
      ) {
        sortedDates[i].end = sortedDates[i + 1].end;
        sortedDates.splice(i + 1, 1);
        i--;
      }
    }
    return sortedDates;
  };

  const getClassNameForDate = (date: Date) => {
    if (moment(new Date()) > moment(date)) {
      return 'react-datepicker__day--passed';
    }
    return availableTime?.some(at =>
      moment(date).isBetween(moment(at.start), moment(at.end), 'days', '[]'),
    )
      ? 'react-datepicker__day--available'
      : 'react-datepicker__day--disabled';
  };

  const handleDateChange = (date: any) => {
    const newDate = {
      year: moment(date).format('YYYY'),
      month: moment(date).format('MM'),
      day: moment(date).format('DD'),
    };
    setSelectedDate(newDate);
    setSelectedStartDate(null);
    if (newDate.month !== activeMonth || newDate.year !== activeYear) {
      handleMonthChange(date);
    }
  };

  const handleMonthChange = (date: Date) => {
    setActiveMonth(moment(date).format('MM'));
    setActiveYear(moment(date).format('YYYY'));
  };

  useEffect(() => {
    if (selectedDate && selectedMonthAvailability) {
      const availableTimeSlots = selectedMonthAvailability.filter(
        (day: IReservationAvailability) => {
          const isSelectedDate = day.start.includes(
            `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`,
          );

          if (isSelectedDate) {
            return day;
          }
        },
      );
      const noOverllapingdates = removeOverlappingDates(availableTimeSlots);
      setAvailableTimeSlots(noOverllapingdates);
    }
  }, [selectedMonthAvailability, selectedDate, serviceDuration]);

  const selectedDateString = selectedDate
    ? `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`
    : '';

  const selectedFormattedDate = selectedDateString ? new Date(selectedDateString) : null;

  return (
    <div>
      {selectedFormattedDate && (
        <DatePicker
          open={true}
          onChange={date => handleDateChange(date)}
          onMonthChange={date => handleMonthChange(date)}
          customInput={<input type='hidden' className='d-none' />}
          locale={reservationSettingsState.data?.locale || 'EN'}
          dateFormat='yyyy-MM-dd'
          required={true}
          selected={selectedFormattedDate}
          wrapperClassName='reservations-calendar-wrapper'
          calendarClassName='reservations-calendar'
          popperClassName='calendar-popper'
          dayClassName={getClassNameForDate}
        />
      )}
      <ReservationSmallCalendar
        selectedUserId={selectedUserId || ''}
        selectedServiceId={selectedServiceId}
        availableTimeSlots={availableTimeSlots || []}
        selectedDate={selectedDateString}
        setSelectedStartDate={setSelectedStartDate}
        reservationValues={reservationValues}
        selectedLocationId={selectedLocationId || undefined}
        setCalendarAutoUserError={setCalendarAutoUserError}
        setCalendarWorkingHoursError={setCalendarWorkingHoursError}
        reservationToEdit={reservationToEdit}
      />
    </div>
  );
};

export default ReservationFormCalendar;
