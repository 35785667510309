import { getLocations } from 'actions/LocationActions';
import FormCheckbox from 'components/Form/FormCheckbox';
import FormErrorText from 'components/Form/FormErrorText';
import FormSelect from 'components/Form/FormSelect';
import { IFormError } from 'constants/Interfaces';
import useError from 'hooks/useError';
import useGetDataForRedux from 'hooks/useGetDataForRedux';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IAppState } from 'store/Store';
import { TLocationState } from 'types/Locations';
import { IResource } from 'types/Resources';

interface IResourcesForm {
  resourcesGroups: Array<any>;
  setValue?: (location: string, value: string[]) => void;
  submitFailed?: boolean;
  errors?: Record<string, any>;
}
// generate array from 1 to X and maximum of 20 usin array.from and has property label
const getResourcesList = (count: number) => {
  const max = Math.min(count, 20);
  return Array.from(Array(max).keys()).map(i => ({
    label: `${i + 1}`,
    value: `${i + 1}`,
  }));
};

const ResourcesForm: React.FC<IResourcesForm> = ({
  resourcesGroups,
  errors,
  submitFailed,
}) => {
  const [error, setError] = useState<IFormError>();
  useError(error, setError);
  const { t } = useTranslation();
  const required = (value: string) => (value ? undefined : t('required'));
  const hasResourceError = errors ? Object.keys(errors).includes('resourceIds') : false;
  const locationState: TLocationState = useSelector<IAppState, TLocationState>(
    (state: IAppState) => state.locationState,
  );
  useGetDataForRedux(setError, locationState.data, getLocations(0));
  const {
    values: { resources: values },
  } = useFormState();
  const hasResource = (id: string) => {
    return values?.some((resource: IResource) => resource?.id === id);
  };

  const largestGroup = Math.max(
    ...resourcesGroups
      .map(group => group[1].length)
      .filter(num => typeof num === 'number' && !isNaN(num)),
  );
  return (
    <>
      <Row>
        <Col sm={12}>
          <Row>
            <Col
              sm={12}
              className={hasResourceError && submitFailed ? 'text-danger' : 'title '}
            >
              {t('service_form_label_resources') + ' *'}
            </Col>
          </Row>
          {resourcesGroups.map((group, i) => (
            <Row key={i}>
              <Col sm={12} className='title checkBox-border py-1 px-4'>
                {locationState?.data?.find(loc => loc.id === group[0])?.name}
              </Col>

              {group[1].map((resource: IResource, index: number) => {
                const positionFromBackend = values?.findIndex(
                  (item: IResource) => item?.id === resource.id,
                );
                // Skaiciuojam kita masyvo pozicija, kai nera jokiu paselectintu reiksmiu.
                const nextEmptyArrayPosition =
                  (values?.length || 0) + index + i * largestGroup;
                const itemPosition =
                  positionFromBackend != null && positionFromBackend !== -1
                    ? positionFromBackend
                    : nextEmptyArrayPosition;
                const checkBoxName = `resources[${itemPosition}].id`;
                const dropDownName = `resources[${itemPosition}].count`;
                return (
                  <Col sm={6} key={resource.id}>
                    <>
                      <FormCheckbox
                        name={checkBoxName}
                        label={resource.name}
                        value={resource.id}
                        id={resource.id}
                        disableArrayFormat
                        checked={hasResource(resource.id)}
                        formStateName='resources'
                      />
                      {hasResource(resource.id) && (
                        <FormSelect
                          name={dropDownName}
                          options={getResourcesList(resource.count)}
                          size='sm'
                          id={resource.id}
                          hasEmptyOption
                          emptyOptionLabel={t('service_form_count_placeholder')}
                          optionValue='value'
                          optionDisplay='label'
                          initialValue={
                            values?.find((res: IResource) => res?.id === resource.id)
                              ?.count
                          }
                          validation={required}
                        />
                      )}
                    </>
                  </Col>
                );
              })}
            </Row>
          ))}
          {hasResourceError && submitFailed && (
            <FormErrorText errorText={t('required')} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ResourcesForm;
