import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormInput from '../../Form/FormInput';

interface IImportPictureInput {
  inputColSizeSm: number;
  inputColSizeMd?: number;
  setValue?: (file?: File) => void;
}

const ImportPictureInput: React.FC<IImportPictureInput> = ({
  inputColSizeSm,
  inputColSizeMd,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={inputColSizeSm} md={inputColSizeMd}>
        <FormInput
          size='sm'
          label={t('user_form_label_profile_photo')}
          name='profilePicture'
          type='file'
          accept='image/*'
          onChange={e => {
            setValue?.(e.target?.files?.[0]);
          }}
        />
      </Col>
    </Row>
  );
};

export default ImportPictureInput;
