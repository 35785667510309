import { AxiosResponse } from 'axios';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';
import {
  ICustomWorkTime,
  ICustomWorkTimeData,
  ICustomWorkTimeDataWithId,
  CustomWorkTimeAdd,
  CustomWorkTimeDelete,
  CustomWorkTimeEdit,
  CustomWorkTimeGet,
  CustomWorkTimePut,
  TCustomWorkTimeGetAction,
  TCustomWorkTimeGetAllAction,
  CustomWorkTimeGetAll,
} from '../types/CustomWorkTime';

interface IGetCustomWorkTime {
  offset: number;
  UserId?: string;
  LocationId?: string;
  sort?: string;
}

export interface IGetCustomWorkTimeAll {
  UserId?: string;
  LocationId?: string;
  DateFrom?: string;
  DateTo?: string;
  sort?: string;
}

export const getCustomWorkTimeAll = ({
  UserId,
  LocationId,
  DateFrom,
  DateTo,
  sort,
}: IGetCustomWorkTimeAll) => {
  return apiCall<TCustomWorkTimeGetAllAction, any, IGetCustomWorkTimeAll>(
    CustomWorkTimeGetAll,
    'GET',
    API_END_POINTS.GET_CUSTOM_WORK_TIME,
    true,
    null,
    {
      limit: 1000,
      UserId,
      LocationId,
      DateFrom,
      DateTo,
      Sort: sort,
    },
  );
};

export const getCustomWorkTime = ({
  offset,
  UserId,
  LocationId,
  sort,
}: IGetCustomWorkTime) => {
  return apiCall<TCustomWorkTimeGetAction, any, ICustomWorkTime>(
    CustomWorkTimeGet,
    'GET',
    API_END_POINTS.GET_CUSTOM_WORK_TIME,
    true,
    null,
    {
      limit: PAGE_LIMIT,
      UserId,
      LocationId,
      offset,
      Sort: sort,
    },
  );
};

export const changeOffset = (newOffset: number) => {
  return {
    type: CustomWorkTimePut.CUSTOM_WORK_TIME_OFFSET_UPDATE,
    payload: newOffset,
  };
};

export const deleteCustomWorkTime = (workTimeId: string) => {
  return async (
    dispatch: TDispatch<CustomWorkTimeDelete>,
  ): Promise<AxiosResponse<null>> => {
    dispatch({
      type: CustomWorkTimeDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'DELETE',
        url: API_END_POINTS.DELETE_CUSTOM_WORK_TIME(workTimeId),
        data: {},
      });
      dispatch({
        type: CustomWorkTimeDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: CustomWorkTimeDelete.DELETE_CUSTOM_WORK_TIME_STORE,
        payload: workTimeId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: CustomWorkTimeDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addNewCustomWorkTimeToStore = (workTime: ICustomWorkTimeDataWithId) => {
  return {
    type: CustomWorkTimeAdd.ADD_CUSTOM_WORK_TIME_STORE,
    payload: workTime,
  };
};

export const addCustomWorkTime = (workTime: ICustomWorkTimeData) => {
  return async (
    dispatch: TDispatch<CustomWorkTimeAdd>,
  ): Promise<AxiosResponse<string>> => {
    dispatch({
      type: CustomWorkTimeAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_CUSTOM_WORK_TIME,
        data: {
          ...workTime,
          locale: 'en', //TODO: figure out what locale to send
        },
      });

      dispatch({
        type: CustomWorkTimeAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        dispatch(addNewCustomWorkTimeToStore({ ...workTime, id: response.data }));
      }
      return response;
    } catch (error) {
      dispatch({
        type: CustomWorkTimeAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedCustomWorkTimeToStore = (workTime: ICustomWorkTimeDataWithId) => {
  return {
    type: CustomWorkTimeEdit.EDIT_CUSTOM_WORK_TIME_STORE,
    payload: workTime,
  };
};

export const editCustomWorkTime = (workTime: ICustomWorkTimeData, workTimeId: string) => {
  return async (
    dispatch: TDispatch<CustomWorkTimeEdit>,
  ): Promise<AxiosResponse<null>> => {
    dispatch({
      type: CustomWorkTimeEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_CUSTOM_WORK_TIME(workTimeId),
        data: {
          ...workTime,
          locale: 'en', //TODO: figure out what locale to send
        },
      });
      dispatch({
        type: CustomWorkTimeEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedCustomWorkTimeToStore({ ...workTime, id: workTimeId }));
      return response;
    } catch (error) {
      dispatch({
        type: CustomWorkTimeEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
