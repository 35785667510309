/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

//User read modal in docs
export interface IResource {
  id: string;
  companyId?: string;
  count: number;
  createdAt?: string;
  creatorId?: string;
  description: string;
  locationId: string;
  name: string;
  updatedAt?: string;
}

export interface IResourceData {
  companyId: string;
  count: number;
  name: string;
  locationId: string;
  description?: string;
}

export interface IResourceDataWithId {
  companyId: string;
  count: number;
  name: string;
  locationId: string;
  description?: string;
  id: string;
}

export interface IGetResources {
  data: Array<IResource>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

export interface TResourceState {
  readonly data?: Array<IResource>;
  readonly allData?: Array<IResource>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
}

export const initialResourceState: TResourceState = {
  data: undefined,
  allData: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
};

export enum ResourceAdd {
  REQUEST = 'RESOURCE_ADD_REQUEST',
  SUCCESS = 'RESOURCE_ADD_SUCCESS',
  FAILED = 'RESOURCE_ADD_FAILED',
  ADD_RESOURCE_STORE = 'RESOURCE_ADD_TO_STORE',
}

export enum ResourceEdit {
  REQUEST = 'RESOURCE_EDIT_REQUEST',
  SUCCESS = 'RESOURCE_EDIT_SUCCESS',
  FAILED = 'RESOURCE_EDIT_FAILED',
  EDIT_RESOURCE_STORE = 'RESOURCE_EDIT_ADD_TO_STORE',
}

export enum ResourcesGet {
  REQUEST = 'RESOURCES_GET_REQUEST',
  SUCCESS = 'RESOURCES_GET_SUCCESS',
  FAILED = 'RESOURCES_GET_FAILED',
}

export enum ResourcesGetAll {
  REQUEST = 'RESOURCES_GET_ALL_REQUEST',
  SUCCESS = 'RESOURCES_GET_ALL_SUCCESS',
  FAILED = 'RESOURCES_GET_ALL_FAILED',
}

export enum ResourcesPut {
  RESOURCES_OFFSET_UPDATE = 'RESOURCES_OFFSET_UPDATE',
}

export enum ResourceDelete {
  REQUEST = 'RESOURCE_DELETE_REQUEST',
  SUCCESS = 'RESOURCE_DELETE_SUCCESS',
  FAILED = 'RESOURCE_DELETE_SUCCESS',
  DELETE_RESOURCE_STORE = 'RESOURCE_DELETE_FROM_STORE',
}

export type TResourcePutAction = {
  type: ResourcesPut.RESOURCES_OFFSET_UPDATE;
  payload: number;
};

export type TResourceEditAction =
  | {
      type: ResourceEdit.REQUEST;
    }
  | {
      type: ResourceEdit.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: ResourceEdit.EDIT_RESOURCE_STORE;
      payload: IResource;
    }
  | {
      type: ResourceEdit.FAILED;
      payload: AxiosError;
    };

export type TResourceAddAction =
  | {
      type: ResourceAdd.REQUEST;
    }
  | {
      type: ResourceAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: ResourceAdd.ADD_RESOURCE_STORE;
      payload: IResource;
    }
  | {
      type: ResourceAdd.FAILED;
      payload: AxiosError;
    };

export type TResourceDeleteAction =
  | {
      type: ResourceDelete.REQUEST;
    }
  | {
      type: ResourceDelete.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: ResourceDelete.DELETE_RESOURCE_STORE;
      payload: string;
    }
  | {
      type: ResourceDelete.FAILED;
      payload: AxiosError;
    };

export type TResourcesGetAction =
  | {
      type: ResourcesGet.REQUEST;
    }
  | {
      type: ResourcesGet.SUCCESS;
      payload: AxiosResponse<IGetResources>;
    }
  | {
      type: ResourcesGet.FAILED;
      payload: AxiosError;
    };

export type TResourcesGetAllAction =
  | {
      type: ResourcesGetAll.REQUEST;
    }
  | {
      type: ResourcesGetAll.SUCCESS;
      payload: AxiosResponse<IGetResources>;
    }
  | {
      type: ResourcesGetAll.FAILED;
      payload: AxiosError;
    };

export type TResourcesActions =
  | TResourcesGetAction
  | TResourcesGetAllAction
  | TResourceDeleteAction
  | TResourceEditAction
  | TResourceAddAction
  | TResourcePutAction
  | TResetAction;
