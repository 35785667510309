import React from 'react';
import './TermsAndPolicies.scss';

const Terms = () => {
  return (
    <div className='terms_and_policies'>
      <h1 className='bigH1'>Terms and Conditions</h1>

      <h2 className='bolded-intro'>Introduction</h2>
      <p>
        The following terms and conditions (“Terms and Conditions”) govern the use of the
        event booking registration system (“Service”) offered by the private limited
        liability company Gera Dovana Group, identification number: 301075902, registered
        office address: J. Jasinskio str. 16A, Vilnius, LT-04352 01112 (“Company”). By
        using the Service, you (“User” or “you”) acknowledge that you have read,
        understand, and agree to be bound by these Terms and Conditions. If you do not
        agree to these Terms and Conditions, you must not use the Service.
      </p>
      <p>
        The Company reserves the right to modify these Terms and Conditions at any time
        and without prior notice. The User’s continued use of the Service following any
        changes to these Terms and Conditions constitutes acceptance of those changes.
      </p>

      <h2>Service Description</h2>
      <p>
        The Service provides Users with the ability to book for services appointment or
        events (“Events”). The Service may also include access to other features and tools
        related to Event booking.
      </p>

      <p>
        The Company does not guarantee that the Service will be always available and may,
        at its sole discretion, modify or discontinue the Service or any portion thereof
        without prior notice to Users. The Company shall not be liable to Users or any
        third party for any modification, suspension, or discontinuance of the Service.
      </p>

      <h2>User Conduct</h2>
      <p>
        You agree to use the Service only for lawful purposes and in accordance with these
        Terms and Conditions. You shall not, without the Company’s prior written consent:
      </p>
      <ul>
        <li>
          Modify, copy, distribute, transmit, display, perform, reproduce, publish,
          license, create derivative works from, transfer, or sell any information,
          software, products, or services obtained from the Service;
        </li>
        <li>
          Use the Service for any commercial purpose or for the benefit of any third
          party, except as explicitly permitted by the Company;
        </li>
        <li>
          Use the Service to transmit any unlawful, harassing, defamatory, abusive,
          threatening, harmful, vulgar, obscene, or otherwise objectionable material;
        </li>
        <li>
          Use the Service to transmit any material that may infringe the intellectual
          property rights or other proprietary rights of third parties;
        </li>
        <li>
          Use the Service to transmit any viruses, worms, trojan horses, or other harmful
          or destructive code;
        </li>
        <li>
          Use the Service to engage in any activity that interferes with the normal
          operation of the Service or with the ability of others to access or use the
          Service;
        </li>
        <li>
          Attempt to gain unauthorized access to the Service, other accounts, computer
          systems, or networks connected to the Service, through hacking, password mining,
          or any other means;
        </li>
        <li>
          Use the Service in any manner that could disable, overburden, damage, or impair
          the Service or interfere with any other party’s use and enjoyment of the
          Service; or
        </li>
        <li>
          Use theService to solicit personal information from other Users or to send
          unsolicited commercial messages.
        </li>
      </ul>
      <p>
        The Company reserves the right to investigate and take appropriate legal action
        against anyone who violates these restrictions, including, without limitation,
        removing the offending material from the Service, canceling the User’s Account,
        and reporting such violations to law enforcement authorities.
      </p>

      <h2>Warranty Disclaimer</h2>

      <p>
        THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE COMPANY
        DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT
        LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
        NON- INFRINGEMENT. THE COMPANY MAKES NO WARRANTY THAT THE SERVICE WILL MEET THE
        USER’S REQUIREMENTS, OR THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
        ERROR- FREE. THE USER UNDERSTANDS AND AGREES THAT ANY MATERIAL AND/OR DATA
        DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT THE
        USER’S OWN DISCRETION AND RISK AND THAT THE USER WILL BE SOLELY RESPONSIBLE FOR
        ANY DAMAGE TO THE USER’S COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
        DOWNLOAD OF SUCH MATERIAL AND/OR DATA.
      </p>

      <h2>Limitation of Liability</h2>

      <p>
        THE COMPANY SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
        CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS
        OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF THE COMPANY
        HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE USE OF
        THE SERVICE OR THE INABILITY TO USE THE SERVICE, OR FOR COST OF PROCUREMENT OF
        SUBSTITUTE GOODS AND SERVICES.
      </p>

      <p>
        THE USER ACKNOWLEDGES THAT THE COMPANY DOES NOT CONTROL THE CONTENT OF THE EVENTS
        OR THE CONDUCT OF THE EVENT ORGANIZERS OR OTHER USERS, AND THAT THE COMPANY SHALL
        NOT BE LIABLE FOR ANY SUCH CONTENT OR CONDUCT. THE USER AGREES TO HOLD THE COMPANY
        HARMLESS FROM AND AGAINST ANY AND ALL CLAIMS AND DAMAGES ARISING OUT OF THE USER’S
        PARTICIPATION IN AN EVENT OR THE USER’S USE OF THE SERVICE.
      </p>

      <h2>Indemnification</h2>

      <p>
        The User agrees to indemnify, defend, and hold harmless the Company, its
        affiliates, licensors, and service providers, and its and their respective
        officers, directors, employees, contractors, agents, licensors, suppliers,
        successors, and assigns from and against any claims, liabilities, damages,
        judgments, awards, losses, costs, expenses, or fees (including reasonable
        attorneys’ fees) arising out of the User’s use of the Service or the User’s
        participation in an Event.
      </p>

      <h2>Governing Law and Jurisdiction</h2>

      <p>
        These Terms and Conditions shall be governed by and construed in accordance with
        the laws of the jurisdiction in which the Company’s headquarters is located,
        without giving effect to its conflict of law provisions.
      </p>

      <p>
        The User agrees to submit to the personal jurisdiction of a court located in the
        jurisdiction in which the Company’s headquarters is located, for any legal
        proceedings arising from these Terms and Conditions or the User’s use of the
        Service.
      </p>

      <h2>Miscellaneous</h2>

      <p>
        These Terms and Conditions constitute the entire agreement between the User and
        the Company with respect to the use of the Service. If any provision of these
        Terms and Conditions is found to be invalid or unenforceable, the remaining
        provisions shall be fully enforced.
      </p>

      <p>
        The failure of the Company to enforce any right or provision of these Terms and
        Conditions shall not constitute a waiver of such right or provision unless
        acknowledged and agreed to by us in writing.
      </p>

      <h2>Entire Agreement</h2>

      <p>
        These Terms and Conditions, together with the Privacy Policy, constitute the
        entire agreement between the User and the Company with respect to the use of the
        Service and supersede all prior or contemporaneous communications, whether
        electronic, oral, or written, between the User and the Company with respect to the
        Service.
      </p>

      <h2>Dispute Resolution</h2>

      <p>
        In the event of a dispute arising out of or relating to these Terms and Conditions
        or the Service, the parties agree to first try to resolve the dispute peacefully
        by negotiations. If the parties are unable to resolve the dispute peacefully, the
        parties agree to resolve the dispute through the court. The location of the court
        shall be in the jurisdiction in which the Company’s headquarters is located.
      </p>

      <h2>Severability</h2>

      <p>
        If any provision of these Terms and Conditions is found to be illegal or
        unenforceable, the provision shall be deemed severed from these Terms and
        Conditions and shall not affect the validity and enforceability of the remaining
        provisions.
      </p>

      <h2>Waiver</h2>

      <p>
        The failure of the Company to enforce any right or provision in these Terms and
        Conditions shall not be deemed a waiver of such right or provision unless
        acknowledged and agreed to by the Company in writing.
      </p>

      <h2>Survival</h2>

      <p>
        The provisions of these Terms and Conditions that, by their nature, are intended
        to survive termination or expiration of this agreement shall so survive, including
        but not limited to sections relating to ownership, confidentiality, warranty
        disclaimer, indemnification, limitation of liability, and governing law.
      </p>

      <h2>Assignment</h2>

      <p>
        The User may not assign these Terms and Conditions or transfer any rights to use
        the Service without the prior written consent of the Company. The Company may
        assign these Terms and Conditions at any time without notice to the User.
      </p>

      <h2>Force Majeure</h2>

      <p>
        The Company shall not be liable for any failure to perform its obligations under
        these Terms and Conditions if such failure is due to acts of nature, fires,
        floods, epidemics, pandemics, acts of God, war, terrorism, strikes, or other
        similar events beyond the control of the Company.
      </p>

      <h2>Amendments</h2>

      <p>
        The Company may amend these Terms and Conditions from time to time. When the
        Company amends these Terms and Conditions, it will update this page and indicate
        the date of revision. The User’s continued use of the Service after any amendment
        of these Terms and Conditions shall be deemed to be the User’s acceptance of such
        amendment.
      </p>

      <h2>No Agency</h2>

      <p>
        These Terms and Conditions do not create any agency, partnership, joint venture,
        or employment relationship, and the User may not make any representation on the
        Company’s behalf.
      </p>

      <h2>Third-Party Beneficiaries</h2>

      <p>
        These Terms and Conditions are for the benefit of the Company, its affiliates,
        licensors, and service providers, and each shall have the right to enforce these
        Terms and Conditions against the User.
      </p>

      <h2>Contact Information</h2>

      <p>
        If you have any questions or comments about these Terms and Conditions, please
        contact the Company at E- mail: info@geradovana.lt
      </p>

      <h2>Governing Law</h2>

      <p>
        These Terms and Conditions shall be governed by and construed in accordance with
        the laws of the jurisdiction in which the Company’s headquarters is located,
        without giving effect to any principles of conflicts of law.
      </p>

      <h2>Injunctive Relief</h2>

      <p>
        The User acknowledges that any breach of these Terms and Conditions may cause the
        Company irreparable harm, and therefore agrees that the Company shall be entitled
        to seek injunctive relief in addition to any other remedies available at law or in
        equity.
      </p>
    </div>
  );
};

export default Terms;
