/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

export interface ICompany {
  name: string;
  sendSms: boolean;
  smsSender: string | null;
  activityType: string | null; // in docs its not nullable but we get null
  locale: string | null;
  accessKey: string;
  id: string;
  createdAt?: string;
  updatedAt?: string;
  reservationGroupsEnabled: boolean;
  maxReservationTimeInFuture: number;
  sendSmsToEmployee: boolean;
  country: string | null;
}

export interface ICompanyData {
  name: string;
  activityType: string;
  sendSms: boolean;
  smsSender: string | null;
  locale?: string;
  reservationGroupsEnabled: boolean;
  maxReservationTimeInFuture: number;
  sendSmsToEmployee: boolean;
  country: string | null;
}

export interface IGetCompanies {
  data: Array<ICompany>;
  offset: number;
  limit: number;
  total: number;
  search: string;
  sort: string | null;
}

export interface IGetCompanyById {
  data: ICompany;
  status: number;
  statusText: string;
}

export interface TCompanyState {
  readonly data?: Array<ICompany>;
  readonly allData?: Array<ICompany>;
  readonly userCompany?: ICompany;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
}

export const initialCompaniesState: TCompanyState = {
  data: undefined,
  allData: undefined,
  userCompany: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
};

export enum CompaniesGetAllUnpaginated {
  REQUEST = 'COMPANIES_GET_UNPAGINATED_ALL_REQUEST',
  SUCCESS = 'COMPANIES_GET_UNPAGINATED_ALL_SUCCESS',
  FAILED = 'COMPANIES_GET_UNPAGINATED_ALL_FAILED',
}

export enum CompaniesGetAll {
  REQUEST = 'COMPANIES_GET_ALL_REQUEST',
  SUCCESS = 'COMPANIES_GET_ALL_SUCCESS',
  FAILED = 'COMPANIES_GET_ALL_FAILED',
}

export enum CompaniesGet {
  REQUEST = 'COMPANIES_GET_REQUEST',
  SUCCESS = 'COMPANIES_GET_SUCCESS',
  FAILED = 'COMPANIES_GET_FAILED',
}

export enum CompanyGetById {
  REQUEST = 'COMPANY_GET_BY_ID_REQUEST',
  SUCCESS = 'COMPANY_GET_BY_ID_SUCCESS',
  FAILED = 'COMPANY_GET_BY_ID_FAILED',
}

export enum CompaniesAdd {
  REQUEST = 'COMPANIES_ADD_REQUEST',
  SUCCESS = 'COMPANIES_ADD_SUCCESS',
  FAILED = 'COMPANIES_ADD_FAILED',
  ADD_COMPANY_STORE = 'COMPANIES_ADD_TO_STORE',
}

export enum CompaniesEdit {
  REQUEST = 'COMPANIES_EDIT_REQUEST',
  SUCCESS = 'COMPANIES_EDIT_SUCCESS',
  FAILED = 'COMPANIES_EDIT_FAILED',
  EDIT_COMPANY_STORE = 'COMPANIES_EDIT_ADD_TO_STORE',
  COMPANIES_CHNAGE_OFFSET = 'COMPANIES_CHNAGE_OFFSET',
}

export enum CompaniesDelete {
  REQUEST = 'COMPANIES_DELETE_REQUEST',
  SUCCESS = 'COMPANIES_DELETE_SUCCESS',
  FAILED = 'COMPANIES_DELETE_SUCCESS',
  DELETE_COMPANY_STORE = ' COMPANIES_DELETE_FROM_STORE',
}

export type TCompaniesGetAll =
  | {
      type: CompaniesGetAll.REQUEST;
    }
  | {
      type: CompaniesGetAll.SUCCESS;
      payload: AxiosResponse<IGetCompanies>;
    }
  | {
      type: CompaniesGetAll.FAILED;
      payload: AxiosError;
    };

export type TCompaniesDeleteAction =
  | {
      type: CompaniesDelete.REQUEST;
    }
  | {
      type: CompaniesDelete.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: CompaniesDelete.DELETE_COMPANY_STORE;
      payload: string;
    }
  | {
      type: CompaniesDelete.FAILED;
      payload: AxiosError;
    };

export type TCompaniesEditAction =
  | {
      type: CompaniesEdit.REQUEST;
    }
  | {
      type: CompaniesEdit.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: CompaniesEdit.EDIT_COMPANY_STORE;
      payload: ICompany;
    }
  | {
      type: CompaniesEdit.FAILED;
      payload: AxiosError;
    }
  | {
      type: CompaniesEdit.COMPANIES_CHNAGE_OFFSET;
      payload: number;
    };

export type TCompaniesAddAction =
  | {
      type: CompaniesAdd.REQUEST;
    }
  | {
      type: CompaniesAdd.SUCCESS;
      payload: AxiosResponse<ICompany>;
    }
  | {
      type: CompaniesAdd.ADD_COMPANY_STORE;
      payload: ICompany;
    }
  | {
      type: CompaniesAdd.FAILED;
      payload: AxiosError;
    };

export type TCompaniesGetAction =
  | {
      type: CompaniesGet.REQUEST;
    }
  | {
      type: CompaniesGet.SUCCESS;
      payload: AxiosResponse<IGetCompanies>;
    }
  | {
      type: CompaniesGet.FAILED;
      payload: AxiosError;
    };

export type TCompanyGetByIdAction =
  | {
      type: CompanyGetById.REQUEST;
    }
  | {
      type: CompanyGetById.SUCCESS;
      payload: IGetCompanyById;
    }
  | {
      type: CompanyGetById.FAILED;
      payload: AxiosError;
    };

export type TCompaniesGetAllUnpaginated =
  | {
      type: CompaniesGetAllUnpaginated.REQUEST;
    }
  | {
      type: CompaniesGetAllUnpaginated.SUCCESS;
      payload: AxiosResponse<IGetCompanies>;
    }
  | {
      type: CompaniesGetAllUnpaginated.FAILED;
      payload: AxiosError;
    };

export type TCompaniesActions =
  | TCompaniesGetAction
  | TCompaniesAddAction
  | TCompaniesDeleteAction
  | TCompaniesEditAction
  | TCompanyGetByIdAction
  | TCompaniesGetAll
  | TResetAction
  | TCompaniesGetAllUnpaginated;
