import React, { useState, useRef } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { IoMdWarning } from 'react-icons/io';
import { IFormError } from 'constants/Interfaces';
import {
  FILE_RULE_COUNT,
  FILE_ALLOWED_TYPES,
  EXAMPLE_FILE_PATHS,
} from 'constants/ClientImport';
import { importClients, getAllClients } from 'actions/ClientsActions';
import { handleError } from 'middlewares/ErrorHandler';
import useError from 'hooks/useError';
import Toast from 'components/Utils/Toast';
import { TClientState } from 'types/Clients';
import { IAppState } from 'store/Store';
import './CustomerImport.scss';

const CustomerImport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [customerFile, setCustomerFile] = useState<File | undefined>(undefined);
  const [formError, setFormError] = useState<string | null>(null);
  const [error, setError] = useState<IFormError>();
  useError(error, setError);
  const inputRef: any = useRef(null);
  const clientsState: TClientState = useSelector<IAppState, TClientState>(
    (state: IAppState) => state.clientsState,
  );

  const handleSubmit = () => {
    if (!customerFile) {
      setFormError(t('required'));
    } else if (!FILE_ALLOWED_TYPES.includes(customerFile.type)) {
      setFormError(t('client_import_rule_1'));
    } else {
      setFormError(null);

      (async () => {
        try {
          await dispatch(
            importClients(customerFile, (clientCount: number) => {
              toast.success(
                <Toast
                  text={t('messages_import_success', {
                    importCount: clientCount,
                  })}
                />,
              );
            }),
          );
          setCustomerFile(undefined);
          if (inputRef && inputRef.current) {
            inputRef.current.value = null;
          }
          await dispatch(getAllClients());
        } catch (err) {
          handleError(
            err?.response?.status,
            setError,
            err.response?.data?.title,
            err.response?.data?.CausedByField,
            t('messages_import_failed'),
          );
        }
      })();
    }
  };

  return (
    <Container fluid className='p-0 customer-import'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='mainCard'>
            <Card.Header className='title-big'>
              {t('client_import_page_title')}
            </Card.Header>
            <Card.Body>
              <p className='customer-import__rules-list--header'>
                <div>{t(`client_import_rule_1`)}</div>
                {t('client_import_rules')}
              </p>
              <ul className='customer-import__rules-list'>
                {Array.from({ length: FILE_RULE_COUNT }, (_, i) => i + 1).map(
                  rule =>
                    i18next.exists(`client_import_rule_${rule}`) && (
                      <li key={rule}>
                        <Trans
                          i18nKey={`client_import_rule_${rule}`}
                          defaults={t(`client_import_rule_${rule}`)}
                          components={{
                            exampleFile: (
                              <Link
                                to={EXAMPLE_FILE_PATHS[i18next.language]}
                                target='_blank'
                                className='font-weight-normal'
                                download
                              />
                            ),
                          }}
                        />
                      </li>
                    ),
                )}
              </ul>
              <b className={formError ? 'customer-import__error' : ''}>
                {t('client_import_form_label_file')} *
              </b>
              <br />
              <label className='custom-file-upload'>
                <input
                  ref={inputRef}
                  type='file'
                  id='customerFile'
                  accept={FILE_ALLOWED_TYPES.join(', ')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCustomerFile(e.target?.files?.[0] || undefined)
                  }
                />
                <button onClick={() => inputRef.current.click()}>
                  {t('chooseFile')}
                </button>
                {customerFile ? customerFile.name : t('noFileChosen')}
              </label>
              {formError && (
                <p className='customer-import__error'>
                  <IoMdWarning /> {formError}
                </p>
              )}
            </Card.Body>
            <Card.Footer>
              <Button onClick={handleSubmit}>
                {clientsState.isLoadingFileImport && (
                  <Spinner animation='border' variant='secondary' size='sm' />
                )}
                {t('client_import_btn')}
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerImport;
