import React from 'react';
import './Avatar.scss';

interface ISquareAvatar {
  src: string;
}

const SquareAvatar = ({ src }: ISquareAvatar) => {
  return <img src={src} className='square--avatar' />;
};

export default SquareAvatar;
