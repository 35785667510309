import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { ROUTES } from '../constants/Routes';
import { getAccessToken, isSuperViewerMode, parseUserData } from '../helpers/Misc';
import DefaultLayout from '../layouts/DefaultLayout';
import { MdBusiness, MdPerson } from 'react-icons/md';
import { FaCalendarAlt, FaUser, FaCog } from 'react-icons/fa';
import { AiOutlineBarChart, AiOutlineFileUnknown } from 'react-icons/ai';
import { tokenLogin } from '../actions/UserActions';
import useError from '../hooks/useError';
import { handleError } from '../middlewares/ErrorHandler';
import { TUserState } from '../types/User';
import { IAppState } from '../store/Store';
import { IFormError } from '../constants/Interfaces';
import { IReservationSettingsState } from '../types/ReservationSettings';
import { getReservationSettings } from '../actions/ReservationSettingsActions';
import i18next from '../config/i18next';
import { checkUserRole } from 'helpers/matchRole';
import { Roles } from 'constants/Roles';

import './PrivateRoute.scss';
interface IPrivaterRoute {
  roles?: Array<string>;
  component: React.ElementType;
  exact: boolean;
  path: string | Array<string>;
}

const PrivateRoute: React.FC<IPrivaterRoute> = ({
  component: Component,
  roles,
  path,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const token = getAccessToken();
  const client = token ? parseUserData(token) : null;
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const reservationSettingsState: IReservationSettingsState = useSelector<
    IAppState,
    IReservationSettingsState
  >((state: IAppState) => state.reservationSettingsState);

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  useEffect(() => {
    (async () => {
      if (!reservationSettingsState.data && client) {
        try {
          const response: any = await dispatch(getReservationSettings());

          // if locale exists in url, use it instead of response
          const queryParams = new URLSearchParams(window.location.search);
          const locale = queryParams.get('locale');
          if (locale) {
            return await i18next.changeLanguage(locale.toLowerCase() || 'en');
          }
          // if locale exists in response
          if (!client.roles.includes(Roles.SuperAdmin)) {
            i18next.changeLanguage(response?.data.locale.toLowerCase() || 'en');
          }
        } catch (err) {
          handleError(
            err?.response?.status,
            setError,
            err.response?.data?.title,
            err.response?.data?.CausedByField,
          );
        }
      }
    })();
  }, [dispatch, reservationSettingsState.data]);

  useEffect(() => {
    (async () => {
      if (!userState.data && token) {
        try {
          await dispatch(tokenLogin(token));
        } catch (err) {
          handleError(
            err?.response?.status,
            setError,
            err.response?.data?.title,
            err.response?.data?.CausedByField,
          );
        }
      }
    })();
  }, [dispatch, userState.data]);

  const superAdminSidebarLinks = [
    {
      name: t('sidebar_menu_user_activity_type_universal'),
      icon: <MdPerson />,
      route: [ROUTES.SUPER_ADMIN.WORKERS, ROUTES.HOME],
    },
    {
      name: t('sidebar_menu_copmany'),
      icon: <MdBusiness />,
      route: [ROUTES.SUPER_ADMIN.COMPANIES],
    },
  ];

  const adminSidebarLinks = [
    {
      name: t('sidebar_menu_calendar'),
      icon: <FaCalendarAlt />,
      route: [ROUTES.ADMIN.RESERVATIONS],
    },
    {
      name: t('sidebar_menu_client'),
      icon: <FaUser />,
      route: [ROUTES.ADMIN.CLIENTS],
    },
    {
      name: t('sidebar_menu_settings'),
      icon: <FaCog />,
      route: [''],
    },
    {
      name: t('sidebar_menu_report'),
      icon: <AiOutlineBarChart />,
      route: [ROUTES.ADMIN.REPORTS],
    },
    {
      name: t('sidebar_menu_instruction'),
      icon: <AiOutlineFileUnknown />,
      route: [ROUTES.ADMIN.INSTRUCTION_EXTERNAL],
    },
  ];

  const employeeSidebarLinks = [
    {
      name: t('sidebar_menu_calendar'),
      icon: <FaCalendarAlt />,
      route: [ROUTES.ADMIN.RESERVATIONS],
    },
  ];

  const AnalystSideBarLinks = [
    {
      name: t('sidebar_menu_report'),
      icon: <AiOutlineBarChart />,
      route: [ROUTES.ADMIN.REPORTS],
    },
  ];

  return (
    <Route
      {...rest}
      path={path}
      render={props => {
        if (!client) {
          return <Redirect to={ROUTES.LOGIN} />;
        }
        // check if route is restricted by role
        if (roles && !checkUserRole(client?.roles || [], roles) && !isSuperViewerMode) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/' }} />;
        }

        // authorised so return component
        return !i18n.language ? (
          <div className='route-loading-container'>
            <Spinner animation='border' variant='primary' />
          </div>
        ) : (
          <DefaultLayout
            superAdminSidebarLinks={superAdminSidebarLinks}
            adminSidebarLinks={adminSidebarLinks}
            employeeSidebarLinks={employeeSidebarLinks}
            AnalystSideBarLinks={AnalystSideBarLinks}
          >
            <Component {...props} key={props.location.key} />
          </DefaultLayout>
        );
      }}
    />
  );
};

export default PrivateRoute;
