import React, { useEffect } from 'react';
import i18n from 'config/i18next';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-day-picker/lib/style.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ErrorBoundary from './ErrorBoundary';
import MainRouter from './router/MainRouter';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from './themes/Default';
import './App.scss';
import { useStoreState } from 'hooks/useStoreState';
import { SettingsLocales } from 'types/ReservationSettings';

const App: React.FC = () => {
  const { data: reservationSettings } = useStoreState().reservationSettingsState;

  useEffect(() => {
    if (reservationSettings?.calendarTimeZone) {
      // if this is removed, calendar will show start of the week as sunday
      // on LT language and in Vilnius timezone. Fixes GDN-322
      moment.tz.setDefault(`Europe/${reservationSettings?.calendarTimeZone}`);
    }

    moment.updateLocale(i18n.language, {
      week: {
        dow: reservationSettings?.locale === SettingsLocales.EN ? 0 : 1,
      },
    });
  }, [i18n, reservationSettings]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <ErrorBoundary>
        <MainRouter />
        <ToastContainer
          position='bottom-right'
          autoClose={5000}
          closeOnClick
          pauseOnFocusLoss
          draggable={false}
          style={{ width: 500 }}
        />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
