import { AxiosResponse } from 'axios';
import {
  ClientsGet,
  ClientAdd,
  ClientEdit,
  ClientsImport,
  ClientDelete,
  TClientsGetAction,
  IClient,
  IClientData,
  ClientsPut,
  IClientDataWithId,
  IGetClients,
  ClientsGetAll,
  TClientsGetAllAction,
} from '../types/Clients';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';

export const getClients = (
  offset: number,
  searchQuery?: string,
  selectedCompany?: string,
  sort?: string,
  limit?: number,
) => {
  return apiCall<TClientsGetAction, any, IClient>(
    ClientsGet,
    'GET',
    API_END_POINTS.GET_CLIENTS,
    true,
    {},
    {
      limit: limit || PAGE_LIMIT,
      offset,
      query: searchQuery,
      Sort: sort || 'name asc',
      CompanyId: selectedCompany,
    },
  );
};

export const getAllClients = () => {
  return apiCall<TClientsGetAllAction, null, IGetClients>(
    ClientsGetAll,
    'GET',
    API_END_POINTS.GET_CLIENTS,
    true,
    null,
    { limit: 1000, Sort: 'name asc' },
  );
};

export const changeOffset = (newOffset: number) => {
  return {
    type: ClientsPut.CLIENTS_OFFSET_UPDATE,
    payload: newOffset,
  };
};

export const deleteClient = (clientId: string) => {
  return async (dispatch: TDispatch<ClientDelete>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ClientDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'DELETE',
        url: API_END_POINTS.DELETE_CLIENT(clientId),
        data: {},
      });
      dispatch({
        type: ClientDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: ClientDelete.DELETE_CLIENT_STORE,
        payload: clientId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ClientDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addNewClientToStore = (client: IClientDataWithId) => {
  return {
    type: ClientAdd.ADD_CLIENT_STORE,
    payload: client,
  };
};

export const addClient = (client: IClientData) => {
  return async (dispatch: TDispatch<ClientAdd>): Promise<AxiosResponse<string>> => {
    dispatch({
      type: ClientAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_CLIENT,
        data: {
          ...client,
          locale: 'en', //TODO: figure out what locale to send
        },
      });
      dispatch({
        type: ClientAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        dispatch(addNewClientToStore({ id: response.data, ...client }));
      }
      return response;
    } catch (error) {
      dispatch({
        type: ClientAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedClientToStore = (client: IClientDataWithId) => {
  return {
    type: ClientEdit.EDIT_CLIENT_STORE,
    payload: client,
  };
};

export const editClient = (client: IClientData, clientId: string) => {
  return async (dispatch: TDispatch<ClientEdit>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ClientEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_CLIENT(clientId),
        data: {
          ...client,
          locale: 'en', //TODO: figure out what locale to send
        },
      });
      dispatch({
        type: ClientEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedClientToStore({ ...client, id: clientId }));
      return response;
    } catch (error) {
      dispatch({
        type: ClientEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const importClients = (file: File, callback: (clientCount: number) => void) => {
  return async (dispatch: TDispatch<ClientsImport>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ClientsImport.REQUEST,
    });
    try {
      const form = new FormData();
      form.append('file', file);
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.IMPORT_CLIENTS,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({
        type: ClientsImport.SUCCESS,
        payload: response,
      });
      callback(response.data);
      return response;
    } catch (error) {
      dispatch({
        type: ClientsImport.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getClientById = async (clientId: string) => {
  const response = await authorisedClient.request<IClient>({
    method: 'GET',
    url: API_END_POINTS.GET_CLIENT_BY_ID(clientId),
    data: {},
  });
  return response;
};
