import { AxiosError } from 'axios';
import { TResetAction } from 'types/App';

export interface IUserLocation {
  city: string;
  country: string;
  hostname: string;
  ip: string;
  loc: string;
  org: string;
  postal: string;
  region: string;
  timezone: string;
}

export interface IUserGetLocation {
  data: IUserLocation;
  status: number;
  statusText: string;
}

export interface IUserLocationState {
  readonly data?: IUserLocation;
  readonly isLoading?: boolean;
}

export const initialUserGetLocationState: IUserLocationState = {
  data: undefined,
  isLoading: undefined,
};

export enum UserGetLocation {
  REQUEST = 'USER_GET_LOCATION_REQUEST',
  SUCCESS = 'USER_GET_LOCATION_SUCCESS',
  FAILED = 'USER_GET_LOCATION_FAILED',
}

export type TUserGetLocationAction =
  | { type: UserGetLocation.REQUEST }
  | { type: UserGetLocation.SUCCESS; payload: IUserGetLocation }
  | { type: UserGetLocation.FAILED; payload: AxiosError }
  | TResetAction;
