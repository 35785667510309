import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { toast } from 'react-toastify';
import Toast from '../../Utils/Toast';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormInput from 'components/Form/FormInput';
import SquareAvatar from 'components/Avatars/SquareAvatar';
import useError from '../../../hooks/useError';
import { validateUserProfile } from '../../../helpers/validators';
import { IFormError } from '../../../constants/Interfaces';
import {
  getEmployees,
  setEmployeeProfilePicture,
} from '../../../actions/EmployeesActions';
import { IEmployee, TEmployeeState } from '../../../types/Employees';
import { IAppState } from '../../../store/Store';
import { TUserState } from '../../../types/User';
import { editedEmployee } from '../../../actions/EmployeesActions';
import { handleError } from '../../../middlewares/ErrorHandler';
import {
  editNameSurname,
  editUsername,
  updateUserProfilePicture,
} from '../../../actions/UserActions';
import { getAccessToken, parseUserData } from '../../../helpers/Misc';
import useGetDataForRedux from '../../../hooks/useGetDataForRedux';

interface IProfileValues {
  name: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  username: string;
  password?: string;
  passwordRepeat?: string;
  uploadPicture?: string;
}

const ProfileForm: React.FC = () => {
  const [error, setError] = useState<IFormError>();
  const [currentUser, setCurrentUser] = useState<IEmployee>();
  const [initialValues, setInitialValues] = useState<IProfileValues>();
  const [uploadedAvatar, setUploadedAvatar] = useState<File | undefined>(undefined);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = getAccessToken();
  const client = token ? parseUserData(token) : null;
  const id = client?.userId;

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  const employeeState: TEmployeeState = useSelector<IAppState, TEmployeeState>(
    (state: IAppState) => state.employeeState,
  );

  useError(error, setError);
  useGetDataForRedux(
    setError,
    employeeState.data,
    getEmployees(employeeState.offset || 0),
  );

  const onSubmit = async (values: IProfileValues) => {
    const { ...formValues } = values;

    const userObjNew: any = {
      ...currentUser,
      ...formValues,
      userName: formValues.username,
      name: formValues.name,
      lastName: formValues.lastName,
      pass: formValues.username,
      email: formValues.email,
      phoneNumber: formValues.phoneNumber,
    };

    if (userState.data?.companyId) {
      userObjNew.company = userState.data?.companyId;
    }
    if (currentUser?.locationIds) {
      userObjNew.locations = [...currentUser.locationIds];
    }
    if (currentUser?.serviceIds) {
      userObjNew.services = [...currentUser.serviceIds];
    }

    if (id) {
      try {
        if (uploadedAvatar) {
          await dispatch(setEmployeeProfilePicture(id, uploadedAvatar));
          dispatch(updateUserProfilePicture(uploadedAvatar));
        }

        await dispatch(editedEmployee(userObjNew, id));
        await dispatch(editNameSurname(formValues.name, formValues.lastName || ''));
        toast.success(<Toast text={t('profileEditedSuccessfully')} />);
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    }
  };

  useEffect(() => {
    if (employeeState.data) {
      for (const employee of employeeState.data) {
        if (employee.id === id) {
          setCurrentUser(employee);
          dispatch(editUsername(employee.username));

          const initial: IProfileValues = {
            name: employee.name,
            lastName: employee.lastName,
            email: employee.email,
            phoneNumber: employee.phoneNumber,
            username: employee.username,
          };
          setInitialValues(initial);
          break;
        }
      }
    }
  }, [employeeState.data]);

  const shouldDisplayAvatar = userState.avatar && userState.avatar.size > 0;

  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={(values: any) => {
        const mainInfoErrors = validateUserProfile(values);
        // add translations to validated form
        Object.keys(mainInfoErrors).map(
          key => (mainInfoErrors[key] = t(`${mainInfoErrors[key]}`)),
        );
        return mainInfoErrors;
      }}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Container fluid>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col md={12} lg={6}>
                    <FormInput
                      size='sm'
                      name='name'
                      type='text'
                      label={t('user_form_label_name') + ' *'}
                    />
                  </Col>
                  <Col md={12} lg={6}>
                    <FormInput
                      size='sm'
                      name='lastName'
                      type='text'
                      label={t('user_form_label_lastname')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <FormInput
                      size='sm'
                      name='email'
                      type='email'
                      label={t('user_form_label_email')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormInput
                      size='sm'
                      name='phoneNumber'
                      type='text'
                      label={t('user_form_label_phone')}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col md={12}>
                    <FormInput
                      size='sm'
                      name='username'
                      type='text'
                      label={t('user_form_label_username') + ' *'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormInput
                      size='sm'
                      name='password'
                      type='password'
                      label={t('user_form_label_password') + ' *'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormInput
                      size='sm'
                      name='passwordRepeat'
                      type='password'
                      label={t('user_form_label_repeat_password') + ' *'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormInput
                      size='sm'
                      name='profilePicture'
                      type='file'
                      accept='image/*'
                      label={t('user_form_label_profile_photo')}
                      onChange={e => setUploadedAvatar(e.target.files?.[0])}
                    />
                  </Col>
                  {shouldDisplayAvatar && (
                    <Col md={12} className='d-flex justify-content-center pb-3'>
                      <SquareAvatar src={URL.createObjectURL(userState.avatar)} />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
          <Card.Footer className='bg-white'>
            <Button type='submit' size='sm' variant='primary' disabled={submitting}>
              {t('form_btn_save')}
            </Button>
          </Card.Footer>
        </form>
      )}
    />
  );
};

export default ProfileForm;
