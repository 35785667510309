import { authorisedClient } from './BaseAction';
import { API_END_POINTS } from '../constants/API';
import {
  IReportsByServices,
  IReportsByUsers,
  ReportsByServicesGet,
  ReportsByUsersGet,
} from 'types/Reports';
import { TDispatch } from 'types/Thunk';
import { AxiosResponse } from 'axios';

interface IGetReportInfo {
  LocationId: string;
  UserId: string;
  From: string;
  To: string;
}

export const getReportsByUsers = (
  { LocationId, UserId, From, To }: IGetReportInfo,
  callback: (reports: IReportsByUsers[]) => void,
) => {
  return async (
    dispatch: TDispatch<ReportsByUsersGet>,
  ): Promise<AxiosResponse<IReportsByUsers>> => {
    dispatch({
      type: ReportsByUsersGet.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'GET',
        url: API_END_POINTS.GET_REPORTS_BY_USERS,
        data: null,
        params: {
          LocationId,
          UserId,
          From,
          To,
        },
      });
      dispatch({
        type: ReportsByUsersGet.SUCCESS,
        payload: response.data,
      });
      callback(response.data);
      return response;
    } catch (error) {
      dispatch({
        type: ReportsByUsersGet.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getReportsByServices = (
  { LocationId, UserId, From, To }: IGetReportInfo,
  callback: (reports: IReportsByServices[]) => void,
) => {
  return async (
    dispatch: TDispatch<ReportsByServicesGet>,
  ): Promise<AxiosResponse<IReportsByUsers>> => {
    dispatch({
      type: ReportsByServicesGet.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'GET',
        url: API_END_POINTS.GET_REPORTS_BY_SERVICES,
        data: null,
        params: {
          LocationId,
          UserId,
          From,
          To,
        },
      });
      dispatch({
        type: ReportsByServicesGet.SUCCESS,
        payload: response.data,
      });
      callback(response.data);
      return response;
    } catch (error) {
      dispatch({
        type: ReportsByServicesGet.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
