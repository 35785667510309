import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormInput from '../../Form/FormInput';
import FormSelect from '../../Form/FormSelect';
import { ILocation } from '../../../types/Locations';
import { IFormError } from '../../../constants/Interfaces';
import './ModalForm.scss';

interface IModalForm {
  locations: ILocation[];
  fieldError?: IFormError;
}

const ModalForm: FC<IModalForm> = ({ locations, fieldError }) => {
  const { t } = useTranslation();
  return (
    <Row className='px-2 py-2'>
      <Col sm={12}>
        <FormInput
          label={t('resource_form_label_name') + ' *'}
          name='name'
          type='text'
          fieldError={fieldError}
        />
      </Col>
      <Col sm={12}>
        <FormSelect
          label={t('resource_form_label_location') + ' *'}
          size='sm'
          hasEmptyOption
          emptyOptionLabel={t('resource_form_placeholder_location')}
          name='locationId'
          options={locations}
          optionValue='id'
          optionDisplay='name'
          fieldError={fieldError}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          label={t('resource_form_label_count') + ' *'}
          name='count'
          type='number'
          fieldError={fieldError}
        />
      </Col>
      <Col sm={12}>
        <FormInput
          label={t('resource_form_label_description')}
          name='description'
          formType='textarea'
          inputClass='resource-desc'
          fieldError={fieldError}
        />
      </Col>
    </Row>
  );
};

export default ModalForm;
