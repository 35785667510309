import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormCheckbox from '../../Form/FormCheckbox';
import Form from 'react-bootstrap/Form';
import { IServiceCheckBoxGroup } from '../../../constants/Interfaces';
import FormErrorText from '../../Form/FormErrorText';
import { checkAllBoxes } from '../../../helpers/FormHelpers';

interface IServicesForm {
  groups: Array<IServiceCheckBoxGroup>;
  setValue?: (location: string, value: string[]) => void;
  submitFailed?: boolean;
  errors?: Record<string, any>;
}

const ServiceForm: React.FC<IServicesForm> = ({
  groups,
  setValue,
  submitFailed,
  errors,
}) => {
  const { t } = useTranslation();
  const hasServicesError = errors ? Object.keys(errors).includes('services') : false;

  return (
    <>
      <Row>
        <Col sm={12}>
          <Row>
            <Col
              sm={12}
              className={hasServicesError && submitFailed ? 'text-danger' : 'title'}
            >
              {t('user_form_label_services')}
            </Col>
          </Row>
          <Row className='checkBox-border'>
            <Col sm={12}>
              {/* TODO: CHECK ALL FEATURE */}
              <Form.Group className='checkBoxMargins'>
                <Form.Check type='checkbox' id='select_all_services'>
                  <Form.Check.Input
                    type='checkbox'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      checkAllBoxes(e, groups, 'services', true, setValue)
                    }
                  />
                  <Form.Check.Label className='form-check-label'>
                    {t('form_checkall_label')}
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
          {groups.map((group, index) => (
            <div key={group.groupName}>
              {/* CheckBox title */}
              <Row>
                <Col sm={12} className='title'>
                  {group.groupName}
                </Col>
              </Row>
              {/* TODO: ask jsutas about groups and services translations */}
              <Row className={index !== groups.length - 1 ? 'checkBox-border' : ''}>
                {group.groupServices.map(service => (
                  <Col sm={6} key={service.id}>
                    <FormCheckbox
                      name='services'
                      label={service.name}
                      value={service.id}
                      id={service.name}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ))}
          {hasServicesError && submitFailed && (
            <FormErrorText errorText={t('required')} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ServiceForm;
