import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

export interface TReportsByUsersState {
  readonly data?: Array<IReportsByUsers>;
  readonly isLoading?: boolean;
}

export interface TReportsByServicesState {
  readonly data?: Array<IReportsByServices>;
  readonly isLoading?: boolean;
}

export const initialReportsByUsersState: TReportsByUsersState = {
  data: undefined,
  isLoading: false,
};

export const initialReportsByServicesState: TReportsByServicesState = {
  data: undefined,
  isLoading: false,
};

export enum ReportsByUsersGet {
  REQUEST = 'ReportsByUsers_GET_REQUEST',
  SUCCESS = 'ReportsByUsers_GET_SUCCESS',
  FAILED = 'ReportsByUsers_GET_FAILED',
}

export enum ReportsByServicesGet {
  REQUEST = 'ReportsByServices_GET_REQUEST',
  SUCCESS = 'ReportsByServices_GET_SUCCESS',
  FAILED = 'ReportsByServices_GET_FAILED',
}

export interface IReportsByUsers {
  userId: string;
  name: string;
  lastName: string;
  email: string;
  count: number;
  price: number;
}

export interface IReportServices {
  serviceId: string;
  serviceName: string;
  duration: number;
  count: number;
  price: number;
}

export interface IReportsByServices {
  serviceGroupId: string;
  serviceGroupName: string;
  count: number;
  price: number;
  services: Array<IReportServices>;
}

export interface IGetReportsByUsers {
  data: Array<IReportsByUsers>;
}

export type TReportsByUsersGetAction =
  | {
      type: ReportsByUsersGet.REQUEST;
    }
  | {
      type: ReportsByUsersGet.SUCCESS;
      payload: AxiosResponse<Array<IReportsByUsers>>;
    }
  | {
      type: ReportsByUsersGet.FAILED;
      payload: AxiosError;
    };

export type TReportsByServicesGetAction =
  | {
      type: ReportsByServicesGet.REQUEST;
    }
  | {
      type: ReportsByServicesGet.SUCCESS;
      payload: AxiosResponse<Array<IReportsByServices>>;
    }
  | {
      type: ReportsByServicesGet.FAILED;
      payload: AxiosError;
    };

export type TReportsActions =
  | TReportsByUsersGetAction
  | TReportsByServicesGetAction
  | TResetAction;
