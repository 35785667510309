import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormCheckbox from '../../Form/FormCheckbox';
import Form from 'react-bootstrap/Form';
import FormErrorText from '../../Form/FormErrorText';
import { ILocation } from '../../../types/Locations';
import { checkAllBoxes } from '../../../helpers/FormHelpers';
import './LocationForm.scss';

interface ILocationForm {
  locations: Array<ILocation>;
  setValue?: (location: string, value: string[]) => void;
  submitFailed?: boolean;
  errors?: Record<string, any>;
}

const LocationForm: React.FC<ILocationForm> = ({
  locations,
  setValue,
  errors,
  submitFailed,
}) => {
  const { t } = useTranslation();
  const hasLocationError = errors ? Object.keys(errors).includes('locations') : false;

  return (
    <>
      <Row>
        <Col sm={12}>
          <Row>
            <Col
              sm={12}
              className={hasLocationError && submitFailed ? 'text-danger' : 'title'}
            >
              {t('user_form_label_locations')}
            </Col>
          </Row>
          <Row className='checkBox-border'>
            <Col sm={12}>
              <Form.Group className='checkBoxMargins'>
                <Form.Check type='checkbox' id='select_all_locations' checked>
                  <Form.Check.Input
                    type='checkbox'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      checkAllBoxes(e, locations, 'locations', false, setValue)
                    }
                  />
                  <Form.Check.Label className='form-check-label'>
                    {t('form_checkall_label')}
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {locations.map(location => (
              <Col sm={6} key={location.id}>
                <FormCheckbox
                  name='locations'
                  label={location.name}
                  value={location.id}
                  id={location.id}
                />
              </Col>
            ))}
          </Row>
          {hasLocationError && submitFailed && (
            <FormErrorText errorText={t('required')} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default LocationForm;
