/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';
import { IPricingModal } from './Services';

//Reservation read modal in docs

export enum ReservationCustomIds {
  CreateNewToGroup = 'CREATE_NEW_RES_TO_MUTLIRES_GROUP',
  EditMultiple = 'EDIT_ALL',
}

export enum ReservationCustomPricingIds {
  BlockTime = 'blockTimeId',
}

export interface IReservation {
  client?: {
    name: string;
    lastname: string;
    phone: string;
    comments?: string;
    email: string;
  };
  id: string | ReservationCustomIds;
  companyId?: string;
  creatorId?: string;
  createdAt?: string;
  updatedAt?: string;
  clientId: string | null;
  locationId: string;
  serviceId: string | undefined;
  servicePricingId: string | ReservationCustomPricingIds;
  start: string;
  end: string;
  price: number;
  duration: number;
  userComments: string;
  code: number | undefined;
  status: string;
  userId: string;
  locale?: string;
  fromApi?: boolean;
  type?: ReservationTypes;
  personsCount?: number;
  isAutoConfirm: boolean;
  isAutoEmployee: boolean;
  voucherNumber?: string;
}

export interface IReservationData {
  id?: string | ReservationCustomIds;
  companyId?: string;
  client?: {
    name: string;
    lastname: string;
    phone: string;
    comments?: string;
    email: string;
  };
  isAutoConfirm?: boolean;
  clientId?: string | null;
  locationId?: string;
  serviceId?: string;
  servicePricingId?: string;
  servicePricing?: IPricingModal;
  userId?: string;
  start: string;
  end: string;
  price?: number;
  duration?: number;
  userComments?: string;
  status?: string;
  isAutoEmployee?: boolean;
  type?: string;
  isPreview?: boolean;
  creatorId?: string;
  fromApi?: boolean;
  personsCount?: number;
  initialData?: IReservationWithInfo;
  voucherNumber?: string;
}

export enum ReservationStatus {
  PRELIMINARY = 'Preliminary',
  CONFIRMED = 'Confirmed',
  FULFILLED = 'Fulfilled',
  CANCELLED = 'Cancelled',
  TEMPORARY = 'Temporary',
}

export enum ReservationTypes {
  BLOCK = 'Block',
  REGULAR = 'Regular',
}

export interface IReservationWithInfo extends IReservation {
  serviceName: string;
  servicePricing: IPricingModal;
  serviceResources?: string[];
  timeAfterService?: number;
  preparingTime?: number;
  employee: string;
  creator: string;
  serviceGroupId?: string;
  serviceCode?: number;
  isBlockTime?: boolean; // we set this ourselves in add-edit modal
}

export interface IReservationDataWithId extends IReservationData {
  id: string;
}

export interface IReservationGroupUpdateParams {
  locationId: string;
  userId: string;
  start: string;
}
export interface IReservationGroupUpdateData {
  userId?: string;
  start?: string;
  end?: string;
  duration?: number;
  userComments?: string;
  isAutoEmployee?: boolean;
}

export interface IGetReservations {
  data: Array<IReservation>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

// /admin-api/Timetable/userServicesAvailability request
export interface IGetUserServicesAvailabilityRequest {
  CompanyId?: string;
  LocationId?: string;
  UserId?: string;
  ServiceId?: string;
  ServicePricingId?: string;
  ServiceDuration?: number;
  ReservationId?: string;
  Start: string;
  End: string;
  MinPersonsCount?: number;
}

// /admin-api/Timetable/userServicesAvailability data
export interface IGetUserServicesAvailability {
  serviceId: string;
  servicePricingId: string;
  serviceDuration: number;
  userId: string;
  start: string;
  end: string;
  availablePersonsCount: number;
}

export interface IReservationAvailability {
  locationId: string;
  userId: string;
  start: string;
  end: string;
}

export interface IReservationAvailabilityData {
  LocationId: string;
  ServicePricingId: string;
  UserId: string | null;
  Start: string;
  End: string;
}

export interface IReservationModalInitialData {
  employee: string;
  location: string;
  time: string;
  personsCount?: number;
}

export interface TReservationState {
  readonly data?: Array<IReservation>;
  readonly calendarData?: Array<IReservation>;
  readonly availableTime?: Array<IReservationAvailability>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
  readonly userAvailableTime?: Array<IGetUserServicesAvailability>;
  readonly filteredReservations?: Array<IReservation>;
}

export const initialReservationState: TReservationState = {
  data: undefined,
  calendarData: undefined,
  availableTime: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
  userAvailableTime: undefined,
  filteredReservations: [],
};

export enum ReservationAdd {
  REQUEST = 'RESERVATION_ADD_REQUEST',
  SUCCESS = 'RESERVATION_ADD_SUCCESS',
  FAILED = 'RESERVATION_ADD_FAILED',
  ADD_RESERVATION_STORE = 'RESERVATION_ADD_TO_STORE',
}

export enum ReservationEdit {
  REQUEST = 'RESERVATION_EDIT_REQUEST',
  SUCCESS = 'RESERVATION_EDIT_SUCCESS',
  FAILED = 'RESERVATION_EDIT_FAILED',
  EDIT_RESERVATION_STORE = 'RESERVATION_EDIT_ADD_TO_STORE',
}

export enum ReservationsGet {
  REQUEST = 'RESERVATIONS_GET_REQUEST',
  SUCCESS = 'RESERVATIONS_GET_SUCCESS',
  FAILED = 'RESERVATIONS_GET_FAILED',
}

export enum ReservationsGetAvailableTime {
  REQUEST = 'RESERVATIONS_GET_AVAILABLE_TIME_REQUEST',
  SUCCESS = 'RESERVATIONS_GET_AVAILABLE_TIME_SUCCESS',
  FAILED = 'RESERVATIONS_GET_AVAILABLE_TIME_FAILED',
}

export enum ReservationsGetAvailableServiceTime {
  REQUEST = 'RESERVATIONS_GET_AVAILABLE_TIME_SERVICE_REQUEST',
  SUCCESS = 'RESERVATIONS_GET_AVAILABLE_TIME_SERVICE_SUCCESS',
  FAILED = 'RESERVATIONS_GET_AVAILABLE_TIME_SERVICE_FAILED',
}

export enum ReservationsPut {
  RESERVATIONS_OFFSET_UPDATE = 'RESERVATIONS_OFFSET_UPDATE',
  RESERVATIONS_CLEAR_DATA = 'RESERVATIONS_CLEAR_DATA',
}

export enum ReservationsGetAll {
  REQUEST = 'RESERVATIONS_GET_ALL_REQUEST',
  SUCCESS = 'RESERVATIONS_GET_ALL_SUCCESS',
  FAILED = 'RESERVATIONS_GET_ALL_FAILED',
}

export enum FilteredReservationsGet {
  REQUEST = 'RESERVATIONS_FILTERED_GET_REQUEST',
  SUCCESS = 'RESERVATIONS_FILTERED_GET_SUCCESS',
  FAILED = 'RESERVATIONS_FILTERED_GET_FAILED',
}
export enum FilteredReservationsSet {
  SET = 'RESERVATIONS_FILTERED_SET',
}

export enum CalendarReservationsGetAll {
  REQUEST = 'CALENDAR_RESERVATIONS_GET_ALL_REQUEST',
  SUCCESS = 'CALENDAR_RESERVATIONS_GET_ALL_SUCCESS',
  FAILED = 'CALENDAR_RESERVATIONS_GET_ALL_FAILED',
}

export type TCalendarReservationsGetAllAction =
  | {
      type: CalendarReservationsGetAll.REQUEST;
    }
  | {
      type: CalendarReservationsGetAll.SUCCESS;
      payload: AxiosResponse<IGetReservations>;
    }
  | {
      type: CalendarReservationsGetAll.FAILED;
      payload: AxiosError;
    };

export type TReservationsGetAllAction =
  | {
      type: ReservationsGetAll.REQUEST;
    }
  | {
      type: ReservationsGetAll.SUCCESS;
      payload: AxiosResponse<IGetReservations>;
    }
  | {
      type: ReservationsGetAll.FAILED;
      payload: AxiosError;
    };

export type TFilteredReservationsGetAction =
  | {
      type: FilteredReservationsGet.REQUEST;
    }
  | {
      type: FilteredReservationsGet.SUCCESS;
      payload: AxiosResponse<IGetReservations>;
    }
  | {
      type: FilteredReservationsGet.FAILED;
      payload: AxiosError;
    };

export type TFilteredReservationsSetAction = {
  type: FilteredReservationsSet.SET;
  payload: any;
};

export enum ReservationDelete {
  REQUEST = 'RESERVATION_DELETE_REQUEST',
  SUCCESS = 'RESERVATION_DELETE_SUCCESS',
  FAILED = 'RESERVATION_DELETE_SUCCESS',
  DELETE_RESERVATION_STORE = 'RESERVATION_DELETE_FROM_STORE',
}

export type TReservationPutAction =
  | {
      type: ReservationsPut.RESERVATIONS_OFFSET_UPDATE;
      payload: number;
    }
  | { type: ReservationsPut.RESERVATIONS_CLEAR_DATA };

export type TReservationEditAction =
  | {
      type: ReservationEdit.REQUEST;
    }
  | {
      type: ReservationEdit.SUCCESS;
    }
  | {
      type: ReservationEdit.EDIT_RESERVATION_STORE;
      payload: IReservation;
    }
  | {
      type: ReservationEdit.FAILED;
      payload: AxiosError;
    };

export type TReservationAddAction =
  | {
      type: ReservationAdd.REQUEST;
    }
  | {
      type: ReservationAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: ReservationAdd.ADD_RESERVATION_STORE;
      payload: IReservation;
    }
  | {
      type: ReservationAdd.FAILED;
      payload: AxiosError;
    };

export type TReservationDeleteAction =
  | {
      type: ReservationDelete.REQUEST;
    }
  | {
      type: ReservationDelete.SUCCESS;
    }
  | {
      type: ReservationDelete.DELETE_RESERVATION_STORE;
      payload: string;
    }
  | {
      type: ReservationDelete.FAILED;
      payload: AxiosError;
    };

export type TReservationsGetAction =
  | {
      type: ReservationsGet.REQUEST;
    }
  | {
      type: ReservationsGet.SUCCESS;
      payload: AxiosResponse<IGetReservations>;
    }
  | {
      type: ReservationsGet.FAILED;
      payload: AxiosError;
    };

export type TReservationsGetAvailableTimeAction =
  | {
      type: ReservationsGetAvailableTime.REQUEST;
    }
  | {
      type: ReservationsGetAvailableTime.SUCCESS;
      payload: AxiosResponse<IReservationAvailability[]>;
    }
  | {
      type: ReservationsGetAvailableTime.FAILED;
      payload: AxiosError;
    };

export type TReservationsGetAvailableServiceTimeAction =
  | {
      type: ReservationsGetAvailableServiceTime.REQUEST;
    }
  | {
      type: ReservationsGetAvailableServiceTime.SUCCESS;
      payload: AxiosResponse<IGetUserServicesAvailability[]>;
    }
  | {
      type: ReservationsGetAvailableServiceTime.FAILED;
      payload: AxiosError;
    };

export type TReservationsActions =
  | TReservationsGetAction
  | TReservationDeleteAction
  | TReservationEditAction
  | TReservationAddAction
  | TReservationsGetAllAction
  | TCalendarReservationsGetAllAction
  | TReservationsGetAvailableTimeAction
  | TReservationPutAction
  | TResetAction
  | TReservationsGetAvailableServiceTimeAction
  | TFilteredReservationsGetAction
  | TFilteredReservationsSetAction;
