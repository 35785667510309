import { IReservationDateTimeUserValues } from 'experience/types/ExperienceFormTypes';
import { IReservationFormValues } from 'experience/types/ExperienceFormTypes';
import { IReservationService } from 'experience/types/ExperienceService';
import { TResetAction } from './App';
import { ICompany } from './Companies';
import { ILocation } from './Locations';

export interface IExperienceWidgetState {
  readonly service?: IReservationService;
  readonly location?: ILocation;
  readonly client?: IReservationFormValues;
  readonly dateTimeUser?: IReservationDateTimeUserValues;
  readonly company?: ICompany;
}

export const initialExperienceWidgetState: IExperienceWidgetState = {
  service: undefined,
  location: undefined,
  client: undefined,
  dateTimeUser: undefined,
  company: undefined,
};

export enum ExperienceWidget {
  CHANGE_SERVICE = 'EXPERIENCE_WIDGET_SERVICE_CHANGE',
  CHANGE_LOCATION = 'EXPERIENCE_WIDGET_LOCATION_CHANGE',
  CHANGE_DATE_TIME_USER = 'EXPERIENCE_WIDGET_DATETIMEUSER_CHANGE',
  CHANGE_CLIENT_INFO = 'EXPERIENCE_WIDGET_CLIENT_INFO_CHANGE',
  CHANGE_COMPANY = 'EXPERIENCE_WIDGET_COMPANY_CHANGE',
}

export type TExperienceWidgetActions =
  | {
      type: ExperienceWidget.CHANGE_SERVICE;
      payload: IReservationService;
    }
  | {
      type: ExperienceWidget.CHANGE_LOCATION;
      payload: ILocation;
    }
  | {
      type: ExperienceWidget.CHANGE_CLIENT_INFO;
      payload: IReservationFormValues;
    }
  | {
      type: ExperienceWidget.CHANGE_DATE_TIME_USER;
      payload: IReservationDateTimeUserValues;
    }
  | {
      type: ExperienceWidget.CHANGE_COMPANY;
      payload: ICompany;
    }
  | TResetAction;
