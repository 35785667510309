import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import AddEditReservationModal from '../Reservations/AddEditReservationModal';
import AddEditClientModal from '../Clients/AddEditClientModal';
import ReservationInfoModal from '../Reservations/ReservationInfoModal';
import {
  clearAllReservationsInStore,
  getFilteredReservations,
  IGetReservationsParams,
} from '../../../actions/ReservationsActions';
import { handleError } from '../../../middlewares/ErrorHandler';
import { IFormError } from '../../../constants/Interfaces';
import useError from '../../../hooks/useError';
import {
  IReservationModalInitialData,
  IReservationWithInfo,
  ReservationCustomIds,
} from '../../../types/Reservations';
import { IClient } from '../../../types/Clients';

import { useStoreState } from 'hooks/useStoreState';
import { getTotalPersonsCount } from 'helpers/CalendarUtils';
import { filterSameReservations } from 'helpers/Reservations';
import ClientReservationsListModal from '../Clients/ClientReservationsListModal';
import { useRef } from 'react';
import { getAllClients, getClientById } from 'actions/ClientsActions';
import useGetDataForRedux from 'hooks/useGetDataForRedux';
import { Roles } from 'constants/Roles';
import UrgentMessageModal from '../Reservations/UrgentMessageModal';

interface ICalendarModalsWrapper {
  openReservationModal: boolean;
  setOpenReservationModal: React.Dispatch<React.SetStateAction<boolean>>;
  reservationToEdit: IReservationWithInfo;
  setReservationToEdit: React.Dispatch<
    React.SetStateAction<IReservationWithInfo | undefined>
  >;
  setClientToEdit: React.Dispatch<React.SetStateAction<IClient | undefined>>;
  clientToEdit?: IClient;
  setReservationInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservationInfoModalOpen: boolean;
  initialReservationData?: IReservationModalInitialData;
  setInitialReservationData: (value: IReservationModalInitialData | undefined) => void;
  onClientEdit?: (clientId: string) => void;
  refetchCalendarProps: IGetReservationsParams;
  urgentMessageModalOpen: boolean;
  setUrgentMessageModalOpen: (value: boolean) => void;
}

const CalendarModalsWrapper: React.FC<ICalendarModalsWrapper> = ({
  openReservationModal,
  reservationInfoModalOpen,
  setOpenReservationModal,
  setReservationInfoModalOpen,
  reservationToEdit,
  setReservationToEdit,
  setClientToEdit,
  clientToEdit,
  initialReservationData,
  setInitialReservationData,
  onClientEdit,
  refetchCalendarProps,
  urgentMessageModalOpen,
  setUrgentMessageModalOpen,
}) => {
  const dispatch = useDispatch();
  const parentReservation = useRef<IReservationWithInfo | undefined>(reservationToEdit);

  const [error, setError] = useState<IFormError>();
  const [selectedClient, setSelectedClient] = useState<{
    name: string;
    lastname: string;
    phone: string;
  }>();
  useError(error, setError);
  //we keep this state here becouse we dont need it in calendar component
  const [reservationsListModalOpen, setReservationsListModalOpen] =
    useState<boolean>(false);
  const [addEditClientModalOpen, setAddEditClientModalOpen] = useState<boolean>(false);
  const [dateTimeCheckIsSkippable, setDateTimeCheckIsSkippable] =
    useState<boolean>(false);

  const {
    reservationsState,
    userState,
    servicesState,
    employeeState,
    clientsState,
    reservationsState: { filteredReservations },
  } = useStoreState();

  useEffect(() => {
    if (
      (reservationToEdit.id && !parentReservation.current?.id) ||
      (reservationToEdit.id === parentReservation.current?.id &&
        JSON.stringify(reservationToEdit) !== JSON.stringify(parentReservation.current))
    ) {
      // save parent reservation if nothing is saved
      // or some values changed except for ID

      // we use it later when we open other reservation
      // windows on top of the original one
      parentReservation.current = reservationToEdit;
    }
  }, [reservationToEdit]);

  const fetchClients = () => {
    if (!userState.data?.roles.includes(Roles.Admin)) return;
    return getAllClients();
  };

  useGetDataForRedux(setError, clientsState.allData, fetchClients());

  const formattedReservation = useMemo(() => {
    if (!reservationToEdit.servicePricing) {
      const {
        serviceId,
        servicePricingId,
        duration,
        price,
        start,
        end,
        userId,
        creatorId,
      } = reservationToEdit;

      const service = servicesState?.allData?.find(ser => ser.id === serviceId);
      const employee = employeeState?.allData?.find(emp => emp.id === userId);
      const creator = employeeState?.allData?.find(emp => emp.id === creatorId);
      const employeeName = `${employee?.name || ''}  ${employee?.lastName || ''}`;
      const creatorName = `${creator?.name || ''}  ${creator?.lastName || ''}`;

      const fullDuration = moment.duration(moment(end).diff(moment(start))).asMinutes();
      return {
        ...reservationToEdit,
        serviceName: service?.name || '',
        servicePricing: {
          id: servicePricingId,
          duration: serviceId ? duration : fullDuration,
          price: price,
        },
        timeAfterService: fullDuration - duration,
        employee: employeeName,
        creator: creatorName,
        serviceId: service?.id,
        reservationDate: moment(start).format('dddd, DD MMMM YYYY'),
      };
    } else {
      return reservationToEdit;
    }
  }, [reservationToEdit, servicesState.allData, employeeState.allData]);

  // add edit reservation modal
  const handleClientAdd = () => {
    setClientToEdit(undefined);
    setAddEditClientModalOpen(true);
  };

  const clearReservationsInStore = async () => {
    if (reservationsState.data) {
      try {
        await dispatch(clearAllReservationsInStore());
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    }
  };

  const employee = useMemo(() => {
    return employeeState.allData?.find(e => e.id === reservationToEdit?.userId);
  }, [employeeState, reservationToEdit]);

  const isMultiReservation =
    Number(filteredReservations?.length) > 1 ||
    Number(reservationToEdit?.personsCount) > 1 ||
    Number(employee?.maxPersonsCount) > 1;

  //reservation info modal functions
  const openClientEdit = async (clientId?: string) => {
    let findClient;

    if (clientId) {
      findClient = (await getClientById(clientId))?.data;
    }

    if (reservationInfoModalOpen && !findClient) {
      // Reservation info modal is open, but client is probably deleted. Do nothing;
      return;
    }
    if (findClient) {
      setClientToEdit(findClient);
    }

    setAddEditClientModalOpen(true);
    setReservationInfoModalOpen(false);
  };

  const handleReservationEdit = (
    reservation: IReservationWithInfo | undefined,
    editMultiple: boolean,
  ) => {
    setOpenReservationModal(true);
    setReservationInfoModalOpen(false);
    if (reservation) {
      setReservationToEdit({
        ...reservation,
        id: editMultiple ? ReservationCustomIds.EditMultiple : reservation.id,
        servicePricing: {
          ...reservation.servicePricing,
          personsCount: reservation.personsCount,
        },
      });
    }
  };

  const handleUrgentMessage = () => {
    setUrgentMessageModalOpen(true);
    setReservationInfoModalOpen(false);
  };

  const closeUrgentMessageModal = () => {
    setUrgentMessageModalOpen(false);
    setReservationInfoModalOpen(true);
  };

  const handleReservationCopy = () => {
    setReservationToEdit(
      reservationToEdit ? { ...reservationToEdit, id: '' } : undefined,
    );
    setOpenReservationModal(true);
    setReservationInfoModalOpen(false);
  };

  const sameReservations = useMemo(
    () => filterSameReservations(reservationToEdit, filteredReservations),
    [reservationToEdit, filteredReservations],
  );

  useEffect(() => {
    if (!reservationToEdit?.userId) return;
    if (!reservationInfoModalOpen && !openReservationModal) return;

    dispatch(
      getFilteredReservations({
        userId: reservationToEdit?.userId,
        LocationId: reservationToEdit?.locationId,
        dateFrom: reservationToEdit?.start,
        dateTo: reservationToEdit?.end,
      }),
    );
  }, [reservationToEdit, dispatch, reservationInfoModalOpen, openReservationModal]);

  const handleMultiReservationCreate = () => {
    if (isMultiReservation) {
      setInitialReservationData({
        employee: reservationToEdit.userId,
        location: reservationToEdit.locationId,
        time: reservationToEdit.start,
        personsCount: getTotalPersonsCount(sameReservations),
      });

      setReservationToEdit({
        ...reservationToEdit,
        id: ReservationCustomIds.CreateNewToGroup,
        client: undefined,
        clientId: null,
      });
      setOpenReservationModal(true);
      setDateTimeCheckIsSkippable(true);
    }
  };

  return (
    <>
      {openReservationModal && (
        <AddEditReservationModal
          setSelectedClient={setSelectedClient}
          refetchCalendarProps={refetchCalendarProps}
          selectedClient={selectedClient}
          setClientToEdit={setClientToEdit}
          show={openReservationModal}
          handleClose={(full?: boolean) => {
            setOpenReservationModal(false);
            setDateTimeCheckIsSkippable(false);

            if (full) {
              parentReservation.current = undefined;
              setReservationToEdit(undefined);
              return;
            }

            if (reservationToEdit.id) {
              setReservationInfoModalOpen(true);
            } else {
              if (parentReservation.current?.id) {
                // User was in COPY reservation window, reset reservationToEdit.id to previously saved parentReservation
                setReservationToEdit({
                  ...reservationToEdit,
                  ...parentReservation.current,
                  clientId:
                    reservationToEdit?.clientId || parentReservation.current.clientId,
                });
                setReservationInfoModalOpen(true);
              } else {
                setReservationToEdit(undefined);
              }
            }
          }}
          setReservationToEdit={setReservationToEdit}
          reservationToEdit={formattedReservation}
          handleClientAdd={handleClientAdd}
          clearReservationsInStore={clearReservationsInStore}
          initialData={initialReservationData}
          isMultiReservation={isMultiReservation}
          isTimeCheckSkippable={dateTimeCheckIsSkippable}
        />
      )}
      {addEditClientModalOpen && (
        <AddEditClientModal
          show={addEditClientModalOpen}
          setReservationToEdit={setReservationToEdit}
          setSelectedClient={setSelectedClient}
          reservationToEdit={formattedReservation}
          clientToEdit={clientToEdit}
          handleClose={() => {
            setAddEditClientModalOpen(false);
            clearReservationsInStore();

            if (reservationToEdit?.id && !openReservationModal) {
              setReservationInfoModalOpen(true);
            } else {
              setClientToEdit(undefined);
            }
          }}
          onClientEdit={onClientEdit}
        />
      )}
      {reservationInfoModalOpen && (
        <ReservationInfoModal
          show={reservationInfoModalOpen}
          clientToEdit={clientToEdit}
          reservationToEdit={formattedReservation}
          handleClientEdit={openClientEdit}
          handleReservationEdit={handleReservationEdit}
          handleReservationCopy={handleReservationCopy}
          clearReservationsInStore={clearReservationsInStore}
          setReservationToEdit={setReservationToEdit}
          displayUrgentMessage
          handleUrgentMessage={handleUrgentMessage}
          handleClose={() => {
            setReservationInfoModalOpen(false);
            setClientToEdit(undefined);
            setReservationToEdit(undefined);

            if (!reservationsListModalOpen) {
              parentReservation.current = undefined;
            }
          }}
          isMultiReservation={isMultiReservation}
          handleMultiReservationCreate={handleMultiReservationCreate}
          onClientNameClick={async (clientId: string) => {
            const client = (await getClientById(clientId)).data;
            if (client) {
              setClientToEdit(client);
              setReservationInfoModalOpen(false);
              setReservationsListModalOpen(true);
            }
          }}
          allowClientEdit={
            reservationToEdit.id === parentReservation.current?.id ||
            reservationToEdit.id === ReservationCustomIds.CreateNewToGroup
          }
        />
      )}

      {reservationsListModalOpen && (
        <ClientReservationsListModal
          show={reservationsListModalOpen}
          clientToEdit={clientToEdit}
          handleClientEdit={() => {
            setAddEditClientModalOpen(true);
          }}
          setReservationToEdit={setReservationToEdit}
          setReservationInfoModalOpen={() => {
            setReservationInfoModalOpen(true);
          }}
          handleClose={() => {
            setReservationsListModalOpen(false);
            setReservationInfoModalOpen(true);

            if (parentReservation.current) {
              setReservationToEdit({
                ...reservationToEdit,
                ...parentReservation.current,
              });
            }
          }}
        />
      )}

      {urgentMessageModalOpen && (
        <UrgentMessageModal
          show={urgentMessageModalOpen}
          handleClose={closeUrgentMessageModal}
          reservations={sameReservations}
        />
      )}
    </>
  );
};

CalendarModalsWrapper.displayName = 'CalendarModalsWrapper';

export default CalendarModalsWrapper;
