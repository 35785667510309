import { AxiosResponse } from 'axios';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';
import {
  IWorkTime,
  IWorkTimeData,
  WorkTimeAdd,
  WorkTimeGet,
  WorkTimePut,
  TWorkTimeGetAction,
  TWorkTimeGetAllAction,
  WorkTimeGetAll,
} from '../types/WorkTime';

interface IGetWorkTime {
  offset: number;
  UserId?: string;
  LocationId?: string;
  sort?: string;
  limit?: number;
}

export interface IGetWorkTimeAll {
  UserId?: string;
  LocationId?: string;
  sort?: string;
  dateFrom?: string;
  dateTo?: string;
}

export const getWorkTimeAll = ({
  UserId,
  LocationId,
  sort,
  dateFrom,
  dateTo,
}: IGetWorkTimeAll) => {
  return apiCall<TWorkTimeGetAllAction, any, IGetWorkTimeAll>(
    WorkTimeGetAll,
    'GET',
    API_END_POINTS.GET_WORK_TIME,
    true,
    null,
    {
      limit: 1000,
      UserId,
      LocationId,
      Sort: sort,
      DateFrom: dateFrom,
      DateTo: dateTo,
    },
  );
};

export const getWorkTime = ({
  offset,
  UserId,
  LocationId,
  sort,
  limit,
}: IGetWorkTime) => {
  return apiCall<TWorkTimeGetAction, any, IWorkTime>(
    WorkTimeGet,
    'GET',
    API_END_POINTS.GET_WORK_TIME,
    true,
    null,
    {
      limit: limit || PAGE_LIMIT,
      UserId,
      LocationId,
      offset,
      Sort: sort,
    },
  );
};

export const changeOffset = (newOffset: number) => {
  return {
    type: WorkTimePut.WORK_TIME_OFFSET_UPDATE,
    payload: newOffset,
  };
};

export const updateWorkTimeInStore = (workTime: IWorkTimeData[], userId: string) => {
  return {
    type: WorkTimeAdd.ADD_WORK_TIME_STORE,
    payload: { workTime, userId },
  };
};

export const clearWorkTimeInStore = () => {
  return {
    type: WorkTimePut.CLEAR_WORK_TIME_STORE,
  };
};

export const addWorkTime = (workTime: IWorkTimeData[], userId: string) => {
  return async (dispatch: TDispatch<WorkTimeAdd>): Promise<AxiosResponse<string>> => {
    dispatch({
      type: WorkTimeAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.ADD_WORK_TIME(userId),
        data: workTime,
      });
      dispatch({
        type: WorkTimeAdd.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: WorkTimeAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
