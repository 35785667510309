import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Toast from '../../components/Utils/Toast';
import Container from 'react-bootstrap/Container';
import { IFormError } from '../../constants/Interfaces';
import useError from '../../hooks/useError';
import TabsForm from '../../components/Utils/Tabs/TabsForm';
import {
  defaultNotificationTranslationKeys,
  INotifications,
  INotificationsState,
} from '../../types/Notifications';
import { IAppState } from '../../store/Store';
import { addNotifications, getNotifications } from '../../actions/NotificationsActions';
import { handleError } from '../../middlewares/ErrorHandler';
import { Button, Col, Row } from 'react-bootstrap';
import FormCheckbox from '../../components/Form/FormCheckbox';
import ViewMailModal from '../../components/Admin/Notifications/ViewMailModal';
import CommonNotificationsForm from '../../components/Admin/Notifications/CommonNotificationsForm';
import { validateNotifications } from '../../helpers/validators';
import { each } from 'lodash';
import { EXPERIENCE_LANGUAGES } from 'constants/Experience';

interface IViewButton {
  text: string;
  handleClick: (values?: INotifications) => void;
  values?: INotifications;
}

const ViewButton: React.FC<IViewButton> = ({ text, handleClick, values }) => {
  return (
    <Button
      size='sm'
      className='grey-borders mr-2 btn btn-light'
      onClick={() => handleClick(values)}
    >
      {text}
    </Button>
  );
};

const AdminNotifications: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  const [remindersTabErrors, setRemindersTabErrors] = useState<boolean>(false);
  const [confirmationsTabErrors, setConfirmationsTabErrors] = useState<boolean>(false);
  const [cancellationsTabErrors, setCancellationsTabErrors] = useState<boolean>(false);
  const [changesTabErrors, setChangesTabErrors] = useState<boolean>(false);
  const [gratitudeTabErrors, setGratitudeTabErrors] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalBody, setModalBody] = useState<string>();
  const [activeTab, setActiveTab] = useState<string>(
    t('settings_notifications_reminders'),
  );
  const commonFormSettings = {
    gratitude: {
      defaultValues: {
        notificationGratitudeEmailTitle: `${t(
          'settings_default_value_notification_gratitude_email_title',
        )}`,
        notificationGratitudeEmailBody: `${t(
          'settings_default_value_notification_gratitude_email_body',
        )}`,
        notificationGratitudeSms: `${t(
          'settings_default_value_notification_gratitude_SMS',
        )}`,
      },
      fieldsToReset: [
        'notificationGratitudeEmailTitle',
        'notificationGratitudeEmailBody',
        'notificationGratitudeSms',
      ],
      inputFields: {
        notificationsOnFieldName: 'notificationGratitudeOn',
        titleFieldName: 'notificationGratitudeEmailTitle',
        bodyFieldName: 'notificationGratitudeEmailBody',
        smsFieldName: 'notificationGratitudeSms',
      },
    },
    cancelled: {
      defaultValues: {
        notificationCancelledEmailTitle: `${t(
          'settings_default_value_notification_cancelled_email_title',
        )}`,
        notificationCancelledEmailBody: `${t(
          'settings_default_value_notification_cancelled_email_body',
        )}`,
        notificationCancelledSms: `${t(
          'settings_default_value_notification_cancelled_SMS',
        )}`,
      },
      fieldsToReset: [
        'notificationCancelledEmailTitle',
        'notificationCancelledEmailBody',
        'notificationCancelledSms',
      ],
      inputFields: {
        notificationsOnFieldName: 'notificationCancelledOn',
        titleFieldName: 'notificationCancelledEmailTitle',
        bodyFieldName: 'notificationCancelledEmailBody',
        smsFieldName: 'notificationCancelledSms',
      },
    },
    changed: {
      defaultValues: {
        notificationUpdatedEmailTitle: `${t(
          'settings_default_value_notification_updated_email_title',
        )}`,
        notificationUpdatedEmailBody: `${t(
          'settings_default_value_notification_updated_email_body',
        )}`,
        notificationUpdatedSms: `${t('settings_default_value_notification_updated_SMS')}`,
      },
      fieldsToReset: [
        'notificationUpdatedEmailTitle',
        'notificationUpdatedEmailBody',
        'notificationUpdatedSms',
      ],
      inputFields: {
        notificationsOnFieldName: 'notificationUpdatedOn',
        titleFieldName: 'notificationUpdatedEmailTitle',
        bodyFieldName: 'notificationUpdatedEmailBody',
        smsFieldName: 'notificationUpdatedSms',
      },
    },
    confirmed: {
      defaultValues: {
        notificationConfirmedEmailTitle: `${t(
          'settings_default_value_notification_confirmed_email_title',
        )}`,
        notificationConfirmedEmailBody: `${t(
          'settings_default_value_notification_confirmed_email_body',
        )}`,
        notificationConfirmedSms: `${t(
          'settings_default_value_notification_confirmed_SMS',
        )}`,
      },
      fieldsToReset: [
        'notificationConfirmedEmailTitle',
        'notificationConfirmedEmailBody',
        'notificationConfirmedSms',
      ],
      inputFields: {
        notificationsOnFieldName: 'notificationConfirmedOn',
        titleFieldName: 'notificationConfirmedEmailTitle',
        bodyFieldName: 'notificationConfirmedEmailBody',
        smsFieldName: 'notificationConfirmedSms',
      },
    },
    reminded: {
      defaultValues: {
        notificationReminderTimeBeforeReservation: '2',
        notificationReminderDoNotSendTime: '1',
        notificationReminderEmailTitle: `${t(
          'settings_default_value_notification_reminder_email_title',
        )}`,
        notificationReminderEmailBody: `${t(
          'settings_default_value_notification_reminder_email_body',
        )}`,
        notificationReminderSms: `${t(
          'settings_default_value_notification_reminder_SMS',
        )}`,
      },
      fieldsToReset: [
        'notificationReminderEmailTitle',
        'notificationReminderEmailBody',
        'notificationReminderSms',
      ],
      inputFields: {
        notificationsOnFieldName: 'notificationReminderOn',
        timeBeforeReservationFieldName: 'notificationReminderTimeBeforeReservation',
        doNotSendTimeFieldName: 'notificationReminderDoNotSendTime',
        titleFieldName: 'notificationReminderEmailTitle',
        bodyFieldName: 'notificationReminderEmailBody',
        smsFieldName: 'notificationReminderSms',
      },
    },
  };

  useError(error, setError);

  const notificationsState: INotificationsState = useSelector<
    IAppState,
    INotificationsState
  >((state: IAppState) => state.notificationsState);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getNotifications());
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, []);

  const tabs = [
    {
      key: 'settings_notifications_reminders',
      component: <CommonNotificationsForm settings={commonFormSettings.reminded} />,
      hasError: remindersTabErrors,
    },
    {
      key: 'settings_notifications_confirmations',
      component: <CommonNotificationsForm settings={commonFormSettings.confirmed} />,
      hasError: confirmationsTabErrors,
    },
    {
      key: 'settings_notifications_updates',
      component: <CommonNotificationsForm settings={commonFormSettings.changed} />,
      hasError: changesTabErrors,
    },
    {
      key: 'settings_notifications_cancellations',
      component: <CommonNotificationsForm settings={commonFormSettings.cancelled} />,
      hasError: cancellationsTabErrors,
    },
    {
      key: 'settings_notifications_gratitude',
      component: <CommonNotificationsForm settings={commonFormSettings.gratitude} />,
      hasError: gratitudeTabErrors,
    },
  ];

  const formValidation = (values: INotifications) => {
    [
      setRemindersTabErrors,
      setConfirmationsTabErrors,
      setChangesTabErrors,
      setCancellationsTabErrors,
      setGratitudeTabErrors,
    ].forEach(f => f(false));
    const setFuncs = {
      confirmations: setConfirmationsTabErrors,
      reminders: setRemindersTabErrors,
      changes: setChangesTabErrors,
      cancellations: setCancellationsTabErrors,
      gratitude: setGratitudeTabErrors,
    };
    const notificationsErrors = validateNotifications(values, setFuncs);
    Object.keys(notificationsErrors).map(
      key => (notificationsErrors[key] = t(`${notificationsErrors[key]}`)),
    );
    return { errors: notificationsErrors };
  };

  const initModalBody = (values?: INotifications) => {
    if (values) {
      const {
        notificationReminderEmailBody,
        notificationCancelledEmailBody,
        notificationConfirmedEmailBody,
        notificationGratitudeEmailBody,
        notificationUpdatedEmailBody,
      } = values;
      switch (activeTab) {
        case t('settings_notifications_reminders'):
          setModalBody(notificationReminderEmailBody);
          break;
        case t('settings_notifications_confirmations'):
          setModalBody(notificationConfirmedEmailBody);
          break;
        case t('settings_notifications_updates'):
          setModalBody(notificationUpdatedEmailBody);
          break;
        case t('settings_notifications_cancellations'):
          setModalBody(notificationCancelledEmailBody);
          break;
        case t('settings_notifications_gratitude'):
          setModalBody(notificationGratitudeEmailBody);
          break;
        default:
          return null;
      }
    }
  };

  const handleViewModal = (values?: INotifications) => {
    initModalBody(values);
    setModalOpen(true);
  };

  const handleSubmit = async (values: INotifications) => {
    setError(undefined);
    try {
      await dispatch(addNotifications(values));
      toast.success(<Toast text={t('addedNotificationSettings')} />);
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };
  const convertNotificationsToTranslated = (notifications?: INotifications) => {
    if (!notifications) {
      return {};
    }
    const translateNotifs: any = {};
    // iterating through translationKeys (DEFAULT CONSTANTS)
    each(defaultNotificationTranslationKeys, (translationKey, defaultNotifType) => {
      // iterating through notifications (FROM API)
      each(notifications, (backendNotif, notificationType) => {
        if (defaultNotifType !== notificationType) return;
        // we do not know wheter the notif is custom or template (user can write anything in the input)
        // so we check if translated value in some language is the same as value from api.
        EXPERIENCE_LANGUAGES.forEach(lang => {
          const translatedValue = t(translationKey, {
            lng: lang,
          });
          if (translatedValue === backendNotif) {
            translateNotifs[notificationType] = t(translationKey);
          } else if (!translateNotifs[notificationType]) {
            translateNotifs[notificationType] = backendNotif;
          }
        });
      });
    });
    return {
      ...translateNotifs,
      ...notifications,
    };
  };

  return (
    <Container fluid className='p-0'>
      <TabsForm
        title={t('settings_notifications_settings_page_title')}
        tabs={tabs}
        validation={formValidation}
        handleSubmit={handleSubmit}
        editValue={convertNotificationsToTranslated(notificationsState.data)}
        handleTabChange={(tab: string) => setActiveTab(tab)}
        formHeader={
          <Row>
            <Col xs={12}>
              <FormCheckbox
                name='notificationsOn'
                label={t('settings_form_label_notifications_on')}
                id='notificationsOn'
              />
            </Col>
          </Row>
        }
        customButton={
          <ViewButton handleClick={handleViewModal} text={t('form_btn_view_mail')} />
        }
      />
      <ViewMailModal
        show={modalOpen}
        modalBody={modalBody}
        handleClose={() => {
          setModalOpen(false);
        }}
      />
    </Container>
  );
};

export default AdminNotifications;
