import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import FormInput from 'components/Form/FormInput';
import './ReservationPricing.scss';
import { formatCurrency } from 'helpers/Currency';
import { IReservationSettingsState } from 'types/ReservationSettings';
import { IAppState } from 'store/Store';
import { useStoreState } from 'hooks/useStoreState';
import { useFormState } from 'react-final-form';

interface Props {
  disabled?: boolean;
  maxPeople: number;
  errors?: Record<string, any>;
}

const ReservationPricing: React.FC<Props> = ({ disabled, maxPeople }) => {
  const { t } = useTranslation();
  const reservationSettingsState: IReservationSettingsState = useSelector<
    IAppState,
    IReservationSettingsState
  >((state: IAppState) => state.reservationSettingsState);
  const { reservationGroupsEnabled } = useStoreState().companyState.userCompany || {};
  const { errors } = useFormState();
  return (
    <Table responsive='md' size='sm' striped className='reservation-pricing-table'>
      <tbody>
        <tr>
          {reservationGroupsEnabled && <th>{t('people_count')}</th>}
          <th>{t('reservation_modal_label_price') + ' *'}</th>
          <th className='reservation-duration'>
            {t('reservation_modal_label_duration') + '*'}
          </th>
          <th className='reservation-duration'>
            {t('reservation_modal_label_time_after') + ' *'}
          </th>
        </tr>
        <tr>
          {reservationGroupsEnabled && (
            <td className='person-count'>
              <FormInput
                disabled={disabled || maxPeople <= 1}
                size='sm'
                name='servicePricing.personsCount'
                type='number'
                inputClass='mb-0'
                wrapperClass='mb-0'
              />
            </td>
          )}
          <td className='pricing'>
            <FormInput
              disabled={disabled}
              size='sm'
              name='servicePricing.price'
              startButton={formatCurrency(reservationSettingsState?.data?.currency)}
              type='text'
              inputClass='mb-0'
              wrapperClass='mb-0'
              fieldError={{
                message: errors?.servicePricing?.price,
                title: errors?.servicePricing?.price,
                causedByField: 'servicePricing.price',
              }}
            />
          </td>
          <td>
            <div className='d-flex pricing-wrapper'>
              <FormInput
                size='sm'
                name='servicePricing.hours'
                type='text'
                inputClass='mb-0'
                endButton={t('twig_time_short_hours')}
                fieldError={{
                  message: errors?.servicePricing?.hours,
                  title: errors?.servicePricing?.hours,
                  causedByField: 'servicePricing.hours',
                }}
              />
              <FormInput
                size='sm'
                name='servicePricing.minutes'
                type='text'
                inputClass='mb-0'
                endButton={t('report_services_duration_minutes_short')}
                fieldError={{
                  message: errors?.servicePricing?.minutes,
                  title: errors?.servicePricing?.minutes,
                  causedByField: 'servicePricing.minutes',
                }}
              />
            </div>
          </td>
          <td>
            <div className='d-flex pricing-wrapper'>
              <FormInput
                size='sm'
                name='servicePricing.hoursAfterService'
                type='text'
                inputClass='mb-0'
                endButton={t('twig_time_short_hours')}
                fieldError={{
                  message: errors?.servicePricing?.hoursAfterService,
                  title: errors?.servicePricing?.hoursAfterService,
                  causedByField: 'servicePricing.hoursAfterService',
                }}
              />
              <FormInput
                size='sm'
                name='servicePricing.minutesAfterService'
                type='text'
                inputClass='mb-0'
                endButton={t('report_services_duration_minutes_short')}
                fieldError={{
                  message: errors?.servicePricing?.minutesAfterService,
                  title: errors?.servicePricing?.minutesAfterService,
                  causedByField: 'servicePricing.minutesAfterService',
                }}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ReservationPricing;
