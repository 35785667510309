import { COMPANY_ID_ITEM } from 'constants/Storage';
import { IClient } from '../types/User';

const jwt = require('jsonwebtoken');

export const getAccessToken = () => {
  return localStorage.getItem('token');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

export const setAccessToken = (token: string) => {
  return localStorage.setItem('token', token);
};

export const setRefreshToken = (token: string) => {
  return localStorage.setItem('refreshToken', token);
};

export const parseUserData = (token: string) => {
  const decodedToken = jwt.decode(token);
  if (!decodedToken) return null;

  const clientInfo: IClient = {
    ...decodedToken.client,
    roles: Array.isArray(decodedToken.role) ? decodedToken.role : [decodedToken.role],
    expiresIn: parseInt(decodedToken.exp, 10) * 1000,
    email: decodedToken.email,
    clientId: decodedToken.client_id,
    name: decodedToken.name,
    companyId: decodedToken.CompanyId || null,
    userId: decodedToken.sub,
  };
  return clientInfo;
};

export const isSuperViewerMode = () => {
  return localStorage.getItem(COMPANY_ID_ITEM);
};
