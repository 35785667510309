import { CurrencySymbols, SettingsCurrencies } from 'types/ReservationSettings';

export const formatCurrency = (currency: SettingsCurrencies | undefined) => {
  if (!currency) return '';
  return CurrencySymbols[currency];
};

export const getInitialPrice = (salePrice?: number, price?: number) => {
  if (salePrice != null && price != null) {
    return salePrice === price ? price : salePrice;
  }
  return price;
};
