import { AxiosResponse } from 'axios';
import {
  ExperienceClientAdd,
  IExperienceClientData,
  IExperienceClientDataWithId,
} from 'experience/types/ExperienceClients';
import { TDispatch } from 'types/Thunk';
import { API_END_POINTS } from 'constants/API';
import { apiClient } from 'actions/BaseAction';

export const addNewExperienceClientToStore = (client: IExperienceClientDataWithId) => {
  return {
    type: ExperienceClientAdd.ADD_CLIENT_STORE,
    payload: client,
  };
};

export const addExperienceClient = (client: IExperienceClientData) => {
  return async (
    dispatch: TDispatch<ExperienceClientAdd>,
  ): Promise<AxiosResponse<string>> => {
    dispatch({
      type: ExperienceClientAdd.REQUEST,
    });

    try {
      const response = await apiClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_EXPERIENCE_CLIENT,
        data: client,
      });
      dispatch({
        type: ExperienceClientAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        dispatch(addNewExperienceClientToStore({ id: response.data, ...client }));
      }
      return response;
    } catch (error) {
      dispatch({
        type: ExperienceClientAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
