/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';
import { ILocation } from './Locations';
//User read modal in docs
export interface IEmployee {
  id: string;
  username: string;
  email?: string;
  roles?: Array<string>;
  companyId?: string;
  name: string;
  lastName?: string;
  phoneNumber?: string;
  createdAt?: string;
  updatedAt?: string;
  locale?: string;
  serviceIds?: Array<string>;
  locationIds?: Array<string>;
  companyName?: string;
  locations?: Array<ILocation>;
  maxPersonsCount: number;
}

export interface IEmployeeData {
  company: string;
  companyName?: string;
  email?: string;
  lastName: string;
  name: string;
  password: string;
  passwordRepeat: string;
  phoneNumber?: string;
  roles: Array<string>;
  username?: string;
  serviceIds?: Array<string>;
  locationIds?: Array<string>;
  id?: string;
  maxPersonsCount: number;
}

export interface IGetEmployees {
  data: Array<IEmployee>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

export interface TEmployeeState {
  readonly data?: Array<IEmployee>;
  readonly allData?: Array<IEmployee>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
}

export const initialEmployeeState: TEmployeeState = {
  data: undefined,
  allData: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
};

export interface IGetEmployeeStatusData {
  data: {
    status: EmployeeStatus;
  };
}

export enum EmployeeStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SENT = 'SentForApproval',
}

export enum EmployeeAdd {
  REQUEST = 'EMPLOYEE_ADD_REQUEST',
  SUCCESS = 'EMPLOYEE_ADD_SUCCESS',
  FAILED = 'EMPLOYEE_ADD_FAILED',
  ADD_EMPLOYEE_STORE = 'EMPLOYEE_ADD_TO_STORE',
}

export enum EmployeesGetAll {
  REQUEST = 'EMPLOYEE_GET_ALL_REQUEST',
  SUCCESS = 'EMPLOYEE_GET_ALL_SUCCESS',
  FAILED = 'EMPLOYEE_GET_ALL_FAILED',
}

export enum EmployeeEdit {
  REQUEST = 'EMPLOYEE_EDIT_REQUEST',
  SUCCESS = 'EMPLOYEE_EDIT_SUCCESS',
  FAILED = 'EMPLOYEE_EDIT_FAILED',
  EDIT_EMPLOYEE_STORE = 'EMPLOYEE_EDIT_ADD_TO_STORE',
}

export enum EmployeeEditAvatar {
  REQUEST = 'EMPLOYEE_EDIT_AVATAR_REQUEST',
  SUCCESS = 'EMPLOYEE_EDIT_AVATAR_SUCCESS',
  FAILED = 'EMPLOYEE_EDIT_AVATAR_FAILED',
}

export enum EmployeesGet {
  REQUEST = 'EMPLOYEES_GET_REQUEST',
  SUCCESS = 'EMPLOYEES_GET_SUCCESS',
  FAILED = 'EMPLOYEES_GET_FAILED',
}

export enum EmployeesGetAvatar {
  REQUEST = 'EMPLOYEES_GET_AVATAR_REQUEST',
  SUCCESS = 'EMPLOYEES_GET_AVATAR_SUCCESS',
  FAILED = 'EMPLOYEES_GET_AVATAR_FAILED',
}

export enum EmployeesPutAvatar {
  REQUEST = 'EMPLOYEES_PUT_AVATAR_REQUEST',
  SUCCESS = 'EMPLOYEES_PUT_AVATAR_SUCCESS',
  FAILED = 'EMPLOYEES_PUT_AVATAR_FAILED',
}

export enum EmployeesPut {
  EPLOYEES_OFFSET_UPDATE = 'EPLOYEES_OFFSET_UPDATE',
}

export enum EmployeeDelete {
  REQUEST = 'EMPLOYEE_DELETE_REQUEST',
  SUCCESS = 'EMPLOYEE_DELETE_SUCCESS',
  FAILED = 'EMPLOYEE_DELETE_SUCCESS',
  DELETE_COMPANY_STORE = ' EMPLOYEE_DELETE_FROM_STORE',
}

export enum EmployeeSendStatusEmail {
  REQUEST = 'EMPLOYEE_SEND_STATUS_REQUEST',
  SUCCESS = 'EMPLOYEE_SEND_STATUS_SUCCESS',
  FAILED = 'EMPLOYEE_SEND_STATUS_FAILED',
}

export type TEmployeePutAction = {
  type: EmployeesPut.EPLOYEES_OFFSET_UPDATE;
  payload: number;
};

export type TEmployeesSendStatusEmail =
  | {
      type: EmployeeSendStatusEmail.REQUEST;
    }
  | {
      type: EmployeeSendStatusEmail.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: EmployeeSendStatusEmail.FAILED;
      payload: AxiosError;
    };

export type TEmployeeGetAllAction =
  | {
      type: EmployeesGetAll.REQUEST;
    }
  | {
      type: EmployeesGetAll.SUCCESS;
      payload: AxiosResponse<IGetEmployees>;
    }
  | {
      type: EmployeesGetAll.FAILED;
      payload: AxiosError;
    };

export type TEmployeeEditAction =
  | {
      type: EmployeeEdit.REQUEST;
    }
  | {
      type: EmployeeEdit.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: EmployeeEdit.EDIT_EMPLOYEE_STORE;
      payload: IEmployee;
    }
  | {
      type: EmployeeEdit.FAILED;
      payload: AxiosError;
    };

export type TEmployeesEditAvatarAction =
  | {
      type: EmployeeEditAvatar.REQUEST;
    }
  | {
      type: EmployeeEditAvatar.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: EmployeeEditAvatar.FAILED;
      payload: AxiosError;
    };

export type TEmployeeAddAction =
  | {
      type: EmployeeAdd.REQUEST;
    }
  | {
      type: EmployeeAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: EmployeeAdd.ADD_EMPLOYEE_STORE;
      payload: IEmployee;
    }
  | {
      type: EmployeeAdd.FAILED;
      payload: AxiosError;
    };

export type TEmployeeDeleteAction =
  | {
      type: EmployeeDelete.REQUEST;
    }
  | {
      type: EmployeeDelete.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: EmployeeDelete.DELETE_COMPANY_STORE;
      payload: string;
    }
  | {
      type: EmployeeDelete.FAILED;
      payload: AxiosError;
    };

export type TEmployeesGetAction =
  | {
      type: EmployeesGet.REQUEST;
    }
  | {
      type: EmployeesGet.SUCCESS;
      payload: AxiosResponse<IGetEmployees>;
    }
  | {
      type: EmployeesGet.FAILED;
      payload: AxiosError;
    };

export type TEmployeesGetAvatarAction =
  | {
      type: EmployeesGetAvatar.REQUEST;
    }
  | {
      type: EmployeesGetAvatar.SUCCESS;
      payload: AxiosResponse<File>;
    }
  | {
      type: EmployeesGetAvatar.FAILED;
      payload: AxiosError;
    };

export type TEmployeesActions =
  | TEmployeesGetAction
  | TEmployeeDeleteAction
  | TEmployeeEditAction
  | TEmployeeGetAllAction
  | TEmployeeAddAction
  | TEmployeePutAction
  | TEmployeesGetAvatarAction
  | TEmployeesEditAvatarAction
  | TResetAction
  | TEmployeesSendStatusEmail;
