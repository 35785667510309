import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormCheckbox from '../../Form/FormCheckbox';
import Form from 'react-bootstrap/Form';
import FormErrorText from '../../Form/FormErrorText';
import { checkAllBoxes } from '../../../helpers/FormHelpers';
import { IEmployee } from '../../../types/Employees';

interface IEmployeeForm {
  employees: Array<IEmployee>;
  setValue?: (location: string, value: string[]) => void;
  submitFailed?: boolean;
  errors?: Record<string, any>;
}

const EmployeeForm: React.FC<IEmployeeForm> = ({
  employees,
  setValue,
  errors,
  submitFailed,
}) => {
  const { t } = useTranslation();
  const hasEmployeeError = errors ? Object.keys(errors).includes('userIds') : false;

  return (
    <>
      <Row>
        <Col sm={12}>
          <Row>
            <Col
              sm={12}
              className={hasEmployeeError && submitFailed ? 'text-danger' : 'title'}
            >
              {t('service_form_label_users')}
            </Col>
          </Row>
          <Row className='checkBox-border'>
            <Col sm={12}>
              <Form.Group className='checkBoxMargins'>
                <Form.Check type='checkbox' id='select_all_employees' checked>
                  <Form.Check.Input
                    type='checkbox'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      checkAllBoxes(e, employees, 'userIds', false, setValue)
                    }
                  />
                  <Form.Check.Label className='form-check-label'>
                    {t('form_checkall_label')}
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {employees.map(employee => (
              <Col sm={6} key={employee.id}>
                <FormCheckbox
                  name='userIds'
                  label={employee.name}
                  value={employee.id}
                  id={employee.id}
                />
              </Col>
            ))}
          </Row>
          {hasEmployeeError && submitFailed && (
            <FormErrorText errorText={t('required')} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default EmployeeForm;
