export interface IExperienceClientData {
  name: string;
  phone?: string;
  lastName?: string;
  email?: string;
}

export interface IExperienceClientDataWithId extends IExperienceClientData {
  id: string;
}

export enum ExperienceClientAdd {
  REQUEST = 'EXPERIENCE_CLIENT_ADD_REQUEST',
  SUCCESS = 'EXPERIENCE_CLIENT_ADD_SUCCESS',
  FAILED = 'EXPERIENCE_CLIENT_ADD_FAILED',
  ADD_CLIENT_STORE = 'EXPERIENCE_CLIENT_ADD_TO_STORE',
}
