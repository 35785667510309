import { AxiosError } from 'axios';
import { TResetAction } from 'types/App';

export interface IEmployeesExperience {
  id: string;
  firstName: string;
  lastName: string;
  locationIds: Array<string>;
}

export interface IExperienceEmployeesState {
  readonly data?: Array<IEmployeesExperience>;
  readonly isLoading?: boolean;
}

export const initialExperienceEmployeesState: IExperienceEmployeesState = {
  data: undefined,
  isLoading: undefined,
};

export interface IEmployeesGetExperience {
  data: Array<IEmployeesExperience>;
  status: number;
  statusText: string;
}

export enum EmployeesGetExperience {
  REQUEST = 'EMPLOYEES_GET_EXPERIENCE_REQUEST',
  SUCCESS = 'EMPLOYEES_GET_EXPERIENCE_SUCCESS',
  FAILED = 'EMPLOYEES_GET_EXPERIENCE_FAILED',
}

export type TEmployeesGetExperienceAction =
  | {
      type: EmployeesGetExperience.REQUEST;
    }
  | {
      type: EmployeesGetExperience.SUCCESS;
      payload: IEmployeesGetExperience;
    }
  | {
      type: EmployeesGetExperience.FAILED;
      payload: AxiosError;
    }
  | TResetAction;
