import React from 'react';
import './Avatar.scss';

interface ICircleAvatar {
  src: string;
}

const CircleAvatar = ({ src }: ICircleAvatar) => {
  return (
    <div
      style={{
        backgroundImage: `url(${src})`,
      }}
      className='circle--avatar'
    />
  );
};

export default React.memo(CircleAvatar);
