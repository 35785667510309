import React from 'react';
import './ExperienceTimeSelect.scss';

interface IExperienceTimeSelect {
  availableTimes: string[];
  active?: string;
  handleTimeSelection: (time: string) => void;
}

const ExperienceTimeSelect = ({
  active,
  handleTimeSelection,
  availableTimes,
}: IExperienceTimeSelect) => {
  return (
    <div className='experience__time-select'>
      {availableTimes.sort().map((time, i) => (
        <p
          className={active === time ? 'active' : ''}
          onClick={() => handleTimeSelection(time)}
          key={i}
        >
          {time}
        </p>
      ))}
    </div>
  );
};

export default ExperienceTimeSelect;
