import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import './TabsForm.scss';
import Card from 'react-bootstrap/Card';

interface ITab {
  key: string;
  component: JSX.Element;
  hasError: boolean;
}

interface ITabsForm {
  tabs: ITab[];
  validation: (values: any) => Record<string, any>; //todo multiple types (values: INotifications | IReservationSettings)
  handleSubmit: (values: any) => void; //todo multiple types (values: INotifications | IReservationSettings)
  editValue?: Record<string, any>;
  customButton?: any;
  title?: string;
  formHeader?: ReactNode;
  handleTabChange?: (tab: string) => void;
}

const TabsForm: React.FC<ITabsForm> = ({
  tabs,
  validation,
  handleSubmit,
  editValue,
  customButton,
  title,
  formHeader,
  handleTabChange,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);
  const modalSubmit = async (values: any) => {
    await handleSubmit?.(values);
  };

  return (
    <Card className='mainCard tabs-form m-0'>
      {title && <Card.Header className='title-big'>{title}</Card.Header>}

      <FinalForm
        onSubmit={modalSubmit}
        initialValues={editValue}
        mutators={{
          // expect (field, value) args from the mutator
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        validate={(values: any) => {
          const errors = validation(values);
          // map validated errors object and spread the object to return it for react-final-form
          const keys = Object.keys(errors);
          let fullError: Record<string, any> = {};
          keys.forEach(key => (fullError = { ...fullError, ...errors[key] }));
          return fullError;
        }}
        render={({
          form,
          handleSubmit,
          errors,
          form: {
            mutators: { push, pop },
          },
          submitting,
          values,
          ...rest
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {formHeader && (
                <Card.Header className='tabs-form-header'>{formHeader}</Card.Header>
              )}
              <Card.Body>
                <Tabs
                  id='controlled-tab'
                  activeKey={activeTab}
                  onSelect={k => {
                    k && setActiveTab(k);
                    k && handleTabChange?.(k);
                  }}
                >
                  {tabs.map(tab => {
                    const translatedText = t(tab.key);
                    return (
                      <Tab
                        eventKey={tab.key}
                        title={translatedText}
                        className='tab'
                        key={`tab.${translatedText}`}
                        tabClassName={
                          tab.hasError && rest.submitFailed ? 'text-danger' : ''
                        }
                      >
                        {React.cloneElement(tab.component, {
                          errors,
                          submitFailed: rest.submitFailed,
                          setValue: form.mutators.setValue,
                          push,
                          pop,
                          values,
                        })}
                      </Tab>
                    );
                  })}
                </Tabs>
              </Card.Body>
              <Card.Footer className='left-container d-flex bg-white'>
                {/* close and save buttons */}
                <Button
                  variant='primary'
                  disabled={submitting}
                  type='submit'
                  className='btn-sm mr-1'
                >
                  {t('form_btn_save')}
                </Button>
                {customButton &&
                  React.cloneElement(customButton, {
                    values,
                  })}
              </Card.Footer>
            </form>
          </>
        )}
      />
    </Card>
  );
};

export default TabsForm;
