import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IFormError } from '../constants/Interfaces';
import { handleError } from '../middlewares/ErrorHandler';

const useGetDataForRedux = (
  setError: (value: IFormError) => void,
  currentState: any,
  action: any,
  setLoading?: (val: boolean) => void,
) => {
  const dispatch = useDispatch();
  const tries = useRef(0);

  useEffect(() => {
    (async () => {
      if (!action) return;
      if (!currentState && tries.current === 0) {
        try {
          setLoading?.(true);
          await dispatch(action);
          setLoading?.(false);
        } catch (err) {
          setLoading?.(false);
          handleError(
            err?.response?.status,
            setError,
            err.response?.data?.title,
            err.response?.data?.CausedByField,
          );
        }
      }
    })();
  }, [dispatch, currentState]);

  return null;
};

export default useGetDataForRedux;
