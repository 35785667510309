import React, { useState, useEffect } from 'react';
import moment from 'moment';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import './WeekPicker.scss';
import { SettingsLocales } from 'types/ReservationSettings';
import { cs as csLocale } from 'date-fns/locale';

interface IWeekPicker {
  weekStartDay: string;
  weekEndDay: string;
  handleWeek: any;
}

const WeekPicker: React.FC<IWeekPicker> = ({ weekStartDay, weekEndDay, handleWeek }) => {
  const [formatedPickerLabel, setFormatedPickerLabel] = useState<string>();

  const language = i18next.language;
  const { t } = useTranslation();

  const highlightWeek = (e: React.MouseEvent) => {
    const weekNode = e.currentTarget?.parentElement?.parentElement;
    weekNode?.classList.add('highlight');
  };

  const removeHighlightWeek = (e: React.MouseEvent) => {
    const weekNode = e.currentTarget?.parentElement?.parentElement;
    weekNode?.classList.remove('highlight');
  };

  const renderDayContents = (day: number) => {
    return (
      <div
        onMouseOver={e => highlightWeek(e)}
        onMouseLeave={removeHighlightWeek}
        className='customDayInWeek'
      >
        {day}
      </div>
    );
  };

  const formatMonth = (day: string) => {
    const month = +moment(day).format('MM');
    const month2digit = month > 9 ? '' + month : '0' + month;
    return t(`employee_calendar_month_genitive_${month2digit}`);
  };

  const formatDay = (day: string) => {
    return moment(day).format('D');
  };

  useEffect(() => {
    let formated;
    if (moment(weekStartDay).month() === moment(weekEndDay).month()) {
      formated = `${formatMonth(weekStartDay)} ${formatDay(weekStartDay)} - ${formatDay(
        weekEndDay,
      )}, ${moment(weekStartDay).format('YYYY')}`;
    } else {
      formated = `${formatMonth(weekStartDay)} ${formatDay(weekStartDay)} - ${formatMonth(
        weekEndDay,
      )} ${formatDay(weekEndDay)}, ${moment(weekStartDay).format('YYYY')}`;
    }
    setFormatedPickerLabel(formated);
  }, [weekStartDay, weekEndDay]);

  return (
    <DatePicker
      selectsRange
      startDate={moment(weekStartDay).toDate()}
      endDate={moment(weekEndDay).toDate()}
      onChange={date => handleWeek(date)}
      dateFormat='MMMM d, yyyy'
      className='calendar-picker btn-wide'
      renderDayContents={renderDayContents}
      locale={
        language.toLowerCase() === SettingsLocales.CZ.toLowerCase() ? csLocale : language
      }
      customInput={<span className='customPickerInput'>{formatedPickerLabel}</span>}
    />
  );
};

export default WeekPicker;
