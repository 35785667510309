import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FaFolderOpen } from 'react-icons/fa';
import TableSearch from '../../Utils/Tables/TableSearch';
import LocationSelect from './LocationSelect';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import { ILocation } from '../../../types/Locations';
import { getServices } from '../../../actions/ServicesActions';
import './ServiceTableUtils.scss';
import { IServiceGroup } from '../../../types/ServiceGroups';
import { IFormError } from '../../../constants/Interfaces';

interface IServiceTableUtils {
  selectOptions: ILocation[];
  setGroupModalOpen: (value?: IServiceGroup) => void;
  setLoading: (value: boolean) => void;
  selectedLocation: string;
  setSelectedLocation: (value: string) => void;
}

const ServiceTableUtils: React.FC<IServiceTableUtils> = ({
  selectOptions,
  setGroupModalOpen,
  setLoading,
  selectedLocation,
  setSelectedLocation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const handleSearch = async () => {
    try {
      setLoading(true);
      await dispatch(getServices(0, searchQuery, '', selectedLocation || ''));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };

  const handleSelectSearch = async (e: React.ChangeEvent<{ value: string }>) => {
    try {
      setLoading(true);
      setSelectedLocation(e.target.value);
      await dispatch(getServices(0, searchQuery, '', e.target.value));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };

  return (
    <Row>
      <Col sm={12} md={6} lg={5}>
        <TableSearch
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          noInputClass
        />
        <LocationSelect
          selectPlaceHolder={t('filter_select_location')}
          handleSearch={handleSelectSearch}
          options={selectOptions}
          selectedLocation={selectedLocation}
        />
      </Col>
      <Col sm={12} md={6} lg={7} className='d-flex justify-content-end align-items-start'>
        <Button
          className='btn-info centerIcon btn-mobile-full-width'
          onClick={() => setGroupModalOpen(undefined)}
        >
          <FaFolderOpen className='pr-1' />
          {t('service_btn_create_service_group')}
        </Button>
      </Col>
    </Row>
  );
};

export default ServiceTableUtils;
