import React, { useState } from 'react';
import clsx from 'clsx';
import './ServiceCategory.scss';

interface IServiceCategory {
  title: string;
  count: number;
  children: React.ReactNode;
}

const ServiceCategory = ({ title, count, children }: IServiceCategory) => {
  const [expanded, setExpanded] = useState(false);

  if (count <= 0) return null;

  return (
    <div className={clsx('experience__service-category', expanded && 'expanded')}>
      <h4 onClick={() => setExpanded(prev => !prev)}>
        {title} <span className='experience__service-category--count'>{count}</span>
      </h4>
      <div className='experience__service-category--items'>{children}</div>
    </div>
  );
};

export default ServiceCategory;
