import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { extendMoment } from 'moment-range';
import {
  IReservationAvailability,
  IReservationData,
  IReservationModalInitialData,
  IReservationWithInfo,
  ReservationCustomIds,
  ReservationStatus,
  ReservationTypes,
} from 'types/Reservations';
import useError from 'hooks/useError';
import { useStoreState } from 'hooks/useStoreState';
import useGetDataForRedux from 'hooks/useGetDataForRedux';
import { handleError } from 'middlewares/ErrorHandler';
import {
  addEditedReservationToStore,
  addReservation,
  clearAllReservationsInStore,
  editReservation,
  getAllReservationsCalendar,
  getReservationAvailableTime,
  IGetReservationsParams,
  updateGroupReservation,
} from 'actions/ReservationsActions';
import { getAllServices } from 'actions/ServicesActions';
import { getAllLocations } from 'actions/LocationActions';
import { getAllServiceGroups } from 'actions/ServiceGroupsActions';
import { getAllEmployees } from 'actions/EmployeesActions';
import { IFormError } from 'constants/Interfaces';
import Toast from 'components/Utils/Toast';
import FormModalExtended from 'components/Utils/Modals/FormModalExtended';
import AgreeToDelete from 'components/Utils/Modals/AgreeToDelete';
import AddEditReservationForm from './AddEditReservationForm';
import './AddEditReservationModal.scss';
import * as Moment from 'moment';
import { useMemo } from 'react';
import { getUpdatedClientObject } from 'reducers/ReservationsReducer';
import { getClientById } from 'actions/ClientsActions';
import { addMinutesToDate, getDuration } from 'helpers/DateTime';
import { ClientIdStatuses } from 'types/Clients';

const moment = extendMoment(Moment as any);

interface IAddEditReservationModal {
  show: boolean;
  handleClose: (full?: boolean) => void;
  reservationToEdit: IReservationWithInfo;
  handleClientAdd: () => void;
  clearReservationsInStore: () => void;
  initialData?: IReservationModalInitialData;
  setReservationToEdit: React.Dispatch<
    React.SetStateAction<IReservationWithInfo | undefined>
  >;
  setSelectedClient: (data: any) => void;
  selectedClient: any;
  isMultiReservation: boolean;
  isTimeCheckSkippable: boolean;
  setClientToEdit: (data: any) => void;
  refetchCalendarProps?: IGetReservationsParams;
}

const AddEditReservationModal: React.FC<IAddEditReservationModal> = ({
  show,
  refetchCalendarProps,
  handleClose,
  reservationToEdit,
  handleClientAdd,
  clearReservationsInStore,
  initialData,
  setReservationToEdit,
  selectedClient,
  setSelectedClient,
  isMultiReservation,
  isTimeCheckSkippable,
  setClientToEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [errorPopupIsOpen, setErrorPopupIsOpen] = useState<boolean>(false);
  const [submitFailed, setSubmitFailed] = useState<boolean>(false);
  const [confirmedFormValues, setConfirmedFormValues] = useState<IReservationWithInfo>();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [selectedServiceId, setSelectedServiceId] = useState<string>(
    reservationToEdit?.serviceId || '',
  );
  const [selectedLocationId, setSelectedLocationId] = useState<string>(
    reservationToEdit?.locationId || initialData?.location || '',
  );
  const {
    userState,
    locationState,
    employeeState,
    servicesState,
    servicesGroupState,
    reservationsState: { filteredReservations, calendarData },
  } = useStoreState();

  useGetDataForRedux(setError, servicesGroupState.allData, getAllServiceGroups());
  useGetDataForRedux(setError, servicesState.allData, getAllServices());
  useGetDataForRedux(setError, locationState.allData, getAllLocations());
  useGetDataForRedux(setError, employeeState.allData, getAllEmployees());
  useError(error, setError, false);

  const [calendarAutoUserError, setCalendarAutoUserError] = useState<boolean>(false);
  const [calendarWorkingHoursError, setCalendarWorkingHoursError] =
    useState<boolean>(false);
  const [calendarUserIsBusy, setCalendarUserIsBusy] = useState<boolean>(false);

  const initialReservationValues = useMemo(() => {
    const client = reservationToEdit.client;
    return {
      ...reservationToEdit,
      client: {
        email: client?.email || '',
        lastname: client?.lastname || '',
        name: client?.name || '',
        phone: client?.phone || '',
        comments: client?.comments || '',
      },
      clientId: reservationToEdit.clientId,
    } as IReservationWithInfo;
  }, []);

  useEffect(() => {
    (async () => {
      await clearReservationsInStore();
    })();
  }, []);

  useEffect(() => {
    return () => {
      setSelectedClient(undefined);
      setSelectedLocationId('');
    };
  }, []);

  const handleCloseAndClear = async () => {
    await clearReservationsInStore();
    setSelectedClient(undefined);
    handleClose();
  };

  const maxPeopleForSelectedUser = useMemo(() => {
    if (!selectedUserId) return undefined;

    const empl = (employeeState.allData || []).find(e => e.id === selectedUserId);

    if (!empl) return undefined;

    const { maxPersonsCount } = empl;

    return Number(maxPersonsCount || 1);
  }, [selectedUserId, reservationToEdit, filteredReservations]);

  const currentResPeopleCount = useMemo(() => {
    let total = 0;
    filteredReservations?.forEach(res => {
      if (
        !moment(reservationToEdit.start).isSame(res.start) ||
        res.locationId !== reservationToEdit.locationId ||
        res.userId !== reservationToEdit.userId
      ) {
        return;
      }

      total += Number(res.personsCount || 1);
    });

    return total;
  }, [reservationToEdit, filteredReservations]);

  const maxPeopleForSelectedUserInCurrentReservation = useMemo(() => {
    let maxPersonsCount = maxPeopleForSelectedUser || 1;

    filteredReservations?.forEach(res => {
      if (res.id === reservationToEdit.id) return;
      if (
        !moment(reservationToEdit.start).isSame(res.start) ||
        res.locationId !== reservationToEdit.locationId ||
        res.userId !== selectedUserId
      )
        return;

      maxPersonsCount -= Number(res.personsCount);
    });

    return Number(maxPersonsCount);
  }, [selectedUserId, maxPeopleForSelectedUser, reservationToEdit, filteredReservations]);

  const validateServiceDuration = (
    hours?: string,
    minutes?: string,
    allowZero = false,
  ) => {
    const errors = { hourError: '', minuteError: '' };

    if (!hours && !minutes) {
      errors.hourError = t('service_form_duration_at_least_one_filled');
    }
    if (hours && !(parseInt(hours, 10) >= 0 && parseInt(hours, 10) <= 23)) {
      errors.hourError = t('service_form_duration_hours_error');
    }
    if (minutes && !(parseInt(minutes, 10) >= 0 && parseInt(minutes, 10) <= 59)) {
      errors.minuteError = t('service_form_duration_minutes_error');
    }
    if (!allowZero && hours === '0' && !minutes) {
      errors.hourError = t('service_form_duration_hours_error');
    }
    if (!allowZero && minutes === '0' && !hours) {
      errors.minuteError = t('service_form_duration_minutes_error');
    }
    if (!allowZero && hours === '0' && minutes === '0') {
      errors.hourError = t('service_form_duration_hours_error');
      errors.minuteError = t('service_form_duration_minutes_error');
    }

    return errors;
  };

  const formValidation = (values: IReservationWithInfo) => {
    const reservationErrors: Record<string, any> = {};
    reservationErrors.servicePricing = {};

    if (values.serviceId && !values.clientId && !selectedClient?.name) {
      reservationErrors.clientId = t('required');
    }
    if (!values.isBlockTime && !values.serviceId) {
      reservationErrors.serviceId = t('required');
    }
    if (!values.locationId && !selectedLocationId) {
      reservationErrors.locationId = t('required');
    }

    if (!values.isBlockTime) {
      let errors = validateServiceDuration(
        values.servicePricing?.hours?.toString(),
        values.servicePricing?.minutes?.toString(),
      );
      if (errors.hourError) reservationErrors.servicePricing.hours = errors.hourError;
      if (errors.minuteError)
        reservationErrors.servicePricing.minutes = errors.minuteError;

      errors = validateServiceDuration(
        values.servicePricing?.hoursAfterService?.toString(),
        values.servicePricing.minutesAfterService?.toString(),
        true,
      );
      if (errors.hourError)
        reservationErrors.servicePricing.hoursAfterService = errors.hourError;
      if (errors.minuteError)
        reservationErrors.servicePricing.minutesAfterService = errors.minuteError;
    }

    if (
      !values.isBlockTime &&
      ((maxPeopleForSelectedUserInCurrentReservation !== undefined &&
        reservationToEdit.id !== ReservationCustomIds.EditMultiple) ||
        reservationToEdit.id === ReservationCustomIds.EditMultiple)
    ) {
      if (
        !values.servicePricing?.personsCount ||
        values.servicePricing.personsCount <= 0
      ) {
        reservationErrors.maxPeopleError = t('error_enter_people_amount');
      } else if (
        (reservationToEdit.id === ReservationCustomIds.EditMultiple && // editing multiple reservations
          currentResPeopleCount > Number(maxPeopleForSelectedUser)) ||
        (reservationToEdit.id !== ReservationCustomIds.EditMultiple && // creating new single reservation
          values.servicePricing?.personsCount >
            Number(maxPeopleForSelectedUserInCurrentReservation || 1))
      ) {
        reservationErrors.maxPeopleError = t('max_people_limit_exceeded');
      }
    }

    if (values.serviceId && values.servicePricing?.price == null) {
      reservationErrors.servicePricing.price = t('required');
    }
    if (!values.start) {
      reservationErrors.start = t('required');
    }
    if (!values.end) {
      reservationErrors.end = t('required');
    }
    if (!values.userId) {
      reservationErrors.userId = t('required');
    }
    if (calendarAutoUserError) {
      reservationErrors.calendarAutoUserError =
        values.userId === 'auto'
          ? t('validators_unable_to_pick_user_for_reservation_activity_type_pasiutuslape')
          : t('validators_reservation_has_intersection_activity_type_pasiutuslape');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { servicePricing, ...reservationErrorsWithoutServicePricing } =
      reservationErrors;
    return {
      reservationErrors,
      hasErrors:
        Object.keys(reservationErrorsWithoutServicePricing || {}).length > 0 ||
        Object.keys(reservationErrors.servicePricing).length > 0,
    };
  };

  const confirmSubmitOutOfWorkingHours = (values: IReservationWithInfo) => {
    setSubmitFailed(true);
    setErrorPopupIsOpen(true);
    setConfirmedFormValues(values);
  };

  const isEditingMultipleReservations =
    isMultiReservation && reservationToEdit?.id === ReservationCustomIds.EditMultiple;

  const isCreatingNewReservation =
    !reservationToEdit?.id?.length ||
    reservationToEdit.id === ReservationCustomIds.CreateNewToGroup;

  const handleMultiReservationUpdate = async (data: IReservationData) => {
    try {
      await dispatch(
        updateGroupReservation(
          {
            locationId: reservationToEdit.locationId,
            userId: reservationToEdit.userId,
            start: reservationToEdit.start,
          },
          {
            userId: data.userId,
            start: data.start,
            end: data.end,
            duration: data.duration,
            userComments: data.userComments,
            isAutoEmployee: data.isAutoEmployee,
          },
          false,
        ),
      );
    } catch (err) {
      return Promise.reject(err);
    }

    filteredReservations?.forEach(res => {
      dispatch(
        addEditedReservationToStore({
          ...res,
          userId: data.userId,
          start: data.start,
          end: data.end,
          duration: data.duration,
          userComments: data.userComments,
          isAutoEmployee: data.isAutoEmployee,
        }),
      );
    });
  };

  const customErrorTitle = (errorResponse: any) => {
    const errorsToDisplay = [
      'USER_IS_BUSY',
      'NO_AVAILABLE_RESOURCES',
      'NO_AVAILABLE_USERS',
      'PERSONSCOUNT_CANNOT_BE_CHANGED',
    ];
    const title = String(errorResponse?.data?.title);

    return errorsToDisplay.includes(title) ? title : 'fill_all_fields';
  };

  const handleSubmit = async (
    values: IReservationWithInfo,
    bypassAvailabilityCheck = false,
  ) => {
    const {
      clientId,
      locationId,
      serviceId,
      servicePricing,
      start,
      end,
      userComments,
      client,
      duration,
      isBlockTime,
    } = values;
    if (formValidation(values).hasErrors) {
      setSubmitFailed(true);
      return;
    }
    setSubmitFailed(false);

    const data: IReservationData = {
      locationId,
      start,
      end,
      duration: servicePricing.duration,
      userComments,
      client,
    };

    if (!isBlockTime) {
      const newDuration = getDuration(servicePricing.hours, servicePricing.minutes);
      // When creating new reservation, subtract the base duration since it is already calculated into end date
      const durationDiff =
        newDuration - (isCreatingNewReservation ? servicePricing.duration : duration);
      const newEndDate = addMinutesToDate(end, durationDiff);
      data.duration = newDuration;
      data.end = newEndDate;
    }

    if (selectedUserId === 'auto') {
      data.isAutoEmployee = true;
    } else {
      data.isAutoEmployee = false;
      data.userId = selectedUserId;
    }

    if (
      (clientId || reservationToEdit.clientId) &&
      clientId !== ClientIdStatuses.Anonymous &&
      (serviceId || selectedServiceId)
    ) {
      data.clientId = clientId || reservationToEdit.clientId;
    }

    if (servicePricing.id) {
      data.servicePricingId = servicePricing.id;
    }

    if (
      servicePricing.price &&
      !values.isBlockTime &&
      reservationToEdit.type !== ReservationTypes.BLOCK
    ) {
      data.price = servicePricing.price;
    }

    data.personsCount = servicePricing?.personsCount || 1;

    if (serviceId || selectedServiceId) {
      data.serviceId = serviceId;
    } else {
      data.type = ReservationTypes.BLOCK;
    }

    try {
      if (!isCreatingNewReservation) {
        if (reservationToEdit.id === ReservationCustomIds.EditMultiple) {
          await handleMultiReservationUpdate(data);

          if (refetchCalendarProps) {
            await dispatch(getAllReservationsCalendar(refetchCalendarProps));
          }
        } else {
          await dispatch(
            editReservation(
              {
                ...reservationToEdit,
                ...data,
                clientId: data.clientId ? data.clientId : null,
                client: !selectedClient?.name ? null : selectedClient,
                companyId: userState.data?.companyId || '',
                status: values.status,
                fromApi: reservationToEdit.fromApi,
              },
              reservationToEdit.id,
            ),
          );

          setReservationToEdit({
            ...reservationToEdit,
            clientId: data.clientId ? data.clientId : null,
            client: data.clientId
              ? getUpdatedClientObject(reservationToEdit.client, selectedClient)
              : null,
          });

          if (reservationToEdit?.clientId !== data.clientId && !!data.clientId) {
            const newClient = (await getClientById(data.clientId)).data;
            setClientToEdit(newClient);
          }
        }

        await dispatch(clearAllReservationsInStore());
        toast.success(<Toast text={t('editedReservation')} />);
        handleClose(true);
      } else {
        if (!bypassAvailabilityCheck && !isTimeCheckSkippable) {
          // console.log(
          //   `${navigator.userAgent} ${bypassAvailabilityCheck} ${isTimeCheckSkippable}`,
          // );
          // console.log(`date `, new Date());
          // console.log(`calendarData`, calendarData);
          // console.log(`values`, values);
          // console.log(`data`, data);

          // console.table(values);
          // console.table(data);

          const response: any = await dispatch(
            getReservationAvailableTime({
              LocationId: locationId || selectedLocationId,
              UserId: selectedUserId === 'auto' ? null : selectedUserId || '',
              Start: start,
              End: end,
              ServicePricingId: servicePricing.id || '',
            }),
          );

          // console.log(`response`, response);

          const availableTimes: undefined | IReservationAvailability[] = response.data;
          if (
            selectedUserId !== 'auto' &&
            (!availableTimes?.length ||
              // try to find at least one user availability that is >= selected reservation time <=
              !availableTimes.some(
                at =>
                  moment(start).isSameOrAfter(moment(at.start)) &&
                  moment(end).isSameOrBefore(moment(at.end)),
              ))
          ) {
            // neturim PILNOS laisvos vietos, vadinas arba nedarbo laiku arba useris busy tuo metu.
            // jeigu useris busy, nemetam out of working hours modalo
            // console.log(`no available time`);
            if (
              calendarData?.find(
                calRes =>
                  calRes.locationId === locationId &&
                  (calRes.userId === selectedUserId || selectedUserId === 'auto') &&
                  calRes.status !== ReservationStatus.CANCELLED &&
                  moment
                    .range(moment(calRes.start), moment(calRes.end))
                    .overlaps(moment.range(moment(start), moment(end))),
              )
            ) {
              // Radom rezervacija situo metu, reiskia rodom busy
              // console.log(`user busy`);
              setCalendarUserIsBusy(true);
              setSubmitFailed(true);
            } else {
              // console.log(`out of whours`);
              // rodom out of working hours
              setCalendarWorkingHoursError(true);
              confirmSubmitOutOfWorkingHours(values);
            }
            return;
          }
        }
        setConfirmedFormValues(undefined);

        await dispatch(
          addReservation({
            ...data,
            serviceId: data.serviceId || selectedServiceId || undefined,
            locationId: data.locationId || selectedLocationId,
            client: selectedClient || client,
            companyId: userState.data?.companyId || '',
            creatorId: userState.data?.userId || '',
          }),
        );

        dispatch(clearAllReservationsInStore());
        if (!isMultiReservation) {
          setReservationToEdit(undefined);
        }
        toast.success(<Toast text={t('addedReservation')} />);
        handleClose();
      }
    } catch (err) {
      setSubmitFailed(true);
      handleError(err?.response?.status, setError, customErrorTitle(err?.response));
    }
  };

  return (
    <div className='add-edit-reservation'>
      <FormModalExtended
        show={show}
        handleClose={handleCloseAndClear}
        modalTitle={
          !isCreatingNewReservation
            ? t(
                isEditingMultipleReservations
                  ? 'form_btn_reservation_multiple_edit'
                  : 'reservation_update_header',
              )
            : t('reservation_create_header')
        }
        validation={formValidation}
        handleSubmit={(values: IReservationWithInfo) =>
          calendarWorkingHoursError
            ? confirmSubmitOutOfWorkingHours({
                ...values,
                userId: selectedUserId || '',
              })
            : handleSubmit(values)
        }
        component={
          <AddEditReservationForm
            setSelectedServiceId={setSelectedServiceId}
            selectedServiceId={selectedServiceId}
            setSelectedLocationId={setSelectedLocationId}
            selectedLocationId={selectedLocationId}
            setSelectedClient={setSelectedClient}
            setSelectedUserId={setSelectedUserId}
            selectedUserId={selectedUserId}
            reservationToEdit={reservationToEdit}
            setCalendarAutoUserError={setCalendarAutoUserError}
            setCalendarWorkingHoursError={setCalendarWorkingHoursError}
            calendarWorkingHoursError={calendarWorkingHoursError}
            calendarUserIsBusy={calendarUserIsBusy}
            failedToSubmit={submitFailed}
            locations={
              initialData
                ? locationState.allData?.filter(loc => loc.id === initialData.location) ||
                  []
                : locationState.allData || []
            }
            services={
              initialData
                ? servicesState.allData?.filter(
                    ser =>
                      ser.userIds.includes(initialData.employee) &&
                      ser.locationIds.includes(initialData.location),
                  ) || []
                : servicesState.allData || []
            }
            serviceGroups={servicesGroupState.allData || []}
            handleClientAdd={handleClientAdd}
            initialData={initialData}
            error={error}
            isMultiReservation={isMultiReservation}
          />
        }
        editValue={initialReservationValues}
        modalClass='add-edit-reservation'
      />

      <AgreeToDelete
        show={errorPopupIsOpen}
        handleClose={() => {
          setErrorPopupIsOpen(false);
          setConfirmedFormValues(undefined);
        }}
        handleAccept={() => {
          if (confirmedFormValues) {
            setErrorPopupIsOpen(false);
            handleSubmit(confirmedFormValues, true);
            setConfirmedFormValues(undefined);
          }
        }}
        customText={t('validators_reservation_user_is_working')}
      />
    </div>
  );
};

AddEditReservationModal.displayName = 'AddEditReservationModal';

export default AddEditReservationModal;
