import {
  TServicesState,
  initialServicesState,
  TServicesActions,
  ServicesGet,
  ServicesGetAll,
  ServicesAdd,
  ServiceEdit,
  ServiceDelete,
} from '../types/Services';
import { Reducer } from 'redux';
import { ResetStore } from 'types/App';

export const convertToHoursAndMinutes = (duration: number) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  const hoursString = hours === 0 ? undefined : hours.toString();
  const minutesString = minutes === 0 ? undefined : minutes.toString();
  return { hours: hoursString, minutes: minutesString };
};

export const servicesReducer: Reducer<TServicesState, TServicesActions> = (
  state = initialServicesState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialServicesState;
    }
    case ServiceDelete.REQUEST:
    case ServiceEdit.REQUEST:
    case ServicesAdd.REQUEST:
    case ServicesGetAll.REQUEST:
    case ServicesGet.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ServiceDelete.FAILED:
    case ServiceEdit.FAILED:
    case ServicesAdd.FAILED:
    case ServicesGetAll.FAILED:
    case ServicesGet.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ServicesGetAll.SUCCESS:
      return {
        ...state,
        isLoading: false,
        allData: action.payload.data.data
          .sort((a, b) => a.name.localeCompare(b.name, 'lt'))
          .map(service => ({
            ...service,
            pricings: service.pricings.map(pricing => ({
              ...pricing,
              hours: convertToHoursAndMinutes(pricing.duration).hours,
              minutes: convertToHoursAndMinutes(pricing.duration).minutes,
            })),
          })),
        data: action.payload.data.data
          .sort((a, b) => a.name.localeCompare(b.name, 'lt'))
          .map(service => ({
            ...service,
            pricings: service.pricings.map(pricing => ({
              ...pricing,
              hours: convertToHoursAndMinutes(pricing.duration).hours,
              minutes: convertToHoursAndMinutes(pricing.duration).minutes,
            })),
          })),
      };
    case ServicesGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data.map(service => ({
          ...service,
          pricings: service.pricings.map(pricing => ({
            ...pricing,
            hours: convertToHoursAndMinutes(pricing.duration).hours,
            minutes: convertToHoursAndMinutes(pricing.duration).minutes,
          })),
        })),
        total: action.payload.data.total,
        isLoading: false,
      };
    }
    case ServicesAdd.ADD_SERVICES_STORE: {
      return {
        ...state,
        data: (state.data || [])
          .concat(action.payload)
          .sort((a, b) => a.name.localeCompare(b.name, 'lt')),
        allData: (state.allData || [])
          .concat(action.payload)
          .sort((a, b) => a.name.localeCompare(b.name, 'lt')),
      };
    }
    case ServiceEdit.EDIT_SERVICE_STORE: {
      return {
        ...state,
        allData: state.allData
          ? state.allData.map(rec =>
              rec.id === action.payload.id ? action.payload : rec,
            )
          : [],
        data: state.data
          ? state.data.map(rec => (rec.id === action.payload.id ? action.payload : rec))
          : [],
      };
    }
    case ServiceDelete.DELETE_SERVICE_STORE: {
      return {
        ...state,
        allData: state.allData
          ? state.allData.filter((rec: any) => rec.id !== action.payload)
          : [],
        data: state.data
          ? state.data.filter((rec: any) => rec.id !== action.payload)
          : [],
        total: state.total ? state.total - 1 : 0,
      };
    }
    case ServiceDelete.SUCCESS:
    case ServiceEdit.SUCCESS:
    case ServicesAdd.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
