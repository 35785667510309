import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localeEn from '../assets/locales/en.json';
import privacyPolicyEn from '../assets/locales/privacy-policy/en.json';
import localeLt from '../assets/locales/lt.json';
import privacyPolicyLt from '../assets/locales/privacy-policy/lt.json';
import localeLv from '../assets/locales/lv.json';
import privacyPolicyLv from '../assets/locales/privacy-policy/lv.json';
import localePl from '../assets/locales/pl.json';
import privacyPolicyPl from '../assets/locales/privacy-policy/pl.json';
import localeRu from '../assets/locales/ru.json';
import privacyPolicyRu from '../assets/locales/privacy-policy/ru.json';
import localeCz from '../assets/locales/cz.json';

// the translations
const resources = {
  en: {
    translation: localeEn,
    privacyPolicy: privacyPolicyEn,
  },
  lt: {
    translation: localeLt,
    privacyPolicy: privacyPolicyLt,
  },
  lv: {
    translation: localeLv,
    privacyPolicy: privacyPolicyLv,
  },
  pl: {
    translation: localePl,
    privacyPolicy: privacyPolicyPl,
  },
  ru: {
    translation: localeRu,
    privacyPolicy: privacyPolicyRu,
  },
  cz: {
    translation: localeCz,
    privacyPolicy: privacyPolicyEn,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    initImmediate: false,
    debug: false,
    ns: ['translation', 'privacyPolicy'],
    defaultNS: 'translation',
    saveMissing: true,
    updateMissing: true,

    react: {
      wait: true,
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
