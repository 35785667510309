import { AxiosResponse } from 'axios';
import { apiCall, authorisedClient } from './BaseAction';
import { TDispatch } from '../types/Thunk';
import { API_END_POINTS, PAGE_LIMIT } from '../constants/API';
import {
  IGetResources,
  IResource,
  IResourceData,
  IResourceDataWithId,
  ResourceAdd,
  ResourceDelete,
  ResourceEdit,
  ResourcesGet,
  ResourcesGetAll,
  ResourcesPut,
  TResourcesGetAction,
  TResourcesGetAllAction,
} from '../types/Resources';

export const getResources = (offset: number, sort?: string, limit?: number) => {
  return apiCall<TResourcesGetAction, any, IResource>(
    ResourcesGet,
    'GET',
    API_END_POINTS.GET_RESOURCES,
    true,
    {},
    {
      limit: limit || PAGE_LIMIT,
      offset,
      Sort: sort || 'name asc',
    },
  );
};

export const getAllResources = () => {
  return apiCall<TResourcesGetAllAction, null, IGetResources>(
    ResourcesGetAll,
    'GET',
    API_END_POINTS.GET_RESOURCES,
    true,
    null,
    { limit: 1000, Sort: 'name asc' },
  );
};

export const changeOffset = (newOffset: number) => {
  return {
    type: ResourcesPut.RESOURCES_OFFSET_UPDATE,
    payload: newOffset,
  };
};

export const deleteResource = (resourceId: string) => {
  return async (dispatch: TDispatch<ResourceDelete>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ResourceDelete.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'DELETE',
        url: API_END_POINTS.DELETE_RESOURCES(resourceId),
        data: {},
      });
      dispatch({
        type: ResourceDelete.SUCCESS,
        payload: response,
      });
      dispatch({
        type: ResourceDelete.DELETE_RESOURCE_STORE,
        payload: resourceId,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ResourceDelete.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addNewResourceToStore = (resource: IResourceDataWithId) => {
  return {
    type: ResourceAdd.ADD_RESOURCE_STORE,
    payload: resource,
  };
};

export const addResource = (resource: IResourceData) => {
  return async (dispatch: TDispatch<ResourceAdd>): Promise<AxiosResponse<string>> => {
    const { name, count, locationId, description, companyId } = resource;

    dispatch({
      type: ResourceAdd.REQUEST,
    });

    try {
      const response = await authorisedClient.request({
        method: 'POST',
        url: API_END_POINTS.ADD_RESOURCE,
        data: {
          name,
          locationId,
          companyId,
          count,
          description,
          locale: 'en', //TODO: figure out what locale to send
        },
      });

      dispatch({
        type: ResourceAdd.SUCCESS,
        payload: response,
      });
      if (response.data) {
        dispatch(addNewResourceToStore({ ...resource, id: response.data }));
      }
      return response;
    } catch (error) {
      dispatch({
        type: ResourceAdd.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const addEditedResourceToStore = (resource: IResourceDataWithId) => {
  return {
    type: ResourceEdit.EDIT_RESOURCE_STORE,
    payload: resource,
  };
};

export const editResource = (resource: IResourceData, resourceId: string) => {
  return async (dispatch: TDispatch<ResourceEdit>): Promise<AxiosResponse<null>> => {
    dispatch({
      type: ResourceEdit.REQUEST,
    });
    try {
      const response = await authorisedClient.request({
        method: 'PUT',
        url: API_END_POINTS.EDIT_RESOURCES(resourceId),
        data: {
          ...resource,
          locale: 'en', //TODO: figure out what locale to send
        },
      });
      dispatch({
        type: ResourceEdit.SUCCESS,
        payload: response,
      });
      dispatch(addEditedResourceToStore({ ...resource, id: resourceId }));
      return response;
    } catch (error) {
      dispatch({
        type: ResourceEdit.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
