import React from 'react';
import { MdErrorOutline } from 'react-icons/md';

interface IToast {
  text: string;
  isError?: boolean;
}

const Toast: React.FC<IToast> = ({ text, isError }) => {
  return (
    <div className='d-flex'>
      {isError && (
        <span>
          <MdErrorOutline style={{ width: 25, height: 25 }} />
        </span>
      )}
      <p className='mx-2 mb-1'>{text}</p>
    </div>
  );
};

export default Toast;
