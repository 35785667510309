import { apiCall } from 'actions/BaseAction';
import { API_END_POINTS } from 'constants/API';
import {
  ServicesGetExperience,
  IServicesGetExperience,
  TServicesGetExperienceAction,
} from 'experience/types/ExperienceServices';

export const getServicesExperience = (
  accessKey: string,
  groupId?: string,
  locationId?: string,
) => {
  return apiCall<TServicesGetExperienceAction, any, IServicesGetExperience>(
    ServicesGetExperience,
    'GET',
    API_END_POINTS.GET_SERVICES_EXPERIENCE,
    false,
    {},
    { groupId, locationId },
    accessKey,
  );
};
