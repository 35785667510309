import { Reducer } from 'redux';
import {
  ExperienceWidgetMultiple,
  IExperienceWidgetMultipeState,
  initialExperienceWidgetMultipleState,
  TExperienceWidgetMultipleActions,
} from 'types/ExperienceWidgetMultiple';

export const experienceWidgetMultipleReducer: Reducer<
  IExperienceWidgetMultipeState,
  TExperienceWidgetMultipleActions
> = (state = initialExperienceWidgetMultipleState, action) => {
  switch (action.type) {
    case ExperienceWidgetMultiple.CHANGE_SERVICE_COMPANY_M: {
      const company = action.payload;
      return { ...state, company };
    }

    case ExperienceWidgetMultiple.CHANGE_NEW_SERVICE_M: {
      const newService = action.payload;
      const newServiceId = action.payload.serviceId;
      const newState = { ...state };
      newState.selectedServices?.push({
        serviceId: newServiceId,
        service: newService,
      });
      return newState;
    }

    case ExperienceWidgetMultiple.CHANGE_LOCATION_M: {
      const { location, serviceId } = action.payload;
      const selectedServices = state.selectedServices?.map(item => {
        if (item.serviceId === serviceId) {
          return { ...item, location };
        }
        return item;
      });
      if (!selectedServices) return state;
      return { ...state, selectedServices };
    }

    case ExperienceWidgetMultiple.CHANGE_DATE_TIME_USER_M: {
      const { dateTimeUser, serviceId } = action.payload;
      const selectedServices = state.selectedServices?.map(item => {
        if (item.serviceId === serviceId) {
          return { ...item, dateTimeUser };
        }
        return item;
      });
      if (!selectedServices) return state;
      return { ...state, selectedServices };
    }

    case ExperienceWidgetMultiple.CHANGE_SERVICE_EDITING_M: {
      const newState = { ...state };
      newState.isEditingService = action.payload;
      return newState;
    }

    case ExperienceWidgetMultiple.CHANGE_CURRENT_SERVICE: {
      const newState = { ...state };
      newState.currentService = action.payload.serviceId;
      newState.isEditingService = action.payload.isEditingService;
      return newState;
    }

    case ExperienceWidgetMultiple.REMOVE_SERVICE_M: {
      const newState = { ...state };
      const currentServiceId = action.payload ? action.payload : newState.currentService;
      const newServiceList = newState.selectedServices?.filter(
        item => item.serviceId !== currentServiceId,
      );
      newState.selectedServices = newServiceList;
      return newState;
    }

    case ExperienceWidgetMultiple.CHANGE_INFO_EMPLOYEE_M: {
      const serviceId = action.payload.serviceId;
      const selectedServices = state.selectedServices?.map(item => {
        if (item.serviceId === serviceId) {
          const reservationInfo = {
            selectedEmployee: action.payload.employee,
          };
          return { ...item, reservationInfo };
        }
        return item;
      });
      return { ...state, selectedServices };
    }

    case ExperienceWidgetMultiple.INIT_MULTIPLE: {
      const newState = { ...state };
      newState.selectedServices = action.payload.selectedServices;
      newState.currentService = action.payload.currentService;
      newState.isEditingService = action.payload.isEditingService;
      return newState;
    }

    case ExperienceWidgetMultiple.CHANGE_INFO_M: {
      const serviceId = action.payload.serviceId;
      const selectedServices = state.selectedServices?.map(item => {
        if (item.serviceId === serviceId) {
          const reservationInfo = {
            selectedDate: action.payload.date ?? item.reservationInfo?.selectedDate,
            selectedTime: action.payload.time ?? item.reservationInfo?.selectedTime,
            selectedEmployee:
              action.payload.employee ?? item.reservationInfo?.selectedEmployee,
          };
          return { ...item, reservationInfo };
        }
        return item;
      });

      return { ...state, selectedServices };
    }

    default: {
      return state;
    }
  }
};
