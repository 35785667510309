import React, { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { FaExclamationTriangle } from 'react-icons/fa';

interface IWorkTimeErrors {
  errors?: Record<string, any>;
}

const WorkTimeErrors: FC<IWorkTimeErrors> = ({ errors }) => {
  const hasTimeRangeError = errors ? Object.keys(errors).includes('timeRange') : false;
  const hasBreakOverlapError = errors
    ? Object.keys(errors).includes('breakOverlap')
    : false;

  const hasBreaksError = errors ? Object.keys(errors).includes('breakErrors') : false;
  const hasCustomBreakError = errors
    ? Object.keys(errors).includes('customBreakErrors')
    : false;
  const hasErrors =
    hasTimeRangeError || hasBreakOverlapError || hasBreaksError || hasCustomBreakError;

  return (
    <>
      {hasErrors && (
        <tr>
          <td colSpan={4}>
            {hasTimeRangeError && (
              <Form.Text as='div' className='text-danger mb-1'>
                <FaExclamationTriangle className='mr-1' />
                {errors?.timeRange}
              </Form.Text>
            )}
            {hasBreakOverlapError && (
              <Form.Text as='div' className='text-danger mb-1'>
                <FaExclamationTriangle className='mr-1' />
                {errors?.breakOverlap}
              </Form.Text>
            )}
            {hasBreaksError && (
              <Form.Text as='div' className='text-danger mb-1'>
                <FaExclamationTriangle className='mr-1' />
                {errors?.breakErrors}
              </Form.Text>
            )}
            {hasCustomBreakError && (
              <Form.Text as='div' className='text-danger mb-1'>
                <FaExclamationTriangle className='mr-1' />
                {errors?.customBreakErrors}
              </Form.Text>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default WorkTimeErrors;
