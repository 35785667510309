import { Reducer } from 'redux';
import { ResetStore } from 'types/App';
import {
  TimetableGetExperience,
  IExperienceTimetableState,
  initialExperienceTimetableState,
  TTimetableGetExperienceAction,
  IExperienceReservationDatesState,
  initialExperienceReservationDatesState,
  ReservationDatesGetExperience,
  TReservationDatesGetExperienceAction,
} from 'experience/types/ExperienceTimetable';

export const experienceTimetableReducer: Reducer<
  IExperienceTimetableState,
  TTimetableGetExperienceAction
> = (state = initialExperienceTimetableState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialExperienceTimetableState;
    }

    case TimetableGetExperience.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TimetableGetExperience.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };

    case TimetableGetExperience.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export const experienceReservationDatesReducer: Reducer<
  IExperienceReservationDatesState,
  TReservationDatesGetExperienceAction
> = (state = initialExperienceReservationDatesState, action) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialExperienceReservationDatesState;
    }

    case ReservationDatesGetExperience.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ReservationDatesGetExperience.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };

    case ReservationDatesGetExperience.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
