import React, { useMemo } from 'react';
import './EditReservation.scss';
import EditReservationIcon from 'assets/images/edit-reservation.svg';
import DeleteReservationIcon from 'assets/images/delete-reservation.svg';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'store/Store';
import {
  IExperienceWidgetMultipeState,
  ISelectedService,
} from 'types/ExperienceWidgetMultiple';
import Block from './Block';
import { useTranslation } from 'react-i18next';
import { SKIP_IDS } from 'config/shipConfig';
import moment from 'moment';
import { EXPERIENCE_LANG_ITEM } from 'constants/Storage';
import { ExperienceLocales } from 'constants/Experience';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'constants/Routes';
import {
  removeExperienceWidgetMultiple,
  setCurrentExperienceWidgeMultiple,
} from 'experience/redux/actions/ExperienceWidgetActionMultiple';
import { IExperienceEmployeesState } from 'experience/types/ExperienceEmployees';
import clsx from 'clsx';
import { getSelectedServices, setSelectedServices } from 'helpers/SessionStorage';

const EditReservation = () => {
  const { t } = useTranslation();
  const widgetMultipleState: IExperienceWidgetMultipeState = useSelector<
    IAppState,
    IExperienceWidgetMultipeState
  >((state: IAppState) => state.experienceWidgetMultipeState);

  const employeeExperienceState: IExperienceEmployeesState = useSelector<
    IAppState,
    IExperienceEmployeesState
  >((state: IAppState) => state.experienceEmployeesState);

  const { params } = useRouteMatch<{ lang: string; company: string }>();

  const language = localStorage.getItem(EXPERIENCE_LANG_ITEM);

  const history = useHistory();

  const dispatch = useDispatch();

  const formatWeekday = (date?: Date) => {
    if (!date) return '';
    const numb = moment(date).day();
    return language === ExperienceLocales.LT
      ? t(`employee_calendar_weekday_${numb}`).toLowerCase()
      : t(`employee_calendar_weekday_${numb}`);
  };

  const formatServiceTime = (
    serviceId: string,
    duration?: number,
    serviceTimeStart?: string,
    serviceMonth?: string,
    date?: Date,
  ) => {
    const serviceTimeEnd = moment(serviceTimeStart, 'hh:mm')
      .add(duration, 'minutes')
      .format('HH:mm');

    const endTime = SKIP_IDS.includes(serviceId) ? '' : ` - ${serviceTimeEnd}`;

    return `${serviceTimeStart}${endTime} ${t('twig_time_short_hours')} ${
      language === ExperienceLocales.LT
        ? t(`employee_calendar_month_genitive_${serviceMonth}`).toLowerCase()
        : t(`employee_calendar_month_genitive_${serviceMonth}`)
    } ${moment(date).format('D')} ${
      language !== ExperienceLocales.EN ? t('employee_calendar_day_short') : ''
    } ${formatWeekday(date)}`;
  };

  const getEmployer = (item: any) => {
    const employee = employeeExperienceState?.data?.find(
      empl => empl.id === item.dateTimeUser?.user,
    );
    return employee
      ? `${employee.firstName} ${employee.lastName || ''}`
      : t('reservation_select_employee_all');
  };

  const handleClickEditService = (serviceId: string) => {
    dispatch(setCurrentExperienceWidgeMultiple(serviceId, true));
    history.push(
      ROUTES.EXPERIENCE.TIME.replace(':company', params.company).replace(
        ':lang',
        params.lang,
      ),
    );
  };

  const handleClickRemoveService = (serviceId: string) => {
    dispatch(removeExperienceWidgetMultiple(serviceId));
    try {
      const session = getSelectedServices();
      if (!session) return;
      const filteredSession = session.selectedServices.filter(
        (item: ISelectedService) => item.serviceId !== serviceId,
      );
      setSelectedServices({ ...session, selectedServices: filteredSession });
      history.replace(
        ROUTES.EXPERIENCE.SERVICES.replace(':company', params.company).replace(
          ':lang',
          params.lang,
        ),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const isEditingItem = (serviceId: string) => {
    return (
      widgetMultipleState.isEditingService &&
      widgetMultipleState?.currentService === serviceId
    );
  };

  const sortedServices = useMemo(
    () => widgetMultipleState.selectedServices?.sort(a => (a.dateTimeUser ? 1 : -1)),
    [widgetMultipleState.selectedServices],
  );
  if (!widgetMultipleState.selectedServices?.length) return null;

  return (
    <div className='experience__reservation_edit_form-wrapper'>
      <Block
        className='experience__edit-block'
        title={t('reservation_select_reservation')}
      >
        <div className='experience__reservation_edit_form'>
          {sortedServices?.map(item => {
            return (
              <div
                className={clsx('experience__reservation_edit_form-item', {
                  'experience__reservation_edit_form-item--disabled': !item.dateTimeUser,
                  'experience__reservation_edit_form-item--editing': isEditingItem(
                    item.serviceId,
                  ),
                })}
                key={item.serviceId}
              >
                <div className='experience__reservation_edit_form-inner-wrapper'>
                  <p className='experience__reservation_edit_form-service'>
                    {item?.service?.service.name}
                  </p>
                  {item.dateTimeUser && (
                    <p className='experience__reservation_edit_form-time-info '>
                      {formatServiceTime(
                        item.serviceId,
                        item.service?.pricing.duration,
                        item.dateTimeUser?.time,
                        moment(item.dateTimeUser?.date).format('MM'),
                        item.dateTimeUser?.date,
                      )}
                      , <span>{t('client_table_col_user').toLowerCase()}:</span>{' '}
                      {getEmployer(item)}
                    </p>
                  )}
                </div>
                {item.dateTimeUser && (
                  <div className='experience__reservation_edit_form-actions'>
                    {!widgetMultipleState.isEditingService && (
                      <img
                        src={EditReservationIcon}
                        width='20px'
                        height='20px'
                        alt='edit reservation'
                        onClick={() => handleClickEditService(item.serviceId)}
                      />
                    )}
                    <img
                      src={DeleteReservationIcon}
                      width='20px'
                      height='20px'
                      alt='delete reservation'
                      onClick={() => handleClickRemoveService(item.serviceId)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Block>
    </div>
  );
};

export default EditReservation;
