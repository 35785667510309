import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormInput from '../../Form/FormInput';
import { ILocation } from '../../../types/Locations';
import FormCheckbox from '../../Form/FormCheckbox';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import lt from 'date-fns/locale/lt';
import lv from 'date-fns/locale/lv';
import pl from 'date-fns/locale/pl';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import styles from './ModalForm.module.scss';
import '../../../assets/styles/DatepickerStylesOverride.scss';
import { IReservationSettingsState } from '../../../types/ReservationSettings';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/Store';
import useGetDataForRedux from '../../../hooks/useGetDataForRedux';
import { getReservationSettings } from '../../../actions/ReservationSettingsActions';
import { IFormError } from '../../../constants/Interfaces';
import useError from '../../../hooks/useError';

registerLocale('LT', lt);
registerLocale('EN', en);
registerLocale('LV', lv);
registerLocale('PL', pl);
registerLocale('RU', ru);

interface IModalForm {
  locations: ILocation[];
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  setStartDate: (date: any) => void;
  setEndDate: (date: any) => void;
}

const ModalForm: FC<IModalForm> = ({
  locations,
  setEndDate,
  startDate,
  endDate,
  setStartDate,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  const reservationSettingsState: IReservationSettingsState = useSelector<
    IAppState,
    IReservationSettingsState
  >((state: IAppState) => state.reservationSettingsState);

  useGetDataForRedux(setError, reservationSettingsState.data, getReservationSettings());

  return (
    <Row className='px-2 py-2'>
      <Col sm={6}>
        <div>
          <Form.Label>{t('vacation_day_form_label_start') + ' *'}</Form.Label>
        </div>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          className='form-control'
          locale={reservationSettingsState.data?.locale || 'EN'}
          dateFormat='yyyy-MM-dd'
          required={true}
        />
      </Col>
      <Col sm={6}>
        <div>
          <Form.Label>{t('vacation_day_form_label_end') + ' *'}</Form.Label>
        </div>
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          className='form-control'
          locale={reservationSettingsState.data?.locale || 'EN'}
          dateFormat='yyyy-MM-dd'
          required={true}
          minDate={startDate}
        />
      </Col>
      <Col sm={12}>
        <Form.Label>{t('vacation_day_form_label_location') + ' *'}</Form.Label>
      </Col>
      {locations.map(({ id, name }) => (
        <Col sm={6} key={id}>
          <FormCheckbox name='locationIds' label={name} value={id} id={name} />
        </Col>
      ))}

      <Col sm={12}>
        <div className={styles.vacationsDesc}>
          <FormInput
            label={t('vacation_day_form_label_description')}
            name='description'
            formType='textarea'
          />
        </div>
      </Col>
    </Row>
  );
};

export default ModalForm;
