import {
  ExperienceWidgetMultiple,
  IExperienceWidgetMultipeState,
} from './../../../types/ExperienceWidgetMultiple';
import { ILocationExperience } from 'experience/types/ExperienceLocation';
import {
  IExperiencePricings,
  IServicesExperience,
} from 'experience/types/ExperienceServices';
import { ICompanyExperience } from 'experience/types/ExperienceCompany';
import { IEmployeesExperience } from 'experience/types/ExperienceEmployees';

export const setExperienceWidgetMultipleService = (
  service: IServicesExperience,
  pricing: IExperiencePricings,
  serviceId: string,
) => {
  return {
    type: ExperienceWidgetMultiple.CHANGE_NEW_SERVICE_M,
    payload: {
      service,
      pricing,
      serviceId,
    },
  };
};

export const setExperienceWidgetMultipleLocation = (
  location: ILocationExperience,
  serviceId: string,
) => {
  return {
    type: ExperienceWidgetMultiple.CHANGE_LOCATION_M,
    payload: {
      location,
      serviceId,
    },
  };
};

export const setExperienceWidgetMultipleInfo = (state: IExperienceWidgetMultipeState) => {
  return {
    type: ExperienceWidgetMultiple.INIT_MULTIPLE,
    payload: state,
  };
};

export const setExperienceWidgetCompanyMultiple = (company: ICompanyExperience) => {
  return {
    type: ExperienceWidgetMultiple.CHANGE_SERVICE_COMPANY_M,
    payload: company,
  };
};

export const setExperienceWidgeMultipletDateTimeUser = (
  date: Date,
  time: string,
  personCount: number,
  user: string | undefined,
  serviceId: string,
) => {
  return {
    type: ExperienceWidgetMultiple.CHANGE_DATE_TIME_USER_M,
    payload: {
      dateTimeUser: {
        date,
        time,
        personCount,
        user,
      },
      serviceId,
    },
  };
};

export const setCurrentExperienceWidgeMultiple = (
  serviceId: string,
  isEditingService: boolean,
) => {
  return {
    type: ExperienceWidgetMultiple.CHANGE_CURRENT_SERVICE,
    payload: {
      serviceId,
      isEditingService,
    },
  };
};

export const setExperienceWidgeMultipleEditing = (isEditingService: boolean) => {
  return {
    type: ExperienceWidgetMultiple.CHANGE_SERVICE_EDITING_M,
    payload: isEditingService,
  };
};

export const removeExperienceWidgetMultiple = (serviceId?: string) => {
  return {
    type: ExperienceWidgetMultiple.REMOVE_SERVICE_M,
    payload: serviceId,
  };
};

export const setExperienceWidgetReservationInfo = (
  serviceId: string,
  date?: Date,
  time?: string,
  employee?: IEmployeesExperience,
) => {
  return {
    type: ExperienceWidgetMultiple.CHANGE_INFO_M,
    payload: {
      date,
      time,
      employee,
      serviceId,
    },
  };
};

export const setExperienceWidgetReservationEmployeeInfo = (
  serviceId: string,
  employee?: IEmployeesExperience,
) => {
  return {
    type: ExperienceWidgetMultiple.CHANGE_INFO_EMPLOYEE_M,
    payload: {
      employee,
      serviceId,
    },
  };
};

export const setExperienceAddress = (
  id: string,
  name: string,
  address: string,
  city: string,
) => {
  return {
    type: ExperienceWidgetMultiple.CHANGE_ADDRESS_M,
    payload: {
      id,
      name,
      address,
      city,
    },
  };
};
