import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import FullCalendar, {
  EventInput,
  EventContentArg,
  EventClickArg,
  DateSpanApi,
  EventDropArg,
} from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MdReportProblem } from 'react-icons/md';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { IEmployer, IlocationOptions } from '../../types/Calendar';
import { IFormError, View } from '../../constants/Interfaces';
import { getAllEmployees } from '../../actions/EmployeesActions';
import { getCustomWorkTimeAll } from '../../actions/CustomWorkTimeActions';
import { getWorkTimeAll } from '../../actions/WorkTimeActions';
import { getLocations } from '../../actions/LocationActions';
import { handleError } from '../../middlewares/ErrorHandler';
import useError from '../../hooks/useError';
import CalendarControls from '../../components/Admin/Calendar/CalendarControls';
import './AdminCalendar.scss';
import {
  IReservationModalInitialData,
  IReservationWithInfo,
} from '../../types/Reservations';
import {
  editReservation,
  getAllReservationsCalendar,
  setFilteredReservations,
  updateGroupReservation,
} from '../../actions/ReservationsActions';
import EventsContent from '../../components/Admin/Calendar/EventsContent';
import { getAllServices } from '../../actions/ServicesActions';
import { IClient } from '../../types/Clients';
import { getClientById } from 'actions/ClientsActions';
import { getReservationSettings } from '../../actions/ReservationSettingsActions';
import { emptyReservationObject } from '../../constants/UtilVariables';
import CalendarHeader from '../../components/Admin/Calendar/CalendarHeader';
import {
  toHoursAndMinutes,
  formateCalendarData,
  isEventDragAllowed,
} from '../../helpers/CalendarUtils';
import useGetDataForRedux from '../../hooks/useGetDataForRedux';
import CalendarModalsWrapper from '../../components/Admin/Calendar/CalendarModalsWrapper';
import { getAccessToken, parseUserData } from 'helpers/Misc';
import { Roles } from '../../constants/Roles';
import Env from '../../constants/Env';
import { useStoreState } from 'hooks/useStoreState';
import { SettingsLocales, SettingsTimezones } from 'types/ReservationSettings';
import { getVacations } from 'actions/VacationsActions';
import { matchAllUserRoles, matchUserRole } from 'helpers/matchRole';
import { formateDate } from 'helpers/DateTime';
import { COMPANY_ID_ITEM } from 'constants/Storage';
import i18next from 'config/i18next';

const AdminCalendar: React.FC = () => {
  const calendarRef: React.RefObject<FullCalendar> = useRef<FullCalendar>(null);

  const [error, setError] = useState<IFormError>();
  const [resources, setResources] = useState<IEmployer[]>();
  const [activeView, setActiveView] = useState<View>(View.day);
  const [calendarData, setCalendarData] = useState<EventInput[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingCalendarResources, setLoadingCalendarRecoures] = useState(false);
  const [locationOptions, setLocationOptions] = useState<
    IlocationOptions[] | undefined
  >();
  const [emplOptions, setEmplOptions] = useState<IEmployer[] | undefined>();
  const [selectedEmpl, setSelectedEmpl] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Date>(moment().toDate());
  const [resourcesToShow, setResourcesToShow] = useState<IEmployer[]>();
  const [dateChanged, setDateChanged] = useState<boolean>(false);
  const [filteredEvents, setFilteredEvents] = useState<any>();
  //reservation modal
  const [reservationToEdit, setReservationToEdit] = useState<IReservationWithInfo>();
  const [reservationInfoModalOpen, setReservationInfoModalOpen] =
    useState<boolean>(false);
  const [reservationInterval, setReservationInterval] = useState<any>();
  const [openReservationModal, setOpenReservationModal] = useState<boolean>(false);
  const [urgentMessageModalOpen, setUrgentMessageModalOpen] = useState(false);
  const [reservationModalInitialData, setReservationModalInitialData] =
    useState<IReservationModalInitialData>();
  //client modal
  const [clientToEdit, setClientToEdit] = useState<IClient>();

  const token = getAccessToken();
  const user = token ? parseUserData(token) : null;
  const { data: reservationSettings } = useStoreState().reservationSettingsState;

  const isEmployee = user?.roles && matchUserRole(user?.roles, [Roles.Employee]);
  const isSuperviewer = user?.roles.includes(Roles.SuperViewer);

  const dispatch = useDispatch();
  const {
    employeeState,
    reservationsState,
    servicesState,
    reservationSettingsState,
    locationState,
    workTimeState,
    vacationsState,
    customWorkTimeState,
  } = useStoreState();
  //hooks
  const { t } = useTranslation();
  useError(error, setError);

  // get data to redux state
  useGetDataForRedux(setError, null, getLocations(0));
  useGetDataForRedux(setError, null, getVacations(0));
  useGetDataForRedux(setError, null, getReservationSettings());

  const handleClientEdit = async () => {
    await fetchCalendarReservations();
  };

  const start = moment(calendarRef?.current?.getApi().view.activeStart)
    .hours(0)
    .minutes(0)
    .seconds(0);

  const end = moment(calendarRef?.current?.getApi().view.activeEnd)
    .hours(23)
    .minutes(59)
    .seconds(59);
  // not using useGetDataForRedux here - its check for current state does not update info when superviewer goes to compnay view mode
  useEffect(() => {
    (async () => {
      try {
        await dispatch(getAllServices());
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, []);

  // if coming from superViewer check and set accounts language;
  useEffect(() => {
    (async () => {
      if (localStorage.getItem(COMPANY_ID_ITEM) && reservationSettingsState.data) {
        const accountLang = reservationSettingsState.data.locale;
        if (accountLang && isSuperviewer) {
          await i18next.changeLanguage(accountLang.toLowerCase());
        }
      }
    })();
  }, [reservationSettingsState.data]);

  useEffect(() => {
    if (isEmployee) {
      setSelectedEmpl(user?.userId);
    } else {
      setSelectedEmpl(View.all);
    }
  }, []);

  // not using useGetDataForRedux here - its check for current state does not update info when superviewer goes to compnay view mode
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch(getAllEmployees());
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    let interval: any;
    if (selectedLocation) {
      getAllData();
      clearInterval(reservationInterval);
      interval = handleInterval();
      setReservationInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [dateChanged, selectedLocation, activeView]);

  useEffect(() => {
    if (emplOptions) {
      setResourcesToShow(emplOptions);
    }
  }, [emplOptions]);

  // formate calendar event data
  useEffect(() => {
    if (
      servicesState.allData &&
      reservationsState.calendarData &&
      employeeState.allData &&
      customWorkTimeState.allData &&
      workTimeState.allData
    ) {
      const calendarData = formateCalendarData(
        servicesState.allData,
        reservationsState.calendarData,
        employeeState.allData,
        customWorkTimeState.allData,
        workTimeState.allData,
        start,
        end,
        selectedLocation,
        vacationsState.data,
      );
      setCalendarData(calendarData);
      setLoading(false);
    }
  }, [
    servicesState.allData,
    reservationsState.calendarData,
    employeeState.allData,
    customWorkTimeState.allData,
    workTimeState.allData,
    activeView,
    dateChanged,
    selectedLocation,
  ]);

  useEffect(() => {
    if (!locationState.data || !employeeState.allData) return;
    let locations: typeof locationOptions = [];

    if (
      matchAllUserRoles(user?.roles, [Roles.Admin]) ||
      user?.roles.includes(Roles.SuperViewer)
    ) {
      // user is admin or superadmin - superviewer, allow all locations
      locations = locationState.data;
    } else {
      // user is employee, show only his own locations
      const employee = employeeState.allData.find(emp => emp.id === user?.userId);
      if (employee) {
        locations = locationState.data.filter(loc =>
          employee.locationIds?.some(emplLocation => emplLocation === loc.id),
        );
      }
    }
    setLocationOptions(locations);
    setSelectedLocation(locations[0]?.id);
    setLoading(false);
  }, [locationState.data, employeeState.allData]);

  useEffect(() => {
    // on resources(employees) load
    if (!employeeState.allData) return;

    setResources(
      employeeState.allData.map(employee => ({
        id: employee.id,
        title: `${employee.name} ${employee.lastName ? employee.lastName : ''}`,
        locationIds: employee.locationIds,
      })),
    );
  }, [employeeState.allData]);

  useEffect(() => {
    if (!resources) return;

    if (
      !matchAllUserRoles(user?.roles, [Roles.Admin]) &&
      !matchAllUserRoles(user?.roles, [Roles.SuperViewer])
    ) {
      // user is employee -> show only himself in calendar
      setEmplOptions(resources.filter(res => res.id === user?.userId));
      return;
    }

    if (selectedLocation) {
      // user is admin and has location selected
      const filtered = resources.filter(res =>
        res.locationIds?.includes(selectedLocation),
      );
      setEmplOptions(filtered);

      if (filtered.length) {
        // IMPORTANT: keep it empty string. This will fire useEffect below, and will do setSelectedEmpl(emplOptions[0].id).
        setSelectedEmpl('');
      }
    }
  }, [selectedLocation, resources]);

  useEffect(() => {
    // selected employee was changed
    if (!matchAllUserRoles(user?.roles, [Roles.Admin]) && !isSuperviewer) return;
    if (!emplOptions) return;
    if (!selectedEmpl) {
      if (!selectedLocation) return;
      if (activeView === View.day) {
        setSelectedEmpl(View.all);
      } else {
        setSelectedEmpl(emplOptions[0].id);
      }
      return;
    }

    if (selectedEmpl === View.all && emplOptions) {
      setResourcesToShow([...emplOptions]);
    } else {
      setResourcesToShow(resources?.filter(res => res.id === selectedEmpl));
    }
  }, [selectedEmpl]);

  useEffect(() => {
    // selected location changed, filter which events to show on calendar
    if (!calendarData || !locationOptions?.length) return; // no calendar data loaded yet

    const locationIds = locationOptions.map(opt => opt.id);
    setFilteredEvents(
      calendarData.filter(
        ev => locationIds.includes(ev.locationId) && ev.locationId === selectedLocation,
      ),
    );
  }, [selectedLocation, locationOptions, calendarData, selectedEmpl]);

  const handleEventClick = useCallback(
    async (clickInfo: EventClickArg) => {
      const eventInfo = clickInfo.event._def.title
        ? JSON.parse(clickInfo.event._def.title)
        : null;
      if (!reservationsState.calendarData || !eventInfo) return;
      const { clientId } = eventInfo;
      const reservationToEdit = reservationsState.calendarData.find(
        reservation => reservation.id === eventInfo.id,
      );

      let clientToEdit = undefined;
      if (clientId && !isEmployee) {
        try {
          clientToEdit = (await getClientById(clientId)).data;
          // eslint-disable-next-line no-empty
        } catch (e) {
          // IMPORTANT: leave this catch block, because it is intentional.
          // sometimes client may not exist and error occurs, but we still need to try fetch it.
        }
      }
      let foundService;
      if (servicesState.allData && reservationToEdit) {
        foundService = servicesState.allData.find(
          service => service.id === reservationToEdit?.serviceId,
        );
      }
      setReservationToEdit({
        ...reservationToEdit,
        serviceGroupId: foundService?.groupId,
        serviceCode: foundService?.code,
      } as IReservationWithInfo);
      setClientToEdit(clientToEdit);
      setReservationInfoModalOpen(true);
    },
    [reservationsState.calendarData, servicesState.allData],
  );

  const handleInterval = () =>
    setInterval(async () => {
      getAllData();
    }, 1000 * 60 * 5);

  const getAllData = async () => {
    setLoadingCalendarRecoures(true);
    await fetchCalendarReservations();
    await fetchCalendarDate();
    setLoadingCalendarRecoures(false);
  };

  const fetchCalendarReservations = async () => {
    try {
      await dispatch(
        getAllReservationsCalendar({
          dateFrom: formateDate(start),
          dateTo: formateDate(end),
          LocationId: selectedLocation,
        }),
      );
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const updateCalendarView = (view: View) => {
    if (view === View.day) {
      calendarRef?.current
        ?.getApi()
        .changeView(resourcesToShow?.length ? 'resourceTimeGridDay' : 'timeGridDay');
    } else {
      calendarRef?.current
        ?.getApi()
        .changeView(resourcesToShow?.length ? 'resourceTimeGridWeek' : 'timeGridWeek');
    }
  };

  const switchToDayView = (startDate: string) => {
    updateCalendarView(View.day);
    setActiveView(View.day);
    calendarRef?.current?.getApi().gotoDate(moment(startDate).toDate());
    setSelectedEmpl(View.all);
  };

  const switchToWeekView = (startDate: Date) => {
    updateCalendarView(View.week);
    setActiveView(View.week);
    if (emplOptions?.length && selectedEmpl === View.all) {
      setSelectedEmpl(emplOptions[0].id);
    }
    calendarRef?.current?.getApi().gotoDate(startDate);
  };

  const fetchCalendarDate = async () => {
    try {
      await dispatch(
        getCustomWorkTimeAll({
          LocationId: selectedLocation,
          DateFrom: formateDate(start),
          DateTo: formateDate(end),
        }),
      );
      await dispatch(
        getWorkTimeAll({
          LocationId: selectedLocation,
          dateFrom: formateDate(start),
          dateTo: formateDate(end),
        }),
      );
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const handleCalendarTimeSlotClick = useCallback(
    (event: any) => {
      if (isEmployee) {
        return;
      }
      if (event.resource?._resource.id && selectedLocation) {
        setReservationModalInitialData({
          employee: event.resource?._resource.id,
          location: selectedLocation,
          time: event.dateStr,
        });
      }
      setOpenReservationModal(true);
    },
    [user, selectedLocation],
  );

  useEffect(() => {
    if (!reservationInfoModalOpen && !openReservationModal && !urgentMessageModalOpen) {
      dispatch(setFilteredReservations(undefined));
      setReservationModalInitialData(undefined);
    }
  }, [dispatch, reservationInfoModalOpen, openReservationModal]);

  const handleEventDragDrop = useCallback(
    async (arg: EventDropArg) => {
      const { revert } = arg;
      const { isMultiReservation, userId, locationId } = arg.event._def.extendedProps;
      const { start: oldDate } = arg.oldEvent;
      const { start: newDate, id } = arg.event;

      const originalReservation = reservationsState?.calendarData?.find(r => r.id === id);

      try {
        if (!originalReservation) throw new Error();

        const { userComments, isAutoEmployee, end: oldEnd } = originalReservation;
        const oldStart = moment(oldDate).format('YYYY-MM-DD[T]HH:mm:ss');
        const duration = moment(oldEnd).diff(moment(oldStart), 'minutes');
        const start = moment(newDate).format('YYYY-MM-DD[T]HH:mm:ss');
        const end = moment(newDate)
          .add(duration, 'minutes')
          .format('YYYY-MM-DD[T]HH:mm:ss');

        if (isMultiReservation) {
          await dispatch(
            updateGroupReservation(
              {
                locationId,
                userId,
                start: oldStart,
              },
              {
                start,
                end,
                userId,
                duration,
                userComments,
                isAutoEmployee,
              },
              true,
            ),
          );
        } else {
          await dispatch(
            editReservation(
              {
                ...originalReservation,
                start,
                end,
              },
              id,
            ),
          );
        }
      } catch (err) {
        revert();
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    },
    [reservationsState.calendarData, dispatch, setError],
  );

  return (
    <Container fluid className='p-0'>
      <Row>
        {(loadingCalendarResources || loading || reservationSettingsState.isLoading) && (
          <div className='grey-background-disabled'>
            <Spinner animation='border' variant='primary' />
          </div>
        )}
        <Col xs={12} className='p-0'>
          <Card className='mainCard '>
            <CalendarHeader
              selectedEmployee={selectedEmpl}
              selectedLocation={selectedLocation}
              date={startDate}
              activeView={activeView}
              handleClick={() => setOpenReservationModal(true)}
              roles={user?.roles}
            />
            <Card.Body>
              <Row className='justify-content-between calendar-controls'>
                <CalendarControls
                  isLoading={loadingCalendarResources}
                  switchToDayView={switchToDayView}
                  switchToWeekView={switchToWeekView}
                  setSelectedEmpl={setSelectedEmpl}
                  setSelectedLocation={setSelectedLocation}
                  setStartDate={setStartDate}
                  activeView={activeView}
                  calendarRef={calendarRef}
                  locationOptions={locationOptions}
                  emplOptions={emplOptions}
                  setDateChanged={setDateChanged}
                  dateChanged={dateChanged}
                  handleAdd={() => setOpenReservationModal(true)}
                  roles={user?.roles}
                  userId={user?.userId}
                  selectedEmpl={selectedEmpl}
                  startDate={startDate}
                />
              </Row>
              <Row>
                <Col className='relative'>
                  {!loading && !reservationSettingsState.isLoading && (
                    <div>
                      {(!resourcesToShow?.length || !locationOptions?.length) && (
                        <div className='grey-background-disabled'>
                          <span className='no-resources-text'>
                            <MdReportProblem style={{ marginRight: 5 }} />
                            {t(
                              'reservation_calendar_no_users_activity_type_pasiutuslape',
                            )}
                          </span>
                        </div>
                      )}

                      <FullCalendar
                        firstDay={
                          reservationSettings?.locale === SettingsLocales.EN ? 0 : 1
                        }
                        schedulerLicenseKey={Env.REACT_APP_CALENDAR_LICENSE_KEY}
                        plugins={[
                          resourceTimeGridPlugin,
                          scrollGridPlugin,
                          interactionPlugin,
                          momentTimezonePlugin,
                        ]}
                        dayMinWidth={70}
                        initialView='resourceTimeGridDay'
                        resourceOrder='title'
                        headerToolbar={{ left: '', right: '' }}
                        timeZone={`Europe/${reservationSettingsState.data?.calendarTimeZone}`}
                        locale={
                          reservationSettingsState.data?.calendarTimeZone ===
                          SettingsTimezones.VILNIUS
                            ? 'lt'
                            : 'pl'
                        }
                        nowIndicator
                        height='auto'
                        allDaySlot={false}
                        slotDuration={toHoursAndMinutes(
                          reservationSettingsState?.data
                            ?.webReservationCalendarInterval || 15,
                        )}
                        eventAllow={isEventDragAllowed}
                        droppable
                        editable
                        eventOverlap={false}
                        eventDurationEditable={false}
                        eventDrop={handleEventDragDrop}
                        slotLabelFormat={{
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                          meridiem: 'short',
                        }}
                        dateClick={handleCalendarTimeSlotClick}
                        slotMinTime={
                          `${
                            reservationSettingsState.data?.calendarHoursFrom
                              ? reservationSettingsState.data?.calendarHoursFrom
                              : 0
                          }:00` || '00:00'
                        }
                        slotMaxTime={
                          `${
                            reservationSettingsState.data?.calendarHoursTo
                              ? reservationSettingsState.data?.calendarHoursTo
                              : 24
                          }:00` || '23:55'
                        }
                        resources={resourcesToShow}
                        events={filteredEvents}
                        ref={calendarRef}
                        dayHeaderContent={(args: { date: string }) => {
                          const [day, monthDate] = moment(args.date)
                            .format('dd. MMMM D')
                            .split('.');
                          const [month, currentDay] = monthDate.trim().split(' ');
                          const translatedDate = `${t(day)}. ${t(month)} ${currentDay}`;
                          return translatedDate;
                        }}
                        resourceLabelClassNames={[
                          'resource-label',
                          `${activeView === View.week && 'hide-label'}`,
                        ]}
                        eventContent={(eventContent: EventContentArg) => (
                          <EventsContent
                            eventContent={eventContent}
                            isEmployee={isEmployee}
                          />
                        )}
                        eventClick={handleEventClick}
                        businessHours={false}
                        selectMirror={true} // show time in highlighted cell
                        selectAllow={(span: DateSpanApi) => {
                          const newRecStart = span.start.getTime();
                          const newRecEnds = span.end.getTime();
                          const overlapingEvents = span?.resource
                            ?.getEvents()
                            .filter((rec: any) => {
                              if (rec._def.groupId !== 'businessHours') {
                                const recStart = new Date(
                                  JSON.parse(rec._def.title).start,
                                ).getTime();
                                const recEnd = new Date(
                                  JSON.parse(rec._def.title).end,
                                ).getTime();
                                if (
                                  (newRecStart == null ||
                                    recEnd == null ||
                                    newRecStart < recEnd) &&
                                  (recStart == null ||
                                    newRecEnds == null ||
                                    recStart < newRecEnds) &&
                                  (newRecStart == null ||
                                    newRecEnds == null ||
                                    newRecStart < newRecEnds) &&
                                  (recStart == null ||
                                    recEnd == null ||
                                    recStart < recEnd)
                                ) {
                                  return true;
                                }
                              }
                              return false;
                            });

                          if (overlapingEvents?.length) return false;
                          // allow max 1 cell selection
                          return (
                            moment(span.end).diff(moment(span.start), 'minutes') <=
                            (reservationSettingsState?.data
                              ?.webReservationCalendarInterval || 15)
                          );
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CalendarModalsWrapper
        openReservationModal={openReservationModal}
        setOpenReservationModal={setOpenReservationModal}
        reservationToEdit={reservationToEdit || emptyReservationObject}
        setReservationToEdit={setReservationToEdit}
        setClientToEdit={setClientToEdit}
        clientToEdit={clientToEdit}
        setReservationInfoModalOpen={setReservationInfoModalOpen}
        reservationInfoModalOpen={reservationInfoModalOpen}
        initialReservationData={reservationModalInitialData}
        setInitialReservationData={setReservationModalInitialData}
        onClientEdit={handleClientEdit}
        urgentMessageModalOpen={urgentMessageModalOpen}
        setUrgentMessageModalOpen={setUrgentMessageModalOpen}
        refetchCalendarProps={{
          dateFrom: formateDate(start),
          dateTo: formateDate(end),
          LocationId: selectedLocation,
        }}
      />
    </Container>
  );
};

export default AdminCalendar;
