import { IReservationWithInfo } from 'types/Reservations';

export const emptyReservationObject: IReservationWithInfo = {
  id: '',
  clientId: '',
  locationId: '',
  serviceId: '',
  servicePricingId: '',
  start: '',
  end: '',
  price: 0,
  duration: 0,
  userComments: '',
  code: 0,
  status: '',
  userId: '',

  serviceName: '',
  servicePricing: {
    id: '',
    name: '',
    duration: 0,
    price: -1,
  },
  timeAfterService: 0,
  employee: '',
  creator: '',
  isAutoConfirm: false,
  isAutoEmployee: false,
};

export const isMobile = window.matchMedia('(max-width: 768px)');

export const TABLE_PAGE_SIZE = 15;

export const PERIODS_FOR_RESERVATION = [{ days: 90 }, { days: 365 }];

export const COUNTRIES = [
  { country: 'LT' },
  { country: 'LV' },
  { country: 'PL' },
  { country: 'CZ' },
];
