import React from 'react';
import Table from 'react-bootstrap/Table';

import { useTable, useExpanded, TableInstance } from 'react-table';

interface ITableExpandable {
  columns: any;
  data: Array<any>;
  customWidth?: string;
  applyCustomWidthTo?: Array<string>;
  striped?: boolean;
  isLoading?: boolean;
  noDataText?: string;
}

const TableExpandable: React.FC<ITableExpandable> = ({
  columns,
  data,
  striped,
  noDataText,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useExpanded,
  ) as TableInstance<object>;

  const getRowStyle = (row: any) => {
    if (row.canExpand) {
      return {
        fontWeight: 700,
        color: '#676a6c',
      };
    } else return {};
  };

  return (
    <>
      <Table {...getTableProps()} striped={striped} responsive='md' size='sm'>
        <thead>
          {data.length ? (
            headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                    key={i}
                    style={{
                      backgroundColor: '#F3F3F4',
                      borderBottom: '1px solid #e7eaec',
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))
          ) : (
            <tr></tr>
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {data.length ? (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  style={{ ...row.getRowProps().style, ...getRowStyle(row) }}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                        key={i}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr style={{ borderBottom: '1px solid #e7eaec' }}>
              <td>{noDataText}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TableExpandable;
