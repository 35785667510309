/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

export interface INotifications {
  notificationsOn: boolean;
  notificationReminderTimeBeforeReservation: number;
  notificationReminderDoNotSendTime: number;
  notificationReminderOn: boolean;
  notificationConfirmedOn: boolean;
  notificationUpdatedOn: boolean;
  notificationCancelledOn: boolean;
  notificationGratitudeOn: boolean;
  notificationBirthdayOn: boolean;
  notificationReminderEmailTitle: string;
  notificationConfirmedEmailTitle: string;
  notificationUpdatedEmailTitle: string;
  notificationCancelledEmailTitle: string;
  notificationGratitudeEmailTitle: string;
  notificationBirthdayEmailTitle: string;
  notificationReminderEmailBody: string;
  notificationConfirmedEmailBody: string;
  notificationUpdatedEmailBody: string;
  notificationCancelledEmailBody: string;
  notificationGratitudeEmailBody: string;
  notificationReminderSms: string;
  notificationConfirmedSms: string;
  notificationUpdatedSms: string;
  notificationCancelledSms: string;
  notificationGratitudeSms: string;
}

export interface IUrgentMessageFields {
  subject: string;
  smsMessage?: string;
  emailMessage?: string;
}

export interface IUrgentMessageBody {
  emailSubject: string;
  emailBodyTemplate?: string;
  smsTemplate?: string;
}

// pick onlu the string properties from the interface
export interface INotificationsStringOnly {
  notificationReminderEmailTitle: string;
  notificationConfirmedEmailTitle: string;
  notificationUpdatedEmailTitle: string;
  notificationCancelledEmailTitle: string;
  notificationGratitudeEmailTitle: string;
  notificationBirthdayEmailTitle: string;
  notificationReminderEmailBody: string;
  notificationConfirmedEmailBody: string;
  notificationUpdatedEmailBody: string;
  notificationCancelledEmailBody: string;
  notificationGratitudeEmailBody: string;
  notificationReminderSms: string;
  notificationConfirmedSms: string;
  notificationUpdatedSms: string;
  notificationCancelledSms: string;
  notificationGratitudeSms: string;
}

export interface INotificationsFuncs {
  confirmations: (val: boolean) => void;
  reminders: (val: boolean) => void;
  changes: (val: boolean) => void;
  cancellations: (val: boolean) => void;
  gratitude: (val: boolean) => void;
}

export enum NotificationsAvailableParams {
  CLIENT_NAME = 'CLIENT_NAME',
  LOCATION_NAME = 'LOCATION_NAME',
  LOCATION_PHONE = 'LOCATION_PHONE',
  SERVICE_NAME = 'SERVICE_NAME',
  RESERVATION_DATE = 'RESERVATION_DATE',
  EMPLOYEE_NAME = 'EMPLOYEE_NAME',
  LOCATION_ADDRESS = 'LOCATION_ADDRESS',
  PERSONS_COUNT = 'PERSONS_COUNT',
  BARCODE = 'BARCODE',
  RESERVATION_DURATION = 'RESERVATION_DURATION',
}

export interface INotificationsState {
  readonly data?: INotifications;
  readonly isLoading?: boolean;
}

export const initialNotificationsState: INotificationsState = {
  data: undefined,
  isLoading: false,
};

export enum NotificationsAdd {
  REQUEST = 'NOTIFICATIONS_ADD_REQUEST',
  SUCCESS = 'NOTIFICATIONS_ADD_SUCCESS',
  FAILED = 'NOTIFICATIONS_ADD_FAILED',
  ADD_NOTIFICATIONS_STORE = 'NOTIFICATIONS_ADD_TO_STORE',
}

export enum NotificationsGet {
  REQUEST = 'NOTIFICATIONS_GET_REQUEST',
  SUCCESS = 'NOTIFICATIONS_GET_SUCCESS',
  FAILED = 'NOTIFICATIONS_GET_FAILED',
}

export enum UrgetMessageSend {
  REQUEST = 'URGENT_MESSAGE_REQUEST',
  SUCCESS = 'URGENT_MESSAGE_SUCCESS',
  FAILED = 'URGENT_MESSAGE_FAILED',
}

export type TNotificationsAddAction =
  | {
      type: NotificationsAdd.REQUEST;
    }
  | {
      type: NotificationsAdd.SUCCESS;
    }
  | {
      type: NotificationsAdd.ADD_NOTIFICATIONS_STORE;
      payload: INotifications;
    }
  | {
      type: NotificationsAdd.FAILED;
      payload: AxiosError;
    };

export type TNotificationsGetAction =
  | {
      type: NotificationsGet.REQUEST;
    }
  | {
      type: NotificationsGet.SUCCESS;
      payload: AxiosResponse<INotifications>;
    }
  | {
      type: NotificationsGet.FAILED;
      payload: AxiosError;
    };

export type TUrgentMessageSendAction =
  | {
      type: UrgetMessageSend.REQUEST;
      payload: IUrgentMessageFields;
    }
  | {
      type: UrgetMessageSend.SUCCESS;
    }
  | {
      type: NotificationsAdd.FAILED;
      payload: AxiosError;
    };

export type TNotificationsActions =
  | TNotificationsGetAction
  | TNotificationsAddAction
  | TResetAction
  | TUrgentMessageSendAction;

export const defaultNotificationTranslationKeys = {
  notificationCancelledEmailBody:
    'settings_default_value_notification_cancelled_email_body',
  notificationCancelledEmailTitle:
    'settings_default_value_notification_cancelled_email_title',
  notificationCancelledSms: 'settings_default_value_notification_cancelled_SMS',
  notificationConfirmedEmailBody:
    'settings_default_value_notification_confirmed_email_body',
  notificationConfirmedEmailTitle:
    'settings_default_value_notification_confirmed_email_title',
  notificationConfirmedSms: 'settings_default_value_notification_confirmed_SMS',
  notificationGratitudeEmailBody:
    'settings_default_value_notification_gratitude_email_body',
  notificationGratitudeEmailTitle:
    'settings_default_value_notification_gratitude_email_title',
  notificationGratitudeSms: 'settings_default_value_notification_gratitude_SMS',
  notificationReminderEmailBody:
    'settings_default_value_notification_reminder_email_body',
  notificationReminderEmailTitle:
    'settings_default_value_notification_reminder_email_title',
  notificationReminderSms: 'settings_default_value_notification_reminder_SMS',
  notificationUpdatedEmailBody: 'settings_default_value_notification_updated_email_body',
  notificationUpdatedEmailTitle:
    'settings_default_value_notification_updated_email_title',
  notificationUpdatedSms: 'settings_default_value_notification_updated_SMS',
};
