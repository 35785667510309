import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'react-final-form';
import { IFormError } from '../../constants/Interfaces';
import FormErrorText from './FormErrorText';

interface IFormInput {
  name: string;
  label?: string;
  options: Array<any>;
  optionValue: string;
  optionDisplay: string;
  hasEmptyOption: boolean;
  emptyOptionLabel: string;
  size?: 'sm' | 'lg';
  inputClass?: string;
  validation?: (value: any) => any;
  fieldError?: IFormError;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
  initialValue?: any;
  id?: string;
}

const handleErrorText = (
  metaError: any,
  inputName: string,
  submitFailed?: boolean,
  metaTouched?: boolean,
  fieldError?: IFormError,
) => {
  if (fieldError && fieldError.causedByField?.toLowerCase() === inputName.toLowerCase()) {
    return <FormErrorText errorText={fieldError.message} />;
  }
  if (metaError && metaTouched && submitFailed) {
    return <FormErrorText errorText={metaError} />;
  }
};

const FormInput: React.FC<IFormInput> = ({
  name,
  size,
  label,
  options,
  optionValue,
  optionDisplay,
  hasEmptyOption,
  emptyOptionLabel,
  inputClass,
  validation,
  onChange,
  fieldError,
  disabled,
  initialValue,
  id,
}) => {
  return (
    <Field initialValue={initialValue} name={name} validate={validation}>
      {({ input, meta }) => (
        <Form.Group className={inputClass}>
          {label && <Form.Label>{label}</Form.Label>}
          <Form.Control
            {...input}
            disabled={disabled}
            size={size}
            id={id}
            as='select'
            isInvalid={meta.error && meta.touched && meta.submitFailed}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              input.onChange(e);
              onChange?.(e);
            }}
          >
            {hasEmptyOption && <option value=''>{emptyOptionLabel}</option>}
            {options.map((item: any) => {
              return (
                <option key={item[optionValue]} value={item[optionValue]}>
                  {item[optionDisplay]}
                </option>
              );
            })}
          </Form.Control>
          {handleErrorText(meta.error, name, meta.submitFailed, meta.touched, fieldError)}
        </Form.Group>
      )}
    </Field>
  );
};

export default FormInput;
