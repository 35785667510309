import { ReactElement } from 'react';
import { IReservation } from '../types/Reservations';
import { IPricingModal } from '../types/Services';

export interface ISidebarLink {
  name: string;
  icon: ReactElement;
  route: string[];
}

export interface ISort {
  id?: string;
  desc?: boolean;
}

export interface IEmployeeSelect {
  companyId: string;
  name: string;
}

export enum CompanyActivities {
  bookItNow = 'BookItNow',
  geraDovana = 'GeraDovana',
  geraDovanaAndBookItNow = 'GeraDovanaAndBookItNow',
  lieliskaDavana = 'LieliskaDavana',
  superPrezenty = 'SuperPrezenty',
  bombadar = 'Bombadar',
}

export enum PasswordValidation {
  Short = 'passwordToShort',
  NonAlphanumeric = 'passwordNonAlphanumeric',
  NoDigit = 'passwordNeedsDigit',
  NoUpper = 'passwordNoUpper',
}

export enum Roles {
  Admin = 'Admin',
  Employee = 'Employee',
  Analyst = 'Analyst',
}

export enum View {
  day = 'day',
  week = 'week',
  all = 'all',
}

export interface IServiceForCheckBox {
  id: string;
  name: string;
}
export interface IServiceCheckBoxGroup {
  groupName: string;
  groupServices: Array<IServiceForCheckBox>;
}

export interface IServiceTableRow {
  companyId: string;
  groupId: string;
  name: string;
  preparingTime?: number;
  timeAfterService?: number;
  userIds: Array<string>;
  locationIds: Array<string>;
  locationNames?: Array<string>;
  resourceIds?: Array<string>;
  pricings: Array<IPricingModal>;
  id: string;
  code: number;
  creatorId: string;
  createdAt: string;
  updatedAt: string;
  singlePriceId?: string;
  singlePriceDuration?: number;
  singlePricePrice?: number;
  hiddenInPlugin: boolean;
}

export interface IFormError {
  title: string;
  message: string;
  causedByField?: string;
}

export interface ICalendarEvent extends IReservation {
  resourceId: string;
  title?: string;
  backgroundColor: string;
  borderColor?: string;
  display?: string;
  isMultiReservation?: boolean;
}
