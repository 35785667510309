import React from 'react';
import './LabelCell.scss';

interface ILabelCell {
  cellData: any;
  displayKey?: string;
  idKey?: string;
}

const LabelCell: React.FC<ILabelCell> = ({ cellData, displayKey, idKey }) => {
  return (
    <div className='d-flex wrap'>
      {cellData.map((item: any, index: number) => (
        <div className='mr-1 mt-1 label' key={idKey ? item[idKey] : index}>
          {displayKey ? item[displayKey] : item}
        </div>
      ))}
    </div>
  );
};

export default LabelCell;
