import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FaEdit, FaPlus } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { GoEye } from 'react-icons/go';
import { IAppState } from '../../store/Store';
import { TCompanyState, ICompany } from '../../types/Companies';
import {
  getCompanies,
  deleteCompany,
  changeOffset,
} from '../../actions/CompaniesActions';
import TableSortable from '../../components/Utils/Tables/TableSortable';
import PaginationComp from '../../components/Utils/Tables/PaginationComp';
import TableSearch from '../../components/Utils/Tables/TableSearch';
import CompanyModal from '../../components/SuperAdmin/Companies/CompanyModal';
import Toast from '../../components/Utils/Toast';
import { handleError } from '../../middlewares/ErrorHandler';
import { IFormError, ISort } from '../../constants/Interfaces';
import { Roles } from '../../constants/Roles';
import useError from '../../hooks/useError';
import AgreeToDelete from '../../components/Utils/Modals/AgreeToDelete';
import { TABLE_PAGE_SIZE } from '../../constants/UtilVariables';
import { ROUTES } from 'constants/Routes';
import { getAccessToken, parseUserData } from 'helpers/Misc';
import { COMPANIES_PAGE, COMPANY_ID_ITEM } from 'constants/Storage';

import './AdminCompanies.scss';
import { useStoreState } from 'hooks/useStoreState';
import { setSuperAdminCompanySearch } from 'reducers/UtilsReducer';

const AdminCompanies: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState<IFormError>();
  const [tableData, setTableData] = useState<ICompany[]>([]);

  const { superadminCompanySearch } = useStoreState().utilsState;
  const [searchQuery, setSearchQuery] = useState(superadminCompanySearch || '');
  const [sortQuery, setSortQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [editCompany, setEditCompany] = useState<ICompany>();
  const [companyToDelete, setCompanyToDelete] = useState<string>();
  const [pages, setPages] = useState<Array<number>>();
  const [activePage, setActivePage] = useState<number>(0);

  useError(error, setError);
  const companyState: TCompanyState = useSelector<IAppState, TCompanyState>(
    (state: IAppState) => state.companyState,
  );

  const token = getAccessToken();
  const user = token ? parseUserData(token) : null;
  const storedPage = localStorage.getItem(COMPANIES_PAGE);

  useEffect(() => {
    if (storedPage) {
      setActivePage(Number(storedPage));
      dispatch(changeOffset(Number(storedPage) * TABLE_PAGE_SIZE));
    } else {
      dispatch(changeOffset(0));
    }
  }, []);

  // trigger only on page mount
  useEffect(() => {
    (async () => {
      try {
        await dispatch(
          getCompanies(
            companyState.offset || 0,
            superadminCompanySearch || '',
            undefined,
            TABLE_PAGE_SIZE,
          ),
        );
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
    return () => {
      if (!storedPage) {
        dispatch(changeOffset(0));
      }
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (companyState.data) {
        setTableData(companyState.data);
        if (companyState.total) {
          const pages = Array.from(
            Array(Math.ceil(companyState.total / TABLE_PAGE_SIZE)).keys(),
          );
          if (pages.length > 1) {
            setPages(pages);
          } else {
            setPages(undefined);
          }
        }
      }
    })();
  }, [dispatch, companyState.data]);

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('company_table_col_name')}`,
        accessor: 'name',
        id: 'name',
      },
      {
        Header: `${t('company_table_col_id')}`,
        accessor: 'id',
        id: 'id',
      },
      {
        Header: '',
        id: 'buttons',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: any) => (
          <div className='flex'>
            {user?.roles.includes(Roles.SuperViewer) && (
              <Button
                size='sm'
                className='btn btn-sm btn-superview centerIcon mx-1 py-2 my-lg-0 white'
                data-tip
                data-for={`super-viewer-tooltip`}
                onClick={() => {
                  localStorage.setItem(COMPANY_ID_ITEM, cell.row.original.id);
                  history.push(ROUTES.ADMIN.RESERVATIONS);
                }}
              >
                <GoEye className='eye-icon' />
              </Button>
            )}

            <ReactTooltip
              id={`super-viewer-tooltip`}
              place='top'
              effect='solid'
              className='superview-tooltip'
            >
              {t('view_company')}
            </ReactTooltip>
            <Button
              size='sm'
              className='btn btn-sm btn-warning centerIcon mx-1 py-2 my-lg-0 white'
              onClick={() => {
                setEditCompany(cell.row.original);
                setOpen(true);
              }}
            >
              <FaEdit />
            </Button>
            <Button
              size='sm'
              className='btn btn-sm btn-danger centerIcon mx-1 py-2 my-lg-0'
              onClick={() => setCompanyToDelete(cell.row.original.id)}
            >
              <IoClose />
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (companyState.total) {
      const pages = Array.from(
        Array(Math.ceil(companyState.total / TABLE_PAGE_SIZE)).keys(),
      );
      if (pages.length > 1) setPages(pages);
      else setPages(undefined);
    } else {
      setPages(undefined);
    }
  }, [companyState.total]);

  const handleSort = useCallback(async (sortBy: Array<ISort>) => {
    try {
      if (sortBy[0].id) {
        const sortDirection = sortBy[0].desc ? 'desc' : 'asc';
        const sortQuery = `${sortBy[0].id} ${sortDirection}`;
        setSortQuery(sortQuery);
        await dispatch(
          getCompanies(companyState.offset || 0, searchQuery, sortQuery, TABLE_PAGE_SIZE),
        );
      }
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  }, []);

  const handleDelete = async () => {
    if (companyToDelete) {
      try {
        await dispatch(deleteCompany(companyToDelete));
        toast.success(
          <Toast
            text={t('successfullyDeleted', { item: t('user_form_label_company') })}
          />,
        );
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    }
  };

  const handleSearch = async () => {
    try {
      setSearchQuery(searchQuery);
      // we set offSet to 0 to get first page data, becouse we dont know if it has more pages
      await dispatch(getCompanies(0, searchQuery, undefined, TABLE_PAGE_SIZE));
      setActivePage(0);
    } catch (err) {
      handleError(
        err?.response?.status,
        setError,
        err.response?.data?.title,
        err.response?.data?.CausedByField,
      );
    }
  };

  const changePage = async (newPage: number) => {
    setActivePage(newPage);
    dispatch(changeOffset(newPage * TABLE_PAGE_SIZE));
    localStorage.setItem(COMPANIES_PAGE, String(newPage));
    await dispatch(
      getCompanies(newPage * TABLE_PAGE_SIZE, searchQuery, undefined, TABLE_PAGE_SIZE),
    );
  };

  useEffect(() => {
    dispatch(setSuperAdminCompanySearch(searchQuery));
  }, [dispatch, searchQuery]);

  return (
    <Container fluid className='p-0'>
      <Row>
        <Col xs={12} className='p-0'>
          <Card className='p-3 w-100'>
            <h3 className='font-weight-light'>{t('company_index_page_title')}</h3>
          </Card>
          <Card className='mainCard'>
            <Card.Header className='title'>{t('company_index_page_title')}</Card.Header>
            <Card.Body>
              <Row className='align-items-start'>
                <Col sm={12} md={7} lg={9} xl={9}>
                  <TableSearch
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    handleSearch={handleSearch}
                  />
                </Col>

                <Col sm={12} md={5} lg={3} xl={3} className='d-flex justify-content-end'>
                  <Button className='btn-info centerIcon' onClick={() => setOpen(true)}>
                    <FaPlus className='pr-1' />
                    {t('company_btn_create_company')}
                  </Button>
                </Col>
              </Row>
              <Row className='mt-4 mt-md-0'>
                <Col sm={12}>
                  <TableSortable
                    data={tableData}
                    columns={columns}
                    onSort={handleSort}
                    striped={false}
                    customWidth='45%'
                    applyCustomWidthTo={['name', 'id']}
                    isLoading={companyState.isLoading}
                  />
                  <PaginationComp
                    activePage={activePage}
                    pages={pages}
                    changePage={changePage}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CompanyModal
        show={open}
        handleClose={() => {
          setOpen(false);
          setEditCompany(undefined);
        }}
        company={editCompany}
        searchQuery={searchQuery}
        sortQuery={sortQuery}
        offset={companyState.offset}
      />
      <AgreeToDelete
        show={!!companyToDelete}
        handleClose={() => setCompanyToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </Container>
  );
};

export default AdminCompanies;
