import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { FieldArray } from 'react-final-form-arrays';
import { IoClose } from 'react-icons/io5';
import { IFormError } from '../../../constants/Interfaces';
import FormSelect from '../../Form/FormSelect';
import FormCheckbox from '../../Form/FormCheckbox';
import { WeekDays } from '../../../types/WorkTime';
import WorkTimeErrors from './WorkTimeErrors';
import cn from 'classnames';
import { getHours, getMinutes } from '../../../helpers/FormHelpers';
import './WorkTimeTable.scss';

interface IWorkTimeTable {
  selectedLocationId: string;
  push?: any;
  submitFailed?: boolean;
  errors?: Record<string, any>;
  fieldError?: IFormError;
  locId?: string;
  values?: any;
  setValue?: (name: string, value: any) => void;
}

const WorkTimeTable: React.FC<IWorkTimeTable> = ({
  push,
  submitFailed,
  errors,
  selectedLocationId,
  locId,
  values,
  setValue,
}) => {
  const { t } = useTranslation();
  const [initialValue] = useState<Array<undefined>>([undefined]);
  if (!selectedLocationId) {
    return null;
  }

  return (
    <Table responsive='md' size='sm' className='work-time-table'>
      <tbody>
        <tr className='gray-bg'>
          <th />
          <th>{t('form_label_work_time_start')}</th>
          <th>{t('form_label_work_time_end')}</th>
          <th />
        </tr>

        {values &&
          values[selectedLocationId] &&
          [
            WeekDays.MONDAY,
            WeekDays.TUESDAY,
            WeekDays.WEDNESDAY,
            WeekDays.THURSDAY,
            WeekDays.FRIDAY,
            WeekDays.SATURDAY,
            WeekDays.SUNDAY,
          ].map(weekday => {
            const rowIsEnabled = values[selectedLocationId][weekday].enabled;
            const rowHasErrors =
              errors &&
              Object.keys(errors).includes(weekday) &&
              (Object.keys(errors[weekday]).includes('timeRange') ||
                Object.keys(errors[weekday]).includes('breakOverlap')) &&
              submitFailed;
            return (
              <Fragment key={weekday}>
                <tr
                  className={cn({
                    'row-invalid': rowHasErrors,
                    'row-disabled gray-bg': !rowIsEnabled,
                  })}
                >
                  <td>
                    <FormCheckbox
                      name={`${selectedLocationId}.${weekday}.enabled`}
                      label={t(weekday)}
                      id={locId + '.' + weekday}
                      groupClass='mb-0'
                    />
                  </td>
                  <td>
                    <Row className='m-0'>
                      <Col xs={6} md={4} className='p-0'>
                        <FormSelect
                          hasEmptyOption={false}
                          emptyOptionLabel={''}
                          name={`${selectedLocationId}.${weekday}.startHour`}
                          options={getHours()}
                          optionValue='id'
                          optionDisplay='name'
                          inputClass='mb-0 time-start'
                        />
                      </Col>
                      <Col xs={6} md={4} className='p-0'>
                        <FormSelect
                          hasEmptyOption={false}
                          emptyOptionLabel={''}
                          name={`${selectedLocationId}.${weekday}.startMin`}
                          options={getMinutes()}
                          optionValue='id'
                          optionDisplay='name'
                          inputClass='mb-0'
                        />
                      </Col>
                    </Row>
                  </td>
                  <td>
                    <Row className='m-0'>
                      <Col xs={6} md={4} className='p-0'>
                        <FormSelect
                          hasEmptyOption={false}
                          emptyOptionLabel={''}
                          name={`${selectedLocationId}.${weekday}.endHour`}
                          options={getHours()}
                          optionValue='id'
                          optionDisplay='name'
                          inputClass='mb-0 time-start'
                        />
                      </Col>
                      <Col xs={6} md={4} className='p-0'>
                        <FormSelect
                          hasEmptyOption={false}
                          emptyOptionLabel={''}
                          name={`${selectedLocationId}.${weekday}.endMin`}
                          options={getMinutes()}
                          optionValue='id'
                          optionDisplay='name'
                          inputClass='mb-0'
                        />
                      </Col>
                    </Row>
                  </td>
                  <td className='d-flex justify-content-end'>
                    <Button
                      className='btn-info centerIcon'
                      onClick={() =>
                        push(`${selectedLocationId}.${weekday}.breaks`, {
                          startHour: '00',
                          startMin: '00',
                          endHour: '00',
                          endMin: '00',
                        })
                      }
                    >
                      <FaPlus className='pr-1' />
                      {t('form_work_time_break')}
                    </Button>
                  </td>
                </tr>

                {errors && Object.keys(errors).includes(weekday) && submitFailed && (
                  <WorkTimeErrors errors={errors[weekday]} />
                )}

                <FieldArray
                  name={`${selectedLocationId}.${weekday}.breaks`}
                  initialValue={initialValue}
                >
                  {({ fields }) => (
                    <>
                      {fields.map((workBreak, index) => {
                        const hasBreakError =
                          errors &&
                          submitFailed &&
                          Object.keys(errors).includes(weekday) &&
                          Object.keys(errors[weekday]).includes('breaks') &&
                          Object.keys(errors[weekday]['breaks']).includes(
                            index.toString(),
                          );
                        return (
                          <Fragment key={workBreak}>
                            <tr
                              className={cn({
                                'break-error': hasBreakError,
                                'd-none': !rowIsEnabled,
                              })}
                            >
                              <td>{t('form_work_time_break')}</td>
                              <td>
                                <Row className='m-0'>
                                  <Col xs={6} md={4} className='p-0'>
                                    <FormSelect
                                      hasEmptyOption={false}
                                      emptyOptionLabel={''}
                                      name={`${workBreak}.startHour`}
                                      options={getHours()}
                                      optionValue='id'
                                      optionDisplay='name'
                                      inputClass='mb-0 time-start'
                                    />
                                  </Col>
                                  <Col xs={6} md={4} className='p-0'>
                                    <FormSelect
                                      hasEmptyOption={false}
                                      emptyOptionLabel={''}
                                      name={`${workBreak}.startMin`}
                                      options={getMinutes()}
                                      optionValue='id'
                                      optionDisplay='name'
                                      inputClass='mb-0'
                                    />
                                  </Col>
                                </Row>
                              </td>
                              <td>
                                <Row className='m-0'>
                                  <Col xs={6} md={4} className='p-0'>
                                    <FormSelect
                                      hasEmptyOption={false}
                                      emptyOptionLabel={''}
                                      name={`${workBreak}.endHour`}
                                      options={getHours()}
                                      optionValue='id'
                                      optionDisplay='name'
                                      inputClass='mb-0 time-start'
                                    />
                                  </Col>
                                  <Col xs={6} md={4} className='p-0'>
                                    <FormSelect
                                      hasEmptyOption={false}
                                      emptyOptionLabel={''}
                                      name={`${workBreak}.endMin`}
                                      options={getMinutes()}
                                      optionValue='id'
                                      optionDisplay='name'
                                      inputClass='mb-0'
                                    />
                                  </Col>
                                </Row>
                              </td>
                              <td className='d-flex justify-content-end'>
                                <Button
                                  size='sm'
                                  className='btn btn-sm btn-danger centerIcon mx-1 py-2 my-lg-0'
                                  onClick={() => {
                                    setValue?.(
                                      `${selectedLocationId}.${weekday}.breaks`,
                                      fields.value.filter((val, i) => i !== index),
                                    );
                                  }}
                                >
                                  <IoClose />
                                </Button>
                              </td>
                            </tr>
                            {errors && hasBreakError && (
                              <WorkTimeErrors
                                errors={{ breakErrors: errors[weekday]['breaks'][index] }}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </FieldArray>
              </Fragment>
            );
          })}
      </tbody>
    </Table>
  );
};

export default WorkTimeTable;
