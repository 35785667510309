import Env from './Env';

export const API_END_POINTS = {
  REGISTER: `${Env.REACT_APP_API_URL}/admin-api/Identity/register`,
  GET_USER_LOCATION: `https://ipinfo.io/json?token=${Env.REACT_APP_IP_TOKEN}`,
  LOGIN: `${Env.REACT_APP_API_URL}/connect/token`,
  LOGOUT: `${Env.REACT_APP_API_URL}/admin-api/Identity/logout`,
  GET_EMPLOYEES: `${Env.REACT_APP_API_URL}/admin-api/Users`,
  GET_EMPLOYEE_BY_ID: (id: string | number) =>
    `${Env.REACT_APP_API_URL}/admin-api/Users/${id}`,
  ADD_EMPLOYEE: `${Env.REACT_APP_API_URL}/admin-api/Users`,
  EMPLOYEE_AVATAR: (id: string | number) =>
    `${Env.REACT_APP_API_URL}/admin-api/Users/${id}/ProfilePhoto`,
  EDIT_EMPLOYEE: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Users/${id}`,
  DELETE_EMPLOYEE: (employeeId: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Users/${employeeId}`,
  SEND_EMPLOYEE: (employeeId: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Users/${employeeId}/CalendarSynchronization/SendForApproval`,
  SEND_EMPLOYEE_REVOKE: (employeeId: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Users/${employeeId}/CalendarSynchronization/Revoke`,
  GET_STATUS_EMPLOYEE: (employeeId: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Users/${employeeId}/CalendarSynchronization/Status`,
  GET_COMPANIES: `${Env.REACT_APP_API_URL}/admin-api/Companies`,
  ADD_COMPANY: `${Env.REACT_APP_API_URL}/admin-api/Companies`,
  EDIT_COMPANY: (companyId: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Companies/${companyId}`,
  DELETE_COMPANY: (companyId: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Companies/${companyId}`,
  GET_COMPANY_BY_ID: (companyId: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Companies/${companyId}`,
  GET_LOCATIONS: `${Env.REACT_APP_API_URL}/admin-api/Locations`,
  ADD_LOCATION: `${Env.REACT_APP_API_URL}/admin-api/Locations`,
  EDIT_LOCATION: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Locations/${id}`,
  DELETE_LOCATION: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Locations/${id}`,
  REFRESH_TOKEN: `${Env.REACT_APP_API_URL}/connect/token`,
  GET_SERVICES: `${Env.REACT_APP_API_URL}/admin-api/Services`,
  ADD_SERVICES: `${Env.REACT_APP_API_URL}/admin-api/Services`,
  EDIT_SERVICE: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Services/${id}`,
  DELETE_SERVICE: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Services/${id}`,
  GET_SERVICE_GROUPS: `${Env.REACT_APP_API_URL}/admin-api/ServiceGroups`,
  ADD_SERVICE_GROUP: `${Env.REACT_APP_API_URL}/admin-api/ServiceGroups`,
  EDIT_SERVICE_GROUP: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/ServiceGroups/${id}`,
  DELETE_SERVICE_GROUP: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/ServiceGroups/${id}`,
  GET_RESOURCES: `${Env.REACT_APP_API_URL}/admin-api/Resources`,
  ADD_RESOURCE: `${Env.REACT_APP_API_URL}/admin-api/Resources`,
  EDIT_RESOURCES: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Resources/${id}`,
  DELETE_RESOURCES: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Resources/${id}`,
  GET_CLIENTS: `${Env.REACT_APP_API_URL}/admin-api/Clients`,
  ADD_CLIENT: `${Env.REACT_APP_API_URL}/admin-api/Clients`,
  EDIT_CLIENT: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Clients/${id}`,
  DELETE_CLIENT: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Clients/${id}`,
  IMPORT_CLIENTS: `${Env.REACT_APP_API_URL}/admin-api/Clients/import`,
  GET_CLIENT_BY_ID: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/Clients/${id}`,
  GET_VACATIONS: `${Env.REACT_APP_API_URL}/admin-api/VacationDays`,
  ADD_VACATION: `${Env.REACT_APP_API_URL}/admin-api/VacationDays`,
  EDIT_VACATION: (id: string) => `${Env.REACT_APP_API_URL}/admin-api/VacationDays/${id}`,
  DELETE_VACATION: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/VacationDays/${id}`,
  GET_RESERVATIONS: `${Env.REACT_APP_API_URL}/admin-api/Reservations`,
  GET_RESERVATION_BY_ID: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Reservations/${id}`,
  GET_RESERVATIONS_CALENDAR: `${Env.REACT_APP_API_URL}/admin-api/Reservations/calendar`,
  ADD_RESERVATION: `${Env.REACT_APP_API_URL}/admin-api/Reservations`,
  EDIT_RESERVATION: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Reservations/${id}`,
  DELETE_RESERVATION: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Reservations/${id}/cancel`,
  GET_WORK_TIME: `${Env.REACT_APP_API_URL}/admin-api/WeekdayWorkTime`,
  ADD_WORK_TIME: (userId: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/WeekdayWorkTime?UserId=${userId}`,
  GET_CUSTOM_WORK_TIME: `${Env.REACT_APP_API_URL}/admin-api/CustomWorkTime`,
  ADD_CUSTOM_WORK_TIME: `${Env.REACT_APP_API_URL}/admin-api/CustomWorkTime`,
  EDIT_CUSTOM_WORK_TIME: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/CustomWorkTime/${id}`,
  DELETE_CUSTOM_WORK_TIME: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/CustomWorkTime/${id}`,
  GET_CUSTOM_WORK_TIME_BY_ID: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/CustomWorkTime/${id}`,
  GET_RESERVATION_SETTINGS: `${Env.REACT_APP_API_URL}/admin-api/Settings/reservation`,
  ADD_RESERVATION_SETTINGS: `${Env.REACT_APP_API_URL}/admin-api/Settings/reservation`,
  GET_NOTIFICATIONS_SETTINGS: `${Env.REACT_APP_API_URL}/admin-api/Settings/notifications`,
  ADD_NOTIFICATIONS_SETTINGS: `${Env.REACT_APP_API_URL}/admin-api/Settings/notifications`,
  GET_USER_AVAILABLE_TIME: `${Env.REACT_APP_API_URL}/admin-api/Timetable/usersAvailability`,
  GET_USER_AVAILABLE_SERVICE_TIME: `${Env.REACT_APP_API_URL}/admin-api/Timetable/userServicesAvailability`,
  GET_REPORTS_BY_SERVICES: `${Env.REACT_APP_API_URL}/admin-api/Reports/ReservationsByServices`,
  GET_REPORTS_BY_USERS: `${Env.REACT_APP_API_URL}/admin-api/Reports/ReservationsByUsers`,
  GET_REPORTS_EXCEL: `${Env.REACT_APP_API_URL}/admin-api/Reports/Excel`,
  GET_REPORTS_PDF: `${Env.REACT_APP_API_URL}/admin-api/Reports/Pdf`,
  GET_CALENDAR_PDF: `${Env.REACT_APP_API_URL}/admin-api/Reports/Calendar/Pdf`,
  UPDATE_RESERVATION_GROUP: (locationId: string, userId: string, start: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/ReservationGroups/location/${locationId}/user/${userId}/start/${start}`,
  CANCEL_RESERVATION_GROUP: (locationId: string, userId: string, start: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/ReservationGroups/location/${locationId}/user/${userId}/start/${start}`,
  // Plugin API
  GET_COMPANY_EXPERIENCE: `${Env.REACT_APP_API_URL}/plugin-api/company`,
  GET_LOCATION_EXPERIENCE: `${Env.REACT_APP_API_URL}/plugin-api/locations`,
  GET_SERVICES_EXPERIENCE: `${Env.REACT_APP_API_URL}/plugin-api/services`,
  GET_SERVICE_GROUPS_EXPERIENCE: `${Env.REACT_APP_API_URL}/plugin-api/ServiceGroups`,
  GET_EMPLOYEES_EXPERIENCE: `${Env.REACT_APP_API_URL}/plugin-api/users`,
  GET_AVAILABLE_TIMES_EXPERIENCE: `${Env.REACT_APP_API_URL}/plugin-api/timetable/reservationTimeSlots`,
  GET_RESERVATION_DATES_EXPERIENCE: `${Env.REACT_APP_API_URL}/plugin-api/timetable/reservationDates`,
  ADD_EXPERIENCE_CLIENT: `${Env.REACT_APP_API_URL}/plugin-api/clients`,
  ADD_EXPERIENCE_RESERVATION: `${Env.REACT_APP_API_URL}/plugin-api/reservations`,
  ADD_EXPERIENCE_RESERVATION_MULTIPLE: `${Env.REACT_APP_API_URL}/plugin-api/reservations/multiple`,
  SEND_URGENT_MESSAGE: (id: string) =>
    `${Env.REACT_APP_API_URL}/admin-api/Reservations/${id}/urgentMessage`,
};

export const PAGE_LIMIT = 1000;
