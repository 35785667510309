import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

export interface IServiceGroup {
  companyId: string;
  name: string;
  id: string;
  createdAt?: string;
  updatedAt?: string;
  creatorId?: string;
}

export interface IGetServiceGroups {
  data: Array<IServiceGroup>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

export interface IServiceGroupData {
  name: string;
  companyId: string;
}

export interface TServiceGroupState {
  readonly data?: Array<IServiceGroup>;
  readonly allData?: Array<IServiceGroup>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
}

export const initialServiceGroupState: TServiceGroupState = {
  data: undefined,
  allData: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
};

export enum ServiceGroupsGet {
  REQUEST = 'SERVICE_GROUPS_GET_REQUEST',
  SUCCESS = 'SERVICE_GROUPS_GET_SUCCESS',
  FAILED = 'SERVICE_GROUPS_GET_FAILED',
}

export enum ServiceGroupGetAll {
  REQUEST = 'SERVICE_GROUPS_GET_ALL_REQUEST',
  SUCCESS = 'SERVICE_GROUPS_GET_ALL_SUCCESS',
  FAILED = 'SERVICE_GROUPS_GET_ALL_FAILED',
}

export enum ServiceGroupAdd {
  REQUEST = 'SERVICE_GROUP_ADD_REQUEST',
  SUCCESS = 'SERVICE_GROUP_ADD_SUCCESS',
  FAILED = 'SERVICE_GROUP_ADD_FAILED',
  ADD_SERVICE_GROUP_STORE = 'SERVICE_GROUP_ADD_TO_STORE',
}

export enum ServiceGroupEdit {
  REQUEST = 'SERVICE_GROUP_EDIT_REQUEST',
  SUCCESS = 'SERVICE_GROUP_EDIT_SUCCESS',
  FAILED = 'SERVICE_GROUP_EDIT_FAILED',
  EDIT_SERVICE_GROUP_STORE = 'SERVICE_GROUP_EDIT_ADD_TO_STORE',
}

export enum ServiceGroupDelete {
  REQUEST = 'SERVICE_GROUP_DELETE_REQUEST',
  SUCCESS = 'SERVICE_GROUP_DELETE_SUCCESS',
  FAILED = 'SERVICE_GROUP_DELETE_SUCCESS',
  DELETE_SERVICE_GROUP_STORE = ' SERVICE_GROUP_DELETE_FROM_STORE',
}

export type TServiceGroupDeleteAction =
  | {
      type: ServiceGroupDelete.REQUEST;
    }
  | {
      type: ServiceGroupDelete.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: ServiceGroupDelete.DELETE_SERVICE_GROUP_STORE;
      payload: string;
    }
  | {
      type: ServiceGroupDelete.FAILED;
      payload: AxiosError;
    };

export type TServiceGroupEditAction =
  | {
      type: ServiceGroupEdit.REQUEST;
    }
  | {
      type: ServiceGroupEdit.SUCCESS;
      payload: AxiosResponse<null>;
    }
  | {
      type: ServiceGroupEdit.EDIT_SERVICE_GROUP_STORE;
      payload: IServiceGroup;
    }
  | {
      type: ServiceGroupEdit.FAILED;
      payload: AxiosError;
    };

export type TServiceGroupsGetAllAction =
  | {
      type: ServiceGroupGetAll.REQUEST;
    }
  | {
      type: ServiceGroupGetAll.SUCCESS;
      payload: AxiosResponse<IGetServiceGroups>;
    }
  | {
      type: ServiceGroupGetAll.FAILED;
      payload: AxiosError;
    };

export type TServiceGroupAddAction =
  | {
      type: ServiceGroupAdd.REQUEST;
    }
  | {
      type: ServiceGroupAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: ServiceGroupAdd.ADD_SERVICE_GROUP_STORE;
      payload: IServiceGroup;
    }
  | {
      type: ServiceGroupAdd.FAILED;
      payload: AxiosError;
    };

export type TServiceGroupsGetAction =
  | {
      type: ServiceGroupsGet.REQUEST;
    }
  | {
      type: ServiceGroupsGet.SUCCESS;
      payload: AxiosResponse<IGetServiceGroups>;
    }
  | {
      type: ServiceGroupsGet.FAILED;
      payload: AxiosError;
    };

export type TServiceGroupsActions =
  | TServiceGroupsGetAction
  | TServiceGroupsGetAllAction
  | TServiceGroupEditAction
  | TServiceGroupDeleteAction
  | TServiceGroupAddAction
  | TResetAction;
