import { EGoogleRedirectStatus } from 'constants/Google';
import i18next from '../../config/i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './StatusDisplayer.scss';

interface IStatusDisplayer {
  status: EGoogleRedirectStatus;
}

const StatusDisplayer: React.FC<IStatusDisplayer> = ({ status }) => {
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const locale = queryParams.get('locale');
      if (locale) {
        await i18next.changeLanguage(locale.toLowerCase() || 'en');
      }
    })();
  }, []);

  const handleStatusMessage = useMemo(() => {
    switch (status) {
      case EGoogleRedirectStatus.APPROVED:
        return <p>{t('synchro_has_been_enabled')}</p>;
      case EGoogleRedirectStatus.DENIED:
        return <p>{t('synchro_has_not_been_enabled')}</p>;
      case EGoogleRedirectStatus.ERROR:
        return <p>{t('synchro_has_error')}</p>;
      default:
        break;
    }
  }, [status, t]);

  return (
    <div className='google-status-wrapper'>
      <div className='status-box'>
        <div className='img-wrapper'>
          <img
            src='/logo.png'
            width='130px'
            height='65px'
            alt={t('reservation_system_name')}
          />
        </div>
        {handleStatusMessage}
      </div>
    </div>
  );
};

export default StatusDisplayer;
