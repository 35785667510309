/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

export enum SettingsTimezones {
  VILNIUS = 'Vilnius',
  WARSAW = 'Warsaw',
}

export enum SettingsLocales {
  LT = 'LT',
  EN = 'EN',
  LV = 'LV',
  RU = 'RU',
  PL = 'PL',
  CZ = 'CZ',
}

export enum SettingsCurrencies {
  EUR = 'EUR',
  ZL = 'ZL',
  CZK = 'CZK',
}

export const CurrencySymbols: Record<SettingsCurrencies, string> = {
  [SettingsCurrencies.EUR]: '€',
  [SettingsCurrencies.ZL]: 'zł',
  [SettingsCurrencies.CZK]: 'Kč',
};

export interface IReservationSettings {
  reservationTimeToServiceStart: number;
  maxReservationTimeInFuture: number;
  maxTimeToCancelFulfilledReservation: number;
  calendarHoursFrom: number;
  calendarHoursTo: number;
  calendarTimeZone: SettingsTimezones;
  locale: SettingsLocales;

  webReservationMinimalTimeBeforeReservation: number;
  webReservationNotification: boolean;
  webReservationNotificationForEmployees: boolean;
  webReservationNotificationEmail?: string;
  webReservationCalendarInterval: number;
  currency: SettingsCurrencies;
}

export interface IGetReservationSettings {
  data: IReservationSettings;
}

export interface IReservationSettingsState {
  readonly data?: IReservationSettings;
  readonly isLoading?: boolean;
}

export const initialReservationSettingsState: IReservationSettingsState = {
  data: undefined,
  isLoading: false,
};

export enum ReservationSettingsAdd {
  REQUEST = 'RESERVATION_SETTINGS_ADD_REQUEST',
  SUCCESS = 'RESERVATION_SETTINGS_ADD_SUCCESS',
  FAILED = 'RESERVATION_SETTINGS_ADD_FAILED',
  ADD_RESERVATION_SETTINGS_STORE = 'RESERVATION_SETTINGS_ADD_TO_STORE',
}

export enum ReservationSettingsGet {
  REQUEST = 'RESERVATION_SETTINGS_GET_REQUEST',
  SUCCESS = 'RESERVATION_SETTINGS_GET_SUCCESS',
  FAILED = 'RESERVATION_SETTINGS_GET_FAILED',
}

export type TReservationSettingsAddAction =
  | {
      type: ReservationSettingsAdd.REQUEST;
    }
  | {
      type: ReservationSettingsAdd.SUCCESS;
    }
  | {
      type: ReservationSettingsAdd.ADD_RESERVATION_SETTINGS_STORE;
      payload: IReservationSettings;
    }
  | {
      type: ReservationSettingsAdd.FAILED;
      payload: AxiosError;
    };

export type TReservationSettingsGetAction =
  | {
      type: ReservationSettingsGet.REQUEST;
    }
  | {
      type: ReservationSettingsGet.SUCCESS;
      payload: AxiosResponse<IReservationSettings>;
    }
  | {
      type: ReservationSettingsGet.FAILED;
      payload: AxiosError;
    };

export type TReservationSettingsActions =
  | TReservationSettingsGetAction
  | TReservationSettingsAddAction
  | TResetAction;
