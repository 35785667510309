import {
  TEmployeeState,
  initialEmployeeState,
  TEmployeesActions,
  EmployeesGet,
  EmployeesGetAll,
  EmployeeDelete,
  EmployeeAdd,
  EmployeeEdit,
  EmployeesPut,
  IEmployee,
  EmployeeSendStatusEmail,
} from '../types/Employees';
import { Reducer } from 'redux';
import { ResetStore } from 'types/App';

export const employeesReducer: Reducer<TEmployeeState, TEmployeesActions> = (
  state = initialEmployeeState,
  action,
) => {
  switch (action.type) {
    case ResetStore.RESET: {
      return initialEmployeeState;
    }
    case EmployeesGetAll.REQUEST:
    case EmployeeEdit.REQUEST:
    case EmployeeAdd.REQUEST:
    case EmployeeDelete.REQUEST:
    case EmployeesGet.REQUEST:
    case EmployeeSendStatusEmail.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case EmployeesGetAll.FAILED:
    case EmployeeEdit.FAILED:
    case EmployeeAdd.FAILED:
    case EmployeeDelete.FAILED:
    case EmployeesGet.FAILED:
    case EmployeeSendStatusEmail.FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case EmployeesGetAll.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allData: action.payload.data.data,
      };
    }
    case EmployeesPut.EPLOYEES_OFFSET_UPDATE: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    case EmployeeDelete.DELETE_COMPANY_STORE: {
      let newAllData: IEmployee[] = [];
      if (state.allData) {
        newAllData = state.allData.filter((rec: any) => rec.id !== action.payload);
      } else if (state.data) {
        newAllData = state.data.filter((rec: any) => rec.id !== action.payload);
      }
      return {
        ...state,
        data: state.data
          ? state.data.filter((rec: any) => rec.id !== action.payload)
          : [],
        total: state.total ? state.total - 1 : 0,
        allData: newAllData,
      };
    }
    case EmployeeAdd.ADD_EMPLOYEE_STORE: {
      return {
        ...state,
        data: state.data ? [...state?.data, action.payload] : [action.payload],
        total: (state.total || 0) + 1,
        allData: state.allData
          ? [...state.allData, action.payload]
          : [...(state.data ? state.data : []), action.payload],
      };
    }
    case EmployeeEdit.EDIT_EMPLOYEE_STORE: {
      let newAllData: IEmployee[] = [];
      if (state.allData) {
        newAllData = state.allData.map(rec =>
          rec.id === action.payload.id ? action.payload : rec,
        );
      } else if (state.data) {
        newAllData = state.data.map(rec =>
          rec.id === action.payload.id ? action.payload : rec,
        );
      }
      return {
        ...state,
        data: state.data
          ? state.data.map(rec => (rec.id === action.payload.id ? action.payload : rec))
          : [],
        allData: newAllData,
      };
    }
    case EmployeeEdit.SUCCESS:
    case EmployeeAdd.SUCCESS:
    case EmployeeDelete.SUCCESS:
    case EmployeeSendStatusEmail.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case EmployeesGet.SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isLoading: false,
        total: action.payload.data.total,
      };
    }
    default:
      return state;
  }
};
