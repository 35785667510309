import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaExclamationTriangle } from 'react-icons/fa';

interface IReservationsErrors {
  calendarWorkingHoursError?: boolean;
  calendarUserIsBusy?: boolean;
  errors?: Record<string, any>;
  selectedClientId?: string;
}

const ReservationsErrors: FC<IReservationsErrors> = ({
  errors,
  calendarWorkingHoursError,
  calendarUserIsBusy,
  selectedClientId,
}) => {
  const { t } = useTranslation();
  const hasUserIdError = errors ? Object.keys(errors).includes('userId') : false;
  const hasClientError = errors ? Object.keys(errors).includes('clientId') : false;
  const hasServiceError = errors ? Object.keys(errors).includes('serviceId') : false;
  const hasPricingError =
    errors && !hasServiceError
      ? errors['servicePricing'] && !!Object.keys(errors['servicePricing']).length
      : false;
  const hasMaxPeopleError = errors
    ? Object.keys(errors).includes('maxPeopleError')
    : false;
  const hasCalendarTimeError = errors
    ? Object.keys(errors).includes('calendarAutoUserError')
    : false;
  const hasDateError = errors
    ? Object.keys(errors).includes('start') || Object.keys(errors).includes('end')
    : false;

  let hasErrors;
  if (selectedClientId === 'anonymous') {
    hasErrors =
      hasUserIdError ||
      hasPricingError ||
      hasDateError ||
      hasCalendarTimeError ||
      hasMaxPeopleError ||
      hasServiceError;
  } else {
    hasErrors =
      hasUserIdError ||
      hasClientError ||
      hasPricingError ||
      hasDateError ||
      hasMaxPeopleError ||
      hasCalendarTimeError ||
      hasServiceError;
  }

  return (
    <>
      {hasErrors && (
        <Alert variant='danger'>
          {hasClientError && selectedClientId !== 'anonymous' && (
            <Form.Text as='span' className='text-danger'>
              <FaExclamationTriangle className='mr-1' />
              {t('validators_reservation_has_no_client')}
            </Form.Text>
          )}

          {hasUserIdError && (
            <Form.Text as='span' className='text-danger'>
              <FaExclamationTriangle className='mr-1' />
              {t('validation_reservation_user_blank')}
            </Form.Text>
          )}
          {hasServiceError && (
            <Form.Text as='span' className='text-danger'>
              <FaExclamationTriangle className='mr-1' />
              {t('validation_reservation_service_blank')}
            </Form.Text>
          )}
          {hasPricingError && (
            <Form.Text as='span' className='text-danger'>
              <FaExclamationTriangle className='mr-1' />
              {t('validation_reservation_service_pricing_error')}
            </Form.Text>
          )}
          {hasDateError && (
            <Form.Text as='span' className='text-danger'>
              <FaExclamationTriangle className='mr-1' />
              {t('validation_reservation_start_end_blank')}
            </Form.Text>
          )}
          {hasCalendarTimeError && (
            <Form.Text as='span' className='text-danger'>
              <FaExclamationTriangle className='mr-1' />
              {errors?.['calendarAutoUserError']}
            </Form.Text>
          )}
          {hasMaxPeopleError && (
            <Form.Text as='span' className='text-danger'>
              <FaExclamationTriangle className='mr-1' />
              {errors?.['maxPeopleError']}
            </Form.Text>
          )}
        </Alert>
      )}
      {calendarWorkingHoursError && (
        <Alert variant='warning'>
          <Form.Text as='span' className='text-warning'>
            <FaExclamationTriangle className='mr-1' />
            {t('validators_reservation_user_is_working')}
          </Form.Text>
        </Alert>
      )}
      {calendarUserIsBusy && (
        <Alert variant='warning'>
          <Form.Text as='span' className='text-warning'>
            <FaExclamationTriangle className='mr-1' />
            {t('USER_IS_BUSY')}
          </Form.Text>
        </Alert>
      )}
    </>
  );
};

export default ReservationsErrors;
