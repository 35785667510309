import moment from 'moment';
import { IReservation } from 'types/Reservations';

export const filterSameReservations = (
  compareTo?: IReservation,
  allReservations?: IReservation[],
  includeSelf = true,
) => {
  if (!compareTo) return [];
  return allReservations?.filter(
    res =>
      moment(compareTo.start).isSame(res.start) &&
      moment(compareTo.end).isSame(res.end) &&
      (includeSelf || (!includeSelf && res.id !== compareTo.id)),
  );
};

export const getPersonCountInReservations = (reservations?: IReservation[]) => {
  let total = 0;
  reservations?.forEach(r => (total += Number(r.personsCount)));
  return total;
};

export const userHasReservationWithAtLeastPersons = (
  reservations: IReservation[],
  atLeast: number,
) => {
  const busyTimes: Record<
    string,
    Array<{ start: string; end: string; max: number }>
  > = {};
  /**
   * {
   *  [locationId]: [{start,end,max},{start,end,max},...]}
   * }
   */

  const addBusyTime = (
    locationId: string,
    start: string,
    end: string,
    people: number,
  ) => {
    if (!busyTimes[locationId]) {
      /** create [] if location wasn't considered yet */
      busyTimes[locationId] = [];
    }

    busyTimes[locationId].push({ start, end, max: people });
  };

  const isTimeAlreadyBusy = (locationId: string, start: string, end: string) => {
    return (
      busyTimes[locationId]?.findIndex(bt => bt.start === start && bt.end === end) || -1
    );
  };

  reservations.forEach(r => {
    const busyTimeIndex = isTimeAlreadyBusy(r.locationId, r.start, r.end);

    if (busyTimeIndex === -1) {
      addBusyTime(r.locationId, r.start, r.end, r.personsCount || 1);
    } else {
      busyTimes[r.locationId][busyTimeIndex].max += r.personsCount || 1;
    }
  });

  for (const key in busyTimes) {
    for (let i = 0; i < busyTimes[key].length; i++) {
      if (busyTimes[key][i].max >= atLeast) {
        return true;
      }
    }
  }

  return false;
};
