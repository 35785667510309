import { apiClient } from 'actions/BaseAction';
import { AxiosResponse } from 'axios';
import { API_END_POINTS } from 'constants/API';
import {
  TimetableGetExperience,
  ReservationDatesGetExperience,
} from 'experience/types/ExperienceTimetable';
import { TDispatch } from 'types/Thunk';

interface IExperienceTimetibleData {
  MinPersonsCount?: number;
  ServiceId?: string;
  ServicePricingId?: string;
  LocationId?: string;
  Start?: string;
  End?: string;
  UserId?: string;
}

export interface IExperienceSelectedServices {
  serviceId: string;
  servicePricingId: string;
  locationId: string;
  userId?: string;
  start: string;
  personsCount: number;
}

interface ITimeTableExpierence {
  currentService: IExperienceTimetibleData;
  selectedServices: IExperienceSelectedServices[];
}

export const getTimetableExperience = ({
  currentService,
  selectedServices,
}: ITimeTableExpierence) => {
  return async (
    dispatch: TDispatch<TimetableGetExperience>,
  ): Promise<AxiosResponse<any>> => {
    dispatch({
      type: TimetableGetExperience.REQUEST,
    });
    try {
      const response = await apiClient.request({
        method: 'POST',
        url: API_END_POINTS.GET_AVAILABLE_TIMES_EXPERIENCE,
        data: [...selectedServices],
        params: currentService,
      });
      dispatch({
        type: TimetableGetExperience.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: TimetableGetExperience.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const getReservationDatesExperience = ({
  currentService,
  selectedServices,
}: ITimeTableExpierence) => {
  return async (
    dispatch: TDispatch<ReservationDatesGetExperience>,
  ): Promise<AxiosResponse<any>> => {
    dispatch({
      type: ReservationDatesGetExperience.REQUEST,
    });
    try {
      const response = await apiClient.request({
        method: 'POST',
        url: API_END_POINTS.GET_RESERVATION_DATES_EXPERIENCE,
        data: selectedServices,
        params: currentService,
      });
      dispatch({
        type: ReservationDatesGetExperience.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ReservationDatesGetExperience.FAILED,
        payload: error,
      });
      throw error;
    }
  };
};
