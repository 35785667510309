import React, { useEffect, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './SynchronizationForm.scss';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import {
  getEmployeeStatus,
  sendEmployeeEmail,
  sendEmployeeEmailRevoke,
} from 'actions/EmployeesActions';
import { EmployeeStatus } from 'types/Employees';
import { Spinner } from 'react-bootstrap';
import { BiRefresh } from 'react-icons/bi';
import { toast } from 'react-toastify';
import Toast from '../../Utils/Toast';
import { useTranslation } from 'react-i18next';

interface ISynchroForm {
  employeeId?: string;
}

const statusTranslationKeys: Record<EmployeeStatus, string> = {
  Active: 'SYNCHRO_ON',
  Inactive: 'SYNCHRO_OFF',
  SentForApproval: 'SYNCHRO_SENT',
};

const SynchronizationForm: React.FC<ISynchroForm> = ({ employeeId }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<EmployeeStatus | null>(null);
  const [isLoadng, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleStatus = useMemo(() => {
    switch (status) {
      case EmployeeStatus.ACTIVE:
        return <p className='subtitle status-active'>{t('SYNCHRO_ON')}</p>;
      case EmployeeStatus.INACTIVE:
        return <p className='subtitle status-inactive'>{t('SYNCHRO_OFF')}</p>;
      case EmployeeStatus.SENT:
        return <p className='subtitle status-sent'>{t('SYNCHRO_SENT')}</p>;
      default:
        break;
    }
  }, [status]);

  const handleVerificationRevoke = async () => {
    if (employeeId) {
      setIsLoading(true);
      await dispatch(sendEmployeeEmailRevoke(employeeId));
      setIsLoading(false);
      setStatus(EmployeeStatus.INACTIVE);
      toast.success(<Toast text={t('SYNCHRO_TURNED_OFF')} />);
    }
  };
  const handleSendVerification = async () => {
    if (employeeId) {
      setIsLoading(true);
      await dispatch(sendEmployeeEmail(employeeId));
      const data: any = await dispatch(getEmployeeStatus(employeeId));
      setIsLoading(false);
      const newStatus = (data?.data?.status || EmployeeStatus.SENT) as EmployeeStatus;
      setStatus(newStatus);
      toast.success(<Toast text={t(statusTranslationKeys[newStatus])} />);
    }
  };

  const handleGetEmployeeStatus = async () => {
    if (employeeId) {
      setIsLoading(true);
      const data: any = await dispatch(getEmployeeStatus(employeeId));
      setIsLoading(false);
      setStatus(data.data.status);
    }
  };

  useEffect(() => {
    handleGetEmployeeStatus();
  }, []);
  return (
    <>
      <Row>
        <Col sm={12}>
          <Row>
            <Col sm={12} className={'title'}>
              {t('GOOGLE_CALENDER_SYNCHRO')}
            </Col>
          </Row>
        </Col>
        <Col>
          <div className='status-wrapper'>
            {isLoadng ? (
              <div className='status-wrapper-inner'>
                <Spinner animation='border' className='mr-1' size='sm' />
              </div>
            ) : (
              <div className='status-wrapper-inner'>
                {handleStatus}
                <BiRefresh className='refresh-button' onClick={handleGetEmployeeStatus} />
              </div>
            )}
            {EmployeeStatus.ACTIVE === status ? (
              <Button
                className='status-button button-off'
                variant='danger'
                onClick={handleVerificationRevoke}
                disabled={isLoadng}
              >
                {t('SYNCHRO_TURN_OFF')}
              </Button>
            ) : (
              <Button
                className='status-button'
                variant='primary'
                onClick={handleSendVerification}
                disabled={isLoadng}
              >
                {t('SYNCHRO_TURN_ON')}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SynchronizationForm;
