/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios';
import { TResetAction } from './App';

//User read modal in docs
export interface IVacation {
  id: string;
  companyId: string;
  start: string;
  end: string;
  description?: string;
  locationIds: string[];
  createdAt?: string;
  creatorId?: string;
  updatedAt?: string;
}

export interface IVacationTableData {
  id: string;
  companyId: string;
  start: string;
  end: string;
  description?: string;
  locationIds: string[];
  createdAt?: string;
  creatorId?: string;
  updatedAt?: string;
  interval: string;
  daysNumber: string | number;
}

export interface IVacationData {
  companyId: string;
  start: string;
  end: string;
  description?: string;
  locationIds?: string[];
  locations?: string[];
}

export interface IVacationDataWithId extends IVacationData {
  id: string;
}

export interface IGetVacations {
  data: Array<IVacation>;
  offset: number;
  limit: number;
  total: number;
  sort?: string;
}

export interface IVacationState {
  readonly data?: Array<IVacation>;
  readonly total?: number;
  readonly offset?: number;
  readonly isLoading?: boolean;
}

export const initialVacationState: IVacationState = {
  data: undefined,
  total: 0,
  offset: 0,
  isLoading: false,
};

export enum VacationAdd {
  REQUEST = 'VACATION_ADD_REQUEST',
  SUCCESS = 'VACATION_ADD_SUCCESS',
  FAILED = 'VACATION_ADD_FAILED',
  ADD_VACATION_STORE = 'VACATION_ADD_TO_STORE',
}

export enum VacationEdit {
  REQUEST = 'VACATION_EDIT_REQUEST',
  SUCCESS = 'VACATION_EDIT_SUCCESS',
  FAILED = 'VACATION_EDIT_FAILED',
  EDIT_VACATION_STORE = 'VACATION_EDIT_ADD_TO_STORE',
}

export enum VacationsGet {
  REQUEST = 'VACATIONS_GET_REQUEST',
  SUCCESS = 'VACATIONS_GET_SUCCESS',
  FAILED = 'VACATIONS_GET_FAILED',
}

export enum VacationsPut {
  VACATIONS_OFFSET_UPDATE = 'VACATIONS_OFFSET_UPDATE',
}

export enum VacationDelete {
  REQUEST = 'VACATION_DELETE_REQUEST',
  SUCCESS = 'VACATION_DELETE_SUCCESS',
  FAILED = 'VACATION_DELETE_SUCCESS',
  DELETE_COMPANY_STORE = 'VACATION_DELETE_FROM_STORE',
}

export type TVacationPutAction = {
  type: VacationsPut.VACATIONS_OFFSET_UPDATE;
  payload: number;
};

export type TVacationEditAction =
  | {
      type: VacationEdit.REQUEST;
    }
  | {
      type: VacationEdit.SUCCESS;
    }
  | {
      type: VacationEdit.EDIT_VACATION_STORE;
      payload: IVacation;
    }
  | {
      type: VacationEdit.FAILED;
      payload: AxiosError;
    };

export type TVacationAddAction =
  | {
      type: VacationAdd.REQUEST;
    }
  | {
      type: VacationAdd.SUCCESS;
      payload: AxiosResponse<string>;
    }
  | {
      type: VacationAdd.ADD_VACATION_STORE;
      payload: IVacation;
    }
  | {
      type: VacationAdd.FAILED;
      payload: AxiosError;
    };

export type TVacationDeleteAction =
  | {
      type: VacationDelete.REQUEST;
    }
  | {
      type: VacationDelete.SUCCESS;
    }
  | {
      type: VacationDelete.DELETE_COMPANY_STORE;
      payload: string;
    }
  | {
      type: VacationDelete.FAILED;
      payload: AxiosError;
    };

export type TVacationsGetAction =
  | {
      type: VacationsGet.REQUEST;
    }
  | {
      type: VacationsGet.SUCCESS;
      payload: AxiosResponse<IGetVacations>;
    }
  | {
      type: VacationsGet.FAILED;
      payload: AxiosError;
    };

export type TVacationsActions =
  | TVacationsGetAction
  | TVacationDeleteAction
  | TVacationEditAction
  | TVacationAddAction
  | TVacationPutAction
  | TResetAction;
