import React from 'react';
import { Field } from 'react-final-form';
import Form from 'react-bootstrap/Form';
import FormErrorText from './FormErrorText';
import './FormRadio.scss';

interface IFormRadio {
  name?: string;
  id: string;
  label?: string | React.Component;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'sm' | 'lg';
  groupClass?: string;
  validation?: (value: any) => any;
  showAvatar?: boolean;
  avatarUrl?: string;
}

const FormRadio: React.FC<IFormRadio> = ({
  name,
  label,
  value,
  onChange,
  groupClass,
  validation,
  id,
  showAvatar,
  avatarUrl,
}) => {
  return (
    <Field
      name={name || ''}
      component='input'
      type='radio'
      value={value}
      validate={validation}
    >
      {({ input, meta }) => (
        <>
          <label className={`form-radio input-group input-group-sm ${groupClass || ''} `}>
            <Form.Control
              id={id}
              isInvalid={meta.error && meta.touched && meta.submitFailed}
              type='radio'
              {...input}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                input.onChange(e);
                onChange?.(e);
              }}
            />
            {label && (
              <Form.Label
                className={
                  meta.error && meta.touched && meta.submitFailed
                    ? 'text-danger mb-0'
                    : 'mb-0'
                }
                htmlFor={id}
              >
                {showAvatar && (
                  <div
                    className='label-avatar'
                    style={{
                      ...(avatarUrl && {
                        backgroundImage: `url(${avatarUrl})`,
                        backgroundColor: 'unset',
                      }),
                    }}
                  />
                )}
                {label}
              </Form.Label>
            )}
          </label>
          {meta.error && meta.touched && meta.submitFailed && (
            <FormErrorText errorText={meta.error} />
          )}
        </>
      )}
    </Field>
  );
};

export default FormRadio;
