export const matchAllUserRoles = (
  userRoles: Array<string> = [],
  neededRoles: Array<string>,
) => {
  if (!userRoles) return false;
  return neededRoles.every(val => userRoles.indexOf(val) !== -1);
};

export const matchAnyUserRoles = (
  userRoles: Array<string> = [],
  neededRoles: Array<string>,
) => {
  if (!userRoles) return false;
  return neededRoles.some(val => userRoles.indexOf(val) !== -1);
};

export const matchUserRole = (userRoles: Array<string>, neededRoles: Array<string>) =>
  userRoles.every(val => neededRoles.indexOf(val) !== -1);

export const checkUserRole = (userRoles: Array<string>, neededRoles: Array<string>) =>
  userRoles.some(val => neededRoles.indexOf(val) !== -1);
