import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from '../../../middlewares/ErrorHandler';
import useError from '../../../hooks/useError';
import Toast from '../../Utils/Toast';
import { TUserState } from '../../../types/User';
import { IAppState } from '../../../store/Store';
import ModalForm from './ModalForm';
import FormModal from '../../Utils/Modals/FormModal';
import AgreeToDelete from '../../Utils/Modals/AgreeToDelete';
import { IVacation, IVacationData } from '../../../types/Vacations';
import {
  addVacation,
  deleteVacation,
  editVacation,
  getVacations,
} from '../../../actions/VacationsActions';
import { ILocation } from '../../../types/Locations';
import { IFormError } from '../../../constants/Interfaces';
import { TABLE_PAGE_SIZE } from 'constants/UtilVariables';
import { formatDate } from 'pages/admin/AdminReports';

interface IAddEditVacationModal {
  show: boolean;
  handleClose: () => void;
  vacationToEdit?: IVacation;
  locations?: ILocation[];
}

const AddEditVacationModal: React.FC<IAddEditVacationModal> = ({
  show,
  handleClose,
  vacationToEdit,
  locations = [],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<IFormError>();
  const [vacationToDelete, setVacationToDelete] = useState<string>();
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  useError(error, setError);

  const userState: TUserState = useSelector<IAppState, TUserState>(
    (state: IAppState) => state.userState,
  );

  useEffect(() => {
    if (vacationToEdit) {
      setStartDate(new Date(vacationToEdit.start));
      setEndDate(new Date(vacationToEdit.end));
    }
  }, [vacationToEdit]);

  useEffect(() => {
    if (moment(startDate) > moment(endDate)) {
      setEndDate(startDate);
    }
  }, [startDate]);

  const formValidation = (values: IVacationData) => {
    const error: Record<string, any> = {};
    if (!values.locationIds?.length) {
      error.locationIds = t('required');
    }
    return error;
  };

  const handleSubmit = async ({ locationIds, description }: IVacationData) => {
    const newVacationObjs = {
      locationIds,
      start: formatDate(startDate || undefined),
      end: formatDate(endDate || undefined),
      description,
      companyId: userState.data?.companyId || '',
    };

    try {
      if (vacationToEdit) {
        await dispatch(editVacation(newVacationObjs, vacationToEdit.id));
        toast.success(<Toast text={t('editedVacation')} />);
      } else {
        await dispatch(addVacation(newVacationObjs));
        toast.success(<Toast text={t('addedVacation')} />);

        setStartDate(null);
        setEndDate(null);
        handleClose();
      }
      await dispatch(getVacations(0, undefined, TABLE_PAGE_SIZE));
    } catch (err) {
      handleError(err?.response?.status, setError, err.response?.data?.title);
    }
  };

  const handleDelete = async () => {
    if (vacationToDelete) {
      try {
        await dispatch(deleteVacation(vacationToDelete));
        toast.success(
          <Toast
            text={t('successfullyDeleted', { item: t('vacation_day_table_page_title') })}
          />,
        );
        handleClose();
        setStartDate(null);
        setEndDate(null);
      } catch (err) {
        handleError(err?.response?.status, setError, err.response?.data?.title);
      }
    }
  };

  const openDeleteDialog = () => {
    if (vacationToEdit) {
      setVacationToDelete(vacationToEdit.id);
    }
  };

  return (
    <>
      <FormModal
        show={show}
        handleClose={handleClose}
        modalTitle={
          vacationToEdit
            ? t('vacation_day_update_header')
            : t('vacation_day_create_header')
        }
        validation={formValidation}
        handleSubmit={handleSubmit}
        form={
          <ModalForm
            locations={locations}
            startDate={startDate}
            endDate={endDate}
            setStartDate={date => setStartDate(date)}
            setEndDate={date => setEndDate(date)}
          />
        }
        editValue={vacationToEdit}
        handleDelete={openDeleteDialog}
      />
      <AgreeToDelete
        show={!!vacationToDelete}
        handleClose={() => setVacationToDelete(undefined)}
        handleAccept={handleDelete}
      />
    </>
  );
};

export default AddEditVacationModal;
